import { InlineEdit, Typography } from 'fgirot-k2-ui-components';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';

interface WageRowProps {
  wage: AggregatedWageTypeWage;
  wageType: EmployerWageType;
}

const WageRow = ({ wage, wageType }: WageRowProps) => {
  return (
    <InlineEdit label={wageType?.name} bold>
      <Typography variant="body2">{formatSweAmountTextWithSuffixWithTwoDecimals(wage.amount)}</Typography>
    </InlineEdit>
  );
};

export default WageRow;
