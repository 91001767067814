import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SEARCH_POSTING_RULES } from 'src/graphql/schema/query/accountingunit/SearchPostingRules';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import { PostingRulesPage } from 'src/types/accountingunit/PostingRule';
import { PostingRulesRequest, SearchPostingRulesFilter } from 'src/types/accountingunit/PostingRulesRequest';
import PostingRulesTable from './PostingRulesTable';
import PostingRulesSearchInput from './PostingRulesSearchInput';
import { getInitialPostingRulesRequest } from './utils/postingRuleUtils';
import SearchPostingRulesFilterModal from './SearchPostingRulesFilterModal';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import PostingRulesFilterDetails from './PostingRulesFilterDetails';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import './posting-rules-tab.scss';

interface PostingRuleTabProps {
  accountingUnit: AccountingUnit;
}
const PostingRulesTab: FC<PostingRuleTabProps> = ({ accountingUnit }) => {
  const { t } = useTranslation();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [postingRulesRequest, setPostingRulesRequest] = useState<PostingRulesRequest>(getInitialPostingRulesRequest());

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleTextSearch = (searchInput: string) => {
    setPostingRulesRequest({
      ...postingRulesRequest,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: SearchPostingRulesFilter) => {
    setPostingRulesRequest({ ...postingRulesRequest, filters });
  };

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleOpenModal = () => {
    setFiltersModalOpen(true);
  };

  return (
    <div className="posting-rules-tab">
      <QueryWrapper
        query={SEARCH_POSTING_RULES}
        options={{
          variables: {
            chartOfAccountsId: accountingUnit.chartOfAccounts.id,
            request: {
              searchInput: postingRulesRequest.searchInput,
              pageNumber: queryPageNumber,
              pageSize,
              filters: postingRulesRequest.filters,
            },
          },
          fetchPolicy: 'no-cache',
        }}
      >
        {({ searchPostingRules, paging }: PostingRulesPage) => {
          const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
          const showPagination = totalPages > 1;

          return (
            <>
              <div className="posting-rules-tab__search-input">
                <PostingRulesSearchInput searchRequest={postingRulesRequest} onSearch={handleTextSearch} />
                <PostingRulesFilterDetails searchRequest={postingRulesRequest} applyFilters={handleApplyFilters} />
              </div>
              {filtersModalOpen && (
                <SearchPostingRulesFilterModal
                  open={filtersModalOpen}
                  filters={postingRulesRequest?.filters}
                  onClose={handleCloseModal}
                  onApplyFilters={handleApplyFilters}
                />
              )}
              <PostingRulesTable postingRules={searchPostingRules} />
              {showPagination && (
                <div data-testid="posting-rules-tab-pagination">
                  <Pagination
                    currentPage={pageNumber}
                    onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                    totalPages={totalPages}
                  />
                </div>
              )}
              <div className="posting-rules-tab__filter-button">
                <Button
                  type="floating-action-button"
                  icon={<Icon type={'Sliders'} stroke="white" />}
                  label={t('common:filter')}
                  onClick={handleOpenModal}
                  data-cy="filter-button"
                />
              </div>
            </>
          );
        }}
      </QueryWrapper>
    </div>
  );
};

export default PostingRulesTab;
