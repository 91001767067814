import { ListGroup, InlineEdit, Typography, Pill, Button, Icon } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChangedObjectType,
  GROUPS,
  BENEFITS,
  VersionChangeType,
  NEW,
  UPDATED,
  PolicyVersionChanges,
} from 'src/types/policy/PolicyVersionChanges';
import './policy-changes-drawer.scss';
import DrawerBase from 'src/components/Common/DrawerBase/DrawerBase';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { Policy } from 'src/types/policy/Policy';

interface PolicyChangesDrawerProps {
  changes: PolicyVersionChanges;
  policyId: string;
  typeOfChanges: ChangedObjectType;
  handleCloseChangesDrawer: () => void;
  onClose: () => void;
}

const PolicyChangesDrawer = ({
  changes,
  typeOfChanges,
  handleCloseChangesDrawer,
  onClose,
  policyId,
}: PolicyChangesDrawerProps) => {
  const { t } = useTranslation();

  const getChangeRow = (label: JSX.Element, value: string, index: number) => {
    return (
      <InlineEdit bold label={label} key={`policy-changes-drawer-${value}-${index}`}>
        <Typography variant="body2" className="policy-changes-drawer__inlineEdit__typography">
          {value}
        </Typography>
      </InlineEdit>
    );
  };

  const label = (label: string, changeType: VersionChangeType) => {
    return (
      <div className="policy-changes-drawer__inlineEdit--label">
        <Typography bold>{label}</Typography>
        {changeType === NEW ? (
          <Pill
            className="policy-changes-drawer__inlineEdit--label__pill"
            label={t('account:employers-tab.policy-settings.changes-drawer.new-change-type')}
            type="success"
          />
        ) : changeType === UPDATED ? (
          <Pill
            className="policy-changes-drawer__inlineEdit--label__pill"
            label={t('account:employers-tab.policy-settings.changes-drawer.updated-change-type')}
            type="heads-up"
          />
        ) : (
          <Pill
            className="policy-changes-drawer__inlineEdit--label__pill"
            label={t('account:employers-tab.policy-settings.changes-drawer.deleted-change-type')}
            type="warning"
          />
        )}
      </div>
    );
  };

  const getTitle = () => {
    return typeOfChanges === GROUPS
      ? 'changed-groups'
      : typeOfChanges === BENEFITS
      ? 'changed-benefits'
      : 'changed-calculations';
  };

  return (
    <DrawerBase
      leftButton={
        <Button type="link" label="Tillbaka" icon={<Icon type={'ArrowLeft'} />} onClick={handleCloseChangesDrawer} />
      }
      onClose={onClose}
    >
      <div className="policy-changes-drawer--sub-title">
        <Typography variant="h3" component="h3" bold>
          {t(`account:employers-tab.policy-settings.changes-drawer.${getTitle()}`)}
        </Typography>
        <Typography className="policy-changes-drawer--sub-title__typography" variant="body2">
          {t('account:employers-tab.policy-settings.changes-drawer.changes-in-this-version')}
        </Typography>
      </div>
      <QueryWrapper query={GET_POLICY} options={{ variables: { policyId } }}>
        {(policy: Policy) => {
          const findEmploymentGroup = (employmentGroupId: string): string => {
            return policy.policyEmploymentGroups.find(
              (employmentGroup) => employmentGroup.employmentGroupId === employmentGroupId,
            )?.employmentGroupName;
          };

          return (
            <ListGroup variant="drawer-navigation">
              {typeOfChanges === GROUPS
                ? changes.changedEmploymentGroups.map((change, index) => {
                    return getChangeRow(
                      label(
                        findEmploymentGroup(change.policyEmploymentGroup?.employmentGroupId),
                        change.versionChangeType,
                      ),
                      '',
                      index,
                    );
                  })
                : typeOfChanges === BENEFITS
                ? changes.changedBenefits.map((change, index) =>
                    getChangeRow(
                      label(change.name, change.versionChangeType),
                      findEmploymentGroup(change.policyEmploymentGroup?.employmentGroupId),
                      index,
                    ),
                  )
                : changes.changedPglRules.map((change, index) =>
                    getChangeRow(label(change.name, change.versionChangeType), '', index),
                  )}
            </ListGroup>
          );
        }}
      </QueryWrapper>
    </DrawerBase>
  );
};

export default PolicyChangesDrawer;
