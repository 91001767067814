import { gql } from '@apollo/client';

export const GET_PAYMENTS_BY_INVOICE_ID = gql`
  query GetPaymentsByInvoiceId($invoiceHeaderId: UUID!) {
    paymentsByInvoiceId(invoiceHeaderId: $invoiceHeaderId) {
      id
      paymentDueDate
      paymentServiceProvider
      bank
      employer {
        id
      }
      paymentCreatedDate
      amount
      paymentStatus
      errorCode
      invoice {
        id
      }
      accountFrom
      accountTo
      description
    }
  }
`;
