import { gql } from '@apollo/client';

export const UPDATE_POLICY_AFFILIATION_RULE = gql`
  mutation UpdatePolicyAffiliationRule($request: UpdatePolicyAffiliationRuleRequest!) {
    updatePolicyAffiliationRule(request: $request) {
      id
      policyRuleType
      ruleValueUnit
      sourceType
      sourceReference
      ruleValues {
        id
        value
        valueType
        condition
      }
    }
  }
`;
