import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UnpaidInvoice } from 'src/types/invoice/UnpaidInvoice';
import { formatSweAmountTextAlwaysWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';

interface UnpaidInvoiceDetailsProps {
  groupedInvoicesByPaymentDueDate: UnpaidInvoice[];
}

const UnpaidInvoiceDetails: FC<UnpaidInvoiceDetailsProps> = ({ groupedInvoicesByPaymentDueDate }) => {
  const { t } = useTranslation();

  const Title = ({ title }: { title: string }) => (
    <Typography variant="caption" bold>
      {title}
    </Typography>
  );
  const Property = ({ value }: { value: string | JSX.Element }) => <Typography variant="body2">{value}</Typography>;

  return (
    <>
      <div className="unpaid-invoices-list-item__titles">
        <Title title={t('economy:economy-overview-tab:invoice-details.insurance-provider')} />
        <Title title={t('economy:economy-overview-tab:invoice-details.account-number-to')} />
        <Title title={t('economy:economy-overview-tab:invoice-details.remaining-amount')} />
        <Title title={t('economy:economy-overview-tab:invoice-details.reference')} />
        <Title title={t('economy:economy-overview-tab:invoice-details.invoice-status')} />
      </div>
      <ul className="unpaid-invoices-list-item__list">
        {groupedInvoicesByPaymentDueDate.map((invoiceDetail, index) => (
          <li
            key={`UnpaidInvoiceDetails-${invoiceDetail}-${index}`}
            data-testid={`unpaid-invoice-details-list-${invoiceDetail.id}`}
          >
            <button className="unpaid-invoices-list-item__button">
              <div className="unpaid-invoices-list-item__row">
                
                <Property value={stringOrBlank(invoiceDetail.insuranceProvider?.legalName)} />
                <Property value={invoiceDetail.accountNumberTo} />
                <Property value={formatSweAmountTextAlwaysWithTwoDecimals(invoiceDetail.remainingAmount)} />
                <Property value={invoiceDetail.ocrNumber ? invoiceDetail.ocrNumber : invoiceDetail.invoiceNumber} />
                <Property value={t(`invoice:status.${invoiceDetail.invoiceStatus}`)} />
              </div>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};
export default UnpaidInvoiceDetails;
