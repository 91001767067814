import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import { useUpdateEmployee } from 'src/service/employee/UpdateEmployeeMutation';
import { Employee } from 'src/types/Employee';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import ListProperty from 'src/components/Common/ListProperty';
import { stringOrBlank } from 'src/util/StringUtil';
import './update-end-date.scss';

interface UpdateEndDateProps {
  endDate: string;
  employee: Employee;
  checkpoint: EmploymentEventCheckpoint;
}

const UpdateEndDate = ({ endDate, employee, checkpoint }: UpdateEndDateProps) => {
  const { t } = useTranslation();
  const updateEmployee = useUpdateEmployee();
  const [lastChosenDate, setLastChosenDate] = useState(endDate);
  const [loading, setLoading] = useState<boolean>(false);
  const handleEmployeeUpdate = (field: string, value: string) => {
    if (lastChosenDate !== value) {
      setLoading(true);
      updateEmployee(
        {
          id: employee.id,
          fieldKey: field,
          fieldValue: value,
        },
        employee.employerId,
      ).finally(() => setLoading(false));
    }
    setLastChosenDate(value);
  };

  return (
    <ListGroup variant="inline-edit">
      <ListProperty
        label={t('examination:drawer.wage-file-employment-end-date-label')}
        value={stringOrBlank(checkpoint.additionalData.wageFileEmploymentEndDate)}
      />
      <InlineEdit bold label={t('examination:violation-types.employmentEndDate')}>
        <UpdateDatePicker
          fieldKey="employmentEndDate"
          onBlur={handleEmployeeUpdate}
          fieldValue={endDate}
          disabled={loading}
        />
      </InlineEdit>
    </ListGroup>
  );
};

export default UpdateEndDate;
