import { Button, Icon } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import When from 'src/components/Common/When';

interface CalculationButtonsProps {
  inDraft: boolean;
  isCreating: boolean;
  handleRemove?: () => void;
  handleCreateOrUpdate: () => void;
  fieldsEmptyOrInvalid: boolean;
  loading: boolean;
}

export const CalculationButtons = ({
  inDraft,
  isCreating,
  handleRemove,
  handleCreateOrUpdate,
  fieldsEmptyOrInvalid,
  loading,
}: CalculationButtonsProps) => {
  const { t } = useTranslation(['common', 'account']);
  return (
    <div className="pgl-rule-drawer__buttons">
      {inDraft && !isCreating && (
        <>
          <When condition={handleRemove != null}>
            <Button
              icon={<Icon type="Trash" />}
              type="secondary"
              label={t('account:pgl-rules:drawer:calculation:remove')}
              onClick={handleRemove}
              loading={loading}
            />
          </When>
          <Button
            type="primary"
            label={t('common:save-changes')}
            onClick={handleCreateOrUpdate}
            disabled={fieldsEmptyOrInvalid}
            loading={loading}
          />
        </>
      )}
      {inDraft && isCreating && (
        <Button
          type="primary"
          label={t('account:pgl-rules:drawer:calculation:add')}
          onClick={handleCreateOrUpdate}
          disabled={fieldsEmptyOrInvalid}
          loading={loading}
        />
      )}
    </div>
  );
};

export default CalculationButtons;
