import { Accordion, ListGroup, Pill, Switch, Typography } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { updateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { getCorrectEventValue } from 'src/util/InsuranceEventUtil';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';
import './retroactive-new-registration-checkpoint.scss';

interface RetroactiveNewRegistrationCheckpointProps {
  checkpoint: InsuranceEventCheckpoint;
  insuranceEvents: Map<string, InsuranceEvent>;
  employerId: string;
}

const RetroactiveNewRegistrationCheckpoint: React.FC<RetroactiveNewRegistrationCheckpointProps> = ({
  checkpoint,
  insuranceEvents,
  employerId,
}) => {
  const { t } = useTranslation();

  const insuranceEvent = insuranceEvents ? insuranceEvents.get(checkpoint?.additionalData.insuranceEventId) : undefined;

  const [toggled, setToggled] = useState(checkpoint.checkpointStatus === 'ACCEPTED');

  const handleToggleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCheckpointStatusMutation([checkpoint.id], e.target.checked ? 'ACCEPTED' : 'RAISED', employerId);
    setToggled(e.target.checked);
  };

  return (
    <Accordion
      header={
        <div className="retroactive-new-registration-checkpoint">
          {t(`insuranceEventTypes:${insuranceEvent.insuranceEventType}`)}
          <Pill label={t(`benefitTypes:${insuranceEvent.benefitType}`)} />
        </div>
      }
      icon={
        <div className="retroactive-new-registration-checkpoint">
          <Typography component="span" variant="body2">
            {t('verify:drawer.controlled')}
          </Typography>
          <Switch toggled={toggled} onChange={handleToggleSwitch} />
        </div>
      }
    >
      <ListGroup variant="inline-edit">
        <ListProperty
          data-testid="insurance-event-about-tab__insurance-provider"
          label={t('verify:drawer.provider')}
          value={insuranceEvent.insuranceProvider.legalName}
        />
        <ListProperty
          data-testid="insurance-event-about-tab__benefit-type"
          label={t('verify:drawer.benefit-type')}
          value={t(`benefitTypes:${insuranceEvent.benefitType}`)}
        />
        <ListProperty
          data-testid="insurance-event-about-tab__event-from-date"
          label={t('verify:drawer.event-from-date')}
          value={stringOrBlank(insuranceEvent.eventFromDate)}
        />
        {insuranceEvent.eventValues.map((eventValue) => (
          <ListProperty
            key={eventValue.eventValueType}
            label={t('verify:drawer.value')}
            value={
              <div className="insurance-event-about-tab__values">
                <Pill type="neutral" size="small" label={eventValue.eventValueType} />
                <Typography variant="caption">
                  {formatSweAmountTextWithSuffixNoDecimal(getCorrectEventValue(eventValue))}
                </Typography>
              </div>
            }
          />
        ))}
      </ListGroup>
    </Accordion>
  );
};
export default RetroactiveNewRegistrationCheckpoint;
