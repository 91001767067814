import { InteractiveCard } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Employee } from 'src/types/Employee';
import EmploymentDetailsDrawer from '../EmploymentDetailsDrawer/EmploymentDetailsDrawer';
import './employment-overview-section.scss';
import EmploymentProperty from './EmploymentProperty';
import EmployeeSectionLabel from '../EmployeeSectionLabel/EmployeeSectionLabel';
import { stringOrBlank } from 'src/util/StringUtil';
import { fetchEmployee } from 'src/service/employee/GetEmployee';
import { fetchLatestNonDraftEmployeeCard } from 'src/service/employeecard/GetLatestNonDraftEmployeeCard';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';

interface EmploymentOverviewSectionProps {
  employee: Employee;
  employeeCard?: AggregatedEmployeeCard;
  period: string;
}

const EmploymentOverviewSection = ({ employee, employeeCard, period }: EmploymentOverviewSectionProps) => {
  const [activeEmployeeCard, setActiveEmployeeCard] = useState<AggregatedEmployeeCard>(employeeCard);
  const [activeEmployee, setActiveEmployee] = useState<Employee>(employee);

  const isDraft = employee.isDraft;

  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const employmentEndDateHasPassed = employee.employmentEndDate && new Date(employee.employmentEndDate) < new Date();

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const getActiveEmployee = async (employeeId: string) => {
    const employee = await fetchEmployee(employeeId);
    setActiveEmployee(employee);
  };

  const getActiveEmployeeCard = async (employeeId, period) => {
    const employeeCard = await fetchLatestNonDraftEmployeeCard(employeeId, period);
    setActiveEmployeeCard(employeeCard);
  };

  useEffect(() => {
    if (isDraft) {
      getActiveEmployee(employee.id);
      getActiveEmployeeCard(employee.id, period);
    }
  }, [employee, employeeCard]);

  return (
    <>
      <InteractiveCard
        data-cy="employment-overview-card"
        label={
          <EmployeeSectionLabel
            employmentEnded={employmentEndDateHasPassed}
            label={t('employees:employment')}
            period={period}
          />
        }
        onClick={openDrawer}
      >
        <div className="employment-overview-section__list">
          <EmploymentProperty
            label={t('employees:employmentCategory')}
            value={t(`employmentCategories:${employeeCard.employmentCategory}`)}
            hasChanged={isDraft && employeeCard.employmentCategory !== activeEmployeeCard?.employmentCategory}
          />
          <EmploymentProperty
            label={t('employees:employmentType')}
            value={t(`employmentTypes:${employeeCard.employmentType}`)}
            hasChanged={isDraft && employeeCard.employmentType !== activeEmployeeCard?.employmentType}
          />
          <EmploymentProperty
            label={t('employees:remunerationType')}
            value={t(`remunerationTypes:${employeeCard.remunerationType}`)}
            hasChanged={isDraft && employeeCard.remunerationType !== activeEmployeeCard?.remunerationType}
          />
          <EmploymentProperty
            label={t('employees:economyDepartment')}
            value={stringOrBlank(employee.accountingUnit?.name)}
            hasChanged={isDraft && employee.accountingUnit?.name !== activeEmployee?.accountingUnit?.name}
          />
          <EmploymentProperty
            label={t('employees:employmentStartDate')}
            value={employee.employmentStartDate}
            hasChanged={isDraft && employee.employmentStartDate !== activeEmployee?.employmentStartDate}
          />
          <EmploymentProperty
            label={t('employees:employmentEndDate')}
            value={stringOrBlank(employee.employmentEndDate)}
            hasChanged={isDraft && employee.employmentEndDate !== activeEmployee?.employmentEndDate}
          />
          <EmploymentProperty
            label={t('employees:employmentPercentage')}
            value={`${employeeCard.workingPercentage && employeeCard.workingPercentage.toString() + '%'}`}
            hasChanged={isDraft && employeeCard.workingPercentage !== activeEmployeeCard?.workingPercentage}
          />
          <EmploymentProperty
            label={t('employees:partTimePension')}
            value={t(`common:${employeeCard.partTimePension}`)}
            hasChanged={isDraft && employeeCard.partTimePension !== activeEmployeeCard?.partTimePension}
          />
        </div>
      </InteractiveCard>

      {employeeCard && (
        <EmploymentDetailsDrawer
          open={drawerOpen}
          employee={employee}
          onClose={closeDrawer}
          employeeCard={employeeCard}
          activeEmployee={activeEmployee}
          activeEmployeeCard={activeEmployeeCard}
        />
      )}
    </>
  );
};

export default EmploymentOverviewSection;
