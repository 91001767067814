import { gql } from '@apollo/client';

export const CREATE_POLICY_EMPLOYMENT_GROUP = gql`
  mutation CreatePolicyEmploymentGroup($employerId: UUID!, $policyId: UUID!, $employmentGroupName: String!) {
    createPolicyEmploymentGroup(
      employerId: $employerId
      policyId: $policyId
      employmentGroupName: $employmentGroupName
    ) {
      id
      employmentGroupId
    }
  }
`;
