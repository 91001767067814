import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateDraftPolicy } from 'src/service/policy/CreateDraftPolicy';

interface CreatePolicyModalProps {
  open: boolean;
  handleClose: () => void;
  employerId: string;
}

const CreatePolicyModal: React.FunctionComponent<CreatePolicyModalProps> = ({ open, handleClose, employerId }) => {
  const { t } = useTranslation();
  const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(false);
  const { customerName } = useParams();
  const navigate = useNavigate();
  const createDraftPolicy = useCreateDraftPolicy();

  const handleCreate = () => {
    setCreateButtonDisabled(true);
    createDraftPolicy(employerId)
      .then((response) => {
        navigate(`/account/${customerName}/${employerId}/policy-settings/${response.id}`);
        handleClose();
        setCreateButtonDisabled(false);
      })
      .catch(() => {
        setCreateButtonDisabled(false);
      });
  };

  return (
    <Modal data-cy="create-policy-modal" centered overlayBackground open={open} onClose={handleClose}>
      <Card
        icon={<Icon type="Alert" stroke="#fda522" />}
        title={t('account:employers-tab.policy-settings.create-draft-policy-modal.title')}
        defaultClose
        onClose={handleClose}
      >
        <Typography component="span">
          {`${t('account:employers-tab.policy-settings.create-draft-policy-modal.body')} `}
        </Typography>
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleClose}
            data-cy="create-policy-modal__cancel-button"
          />
          <Button
            disabled={createButtonDisabled}
            label={t('account:employers-tab.policy-settings.create-draft-policy-modal.button-text')}
            onClick={handleCreate}
            data-cy="create-policy-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreatePolicyModal;
