import { UPDATE_POLICY_BENEFIT_CONNECTION_RULE } from 'src/graphql/schema/mutation/policy/UpdatePolicyBenefitConnectionRule';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { UpdatePolicyBenefitConnectionRuleRequest } from 'src/types/policy/request/UpdatePolicyBenefitConnectionRuleRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdatePolicyBenefitConnectionRule = () => {
  const apolloMutation = useApolloMutation();

  const updatePolicyBenefitConnectionRule = (request: UpdatePolicyBenefitConnectionRuleRequest, employerId: string) =>
    apolloMutation(UPDATE_POLICY_BENEFIT_CONNECTION_RULE, employerId, { request }, [GET_POLICY]);

  return updatePolicyBenefitConnectionRule;
};
