import { gql } from '@apollo/client';

export const GET_EMPLOYER_PAY_TYPES = gql`
  query GetEmployerPayTypes($employerId: UUID!) {
    employerPayTypes(employerId: $employerId) {
      id
      number
      name
      employerWageType {
        id
        name
        wageType
      }
      employerId
      inUse
      useQuantity
      employerKubs {
        id
        employerId
        kubType
        description
      }
    }
  }
`;
