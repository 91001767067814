import { TableRow, Td } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceRow } from 'src/types/invoice/InvoiceRow';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';

interface InvoiceRowTableRowProps {
  row: InvoiceRow;
}

const InvoiceRowTableRow: FC<InvoiceRowTableRowProps> = ({ row }) => {
  const { t } = useTranslation();

  return (
    <TableRow key={`InvoiceTableRow-${row.id}`}>
      <Td>{stringOrBlank(row.employee.personNumber)}</Td>
      <Td>{stringOrBlank(row.employee.firstName)}</Td>
      <Td>{stringOrBlank(row.employee.lastName)}</Td>
      <Td>{stringOrBlank(row.employee.employmentNumber)}</Td>
      <Td>
        {row?.insuranceTypeMapping?.benefitType ? t(`benefitTypes:${row?.insuranceTypeMapping?.benefitType}`) : '-'}
      </Td>
      <Td>{stringOrBlank(row.insuranceNumber)}</Td>
      <Td>{stringOrBlank(row?.insuranceTypeMapping.name)}</Td>
      <Td>{stringOrBlank(row.contractBenefitGroup)}</Td>
      <Td>{stringOrBlank(row.contractBenefitGroupName)}</Td>
      <Td>{stringOrBlank(row.note)}</Td>
      <Td>{stringOrBlank(row.invoicePeriod?.periodStart)}</Td>
      <Td>{stringOrBlank(row.invoicePeriod?.periodEnd)}</Td>
      <Td>{stringOrBlank(formatSweAmountText(row.amount))}</Td>
    </TableRow>
  );
};

export default InvoiceRowTableRow;
