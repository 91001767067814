import {
  UPDATE_ACCOUNTING_TYPE,
  UPDATE_ACCOUNTING_TYPE_COMPONENTS,
} from 'src/graphql/schema/mutation/accountingunit/UpdateAccountingType';
import { ACCOUNTING_TYPE } from 'src/graphql/schema/query/accountingunit/AccountingType';
import {
  UpdateAccountingTypeComponentsInput,
  UpdateAccountingTypeInput,
} from 'src/types/accountingunit/AccountingType';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateAccountingType = () => {
  const apolloMutation = useApolloMutation(false);

  const updateAccountingType = (request: UpdateAccountingTypeInput, employerId: string) =>
    apolloMutation(UPDATE_ACCOUNTING_TYPE, employerId, { request }, [ACCOUNTING_TYPE]);

  return updateAccountingType;
};

export const useUpdateAccountingTypeComponents = () => {
  const apolloMutation = useApolloMutation();

  const updateAccountingTypeComponents = (request: UpdateAccountingTypeComponentsInput, employerId: string) =>
    apolloMutation(UPDATE_ACCOUNTING_TYPE_COMPONENTS, employerId, { request }, [ACCOUNTING_TYPE]);

  return updateAccountingTypeComponents;
};
