import { RuleValue } from './RuleValue';

export interface AffiliationRule {
  id: string;
  policyRuleType: PolicyRuleType;
  ruleValues: RuleValue[];
  ruleValueUnit?: RuleValueUnit;
  sourceType?: string;
  sourceReference?: string;
}
export enum RuleValueUnit {
  AGE = 'AGE',
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  SEK = 'SEK',
  IBB = 'IBB',
}
export enum PolicyRuleType {
  EMPLOYMENT_CATEGORY = 'EMPLOYMENT_CATEGORY',
  EMPLOYMENT_REMUNERATION_TYPE = 'EMPLOYMENT_REMUNERATION_TYPE',
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  AGE_LIMIT = 'AGE_LIMIT',
  YEAR_OF_BIRTH_LIMIT = 'YEAR_OF_BIRTH_LIMIT',
  PARENTAL_LEAVE_LIMIT = 'PARENTAL_LEAVE_LIMIT',
  MILITARY_LEAVE_LIMIT = 'MILITARY_LEAVE_LIMIT',
  STUDIES_LEAVE_LIMIT = 'STUDIES_LEAVE_LIMIT',
  LEAVE_OF_ABSENCE_LIMIT = 'LEAVE_OF_ABSENCE_LIMIT',
  SICK_LEAVE_LIMIT = 'SICK_LEAVE_LIMIT',
  ALTERNATIVE_SICK_LEAVE_LIMIT = 'ALTERNATIVE_SICK_LEAVE_LIMIT',
  EMPLOYMENT_START_DATE = 'EMPLOYMENT_START_DATE',
  EMPLOYMENT_END_DATE = 'EMPLOYMENT_END_DATE',
  WAGE_UPPER_LIMIT = 'WAGE_UPPER_LIMIT',
  WAGE_LOWER_LIMIT = 'WAGE_LOWER_LIMIT',
  INDIVIDUAL_CHOICE_ITP1 = 'INDIVIDUAL_CHOICE_ITP1',
  WORKING_HOURS_LIMIT = 'WORKING_HOURS_LIMIT',
}

export const leaveLimitRuleTypes: PolicyRuleType[] = [
  PolicyRuleType.LEAVE_OF_ABSENCE_LIMIT,
  PolicyRuleType.STUDIES_LEAVE_LIMIT,
  PolicyRuleType.MILITARY_LEAVE_LIMIT,
  PolicyRuleType.PARENTAL_LEAVE_LIMIT,
];
export const sickLeaveLimitRuleTypes: PolicyRuleType[] = [
  PolicyRuleType.SICK_LEAVE_LIMIT,
  PolicyRuleType.ALTERNATIVE_SICK_LEAVE_LIMIT,
];
