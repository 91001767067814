import { useTranslation } from 'react-i18next';
import { PremiumInvoiceRowsFilter } from 'src/types/invoice/PremiumInvoiceRowsFilter';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { PremiumInvoiceRowSortField } from '../PremiumInvoiceRowsTable/PremiumInvoiceRowSortField';
import { stringOrBlank } from 'src/util/StringUtil';

export const getInitialSearchPremiumInvoiceRowsRequest = (): PremiumInvoiceRowsFilter => {
  return {
    periodStart: { year: null, month: null },
    periodEnd: { year: null, month: null },
    insuranceProviderIds: [],
  };
};

export const getPremiumInvoiceTableHeads = (
  onSortFieldChange: (sortField: PremiumInvoiceRowSortField) => void,
): TableHeadProps[] => {
  const { t } = useTranslation();
  return [
    {
      fieldName: 'insuranceProvider',
      title: t('premiumInvoiceRows:table.head.insurance-provider'),
    },
    {
      fieldName: 'agreedProduct',
      title: t('premiumInvoiceRows:table.head.agreed-product'),
    },
    {
      fieldName: 'insuranceNumber',
      title: t('premiumInvoiceRows:table.head.insurance-number'),
    },
    {
      fieldName: 'insuranceTypeMappingName',
      title: t('premiumInvoiceRows:table.head.insurance-type-mapping-name'),
    },
    {
      fieldName: 'amount',
      title: t('premiumInvoiceRows:table.head.amount'),
    },
    {
      fieldName: 'salaryTax',
      title: t('premiumInvoiceRows:table.head.salary-tax'),
    },
    {
      handleSortDirectionChange: () => {
        onSortFieldChange('periodStart');
      },
      fieldName: 'periodStart',
      title: t('premiumInvoiceRows:table.head.period-start'),
    },
    {
      handleSortDirectionChange: () => {
        onSortFieldChange('periodEnd');
      },
      fieldName: 'periodEnd',
      title: t('premiumInvoiceRows:table.head.period-end'),
    },
    {
      fieldName: 'agreementNumber',
      title: t('premiumInvoiceRows:table.head.agreement-number'),
    },
    {
      fieldName: 'invoiceNumber',
      title: t('premiumInvoiceRows:table.head.invoice-number'),
    },
    {
      fieldName: 'benefitType',
      title: t('premiumInvoiceRows:table.head.benefit-type'),
    },
    {
      fieldName: 'contractBenefitGroup',
      title: t('premiumInvoiceRows:table.head.contact-benefit-group'),
    },
    {
      fieldName: 'contractBenefitGroupName',
      title: t('premiumInvoiceRows:table.head.contract-benefit-group-name'),
    },
    {
      fieldName: 'dueDate',
      title: t('premiumInvoiceRows:table.head.due-date'),
    },
    {
      handleSortDirectionChange: () => {
        onSortFieldChange('paymentDueDate');
      },
      fieldName: 'paymentDueDate',
      title: t('premiumInvoiceRows:table.head.payment-date'),
    },
    {
      fieldName: 'note',
      title: t('premiumInvoiceRows:table.head.note'),
    },
  ];
};

export const translateBenefitType = (benefitType: string): string => {
  const { t } = useTranslation();

  if (benefitType) {
    return t(`benefitTypes:${benefitType}`);
  }
  return stringOrBlank(benefitType);
};
