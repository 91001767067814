import { Button, Checkbox, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchInsuranceProductCondition } from 'src/service/insurance/GetInsuranceProductCondition';
import './condition-row.scss';

interface ConditionRowProps {
  link: string;
  benefitTypes: string[];
  certify?: boolean;
  conditionIds: string[];
  handleOnChangeChecked?: (conditionIds: string[], operation: string) => void;
}

const ConditionRow = ({ link, benefitTypes, certify, conditionIds, handleOnChangeChecked }: ConditionRowProps) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const handleOnChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setChecked(true);
      handleOnChangeChecked(conditionIds, 'ADD');
    } else {
      setChecked(false);
      handleOnChangeChecked(conditionIds, 'REMOVE');
    }
  };

  const handleConditionLinkClick = () => {
    fetchInsuranceProductCondition(link).then((data) => {
      window.open(data.documentUrl, '_blank', 'noopener noreferrer');
    });
  };

  return (
    <div className={['condition-row'].join(' ')}>
      {certify && (
        <>
          <Checkbox checked={checked} className="condition-row__checkbox" onChange={handleOnChangeCheckbox} />
          <Typography component="span" className="condition-row__text" variant="body2">
            <>{t('certify:conditions-modal.read-understand')} &nbsp;</>
          </Typography>
        </>
      )}
      <Button
        size="small"
        type="inline"
        label={certify ? t('certify:conditions-modal.conditions-for') : t('certify:conditions-modal.Conditions-for')}
        onClick={handleConditionLinkClick}
      />
      {benefitTypes.map((benefitType) => (
        <Pill key={benefitType} className="condition-row__pill" label={`${t(`benefitTypes:${benefitType}`)}`} />
      ))}
    </div>
  );
};

export default ConditionRow;
