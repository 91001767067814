import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import { useUpdateEmployee } from 'src/service/employee/UpdateEmployeeMutation';
import { Employee } from 'src/types/Employee';
import ListProperty from 'src/components/Common/ListProperty';
import { stringOrBlank } from 'src/util/StringUtil';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import './update-start-date.scss';

interface UpdateStartDateProps {
  startDate: string;
  employee: Employee;
  checkpoint: EmploymentEventCheckpoint;
}

const UpdateStartDate = ({ startDate, employee, checkpoint }: UpdateStartDateProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [lastChosenDate, setLastChosenDate] = useState(startDate);

  const updateEmployee = useUpdateEmployee();
  const handleEmployeeUpdate = (field: string, value: string) => {
    if (lastChosenDate !== value) {
      setLoading(true);
      updateEmployee(
        {
          id: employee.id,
          fieldKey: field,
          fieldValue: value,
        },
        employee.employerId,
      ).finally(() => setLoading(false));
    }
    setLastChosenDate(value);
  };

  return (
    <ListGroup variant="inline-edit">
      <ListProperty
        label={t('examination:drawer.wage-file-employment-start-date-label')}
        value={stringOrBlank(checkpoint.additionalData.wageFileEmploymentStartDate)}
      />
      <InlineEdit bold label={t('examination:violation-types.employmentStartDate')}>
        <UpdateDatePicker
          fieldKey="employmentStartDate"
          onBlur={handleEmployeeUpdate}
          fieldValue={startDate}
          disabled={loading}
        />
      </InlineEdit>
    </ListGroup>
  );
};

export default UpdateStartDate;
