import { InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import './agreement-status-tab.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { UpdateInsuranceProviderContractRequest } from 'src/types/contract/request/UpdateInsuranceProviderContractRequest';
import { useUpdateInsuranceProviderContract } from 'src/service/contract/UpdateInsuranceProviderContract';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { useParams } from 'react-router-dom';

interface AgreementStatusTabProps {
  contract: InsuranceProviderContract;
}

const AgreementStatusTab: FC<AgreementStatusTabProps> = ({ contract }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const updateInsuranceProviderContract = useUpdateInsuranceProviderContract();

  const agreementStatus: Option<string>[] = [
    { label: t(`agreementRegistrySettings:agreement-status.${'ACTIVE'}`), value: 'ACTIVE' },
    { label: t(`agreementRegistrySettings:agreement-status.${'TERMINATED'}`), value: 'TERMINATED' },
  ];

  const handleAgreementStatusChange = (option: Option<string>) => {
    const request: UpdateInsuranceProviderContractRequest = {
      insuranceProviderContractId: contract.id,
      status: option.value,
    };
    updateInsuranceProviderContract(request, employerId).catch(() => {
      alert('Error when updating status');
    });
  };

  return (
    <div className="agreement-status-tab">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('agreementRegistrySettings:status')} bold>
          <Select
            small
            placeholder={t('common:select')}
            options={agreementStatus}
            selected={agreementStatus.find((option) => option.value === contract.status)}
            onChange={handleAgreementStatusChange}
            data-cy="agreement-status-tab-dropdown"
          />
        </InlineEdit>
      </ListGroup>
    </div>
  );
};

export default AgreementStatusTab;
