import { CreateInsuranceProviderContractRequest } from 'src/types/contract/request/CreateInsuranceProviderContractRequest';
import { isBlank } from './StringUtil';

export const isContractRequestValid = (request: CreateInsuranceProviderContractRequest) => {
  let isValid = true;

  if (isBlank(request.insuranceProviderId)) {
    isValid = false;
  }

  if (isBlank(request.name)) {
    isValid = false;
  }

  if (isBlank(request.agreementNumber)) {
    isValid = false;
  }

  if (request.name && request.name.length > 64) {
    isValid = false;
  }

  if (request.agreementNumber && request.agreementNumber.length > 64) {
    isValid = false;
  }

  return isValid;
};
