import { Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import ScrollBase from 'src/components/Common/ScrollBase';
import { ReportingStatusEmployer } from 'src/types/employer/Employer';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import FileCardList from './FileCardList';
import './company-upload-status.scss';

interface CompanyUploadStatusProps {
  open: boolean;
  onClose?: () => void;
  wageFilePeriod: string;
  employer?: ReportingStatusEmployer;
}

const CompanyUploadStatus = ({ open, onClose, wageFilePeriod, employer }: CompanyUploadStatusProps) => {
  const { t } = useTranslation();

  const handleOnClose = () => {
    onClose && onClose();
  };

  return (
    <DrawerModal open={open} overlayBackground overlayOnClose onClose={handleOnClose}>
      <DrawerBase onClose={handleOnClose} title={employer.legalName}>
        <Typography variant="caption" bold className="company-upload-status__subtitle">
          {usePeriodText(wageFilePeriod)}
        </Typography>
        <div className="company-upload-status__file-info">
          <Typography variant="body2" bold>
            {`${employer.wageFileSessions.length} ${t('upload:reporting-status.company-upload-status.files-uploaded')}`}
          </Typography>
        </div>

        <ScrollBase maxHeightPercentage={70}>
          <FileCardList
            title={t('upload:reporting-status.company-upload-status.cant-process')}
            error
            uploadSessions={employer.wageFileSessions.filter((session) => session.validationErrors.length > 0)}
          />

          <FileCardList
            title={t('upload:reporting-status.company-upload-status.other-files')}
            uploadSessions={employer.wageFileSessions.filter((session) => session.validationErrors.length <= 0)}
          />
        </ScrollBase>
      </DrawerBase>
    </DrawerModal>
  );
};

export default CompanyUploadStatus;
