import { Button, Typography } from 'fgirot-k2-ui-components';
import LoadingOverlay from 'src/components/Common/LoadingOverlay';

interface PeriodDetailsCardSectionProps {
  title: string;
  children: React.ReactNode;
  loading?: boolean;
  buttonLabel: string;
  buttonOnClick: () => void;
}

const PeriodDetailsCardSection = ({
  title,
  loading,
  buttonLabel,
  buttonOnClick,
  children,
}: PeriodDetailsCardSectionProps) => {
  return (
    <div className="period-details-card--section">
      <Typography variant="subtitle" bold className="period-details-card--section-title">
        {title}
      </Typography>
      <div className="period-details-card--section-content">
        <LoadingOverlay loading={loading} />
        {children}
      </div>
      <Button type="primary" label={buttonLabel} onClick={buttonOnClick} />
    </div>
  );
};

export default PeriodDetailsCardSection;
