import { InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';

interface PolicyBenefitTabProps {
  policyBenefits: PolicyBenefit[];
}

const PolicyBenefitTab: React.FunctionComponent<PolicyBenefitTabProps> = ({ policyBenefits }) => {
  const { t } = useTranslation();

  return (
    <div className="policy-employment-group-drawer__tabs__tab">
      <ListGroup variant={'drawer-navigation'}>
        {policyBenefits.map((benefit, index) => (
          <InlineEdit bold label={benefit.name} key={`policy-changes-drawer-${benefit.id}-${index}`}>
            <div className="policy-employment-group-drawer__pill">
              <Pill label={t(`benefitTypes:abbreviations.${benefit.benefitType}`)} />
              <Typography variant="body2">{t(`benefitTypes:${benefit.benefitType}`)}</Typography>
            </div>
          </InlineEdit>
        ))}
      </ListGroup>
    </div>
  );
};

export default PolicyBenefitTab;
