import { InlineEdit, Select, Switch } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDER } from 'src/graphql/schema/query/insurance/GetInsuranceProvider';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import { createOptionsFromList } from 'src/util/OptionUtil';

interface CertifyConditionInputsProps {
  contract: InsuranceProviderContract;
  onChange: (key: string, value: string | boolean) => void;
  agreedProductRequest: ContractAgreedProductRequest;
}

const CertifyConditionInputs = ({ contract, onChange, agreedProductRequest }: CertifyConditionInputsProps) => {
  const { t } = useTranslation();

  const onProductConditionChange = (productConditionId: string) => {
    onChange('certifyInsuranceProductConditionId', productConditionId);
  };

  const onCertifyInsuranceProductConditionChange = (e) => {
    onChange('certifyInsuranceProductCondition', e.target.checked);
    if (e.target.checked === false) onChange('certifyInsuranceProductConditionId', null);
  };

  return (
    <>
      {contract.insuranceProvider !== null ? (
        <QueryWrapper
          query={GET_INSURANCE_PROVIDER}
          options={{
            variables: {
              insuranceProviderId: contract.insuranceProvider?.id,
            },
          }}
        >
          {(insuranceProvider: InsuranceProvider) => {
            const productConditions = insuranceProvider.insuranceProductConditions;
            const productConditionsOptions = [
              { value: null, label: t('common:select') },
              ...createOptionsFromList(productConditions, 'id', 'name'),
            ];
            return (
              <>
                <InlineEdit
                  label={t('agreementRegistrySettings:drawer.should-certify-insurance-product-condition')}
                  bold
                >
                  <Switch
                    toggled={agreedProductRequest.certifyInsuranceProductCondition}
                    onChange={onCertifyInsuranceProductConditionChange}
                    disabled={productConditions.length < 1}
                  />
                </InlineEdit>
                <InlineEdit label={t('agreementRegistrySettings:drawer.certify-insurance-product-condition')} bold>
                  <Select
                    small
                    placeholder={t('common:select')}
                    options={productConditionsOptions}
                    selected={productConditionsOptions.find(
                      (option) => option.value === agreedProductRequest.certifyInsuranceProductConditionId,
                    )}
                    onChange={(option) => onProductConditionChange(option.value)}
                    data-cy="product-condition-dropdown"
                    disabled={agreedProductRequest.certifyInsuranceProductCondition === false}
                    dropdownPosition="right"
                    allowDropdownMaxWidth={false}
                  />
                </InlineEdit>
              </>
            );
          }}
        </QueryWrapper>
      ) : (
        <>
          <InlineEdit label={t('agreementRegistrySettings:drawer.should-certify-insurance-product-condition')} bold>
            <Switch disabled toggled={false} onChange={null} />
          </InlineEdit>
          <InlineEdit label={t('agreementRegistrySettings:drawer.certify-insurance-product-condition')} bold>
            <Select
              small
              placeholder={t('agreementRegistrySettings:drawer.input-not-available')}
              selected={null}
              options={[]}
              onChange={null}
              disabled
            />
          </InlineEdit>
        </>
      )}
    </>
  );
};

export default CertifyConditionInputs;
