import { useApolloMutation } from '../graphql/Mutation';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';
import { UpsertDraftEmployeeCardGrossSalaryWageRequest } from 'src/types/employees/request/UpsertDraftEmployeeCardGrossSalaryWageRequest';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';
import { UPSERT_DRAFT_EMPLOYEE_CARD_GROSS_SALARY_WAGE } from 'src/graphql/schema/mutation/employeecard/UpsertDraftEmployeeCardGrossSalaryWage';

export const useUpsertDraftEmployeeCardGrossSalaryWage = () => {
  const apolloMutation = useApolloMutation();

  const upsertDraftEmployeeCardGrossSalaryWage = (
    request: UpsertDraftEmployeeCardGrossSalaryWageRequest,
    employerId: string,
  ) =>
    apolloMutation(
      UPSERT_DRAFT_EMPLOYEE_CARD_GROSS_SALARY_WAGE,
      employerId,
      {
        request,
      },
      [GET_LATEST_EMPLOYEE_CARD, FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES],
    );

  return upsertDraftEmployeeCardGrossSalaryWage;
};
