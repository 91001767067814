import { SortDirection, TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { PostingRule } from 'src/types/accountingunit/PostingRule';
import PostingRuleTableRow from './PostingRuleTableRow';
import { sortAlphabeticalAsc, sortAlphabeticalDesc, sortNumericalAsc, sortNumericalDesc } from 'src/util/SortUtil';

interface PostingRulesTableProps {
  postingRules: PostingRule[];
}

const PostingRulesTable: FC<PostingRulesTableProps> = ({ postingRules }) => {
  const [sortDirection, setSortDirection] = useState<SortDirection>('DOWN');
  const [sortField, setSortField] = useState<
    'insuranceProvider' | 'insuranceTypeName' | 'invoiceRowName' | 'benefitType' | 'account' | 'insuranceCategory'
  >('insuranceProvider');

  const tableHeads = [
    {
      title: t('economySettings:posting-rules-tab.table.head.insurance-provider'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceProvider');
      },
      fieldName: 'insuranceProvider',
    },
    {
      title: t('economySettings:posting-rules-tab.table.head.insurance-type-name'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceTypeName');
      },
      fieldName: 'insuranceTypeName',
    },
    {
      title: t('economySettings:posting-rules-tab.table.head.benefit-type'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('benefitType');
      },
      fieldName: 'benefitType',
    },
    {
      title: t('economySettings:posting-rules-tab.table.head.insurance-category'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceCategory');
      },
      fieldName: 'insuranceCategory',
    },
    {
      title: t('economySettings:posting-rules-tab.table.head.account'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('account');
      },
      fieldName: 'account',
    },
  ];

  const sortPostingRules = (a: PostingRule, b: PostingRule) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalAsc : sortNumericalDesc;
    switch (sortField) {
      case 'insuranceProvider':
        return sortAlphabetical(
          a.insuranceTypeMapping.insuranceProvider.legalName,
          b.insuranceTypeMapping.insuranceProvider.legalName,
        );
      case 'insuranceTypeName':
        return sortAlphabetical(a.insuranceTypeMapping.name, b.insuranceTypeMapping.name);
      // add case for field invoiceRowName
      case 'benefitType':
        return sortAlphabetical(a.insuranceTypeMapping.benefitType, b.insuranceTypeMapping.benefitType);
      case 'insuranceCategory':
        return sortNumerical(a.insuranceTypeMapping.insuranceCategory, b.insuranceTypeMapping.insuranceCategory);
      case 'account':
        return sortNumerical(a.accountingRow.accountNumber, b.accountingRow.accountNumber);
      default:
        return undefined;
    }
  };

  return (
    <TableBase>
      <TableHead>
        <TableRow>
          {tableHeads.map((head) => (
            <Th
              includeSortButtons={head?.sort !== undefined}
              handleSortDirectionChange={head.sort}
              key={`PostingRuleTableHead-${head.title}`}
              sortDirection={head.fieldName === sortField ? sortDirection : null}
            >
              {head.title}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {postingRules.sort(sortPostingRules).map((rule) => (
          <PostingRuleTableRow key={`PostingRuleTableRow-${rule.id}`} postingRule={rule} />
        ))}
      </TableBody>
    </TableBase>
  );
};

export default PostingRulesTable;
