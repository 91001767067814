export interface PaymentAccount {
  id: string;
  accountNumber: string;
  paymentServiceProvider: PaymentServiceProviderType;
  accountActive: boolean;
  bank: BankType;
}

export type PaymentServiceProviderType = 'BANKGIROT' | 'PAGERO';
export const paymentServiceProviderType: PaymentServiceProviderType[] = ['BANKGIROT', 'PAGERO'];

export type BankType = 'NORDEA' | 'HANDELSBANKEN' | 'SEB';
export const bankType: BankType[] = ['NORDEA', 'HANDELSBANKEN', 'SEB'];

export interface CreateAccountInput {
  accountNumber: string;
  paymentServiceProvider: PaymentServiceProviderType;
  accountActive: boolean;
  bank: BankType;
}

export interface PatchAccountInput {
  id: string;
  accountNumber: string;
  paymentServiceProvider: PaymentServiceProviderType;
  accountActive: boolean;
  bank: BankType;
}
