import { ListItem, Collapsible, Select } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { client } from 'src/graphql';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { useUpdateEmployee } from 'src/service/employee/UpdateEmployeeMutation';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import { Employee } from 'src/types/Employee';
import { createOptions } from 'src/util/OptionUtil';
interface EmployeeListItemProps {
  employee: Employee;
  accountingUnits: AccountingUnit[];
}

const EmployeeListItem: FC<EmployeeListItemProps> = ({ employee, accountingUnits }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [updatedEmployee, setUpdatedEmployee] = useState(employee);
  const updateEmployee = useUpdateEmployee();

  const toggleOpen = () => setOpen((prev) => !prev);

  const handleEmployeeOnChange = (field: string, value: string) =>
    setUpdatedEmployee((prev) => ({ ...prev, [field]: value }));

  const accountingUnitOptions = createOptions(accountingUnits, null, null, 'name');

  const handleEmployeeMutation = (fieldValue: string, fieldKey: string) =>
    updateEmployee(
      {
        id: employee.id,
        fieldKey,
        fieldValue,
      },
      employee.employerId,
    ).then(() =>
      client.refetchQueries({
        include: [GET_EMPLOYEE],
      }),
    );

  return (
    <div className="employee-list-item">
      <ListItem
        border
        header={`${employee.firstName} ${employee.lastName}`}
        buttonLabel="komplettera"
        onClick={toggleOpen}
      >
        <Collapsible open={open}>
          <TextInput
            label={t('invoice:employee-missing-modal.form.first-name')}
            variant="default"
            value={updatedEmployee.firstName}
            onChange={(e) => handleEmployeeOnChange('firstName', e.target.value)}
            onBlur={() => handleEmployeeMutation(updatedEmployee.firstName, 'firstName')}
          />
          <TextInput
            label={t('invoice:employee-missing-modal.form.last-name')}
            variant="default"
            value={updatedEmployee.lastName}
            onChange={(e) => handleEmployeeOnChange('lastName', e.target.value)}
            onBlur={() => handleEmployeeMutation(updatedEmployee.lastName, 'lastName')}
          />
          <TextInput
            label={t('invoice:employee-missing-modal.form.employment-number')}
            variant="default"
            value={updatedEmployee.employmentNumber}
            onChange={(e) => handleEmployeeOnChange('employmentNumber', e.target.value)}
            onBlur={() => handleEmployeeMutation(updatedEmployee.employmentNumber, 'employmentNumber')}
          />
          <Select
            placeholder={t('invoice:employee-missing-modal.form.accounting-unit')}
            options={accountingUnitOptions}
            selected={accountingUnitOptions.find((option) => option.value.id === employee.accountingUnit?.id)}
            onChange={(option) => handleEmployeeMutation(option.value.id, 'accountingUnitId')}
          />
        </Collapsible>
      </ListItem>
    </div>
  );
};

export default EmployeeListItem;
