import { post } from 'src/http/post';
import { ExportInvoiceRowsRequest } from 'src/types/invoice/ExportInvoiceRowsRequest';

export const exportInvoiceRows = (searchRequest: ExportInvoiceRowsRequest) => {
  return post('/api/exports/invoice-rows', searchRequest, { responseType: 'blob' }).then((response) => {
    if (response.status === 200) {
      const url = URL.createObjectURL(response.data as Blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = response.headers['content-disposition'].split('filename=')[1];
      a.click();

      URL.revokeObjectURL(url);
    }
  });
};
