import { GET_VERIFY_INSURANCE_EVENT_CHECKPOINTS } from '../../graphql/schema/query/checkpoint/GetVerifyInsuranceEventCheckpoints';
import { Service } from '../../types/Service';
import { InsuranceEventReportEmployeeResponse } from '../../types/report/ReportEmployeeResponse';
import { useCheckpointSubscription } from './CheckpointSubscription';
import { VERIFY_INSURANCE_EVENT_CHECKPOINT } from '../../graphql/schema/subscription/VerifyInsuranceEventCheckpoint';
import { ERROR, INIT, LOADED, LOADING } from '../../types/Status';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

const useVerifyInsuranceEventsCheckpoints = (
  employerIds: string[],
  wageFilePeriod: string,
): Service<InsuranceEventReportEmployeeResponse[]> => {
  const [result, setResult] = useState<Service<InsuranceEventReportEmployeeResponse[]>>({ status: INIT });

  const { loading, error, data } = useQuery(GET_VERIFY_INSURANCE_EVENT_CHECKPOINTS, {
    variables: {
      employerIds,
      wageFilePeriod,
    },
    skip: !employerIds || employerIds.length === 0 || !wageFilePeriod,
    fetchPolicy: 'no-cache',
  });

  useCheckpointSubscription(VERIFY_INSURANCE_EVENT_CHECKPOINT, employerIds, wageFilePeriod, setResult);

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data &&
      result.status !== LOADED &&
      setResult({
        status: LOADED,
        payload: data.reportEmployees,
      });
  }, [loading, error, data]);

  return result;
};

export default useVerifyInsuranceEventsCheckpoints;
