import React, { FC } from 'react';
import { Avatar, ChatBubble, Typography } from 'fgirot-k2-ui-components';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import { EmployeeComment } from 'src/types/employees/EmployeeComment';
import { useDeleteEmployeeComment } from 'src/service/employee/DeleteEmployeeComment';
import { Employee } from 'src/types/Employee';
import { mapNamesToOneString } from 'src/util/NameUtil/NameUtil';
import './employee-comment-entry.scss';

interface EmployeeCommentEntryProps {
  employee: Employee;
  employeeComment: EmployeeComment;
}

const EmployeeCommentEntry: FC<EmployeeCommentEntryProps> = ({ employeeComment, employee }) => {
  const deleteEmployeeComment = useDeleteEmployeeComment();

  const formattedDateTime = formatDateTimeString(employeeComment.createdAt);

  const handleDeleteComment = () => {
    deleteEmployeeComment(employee.id, employeeComment.id, employee.employerId);
  };

  return (
    <div className="employee-comment-entry">
      <Avatar className="employee-comment-entry__avatar" size="small" />
      <div className="employee-comment-entry__content">
        <ChatBubble chatText={employeeComment.comment} removable onClick={handleDeleteComment} />
        <div className="employee-comment-entry__content__meta">
          <Typography className="employee-comment-entry__content__meta--name" variant="body2">
            {mapNamesToOneString(employeeComment.createdBy.firstName, employeeComment.createdBy.lastName)}
          </Typography>
          <Typography className="employee-comment-entry__content__meta--date" variant="body2">
            {formattedDateTime}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCommentEntry;
