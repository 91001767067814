import { DrawerNavigation, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './agreed-product-row.scss';

interface AgreedProductRowProps {
  title: string;
  value: string;
  benefitType: string;
  handleOpenProductDetails: () => void;
}

const AgreedProductRow: FC<AgreedProductRowProps> = ({ title, value, benefitType, handleOpenProductDetails }) => {
  const { t } = useTranslation();
  return (
    <DrawerNavigation
      label={
        <>
          <Typography bold variant="body2">
            {title}
          </Typography>
          <Pill
            type="neutral"
            className="agreed-product-row__pill"
            label={t(`benefitTypes:abbreviations.${benefitType}`)}
          />
        </>
      }
      value={value}
      onClick={handleOpenProductDetails}
    />
  );
};

export default AgreedProductRow;
