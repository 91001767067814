import { BACKFILL_DRAFT_EMPLOYEE_CARDS } from 'src/graphql/schema/mutation/employee/BackfillDraftEmployeeCards';
import { useApolloMutation } from '../graphql/Mutation';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';

export const useBackfillDraftEmployeeCards = () => {
  const apolloMutation = useApolloMutation();

  const backfillDraftEmployeeCards = (employeeId: string, employerId: string, newEmploymentStartDate: string) =>
    apolloMutation(
      BACKFILL_DRAFT_EMPLOYEE_CARDS,
      employerId,
      {
        employeeId,
        newEmploymentStartDate,
      },
      [FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES],
    );

  return backfillDraftEmployeeCards;
};
