import { useApolloMutation } from '../graphql/Mutation';
import { CREATE_CONTRACT_SETTING } from 'src/graphql/schema/mutation/contract/CreateContractSetting';
import { CreateContractSettingInput } from 'src/types/contract/ContractSetting';

export const useCreateContractSetting = () => {
  const apolloMutation = useApolloMutation();

  const createContractSetting = (employerId: string, createContractSettingInput: CreateContractSettingInput) =>
    apolloMutation(CREATE_CONTRACT_SETTING, employerId, { request: createContractSettingInput });

  return createContractSetting;
};
