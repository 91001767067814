import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import { CREATE_CONTRACT_AGREED_PRODUCT } from 'src/graphql/schema/mutation/contract/CreateContractAgreedProduct';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateContractAgreedProduct = () => {
  const apolloMutation = useApolloMutation();

  const createContractAgreedProduct = (
    employerId: string,
    insuranceProviderContractId: string,
    request: ContractAgreedProductRequest,
  ) =>
    apolloMutation(
      CREATE_CONTRACT_AGREED_PRODUCT,
      employerId,
      {
        insuranceProviderContractId,
        request,
      },
      [GET_INSURANCE_PROVIDER_CONTRACTS],
    );

  return createContractAgreedProduct;
};
