import { gql } from '@apollo/client';

export const CREATE_ACCOUNTING_COMPONENT_TYPE = gql`
  mutation CreateAccountingComponentType($request: CreateAccountingComponentTypeInput) {
    createAccountingComponentType(request: $request) {
      id
      name
    }
  }
`;
