import { CREATE_POSTING_RULE } from 'src/graphql/schema/mutation/accountingunit/CreatePostingRule';
import {
  CreatePostingRuleInput,
  UpdatePostingRuleComponentsInput,
  UpdatePostingRuleInput,
} from 'src/types/accountingunit/PostingRule';
import {
  UPDATE_POSTING_RULE,
  UPDATE_POSTING_RULE_COMPONENTS,
} from 'src/graphql/schema/mutation/accountingunit/UpdatePostingRule';
import { DELETE_POSTING_RULE } from 'src/graphql/schema/mutation/accountingunit/DeletePostingRule';
import { ACCOUNTING_UNIT } from 'src/graphql/schema/query/accountingunit/AccountingUnit';
import { useApolloMutation } from '../graphql/Mutation';
import { POSTING_RULE } from 'src/graphql/schema/query/accountingunit/PostingRule';
import { SEARCH_POSTING_RULES } from 'src/graphql/schema/query/accountingunit/SearchPostingRules';
import { POSTING_RULES } from 'src/graphql/schema/query/accountingunit/PostingRules';

export const useCreatePostingRule = () => {
  const apolloMutation = useApolloMutation();

  const createPostingRule = (request: CreatePostingRuleInput, employerId: string) =>
    apolloMutation(CREATE_POSTING_RULE, employerId, { request }, [SEARCH_POSTING_RULES, POSTING_RULES]);

  return createPostingRule;
};

export const useUpdatePostingRule = () => {
  const apolloMutation = useApolloMutation(false);

  const updatePostingRule = (request: UpdatePostingRuleInput, employerId: string) =>
    apolloMutation(UPDATE_POSTING_RULE, employerId, { request }, [SEARCH_POSTING_RULES, ACCOUNTING_UNIT, POSTING_RULE]);

  return updatePostingRule;
};

export const useUpdatePostingRuleComponents = () => {
  const apolloMutation = useApolloMutation();

  const updatePostingRuleComponents = (request: UpdatePostingRuleComponentsInput, employerId: string) =>
    apolloMutation(UPDATE_POSTING_RULE_COMPONENTS, employerId, { request }, [POSTING_RULE]);

  return updatePostingRuleComponents;
};

export const useDeletePostingRule = () => {
  const apolloMutation = useApolloMutation();

  const deletePostingRule = (postingRuleId, employerId: string) =>
    apolloMutation(DELETE_POSTING_RULE, employerId, { postingRuleId }, [SEARCH_POSTING_RULES]);

  return deletePostingRule;
};
