import { Button, Card, CardAction, Modal, Option } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingAdjustmentActionInput } from 'src/types/accountingunit/AccountingAdjustmentAction';
import { AccountingAdjustmentActionType } from 'src/types/accountingunit/AccountingAdjustmentActionType';
import { AccountingAdjustmentCondition } from 'src/types/accountingunit/AccountingAdjustmentCondition';
import ActionSection from './ActionSection';
import ConditionSection from './ConditionSection';
import { replaceObjectById } from 'src/util/ArrayUtil';
import { useCreateAccountingAdjustmentRule } from 'src/service/accountingunit/AccountingAdjustmentRule';
import { useParams } from 'react-router-dom';
import { CreateAccountingAdjustmentRuleInput } from 'src/types/accountingunit/AccountingAdjustmentRule';
import { generateUUID } from 'src/util/UUIDUtil';
import { isValidRequest } from './util/AccountingAdjustmentRuleUtil';
interface AddAccountingAdjustmentRuleModalProps {
  open: boolean;
  onClose: () => void;
}

const AddAccountingAdjustmentRuleModal: FC<AddAccountingAdjustmentRuleModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();

  const [accountingAdjustmentAction, setAccountingAdjustmentAction] = useState<AccountingAdjustmentActionInput>({
    accountingAdjustmentActionType: null,
    referenceId: null,
  });
  const createAccountingAdjustmentRule = useCreateAccountingAdjustmentRule();
  const [accountingAdjustmentConditions, setAccountingAdjustmentConditions] = useState<AccountingAdjustmentCondition[]>(
    [],
  );

  const handleAccountingActionTypeChange = (option: Option<AccountingAdjustmentActionType>) => {
    setAccountingAdjustmentAction({ referenceId: null, accountingAdjustmentActionType: option.value });
  };

  const handleAccountingActionReferenceChange = (option: Option<string>) => {
    setAccountingAdjustmentAction((prev) => ({ ...prev, referenceId: option.value }));
  };

  const handleOnClose = () => {
    resetValues();
    onClose();
  };

  const handleCreateAccountingAdjustmentCondition = () => {
    setAccountingAdjustmentConditions((prev) => [
      ...prev,
      { id: generateUUID(), reference: { id: null, name: null }, accountingAdjustmentConditionType: null },
    ]);
  };

  const handleDeleteAccountingAdjustmentCondition = (id: string) => {
    setAccountingAdjustmentConditions((prev) => [...prev.filter((condition) => condition.id !== id)]);
  };

  const handleUpdateAccountingAdjustmentCondition = (accountingAdjustmentCondition: AccountingAdjustmentCondition) => {
    setAccountingAdjustmentConditions((prev) => [
      ...replaceObjectById<AccountingAdjustmentCondition>(prev, accountingAdjustmentCondition),
    ]);
  };
  const resetValues = () => {
    setAccountingAdjustmentConditions([]);
    setAccountingAdjustmentAction({
      accountingAdjustmentActionType: null,
      referenceId: null,
    });
  };

  const handleCreateAccountingAdjustmentRule = () => {
    const request: CreateAccountingAdjustmentRuleInput = {
      accountingAdjustmentConditions: accountingAdjustmentConditions.map((condition) => ({
        accountingAdjustmentConditionType: condition.accountingAdjustmentConditionType,
        referenceId: condition.reference.id,
      })),
      accountingAdjustmentAction,
    };
    createAccountingAdjustmentRule(request, employerId).then(() => {
      handleOnClose();
    });
  };
  return (
    <Modal centered overlayBackground open={open} onClose={handleOnClose}>
      <Card title={t('economySettings:accounting-adjustment-tab.modal.title')} defaultClose onClose={handleOnClose}>
        <ConditionSection
          accountingAdjustmentConditions={accountingAdjustmentConditions}
          createAccountingAdjustmentCondition={handleCreateAccountingAdjustmentCondition}
          deleteAccountingAdjustmentCondition={handleDeleteAccountingAdjustmentCondition}
          updateAccountingAdjustmentCondition={handleUpdateAccountingAdjustmentCondition}
        />
        <ActionSection
          accountingAdjustmentAction={accountingAdjustmentAction}
          onAccountingActionTypeChange={handleAccountingActionTypeChange}
          onAccountingActionReferenceChange={handleAccountingActionReferenceChange}
        />
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleOnClose} />
          <Button
            data-cy="create-accounting-adjustment-rule-button"
            disabled={!isValidRequest(accountingAdjustmentConditions, accountingAdjustmentAction)}
            label={t('economySettings:accounting-components-tab.modal.add')}
            onClick={handleCreateAccountingAdjustmentRule}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddAccountingAdjustmentRuleModal;
