import { ListItem, TableBody, TableHead } from 'fgirot-k2-ui-components';
import './insurance-events-search-result.scss';
import React, { useState } from 'react';
import InsuranceEventDetailsRow from './InsuranceEventDetailsRow';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import ReportingEmployeeHeader from 'src/components/Reporting/ReportingEmployeeHeader';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import InsuranceEventsDrawer from '../InsuranceEventsDrawer';
import InsuranceEventsTableHeads from './InsuranceEventsTableHeads';
import { PersonInsuranceEventsResponse } from 'src/types/reporting/PersonInsuranceEventsResponse';
import { Employer } from 'src/types/employer/Employer';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';

interface InsuranceEventsSearchResultProps {
  person: PersonInsuranceEventsResponse;
  employers: Employer[];
}

const InsuranceEventsSearchResult = ({ person, employers }: InsuranceEventsSearchResultProps) => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedInsuranceEvent, setSelectedInsuranceEvent] = useState<InsuranceEvent>(null);
  const fullName = formatEmployeeFullName(person.employees[0]);

  const employmentDetails = person.employees.map((employee) => {
    return {
      employmentNumber: employee.employmentNumber,
      employerId: employee.employerId,
    };
  });

  const handleOpenDrawer = () => {
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setSelectedInsuranceEvent(null);
  };

  const handleBackClick = () => {
    setSelectedInsuranceEvent(null);
  };

  const handleSelectEvent = (event: InsuranceEvent) => {
    setShowDrawer(true);
    setSelectedInsuranceEvent(event);
  };

  const anyEmployeeHasDraft = person.employees.some((employee) => employee.hasDraft);

  return (
    <div className="insurance-events-search-result">
      <ListItem
        compressed
        buttonLabel={`${t('reporting:insurance-events.show-details-accessibility-label')} ${fullName}`}
        header={
          <ReportingEmployeeHeader
            fullName={fullName}
            personNumber={person.personNumber}
            employmentDetails={employmentDetails}
            hasDraft={anyEmployeeHasDraft}
          />
        }
        onClick={() => handleOpenDrawer()}
        data-cy={`insurance-events-search-result-${person.id}`}
      >
        <TableBase compressed>
          <TableHead>
            <InsuranceEventsTableHeads />
          </TableHead>
          <TableBody>
            {person.insuranceEvents.map((insuranceEvent) => {
              const employee = person.employees.find((employee) => employee.id === insuranceEvent.employeeId);
              return (
                <InsuranceEventDetailsRow
                  key={insuranceEvent.insuranceEventId}
                  insuranceEvent={insuranceEvent}
                  employmentNumber={employee.employmentNumber}
                  onSelectEvent={handleSelectEvent}
                />
              );
            })}
          </TableBody>
        </TableBase>
      </ListItem>
      {showDrawer && (
        <InsuranceEventsDrawer
          open={showDrawer}
          person={person}
          onClose={handleCloseDrawer}
          onSelectEvent={handleSelectEvent}
          onBackClick={handleBackClick}
          selectedInsuranceEvent={selectedInsuranceEvent}
          employers={employers}
        />
      )}
    </div>
  );
};

export default InsuranceEventsSearchResult;
