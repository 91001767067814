import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_REPORTING_PERIOD_SUMMARIES } from 'src/graphql/schema/query/reporting/GetReportingPeriodSummaries';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { ReportingPeriodSummary } from 'src/types/reporting/ReportingPeriodSummary';

export const useReportingPeriodSummaries = (
  fromPeriod: string,
  toPeriod: string,
  employerIds: string[],
): Service<ReportingPeriodSummary[]> => {
  const [result, setResult] = useState<Service<ReportingPeriodSummary[]>>({
    status: INIT,
  });

  const { loading, error, data } = useQuery(GET_REPORTING_PERIOD_SUMMARIES, {
    variables: { fromPeriod, toPeriod, employerIds },
    skip: !employerIds?.length,
  });

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data && setResult({ status: LOADED, payload: data.reportingPeriodSummaries });
  }, [loading, error, data]);

  return result;
};
