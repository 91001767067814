import { Banner } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

function EmployeeInDraftBanner() {
  const { t } = useTranslation();
  return <Banner type="announcement" message={t('employees:actions.draft-banner')} />;
}

export default EmployeeInDraftBanner;
