import { InlineEdit, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { Wage } from 'src/types/EmployeeCard';

interface QuantityValueInputProps {
  isEditable: boolean;
  wage?: Wage;
  quantityValue: string;
  onHoursChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const QuantityValueInput = ({ isEditable, wage, quantityValue, onHoursChange }: QuantityValueInputProps) => {
  const { t } = useTranslation();

  return (
    <InlineEdit label={wage?.quantityUnit ? t(`unitTypes:${wage?.quantityUnit}`) : t('unitTypes:HOURS')} bold>
      {isEditable ? (
        <TextInput
          variant="inline-edit"
          value={quantityValue ? quantityValue : ''}
          onChange={onHoursChange}
          validationKey="positiveIntegerString"
          type="text"
          maxLength={65}
          data-cy="quantity-value-input"
        />
      ) : (
        <Typography variant="body2">{quantityValue}</Typography>
      )}
    </InlineEdit>
  );
};
