import { Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import './provider.scss';
import { CertifyConditionMetadata } from 'src/types/CertifyConditionMetadata';
import ConditionRow from './ConditionRow';
import { arrayToMap } from 'src/util/ArrayUtil';

interface ProviderProps {
  values: CertifyConditionMetadata[];
  name: string;
  certify?: boolean;
  handleOnChangeChecked?: (conditionIds: string[], operation: string) => void;
}

const Provider = ({ values, name, certify, handleOnChangeChecked }: ProviderProps) => {
  const groupedConditions: Map<string, Array<CertifyConditionMetadata>> = arrayToMap(
    values,
    'insuranceProviderProductConditionId',
  );

  return (
    <div className={[`provider`].join(' ')}>
      <Typography component="h4" className="provider__title" bold variant="body2">
        {name}
      </Typography>
      {Array.from(groupedConditions.keys()).map((key, index) => (
        <ConditionRow
          key={index + key}
          link={key}
          certify={certify}
          benefitTypes={groupedConditions.get(key).map((condition) => condition?.benefitType)}
          conditionIds={groupedConditions.get(key).map((condition) => condition?.insuranceProviderProductConditionId)}
          handleOnChangeChecked={handleOnChangeChecked}
        />
      ))}
    </div>
  );
};

export default Provider;
