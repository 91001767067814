import { useSubscription } from '@apollo/client';
import { useState, useEffect } from 'react';
import { SESSION_UPDATED } from 'src/graphql/schema/subscription/SessionUpdated';
import { WageFileSession } from 'src/types/WageFileSession';

export const useSession = (): [WageFileSession[], (input: string[]) => void] => {
  const [result, setResult] = useState<WageFileSession[]>([]);

  const { loading, data, error } = useSubscription(SESSION_UPDATED);

  useEffect(() => {
    const handleSubscription = () => {
      if (data) {
        const sessionUpdated: WageFileSession = data.sessionUpdated;
        const newSession = result.filter((session) => session.id === sessionUpdated.id).length === 0;

        const updatedSessions = newSession
          ? [...result, sessionUpdated]
          : result.map((session) => {
              return session.id === sessionUpdated.id ? sessionUpdated : session;
            });
        setResult(updatedSessions);
      }
    };
    handleSubscription();
  }, [loading, error, data]);

  const removeSessions = (sessionIds: string[]) => {
    setResult(result.filter((session) => !sessionIds.includes(session.id)));
  };

  return [result, removeSessions];
};
