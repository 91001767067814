import React from 'react';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import './certify-employee-drawer.scss';
import SupplierInfoTable from './SupplierInfoTable';
import UpdateSupplierInfo from './UpdateSupplierInfo';
import { InsuranceProvider } from 'src/types/InsuranceProvider';

interface CertifyEmployeeDrawerProps {
  infoPageVisible: boolean;
  handleInfoPageOpen: (checkpointId: string) => void;
  selectedCheckpointId: string;
  reportEmployee: InsuranceEventReportEmployeeResponse;
  insuranceProviders: InsuranceProvider[];
}

const CertifyEmployeeDrawer = ({
  infoPageVisible,
  handleInfoPageOpen,
  selectedCheckpointId,
  reportEmployee,
  insuranceProviders,
}: CertifyEmployeeDrawerProps) => {
  const handleSelectedProduct = (checkpointId: string) => {
    handleInfoPageOpen(checkpointId);
  };
  return infoPageVisible ? (
    <UpdateSupplierInfo
      checkpoint={reportEmployee.checkpoints.find((checkpoint) => checkpoint.id === selectedCheckpointId)}
      employerId={reportEmployee?.employee.employerId}
    />
  ) : (
    <SupplierInfoTable
      checkpoints={reportEmployee.checkpoints}
      handleSelectedProduct={handleSelectedProduct}
      insuranceProviders={insuranceProviders}
      employerId={reportEmployee?.employee.employerId}
    />
  );
};

export default CertifyEmployeeDrawer;
