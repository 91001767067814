import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import './policy-calculations-tab.scss';
import { Button, Icon } from 'fgirot-k2-ui-components';
import CreateCalculationModal from './CreateCalculationModal/CreateCalculationModal';
import PglCalculations from '../PglCalculations/PglCalculations';

interface PolicyCalculationsTabProps {
  policyEntity: PolicyEntity;
}

const PolicyCalculationsTab: React.FunctionComponent<PolicyCalculationsTabProps> = ({ policyEntity }) => {
  const [createCalculationModalOpen, setCreateCalculationModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenAddCalculationModal = () => {
    setCreateCalculationModalOpen(true);
  };

  const handleCloseAddCalculationModal = () => {
    setCreateCalculationModalOpen(false);
  };

  return (
    <>
      <div className="policy-calculations-tab">
        <PglCalculations policyEntity={policyEntity} />
      </div>
      {policyEntity?.state === 'DRAFT' && (
        <div className="policy-calculations-tab__create-group-button">
          <Button
            data-cy="create-calculation-button"
            type="floating-action-button"
            icon={<Icon type={'PlusLarge'} />}
            label={t('policySettings:calculations-tab.add-calculation')}
            onClick={handleOpenAddCalculationModal}
          />
        </div>
      )}
      <CreateCalculationModal
        employerId={policyEntity?.employerId}
        policyId={policyEntity?.id}
        open={createCalculationModalOpen}
        onClose={handleCloseAddCalculationModal}
      />
    </>
  );
};

export default PolicyCalculationsTab;
