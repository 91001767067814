import { AccountingUnit } from '../accountingunit/AccountingUnit';
import { WageFileSession } from '../WageFileSession';

export interface Employer {
  id: string;
  customerId: string;
  customerNumber: number;
  legalName: string;
  organisationNumber: string;
  description: string;
  accountingUnits: AccountingUnit[];
}

export interface ReportingStatusEmployer {
  id: string;
  legalName: string;
  organisationNumber: string;
  wageFileSessions: WageFileSession[];
}

export type WageFileFormat = 'XML';
export const wageFileFormats: WageFileFormat[] = ['XML'];
