import { Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_REPORTING_EVENTS_COUNT } from 'src/graphql/schema/query/reporting/GetReportingEventsCount';
import { ReportingEventsCount } from 'src/types/reporting/ReportingEventsCount';
import { ReportingEventsPage } from 'src/types/reporting/ReportingEventsPage';

interface ReportingEventsSearchResultSummaryProps {
  reportingEvents: ReportingEventsPage;
  period: string;
}

export const ReportingEventsSearchResultSummary = ({
  reportingEvents,
  period,
}: ReportingEventsSearchResultSummaryProps) => {
  const { t } = useTranslation();

  const numberOfReportingEvents =
    reportingEvents?.persons?.reduce((acc, curr) => acc + curr.reportingEvents.length, 0) || 0;

  return (
    <Typography variant="caption">
      <>
        {`${t('reporting:reporting-events.shows')} `}
        <Typography className="reporting-event-table__page" component="span" variant="caption" bold>
          {numberOfReportingEvents.toString()}
        </Typography>
        <>
          <QueryWrapper query={GET_REPORTING_EVENTS_COUNT} options={{ variables: { wageFilePeriod: period } }}>
            {(data: ReportingEventsCount) => (
              <>
                {` ${t('reporting:reporting-events.of')} `}
                <Typography className="reporting-event-table__page" component="span" variant="caption" bold>
                  {data.totalNumberOfReportingEvents.toString() + ''}
                </Typography>
                {` ${t('reporting:reporting-events.events-total-in-period')}`}
              </>
            )}
          </QueryWrapper>
        </>
      </>
    </Typography>
  );
};

export default ReportingEventsSearchResultSummary;
