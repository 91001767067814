import { client } from 'src/graphql';
import { extractDataField } from './GraphqlUtils';
import { DocumentNode } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ToastContext } from 'fgirot-k2-ui-components';

export const useApolloMutation = (useSuccessToast = true) => {
  const { t } = useTranslation();
  const { displayToast } = useContext(ToastContext);

  const apolloMutation = (
    mutation: DocumentNode,
    employerId: string,
    options?: object,
    refetchQueries?: DocumentNode[],
  ) => {
    const sucessTitle = t('common:toast-success');
    const errorTitle = t('common:toast-error');
    let mutationName: string = null;

    if ('name' in mutation.definitions[0]) {
      mutationName = mutation.definitions[0].name?.value;
    }

    const successMessage = t([`userFeedback:${mutationName}.success`, `userFeedback:fallback.success`]);
    const errorMessage = t([`userFeedback:${mutationName}.error`, `userFeedback:fallback.error`]);

    return client
      .mutate({
        mutation,
        variables: options,
        context: { employerId },
      })
      .then(async (res) => {
        await client.refetchQueries({
          include: refetchQueries,
        });
        const data = res.data[extractDataField(mutation)];
        if (data == null) throw new Error();

        useSuccessToast && displayToast('success', sucessTitle, successMessage);
        return data;
      })
      .catch((err) => {
        displayToast('error', errorTitle, errorMessage);
        throw err;
      });
  };

  return apolloMutation;
};
