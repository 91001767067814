import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { useApolloQueryList } from '../graphql/Query';

export const useEmployeeCardHistoricalAggregatedWages = (
  employeeId: string,
  fromPeriod: string,
  toPeriod: string,
  allowDrafts: boolean,
) => {
  const data = useApolloQueryList<AggregatedEmployeeCard>(FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES, {
    variables: {
      employeeId,
      fromPeriod,
      toPeriod,
      allowDrafts,
    },
    fetchPolicy: 'no-cache',
  });

  return data;
};
