import { Input } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import { ValidationDataType } from 'src/types/validation/ValidationDataType';
import MaxCharacterLimit from '../MaxCharacterLimit';
import './text-area.scss';

interface TextAreaProps {
  value: string;
  characterLimit?: number;
  placeholder?: string;
  validationKey?: ValidationDataType;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  small?: boolean;
}

const TextArea: FC<TextAreaProps> = ({
  value,
  characterLimit,
  placeholder,
  validationKey,
  onChange,
  small,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { error } = inputSchema.validate({ [validationKey]: value }, { abortEarly: false });

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    errorMessage && setErrorMessage(undefined);
    if (event.target.value.length <= characterLimit) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (error && error.details.length > 0 && validationKey) {
      setErrorMessage(t(`validationErrors:${error.details[0].context.key}.${error.details[0].type}`));
    }
    error ?? setErrorMessage('');
  }, [error]);

  return (
    <div className={['text-area', small ? 'text-area__small' : ''].join(' ')}>
      <Input.TextArea
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        errorMessage={errorMessage}
        {...props}
      />
      {characterLimit && (
        <div>
          <MaxCharacterLimit currentCharacters={value.length} characterLimit={characterLimit} />
        </div>
      )}
    </div>
  );
};

export default TextArea;
