import { useSubscription } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { EMPLOYEE_CARD } from 'src/graphql/schema/subscription/EmployeeCard';
import { ReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { Service } from 'src/types/Service';
import { LOADED } from 'src/types/Status';
import { handleEmployeeCardUpdate } from './HandleEmployeeCardUpdate';

export const useEmployeeCardSubscription = <T extends ReportEmployeeResponse>(
  employeeCardIds: string[],
  setResult: Dispatch<SetStateAction<Service<T[]>>>,
) => {
  const { data } = useSubscription(EMPLOYEE_CARD, {
    variables: { employeeCardIds },
    fetchPolicy: 'no-cache',
    skip: employeeCardIds.length === 0,
  });

  useEffect(() => {
    if (data !== undefined) {
      setResult((prev) => {
        return prev.status === LOADED
          ? { ...prev, payload: handleEmployeeCardUpdate(prev.payload, data.employeeCardUpdated) }
          : prev;
      });
    }
  }, [data]);
};
