import { HandleInsuranceEventRequest } from 'src/types/reporting/HandleInsuranceEventRequest';
import { useApolloMutation } from '../graphql/Mutation';
import { HANDLE_INSURANCE_EVENT } from 'src/graphql/schema/mutation/reporting/HandleInsuranceEvent';
import { GET_INSURANCE_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetInsuranceEventCheckpoints';
import { GET_INSURANCE_EVENTS } from 'src/graphql/schema/query/insurance/GetInsuranceEvents';
import { GET_INSURANCE_EVENT_COMMENTS } from 'src/graphql/schema/query/insurance/GetInsuranceEventComments';
import { GET_VERIFY_INSURANCE_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetVerifyInsuranceEventCheckpoints';

export const useHandleInsuranceEvent = () => {
  const apolloMutation = useApolloMutation();

  const handleInsuranceEvent = (employerId: string, request: HandleInsuranceEventRequest) =>
    apolloMutation(
      HANDLE_INSURANCE_EVENT,
      employerId,
      {
        request,
      },
      [
        GET_INSURANCE_EVENT_CHECKPOINTS,
        GET_INSURANCE_EVENTS,
        GET_INSURANCE_EVENT_COMMENTS,
        GET_VERIFY_INSURANCE_EVENT_CHECKPOINTS,
      ],
    );

  return handleInsuranceEvent;
};
