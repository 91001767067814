import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import CalculationButtons from './CalculationButtons';
import CommonPglCalculationsDrawer, { CommonPglCalculationsDrawerProps } from './CommonPglCalculationsDrawer';
import { UpdatePGLRuleRequest } from 'src/types/policy/request/UpdatePGLRuleRequest';
import TextInput from 'src/components/Common/TextInput';

interface MinimumReportableSettingProps extends CommonPglCalculationsDrawerProps {
  inDraft: boolean;
  minimumReportableAmount: number;
  handleUpdate: (updatePGlRuleRequest: UpdatePGLRuleRequest) => void;
  loading: boolean;
}

const MinimumReportableSetting = ({
  pglRuleName,
  onClose,
  onBackClick,
  minimumReportableAmount,
  inDraft,
  handleUpdate,
  loading,
}: MinimumReportableSettingProps) => {
  const { t } = useTranslation(['account']);
  const [value, setValue] = useState(minimumReportableAmount);
  return (
    <CommonPglCalculationsDrawer
      pglRuleName={pglRuleName}
      onClose={onClose}
      onBackClick={onBackClick}
      title={t('account:pgl-rules.drawer.reporting.title')}
      descriptions={[
        t('account:pgl-rules.drawer.reporting.reportable-changeable-amount-information-1'),
        t('account:pgl-rules.drawer.reporting.reportable-changeable-amount-information-2'),
      ]}
    >
      <ListGroup variant="inline-edit">
        <InlineEdit
          label={`${t('account:pgl-rules:drawer:reporting:reportable-changeable-amount-information-label')}*`}
          bold
        >
          <TextInput
            variant="inline-edit"
            type="number"
            validationKey="integer"
            value={`${value}`}
            onChange={(e) => setValue(Number(e.target.value))}
            disabled={!inDraft || loading}
          />
        </InlineEdit>
      </ListGroup>
      <CalculationButtons
        inDraft={inDraft}
        isCreating={false}
        handleCreateOrUpdate={() => {
          handleUpdate({ minimumReportableChangeAmount: value });
        }}
        loading={loading}
        fieldsEmptyOrInvalid={isNaN(value) || value < 0 || value == minimumReportableAmount}
      />
    </CommonPglCalculationsDrawer>
  );
};

export default MinimumReportableSetting;
