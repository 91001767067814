import { InlineEdit, Switch } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  useAddEmploymentGroupConnection,
  useRemoveEmploymentGroupConnection,
} from 'src/service/policy/EmploymentGroupConnections';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { DRAFT, PolicyState } from 'src/types/policy/PolicyEntity';

interface EmploymentGroupInlineEditProps {
  employmentGroup: PolicyEmploymentGroup;
  policyBenefit: PolicyBenefit;
  policyEntityState: PolicyState;
}
const EmploymentGroupInlineEdit: FC<EmploymentGroupInlineEditProps> = ({
  employmentGroup,
  policyBenefit,
  policyEntityState,
}) => {
  const { employerId } = useParams();
  const addEmploymentGroupConnection = useAddEmploymentGroupConnection();
  const removeEmploymentGroupConnection = useRemoveEmploymentGroupConnection();
  const handleToggle = (benefitPackageId: string, policyEmploymentGroupId: string) => {
    policyEntityState === DRAFT &&
    policyBenefit.policyEmploymentGroups.some((peg) => peg.employmentGroupId === employmentGroup.employmentGroupId)
      ? removeEmploymentGroupConnection(benefitPackageId, policyEmploymentGroupId, employerId)
      : addEmploymentGroupConnection(benefitPackageId, policyEmploymentGroupId, employerId);
  };

  return (
    <InlineEdit label={employmentGroup.employmentGroupName}>
      <Switch
        //TODO: should be disabled if policyEntityState === DRAFT, right now no disbaled prop exists
        toggled={policyBenefit.policyEmploymentGroups.some(
          (peg) => peg.employmentGroupId === employmentGroup.employmentGroupId,
        )}
        onChange={() => handleToggle(policyBenefit.benefitPackageId, employmentGroup.id)}
      />
    </InlineEdit>
  );
};

export default EmploymentGroupInlineEdit;
