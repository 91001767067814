import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import { TableHeadProps } from 'src/types/TableHeadProps';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceEventsSortField } from './InsuranceEventsSortField';

interface InsuranceEventsTableHeadProps {
  sortDirection?: SortDirection;
  sortField: InsuranceEventsSortField;
  onSortFieldChange?: (sortField: InsuranceEventsSortField) => void;
  onSortDirectionChange?: () => void;
}

function InsuranceEventsTableHead({
  sortDirection,
  sortField,
  onSortFieldChange,
  onSortDirectionChange,
}: InsuranceEventsTableHeadProps) {
  const { t } = useTranslation();

  const tableHeads: TableHeadProps[] = [
    {
      fieldName: 'insuranceProvider',
      title: t('employeeInsuranceEvents:table.head.insurance-provider'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceProvider');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'reportingType',
      title: t('employeeInsuranceEvents:table.head.reporting-type'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('reportingType');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'insuranceEventType',
      title: t('employeeInsuranceEvents:table.head.insurance-event-type'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceEventType');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'benefitType',
      title: t('employeeInsuranceEvents:table.head.benefit-type'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('benefitType');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'agreedProduct',
      title: t('employeeInsuranceEvents:table.head.agreed-product'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('agreedProduct');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'wageFilePeriod',
      title: t('employeeInsuranceEvents:table.head.wage-file-period'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('wageFilePeriod');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'eventFromDate',
      title: t('employeeInsuranceEvents:table.head.event-from-date'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('eventFromDate');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'certifyInsuranceProductCondition',
      title: t('employeeInsuranceEvents:table.head.certify-insurance-product-condition'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('certifyInsuranceProductCondition');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'eventValues',
      title: t('employeeInsuranceEvents:table.head.value'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('eventValues');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'status',
      title: t('employeeInsuranceEvents:table.head.status'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('status');
        onSortDirectionChange();
      },
    },
  ];

  return (
    <TableRow>
      {tableHeads.map((head) => (
        <Th
          key={`InsuranceEventsTableHead-${head.title}`}
          includeSortButtons
          sortDirection={sortField === head.fieldName ? sortDirection : undefined}
          handleSortDirectionChange={head.handleSortDirectionChange}
        >
          {head.title}
        </Th>
      ))}
    </TableRow>
  );
}

export default InsuranceEventsTableHead;
