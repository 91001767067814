import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { ACCOUNTING_ADJUSTMENT_RULES } from 'src/graphql/schema/query/accountingunit/AccountingAdjustmentRules';
import AccountingAdjustmentTable from './AccountingAdjustmentTable';
import { AccountingAdjustmentRule } from 'src/types/accountingunit/AccountingAdjustmentRule';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import AddAccountingAdjustmentRuleModal from './AddAccountingAdjustmentRuleModal/AddAccountingAdjustmentRuleModal';
import './accounting-adjustments-tab.scss';
import { useTranslation } from 'react-i18next';

const AccountingAdjustmentsTab = () => {
  const { employerId } = useParams();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <QueryWrapper query={ACCOUNTING_ADJUSTMENT_RULES} type="list" options={{ variables: { employerId } }}>
      {(accountingAdjustmentRules: AccountingAdjustmentRule[]) => (
        <>
          <AccountingAdjustmentTable accountingAdjustmentRules={accountingAdjustmentRules} />
          <FloatingAddButton
            data-cy="add-accounting-adjustment-button"
            label={t('economySettings:accounting-adjustment-tab.add-adjustment')}
            onClick={toggleModal}
          />
          <AddAccountingAdjustmentRuleModal open={modalOpen} onClose={toggleModal} />
        </>
      )}
    </QueryWrapper>
  );
};

export default AccountingAdjustmentsTab;
