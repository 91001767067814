import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Button,
  Card,
  CardAction,
  Checkbox,
  Modal,
  Option,
  RadioCard,
  RadioGroup,
  Select,
} from 'fgirot-k2-ui-components';
import './create-user-modal.scss';
import bankIdLogo from './BankID_logo.svg';
import frejaEID from './freja_eid.png';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { CreateExternalUser, IdentifierType } from 'src/types/user/User';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import { useCreateExternalUser } from 'src/service/user/CreateExternalUser';
import useValidateCreateUserForm from './util';
import { loggedInUserIsAdmin } from 'src/service/user/getUserFromToken';

interface CreateUserModalProps {
  open: boolean;
  onClose: () => void;
}

const CONNECTION_LIMITED = 'LIMITED';
const CONNECTION_RUNNING = 'RUNNING';

const CreateUserModal: FunctionComponent<CreateUserModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const createExternalUser = useCreateExternalUser();
  const connectionOptions = [
    { value: CONNECTION_LIMITED, label: t('permission:create-user-modal.connection-limited-label') },
    { value: CONNECTION_RUNNING, label: t('permission:create-user-modal.connection-running-label') },
  ];
  const [selectedConnection, setSelectedConnection] = useState(CONNECTION_RUNNING);
  const connectionOptionsList = createOptionsFromList(connectionOptions, 'value', 'label');

  const [createUserRequest, setCreateUserRequest] = useState<CreateExternalUser>({
    firstName: '',
    lastName: '',
    identifier: '',
    identifierType: IdentifierType.BANK_ID,
    phone: '',
    email: '',
    orderRef: '',
    validTo: '',
    admin: false,
  });

  const formHasErrors = useValidateCreateUserForm(createUserRequest, selectedConnection);

  const identifierLabel =
    createUserRequest.identifierType === IdentifierType.BANK_ID
      ? t('permission:create-user-modal.identifier-person-number-label')
      : t('permission:create-user-modal.identifier-e-id-label');

  const handleOnClose = () => {
    setCreateUserRequest({
      firstName: '',
      lastName: '',
      identifier: '',
      identifierType: IdentifierType.BANK_ID,
      phone: '',
      email: '',
      orderRef: '',
      validTo: '',
      admin: false,
    });
    setSelectedConnection(CONNECTION_RUNNING);
    onClose();
  };

  const handleUpdateProperty = (event: ChangeEvent<HTMLInputElement>, key: keyof CreateExternalUser) =>
    setCreateUserRequest((prev) => ({ ...prev, [key]: event.target.value }));

  const handleIdentifierType = (input: IdentifierType) =>
    setCreateUserRequest((prev) => {
      return { ...prev, identifierType: input };
    });

  const handleSelectConnectionChange = (option: Option<string>) => {
    if (option.value === CONNECTION_RUNNING) {
      setCreateUserRequest((prev) => {
        const validTo = option.value === CONNECTION_RUNNING ? '' : prev.validTo;
        return { ...prev, validTo };
      });
    }
    setSelectedConnection(option.value);
  };
  const handleValidToOnChange = (value: string) => {
    setCreateUserRequest((prev) => {
      return { ...prev, validTo: value };
    });
  };

  const handleIsAdmin = (value: boolean) => {
    setCreateUserRequest((prev) => {
      return { ...prev, admin: value };
    });
  };

  const handleCreateUser = () => {
    if (!formHasErrors) {
      let request = createUserRequest;
      if (selectedConnection === CONNECTION_RUNNING) {
        request = { ...createUserRequest, validTo: null };
      } else {
        const date = new Date(request.validTo);
        request = { ...createUserRequest, validTo: date.toISOString() };
      }
      createExternalUser(request);
      handleOnClose();
    }
  };

  return (
    <Modal overlayBackground centered onClose={handleOnClose} open={open}>
      <Card title={t('permission:create-user-modal.title')}>
        <div className="create-user-modal" data-testid="create-user-modal">
          <RadioGroup
            className="create-user-modal__radio-group"
            label={t('permission:create-user-modal.identification-label')}
            selected={createUserRequest.identifierType}
            onChange={handleIdentifierType}
            card
          >
            <RadioCard
              value={IdentifierType.BANK_ID}
              image={<img src={bankIdLogo} />}
              label={t('identifierTypes:BANK_ID')}
            />
            <RadioCard value={IdentifierType.E_ID} image={<img src={frejaEID} />} label={t('identifierTypes:E_ID')} />
          </RadioGroup>
          <TextInput
            label={identifierLabel}
            type="text"
            className="create-user-modal__identifier"
            validationKey={createUserRequest.identifierType === IdentifierType.BANK_ID ? 'personNumber' : 'expisoft'}
            variant="default"
            value={createUserRequest.identifier}
            onChange={(e) => handleUpdateProperty(e, 'identifier')}
            mandatory
            data-testid="input-identifier"
          />
          <div className="create-user-modal__grid">
            <TextInput
              label={t('common:first-name')}
              type="text"
              validationKey="text255"
              variant="default"
              value={createUserRequest.firstName}
              onChange={(e) => handleUpdateProperty(e, 'firstName')}
              mandatory
              data-testid="input-first-name"
            />
            <TextInput
              label={t('common:last-name')}
              type="text"
              validationKey="text255"
              variant="default"
              value={createUserRequest.lastName}
              onChange={(e) => handleUpdateProperty(e, 'lastName')}
              mandatory
              data-testid="input-last-name"
            />
            <TextInput
              label={t('permission:create-user-modal.phone-label')}
              type="tel"
              validationKey="phoneNumber"
              variant="default"
              value={createUserRequest.phone}
              onChange={(e) => handleUpdateProperty(e, 'phone')}
              mandatory
              data-testid="input-phone"
            />
            <TextInput
              label={t('common:email')}
              type="email"
              validationKey="email"
              variant="default"
              maxLength={255}
              value={createUserRequest.email}
              onChange={(e) => handleUpdateProperty(e, 'email')}
              mandatory
              data-testid="input-email"
            />
          </div>

          <TextInput
            label={t('permission:create-user-modal.order-ref-label')}
            className="create-user-modal__reason"
            type="text"
            validationKey="text255"
            variant="default"
            value={createUserRequest.orderRef}
            onChange={(e) => handleUpdateProperty(e, 'orderRef')}
            mandatory
            data-testid="input-order-ref"
          />
          <div className="create-user-modal__grid" data-testid="connection-wrapper">
            <Select
              allowSelectMaxWidth
              className="create-user-modal__grid__select"
              label={t('permission:create-user-modal.connection-label')}
              selected={connectionOptionsList.find((option) => option.value === selectedConnection)}
              options={connectionOptionsList}
              onChange={handleSelectConnectionChange}
              placeholder={t('common:select')}
              data-testid="select"
            />

            <UpdateDatePicker
              label={t('permission:create-user-modal.valid-to-label')}
              fieldValue={createUserRequest.validTo}
              fieldKey="paymentDueDate"
              disabled={selectedConnection !== CONNECTION_LIMITED}
              onBlur={(_, value) => {
                handleValidToOnChange(value);
              }}
              data-testid="date-picker"
            />
          </div>
          {loggedInUserIsAdmin() && (
            <Checkbox
              checked={createUserRequest.admin}
              onChange={(e) => handleIsAdmin(e.target.checked)}
              label={t('permission:create-user-modal.admin-checkbox-label')}
            />
          )}
          <CardAction>
            <Button onClick={handleOnClose} type="link" label={t('common:cancel')} data-testid="cancel-button" />
            <Button
              label={t('common:add')}
              disabled={formHasErrors}
              onClick={handleCreateUser}
              data-testid={`submit-button-${formHasErrors ? 'disabled' : 'active'}`}
            />
          </CardAction>
        </div>
      </Card>
    </Modal>
  );
};

export default CreateUserModal;
