import { gql } from '@apollo/client';

export const DELETE_EMPLOYMENT_GROUP_RULE_BLOCK = gql`
  mutation DeleteEmploymentGroupRuleBlock($ruleBlockId: UUID!) {
    deleteEmploymentGroupRuleBlock(ruleBlockId: $ruleBlockId) {
      id
    }
  }
`;

export const DELETE_BENEFIT_RULE_BLOCK = gql`
  mutation DeleteBenefitRuleBlock($ruleBlockId: UUID!) {
    deleteBenefitRuleBlock(ruleBlockId: $ruleBlockId) {
      id
    }
  }
`;
