import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InlineEdit, ListGroup, Switch } from 'fgirot-k2-ui-components';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { GroupedAffiliationRules } from 'src/types/policy/GroupedAffiliationRules';
import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { RuleValue } from 'src/types/policy/RuleValue';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';

interface ToggleRuleComponentProps<T> {
  policyState: PolicyState;
  selectedAffiliationRuleGroup: GroupedAffiliationRules;
  policyRuleType: PolicyRuleType;
  onUpdateOrDeleteAffiliationRule: (request: UpdateOrDeleteAffiliationRuleRequest) => void;
  labelResource: string;
  values: T[];
}

const ToggleRuleComponent = <T extends string>({
  policyState,
  selectedAffiliationRuleGroup,
  policyRuleType,
  onUpdateOrDeleteAffiliationRule,
  labelResource,
  values,
}: ToggleRuleComponentProps<T>) => {
  const { t } = useTranslation();
  const getCurrentRuleValues = () => selectedAffiliationRuleGroup.affiliationRules.flatMap((rule) => rule.ruleValues);

  const handleToggle = (value: T, event: ChangeEvent<HTMLInputElement>) => {
    if (policyState === 'DRAFT') {
      const ruleValues: RuleValue[] = event.target.checked
        ? [
            ...getCurrentRuleValues(),
            {
              value,
              valueType: 'EQUALS',
            },
          ]
        : getCurrentRuleValues().filter((ruleValue) => ruleValue.value !== value);

      const selectedPolicyRule = selectedAffiliationRuleGroup.affiliationRules.find((r) => r);
      onUpdateOrDeleteAffiliationRule({ ruleId: selectedPolicyRule?.id, policyRuleType, ruleValues });
    }
  };

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant={'inline-edit'}>
        {values.map((value: T, index: number) => {
          return (
            <InlineEdit bold key={`${labelResource}-${value}-${index}`} label={t(`${labelResource}:${value}`)}>
              <Switch
                toggled={getCurrentRuleValues().some((ruleValue) => ruleValue.value === value)}
                onChange={(event) => handleToggle(value, event)}
                data-cy={`toggle-rule-component__switch-${value}`}
              />
            </InlineEdit>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default ToggleRuleComponent;
