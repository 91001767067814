import React from 'react';
import {
  BENEFITS,
  CALCULATIONS,
  ChangedObjectType,
  GROUPS,
  PolicyVersionChanges,
} from 'src/types/policy/PolicyVersionChanges';
import { DrawerNavigation, ListGroup } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';

interface PolicyChangesNaviationProps {
  changes: PolicyVersionChanges;
  handleSelectChanges: (type: ChangedObjectType) => void;
}

const PolicyChangesNavigation: React.FunctionComponent<PolicyChangesNaviationProps> = ({
  changes,
  handleSelectChanges,
}) => {
  const { t } = useTranslation();
  return (
    changes && (
      <ListGroup variant={'drawer-navigation'}>
        <DrawerNavigation
          label={t('account:employers-tab.policy-settings.calculations')}
          value={changes.changedPglRules.length.toString()}
          onClick={() => handleSelectChanges(CALCULATIONS)}
        ></DrawerNavigation>
        <DrawerNavigation
          label={t('account:employers-tab.policy-settings.groups')}
          value={changes.changedEmploymentGroups.length.toString()}
          onClick={() => handleSelectChanges(GROUPS)}
        ></DrawerNavigation>
        <DrawerNavigation
          label={t('account:employers-tab.policy-settings.benefits')}
          value={changes.changedBenefits.length.toString()}
          onClick={() => handleSelectChanges(BENEFITS)}
        ></DrawerNavigation>
      </ListGroup>
    )
  );
};

export default PolicyChangesNavigation;
