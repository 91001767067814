import { TableHead, TableRow, TableBody, Td, SortDirection } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { Invoice } from 'src/types/invoice/Invoice';
import { useNavigate } from 'react-router-dom';
import InvoiceStatusTd from './InvoiceStatusTd/InvoiceStatusTd';
import { InvoiceTableSortField } from './InvoiceTableSortField';
import InvoiceTableHeads from './InvoiceTableHeads';
import { formatSweAmountTextAlwaysWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';

interface InvoicesTableProps {
  invoices: Invoice[];
  sortingProperty?: InvoiceTableSortField;
  sortDirection?: SortDirection;
  applySorting(property: string, direction: SortDirection);
}

const InvoicesTable: FC<InvoicesTableProps> = ({ invoices, sortingProperty, sortDirection, applySorting }) => {
  const navigate = useNavigate();

  const handleSort = (property: InvoiceTableSortField) => {
    if (property == sortingProperty) {
      applySorting(property, sortDirection == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sortDirection);
    }
  };

  return (
    <TableBase maxHeightPercentage={65}>
      <TableHead filled>
        <InvoiceTableHeads sortField={sortingProperty} sortDirection={sortDirection} onApplySorting={handleSort} />
      </TableHead>
      <TableBody>
        {invoices.map((invoice, index) => {
          const handleSelectInvoice = () => navigate(`/economy/invoices/${invoice.id}`);
          return (
            <TableRow key={`invoice-table-row-${invoice.id}-${index}`} onClick={handleSelectInvoice}>
              <Td>{stringOrBlank(invoice.insuranceProvider?.legalName)}</Td>
              <Td>{stringOrBlank(invoice.agreementNumber)}</Td>
              <Td>{stringOrBlank(invoice.invoiceNumber)}</Td>
              <Td>{stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(invoice.totalAmount))}</Td>
              <Td>{stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(invoice.remainingAmount))}</Td>
              <Td>{stringOrBlank(invoice.ocrNumber)}</Td>
              <Td>{stringOrBlank(invoice.paymentDueDate)}</Td>
              <InvoiceStatusTd invoice={invoice} />
            </TableRow>
          );
        })}
      </TableBody>
    </TableBase>
  );
};

export default InvoicesTable;
