import { Banner, Button, Switch, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useEffect, useState } from 'react';
import {
  AccountingComponent,
  AccountingComponentFlat,
  AccountingComponentInput,
} from 'src/types/accountingunit/AccountingComponent';
import { arrangeFlatArrayAsTree } from 'src/util/ArrayUtil';
import { recursiveHasGoodShare } from 'src/util/AccountingComponentUtil';
import AccountingComponentAccordion from './AccountingComponentAccordion';
import { useTranslation } from 'react-i18next';
import AddAccountingComponentModal from './AddAccountingComponentModal';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import { useParams } from 'react-router-dom';
import { UpdateAccountingTypeComponentsInput } from 'src/types/accountingunit/AccountingType';
import { useUpdateAccountingTypeComponents } from 'src/service/accountingunit/AccountingType';
import { refetchQuery } from 'src/service/graphql/Query';
import { ACCOUNTING_TYPE } from 'src/graphql/schema/query/accountingunit/AccountingType';

interface AccountingComponentSectionProps {
  accountingComponents: AccountingComponentFlat[];
}

const AccountingComponentSection: FC<AccountingComponentSectionProps> = ({ accountingComponents: ac }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [accountingComponents, setAccountingComponents] = useState<AccountingComponentFlat[]>(ac);
  const [arrangedAccountingComponents, setArrangedAccountingComponents] = useState<AccountingComponent[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editAccountingComponents, setEditAccountingComponents] = useState(false);
  const toggleEditAccountingComponents = () => setEditAccountingComponents((prev) => !prev);
  const { accountingTypeId } = useParams();
  const updateAccountingTypeComponents = useUpdateAccountingTypeComponents();
  const toggleModal = () => setModalOpen((prev) => !prev);

  const addNewAccountingComponent = (newComponent: AccountingComponentFlat) => {
    setAccountingComponents((prev) => {
      const parent = prev.find((component) => component.id === newComponent.parentId);
      return parent
        ? [
            ...prev.filter((component) => component.id !== parent?.id),
            { ...parent, accountingComponents: [...parent.accountingComponents, newComponent.id] },
            newComponent,
          ]
        : [...prev, { ...newComponent, parentId: null }];
    });
  };

  const deleteAccountingComponent = (accountingComponentId: string) => {
    setAccountingComponents((prev) => {
      const componentToRemove = prev.find((component) => component.id === accountingComponentId);
      return [
        ...prev
          .map((component) => ({
            ...component,
            accountingComponents: component.accountingComponents.filter((childId) => childId !== componentToRemove.id),
          }))
          .filter((component) => component.id !== accountingComponentId),
      ];
    });
  };

  const updateAccountingComponentShare = (accountingComponentId: string, share: number) => {
    setAccountingComponents((prev) => [
      ...prev.map((component) => (component.id === accountingComponentId ? { ...component, share } : component)),
    ]);
  };

  const handleSaveAccountingTypeComponents = () => {
    const request: UpdateAccountingTypeComponentsInput = {
      accountingTypeId,
      accountingComponents: getInputComponent(arrangedAccountingComponents),
    };
    updateAccountingTypeComponents(request, employerId).then(toggleEditAccountingComponents);
  };
  const getInputComponent = (accountingComponents: AccountingComponent[]): AccountingComponentInput[] =>
    accountingComponents.map((component) => ({
      id: component.id,
      accountingComponentValueId: component.accountingComponentValueId,
      share: component.share,
      accountingComponents: getInputComponent(component.accountingComponents),
    }));
  const handleEditCancel = () => {
    refetchQuery(ACCOUNTING_TYPE);
    toggleEditAccountingComponents();
    setAccountingComponents(ac);
  };
  useEffect(() => {
    setArrangedAccountingComponents(
      arrangeFlatArrayAsTree<AccountingComponentFlat, AccountingComponent>(
        accountingComponents,
        'accountingComponents',
      ),
    );
  }, [accountingComponents]);

  return (
    <div className="accounting-type-page__container">
      {arrangedAccountingComponents && !recursiveHasGoodShare(arrangedAccountingComponents) && (
        <Banner
          className="accounting-type-page__container--share-banner"
          message={t('economySettings:accounting-type-page.wrong-share-banner')}
          type="warning"
        />
      )}
      <Typography variant="h2">{t('economySettings:accounting-type-page.accounting-components')}</Typography>
      <div className="accounting-type-page__container__edit">
        <div className="accounting-type-page__container__edit--switch">
          <Typography variant="caption">Lås upp för att editera</Typography>
          <Switch toggled={editAccountingComponents} onChange={toggleEditAccountingComponents} />
        </div>
        <div className="accounting-type-page__container__edit--buttons">
          <Button
            disabled={
              !editAccountingComponents ||
              (arrangedAccountingComponents && !recursiveHasGoodShare(arrangedAccountingComponents))
            }
            onClick={handleSaveAccountingTypeComponents}
            label={t('economySettings:accounting-type-page.save-changes')}
          />
          <Button
            type="secondary"
            onClick={handleEditCancel}
            disabled={!editAccountingComponents}
            label={t('common:cancel')}
          />
        </div>
      </div>
      {arrangedAccountingComponents.map((accountingComponent) => (
        <AccountingComponentAccordion
          isEditable={editAccountingComponents}
          addNewAccountingComponent={addNewAccountingComponent}
          deleteAccountingComponent={deleteAccountingComponent}
          updateAccountingComponentShare={updateAccountingComponentShare}
          key={`AccountingComponentAccordion-${accountingComponent.id}`}
          accountingComponent={accountingComponent}
        />
      ))}
      {accountingComponents && accountingComponents.length === 0 && (
        <Typography>{t('economySettings:accounting-type-page.no-accounting-component')}</Typography>
      )}
      <FloatingAddButton
        label={t('economySettings:accounting-type-page.add-accounting-component')}
        onClick={toggleModal}
        disabled={!editAccountingComponents}
      />
      <AddAccountingComponentModal
        createAccountingComponent={addNewAccountingComponent}
        open={modalOpen}
        onClose={toggleModal}
        parentId={accountingTypeId}
        parentType={'ACCOUNTING_TYPE'}
      />
    </div>
  );
};

export default AccountingComponentSection;
