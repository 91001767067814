import React, { useEffect } from 'react';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import './policy-version-header.scss';
import PolicyActions from './PolicyActions';
import PolicyState from './PolicyState';
import PolicyStateBanner from './PolicyStateBanner/PolicyStateBanner';
import { useNavigate, useParams } from 'react-router-dom';
import { getPreSelectedPolicyId } from 'src/util/PolicyUtil';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_POLICY_VERSION_CHANGES } from 'src/graphql/schema/query/policy/GetPolicyVersionChanges';
import { PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';

interface PolicyVersionHeaderProps {
  policyEntities: PolicyEntity[];
}

const PolicyVersionHeader = ({ policyEntities }: PolicyVersionHeaderProps) => {
  const { customerName, employerId, policyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    !policyId &&
      navigate(`/account/${customerName}/${employerId}/policy-settings/${getPreSelectedPolicyId(policyEntities)}`);
  }, [policyId]);

  const selectedPolicyEntity = policyEntities.find((entity) => entity.id === policyId);

  return (
    <QueryWrapper query={GET_POLICY_VERSION_CHANGES} options={{ variables: { policyId } }}>
      {(changes: PolicyVersionChanges) => (
        <>
          <div className={['policy-version-header'].join(' ')}>
            {selectedPolicyEntity && <PolicyState selectedPolicyEntity={selectedPolicyEntity} changes={changes} />}
            <PolicyActions
              selectedPolicyEntity={selectedPolicyEntity}
              policyEntities={policyEntities}
              changes={changes}
            />
          </div>
          {selectedPolicyEntity && <PolicyStateBanner policyState={selectedPolicyEntity.state} />}
        </>
      )}
    </QueryWrapper>
  );
};

export default PolicyVersionHeader;
