import KeycloakInstance from 'src/keycloak';
import jwtDecode from 'jwt-decode';

interface AccessToken {
  customer_id: string;
}

export const getCustomerId = (): string => {
  const token = KeycloakInstance.token;
  if (token) {
    const decodedToken: AccessToken = jwtDecode(token);
    return decodedToken.customer_id;
  }
  return undefined;
};
