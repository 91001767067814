import React, { FC } from 'react';
import { Drawer, Typography } from 'fgirot-k2-ui-components';
import './drawer-base.scss';

interface DrawerBaseProps {
  title?: JSX.Element | string;
  subTitle?: JSX.Element | string;
  onClose: () => void;
  leftButton?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
}

const DrawerBase: FC<DrawerBaseProps> = ({ title, subTitle, onClose, leftButton, children }) => {
  return (
    <Drawer leftButton={leftButton} type="elements" onClose={onClose}>
      <div className="drawer-base__title-container">
        {title &&
          (typeof title === 'string' ? (
            <Typography variant="h3" component="h3" bold>
              {title}
            </Typography>
          ) : (
            title
          ))}
        {subTitle && (typeof subTitle === 'string' ? <Typography variant="body2">{subTitle}</Typography> : subTitle)}
      </div>
      <>{children}</>
    </Drawer>
  );
};

export default DrawerBase;
