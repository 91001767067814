import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_INSURANCE_EVENTS } from '../../graphql/schema/query/employee/GetEmployeeInsuranceEvents';
import { PATCH_INSURANCE_EVENT } from '../../graphql/schema/mutation/reporting/PatchInsuranceEvent';

export const usePatchInsuranceEvent = () => {
  const apolloMutation = useApolloMutation(false);

  return (employerId: string, insuranceEventId: string, date: string) =>
    apolloMutation(
      PATCH_INSURANCE_EVENT,
      employerId,
      {
        request: {
          insuranceEventId,
          fieldKey: 'eventFromDate',
          fieldValue: date,
        },
      },
      [GET_EMPLOYEE_INSURANCE_EVENTS],
    );
};
