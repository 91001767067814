import { UPSERT_EMPLOYER_WAGE_FILE_SETTING } from 'src/graphql/schema/mutation/employer/UpsertEmployerWageFileSetting';
import { EmployerWageFileSettingRequest } from 'src/types/employer/request/EmployerWageFileSettingRequest';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYER_WAGE_FILE_SETTING } from 'src/graphql/schema/query/employer/GetEmployerWagFileSetting';

export const useUpsertEmployerWageFileSetting = () => {
  const apolloMutation = useApolloMutation();

  const upsertEmployerWageFileSetting = (employerId: string, request: EmployerWageFileSettingRequest) =>
    apolloMutation(UPSERT_EMPLOYER_WAGE_FILE_SETTING, employerId, { employerId, request }, [
      GET_EMPLOYER_WAGE_FILE_SETTING,
    ]);

  return upsertEmployerWageFileSetting;
};
