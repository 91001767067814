import { InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import './agreement-settings-tab.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import UpdateInput from 'src/components/Common/UpdateInput';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { UpdateInsuranceProviderContractRequest } from 'src/types/contract/request/UpdateInsuranceProviderContractRequest';
import { useParams } from 'react-router-dom';
import { useUpdateInsuranceProviderContract } from 'src/service/contract/UpdateInsuranceProviderContract';
import { ContractSetting } from 'src/types/contract/ContractSetting';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_CONTRACT_SETTING } from 'src/graphql/schema/query/contract/GetContractSetting';
import CollectumTglSetting from './CollectumTglSetting';
import When from 'src/components/Common/When';

interface AgreementSettingsTabProps {
  contract: InsuranceProviderContract;
}

const AgreementSettingsTab: FC<AgreementSettingsTabProps> = ({ contract }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const updateInsuranceProviderContract = useUpdateInsuranceProviderContract();

  const handleBillingIntervalChange = (field: string, value: string) => {
    const request: UpdateInsuranceProviderContractRequest = {
      insuranceProviderContractId: contract.id,
      invoiceInterval: parseInt(value),
    };
    updateInsuranceProviderContract(request, employerId).catch(() => {
      alert('Error when updating invoice interval');
    });
  };

  const insuranceProviderIsCollectum = contract?.insuranceProvider?.legalName?.toLowerCase() === 'collectum';

  return (
    <div className="agreement-settings-tab">
      <Typography bold variant="body1" component="label">
        {t('agreementRegistrySettings:billing-interval')}
      </Typography>
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('agreementRegistrySettings:number-of-months')} bold>
          <UpdateInput
            placeholder={t('agreementRegistrySettings:placeholder-months-input')}
            type="number"
            validationKey="integer"
            fieldKey={contract?.invoiceInterval?.toString()}
            fieldValue={contract?.invoiceInterval?.toString()}
            onBlur={handleBillingIntervalChange}
          />
        </InlineEdit>
      </ListGroup>
      <When condition={insuranceProviderIsCollectum}>
        <QueryWrapper
          query={GET_CONTRACT_SETTING}
          options={{
            variables: { employerId: employerId, key: 'COLLECTUM_TGL', insuranceProviderContractId: contract.id },
          }}
        >
          {(contractSetting: ContractSetting) => (
            <CollectumTglSetting contractSetting={contractSetting} insuranceProviderContractId={contract.id} />
          )}
        </QueryWrapper>
      </When>
    </div>
  );
};
export default AgreementSettingsTab;
