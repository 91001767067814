import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { useState } from 'react';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import AgreedProductInputs from '../../AgreedProductInputs/AgreedProductInputs';
import { Button, Icon } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ContractAgreedProduct } from 'src/types/contract/ContractAgreedProduct';
import {
  agreedProductRequestIsValid,
  hasAgreedProductRequestChangedFromAgreedProduct,
} from 'src/util/ContractAgreedProductUtil';

interface AgreedProductEditableFieldsProps {
  contract: InsuranceProviderContract;
  agreedProduct: ContractAgreedProduct;
  onUpdateAgreedProduct: (request: ContractAgreedProductRequest) => void;
  onDeleteAgreedProduct: (agreedProductId: string) => void;
}

const AgreedProductEditableFields = ({
  contract,
  agreedProduct,
  onUpdateAgreedProduct,
  onDeleteAgreedProduct,
}: AgreedProductEditableFieldsProps) => {
  const { t } = useTranslation();

  const [agreedProductRequest, setAgreedProductRequest] = useState<ContractAgreedProductRequest>({
    name: agreedProduct.name,
    insuranceProductId: agreedProduct.insuranceProduct?.id ?? null,
    reportingRuleConfigurationId: agreedProduct.reportingRuleConfiguration?.id ?? null,
    insuranceCategory: agreedProduct.insuranceProviderProductCategory ?? null,
    agreementProductCode: agreedProduct.agreementProductCode,
    sellingParty: null,
    certifyInsuranceProductCondition: agreedProduct.certifyInsuranceProductCondition ?? false,
    certifyInsuranceProductConditionId: agreedProduct.certifyInsuranceProductConditionId ?? null,
    defaultReportingDetailLevel: agreedProduct.defaultReportingDetailLevel ?? null,
  });

  const updateButtonDisabled =
    !agreedProductRequestIsValid(agreedProductRequest) ||
    !hasAgreedProductRequestChangedFromAgreedProduct(agreedProductRequest, agreedProduct);

  const handleChange = (key: string, value: string) => {
    setAgreedProductRequest((prev) => {
      return { ...prev, [key]: value };
    });
  };

  return (
    <>
      <AgreedProductInputs contract={contract} agreedProductRequest={agreedProductRequest} onChange={handleChange} />
      <div className="agreed-product-details__buttons">
        <Button
          className="agreed-product-details__button"
          icon={<Icon type="Trash" />}
          type="secondary"
          label={t('agreementRegistrySettings:drawer:remove-agreed-product')}
          onClick={() => onDeleteAgreedProduct(agreedProduct.id)}
          data-cy="agreed-product-delete-button"
        />
        <Button
          className="agreed-product-details__button"
          type="primary"
          label={t('agreementRegistrySettings:drawer:update-agreed-product')}
          onClick={() => onUpdateAgreedProduct(agreedProductRequest)}
          disabled={updateButtonDisabled}
          data-cy="agreed-product-update-button"
        />
      </div>
    </>
  );
};

export default AgreedProductEditableFields;
