import { gql } from '@apollo/client';

export const UPDATE_EMPLOYER_INDIVIDUAL_CHOICE = gql`
  mutation UpdateEmployerIndividualChoice($request: UpdateEmployerIndividualChoiceRequest!) {
    updateEmployerIndividualChoice(request: $request) {
      id
      type
      fromWageFile
      fromInvoice
    }
  }
`;
