import { useTranslation } from 'react-i18next';
import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { GetGroup, GroupRole } from 'src/types/group/Group';

interface GroupsTableRowProps {
  group: GetGroup;
  onClick: (userId: string) => void;
}

const GroupsTableRow: FC<GroupsTableRowProps> = ({ group, onClick }) => {
  const { t } = useTranslation();

  const getTranslation = (roleName: string) => {
    return t(`rolesTranslations:${roleName}`);
  };

  const formatRoleString = (roles: GroupRole[]): string => {
    if (roles.length) {
      const displayedName = getTranslation(roles[0].name);
      const remainingRoles = roles.slice(1);
      if (remainingRoles.length) {
        return `${displayedName} + ${remainingRoles.length}`;
      }
      return displayedName;
    }
    return '-';
  };
  return (
    <TableRow onClick={() => onClick(group.id)} data-testid={`groups-table-row-${group.id}`}>
      <Td>{group.name}</Td>
      <Td>{group.employers.length}</Td>
      <Td>{group.users.length}</Td>
      <Td>{formatRoleString(group.roles)}</Td>
    </TableRow>
  );
};

export default GroupsTableRow;
