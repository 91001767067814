import { TableRow, Td, Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ControlPointRuleValue, EmployerControlPointRule } from 'src/types/employer/EmployerControlPointRule';
import './employment-control-point-row.scss';

interface EmploymentControlPointRowProps {
  rule: EmployerControlPointRule;
}

const EmploymentControlPointRow = ({ rule }: EmploymentControlPointRowProps) => {
  const { t } = useTranslation();

  const buildRuleSentence = (ruleValues: ControlPointRuleValue[]): string => {
    const sentence = ruleValues
      .map(
        (ruleValue) =>
          t(`employmentControlPointRules:rule-value-type:${ruleValue.valueType}`) +
          ` ${ruleValue.value} ` +
          (rule.ruleValueUnit ? t(`employmentControlPointRules:unit:${rule.ruleValueUnit}`) : ''),
      )
      .join(', ');

    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  };

  return (
    <TableRow>
      <Td>
        <Pill
          className="employment-control-point-row__pill--margin"
          label={t(`subActionCategories:${rule.ruleName}`)}
          type={'heads-up'}
        />
      </Td>
      <Td>{t(`employmentControlPointRules:rule-name:${rule.ruleName}`)}</Td>
      <Td>
        {/* TODO: dessa kollar bara på första regeln */}
        {buildRuleSentence(rule.ruleValues)}
      </Td>
    </TableRow>
  );
};

export default EmploymentControlPointRow;
