import { InlineEdit, ListGroup, Select, Typography, Option, Switch, Button } from 'fgirot-k2-ui-components';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateOrUpdateEmployerPayType } from 'src/service/wagetype/CreateOrUpdateEmployerPayType';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import WageTypeKubTypeMappingInput from './WageTypeKubMappingInput';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_EMPLOYER_KUBS } from 'src/graphql/schema/query/employer/GetEmployerKubs';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { isHourlySalary, payTypeRequestHasChanged, payTypeRequestIsCorrectlyMapped } from 'src/util/WageUtil';
import { CreateOrUpdateEmployerPayType } from 'src/types/CreateOrUpdateEmployerPayType';
import InfoMessage from 'src/components/Common/InfoMessage';
import './pay-type-mapping-tab.scss';
import When from 'src/components/Common/When';

interface MappingTabProps {
  employerId: string;
  payTypeNumber?: string;
  payTypeName?: string;
  employerPayType: EmployerPayType;
  employerWageTypes: EmployerWageType[];
  onBackClick?: () => void;
}

const MappingTab: FunctionComponent<MappingTabProps> = ({
  employerId,
  employerPayType,
  payTypeName,
  payTypeNumber,
  employerWageTypes,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const createOrUpdatePayType = useCreateOrUpdateEmployerPayType();
  const filteredEmployerWageTypes = employerWageTypes?.filter((wageType) => wageType?.wageType !== 'GROSS_SALARY');
  const [loading, setLoading] = useState<boolean>(false);

  const employerWageTypeOptions = filteredEmployerWageTypes?.map((wt) => ({
    label: wt.name,
    value: wt,
  }));

  const [payTypeRequest, setPayTypeRequest] = useState<CreateOrUpdateEmployerPayType>({
    id: employerPayType?.id,
    employerWageTypeId: employerPayType?.employerWageType?.id || employerWageTypeOptions[0]?.value?.id,
    number: employerPayType?.number || payTypeNumber,
    name: employerPayType?.name || payTypeName,
    inUse: employerPayType ? employerPayType.inUse : true,
    useQuantity: employerPayType ? employerPayType.useQuantity : true,
    kubTypeMappings: employerPayType?.employerKubs.map((kub) => kub.kubType) || [],
  });

  const selectedWageType = employerWageTypes?.find((wt) => wt.id === payTypeRequest?.employerWageTypeId);
  const notMappedWageType = employerWageTypes?.find((wt) => wt.wageType === 'NOT_MAPPED');

  const handleSelectWageType = (option: Option<EmployerWageType>) =>
    updatePayTypeRequest('employerWageTypeId', option.value.id);

  const handleToggleInUse = (event: ChangeEvent<HTMLInputElement>) =>
    updatePayTypeRequest('inUse', event.target.checked);

  const handleToggleUseQuantity = (event: ChangeEvent<HTMLInputElement>) =>
    updatePayTypeRequest('useQuantity', event.target.checked);

  const handleUpdateKubMapping = (type: 'ADD' | 'REMOVE', kubType: string) => {
    const kubMappings =
      type === 'ADD'
        ? [...payTypeRequest.kubTypeMappings, kubType]
        : payTypeRequest.kubTypeMappings.filter((kub) => kub !== kubType);
    updatePayTypeRequest('kubTypeMappings', kubMappings);
  };

  const updatePayTypeRequest = <K extends keyof CreateOrUpdateEmployerPayType>(
    key: K,
    value: CreateOrUpdateEmployerPayType[K],
  ) => {
    setPayTypeRequest({
      ...payTypeRequest,
      [key]: value,
    });
  };

  const handleCreateOrUpdatePayType = async () => {
    setLoading(true);
    await createOrUpdatePayType(employerId, payTypeRequest);
    onBackClick && onBackClick();
  };

  useEffect(() => {
    if (
      !payTypeRequest.inUse &&
      (payTypeRequest.employerWageTypeId !== notMappedWageType.id || payTypeRequest.kubTypeMappings.length > 0)
    ) {
      setPayTypeRequest({
        ...payTypeRequest,
        employerWageTypeId: notMappedWageType.id,
        kubTypeMappings: [],
      });
    }

    if (!isHourlySalary(selectedWageType?.wageType) && payTypeRequest.useQuantity === false) {
      updatePayTypeRequest('useQuantity', true);
    }
  }, [payTypeRequest]);

  const canBeSaved =
    payTypeRequestIsCorrectlyMapped(payTypeRequest, notMappedWageType) &&
    payTypeRequestHasChanged(employerPayType, payTypeRequest);

  return (
    <div className="pay-type-mapping-tab">
      <section>
        <div className="pay-type-mapping-tab__title">
          <div className="pay-type-mapping-tab__status">
            <Typography className="pay-type-mapping-tab__text" variant="subtitle" bold>
              {t('account:employers-tab.wage-type-mapping-settings.in-use:title')}
            </Typography>
          </div>
        </div>
        <ListGroup variant="inline-edit">
          <InlineEdit
            label={t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.in-use-subtitle')}
            bold
          >
            <Switch toggled={payTypeRequest.inUse} onChange={handleToggleInUse} />
          </InlineEdit>
        </ListGroup>
        <InfoMessage message={t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.mapping-info')} />
      </section>
      <When condition={payTypeRequest.inUse}>
        <section>
          <div className="pay-type-mapping-tab__title">
            <Typography className="pay-type-mapping-tab__text" variant="subtitle" bold>
              {t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.wage-type')}
            </Typography>
            <Typography className="pay-type-mapping-tab__text" variant="caption">
              {t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.wage-type-subtitle')}
            </Typography>
          </div>
          <ListGroup variant="inline-edit">
            <InlineEdit bold label={t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.wage-type')}>
              <Select
                data-cy="wage-type-select"
                className="update-wage-type-select"
                options={employerWageTypeOptions}
                selected={employerWageTypeOptions?.find((opt) => opt.value?.id === payTypeRequest?.employerWageTypeId)}
                onChange={handleSelectWageType}
                small
              />
            </InlineEdit>
          </ListGroup>
        </section>
        <When condition={isHourlySalary(selectedWageType?.wageType)}>
          <section>
            <ListGroup variant="inline-edit">
              <InlineEdit bold label={t('account:employers-tab.wage-type-mapping-settings.use-quantity-label')}>
                <Switch
                  data-cy="use-quantity-switch"
                  toggled={payTypeRequest?.useQuantity}
                  onChange={handleToggleUseQuantity}
                />
              </InlineEdit>
            </ListGroup>
          </section>
        </When>
        <section>
          <div className="pay-type-mapping-tab__title">
            <Typography className="pay-type-drawer__tab__text" variant="subtitle" bold>
              {t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.kub')}
            </Typography>
            <Typography className="pay-type-drawer__tab__text" variant="caption">
              {t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.kub-subtitle')}
            </Typography>
          </div>
          <ListGroup variant="inline-edit">
            <QueryWrapper
              query={GET_EMPLOYER_KUBS}
              options={{ variables: { employerId }, skip: !employerId }}
              type="list"
            >
              {(employerKubs: EmployerKub[]) => (
                <>
                  {employerKubs.map((employerKub) => (
                    <WageTypeKubTypeMappingInput
                      key={employerKub?.id}
                      employerKub={employerKub}
                      employerPayType={employerPayType}
                      updateKubMapping={handleUpdateKubMapping}
                    />
                  ))}
                </>
              )}
            </QueryWrapper>
          </ListGroup>
        </section>
      </When>
      <Button
        onClick={handleCreateOrUpdatePayType}
        disabled={!canBeSaved || loading}
        label={t('common:save')}
        data-cy="save-pay-type-button"
      />
    </div>
  );
};

export default MappingTab;
