import { Modal, Card, CardAction, Button, Select, Option } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import TextInput from 'src/components/Common/TextInput/TextInput';
import { ACCOUNTING_TYPE_VALUES } from 'src/graphql/schema/query/accountingunit/AccountingTypeValue';
import { useCreateAccountingRow } from 'src/service/accountingunit/AccountingRow';
import { CreateAccountingRowInput } from 'src/types/accountingunit/AccountingRow';
import { AccountingTypeValue } from 'src/types/accountingunit/AccountingTypeValue';
import { createOptions } from 'src/util/OptionUtil';
import { isBlank } from 'src/util/StringUtil';
import './add-accounting-row-modal.scss';

interface AddAccountingRowModalProps {
  open: boolean;
  onClose: () => void;
  chartOfAccountsId: string;
}
const AddAccountingRowModal: FC<AddAccountingRowModalProps> = ({ open, onClose, chartOfAccountsId }) => {
  const { employerId } = useParams();
  const { t } = useTranslation();
  const createAccountingRow = useCreateAccountingRow();

  const [createAccountingRowInput, setCreateAccountingRowInput] = useState<CreateAccountingRowInput>({
    chartOfAccountsId: chartOfAccountsId,
    accountingTypeValue: '',
    accountingNumber: '',
    accountingText: '',
  });
  const handleOnClose = () => {
    setCreateAccountingRowInput({
      chartOfAccountsId: chartOfAccountsId,
      accountingTypeValue: '',
      accountingNumber: '',
      accountingText: '',
    });
    onClose();
  };

  const onAccountingNumberChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCreateAccountingRowInput((prev) => ({ ...prev, accountingNumber: e.target.value }));
  const onAccountingTextChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCreateAccountingRowInput((prev) => ({ ...prev, accountingText: e.target.value }));
  const onAccountingTypeChange = (option: Option<AccountingTypeValue>) =>
    setCreateAccountingRowInput((prev) => ({ ...prev, accountingTypeValue: option.value.name }));

  const handleCreateAccountingRow = () => createAccountingRow(createAccountingRowInput, employerId).then(handleOnClose);
  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="add-accounting-row-modal"
        title={t('economySettings:accounting-rows-tab.add-accounting-row-modal.title')}
        defaultClose
        onClose={handleOnClose}
      >
        <TextInput
          data-cy="accounting-number-input"
          variant="default"
          label={t('economySettings:accounting-rows-tab.add-accounting-row-modal.account-number-label')}
          value={createAccountingRowInput.accountingNumber}
          validationKey="text64"
          type="text"
          maxLength={65}
          mandatory
          onChange={onAccountingNumberChange}
        />
        <TextInput
          data-cy="accounting-text-input"
          variant="default"
          label={t('economySettings:accounting-rows-tab.add-accounting-row-modal.accounting-text-label')}
          type="text"
          validationKey="text64"
          maxLength={65}
          value={createAccountingRowInput.accountingText}
          mandatory
          onChange={onAccountingTextChange}
        />
        <div>
          <QueryWrapper query={ACCOUNTING_TYPE_VALUES} options={{ variables: { employerId } }}>
            {(accountingTypes: AccountingTypeValue[]) => {
              const options = createOptions(accountingTypes, t, 'accountingTypeValue', 'name');

              return (
                <Select
                  label={t('economySettings:accounting-rows-tab.add-accounting-row-modal.accounting-type-label')}
                  data-cy="accounting-type-input"
                  placeholder={t(
                    'economySettings:accounting-rows-tab.add-accounting-row-modal.accounting-type-placeholder',
                  )}
                  selected={options.find(
                    (option) => option.value.name === createAccountingRowInput.accountingTypeValue,
                  )}
                  options={options}
                  onChange={onAccountingTypeChange}
                  className="add-accounting-row-modal__dropdown"
                  small
                  mandatory
                  outlined
                />
              );
            }}
          </QueryWrapper>
        </div>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleOnClose} />
          <Button
            disabled={
              isBlank(createAccountingRowInput.accountingNumber) ||
              isBlank(createAccountingRowInput.accountingText) ||
              isBlank(createAccountingRowInput.accountingTypeValue)
            }
            label={t('economySettings:accounting-rows-tab.add-accounting-row-modal.add')}
            onClick={handleCreateAccountingRow}
            data-cy="add-accounting-row-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddAccountingRowModal;
