import useInsuranceEventFilterProps from './useInsuranceEventFilterProps';
import useEmployerFilterProps from './useEmployerFilterProps';
import { useState } from 'react';
import { FilterSelectProps } from 'src/components/Common/FilterComponent/FilterSelectProps';
import { Service } from 'src/types/Service';
import { Employer } from 'src/types/employer/Employer';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { filterFullName } from 'src/util/EmployeeFilter';

const useFilters = (
  service: Service<InsuranceEventReportEmployeeResponse[]>,
  employers: Employer[],
): [
  FilterSelectProps<unknown>,
  FilterSelectProps<unknown>,
  string,
  (value: string) => void,
  InsuranceEventReportEmployeeResponse[],
] => {
  const [nameFilter, setNameFilter] = useState('');
  const employerFilterProps = useEmployerFilterProps(employers);

  const data = service.status == 'loaded' ? service.payload : [];

  const insuranceEventFilterProps = useInsuranceEventFilterProps(
    data.map((reportEmployee) => reportEmployee.checkpoints),
  );

  return [
    employerFilterProps,
    insuranceEventFilterProps,
    nameFilter,
    setNameFilter,
    data.filter(
      (employee) =>
        filterFullName(employee.employee, nameFilter) &&
        employerFilterProps.filter(employee) &&
        insuranceEventFilterProps.filter(employee),
    ),
  ];
};

export default useFilters;
