import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pill, Td } from 'fgirot-k2-ui-components';
import './td-examination-period-difference.scss';
import { Checkpoint } from 'src/types/Checkpoint';

interface TdExaminationPeriodDifferenceProps {
  checkpoints: Checkpoint[];
}

const TdExaminationPeriodDifference = ({ checkpoints }: TdExaminationPeriodDifferenceProps) => {
  const { t } = useTranslation();
  return (
    <Td>
      {checkpoints &&
        checkpoints.map((cp, index) => {
          return (
            <Pill
              key={`examination-period-difference-pill-${cp.id}`}
              className={index !== 0 ? 'pill--margin' : ''}
              type="heads-up"
              label={`${t(`periodDifference:${cp.subActionCategory}`)}`}
            />
          );
        })}
    </Td>
  );
};

export default TdExaminationPeriodDifference;
