import { gql } from '@apollo/client';

export const GET_ACTIVE_ACCOUNT = gql`
  query GetActiveAccount($employerId: UUID!) {
    activeAccount(employerId: $employerId) {
      id
      accountNumber
      paymentServiceProvider
      accountActive
      bank
    }
  }
`;
