import { gql } from '@apollo/client';

export const GET_POLICY = gql`
  query GetPolicy($policyId: UUID!) {
    policy(policyId: $policyId) {
      policyEmploymentGroups {
        id
        employmentGroupId
        employmentGroupName
        affiliationRuleBlocks {
          id
          groupedAffiliationRules {
            affiliationRuleGroupType
            affiliationRules {
              id
              policyRuleType
              ruleValueUnit
              sourceType
              sourceReference
              ruleValues {
                id
                value
                valueType
                condition
              }
            }
          }
        }
        policyBenefits {
          id
          benefitPackageId
          benefitType
          name
        }
      }
      policyBenefits {
        id
        benefitPackageId
        name
        benefitType
        agreedProducts {
          id
          agreementNumber
          name
          insuranceProviderId
          insuranceProduct {
            id
            name
          }
        }
        policyEmploymentGroups {
          id
          employmentGroupId
          employmentGroupName
        }
        connectionRule {
          optional
        }
        affiliationRuleBlocks {
          id
          groupedAffiliationRules {
            affiliationRuleGroupType
            affiliationRules {
              id
              policyRuleType
              ruleValueUnit
              sourceType
              sourceReference
              ruleValues {
                id
                value
                valueType
                condition
              }
            }
          }
        }
        pglRuleReferences {
          pglRuleReference {
            pglRuleId
            name
          }
          spglRuleReference {
            pglRuleId
            name
          }
        }
        kubType
      }
    }
  }
`;
