import { gql } from '@apollo/client';

export const CREATE_AGREED_REPORTING_TYPE_CONFIG = gql`
  mutation CreateAgreedReportingTypeConfig(
    $insuranceProviderContractId: UUID!
    $agreedProductId: UUID!
    $request: AgreedReportingTypeConfigRequest!
  ) {
    createAgreedReportingTypeConfig(
      insuranceProviderContractId: $insuranceProviderContractId
      agreedProductId: $agreedProductId
      request: $request
    ) {
      id
    }
  }
`;
