import { SortDirection, TableBody, TableHead, TableRow, Td, Th } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { EmployeeInsurance } from 'src/types/insurance/Insurance';
import { InsurancesSortField } from './InsurancesSortField';
import { sortAlphabeticalAsc, sortAlphabeticalDesc } from 'src/util/SortUtil';
import { useTranslation } from 'react-i18next';
import { getTableHeads } from './util';
import { stringOrBlank } from 'src/util/StringUtil';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';

interface InsurancesTableProps {
  employeeInsurances: EmployeeInsurance[];
  onDrawerOpen: (employeeInsurance: EmployeeInsurance) => void;
}

const InsurancesTable = ({ employeeInsurances, onDrawerOpen }: InsurancesTableProps) => {
  const [sortField, setSortField] = useState<InsurancesSortField>('insuranceProvider');
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const { t } = useTranslation();

  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));
  const handleSortFieldChange = (sortField: InsurancesSortField) => setSortField(sortField);

  const sortFunction = (a: EmployeeInsurance, b: EmployeeInsurance) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;

    switch (sortField) {
      case 'insuranceProvider':
        return sortAlphabetical(a.insurance?.insuranceProvider?.legalName, b.insurance?.insuranceProvider?.legalName);
      case 'benefitType':
        return sortAlphabetical(
          t(`benefitTypes:${a.insurance.benefitType}`),
          t(`benefitTypes:${b.insurance.benefitType}`),
        );
      case 'insuranceTypeMapping':
        return sortAlphabetical(
          a.insurance?.insuranceTypeMappings[0]?.name,
          b.insurance?.insuranceTypeMappings[0]?.name,
        );
      case 'agreedProduct':
        return sortAlphabetical(a.insurance?.agreedProduct?.name, b.insurance?.agreedProduct?.name);
      case 'agreementNumber':
        return sortAlphabetical(a.insurance.agreementNumber, b.insurance.agreementNumber);
      case 'insuranceNumber':
        return sortAlphabetical(a.insurance.insuranceNumber, b.insurance.insuranceNumber);
      case 'certifyInsuranceProductCondition':
        return sortAlphabetical(
          t(
            `insurances:certify-insurance-product-condition.${a.insurance.agreedProduct.certifyInsuranceProductCondition}`,
          ),
          t(
            `insurances:certify-insurance-product-condition.${b.insurance.agreedProduct.certifyInsuranceProductCondition}`,
          ),
        );
      case 'statusCategory':
        return sortAlphabetical(
          t(`insurances:insurance-status.${a.insurance.insuranceStatus}`),
          t(`insurances:insurance-status.${b.insurance.insuranceStatus}`),
        );
      default:
        return undefined;
    }
  };

  const sortedEmployeeInsurances = employeeInsurances?.sort(sortFunction);
  const tableHeads = getTableHeads({
    sortDirection,
    onSortFieldChange: handleSortFieldChange,
    onSortDirectionChange: handleSortDirectionChange,
  });
  return (
    <TableBase compressed hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns}>
      <TableHead>
        <TableRow>
          {tableHeads.map((head) => (
            <Th
              includeSortButtons
              sortDirection={sortField === head.fieldName ? sortDirection : undefined}
              handleSortDirectionChange={head.handleSortDirectionChange}
              key={`insurances-table-${head.title}`}
            >
              {head.title}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedEmployeeInsurances?.map((insurance) => {
          const { benefitType, insuranceNumber, agreementNumber, insuranceStatus } = insurance.insurance;
          const agreedProduct = insurance.insurance.agreedProduct;

          const getInsuranceTypeMappingName = (employeeInsurance: EmployeeInsurance) => {
            if (employeeInsurance.insurance?.insuranceTypeMappings?.length > 1) {
              return `${employeeInsurance.insurance?.insuranceTypeMappings[0]?.name}, +${
                employeeInsurance.insurance?.insuranceTypeMappings?.length - 1
              }`;
            }
            if (employeeInsurance.insurance.insuranceTypeMappings.length === 1) {
              return `${employeeInsurance.insurance?.insuranceTypeMappings[0]?.name}`;
            }
            if (employeeInsurance.insurance.insuranceTypeMappings.length === 0) {
              return '-';
            }
          };
          return (
            <TableRow
              onClick={() => onDrawerOpen(insurance)}
              key={`InsurancesTableRow-${insurance.id}`}
              data-testid={`insurance-table-row__${insurance.id}`}
            >
              <Td compressed>{stringOrBlank(insurance?.insurance?.insuranceProvider?.legalName)}</Td>
              <Td compressed>{t(`benefitTypes:${benefitType}`)}</Td>
              <Td compressed>{getInsuranceTypeMappingName(insurance)}</Td>
              <Td compressed>{stringOrBlank(agreedProduct?.name)}</Td>
              <Td compressed>
                {t(`insurances:certify-insurance-product-condition.${agreedProduct?.certifyInsuranceProductCondition}`)}
              </Td>
              <Td compressed>{stringOrBlank(insuranceNumber)}</Td>
              <Td compressed>{stringOrBlank(agreementNumber)}</Td>
              <Td compressed>{t(`insurances:insurance-status-category.${insuranceStatus?.statusCategory}`)}</Td>
              <Td compressed>{stringOrBlank(formatDateTimeString(insuranceStatus?.latestStatusChange))}</Td>
            </TableRow>
          );
        })}
      </TableBody>
    </TableBase>
  );
};

export default InsurancesTable;
