import { post } from 'src/http/post';
import { SearchAccountingItemsRequest } from 'src/types/ledgerStorage/SearchAccountingItemsRequest';

export const exportAccountingItems = (searchRequest: SearchAccountingItemsRequest) => {
  return post('/api/exports/accounting-items', searchRequest, { responseType: 'blob' }).then((response) => {
    if (response.status === 200) {
      const url = URL.createObjectURL(response.data as Blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = response.headers['content-disposition'].split('filename=')[1];
      a.click();

      URL.revokeObjectURL(url);
    }
  });
};
