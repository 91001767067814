import { PieChart, Typography } from 'fgirot-k2-ui-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingPeriodSummary } from 'src/types/reporting/ReportingPeriodSummary';
import { getMonthKeys } from 'src/util/date/Months';
import './period-details-card.scss';
import { useNavigate } from 'react-router-dom';
import { stringOrBlank } from 'src/util/StringUtil';
import PeriodDetailsCardBox from './PeriodDetailsCardBox';
import PeriodDetailsCardSection from './PeriodDetailsCardSection';
import LoadingOverlay from 'src/components/Common/LoadingOverlay';
import { useReportingPeriodDetails } from 'src/service/reporting/GetReportingPeriodDetails';
import { LOADED, LOADING } from 'src/types/Status';
import PeriodStatusPill from '../PeriodCard/PeriodStatusPill';
import { useEventsPerReportingTypeDataPoints } from '../utils';

interface PeriodDetailsCardProps {
  reportingPeriodSummaries: ReportingPeriodSummary[];
  year: number;
  month: number;
  employerIds: string[];
  reportingPeriodSummaryLoading: boolean;
}

function PeriodDetailsCard({
  reportingPeriodSummaries,
  year,
  month,
  employerIds,
  reportingPeriodSummaryLoading,
}: PeriodDetailsCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getEmptyDataPoints, generateDataPoints } = useEventsPerReportingTypeDataPoints();
  const [selectedSummary, setSelectedSummary] = React.useState<ReportingPeriodSummary>();
  const [dataPoints, setDataPoints] = React.useState(getEmptyDataPoints());

  useEffect(() => {
    if (reportingPeriodSummaries) {
      const summary = reportingPeriodSummaries?.find(
        (summary) => summary.wageFilePeriod === `${year}${month.toString().padStart(2, '0')}`,
      );
      setSelectedSummary(summary);
      if (summary?.insuranceEventSummary?.perReportingType?.length > 0) {
        setDataPoints(generateDataPoints(summary?.insuranceEventSummary?.perReportingType));
      } else {
        setDataPoints(getEmptyDataPoints());
      }
    }
  }, [reportingPeriodSummaries, month, year]);

  const wageFilePeriod = `${year}${month.toString().padStart(2, '0')}`;

  const goToEvents = () => {
    navigate(`/reporting/${year}/${new String(month).padStart(2, '0')}/insurance-events`);
  };

  const goToReportingProgressStep = (step: number) => {
    navigate(`/reporting/${year}/${new String(month).padStart(2, '0')}?progress=${step}`);
  };

  const service = useReportingPeriodDetails(employerIds, wageFilePeriod);
  const details = service.status === LOADED ? service.payload : undefined;

  return (
    <section className="period-details-card" data-testid={`period-details-${wageFilePeriod}`}>
      <header className="period-details-card--header">
        <div className="period-details-card--title">
          <Typography variant="h2" component="h3" bold>
            {`${t(getMonthKeys()[month - 1])}`}
          </Typography>
          <Typography variant="h2" bold className="period-card--title-year">
            {year.toString()}
          </Typography>
          <div className="period-details-card--title-pill">
            <PeriodStatusPill periodStatus={selectedSummary?.periodStatus} />
          </div>
        </div>
        <div className="period-details-card--pie-chart">
          <LoadingOverlay loading={reportingPeriodSummaryLoading} />
          <PieChart
            title={
              <Typography variant="caption" bold className="period-card--sub-title">
                {t('report:overview.insurance-events')}
              </Typography>
            }
            dataPoints={dataPoints}
          />
        </div>
      </header>
      <div className="period-details-card--content">
        <PeriodDetailsCardSection
          title={t('report:overview.title.files')}
          loading={service.status === LOADING}
          buttonLabel={t('report:overview.navigate.files')}
          buttonOnClick={() => goToReportingProgressStep(0)}
        >
          <PeriodDetailsCardBox
            title={t('report:overview.processed-files')}
            value={stringOrBlank(details?.wageFileDetails?.receivedNumberOfFiles)}
            icon="FileBlank"
            data-testid="period-details-processed-files"
          />
          {selectedSummary?.periodStatus === 'ONGOING' && (
            <PeriodDetailsCardBox
              title={t('report:overview.expected-files')}
              value={stringOrBlank(details?.wageFileDetails?.expectedNumberOfFiles)}
              icon="FileBlank"
              data-testid="period-details-expected-files"
            />
          )}
          <PeriodDetailsCardBox
            title={t('report:overview.processed-employees')}
            value={stringOrBlank(details?.wageFileDetails?.receivedNumberOfEmployees)}
            icon="Users"
            data-testid="period-details-processed-employees"
          />
        </PeriodDetailsCardSection>
        <PeriodDetailsCardSection
          title={t('report:overview.title.validate')}
          loading={service.status === LOADING}
          buttonLabel={t('report:overview.navigate.validate')}
          buttonOnClick={() => goToReportingProgressStep(1)}
        >
          <PeriodDetailsCardBox
            title={t('report:overview.employees-with-unfinished-checkpoints')}
            icon="Users"
            value={stringOrBlank(details?.dataValidationEventDetails?.employeesWithUnfinishedCheckpoints)}
            data-testid="period-details-checkpoint-validate"
          />
        </PeriodDetailsCardSection>
        <PeriodDetailsCardSection
          title={t('report:overview.title.examinate')}
          buttonLabel={t('report:overview.navigate.examinate')}
          buttonOnClick={() => goToReportingProgressStep(2)}
          loading={service.status === LOADING}
        >
          <PeriodDetailsCardBox
            title={t('report:overview.employees-with-unfinished-checkpoints')}
            icon="Users"
            value={stringOrBlank(details?.employmentEventDetails?.employeesWithUnfinishedCheckpoints)}
            data-testid="period-details-checkpoint-examinate"
          />
        </PeriodDetailsCardSection>
        <PeriodDetailsCardSection
          title={t('report:overview.title.verify')}
          loading={service.status === LOADING}
          buttonLabel={t('report:overview.navigate.verify')}
          buttonOnClick={() => goToReportingProgressStep(3)}
        >
          <PeriodDetailsCardBox
            title={t('report:overview.employees-with-unfinished-checkpoints')}
            icon="Users"
            value={stringOrBlank(details?.verifyInsuranceEventDetails?.employeesWithUnfinishedCheckpoints)}
            data-testid="period-details-checkpoint-verify"
          />
        </PeriodDetailsCardSection>
        <PeriodDetailsCardSection
          title={t('report:overview.title.certify')}
          loading={service.status === LOADING}
          buttonLabel={t('report:overview.navigate.certify')}
          buttonOnClick={() => goToReportingProgressStep(4)}
        >
          <PeriodDetailsCardBox
            title={t('report:overview.employees-with-unfinished-checkpoints')}
            icon="Users"
            value={stringOrBlank(details?.certifyInsuranceEventDetails?.employeesWithUnfinishedCheckpoints)}
            data-testid="period-details-checkpoint-certify"
          />
        </PeriodDetailsCardSection>
        <PeriodDetailsCardSection
          title={t('report:overview.title.events')}
          loading={service.status === LOADING}
          buttonLabel={t('report:overview.navigate.events')}
          buttonOnClick={goToEvents}
        >
          <PeriodDetailsCardBox
            title={t('report:overview.employees-with-reporting-events')}
            icon="Users"
            value={stringOrBlank(details?.reportingEventsDetails?.employeesWithReportingEvents)}
            data-testid="period-details-employees-with-reporting-events"
          />
          <PeriodDetailsCardBox
            title={t('report:overview.reporting-events-done')}
            value={stringOrBlank(details?.reportingEventsDetails?.numberOfReportedReportingEvents)}
            icon="Check"
            data-testid="period-details-reported-events"
          />
        </PeriodDetailsCardSection>
      </div>
    </section>
  );
}

export default PeriodDetailsCard;
