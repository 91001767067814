import { useApolloMutation } from '../graphql/Mutation';
import { CreatePaymentTransactionInput } from 'src/types/invoice/CreatePaymentTransactionInput';
import { CREATE_PAYMENT_TRANSACTION } from 'src/graphql/schema/mutation/invoice/CreatePaymentTransaction';
import { GET_PAYMENT_TRANSACTIONS } from 'src/graphql/schema/query/invoice/GetPaymentTransactions';
import { DELETE_PAYMENT_TRANSACTION } from 'src/graphql/schema/mutation/invoice/DeletePaymentTransaction';
import { GET_INVOICE } from 'src/graphql/schema/query/invoice/GetInvoice';

export const useCreatePaymentTransaction = () => {
  const apolloMutation = useApolloMutation();
  const createPaymentTransaction = (
    invoiceHeaderId: string,
    request: CreatePaymentTransactionInput,
    employerId: string,
  ) =>
    apolloMutation(CREATE_PAYMENT_TRANSACTION, employerId, { invoiceHeaderId, request }, [
      GET_PAYMENT_TRANSACTIONS,
      GET_INVOICE,
    ]);
  return createPaymentTransaction;
};

export const useDeletePaymentTransaction = () => {
  const apolloMutation = useApolloMutation();
  const deletePaymentTransaction = (paymentTransactionId: string, employerId: string) =>
    apolloMutation(DELETE_PAYMENT_TRANSACTION, employerId, { paymentTransactionId }, [
      GET_PAYMENT_TRANSACTIONS,
      GET_INVOICE,
    ]);
  return deletePaymentTransaction;
};
