import { CreateExternalUser } from 'src/types/user/User';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_USERS } from 'src/graphql/schema/query/user/GetUsers';
import { CREATE_EXTERNAL_USER } from 'src/graphql/schema/mutation/CreateExternalUser';

export const useCreateExternalUser = () => {
  const apolloMutation = useApolloMutation();

  const createExternalUser = (request: CreateExternalUser) =>
    apolloMutation(CREATE_EXTERNAL_USER, '', { request }, [GET_USERS]);

  return createExternalUser;
};
