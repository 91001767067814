import { gql } from '@apollo/client';

export const GET_INSURANCE_PROVIDER_CONTRACT = gql`
  query GetInsuranceProviderContract($insuranceProviderContractId: UUID!) {
    insuranceProviderContract(insuranceProviderContractId: $insuranceProviderContractId) {
      id
      employerId
      name
      status
      invoiceInterval
      agreementNumber
      insuranceProvider {
        id
        legalName
      }
      agreedProducts {
        id
        name
        defaultReportingDetailLevel
        insuranceProduct {
          id
          name
          insuranceProvider {
            id
            legalName
          }
        }
        insuranceProviderProductCategory
        certifyInsuranceProductCondition
        certifyInsuranceProductConditionId
      }
      agreementCostCentres {
        id
        code
        isDefault
      }
    }
  }
`;
