import { Checkpoint, InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { DataValidationCheckpoint } from 'src/types/DataValidationCheckpoint';
import { Employee } from 'src/types/Employee';
import { Employer } from 'src/types/employer/Employer';
import { Option } from 'fgirot-k2-ui-components';

const filterName = (name: string, nameFilter: string): boolean => {
  return name ? name.toLowerCase().includes(nameFilter.toLowerCase()) : false;
};

export const filterFullName = (employee: Employee, nameFilter: string): boolean => {
  return nameFilter ? filterName(employee.firstName, nameFilter) || filterName(employee.lastName, nameFilter) : true;
};

export const filterEmployer = (employee: Employee, selectedEmployer: Option<Employer>[]): boolean => {
  return selectedEmployer.length > 0
    ? selectedEmployer.filter((option) => option.value.id === employee.employerId).length > 0
    : true;
};

export const filterValidationTypes = (
  checkpoints: DataValidationCheckpoint[],
  selectedValidationTypes: Option<string>[],
): boolean => {
  const selected = selectedValidationTypes.map((c) => c.value);
  return selected.length > 0
    ? !checkpoints.every((checkpoint) => checkpoint.additionalData.fields.every((f) => !selected.includes(f.key)))
    : true;
};

export const filterSubActionCategories = (checkpoints: Checkpoint[], selected: Option<string>[]): boolean => {
  return selected.length > 0 ? lookUpSubActionCategories(checkpoints, selected) : true;
};

export const filterInsuranceEventTypes = (
  checkpoints: InsuranceEventCheckpoint[],
  selected: Option<string>[],
): boolean => {
  return selected.length > 0 ? lookUpInsuranceEventTypes(checkpoints, selected) : true;
};

export const filterBenefitTypes = (checkpoints: InsuranceEventCheckpoint[], selected: Option<string>[]): boolean => {
  return selected.length > 0 ? lookUpBenefitTypes(checkpoints, selected) : true;
};

const lookUpInsuranceEventTypes = (checkpoints: InsuranceEventCheckpoint[], selected: Option<string>[]): boolean => {
  const insuranceEventTypes = checkpoints.flatMap((checkpoint) => checkpoint.insuranceEventType);
  const selectedInsuranceEventType = selected.map((option) => option.value.toUpperCase());
  return selectedInsuranceEventType.some((selected) => insuranceEventTypes.includes(selected));
};

const lookUpBenefitTypes = (checkpoints: InsuranceEventCheckpoint[], selected: Option<string>[]): boolean => {
  const benefitTypes = checkpoints.flatMap((checkpoint) => checkpoint.benefitType);
  const selectedBenefitType = selected.map((option) => option.value.toUpperCase());
  return selectedBenefitType.some((selected) => benefitTypes.includes(selected));
};

const lookUpSubActionCategories = (checkpoints: Checkpoint[], selected: Option<string>[]): boolean => {
  const employeeSubActionCategories = checkpoints.map((checkpoint) => checkpoint.subActionCategory.toUpperCase());
  const selectedSubActionCategories = selected.map((option) => option.value.toUpperCase());
  return selectedSubActionCategories.some((selected) => employeeSubActionCategories.includes(selected));
};
