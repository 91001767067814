import { TableHead, TableRow, Th, TableBody } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import AddAccountingRowModal from './AddAccountingRowModal/AddAccountingRowModal';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import { useTranslation } from 'react-i18next';
import { ChartOfAccounts } from 'src/types/accountingunit/ChartOfAccounts';
import AccountingRowTableRow from './AccountingRowTableRow';

interface AccountingRowsTableProps {
  chartOfAccounts: ChartOfAccounts;
}

const AccountingRowsTable: FC<AccountingRowsTableProps> = ({ chartOfAccounts }) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((prev) => !prev);
  return (
    <>
      <TableBase>
        <TableHead>
          <TableRow>
            <Th includeSortButtons>{t('economySettings:accounting-rows-tab.table.head.account')}</Th>
            <Th includeSortButtons>{t('economySettings:accounting-rows-tab.table.head.name')}</Th>
            <Th includeSortButtons>{t('economySettings:accounting-rows-tab.table.head.type')}</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {chartOfAccounts.accountingRows.map((row) => (
            <AccountingRowTableRow key={`accounting-row-table-row-${row.id}`} accountingRow={row} />
          ))}
        </TableBody>
      </TableBase>
      <FloatingAddButton label={t('economySettings:accounting-rows-tab.add-button')} onClick={toggleModalOpen} />
      <AddAccountingRowModal open={modalOpen} onClose={toggleModalOpen} chartOfAccountsId={chartOfAccounts.id} />
    </>
  );
};

export default AccountingRowsTable;
