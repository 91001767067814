export type AccountingAdjustmentConditionType =
  | 'ACCOUNTING_TYPE'
  | 'ACCOUNTING_ROW'
  | 'ACCOUNTING_COMPONENT_VALUE'
  | 'ACCOUNTING_COMPONENT_VALUE_MISSING'
  | 'ACCOUNTING_UNIT';

export const accountingAdjustmentConditionTypes: AccountingAdjustmentConditionType[] = [
  'ACCOUNTING_COMPONENT_VALUE',
  'ACCOUNTING_COMPONENT_VALUE_MISSING',
  'ACCOUNTING_ROW',
  'ACCOUNTING_TYPE',
  'ACCOUNTING_UNIT',
];
