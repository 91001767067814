import { Banner } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_MISSING_EMPLOYEE_CHECKPOINT } from 'src/graphql/schema/query/checkpoint/GetMissingEmployeeCheckpoint';
import { Checkpoint } from 'src/types/Checkpoint';
import EmployeeMissingModal from '../EmployeeMissingModal/EmployeeMissingModal';
import { useTranslation } from 'react-i18next';
import { unique } from 'src/util/ArrayUtil';
import './checkpoint-banners.scss';
interface EmployeeMissingBannerProps {
  employerId: string;
  errandId: string;
}
const EmployeeMissingBanner: FC<EmployeeMissingBannerProps> = ({ employerId, errandId }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <QueryWrapper
      query={GET_MISSING_EMPLOYEE_CHECKPOINT}
      options={{ variables: { employerIds: [employerId], errandId } }}
    >
      {(checkpoints: Checkpoint[]) => {
        const uniqueEmployeeIds = unique(checkpoints.map((checkpoint) => checkpoint.employeeId));
        return (
          <>
            {checkpoints.length > 0 && (
              <Banner
                type="warning"
                message={`${t('invoice:drawer.employee-missing-banner.message-1')} ${uniqueEmployeeIds.length} ${t(
                  'invoice:drawer.employee-missing-banner.message-2',
                )}`}
                buttonLabel={t('invoice:drawer.employee-missing-banner.button-label')}
                onClick={handleOpen}
                className="employee-missing-banner"
              />
            )}

            <EmployeeMissingModal employerId={employerId} open={open} onClose={handleClose} checkpoints={checkpoints} />
          </>
        );
      }}
    </QueryWrapper>
  );
};

export default EmployeeMissingBanner;
