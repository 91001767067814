import { Option } from 'fgirot-k2-ui-components';

export const navOptions: Option<number>[] = [
  { label: 'reporting', value: 0 },
  { label: 'employees', value: 1 },
  { label: 'economy', value: 2 },
  { label: 'documents', value: 3 },
  { label: 'permission', value: 4 },
  { label: 'account', value: 5 },
];
