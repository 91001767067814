import { gql } from '@apollo/client';

export const GET_INVOICES = gql`
  query GetInvoices($employerId: UUID!, $pageNumber: Int, $pageSize: Int) {
    invoices(employerId: $employerId, pageNumber: $pageNumber, pageSize: $pageSize) {
      paging {
        totalNumberOfElements
        hasNext
      }
      invoices {
        id
        createdAt
        updatedAt
        sequenceNumber
        invoiceNumber
        fileName
        invoiceStatus
        invoiceIssuedCreationDate
        arrivalDate
        convertedDate
        hasPaperInvoice
        agreementNumber
        note
        dueDate
        paymentDueDate
        paymentMethod
        ocrNumber
        accountNumberTo
        currencyCode
        totalAmount
        roundingAmount
        vatAmount
        remainingAmount
        invoicePeriod {
          periodStart
          periodEnd
        }
        employer {
          id
        }
        insuranceProvider {
          id
          name
          organisationNumber
        }
      }
    }
  }
`;
