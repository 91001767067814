import { DrawerNavigation, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AgreedProduct } from 'src/types/contract/AgreedProduct';
import AgreedProductRow from './AgreedProductRow/AgreedProductRow';
import ScrollBase from 'src/components/Common/ScrollBase/ScrollBase';

interface AgreedProductDrawerTabProps {
  agreedProducts: AgreedProduct[];
  benefitType: string;
  inDraft: boolean;
  onDisplayAgreedProductDetailDrawer: (selectedAgreedProductId: string) => void;
  onAddAgreedProduct: () => void;
}

const AgreedProductDrawerTab: FC<AgreedProductDrawerTabProps> = ({
  agreedProducts,
  benefitType,
  inDraft,
  onDisplayAgreedProductDetailDrawer,
  onAddAgreedProduct,
}) => {
  const { t } = useTranslation();

  return (
    <div className={['policy-benefit-overview__tab'].join(' ')}>
      <Typography bold variant="subtitle">
        {t('account:employers-tab.policy-settings.table.drawer.chosen-products')}
      </Typography>
      <div>
        {inDraft && (
          <ListGroup className="policy-benefit-overview__table" variant="drawer-navigation">
            <DrawerNavigation label={t('policySettings:agreed-products-tab.add')} onClick={onAddAgreedProduct} />
          </ListGroup>
        )}
      </div>
      <ScrollBase maxHeightPercentage={50}>
        <ListGroup variant="drawer-navigation" className="policy-benefit-overview__tab">
          {agreedProducts.map((product, index) => {
            return (
              <AgreedProductRow
                title={product?.name}
                value={product?.agreementNumber}
                benefitType={benefitType}
                key={`AgreedProductRow-${product?.id}-${index}`}
                handleOpenProductDetails={() => onDisplayAgreedProductDetailDrawer(product?.id)}
              />
            );
          })}
        </ListGroup>
      </ScrollBase>
    </div>
  );
};

export default AgreedProductDrawerTab;
