import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleCard from 'src/components/Common/ModuleCard';
import ModuleCardBox from 'src/components/Common/ModuleCardBox';
import { Employer } from 'src/types/employer/Employer';
import { PaymentAccount } from 'src/types/payment/PaymentAccount';
import { stringOrBlank } from 'src/util/StringUtil';
import PaymentSettingsDrawer from '../PaymentSettingsDrawer';

interface ActiveAccountCardProps {
  account: PaymentAccount;
  employer: Employer;
}

const ActiveAccountCard: FC<ActiveAccountCardProps> = ({ account, employer }) => {
  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const togglePaymentSettingsDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  return (
    <>
      <ModuleCard
        className="payment-settings-tab__module-card"
        title={t('economySettings:payment-settings-tab.module-card.payment-title')}
        iconType="Stats"
        onClick={togglePaymentSettingsDrawer}
        dataCy="payment-settings-tab__active-account-card"
      >
        <div className="payment-settings-tab__module-card-content">
          <ModuleCardBox
            label={t('economySettings:payment-settings-tab.module-card.account-number-box')}
            value={stringOrBlank(account.accountNumber)}
            dataCy="payment-settings-tab__account-number-box"
          />
          <ModuleCardBox
            label={t('economySettings:payment-settings-tab.module-card.payment-service-provider-box')}
            value={stringOrBlank(
              t(`economySettings:payment-settings-tab.payment-service-provider.${account.paymentServiceProvider}`),
            )}
            dataCy="payment-settings-tab__account-type-box"
          />
          <ModuleCardBox
            label={t('economySettings:payment-settings-tab.module-card.bank-box')}
            value={stringOrBlank(t(`economySettings:payment-settings-tab.bank.${account.bank}`))}
            dataCy="payment-settings-tab__bank-box"
          />
          <ModuleCardBox
            label={t('economySettings:payment-settings-tab.module-card.active-account-box')}
            value={stringOrBlank(
              t(`economySettings:payment-settings-tab.module-card.active-account.${account.accountActive}`),
            )}
            dataCy="payment-settings-tab__active-account-box"
          />
        </div>
      </ModuleCard>
      <PaymentSettingsDrawer
        open={drawerOpen}
        employer={employer}
        account={account}
        onClose={togglePaymentSettingsDrawer}
      />
    </>
  );
};

export default ActiveAccountCard;
