export type SubActionCategory =
  | 'MANDATORY_EMPLOYEE_STORAGE_DATA'
  | 'MANDATORY_EMPLOYEE_CARD_DATA'
  | 'MANDATORY_WAGE_TYPE_MAPPING'
  | 'MISSING_EMPLOYEE_IN_WAGE_FILE'
  | 'CERTIFY_FULL_EMPLOYABILITY'
  | 'GROSS_SALARY_CHANGE'
  | 'FIXED_SUPPLEMENT_CHANGE'
  | 'GROSS_DEDUCTION_AMOUNT_CHANGE'
  | 'SALARY_EXCHANGE_AMOUNT_CHANGE'
  | 'MULTIPLE_MONTHLY_SALARY_WAGE_ROWS_VALID_FROMDATE_REQUIRED'
  | 'RETROACTIVELY_EMPLOYMENT_START_DATE'
  | 'UNFINISHED_REPORTING_PREVIOUS_PERIOD'
  | 'PREVIOUS_PERIOD_EMPLOYEE_CARD_MISSING'
  | 'RETRO_WAGE_ROWS_FOR_KUB_PAYED_AMOUNT_MISSING'
  | 'WAGE_TYPES_POSITIVE_SUM_NEGATIVE_REQUIRED'
  | 'INDIVIDUAL_CHOICE_ITP1_FROM_PERIOD_REQUIRED';

export const subActionCategories: SubActionCategory[] = [
  'MANDATORY_EMPLOYEE_STORAGE_DATA',
  'MANDATORY_EMPLOYEE_CARD_DATA',
  'MANDATORY_WAGE_TYPE_MAPPING',
  'MISSING_EMPLOYEE_IN_WAGE_FILE',
  'CERTIFY_FULL_EMPLOYABILITY',
  'GROSS_SALARY_CHANGE',
  'FIXED_SUPPLEMENT_CHANGE',
  'GROSS_DEDUCTION_AMOUNT_CHANGE',
  'SALARY_EXCHANGE_AMOUNT_CHANGE',
  'MULTIPLE_MONTHLY_SALARY_WAGE_ROWS_VALID_FROMDATE_REQUIRED',
  'RETROACTIVELY_EMPLOYMENT_START_DATE',
  'UNFINISHED_REPORTING_PREVIOUS_PERIOD',
  'PREVIOUS_PERIOD_EMPLOYEE_CARD_MISSING',
  'RETRO_WAGE_ROWS_FOR_KUB_PAYED_AMOUNT_MISSING',
  'WAGE_TYPES_POSITIVE_SUM_NEGATIVE_REQUIRED',
  'INDIVIDUAL_CHOICE_ITP1_FROM_PERIOD_REQUIRED',
];

export const wageTypeChangeSubActionCategories: SubActionCategory[] = [
  'FIXED_SUPPLEMENT_CHANGE',
  'GROSS_DEDUCTION_AMOUNT_CHANGE',
  'SALARY_EXCHANGE_AMOUNT_CHANGE',
];
