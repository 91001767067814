import React from 'react';
import { useTranslation } from 'react-i18next';
import { Employer } from 'src/types/employer/Employer';
import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import TableBase from 'src/components/Common/TableBase';
import InfoMessage from 'src/components/Common/InfoMessage';
import MandatoryDataBasicTypeRow from './MandatoryDataBacisTypeRow';
import MandatoryDataEmployerTypeRow from './MandatoryDataEmployerTypeRow';
import './mandatory-data-settings-tab.scss';

interface MandatoryDataSettingsTabProps {
  employer: Employer;
}

const MandatoryDataSettingsTab = ({ employer }: MandatoryDataSettingsTabProps) => {
  const { t } = useTranslation();

  const head = [
    { name: t('account:employers-tab.reporting-settings.mandatory-data-tab.control-type') },
    { name: t('account:employers-tab.reporting-settings.mandatory-data-tab.control-field') },
  ];

  return (
    <div className={['mandatory-data-settings-tab'].join(' ')}>
      <TableBase maxHeightPercentage={55}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th key={`ValidatingTable-${title.name}-${index}`}>{title.name}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <MandatoryDataBasicTypeRow />
          <MandatoryDataEmployerTypeRow employer={employer} />
        </TableBody>
      </TableBase>

      <InfoMessage
        message={t('account:employers-tab.reporting-settings.mandatory-data-tab.page-info-text')}
        linkText={t('account:employers-tab.reporting-settings.mandatory-data-tab.page-info-link-text')}
      />
    </div>
  );
};

export default MandatoryDataSettingsTab;
