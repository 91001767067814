import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardAction, Modal } from 'fgirot-k2-ui-components';
import { useCreateEmploymentGroup } from 'src/service/policy/CreateEmploymentGroup';
import { CreateEmploymentGroupRequest } from 'src/types/policy/request/CreateEmploymentGroupRequest';
import TextInput from 'src/components/Common/TextInput';

interface CreateEmploymentGroupModalProps {
  open: boolean;
  onClose: () => void;
  employerId: string;
  policyId: string;
}

const CreateEmploymentGroupModal: React.FunctionComponent<CreateEmploymentGroupModalProps> = ({
  open,
  onClose,
  employerId,
  policyId,
}) => {
  const { t } = useTranslation();
  const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);
  const [createGroupRequest, setCreateGroupRequest] = useState<CreateEmploymentGroupRequest>({
    employmentGroupName: '',
  });
  const createEmploymentGroup = useCreateEmploymentGroup();

  const handleEmploymentGroupName = (event: ChangeEvent<HTMLInputElement>) => {
    setCreateGroupRequest((prev) => {
      return { ...prev, employmentGroupName: event.target.value };
    });
    setCreateButtonDisabled(event.target.value.length < 3 || event.target.value.length > 64);
  };

  const handleOnClose = () => {
    setCreateButtonDisabled(true);
    setCreateGroupRequest({
      employmentGroupName: '',
    });
    onClose();
  };

  const handleCreateGroup = async () => {
    setCreateButtonDisabled(true);
    createEmploymentGroup(employerId, policyId, createGroupRequest.employmentGroupName).then(() => {
      handleOnClose();
    });
  };

  return (
    <Modal data-cy="create-employment-group-modal" centered overlayBackground open={open} onClose={handleOnClose}>
      <Card title={t('policySettings:employment-groups-tab.create-group-modal.title')} size="narrow">
        <TextInput
          label={t('policySettings:employment-groups-tab.create-group-modal.name')}
          type="text"
          validationKey="text64"
          variant="default"
          onChange={handleEmploymentGroupName}
          value={createGroupRequest.employmentGroupName}
          maxLength={65}
        />
        <CardAction>
          <Button
            label={t('common:cancel')}
            type="link"
            onClick={handleOnClose}
            data-cy="create-employment-group-modal__cancel-button"
          />
          <Button
            label={t('common:create')}
            disabled={createButtonDisabled}
            onClick={handleCreateGroup}
            data-cy="create-employment-group-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateEmploymentGroupModal;
