import { Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import './events-filter-details.scss';
import { SearchEventsFilters, SearchEventsRequest } from 'src/types/reporting/SearchEventsRequest';

interface EventsFilterDetailsProps {
  searchRequest: SearchEventsRequest;
  applyFilters: (filters: SearchEventsFilters) => void;
}

export const EventsFilterDetails = ({ searchRequest, applyFilters }: EventsFilterDetailsProps) => {
  const { t } = useTranslation();
  const filtersApplied =
    searchRequest.filters?.employerFilter?.employerIds?.length > 0 ||
    searchRequest.filters?.insuranceProviderFilter?.insuranceProviderIds?.length > 0 ||
    searchRequest.filters?.benefitTypeFilter?.benefitTypes?.length > 0 ||
    searchRequest.filters?.insuranceEventTypeFilter?.insuranceEventTypes?.length > 0 ||
    searchRequest.filters?.insuranceEventStatusFilter?.insuranceEventStatuses?.length > 0;

  const removeEmployerFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      employerFilter: {
        employerIds: [],
      },
    });
  };

  const removeInsuranceProviderFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      insuranceProviderFilter: {
        insuranceProviderIds: [],
      },
    });
  };

  const removeBenefitTypeFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      benefitTypeFilter: {
        benefitTypes: [],
      },
    });
  };

  const removeInsuranceEventTypeFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      insuranceEventTypeFilter: {
        insuranceEventTypes: [],
      },
    });
  };

  const removeInsuranceEventStatusFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      insuranceEventStatusFilter: {
        insuranceEventStatuses: [],
      },
    });
  };

  const removeReportingTypeFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      reportingTypeFilter: {
        reportingTypes: [],
      },
    });
  };

  const removeMessageStatusFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      messageStatusFilter: {
        messageStatuses: [],
      },
    });
  };

  return (
    <div className="events-filter-details">
      <Typography variant="caption" bold>
        Filter: &nbsp;
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <div className="events-filter-details__buttons">
          {searchRequest.filters?.employerFilter?.employerIds?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.employerFilter.employerIds.length + ' ' + t('reporting:search-filters.employers')
              }
              onClick={removeEmployerFilter}
            />
          )}
          {searchRequest.filters?.insuranceProviderFilter?.insuranceProviderIds?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.insuranceProviderFilter.insuranceProviderIds.length +
                ' ' +
                t('reporting:search-filters:insurance-providers')
              }
              onClick={removeInsuranceProviderFilter}
            />
          )}
          {searchRequest.filters?.benefitTypeFilter?.benefitTypes?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.benefitTypeFilter.benefitTypes.length +
                ' ' +
                t('reporting:search-filters.benefit-types')
              }
              onClick={removeBenefitTypeFilter}
            />
          )}
          {searchRequest.filters?.insuranceEventTypeFilter?.insuranceEventTypes?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.insuranceEventTypeFilter.insuranceEventTypes.length +
                ' ' +
                t('reporting:search-filters.insurance-event-types')
              }
              onClick={removeInsuranceEventTypeFilter}
            />
          )}
          {searchRequest.filters?.reportingTypeFilter?.reportingTypes?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.reportingTypeFilter.reportingTypes.length +
                ' ' +
                t('reporting:search-filters.reporting-types')
              }
              onClick={removeReportingTypeFilter}
            />
          )}
          {searchRequest.filters?.insuranceEventStatusFilter?.insuranceEventStatuses?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.insuranceEventStatusFilter.insuranceEventStatuses.length +
                ' ' +
                t('reporting:search-filters.insurance-event-statuses')
              }
              onClick={removeInsuranceEventStatusFilter}
            />
          )}
          {searchRequest.filters?.messageStatusFilter?.messageStatuses?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.messageStatusFilter.messageStatuses.length +
                ' ' +
                t('reporting:search-filters.message-statuses')
              }
              onClick={removeMessageStatusFilter}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EventsFilterDetails;
