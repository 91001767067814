import { DocumentNode, gql } from '@apollo/client';
import { Option } from 'fgirot-k2-ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { client } from 'src/graphql';
import { ACCOUNTING_COMPONENT_TYPES } from 'src/graphql/schema/query/accountingunit/AccountingComponentTypes';
import { extractDataField } from 'src/service/graphql/GraphqlUtils';
import { AccountingAdjustmentActionType } from 'src/types/accountingunit/AccountingAdjustmentActionType';
import { AccountingAdjustmentConditionType } from 'src/types/accountingunit/AccountingAdjustmentConditionTypes';
import { AccountingComponentType } from 'src/types/accountingunit/AccountingComponent';

export const useReferenceOptions = (
  type: AccountingAdjustmentActionType | AccountingAdjustmentConditionType,
  employerId: string,
): [options: Option<string>[], extended: Map<string, AccountingComponentType>] => {
  const [result, setResult] = useState<Option<string>[]>([]);
  const [extended, setExtended] = useState<Map<string, AccountingComponentType>>(new Map());
  const { t } = useTranslation();
  const query = getReferenceQuery(type);

  useEffect(() => {
    const dataField = query ? extractDataField(query) : null;
    const map = new Map<string, AccountingComponentType>();
    dataField &&
      client.query({ query, variables: { employerId } }).then((res) => {
        switch (type) {
          case 'ACCOUNTING_COMPONENT_VALUE':
            setResult(
              res.data[dataField].map((object) => {
                const { id, name } = object;
                map.set(id, object);
                return { label: name, value: id };
              }),
            );
            setExtended(map);
            break;
          case 'ACCOUNTING_COMPONENT_VALUE_MISSING':
          case 'ACCOUNTING_UNIT':
            setResult(res.data[dataField].map((object) => ({ label: object.name, value: object.id })));
            break;
          case 'ACCOUNTING_TYPE':
            setResult(
              res.data[dataField].map((object) => ({
                label: t(`accountingTypeValue:${object.accountingTypeValue.name}`),
                value: object.id,
              })),
            );
            break;

          case 'ACCOUNTING_ROW':
            setResult(
              res.data[dataField].map((object) => ({
                label: `${object.accountingUnit.name} - ${object.accountNumber}`,
                value: object.id,
              })),
            );
            break;

          default:
            setResult([]);
        }
      });
  }, [type]);

  return [result, extended];
};

const getReferenceQuery = (type: AccountingAdjustmentActionType | AccountingAdjustmentConditionType): DocumentNode => {
  switch (type) {
    case 'ACCOUNTING_COMPONENT_VALUE_MISSING': {
      return gql`
        query GetAccountingComponentTypes($employerId: UUID!) {
          accountingComponentTypes(employerId: $employerId) {
            id
            name
          }
        }
      `;
    }
    case 'ACCOUNTING_COMPONENT_VALUE': {
      return ACCOUNTING_COMPONENT_TYPES;
    }
    case 'ACCOUNTING_UNIT': {
      return gql`
        query GetAccountingUnitsAsReferenceOptions($employerId: UUID!) {
          accountingUnits(employerId: $employerId) {
            id
            name
          }
        }
      `;
    }
    case 'ACCOUNTING_TYPE': {
      return gql`
        query GetAccountingTypesAsReferenceOptions($employerId: UUID!) {
          accountingTypes(employerId: $employerId) {
            id
            accountingComponentsSource
            accountingTypeValue {
              name
              number
            }
          }
        }
      `;
    }
    case 'ACCOUNTING_ROW': {
      return gql`
        query GetAccountingRowsAsReferenceOptions($employerId: UUID!) {
          accountingRows(employerId: $employerId) {
            id
            accountingText
            accountNumber
            accountingUnit {
              name
            }
          }
        }
      `;
    }
    default: {
      return null;
    }
  }
};
