import { ExtentCalculation } from './request/EmployerWageTypeRequest';

export interface EmployerWageType {
  id: string;
  name: string;
  wageType: WageType;
  extentCalculation: ExtentCalculation;
  negateIncomingAmount?: boolean;
}

export const wageTypes = [
  'GROSS_SALARY',
  'MONTHLY_SALARY',
  'HOURLY_SALARY',
  'STRAIGHT_BONUS',
  'SUCCESSIVE_BONUS',
  'ESTIMATED_BONUS',
  'VARIABLE_SUPPLEMENT_VACATION',
  'VARIABLE_SUPPLEMENT_NON_VACATION',
  'FIXED_SUPPLEMENT_VACATION',
  'FIXED_SUPPLEMENT_NON_VACATION',
  'VARIABLE_PARTS_PREVIOUS_YEAR',
  'SALARY_EXCHANGE',
  'GROSS_DEDUCTION',
  'PARTIAL_PENSION',
  'QUALIFYING_DEDUCTION',
  'LEAVE_OF_ABSENCE_SICK',
  'LEAVE_OF_ABSENCE_GENERAL',
  'LEAVE_OF_ABSENCE_MILITARY',
  'LEAVE_OF_ABSENCE_STUDIES',
  'LEAVE_OF_ABSENCE_PARENTAL',
  'LEAVE_OF_ABSENCE_CARE_OF_CHILD',
  'STRAIGHT_BONUS_EXCHANGE',
  'SUCCESSIVE_BONUS_EXCHANGE',
  'GROUP_INSURANCE_PREMIUM',
  'ONE_TIME_PREMIUMS',
  'MISC',
  'NOT_MAPPED',
] as const;

export type WageType = typeof wageTypes[number];
