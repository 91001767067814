import { PageableResponse } from '../PageableResponse';
import { Customer } from '../customer/Customer';
import { ExternalGroup } from '../group/Group';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  identifier: string;
  identifierType: string;
  orderRef: string;
  userType: string;
  admin: boolean;
  validTo: string;
  accountNonLocked: boolean;
  customers: [Customer];
}

export interface GetUsersPage extends PageableResponse {
  externalUsers: [ExternalUser];
}
export interface ExternalUser {
  user: User;
  externalGroups: [ExternalGroup];
}
export interface CreateExternalUser {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  identifier: string;
  identifierType: string;
  orderRef: string;
  validTo: string;
  admin: boolean;
}

export enum UserType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum IdentifierType {
  BANK_ID = 'BANK_ID',
  E_ID = 'E_ID',
}

export enum UserFilter {
  ALL = 'ALL',
  ADMIN_USERS = 'ADMIN_USERS',
  USERS = 'USERS',
}
