import { Checkbox, InlineEdit } from 'fgirot-k2-ui-components';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';

interface WageTypeKubMappingInputProps {
  employerPayType: EmployerPayType;
  employerKub: EmployerKub;
  updateKubMapping: (type: 'ADD' | 'REMOVE', kubType: string) => void;
}

const WageTypeKubMappingInput: FunctionComponent<WageTypeKubMappingInputProps> = ({
  employerPayType,
  employerKub,
  updateKubMapping,
}) => {
  const [checked, setChecked] = useState<boolean>(
    employerPayType?.employerKubs?.some((kub) => kub.kubType === employerKub.kubType),
  );

  const handleToggleKubMapping = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setChecked(checked);
    if (checked) {
      updateKubMapping('ADD', employerKub.kubType);
    } else {
      updateKubMapping('REMOVE', employerKub.kubType);
    }
  };

  return (
    <InlineEdit label={employerKub.kubType} bold data-cy={`kub-switch-${employerKub.kubType}`}>
      <Checkbox disabled={false} checked={checked} onChange={handleToggleKubMapping} />
    </InlineEdit>
  );
};

export default WageTypeKubMappingInput;
