import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { useApolloQuery, useApolloQueryList } from '../graphql/Query';
import { GET_EMPLOYER_WAGE_TYPES } from 'src/graphql/schema/query/employer/GetEmployerWageTypes';
import { Service } from 'src/types/Service';

export const useEmployerWageTypes = (employerId: string): EmployerWageType[] => {
  return useApolloQueryList(GET_EMPLOYER_WAGE_TYPES, { variables: { employerId }, skip: !employerId });
};
export const useApolloQueryEmployerWageTypes = (employerId: string): Service<EmployerWageType[]> => {
  return useApolloQuery<EmployerWageType[]>(GET_EMPLOYER_WAGE_TYPES, { variables: { employerId }, skip: !employerId });
};
