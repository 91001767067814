import { InputParameter, InputParameterEnum } from 'src/types/documents/DocumentTemplate';
import { Option } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';

export const getInputParametersOptions = (params: InputParameter[]): Option<string>[] => {
  const { t } = useTranslation();

  const inputParameterOptions: Option<string>[] = [];
  if (params?.includes(InputParameterEnum.INPUT_PERIODTYPE_ACCOUNTING)) {
    const option = {
      label: t('documents:input-parameters.INPUT_PERIODTYPE_ACCOUNTING'),
      value: 'INPUT_PERIODTYPE_ACCOUNTING',
    };
    inputParameterOptions?.push(option);
  }

  if (params?.includes(InputParameterEnum.INPUT_PERIODTYPE_PREMIUM)) {
    const option = {
      label: t('documents:input-parameters.INPUT_PERIODTYPE_PREMIUM'),
      value: 'INPUT_PERIODTYPE_PREMIUM',
    };
    inputParameterOptions?.push(option);
  }

  if (params?.includes(InputParameterEnum.INPUT_PERIODTYPE_PAYMENT)) {
    const option = {
      label: t('documents:input-parameters.INPUT_PERIODTYPE_PAYMENT'),
      value: 'INPUT_PERIODTYPE_PAYMENT',
    };
    inputParameterOptions?.push(option);
  }
  return inputParameterOptions;
};
