import { InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import WageTypeCalculation from './WageTypeCalculation/WageTypeCalculation';
import BonusCalculation from './BonusCalculation';
import './pgl-calculation-details.scss';
import { PglBonusComponent } from 'src/types/policy/PglBonusComponent';
import { PglWageType } from 'src/types/policy/PglWageType';
import CommonPglCalculationsDrawer from './CommonPglCalculationsDrawer/CommonPglCalculationsDrawer';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { PglType } from 'src/types/policy/PglType';
import PglTypeSelect from './PglTypeSelect';
import { isSPglWageType } from 'src/util/PolicyUtil';

interface PglCalculationDetailsProps {
  onBackClick: () => void;
  onClose: () => void;
  employerId: string;
  pglWageType?: PglWageType;
  pglBonusComponent?: PglBonusComponent;
  pglRuleId: string;
  policyState: PolicyState;
  policyId: string;
  pglRuleName: string;
  employerWageTypes: EmployerWageType[];
  pglType: PglType;
}

export const PglCalculationDetails = ({
  onBackClick,
  onClose,
  employerId,
  pglWageType,
  pglBonusComponent,
  pglRuleId,
  policyState,
  policyId,
  pglRuleName,
  employerWageTypes,
  pglType,
}: PglCalculationDetailsProps) => {
  const { t } = useTranslation(['common', 'account', 'wageTypes']);
  const inDraft = policyState === 'DRAFT';
  const isUpdating = !!pglWageType || !!pglBonusComponent;

  const [selectedPglType, setSelectedPglType] = useState<PglType>(pglType);

  const filteredEmployerWageTypes = employerWageTypes
    .filter((wt) => wt.wageType !== 'NOT_MAPPED')
    .filter((wt) => selectedPglType == 'PGL' || (selectedPglType == 'SPGL' && isSPglWageType(wt.wageType)));

  const wageTypeOptions = filteredEmployerWageTypes.map((wt) => ({
    value: wt,
    label: wt.name,
  }));

  const [selectedWageType, setSelectedWageType] = useState(
    wageTypeOptions.find((option) => option.value.id === pglWageType?.employerWageTypeId),
  );

  useEffect(() => {
    (pglBonusComponent?.bonusAverageType === 'STRAIGHT' || pglBonusComponent?.bonusAverageType === 'SUCCESSIVE') &&
      setSelectedWageType(wageTypeOptions.find((option) => option.value.id === pglBonusComponent?.employerWageTypeId));
  }, [pglBonusComponent]);

  const handleWageTypeChange = (option: Option<EmployerWageType>) => {
    setSelectedWageType(option);
  };

  const handleSetSelectedType = (pglType: PglType) => {
    setSelectedWageType(null);
    setSelectedPglType(pglType);
  };

  return (
    <CommonPglCalculationsDrawer onClose={onClose} onBackClick={onBackClick} pglRuleName={pglRuleName}>
      <ListGroup variant="inline-edit">
        <PglTypeSelect pglType={selectedPglType} setPglType={handleSetSelectedType} disabled={isUpdating} />

        <InlineEdit label={t('account:pgl-rules:drawer:wage-type:wage-type')} bold>
          <Select
            placeholder={t(
              `policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.calculation-variables.reference.placeholder`,
            )}
            options={wageTypeOptions}
            selected={selectedWageType}
            onChange={handleWageTypeChange}
            data-cy="calculation-variable-list-group__dropdown-calculation"
            disabled={!inDraft || isUpdating}
            small
          />
        </InlineEdit>
      </ListGroup>

      {selectedWageType &&
        selectedWageType.value.wageType !== 'STRAIGHT_BONUS' &&
        selectedWageType.value.wageType !== 'SUCCESSIVE_BONUS' && (
          <WageTypeCalculation
            employerId={employerId}
            selectedWageType={selectedWageType}
            pglWageType={pglWageType}
            inDraft={inDraft}
            policyId={policyId}
            pglRuleId={pglRuleId}
            onComplete={onBackClick}
            pglType={selectedPglType}
          />
        )}
      {selectedWageType &&
        (selectedWageType.value.wageType === 'STRAIGHT_BONUS' ||
          selectedWageType.value.wageType === 'SUCCESSIVE_BONUS') && (
          <BonusCalculation
            employerId={employerId}
            selectedWageType={selectedWageType}
            pglBonusComponent={pglBonusComponent}
            inDraft={inDraft}
            policyId={policyId}
            pglRuleId={pglRuleId}
            onComplete={onBackClick}
          />
        )}
    </CommonPglCalculationsDrawer>
  );
};

export default PglCalculationDetails;
