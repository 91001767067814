import { InlineEdit, ListGroup, Option } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Month } from 'src/types/Month';
import PeriodInput from '../PeriodInput';

interface SingleYearMonthProps {
  selectedMonth: Option<Month>;
  setSelectedMonth: React.Dispatch<React.SetStateAction<Option<Month>>>;
  selectedYear: Option<number>;
  setSelectedYear: React.Dispatch<React.SetStateAction<Option<number>>>;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<string>>;
}

const SingleYearMonth: FC<SingleYearMonthProps> = ({
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
  setSelectedPeriod,
}) => {
  const { t } = useTranslation();

  return (
    <ListGroup variant="inline-edit">
      <InlineEdit
        label={t('documents:document-templates-tab.drawer.generate-document-drawer-tab.period-label')}
        bold
        mandatory
      >
        <PeriodInput
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          setSelectedPeriod={setSelectedPeriod}
        />
      </InlineEdit>
    </ListGroup>
  );
};

export default SingleYearMonth;
