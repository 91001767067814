import './certify-conditions-modal.scss';
import { Modal, Card, Icon, Typography, Button, CardAction } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchInsuranceProviders } from 'src/service/insurance/GetInsuranceProviders';
import { CertifyConditionMetadata } from 'src/types/CertifyConditionMetadata';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import Provider from '../ShowConditionsModal/Provider';
import { arrayToMap, unique } from 'src/util/ArrayUtil';
import ScrollBase from 'src/components/Common/ScrollBase';
import { uniqueConditions } from 'src/util/ConditionUtil';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import LoadingPage from 'src/components/Common/LoadingPage';
import { useConditionsMetadata } from './CertifyConditionsHook';
import { EmployeeReadyToFinalize } from 'src/types/EmployeeReadyToFinalize';

interface CertifyConditionsModalProps {
  open: boolean;
  handleExit: () => void;
  handleFinalize: (employees: EmployeeReadyToFinalize[]) => void;
}

const CertifyConditionsModal = ({ open, handleExit, handleFinalize }: CertifyConditionsModalProps) => {
  const { t } = useTranslation();
  const [insuranceProviders, setInsuranceProviders] = useState<InsuranceProvider[]>();
  const [checkedConditionIds, setCheckedConditionIds] = useState<string[]>([]);

  useEffect(() => {
    fetchInsuranceProviders().then((data) => setInsuranceProviders(data));
  }, []);

  const service = useConditionsMetadata();

  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>{t('common:error')}</div>;
    case LOADED:
      break;
  }

  const employeesReadyToFinalize = service.payload;

  const conditionsMetadata = employeesReadyToFinalize?.flatMap((employeeData) => employeeData.conditionsMetadata);

  const uniqueConditionMetadatas =
    conditionsMetadata && conditionsMetadata.length > 0
      ? Array.from(uniqueConditions(conditionsMetadata).values())
      : [];
  const groupedConditionMetadatas: Map<string, Array<CertifyConditionMetadata>> = arrayToMap(
    uniqueConditionMetadatas,
    'insuranceProviderId',
  );

  const handleOnChangeChecked = (conditionIds: string[], operation: string) => {
    if (operation === 'ADD') {
      const uniqueConditionIds = unique([...checkedConditionIds, ...conditionIds]);
      setCheckedConditionIds([...uniqueConditionIds]);
    } else {
      setCheckedConditionIds([...checkedConditionIds.filter((conditionId) => !conditionIds.includes(conditionId))]);
    }
  };

  const handleOnClickCertify = () => {
    if (!employeesReadyToFinalize) {
      handleExit();
      return;
    }
    const employees: EmployeeReadyToFinalize[] = employeesReadyToFinalize
      .filter((employee) =>
        employee.conditionsMetadata.every((conditionMetadata) =>
          checkedConditionIds.includes(conditionMetadata.insuranceProviderProductConditionId),
        ),
      )
      .map((employee) => ({
        employeeId: employee.employeeId,
        employerId: employee.employerId,
      }));

    handleFinalize(employees);
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleExit}>
      <Card icon={<Icon type="Alert" />} title={t('certify:conditions-modal.confirm')} defaultClose={false}>
        <Typography>{t('certify:conditions-modal.subtitle-check')}</Typography>
        <ScrollBase className="certify-conditions-modal__content" maxHeightPercentage={40}>
          {groupedConditionMetadatas &&
            insuranceProviders &&
            Array.from(groupedConditionMetadatas.keys()).map((key) => (
              <Provider
                key={key}
                certify={true}
                name={insuranceProviders.find((provider) => provider.id === key)?.legalName}
                values={groupedConditionMetadatas.get(key)}
                handleOnChangeChecked={handleOnChangeChecked}
              />
            ))}
        </ScrollBase>

        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleExit} />
          <Button label={t('certify:conditions-modal.i-certify')} onClick={handleOnClickCertify} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CertifyConditionsModal;
