import { Accordion, ListItem, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleCardBox from 'src/components/Common/ModuleCardBox';
import { UnpaidInvoice } from 'src/types/invoice/UnpaidInvoice';
import { convertMonthNumberStringToEnum } from 'src/util/date/Months';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import UnpaidInvoiceDetails from './UnpaidInvoiceDetails';
import './unpaid-invoices-list-item.scss';

interface UnpaidInvoicesListItemProps {
  groupedInvoicesByPaymentDueDate: UnpaidInvoice[];
  paymentDueDate: string;
}

const UnpaidInvoicesListItem: FC<UnpaidInvoicesListItemProps> = ({
  groupedInvoicesByPaymentDueDate,
  paymentDueDate,
}) => {
  const { t } = useTranslation();
  const totalRemainingAmount = groupedInvoicesByPaymentDueDate.reduce((acc, curr) => acc + curr.remainingAmount, 0);

  const header = (
    <div className="unpaid-invoices-list-item__header">
      <ModuleCardBox
        data-testid="unpaid-invoices-list-item__date"
        className="unpaid-invoices-list-item__date"
        value={
          `${paymentDueDate.substring(8, 10)} ` +
          t(`common:months.${convertMonthNumberStringToEnum(paymentDueDate.substring(5, 7))}`) +
          ` ${paymentDueDate.substring(0, 4)}`
        }
      />
      <Typography bold variant="body2">
        {t('economy:economy-overview-tab.total-remaining-amount') +
          formatSweAmountTextWithSuffixNoDecimal(`${totalRemainingAmount}`)}
      </Typography>
    </div>
  );

  return (
    <div className="unpaid-invoices-list-item">
      <ListItem compressed border data-cy="unpaid-invoices-list-item" header={header}>
        <Accordion
          header={<Typography variant="body2">{t('economy:economy-overview-tab.accordion-title')}</Typography>}
        >
          <UnpaidInvoiceDetails groupedInvoicesByPaymentDueDate={groupedInvoicesByPaymentDueDate} />
        </Accordion>
      </ListItem>
    </div>
  );
};
export default UnpaidInvoicesListItem;
