import { Banner, Button, Card, CardAction, Icon, Modal, Pill, ToastContext, Typography } from 'fgirot-k2-ui-components';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { usePublishDraftEmployee } from 'src/service/employee/PublishDraftEmployee';
import { CheckpointsInPeriodError } from 'src/types/employees/PublishDraftEmployeeResponse';
import './publish-draft-employee-modal.scss';
import { usePeriodText } from 'src/util/date/PeriodUtil';

interface PublishDraftEmployeeModalProps {
  open: boolean;
  handleClose: () => void;
  employeeId: string;
  employerId: string;
}

function PublishDraftEmployeeModal({ open, handleClose, employeeId, employerId }: PublishDraftEmployeeModalProps) {
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);
  const publishDraftEmployee = usePublishDraftEmployee();
  const [periodCheckpointErrors, setPeriodCheckpointErrors] = useState<CheckpointsInPeriodError[]>([]);
  const [loading, setLoading] = useState(false);
  const { displayToast } = useContext(ToastContext);

  const { t } = useTranslation();

  const handlePublishDraft = async () => {
    setLoading(true);
    setPeriodCheckpointErrors([]);
    setPublishButtonDisabled(true);

    const response = await publishDraftEmployee(employeeId, employerId);

    if (!response.success) {
      setPeriodCheckpointErrors(response.checkpointErrors);
    } else {
      displayPublishSucessfulToast();
      setPublishButtonDisabled(false);
      handleClose();
    }

    setLoading(false);
  };

  const displayPublishSucessfulToast = () => {
    const successMessage = t('userFeedback:PublishDraftEmployee.success');
    displayToast('success', t('common:success'), successMessage);
  };

  useEffect(() => {
    if (open) {
      setPublishButtonDisabled(false);
      setPeriodCheckpointErrors([]);
    }
  }, [open]);

  return (
    <Modal centered overlayBackground open={open} onClose={handleClose}>
      <Card
        icon={<Icon type="CheckRounded" stroke="#479890" />}
        title={t('employees:actions.publish')}
        defaultClose
        onClose={handleClose}
        size="wide"
      >
        <div className="publish-draft-employee-modal">
          <When condition={periodCheckpointErrors.length === 0}>
            <Typography component="span">{t('employees:actions.publish-draft-body')}</Typography>
          </When>
          <When condition={periodCheckpointErrors.length > 0}>
            <>
              <Banner type="warning" message={t('employees:actions.publish-would-result-in-checkpoints-message')} />
              <div>
                {periodCheckpointErrors.map((error) => (
                  <div key={error.period} className="publish-draft-employee-modal__period">
                    <PeriodText period={error.period} />
                    <ul className="publish-draft-employee-modal__errors">
                      {error.resultingCheckpoints.map((checkpoint) => (
                        <li key={checkpoint.subActionCategory}>
                          <Pill type="warning" label={t(`subActionCategories:${checkpoint.subActionCategory}`)} />
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </>
          </When>
        </div>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleClose} />
          <Button
            loading={loading}
            disabled={publishButtonDisabled}
            type="primary"
            label={t('employees:actions.publish')}
            onClick={handlePublishDraft}
            data-cy="publish-draft-employee-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
}

function PeriodText({ period }: { period: string }) {
  const formattedPeriod = usePeriodText(period);

  return (
    <Typography variant="subtitle" bold>
      {formattedPeriod}
    </Typography>
  );
}

export default PublishDraftEmployeeModal;
