import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { InvoiceRowSortField } from './InvoiceRowSortField';
import { useTranslation } from 'react-i18next';

interface InvoiceRowsTableHeadProps {
  sortDirection: SortDirection;
  sortField: InvoiceRowSortField;
  onSortFieldChange: (sortField: InvoiceRowSortField) => void;
  onSortDirectionChange: () => void;
}
const InvoiceRowsTableHead: FC<InvoiceRowsTableHeadProps> = ({
  sortDirection,
  sortField,
  onSortDirectionChange,
  onSortFieldChange,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('employee.personNumber');
        onSortDirectionChange();
      },
      fieldName: 'employee.personNumber',
      title: t('invoice:invoice-rows.person-number'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('employee.firstName');
        onSortDirectionChange();
      },
      fieldName: 'employee.firstName',
      title: t('invoice:invoice-rows.first-name'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('employee.lastName');
        onSortDirectionChange();
      },
      fieldName: 'employee.lastName',
      title: t('invoice:invoice-rows.last-name'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('employee.employmentNumber');
        onSortDirectionChange();
      },
      fieldName: 'employee.employmentNumber',
      title: t('invoice:invoice-rows.employment-number'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('benefitType');
        onSortDirectionChange();
      },
      fieldName: 'benefitType',
      title: t('invoice:invoice-rows.benefit-type'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceNumber');
        onSortDirectionChange();
      },
      fieldName: 'insuranceNumber',
      title: t('invoice:invoice-rows.insurance-number'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceTypeMappingName');
        onSortDirectionChange();
      },
      fieldName: 'insuranceTypeMappingName',
      title: t('invoice:invoice-rows.insurance-type-mapping-name'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('contractBenefitGroup');
        onSortDirectionChange();
      },
      fieldName: 'contractBenefitGroup',
      title: t('invoice:invoice-rows.contract-benefit-group'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('contractBenefitGroupName');
        onSortDirectionChange();
      },
      fieldName: 'contractBenefitGroupName',
      title: t('invoice:invoice-rows.contract-benefit-group-name'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('note');
        onSortDirectionChange();
      },
      fieldName: 'note',
      title: t('invoice:invoice-rows.comment'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('invoicePeriod.periodStart');
        onSortDirectionChange();
      },
      fieldName: 'invoicePeriod.periodStart',
      title: t('invoice:invoice-rows.from-date'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('invoicePeriod.periodEnd');
        onSortDirectionChange();
      },
      fieldName: 'invoicePeriod.periodEnd',
      title: t('invoice:invoice-rows.to-date'),
    },
    {
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('amount');
        onSortDirectionChange();
      },
      fieldName: 'amount',
      title: t('invoice:invoice-rows.amount'),
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head, index) => (
        <Th
          includeSortButtons
          sortDirection={sortField === head.fieldName ? sortDirection : undefined}
          handleSortDirectionChange={head.handleSortDirectionChange}
          key={`ValidatingTable-${head.title}-${index}`}
        >
          {head.title}
        </Th>
      ))}
    </TableRow>
  );
};

export default InvoiceRowsTableHead;
