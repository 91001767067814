import { CREATE_ACCOUNTING_ADJUSTMENT_RULE } from 'src/graphql/schema/mutation/accountingunit/CreateAccountingAdjustmentRule';
import { useApolloMutation } from '../graphql/Mutation';
import { CreateAccountingAdjustmentRuleInput } from 'src/types/accountingunit/AccountingAdjustmentRule';
import { ACCOUNTING_ADJUSTMENT_RULES } from 'src/graphql/schema/query/accountingunit/AccountingAdjustmentRules';
import { DELETE_ACCOUNTING_ADJUSTMENT_RULE } from 'src/graphql/schema/mutation/accountingunit/DeleteAccountingAdjustmentRule';

export const useCreateAccountingAdjustmentRule = () => {
  const apolloMutation = useApolloMutation();
  const createAccountingAdjustmentRule = (request: CreateAccountingAdjustmentRuleInput, employerId: string) =>
    apolloMutation(CREATE_ACCOUNTING_ADJUSTMENT_RULE, employerId, { request }, [ACCOUNTING_ADJUSTMENT_RULES]);
  return createAccountingAdjustmentRule;
};

export const useDeleteAccountingAdjustmentRule = () => {
  const apolloMutation = useApolloMutation();
  const deleteAccountingAdjustmentRule = (accountingAdjustmentRuleId: string, employerId: string) =>
    apolloMutation(DELETE_ACCOUNTING_ADJUSTMENT_RULE, employerId, { accountingAdjustmentRuleId }, [
      ACCOUNTING_ADJUSTMENT_RULES,
    ]);
  return deleteAccountingAdjustmentRule;
};
