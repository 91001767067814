import { gql } from '@apollo/client';

export const DELETE_CONTRACT_AGREEMENT_COST_CENTRE = gql`
  mutation DeleteContractAgreementCostCentre($insuranceProviderContractId: UUID!, $agreementCostCentreId: UUID!) {
    deleteContractAgreementCostCentre(
      insuranceProviderContractId: $insuranceProviderContractId
      agreementCostCentreId: $agreementCostCentreId
    ) {
      id
    }
  }
`;
