import { gql } from '@apollo/client';

export const GET_EMPLOYEE_COMMENTS = gql`
  query GetEmployeeComments($employeeId: UUID!) {
    employeeComments(employeeId: $employeeId) {
      id
      comment
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;
