import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_POLICY_ENTITIES } from 'src/graphql/schema/query/policy/GetPolicyEntities';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import { sortPolicyEntities } from 'src/util/PolicyUtil';

export const usePolicyEntities = (employerId: string): Service<PolicyEntity[]> => {
  const [result, setResult] = useState<Service<PolicyEntity[]>>({
    status: INIT,
  });

  const { loading, error, data } = useQuery(GET_POLICY_ENTITIES, {
    variables: { employerId },
    skip: !employerId,
  });

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data && setResult({ status: LOADED, payload: sortPolicyEntities(data.policyEntities) });
  }, [loading, error, data]);

  return result;
};
