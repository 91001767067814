export type EmploymentCategory = 'UNKNOWN_EMPLOYMENT_CATEGORY' | 'WHITECOLLAR_CATEGORY' | 'BLUECOLLAR_CATEGORY';

export const UNKNOWN_EMPLOYMENT_CATEGORY = 'UNKNOWN_EMPLOYMENT_CATEGORY';
const WHITECOLLAR_CATEGORY = 'WHITECOLLAR_CATEGORY';
const BLUECOLLAR_CATEGORY = 'BLUECOLLAR_CATEGORY';

export const employmentCategories: EmploymentCategory[] = [
  WHITECOLLAR_CATEGORY,
  BLUECOLLAR_CATEGORY,
  UNKNOWN_EMPLOYMENT_CATEGORY,
];
