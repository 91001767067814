import { gql } from '@apollo/client';

export const GET_EMPLOYERS_IN_CUSTOMER = gql`
  query GetEmployersInCustomer($customerId: UUID!) {
    employers(customerId: $customerId) {
      id
      legalName
      organisationNumber
    }
  }
`;
