import { gql } from '@apollo/client';

export const ACCOUNTING_UNIT = gql`
  query GetAccountingUnit($accountingUnitId: UUID!) {
    accountingUnit(accountingUnitId: $accountingUnitId) {
      id
      name
      description
      defaultAccountingUnit
      chartOfAccounts {
        id
        name
        description
        accountingRows {
          id
          accountNumber
          accountingText
          accountingType {
            id
            accountingTypeValue {
              name
              number
            }
          }
        }
      }
    }
  }
`;
