import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import When from 'src/components/Common/When';
import { Employee } from 'src/types/Employee';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { EmployeeWagesOverviewDrawerTab } from 'src/types/tabs/EmployeeWagesOverviewDrawerTab';
import WageTypesOverviewDrawer from './WageTypesOverviewDrawer';
import YearlyOverview from './YearlyOverview';
import WageTypeDetailsDrawer from './WageTypeDetailsDrawer';
import './wages-overview-drawer.scss';
import WageDetailsDrawer from './WageDetailsDrawer';
import CreateDraftWageDrawer from './CreateDraftWageDrawer';
import {
  findAggregatedWageByPayTypeNumber,
  findAggregatedWageByWageTypeId,
  findWageByIdInAggregatedEmployeeCard,
} from 'src/util/WageUtil';

interface WagesOverviewDrawerProps {
  open: boolean;
  employee: Employee;
  onDisplayWageTypesOverviewDrawer: (month: number) => void;
  onClose: () => void;
  onBackClick: () => void;
  period: string;
  selectedWageMonth: number;
  isDraft: boolean;
  aggregatedEmployeeCards: AggregatedEmployeeCard[];
}

const WagesOverviewDrawer = ({
  open,
  onClose,
  onBackClick,
  period,
  onDisplayWageTypesOverviewDrawer,
  aggregatedEmployeeCards,
  employee,
  selectedWageMonth,
  isDraft,
}: WagesOverviewDrawerProps) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<EmployeeWagesOverviewDrawerTab>(EmployeeWagesOverviewDrawerTab.PERIOD);

  const [selectedWageTypeId, setSelectedWageTypeId] = useState<string>();
  const [selectedPayTypeNumber, setSelectedPayTypeNumber] = useState<string>();
  const [selectedWageId, setSelectedWageId] = useState<string>();
  const [isCreatingWage, setIsCreatingWage] = useState(false);

  const yearFromPeriod = period.slice(0, 4);

  const selectedPeriod = period.slice(0, 4) + selectedWageMonth?.toString().padStart(2, '0');

  const handleDisplayOverviewDrawer = () => {
    setSelectedWageId(null);
    setSelectedWageTypeId(null);
    setIsCreatingWage(false);
    setSelectedPayTypeNumber(null);
  };

  const handleDisplayCreateWageDrawer = () => {
    setIsCreatingWage(true);
    setSelectedWageTypeId(null);
    setSelectedWageId(null);
    setSelectedPayTypeNumber(null);
  };

  const handleDisplayWageTypeDrawer = (wageTypeId: string, payTypeNumber: string) => {
    setSelectedWageId(null);
    setIsCreatingWage(false);
    if (wageTypeId) {
      setSelectedWageTypeId(wageTypeId);
    } else {
      setSelectedPayTypeNumber(payTypeNumber);
    }
  };

  const handleDisplayPayTypeDrawer = (payTypeNumber: string) => {
    setSelectedWageId(null);
    setIsCreatingWage(false);
    setSelectedWageTypeId(null);
    setSelectedPayTypeNumber(payTypeNumber);
  };

  const employeeCard = aggregatedEmployeeCards?.find((employeeCard) => employeeCard.period === selectedPeriod);

  const selectedAggregatedWageType = useMemo(() => {
    return findAggregatedWageByWageTypeId(employeeCard, selectedWageTypeId);
  }, [employeeCard, selectedWageTypeId]);

  const selectedAggregatedPayType = useMemo(() => {
    return findAggregatedWageByPayTypeNumber(employeeCard, selectedPayTypeNumber);
  }, [employeeCard, selectedPayTypeNumber]);

  const selectedWage = useMemo(() => {
    return findWageByIdInAggregatedEmployeeCard(employeeCard, selectedWageId);
  }, [employeeCard, selectedWageId, selectedWageTypeId, selectedPayTypeNumber]);

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <When condition={!selectedWageMonth}>
        <DrawerBase
          title={
            <div className="wages-overview-drawer__title">
              <Typography variant="h2" bold>
                {t('wages:wages')}
              </Typography>
              <Typography variant="subtitle">{yearFromPeriod}</Typography>
            </div>
          }
          onClose={onClose}
        >
          <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
            <Tab title={t('wages:period')}>
              <YearlyOverview
                aggregatedEmployeeCards={aggregatedEmployeeCards}
                period={period}
                onClick={onDisplayWageTypesOverviewDrawer}
              />
            </Tab>
          </Tabs>
        </DrawerBase>
      </When>
      <When condition={(!!selectedWageTypeId || !!selectedPayTypeNumber) && !selectedWageId && !isCreatingWage}>
        <WageTypeDetailsDrawer
          selectedPeriod={selectedPeriod}
          employerId={employee.employerId}
          selectedWageType={selectedAggregatedWageType}
          onBackClick={handleDisplayOverviewDrawer}
          selectedPayType={selectedAggregatedPayType}
          onClose={onClose}
          onSelectWage={setSelectedWageId}
          isDraft={isDraft}
        />
      </When>
      <When
        condition={
          selectedWageMonth &&
          !selectedAggregatedWageType &&
          !selectedAggregatedPayType &&
          !selectedWage &&
          !isCreatingWage
        }
      >
        <WageTypesOverviewDrawer
          employerId={employee.employerId}
          aggregatedEmployeeCards={aggregatedEmployeeCards}
          onSelectWageType={setSelectedWageTypeId}
          onSelectPayType={handleDisplayPayTypeDrawer}
          onSelectWage={setSelectedWageId}
          displayCreateDraftWageDrawer={handleDisplayCreateWageDrawer}
          selectedPeriod={selectedPeriod}
          onClose={onClose}
          onBackClick={onBackClick}
          isDraft={isDraft}
        />
      </When>
      <When condition={selectedWageMonth && !!selectedWage && !isCreatingWage}>
        <WageDetailsDrawer
          wage={selectedWage}
          wageType={selectedAggregatedWageType}
          onBackClick={handleDisplayWageTypeDrawer}
          onDisplayOverviewDrawer={handleDisplayOverviewDrawer}
          onClose={onClose}
          employeeCardId={employeeCard?.id}
          isDraft={isDraft}
          period={selectedPeriod}
          employerId={employee.employerId}
        />
      </When>
      <When condition={isDraft && selectedWageMonth && isCreatingWage && !selectedAggregatedWageType && !selectedWage}>
        <CreateDraftWageDrawer
          period={selectedPeriod}
          employerId={employee.employerId}
          employeeCardId={employeeCard?.id}
          onBackClick={handleDisplayOverviewDrawer}
          onClose={onClose}
        />
      </When>
    </DrawerModal>
  );
};

export default WagesOverviewDrawer;
