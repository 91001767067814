import { gql } from '@apollo/client';

export const GET_LATEST_EMPLOYEE_CARD_WITH_SOURCE_WAGE_FILE = gql`
  query GetLatestEmployeeCardWithSourceWageFile($employeeId: UUID!) {
    latestEmployeeCardWithSourceWageFile(employeeId: $employeeId) {
      id
      period
    }
  }
`;
