import { gql } from '@apollo/client';

export const CREATE_EMPLOYER_KUB = gql`
  mutation CreateEmployerKub($request: CreateEmployerKubRequest!) {
    createEmployerKub(request: $request) {
      id
      employerId
      kubType
      description
      employerPayTypes {
        id
        name
        number
      }
    }
  }
`;
