import { Badge, Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import ProductsTab from './ProductsTab/ProductsTab';
import { useTranslation } from 'react-i18next';
import CostCentresTab from './CostCentresTab/CostCentresTab';
import DrawerBase from 'src/components/Common/DrawerBase';
import { AgreementRegistryDrawerTab } from 'src/types/tabs/AgreementRegistryDrawerTab';
import { stringOrBlank } from 'src/util/StringUtil';
import AgreementStatusTab from './AgreementStatusTab';
import AgreementSettingsTab from './AgreementSettingsTab';

interface AgreementRegistryOverviewProps {
  contract: InsuranceProviderContract;
  onClose: () => void;
  onCreateNewAgreedProduct: () => void;
  onSelectAgreedProduct: (id: string) => void;
  onCreateNewAgreementCostCentre: () => void;
  onSelectAgreementCostCentre: (id: string) => void;
  currentTab: AgreementRegistryDrawerTab;
}

const AgreementRegistryOverview: FC<AgreementRegistryOverviewProps> = ({
  contract,
  onClose,
  onCreateNewAgreedProduct,
  onSelectAgreedProduct,
  onCreateNewAgreementCostCentre,
  onSelectAgreementCostCentre,
  currentTab,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerBase
      onClose={onClose}
      title={contract.name}
      subTitle={
        <div className="agreement-registry-drawer__sub-title">
          <Typography>{stringOrBlank(contract.insuranceProvider?.legalName)}</Typography>
          <Pill type="neutral" label={contract.agreementNumber} />
          {contract.status === 'ACTIVE' ? (
            <Badge
              label={t(`agreementRegistrySettings:agreement-status.${'ACTIVE'}`)}
              type="success"
              circle="small"
              background="light"
            />
          ) : (
            <Badge
              label={t(`agreementRegistrySettings:agreement-status.${'TERMINATED'}`)}
              type="warning"
              circle="small"
              background="light"
            />
          )}
        </div>
      }
    >
      <>
        <Tabs tabIndex={currentTab}>
          <Tab title={t('agreementRegistrySettings:products')}>
            <ProductsTab
              agreedProducts={contract.agreedProducts}
              onCreateNewAgreedProduct={onCreateNewAgreedProduct}
              onSelectAgreedProduct={onSelectAgreedProduct}
            />
          </Tab>
          <Tab title={t('agreementRegistrySettings:agreement-cost-centres')}>
            <CostCentresTab
              agreementCostCenters={contract.agreementCostCentres}
              onCreateNewAgreementCostCentre={onCreateNewAgreementCostCentre}
              onSelectAgreementCostCentre={onSelectAgreementCostCentre}
            />
          </Tab>
          <Tab title={t('agreementRegistrySettings:status')}>
            <AgreementStatusTab contract={contract} />
          </Tab>
          <Tab title={t('agreementRegistrySettings:settings')}>
            <AgreementSettingsTab contract={contract} />
          </Tab>
        </Tabs>
      </>
    </DrawerBase>
  );
};

export default AgreementRegistryOverview;
