import { gql } from '@apollo/client';

export const GET_CONTRACTED_INSURANCE_PROVIDERS = gql`
  query GetContractedInsuranceProviders {
    contractedInsuranceProviders {
      employerId
      insuranceProviders {
        id
        legalName
      }
    }
  }
`;
