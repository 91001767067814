import { SearchAccountingItemsRequest } from 'src/types/ledgerStorage/SearchAccountingItemsRequest';

export const getInitialSearchAccountingItemsRequest = (): SearchAccountingItemsRequest => {
  return {
    searchInput: '',
    pageNumber: 0,
    pageSize: 25,
    filters: {
      employerId: undefined,
      accountingPeriodFilter: {
        fromPeriod: null,
        toPeriod: null,
      },
      insuranceProviderIdsFilter: null,
    },
  };
};
