import { Banner, ListGroup } from 'fgirot-k2-ui-components';
import React from 'react';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { Employer } from 'src/types/employer/Employer';
import { EmployerReportingFlowSetting } from 'src/types/employer/EmployerReportingFlowSetting';
import AutomaticReportingToggle from './AutomaticReportingToggle';
import './disable-automatic-reporting-drawer.scss';
import { useTranslation } from 'react-i18next';

interface DisableAutomaticReportingDrawerProps {
  onClose: () => void;
  reportingFlowSettings: EmployerReportingFlowSetting[];
  employers: Employer[];
  open: boolean;
}

function DisableAutomaticReportingDrawer({
  onClose,
  employers,
  open,
  reportingFlowSettings,
}: DisableAutomaticReportingDrawerProps) {
  const { t } = useTranslation();

  return (
    <DrawerModal open={open} onClose={onClose} overlayBackground overlayOnClose>
      <DrawerBase onClose={onClose} title={t('reporting:disable-automatic-reporting.disable-automatic-reporting')}>
        <div className="disable-automatic-reporting-drawer">
          <Banner type="announcement" message={t('reporting:disable-automatic-reporting.info-text')} />
          <div>
            <ListGroup variant="inline-edit">
              {reportingFlowSettings.map((setting) => {
                const employer = employers.find((e) => e.id === setting.employerId);
                return (
                  <AutomaticReportingToggle key={setting.id} employer={employer} reportingFlowSettings={setting} />
                );
              })}
            </ListGroup>
          </div>
        </div>
      </DrawerBase>
    </DrawerModal>
  );
}

export default DisableAutomaticReportingDrawer;
