import { Button, Collapsible, InlineEdit, ListGroup, Switch, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextInput from 'src/components/Common/TextInput';
import { ContractSetting } from 'src/types/contract/ContractSetting';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import { usePatchContractSetting } from 'src/service/contract/PatchContractSetting';
import { useCreateContractSetting } from 'src/service/contract/CreateContractSetting';
import { isBlank } from 'src/util/StringUtil';

interface CollectumTglSettingProps {
  insuranceProviderContractId: string;
  contractSetting: ContractSetting;
}

const CollectumTglSetting: FC<CollectumTglSettingProps> = ({ insuranceProviderContractId, contractSetting }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();

  const patchContractSetting = usePatchContractSetting();
  const createContractSetting = useCreateContractSetting();

  const [collectumTgl, setCollctumTgl] = useState<{ fromDate: string; toDate: string }>({
    fromDate: contractSetting?.fromDate ? contractSetting?.fromDate.substring(0, 7) : '',
    toDate: contractSetting?.toDate ? contractSetting?.toDate.substring(0, 7) : '',
  });
  const { error: fromDateError } = inputSchema.validate(
    {
      ['yearAndMonth']: collectumTgl.fromDate,
    },
    { abortEarly: false },
  );
  const { error: toDateError } = inputSchema.validate(
    {
      ['yearAndMonth']: collectumTgl.toDate,
    },
    { abortEarly: false },
  );

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    !contractSetting
      ? createContractSetting(employerId, {
          insuranceProviderContractId: insuranceProviderContractId,
          key: 'COLLECTUM_TGL',
          value: 'ENABLED',
          fromDate: collectumTgl.fromDate,
          toDate: collectumTgl.toDate,
        })
      : patchContractSetting(employerId, {
          contractSettingId: contractSetting?.id,
          value: e.target.checked ? 'ENABLED' : 'DISABLED',
        });
  };

  const handleFromDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCollctumTgl((prev) => ({ ...prev, fromDate: event.target.value }));
  };

  const handleToDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCollctumTgl((prev) => ({ ...prev, toDate: event.target.value }));
  };

  const handleSave = () => {
    patchContractSetting(employerId, {
      contractSettingId: contractSetting?.id,
      fromDate: collectumTgl.fromDate,
      toDate: collectumTgl.toDate,
    });
  };

  const isSaveButtonDisabled =
    fromDateError != undefined ||
    toDateError != undefined ||
    isBlank(collectumTgl.fromDate) ||
    isBlank(collectumTgl.toDate) ||
    collectumTgl.fromDate > collectumTgl.toDate;

  return (
    <>
      <Typography bold variant="body1" component="label">
        {t('agreementRegistrySettings:tgl-settings.tgl-alecta-label')}
      </Typography>
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('agreementRegistrySettings:tgl-settings.switch-label')} bold>
          <Switch toggled={contractSetting?.value === 'ENABLED'} onChange={handleToggle} />
        </InlineEdit>
      </ListGroup>

      <Collapsible open={contractSetting?.value === 'ENABLED'}>
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('agreementRegistrySettings:tgl-settings.from-period')} bold>
            <TextInput
              type="text"
              validationKey="yearAndMonth"
              variant="inline-edit"
              maxLength={8}
              value={collectumTgl.fromDate}
              onChange={handleFromDateChange}
            />
          </InlineEdit>
          <InlineEdit label={t('agreementRegistrySettings:tgl-settings.to-period')} bold>
            <TextInput
              type="text"
              validationKey="yearAndMonth"
              variant="inline-edit"
              maxLength={8}
              value={collectumTgl.toDate}
              onChange={handleToDateChange}
            />
          </InlineEdit>
        </ListGroup>
        <div className="agreement-settings-tab__buttons">
          <Button
            className="agreement-settings-tab__button"
            label={t('agreementRegistrySettings:tgl-settings.save-btn')}
            disabled={isSaveButtonDisabled}
            onClick={handleSave}
          />
        </div>
      </Collapsible>
    </>
  );
};
export default CollectumTglSetting;
