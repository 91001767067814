import { DELETE_CONTRACT_AGREEMENT_COST_CENTRE } from 'src/graphql/schema/mutation/contract/DeleteContractAgreementCostCentre';
import { GET_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/query/contract/GetInsuranceProviderContract';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeleteContractAgreementCostCentre = () => {
  const apolloMutation = useApolloMutation();

  const deleteContractAgreementCostCentre = (
    employerId: string,
    insuranceProviderContractId: string,
    agreementCostCentreId: string,
  ) =>
    apolloMutation(
      DELETE_CONTRACT_AGREEMENT_COST_CENTRE,
      employerId,
      {
        insuranceProviderContractId,
        agreementCostCentreId,
      },
      [GET_INSURANCE_PROVIDER_CONTRACTS, GET_INSURANCE_PROVIDER_CONTRACT],
    );

  return deleteContractAgreementCostCentre;
};
