import { gql } from '@apollo/client';

export const GET_BENEFIT_TYPES = gql`
  query GetBenefitTypes {
    policyBenefitTypes {
      type
      description
    }
  }
`;
