import { Banner, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { DataValidationEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { unique } from 'src/util/ArrayUtil';
import { getBannerType } from 'src/util/CheckpointUtil';
import PayTypeMappingDrawer from './PayTypeMappingDrawer';
import './missing-pay-types-banner.scss';
import When from 'src/components/Common/When';

interface MissingPayTypesBannerProps {
  employeeResponses: DataValidationEventReportEmployeeResponse[];
}

const MissingPayTypesBanner = ({ employeeResponses }: MissingPayTypesBannerProps) => {
  const [wageTypeModalOpen, setwageTypeModalOpen] = useState(false);

  const handleBannerClick = () => {
    setwageTypeModalOpen((prev) => !prev);
  };

  const wageTypes = unique(
    employeeResponses.flatMap((e) => e.checkpoints.flatMap((c) => c.additionalData?.wageTypeMappings)),
    'code',
  );

  const employerCheckpoints = employeeResponses.map((response) => {
    return { employerId: response.employee.employerId, checkpoints: response.checkpoints };
  });

  const bannerMessage = (
    <Typography variant="body2">
      <>
        Det finns&nbsp;
        <Typography component="span" variant="body2" bold>
          {wageTypes.length.toString()}
        </Typography>
        &nbsp;nya lönearter som saknar matching till lönetyper. Dessa berör&nbsp;
        <Typography component="span" variant="body2" bold>
          {employeeResponses.length.toString()}
        </Typography>
        &nbsp;anställda.
      </>
    </Typography>
  );

  const checkpoints = employerCheckpoints.flatMap((employerCheckpoint) => employerCheckpoint.checkpoints);

  return (
    <When condition={employeeResponses.length > 0}>
      <Banner
        className="missing-pay-types-banner"
        type={getBannerType(checkpoints)}
        message={bannerMessage}
        buttonLabel={'Mappa lönearter'}
        onClick={handleBannerClick}
      />
      <PayTypeMappingDrawer
        wageTypeCheckpoints={employerCheckpoints}
        employerIds={unique(employeeResponses.map((response) => response.employee.employerId))}
        open={wageTypeModalOpen}
        handleClose={() => setwageTypeModalOpen(false)}
      />
    </When>
  );
};

export default MissingPayTypesBanner;
