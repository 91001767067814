import { Banner, DrawerNavigation, ListGroup, Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { useState } from 'react';
import { EmployeeWagesOverviewDrawerTab } from 'src/types/tabs/EmployeeWagesOverviewDrawerTab';
import { EmployeeCardCalculation } from 'src/types/employees/EmployeeCardCalculation';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import KubDetailsDrawer from '../KubDetailsDrawer/KubDetailsDrawer';
import PglDetailsDrawer from '../PglDetailsDrawer/PglDetailsDrawer';
import { useTranslation } from 'react-i18next';
import './calculations-overview-drawer.scss';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import CalculationLabel from 'src/components/Employees/EmployeeOverview/calculations/CalculationLabel';

interface CalculationsOverviewDrawerProps {
  open: boolean;
  onClose: () => void;
  onBackClick: () => void;
  period: string;
  selectedPglId: string;
  selectedKubId: string;
  onDisplayPglDetailsDrawer: (id: string) => void;
  onDisplayKubDetailsDrawer: (id: string) => void;
  qualifiedCalculations: EmployeeCardCalculation;
  employerId: string;
}

const CalculationsOverviewDrawer = ({
  open,
  onClose,
  onBackClick,
  period,
  selectedPglId,
  selectedKubId,
  onDisplayPglDetailsDrawer,
  onDisplayKubDetailsDrawer,
  qualifiedCalculations,
  employerId,
}: CalculationsOverviewDrawerProps) => {
  const [currentTab, setCurrentTab] = useState<EmployeeWagesOverviewDrawerTab>(EmployeeWagesOverviewDrawerTab.PERIOD);
  const { t } = useTranslation();
  const periodText = getFormattedDateStringFromPeriod(period);

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      {!selectedKubId && !selectedPglId && (
        <DrawerBase
          title={
            <>
              <Typography variant="h2" bold>
                {t('calculations:calculations')}
              </Typography>
              <Pill type="success" label={periodText} />
            </>
          }
          onClose={onClose}
        >
          <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
            <Tab title={t('common:about')}>
              <div className="calculations-overview-drawer">
                <Typography className="calculations-overview-drawer__heading" variant="body1" component="h3" bold>
                  {t('calculations:pgl')}
                </Typography>
                <ListGroup variant="drawer-navigation">
                  {qualifiedCalculations?.pglOutcomes.length === 0 ? (
                    <Banner type="announcement" message={t('calculations:no-pgl-outcomes')} />
                  ) : (
                    qualifiedCalculations.pglOutcomes.map((pglOutcome) => (
                      <DrawerNavigation
                        key={pglOutcome.id}
                        label={<CalculationLabel label={pglOutcome.name} type={pglOutcome.pglType} />}
                        value={formatSweAmountTextWithSuffixNoDecimal(pglOutcome.pglSum)}
                        onClick={() => onDisplayPglDetailsDrawer(pglOutcome.id)}
                        data-cy={`drawer-navigation__pgl-${pglOutcome.name}`}
                      />
                    ))
                  )}
                </ListGroup>
                <Typography className="calculations-overview-drawer__heading" variant="body1" component="h3" bold>
                  {t('calculations:kub')}
                </Typography>
                <ListGroup variant="drawer-navigation">
                  {qualifiedCalculations.kubOutcomes.length === 0 ? (
                    <Banner type="announcement" message={t('calculations:no-kub-outcomes')} />
                  ) : (
                    qualifiedCalculations.kubOutcomes.map((kubOutcome) => (
                      <DrawerNavigation
                        key={kubOutcome.id}
                        label={kubOutcome.kubType}
                        value={formatSweAmountTextWithSuffixNoDecimal(kubOutcome.kubSum)}
                        onClick={() => onDisplayKubDetailsDrawer(kubOutcome.id)}
                        data-cy={`drawer-navigation__kub-${kubOutcome.kubType}`}
                      />
                    ))
                  )}
                </ListGroup>
              </div>
            </Tab>
          </Tabs>
        </DrawerBase>
      )}
      {selectedKubId && (
        <KubDetailsDrawer onClose={onClose} onBackClick={onBackClick} kubId={selectedKubId} periodText={periodText} />
      )}
      {selectedPglId && (
        <PglDetailsDrawer
          onClose={onClose}
          onBackClick={onBackClick}
          pglId={selectedPglId}
          periodText={periodText}
          employerId={employerId}
        />
      )}
    </DrawerModal>
  );
};

export default CalculationsOverviewDrawer;
