import { InlineEdit, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import {
  EmployeeIndividualChoice,
  EmployeeIndividualChoiceProperty,
} from 'src/types/employees/EmployeeIndividualChoice';
import { CreateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/CreateEmployeeIndividualChoiceRequest';
import { usePeriodText } from 'src/util/date/PeriodUtil';

interface IndividualChoicePropertyProps {
  property: EmployeeIndividualChoiceProperty;
  individualChoice: EmployeeIndividualChoice | CreateEmployeeIndividualChoiceRequest;
  isEditing?: boolean;
}

function IndividualChoiceProperty({ property, individualChoice, isEditing = false }: IndividualChoicePropertyProps) {
  const { t } = useTranslation();
  const periodText = usePeriodText(individualChoice.fromPeriod);

  const formattedProperty = (): string => {
    switch (property) {
      case 'type':
        return t(`individualChoiceTypes:${individualChoice.type}`);
      case 'fromPeriod':
        return periodText;
      case 'source':
        return t(`employees:individual-choices.sources.${individualChoice[property]}`);
      default:
        return individualChoice[property].toString();
    }
  };

  return (
    <InlineEdit label={t(`employees:individual-choices.${property}`)} bold>
      {isEditing ? (
        <TextInput value={formattedProperty()} disabled />
      ) : (
        <Typography variant="body2" data-testid={`individual-choice-${property}`}>
          {formattedProperty()}
        </Typography>
      )}
    </InlineEdit>
  );
}

export default IndividualChoiceProperty;
