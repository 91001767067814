import { useApolloMutation } from '../graphql/Mutation';
import {TRIGGER_REPORTING_QUEUE} from "../../graphql/schema/mutation/report/TriggerReportingQueue";
import {TriggerReportingQueueRequest} from "../../types/reporting/TriggerReportingQueueRequest";
import {GET_REPORTING_EVENTS} from "../../graphql/schema/query/reporting/GetReportingEvents";

export const useTriggerReportingQueue = () => {
  const apolloMutation = useApolloMutation();

    const triggerReportingQueue = (employerId: string, request: TriggerReportingQueueRequest) =>
        apolloMutation(
            TRIGGER_REPORTING_QUEUE,
            employerId,
            {
                request,
            },
            [GET_REPORTING_EVENTS]
        );
    return triggerReportingQueue;
};

