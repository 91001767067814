import { gql } from '@apollo/client';

export const CREATE_PGL_RULE = gql`
  mutation CreatePglRule($policyId: UUID!, $name: String!) {
    createPglRule(policyId: $policyId, name: $name) {
      id
      ruleId
      name
      pglWageTypes {
        id
        employerWageTypeId
        conversionFactor
      }
    }
  }
`;
