import { DrawerNavigation, InlineEdit, ListGroup, Option, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PglRuleReferences } from 'src/types/policy/PglRuleReferences';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';

interface PolicyBenefitCalculationDrawerTabProps {
  policyBenefit: PolicyBenefit;
  onDisplayCalculationPglDetailDrawer: (type: BenefitCalculationType) => void;
}

export type BenefitCalculationType = 'PGL' | 'KUB' | 'UNUSED';

const PolicyBenefitCalculationDrawerTab: FC<PolicyBenefitCalculationDrawerTabProps> = ({
  policyBenefit,
  onDisplayCalculationPglDetailDrawer,
}) => {
  const { t } = useTranslation();

  const resolveType = (): Option<BenefitCalculationType> => {
    const pglRefs = policyBenefit.pglRuleReferences;
    const kubType = policyBenefit.kubType;
    if (pglRefs) {
      if (pglRefs.pglRuleReference || pglRefs.spglRuleReference) {
        return { label: t('account:employers-tab.policy-settings.table.drawer.pgl-model'), value: 'PGL' };
      }
    }
    if (kubType) {
      return { label: t('account:employers-tab.policy-settings.table.drawer.kub-model'), value: 'KUB' };
    }
    return { label: t('common:not-in-use'), value: 'UNUSED' };
  };

  const selected = resolveType();

  return (
    <div className={['policy-benefit-overview__tab'].join(' ')}>
      <ListGroup variant="drawer-navigation" className="policy-benefit-overview__table">
        <DrawerNavigation
          label={t('common:type')}
          value={selected.label}
          onClick={() => onDisplayCalculationPglDetailDrawer(selected.value)}
        />
      </ListGroup>
      {selected.value === 'PGL' && <PglBlock pglRuleReferences={policyBenefit.pglRuleReferences} />}
    </div>
  );
};

const PglBlock = ({ pglRuleReferences }: { pglRuleReferences: PglRuleReferences }) => {
  const { t } = useTranslation();
  return (
    <ListGroup variant="drawer-navigation">
      {pglRuleReferences.pglRuleReference && (
        <InlineEdit
          bold
          label={t('account:employers-tab.policy-settings.table.drawer.pgl-model')}
          key={`policy-changes-drawer-pgl`}
        >
          <Typography variant="body2">{pglRuleReferences.pglRuleReference.name}</Typography>
        </InlineEdit>
      )}

      <InlineEdit
        bold
        label={t('account:employers-tab.policy-settings.table.drawer.spgl-model')}
        key={`policy-changes-drawer-spgl`}
      >
        <Typography variant="body2">
          {t(pglRuleReferences.spglRuleReference == null ? 'common:no' : 'common:yes')}
        </Typography>
      </InlineEdit>
    </ListGroup>
  );
};

export default PolicyBenefitCalculationDrawerTab;
