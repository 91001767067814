import { TableRow, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import { Wage } from 'src/types/EmployeeCard';
import { stringOrBlank } from 'src/util/StringUtil';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import './absence-table-row.scss';
import { formatPayType } from 'src/util/WageUtil';
import { useTranslation } from 'react-i18next';

interface AbsenceTableRowProps {
  wage: Wage;
}

function AbsenceTableRow({ wage }: AbsenceTableRowProps) {
  const periodString = usePeriodText(wage?.employeeCardPeriod);
  const { t } = useTranslation();

  return (
    <TableRow className="absence-table-row">
      <Td>{formatPayType(wage.payTypeNumber, wage.payTypeName, t)}</Td>
      <Td>{periodString}</Td>
      <Td>{stringOrBlank(wage?.fromDate)}</Td>
      <Td>{stringOrBlank(wage?.toDate)}</Td>
      <Td className="absence-table-row__sum">{stringOrBlank(wage?.extentAdjustedAbsenceDays)}</Td>
    </TableRow>
  );
}

export default AbsenceTableRow;
