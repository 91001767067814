import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { ACCOUNTING_TYPES } from 'src/graphql/schema/query/accountingunit/AccountingType';
import { AccountingType } from 'src/types/accountingunit/AccountingType';
import AccountingTypeItem from './AccountingTypeItem';

const AccountingTypeTab = () => {
  const { employerId, customerName } = useParams();

  const navigate = useNavigate();

  return (
    <QueryWrapper query={ACCOUNTING_TYPES} options={{ variables: { employerId } }}>
      {(accountingTypes: AccountingType[]) => {
        return (
          <ul>
            {accountingTypes.map((accountingType) => {
              const handleOnClick = () => {
                navigate(
                  `/account/${customerName}/${employerId}/economy-settings/accounting-type/${accountingType.id}`,
                );
              };
              return (
                <AccountingTypeItem
                  key={`AccountingTypeItem-${accountingType.id}`}
                  accountingType={accountingType}
                  onClick={handleOnClick}
                />
              );
            })}
          </ul>
        );
      }}
    </QueryWrapper>
  );
};

export default AccountingTypeTab;
