import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';
import { UpdateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/UpdateEmployeeIndividualChoiceRequest';
import { UPDATE_DRAFT_EMPLOYEE_INDIVIDUAL_CHOICE } from 'src/graphql/schema/mutation/employee/UpdateDraftEmployeeIndividualChoice';

export const useUpdateDraftEmployeeIndividualChoice = () => {
  const apolloMutation = useApolloMutation();

  const updateDraftEmployeeIndividualChoice = (request: UpdateEmployeeIndividualChoiceRequest, employerId: string) =>
    apolloMutation(
      UPDATE_DRAFT_EMPLOYEE_INDIVIDUAL_CHOICE,
      employerId,
      {
        request,
      },
      [GET_EMPLOYEE_INDIVIDUAL_CHOICES],
    );

  return updateDraftEmployeeIndividualChoice;
};
