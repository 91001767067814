import { Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { PglType } from 'src/types/policy/PglWageType';
import styles from './styles.module.scss';

interface DetailsDrawerTitleProps {
  period: string;
  type: PglType | 'KUB';
  name: string;
}

const DetailsDrawerTitle = ({ period, name, type }: DetailsDrawerTitleProps) => {
  return (
    <>
      <Typography variant="h2" bold>
        {name}
      </Typography>
      <div className={styles.container}>
        <Pill type="success" label={period} />
        <Pill type="neutral" label={type} />
      </div>
    </>
  );
};

export default DetailsDrawerTitle;
