import React, { useEffect, useState } from 'react';
import PeriodCard from './PeriodCard';
import PeriodDetailsCard from './PeriodDetailsCard';
import { Customer } from 'src/types/customer/Customer';
import { useEmployersIdLegalName } from 'src/service/employer/GetEmployer';
import { useReportingPeriodSummaries } from 'src/service/reporting/GetReportingPeriodSummaries';
import { LOADED, LOADING } from 'src/types/Status';
import { getPeriodsBetween } from 'src/util/date/PeriodUtil';

interface ReportingYearOverviewProps {
  selectedYear: number;
  customer: Customer;
}

const ReportingYearOverview: React.FunctionComponent<ReportingYearOverviewProps> = ({ selectedYear, customer }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState<number>(
    selectedYear === currentDate.getFullYear() ? currentDate.getMonth() + 1 : 1,
  );

  const employers = useEmployersIdLegalName(customer?.id);
  const employerIds = employers.map((e) => e.id);

  const handleSelectPeriod = (month: number) => {
    setSelectedMonth(month);
    window.scrollTo(0, 0);
  };

  const fromPeriod = `${selectedYear}01`;
  const toPeriod =
    selectedYear === currentYear ? `${selectedYear}${currentMonth.toString().padStart(2, '0')}` : `${selectedYear}12`;

  const resetMonthWhenChangingYear = () => {
    if (selectedYear !== currentYear) {
      setSelectedMonth(1);
    }
  };

  useEffect(() => {
    resetMonthWhenChangingYear();
  }, [selectedYear]);

  const service = useReportingPeriodSummaries(fromPeriod, toPeriod, employerIds);

  const periods = getPeriodsBetween(fromPeriod, toPeriod);

  return (
    <div className="reporting-overview">
      <PeriodDetailsCard
        reportingPeriodSummaries={service.status === LOADED ? service.payload : null}
        reportingPeriodSummaryLoading={service.status === LOADING}
        year={selectedYear}
        month={selectedMonth}
        employerIds={employerIds}
      />
      <ul className="reporting-overview__year-overview">
        {periods.map((period) => {
          const month = parseInt(period?.substring(4, 6));

          return (
            <PeriodCard
              onSelectPeriod={handleSelectPeriod}
              key={'period-year' + selectedYear + '-monthIndex-' + month}
              year={selectedYear}
              month={month}
              reportingPeriodSummaryLoading={service.status === LOADING}
              reportingPeriodSummary={
                service.status === LOADED
                  ? service.payload.find(
                      (summary) => summary.wageFilePeriod === `${selectedYear}${month.toString().padStart(2, '0')}`,
                    )
                  : undefined
              }
            />
          );
        })}
      </ul>
    </div>
  );
};

export default ReportingYearOverview;
