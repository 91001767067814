import Joi from 'joi';

export const datePickerSchema = Joi.object({
  employmentStartDate: Joi.date().iso(),
  fromDate: Joi.date().iso(),
  toDate: Joi.date().iso(),
  grossSalaryFromDate: Joi.date().iso(),
  employmentEndDate: Joi.date().iso().allow(''),
  employmentEndDateNotNull: Joi.date().iso().disallow(null, ''),
  paymentDueDate: Joi.date().iso().disallow(null, ''),
  period: Joi.date().iso(),
});
