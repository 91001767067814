import { Avatar, ChatBubble, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { InsuranceEventComment } from 'src/types/reporting/InsuranceEvent';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import './insurance-event-comment.scss';
import { useTranslation } from 'react-i18next';
import { mapNamesToOneString } from 'src/util/NameUtil/NameUtil';

interface InsuranceEventCommentEntryProps {
  comment: InsuranceEventComment;
}

function InsuranceEventCommentEntry({ comment }: InsuranceEventCommentEntryProps) {
  const { t } = useTranslation();
  const formattedDateTime = formatDateTimeString(comment.createdDate);

  return (
    <li className="insurance-event-comment">
      <Pill
        className="insurance-event-comment__pill"
        label={t(`insuranceEventHandlingTypes:past-tense.${comment.handlingType}`)}
      />
      <Avatar size="small" />
      <div className="insurance-event-comment__content">
        <ChatBubble chatText={comment.comment} />
        <div className="insurance-event-comment__info">
          <Typography variant="body2" className="insurance-event-comment__name">
            {mapNamesToOneString(comment.user.firstName, comment.user.lastName)}
          </Typography>
          <Typography variant="body2" className="insurance-event-comment__date">
            {formattedDateTime}
          </Typography>
        </div>
      </div>
    </li>
  );
}

export default InsuranceEventCommentEntry;
