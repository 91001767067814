import { Button, Divider, InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import PeriodPill from 'src/components/Common/PeriodPill';
import { useEmployeeCardHistoricalAggregatedWages } from 'src/service/employeecard/FindEmployeeCardHistoricalAggregatedWages';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { Wage } from 'src/types/EmployeeCard';
import { parseSweAmountTextWithNegativeAmounts } from 'src/util/Number/AmountFormatter';
import UpdateInput from 'src/components/Common/UpdateInput';
import When from 'src/components/Common/When';
import { payedAmountsAreValid } from './utils';
import { CreateEmploymentEventWagesRequest } from 'src/types/reporting/CreateEmploymentEventWages';
import { useCreateEmploymentEventWages } from 'src/service/reporting/CreateEmploymentEventWages';
import { useTranslation } from 'react-i18next';
import { Employee } from 'src/types/Employee';
import { sortPeriods } from 'src/util/SortUtil';
import { UpdateEmployeeCardWage } from 'src/types/UpdateEmployeeCardWage';
import { useUpdateEmployeeCardWage } from 'src/service/employeecard/UpdateEmployeeCardWage';
import './update-wages-payed-amount.scss';

interface UpdateWagesPayedAmountProps {
  checkpoint: EmploymentEventCheckpoint;
  wageFilePeriod: string;
  employee: Employee;
  employerId: string;
  onBackClick: () => void;
}

export interface PayedAmountPerPeriod {
  period: string;
  employeeCardId: string;
  payedAmount: string;
  wageId: string;
}

export interface PeriodsToWages {
  [key: string]: Wage[];
}

const UpdateWagesPayedAmount = ({
  checkpoint,
  wageFilePeriod,
  employee,
  employerId,
  onBackClick,
}: UpdateWagesPayedAmountProps) => {
  const employeeCardPeriod = checkpoint.additionalData.employeeCardPeriod;
  const [payedAmounts, setPayedAmounts] = useState<PayedAmountPerPeriod[]>([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const aggregatedEmployeeCardWages = useEmployeeCardHistoricalAggregatedWages(
    employee.id,
    employeeCardPeriod,
    wageFilePeriod,
    false,
  );

  const { t } = useTranslation();

  const createEmploymentEventWages = useCreateEmploymentEventWages();
  const updateEmployeeCardWage = useUpdateEmployeeCardWage();

  aggregatedEmployeeCardWages.sort((a, b) => (a.period < b.period ? 1 : -1));

  const kubWagesPerPeriodAndCardId: PeriodsToWages = aggregatedEmployeeCardWages.reduce((acc, curr) => {
    acc[`${curr.period} ${curr.id}`] = [
      ...curr.aggregatedWageTypeWages.flatMap((w) => w.wages).filter((w) => w.payTypeNumber === 'KUB'),
      ...curr.aggregatedPayTypeWages.flatMap((w) => w.wages),
    ] as Wage[];
    return acc;
  }, {});

  useEffect(() => {
    if (!kubWagesPerPeriodAndCardId) return;

    const sortedPeriods = Object.keys(kubWagesPerPeriodAndCardId).sort(sortPeriods);
    const periodsToShow = sortedPeriods.slice(1);

    const updatedPayedAmounts = periodsToShow.map((key) => {
      const [period, employeeCardId] = key.split(' ');

      const kubWages = kubWagesPerPeriodAndCardId[key];

      return {
        period,
        employeeCardId,
        payedAmount: kubWages.length > 0 ? kubWages[0].payedAmount.toString() : '0',
        wageId: kubWages.length > 0 ? kubWages[0].id : null,
      } as PayedAmountPerPeriod;
    });

    setPayedAmounts(updatedPayedAmounts);
  }, [aggregatedEmployeeCardWages]);

  const handleSubmit = async () => {
    if (payedAmounts.some((amount) => amount.wageId === null)) {
      setLoading(true);

      const request: CreateEmploymentEventWagesRequest = {
        checkpointId: checkpoint.id,
        checkpointEmployeeCardId: checkpoint.additionalData.employeeCardId,
        wages: payedAmounts
          .filter((amount) => amount.wageId === null)
          .reduce((acc, curr) => {
            acc.push({
              employeeCardId: curr.employeeCardId,
              payTypeNumber: 'KUB',
              amount: +parseSweAmountTextWithNegativeAmounts(curr.payedAmount),
              payedAmount: +parseSweAmountTextWithNegativeAmounts(curr.payedAmount),
            });
            return acc;
          }, []),
      };

      await createEmploymentEventWages(request, employerId);
    }

    if (payedAmounts.some((amount) => amount.wageId !== null)) {
      setLoading(true);

      Object.keys(kubWagesPerPeriodAndCardId).map((key) =>
        kubWagesPerPeriodAndCardId[key].map((existingKubWage) =>
          payedAmounts
            .filter(
              (amount) =>
                amount.wageId !== null &&
                amount.payedAmount !== existingKubWage.payedAmount.toString() &&
                amount.wageId === existingKubWage.id,
            )
            .forEach((wage) => {
              const updateRequest: UpdateEmployeeCardWage = {
                id: wage.employeeCardId,
                wageId: wage.wageId,
                fieldKey: 'payedAmount',
                fieldValue: parseSweAmountTextWithNegativeAmounts(wage.payedAmount),
              };
              updateEmployeeCardWage(updateRequest, employerId);
            }),
        ),
      );
    }
    onBackClick();
  };

  const handlePayedAmountChange = (key: string, value: string) => {
    const [period, employeeCardId] = key.split(' ');
    setPayedAmounts((prev) =>
      prev.map((p) => {
        if (p.period === period && p.employeeCardId === employeeCardId) {
          return { ...p, payedAmount: value };
        }
        return p;
      }),
    );
  };

  useEffect(() => {
    setSubmitButtonDisabled(!payedAmountsAreValid(payedAmounts, kubWagesPerPeriodAndCardId));
  }, [payedAmounts]);

  return (
    <>
      <div className="update-wages-payed-amount__pill">
        <Pill size="large" label={t('employees:employmentStartDate') + ` ${employee.employmentStartDate}`} />
      </div>
      <section className="update-wages-payed-amount">
        {Object.keys(kubWagesPerPeriodAndCardId)
          .filter((key) => {
            const extractedPeriods = Object.keys(kubWagesPerPeriodAndCardId).map((key) => key.split(' ')[0]);
            const sortedPeriods = extractedPeriods.sort(sortPeriods);
            const periodsToShow = sortedPeriods.slice(1);
            return periodsToShow.includes(key.split(' ')[0]);
          })
          .map((key) => {
            const [period, employeeCardId] = key.split(' ');
            const relevantPayedAmount = payedAmounts.find(
              (p) => p.period === period && p.employeeCardId === employeeCardId,
            );
            const isCurrentMonth =
              period === new Date().toLocaleString('default', { month: 'long' }) + ' ' + new Date().getFullYear();

            return (
              <React.Fragment key={period}>
                <div className="update-wages-payed-amount__period-section">
                  <div className="update-wages-payed-amount__period-header">
                    <Typography variant="subtitle" bold>
                      {t('common:period')}
                    </Typography>
                    <PeriodPill period={period} type="success" />
                  </div>
                  <ListGroup variant="inline-edit">
                    <When condition={!!relevantPayedAmount && !isCurrentMonth}>
                      <InlineEdit label={t('examination:drawer.enter-payed-amount-for-period')} bold>
                        <UpdateInput
                          fieldKey={`${period} ${employeeCardId}`}
                          fieldValue={relevantPayedAmount?.payedAmount?.toString()}
                          placeholder={t('examination:drawer.enter-amount')}
                          onChange={handlePayedAmountChange}
                          onBlur={handlePayedAmountChange}
                          validationKey="amount2DecAllowNegative"
                          type="text"
                          disabled={loading}
                        />
                      </InlineEdit>
                    </When>
                  </ListGroup>
                </div>
                <Divider />
              </React.Fragment>
            );
          })}
        <Button
          type="primary"
          className="update-wages-payed-amount__button"
          label={t('common:save')}
          onClick={handleSubmit}
          disabled={submitButtonDisabled}
          loading={loading}
        />
      </section>
    </>
  );
};

export default UpdateWagesPayedAmount;
