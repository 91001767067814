import { Option, Select } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import './reporting-events.scss';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from 'src/components/Common/LoadingPage';
import { useEmployersInCustomer } from 'src/service/employer/GetEmployersInCustomer';
import { INIT, LOADING, ERROR, LOADED } from 'src/types/Status';
import { periodToDate } from 'src/util/date/PeriodUtil';
import { useMonths } from 'src/util/date/Months';
import { useTranslation } from 'react-i18next';
import { getCustomerId } from 'src/service/customer/GetCustomerId';
import ReportingEventsOverview from 'src/components/Reporting/ReportingEvents/ReportingEventsOverview';
import { useYearOptions } from 'src/util/date/Years';
import EventsSearchInput from 'src/components/Common/EventsSearchInput';

const ReportingEvents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { year, month } = useParams();
  const period = `${year}${month}`;
  const periodAsDate = periodToDate(period);
  const selectedYear = periodAsDate.getFullYear();
  const selectedMonth = periodAsDate.getMonth() + 1;
  const [searchInput, setSearchInput] = useState('');

  const yearsOptions = useYearOptions();
  const monthOptions: Option<number>[] = useMonths().map((month, index) => {
    return { label: month, value: index + 1 };
  });

  const customerId = getCustomerId();
  const service = useEmployersInCustomer(customerId);

  const handleYearChange = (option: Option<number>) => {
    navigate(`/reporting/${option.value}/${selectedMonth.toString().padStart(2, '0')}/insurance-events?tab=1`);
  };

  const handleMonthChange = (option: Option<number>) => {
    navigate(`/reporting/${selectedYear}/${option.value.toString().padStart(2, '0')}/insurance-events?tab=1`);
  };

  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>{t('common:error')}</div>;
    case LOADED:
      break;
  }

  const employers = service.payload;

  const onSearch = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  return (
    <>
      <div className="reporting-events__header">
        <EventsSearchInput onSearch={onSearch} initialSearchInput={searchInput} />
        <Select
          options={monthOptions}
          onChange={handleMonthChange}
          selected={monthOptions.find((opt) => opt.value === selectedMonth)}
          alphabetical={false}
        />
        <Select
          options={yearsOptions}
          onChange={handleYearChange}
          selected={yearsOptions.find((opt) => opt.value === selectedYear)}
        />
      </div>
      <ReportingEventsOverview employers={employers} reportingPeriod={period} searchInput={searchInput} />
    </>
  );
};

export default ReportingEvents;
