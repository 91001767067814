import { Icon, InlineEdit, Tooltip, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import './editable-property.scss';
import { useTranslation } from 'react-i18next';

interface EditablePropertyProps {
  children: React.ReactNode;
  label: string;
  hasChanged?: boolean;
}

function EditableProperty({ children, label, hasChanged }: EditablePropertyProps) {
  return (
    <InlineEdit
      className="editable-property"
      label={<EditablePropertyLabel label={label} hasChanged={hasChanged} />}
      bold
    >
      {children}
    </InlineEdit>
  );
}

interface EditablePropertyLabelProps {
  label: string;
  hasChanged?: boolean;
}

const EditablePropertyLabel = ({ label, hasChanged }: EditablePropertyLabelProps) => {
  const { t } = useTranslation();

  return (
    <span className="editable-property__label">
      {hasChanged ? (
        <Tooltip content={t('employees:employee-draft-property-changed')} direction="bottom">
          <span className="editable-property__label">
            <Typography variant="body2" bold>
              {label}
            </Typography>
            <Icon type="Info" stroke="#a6aebb" className="editable-property__icon" />
          </span>
        </Tooltip>
      ) : (
        <Typography variant="body2" bold>
          {label}
        </Typography>
      )}
    </span>
  );
};

export default EditableProperty;
