import { gql } from '@apollo/client';

export const GET_EMPLOYER_KUBS = gql`
  query GetEmployerKubs($employerId: UUID!) {
    employerKubs(employerId: $employerId) {
      id
      employerId
      kubType
      description
      employerPayTypes {
        id
        name
        number
      }
    }
  }
`;
