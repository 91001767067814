import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { ExternalUser } from 'src/types/user/User';
import UsersTableRow from './UsersTableRow';

interface UsersTable {
  users: ExternalUser[];
  onClick: (userId: string) => void;
}

const UsersTable: FC<UsersTable> = ({ users, onClick }) => {
  const { t } = useTranslation();

  const head = [
    { name: t('permission:users-table-head.name'), centered: false },
    { name: t('permission:users-table-head.email'), centered: false },
    { name: t('permission:users-table-head.groups'), centered: false },
  ];

  return (
    <div data-testid="users-table">
      <TableBase maxHeightPercentage={60}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th centered={title.centered} key={`UsersTable-${title.name}-${index}`}>
                {title.name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <UsersTableRow
              key={`UsersTableRow-${user.user.id}`}
              user={user.user}
              groups={user.externalGroups}
              onClick={() => onClick(user.user.id)}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default UsersTable;
