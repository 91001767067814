import React, { useState } from 'react';
import { usePglRules } from 'src/service/policy/GetPglRules';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import PglCalculationTable from './PglCalculationTable';
import PglRuleDrawer from './PglRuleDrawer';

interface PglCalculationsProps {
  policyEntity: PolicyEntity;
}

const PglCalculations = ({ policyEntity }: PglCalculationsProps) => {
  const [pglRuleDrawerOpen, setPglRuleDrawerOpen] = useState(false);
  const [selectedPglRuleId, setSelectedPglRuleId] = useState<string>();

  const pglRules = usePglRules(policyEntity?.id);

  const handleOpenPglRuleDrawer = (id: string) => {
    setSelectedPglRuleId(id);
    setPglRuleDrawerOpen(true);
  };

  const handleClosePglRuleDrawer = () => {
    setPglRuleDrawerOpen(false);
  };

  return (
    <>
      <PglCalculationTable pglRules={pglRules} handleOpenPglRuleDrawer={handleOpenPglRuleDrawer} />
      {pglRuleDrawerOpen && (
        <PglRuleDrawer
          employerId={policyEntity.employerId}
          pglRule={pglRules.find((rule) => rule.id === selectedPglRuleId)}
          onClose={handleClosePglRuleDrawer}
          open={pglRuleDrawerOpen}
          policyState={policyEntity.state}
          policyId={policyEntity.id}
        />
      )}
    </>
  );
};

export default PglCalculations;
