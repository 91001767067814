import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { UPDATE_CONTRACT_AGREED_PRODUCT } from 'src/graphql/schema/mutation/contract/UpdateContractAgreedProduct';
import { GET_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/query/contract/GetInsuranceProviderContract';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateContractAgreedProduct = () => {
  const apolloMutation = useApolloMutation();

  const updateContractAgreedProduct = (
    employerId: string,
    insuranceProviderContractId: string,
    agreedProductId: string,
    request: ContractAgreedProductRequest,
  ) =>
    apolloMutation(
      UPDATE_CONTRACT_AGREED_PRODUCT,
      employerId,
      {
        insuranceProviderContractId,
        agreedProductId,
        request,
      },
      [GET_INSURANCE_PROVIDER_CONTRACTS, GET_INSURANCE_PROVIDER_CONTRACT],
    );

  return updateContractAgreedProduct;
};
