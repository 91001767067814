import { useApolloMutation } from '../graphql/Mutation';
import { EmployerIndividualChoiceRequest } from 'src/types/employer/request/EmployerIndividualChoiceRequest';
import { CREATE_EMPLOYER_INDIVIDUAL_CHOICE } from 'src/graphql/schema/mutation/employer/CreateEmployerIndividualChoice';
import { GET_EMPLOYER_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employer/GetEmployerIndividualChoices';

export const useCreateEmployerIndividualChoice = () => {
  const apolloMutation = useApolloMutation();

  const createEmployerIndividualChoice = (request: EmployerIndividualChoiceRequest) =>
    apolloMutation(CREATE_EMPLOYER_INDIVIDUAL_CHOICE, request.employerId, { request }, [
      GET_EMPLOYER_INDIVIDUAL_CHOICES,
    ]);

  return createEmployerIndividualChoice;
};
