import { Button, Datepicker, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { Wage } from 'src/types/EmployeeCard';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { formatDate } from 'src/util/date/PeriodUtil';
import './gross-salary-wage-details.scss';
import { useUpsertDraftEmployeeCardGrossSalaryWage } from 'src/service/employeecard/UpsertDraftEmployeeCardGrossSalaryWage';
import { UpsertDraftEmployeeCardGrossSalaryWageRequest } from 'src/types/employees/request/UpsertDraftEmployeeCardGrossSalaryWageRequest';
import { hasAmountChanged } from '../utils';

interface GrossSalaryWageDetailsProps {
  wage: Wage;
  employeeCardId: string;
  employerId: string;
  isDraft: boolean;
  period: string;
  onBackClick: () => void;
}

function GrossSalaryWageDetails({
  wage,
  employeeCardId,
  isDraft,
  period,
  employerId,
  onBackClick,
}: GrossSalaryWageDetailsProps) {
  const [amount, setAmount] = useState(wage.amount.toString().replace('.', ','));
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const { t } = useTranslation();

  const updateGrossSalaryWage = useUpsertDraftEmployeeCardGrossSalaryWage();

  const firstDayOfPeriod = formatDate(new Date(+period.substring(0, 4), +period.substring(4, 6) - 1, 1));

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handleSaveChanges = async () => {
    setSaveButtonDisabled(true);
    const request: UpsertDraftEmployeeCardGrossSalaryWageRequest = {
      employeeCardId,
      amount: Number(amount.replaceAll(',', '.')),
      fromDate: firstDayOfPeriod,
    };

    await updateGrossSalaryWage(request, employerId);
    onBackClick();
  };

  useEffect(() => {
    hasAmountChanged(wage.amount, amount) ? setSaveButtonDisabled(false) : setSaveButtonDisabled(true);
  }, [amount]);

  return (
    <div className="gross-salary-wage-details">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('wages:gross-salary-from-date')} bold>
          {isDraft ? (
            <Datepicker
              variant="inline-edit"
              className="update-gross-salary-date-picker"
              placeholder="XXXX-MM-DD"
              value={wage.fromDate}
              disabled
              months={[]}
              daysShort={[]}
              years={[]}
            />
          ) : (
            <Typography variant="body2">{firstDayOfPeriod.toString()}</Typography>
          )}
        </InlineEdit>
        <InlineEdit label={t('wages:amount')} bold>
          {isDraft ? (
            <TextInput
              value={amount.toString()}
              onChange={handleAmountChange}
              validationKey="amount2Dec"
              type="text"
              data-cy="amount-input"
            />
          ) : (
            <Typography variant="body2">{formatSweAmountTextWithSuffixWithTwoDecimals(wage.amount)}</Typography>
          )}
        </InlineEdit>
      </ListGroup>
      {isDraft && (
        <Button
          type="primary"
          label={t('common:save-changes')}
          onClick={handleSaveChanges}
          disabled={saveButtonDisabled}
          data-cy="save-wage-changes-button"
        />
      )}
    </div>
  );
}

export default GrossSalaryWageDetails;
