import { gql } from '@apollo/client';

export const GET_EMPLOYER = gql`
  query GetEmployer($employerId: UUID!) {
    employer(employerId: $employerId) {
      id
      legalName
      organisationNumber
      customerId
      customerNumber
    }
  }
`;
