import { DrawerNavigation, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/invoice/Invoice';
import { PaymentTransaction } from 'src/types/invoice/PaymentTransaction';
import { Payment } from 'src/types/payment/Payment';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';
import './payment-transactions-tab.scss';

interface PaymentTransactionsTabProps {
  invoice: Invoice;
  paymentTransactions: PaymentTransaction[];
  payments: Payment[];
  onCreateNewTransaction: () => void;
  onSelectTransaction: (id: string) => void;
  onSelectPayment: (id: string) => void;
}

const PaymentTransactionsTab: FC<PaymentTransactionsTabProps> = ({
  invoice,
  paymentTransactions,
  payments,
  onCreateNewTransaction,
  onSelectTransaction,
  onSelectPayment,
}) => {
  const { t } = useTranslation();

  const isCreateNewTransactionButtonDisabled = invoice.remainingAmount === 0 || invoice.invoiceStatus === 'REJECTED';
  return (
    <div className="payment-transactions-tab">
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('invoice:invoice-drawer.transactions-tab.add-transaction-button-label')}
          onClick={onCreateNewTransaction}
          disabled={isCreateNewTransactionButtonDisabled}
          data-cy="payment-transactions-tab__create-transaction"
        />
      </ListGroup>
      {paymentTransactions && (
        <ListGroup variant="drawer-navigation">
          {paymentTransactions.map((transaction) => (
            <DrawerNavigation
              label={
                <div className="payment-transactions-tab__label">
                  <Typography bold variant="body2">
                    {t('invoice:invoice-drawer.transactions-tab.transaction-label') +
                      ` ${stringOrBlank(transaction.createdDate).substring(0, 10)}`}
                  </Typography>
                  <Pill type="heads-up" label={t('invoice:invoice-drawer.transactions-tab.transaction-pill')} />
                </div>
              }
              value={formatSweAmountText(`${transaction.amount}`)}
              onClick={() => onSelectTransaction(transaction.id)}
              key={`PaymentTransactionTab-${transaction.id}`}
              data-cy="payment-transactions-tab__transaction-details"
            />
          ))}
        </ListGroup>
      )}
      {payments && (
        <ListGroup variant="drawer-navigation">
          {payments.map((payment) => (
            <DrawerNavigation
              label={
                t('invoice:invoice-drawer.transactions-tab.transaction-label') +
                ` ${stringOrBlank(payment.paymentCreatedDate).substring(0, 10)}`
              }
              key={`PaymentTransactionTab-${payment.id}`}
              value={formatSweAmountText(`${payment.amount}`)}
              onClick={() => onSelectPayment(payment.id)}
              data-cy="payment-transactions-tab__payment-details"
            />
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default PaymentTransactionsTab;
