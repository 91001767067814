import { DELETE_POLICY_AFFILIATION_RULE } from 'src/graphql/schema/mutation/policy/DeletePolicyAffiliationRule';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { DeletePolicyAffiliationRuleRequest } from 'src/types/policy/request/DeletePolicyAffiliationRuleRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeletePolicyAffiliationRule = () => {
  const apolloMutation = useApolloMutation();

  const deletePolicyAffiliationRule = (employerId: string, request: DeletePolicyAffiliationRuleRequest) =>
    apolloMutation(DELETE_POLICY_AFFILIATION_RULE, employerId, { request }, [GET_POLICY]);

  return deletePolicyAffiliationRule;
};
