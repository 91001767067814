import { gql } from '@apollo/client';

export const GET_EMPLOYERS = gql`
  query GetEmployers($customerId: UUID!, $period: String) {
    employers(customerId: $customerId, period: $period) {
      id
      legalName
      accountingUnits {
        id
        name
      }
      wageFileSessions {
        id
        customerId
        employerId
        period
        uploadSignature
        uploadProtocol
        filename
        status
        createdAt
        validationErrors {
          errorMessageKey
        }
      }
    }
  }
`;

export const GET_EMPLOYERS_WAGE_FILE_SESSIONS = gql`
  query GetEmployers($customerId: UUID!, $period: String) {
    employers(customerId: $customerId, period: $period) {
      id
      legalName
      wageFileSessions {
        id
        customerId
        employerId
        period
        uploadSignature
        uploadProtocol
        filename
        status
        createdAt
        validationErrors {
          errorMessageKey
        }
      }
    }
  }
`;

export const GET_EMPLOYERS_ID_LEGALNAME = gql`
  query ($customerId: UUID!) {
    employers(customerId: $customerId) {
      id
      legalName
    }
  }
`;
