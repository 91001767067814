import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';

export const getDefaultFilters = (): SearchInvoicesFilter => {
  return {
    searchInput: '',
    paymentDueDate: {
      fromDate: '',
      toDate: '',
    },
    invoiceStatuses: [
      'INVOICE_ARRIVED',
      'REJECTED',
      'CHECKPOINT_PENDING',
      'VALIDATION_DONE',
      'INVOICE_MATCHING_DONE',
      'NO_PAYMENT_SERVICE',
      'CREDIT_OPEN',
      'ACCOUNTING_ONLY',
      'PREPARED_FOR_PAYMENTS',
      'PRELIMINARY_ACCOUNTING_DONE',
      'ACCOUNTING_DONE',
      'INVOICE_PAYMENT_REQUEST_SENT',
      'INVOICE_PAYMENT_FAILED',
      'PAUSED',
    ],
    invoiceTypes: ['DEBIT', 'CREDIT'],
    insuranceProviderIds: [],
    employerIds: [],
  };
};
