import { TableRow, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PglRule } from 'src/types/policy/PglRule';
import './pgl-rules-table-row.scss';

interface PglRulesTableRowProps {
  pglRule: PglRule;
  handleOpenPglRuleModal: (id: string) => void;
}

const PglRulesTableRow = ({ pglRule, handleOpenPglRuleModal }: PglRulesTableRowProps) => {
  const { t } = useTranslation();
  return (
    <TableRow onClick={() => handleOpenPglRuleModal(pglRule.id)} className={['policy-settings-table-row'].join(' ')}>
      <Td>{pglRule.name}</Td>
      <Td>{`${pglRule.pglWageTypes.length + pglRule.pglBonusComponents.length + pglRule.spglWageTypes.length} ${t(
        'account:pgl-rules:table.row.wage-types',
      )}`}</Td>
      <Td>-</Td>
    </TableRow>
  );
};

export default PglRulesTableRow;
