import { Modal, Card, Icon, Typography, CardAction, Button } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './progress-with-error-modal.scss';

interface ProgressWithErrorModalProps {
  open: boolean;
  onClose: () => void;
  handleNext: () => void;
}

const ProgressWithErrorModal = ({ open, onClose, handleNext }: ProgressWithErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal centered overlayBackground className="progress-with-error-modal" open={open} onClose={onClose}>
      <Card icon={<Icon type="Alert" />} title="Det finns felaktiga filer">
        <Typography>{t('upload:progress-with-error.title')}</Typography>
        <Typography className="progress-with-error-modal__text--margin">
          {t('upload:progress-with-error.subtitle')}
        </Typography>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={onClose} />
          <Button label={t('upload:progress-with-error.yes-continue')} onClick={handleNext} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default ProgressWithErrorModal;
