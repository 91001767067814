import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageBase from 'src/components/PageBase';
import './policy-settings.scss';
import PolicyTabs from 'src/components/Account/PolicySettings/PolicyTabs';
import PolicyVersionHeader from 'src/components/Account/PolicySettings/PolicyVersionHeader';
import { Employer } from 'src/types/employer/Employer';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import { usePolicyEntities } from 'src/service/policy/GetPolicyEntities';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import LoadingPage from 'src/components/Common/LoadingPage';

const PolicySettings = () => {
  const { t } = useTranslation();
  const { customerName, employerId, policyId } = useParams();

  const navigate = useNavigate();
  const handleBackClick = () => navigate(`/account/${customerName}/${employerId}`);

  const service = usePolicyEntities(employerId);

  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>Error...</div>;
    case LOADED:
      break;
  }

  const policyEntities = service.payload;

  return (
    <QueryWrapper query={GET_EMPLOYER} options={{ variables: { employerId }, skip: !employerId }}>
      {(employer: Employer) => {
        const breadCrumbs = [
          {
            name: customerName,
            steps: 2,
          },
          {
            name: employer?.legalName,
            steps: 1,
          },
        ];
        return (
          <PageBase
            title={t('account:employers-tab.policy-settings.policy')}
            handleBackButton={handleBackClick}
            breadCrumbs={breadCrumbs}
          >
            <PolicyVersionHeader policyEntities={policyEntities} />
            {policyId && (
              <PolicyTabs
                policyEntity={policyEntities.find((entity) => entity.id === policyId)}
                employerId={employer?.id}
              />
            )}
          </PageBase>
        );
      }}
    </QueryWrapper>
  );
};

export default PolicySettings;
