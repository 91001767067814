import { FunctionComponent } from 'react';
import { Typography, Pill, VersionPill } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { PolicyEntity, DRAFT } from 'src/types/policy/PolicyEntity';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';

interface TitleProps {
  policyEntity: PolicyEntity;
}
const Title: FunctionComponent<TitleProps> = ({ policyEntity }) => {
  const { t } = useTranslation('account');
  const fromDateText = formatDateTimeString(policyEntity.fromDate);
  const toDateText = formatDateTimeString(policyEntity.toDate);
  return policyEntity.state === DRAFT ? (
    <>
      <div className="policy-versions-drawer__title">
        <Typography variant="h2" component="h2" className="policy-versions-drawer__title--typography" bold>
          {t('account:employers-tab.policy-settings.policy-version-header.new-version')}
        </Typography>
      </div>
      <Pill
        className="policy-versions-drawer__title--pill"
        label={t('account:employers-tab.policy-settings.policy-version-header.draft')}
        type="warning"
      />
    </>
  ) : (
    <>
      <div className="policy-versions-drawer__title">
        <VersionPill size="large" className="policy-versions-drawer__title--pill" label={`V${policyEntity.version}`} />
        <Typography
          variant="h2"
          component="h2"
          className="policy-versions-drawer__title--typography"
          bold
        >{`${fromDateText} - ${
          toDateText || t('account:employers-tab.policy-settings.until-further-notice')
        }`}</Typography>
      </div>
      {policyEntity.state === 'ACTIVE' && (
        <Pill
          className="policy-versions-drawer__title--pill"
          label={t('account:employers-tab.policy-settings.policy-version-header.active')}
          type="success"
        />
      )}
    </>
  );
};

export default Title;
