import React from 'react';
import EmployerIndividualChoicesTable from './EmployerIndividualChoicesTable';
import { GET_EMPLOYER_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employer/GetEmployerIndividualChoices';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import AddEmployerIndividualChoiceModal from './AddEmployerIndividualChoiceModal';
import { useTranslation } from 'react-i18next';
import EmployerIndividualChoiceDrawer from './EmployerIndividualChoiceDrawer';

interface EmployerIndividualChoicesTabProps {
  employerId: string;
}

function EmployerIndividualChoicesTab({ employerId }: EmployerIndividualChoicesTabProps) {
  const [displayModal, setDisplayModal] = React.useState(false);
  const [selectedIndividualChoice, setSelectedIndividualChoice] = React.useState<EmployerIndividualChoice>(null);

  const { t } = useTranslation();

  const openModal = () => setDisplayModal(true);

  const closeModal = () => setDisplayModal(false);

  const closeDrawer = () => setSelectedIndividualChoice(null);

  const handleSelectIndividualChoice = (individualChoice: EmployerIndividualChoice) =>
    setSelectedIndividualChoice(individualChoice);

  return (
    <div className="employer-individual-choices-tab">
      <QueryWrapper type="list" query={GET_EMPLOYER_INDIVIDUAL_CHOICES} options={{ variables: { employerId } }}>
        {(employerIndividualChoices: EmployerIndividualChoice[]) => (
          <>
            <EmployerIndividualChoicesTable
              employerIndividualChoices={employerIndividualChoices}
              onSelectIndividualChoice={handleSelectIndividualChoice}
            />
            <FloatingAddButton
              data-testid="add-employer-individual-choice-button"
              onClick={openModal}
              label={t(
                'account:employers-tab.reporting-settings.individual-choices-tab.add-employer-individual-choice',
              )}
            />
            {displayModal && (
              <AddEmployerIndividualChoiceModal open={displayModal} handleClose={closeModal} employerId={employerId} />
            )}
            {selectedIndividualChoice && (
              <EmployerIndividualChoiceDrawer
                open={!!selectedIndividualChoice}
                onClose={closeDrawer}
                employerId={employerId}
                employerIndividualChoice={selectedIndividualChoice}
              />
            )}
          </>
        )}
      </QueryWrapper>
    </div>
  );
}

export default EmployerIndividualChoicesTab;
