import { Button, Icon, ListItem, Select, Typography, Option } from 'fgirot-k2-ui-components';
import React from 'react';
import PageBase from 'src/components/PageBase';
import { PostingRule, UpdatePostingRuleInput } from 'src/types/accountingunit/PostingRule';
import { stringOrBlank } from 'src/util/StringUtil';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { POSTING_RULE } from 'src/graphql/schema/query/accountingunit/PostingRule';
import PostingRuleComponentSection from '../PostingRuleComponentSection';
import { ACCOUNTING_ROWS } from 'src/graphql/schema/query/accountingunit/AccountingRows';
import { AccountingRow } from 'src/types/accountingunit/AccountingRow';
import { useDeletePostingRule, useUpdatePostingRule } from 'src/service/accountingunit/PostingRule';
import './posting-rule-details.scss';
import { t } from 'i18next';

const PostingRuleDetails = () => {
  const { employerId, postingRuleId, accountingUnitId } = useParams();

  const updatePostingRule = useUpdatePostingRule();
  const deletePostingRule = useDeletePostingRule();

  const navigate = useNavigate();
  const handleDeletePostingRule = () => deletePostingRule(postingRuleId, employerId);

  return (
    <QueryWrapper query={POSTING_RULE} options={{ variables: { postingRuleId: postingRuleId } }}>
      {(postingRule: PostingRule) => (
        <PageBase
          header
          handleBackButton={() => navigate(-1)}
          title={`${t('economySettings:posting-rules-tab.posting-rule-details.header')} ${stringOrBlank(
            postingRule.accountingRow.accountNumber,
          )}`}
        >
          <div className="posting-rule-details__edit-buttons">
            <QueryWrapper query={ACCOUNTING_ROWS} options={{ variables: { accountingUnitId, employerId } }}>
              {(accountingRows: AccountingRow[]) => {
                const filteredAccountingRows = accountingRows.filter(
                  (row) => row.accountingType.accountingTypeValue.name === 'RESULT_ACCOUNT',
                );
                const accountingRowOptions = filteredAccountingRows.map((row) => ({
                  value: row,
                  label: `${row.accountNumber} - ${row.accountingText || ''}`,
                }));

                const handleAccountingRowChange = (option: Option<AccountingRow>) => {
                  const request: UpdatePostingRuleInput = {
                    name: `${postingRule.id}`,
                    postingRuleId: postingRule.id,
                    accountingRowId: option.value.id,
                  };
                  updatePostingRule(request, employerId);
                };

                return (
                  <Select
                    label={t('economySettings:posting-rules-tab.posting-rule-details.update-account-number-label')}
                    placeholder={`${postingRule.accountingRow.accountNumber}`}
                    data-cy="posting-rule-account__select"
                    selected={accountingRowOptions.find((option) => option.value.id === postingRule.accountingRow.id)}
                    options={accountingRowOptions}
                    onChange={handleAccountingRowChange}
                    small
                    outlined
                  />
                );
              }}
            </QueryWrapper>
            <div>
              <Button icon={<Icon type={'Trash'} />} type="secondary" onClick={handleDeletePostingRule} />
            </div>
          </div>
          <div className="posting-rule-details">
            <ListItem>
              <div className="posting-rule-details__container">
                <Typography className="posting-rule-details__property">{`${t(
                  'economySettings:posting-rules-tab.posting-rule-details.insurance-type-name',
                )}: ${
                  postingRule.insuranceTypeMapping.name ? `${postingRule.insuranceTypeMapping.name}` : '-'
                }`}</Typography>
                <Typography className="posting-rule-details__property">{`${t(
                  'economySettings:posting-rules-tab.posting-rule-details.benefit-type',
                )}: ${
                  postingRule.insuranceTypeMapping.benefitType
                    ? t(`benefitTypes:${postingRule.insuranceTypeMapping.benefitType}`)
                    : '-'
                }`}</Typography>
                <Typography className="posting-rule-details__property">{`${t(
                  'economySettings:posting-rules-tab.posting-rule-details.tax-rule',
                )}: ${
                  postingRule.insuranceTypeMapping.taxRule
                    ? t(`insuranceTypeMapping:tax-rule.${postingRule.insuranceTypeMapping.taxRule}`)
                    : '-'
                }`}</Typography>
                <Typography className="posting-rule-details__property">{`${t(
                  'economySettings:posting-rules-tab.posting-rule-details.premium-source',
                )}: ${t(
                  `insuranceTypeMapping:premium-source.${postingRule.insuranceTypeMapping.premiumSource}`,
                )}`}</Typography>
                <Typography className="posting-rule-details__property">{`${t(
                  'economySettings:posting-rules-tab.posting-rule-details.insurance-category',
                )}: ${
                  postingRule.insuranceTypeMapping.insuranceCategory
                    ? t(`insuranceTypeMapping:insurance-category.${postingRule.insuranceTypeMapping.insuranceCategory}`)
                    : '-'
                }`}</Typography>
                <Typography className="posting-rule-details__property">{`${t(
                  'economySettings:posting-rules-tab.posting-rule-details.identifier',
                )}: ${
                  postingRule.insuranceTypeMapping.identifier
                    ? t(`insuranceTypeMapping:identifier.${postingRule.insuranceTypeMapping.identifier}`)
                    : '-'
                }`}</Typography>
                <Typography className="posting-rule-details__property">{`${t(
                  'economySettings:posting-rules-tab.posting-rule-details.invoice-row-names',
                )}: ${
                  postingRule.insuranceTypeMapping.invoiceRowMappings
                    ? postingRule.insuranceTypeMapping.invoiceRowMappings
                        .map((invoiceRowMapping) => invoiceRowMapping.invoiceRowName)
                        .join(', ')
                    : '-'
                }`}</Typography>
              </div>
            </ListItem>
          </div>
          <div>
            {postingRule.insuranceTypeMapping.insuranceCategory !== 'PERSONAL_INSURANCE' && (
              <PostingRuleComponentSection accountingComponents={postingRule.accountingComponents} />
            )}
          </div>
        </PageBase>
      )}
    </QueryWrapper>
  );
};
export default PostingRuleDetails;
