import { gql } from '@apollo/client';

export const EMPLOYEE_UPDATED = gql`
  subscription EmployeeUpdated($employerIds: [UUID]!) {
    employeeUpdated(employerIds: $employerIds) {
      id
      firstName
      lastName
      personNumber
      employerId
      employmentStartDate
      employmentEndDate
      employmentNumber
      earliestEmploymentStartDate
    }
  }
`;
