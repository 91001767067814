import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import AccountingRowsTable from './AccountingRowsTable/AccountingRowsTable';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { ACCOUNTING_UNIT } from 'src/graphql/schema/query/accountingunit/AccountingUnit';
import PageBase from 'src/components/PageBase/PageBase';
import useTabParams from 'src/components/hooks/useTabParams';
import { AccountingUnitDetailsTab } from 'src/types/tabs/AccountingUnitDetailsTab';
import PostingRulesTab from './PostingRulesTab';
import InsuranceTypeMappingsTab from './InsuranceTypeMappingsTab';

const AccountingUnitDetails: FC = () => {
  const { t } = useTranslation();
  const { accountingUnitId } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useTabParams('tab', AccountingUnitDetailsTab.ACCOUNTING_ROWS);

  return (
    <QueryWrapper query={ACCOUNTING_UNIT} options={{ variables: { accountingUnitId } }}>
      {(accountingUnit: AccountingUnit) => (
        <PageBase title={accountingUnit.name} handleBackButton={() => navigate(-1)}>
          <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
            <Tab title={t('economySettings:tabs.accounting-rows')}>
              <AccountingRowsTable chartOfAccounts={accountingUnit.chartOfAccounts} />
            </Tab>
            <Tab title={t('economySettings:tabs.posting-rules')}>
              <PostingRulesTab accountingUnit={accountingUnit} />
            </Tab>
            <Tab title={t('economySettings:tabs.insurance-type-mappings')}>
              <InsuranceTypeMappingsTab accountingUnit={accountingUnit} />
            </Tab>
          </Tabs>
        </PageBase>
      )}
    </QueryWrapper>
  );
};

export default AccountingUnitDetails;
