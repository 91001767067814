import { Typography, Pill, DrawerNavigation, VersionPill } from 'fgirot-k2-ui-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DRAFT, PolicyEntity } from 'src/types/policy/PolicyEntity';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import './policy-version-drawer-navigation.scss';

interface PolicyVersionDrawerNavigationProps {
  policyEntity: PolicyEntity;
  handleDisplayPolicyEntity: (policyId: string) => void;
}
const PolicyVersionDrawerNavigation: FunctionComponent<PolicyVersionDrawerNavigationProps> = ({
  policyEntity,
  handleDisplayPolicyEntity,
}) => {
  const { t } = useTranslation();
  const fromDateText = formatDateTimeString(policyEntity.fromDate);
  const toDateText = formatDateTimeString(policyEntity.toDate);

  const label =
    policyEntity.state === DRAFT ? (
      <>
        <Typography
          component="span"
          variant="body2"
          bold
          className="policy-version-drawer-navigation__version-info--typography"
        >
          {t('account:employers-tab.policy-settings.policy-version-header.new-version')}
        </Typography>
        <Pill
          className="policy-version-drawer-navigation__version-info--pill"
          label={t('account:employers-tab.policy-settings.policy-version-header.draft')}
          type="warning"
        />
      </>
    ) : (
      <>
        <VersionPill
          className="policy-version-drawer-navigation__version-info--pill"
          label={`V${policyEntity.version}`}
        />
        <Typography
          component="span"
          variant="body2"
          className="policy-version-drawer-navigation__version-info--typography"
          bold
        >{`${fromDateText} - ${
          toDateText || t('account:employers-tab.policy-settings.until-further-notice')
        }`}</Typography>
        {policyEntity.state === 'ACTIVE' && (
          <Pill
            className="policy-version-drawer-navigation__version-info--pill"
            label={t('account:employers-tab.policy-settings.policy-version-header.active')}
            type="success"
          />
        )}
      </>
    );

  const handleOnClick = () => {
    handleDisplayPolicyEntity(policyEntity.id);
  };

  return (
    <DrawerNavigation
      label={label}
      value={
        policyEntity.state === DRAFT && t('account:employers-tab.policy-settings.policy-version-header.not-published')
      }
      onClick={handleOnClick}
    />
  );
};
export default PolicyVersionDrawerNavigation;
