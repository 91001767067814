import { gql } from '@apollo/client';

export const ACCOUNTING_COMPONENT_VALUES = gql`
  query GetAccountingComponentValues($employerId: UUID!) {
    accountingComponentValues(employerId: $employerId) {
      id
      name
      accountingComponentType {
        id
        name
      }
    }
  }
`;
