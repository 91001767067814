import React, { FC, useState } from 'react';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import InsuranceEventsTable from './InsuranceEventsTable';
import EmployeeInsuranceEventsFiltersModal from './EmployeeInsuranceEventsFiltersModal/EmployeeInsuranceEventsFiltersModal';
import EmployeeInsuranceEventsFilterDetails from './EmployeeInsuranceEventsFilterDetails/EmployeeInsuranceEventsFilterDetails';
import {
  EmployeeInsuranceEventsFilters,
  EmployeeInsuranceEventsRequest,
} from 'src/types/employees/request/EmployeeInsuranceEventsRequest';
import EmployeeInsuranceEventDetailsDrawer from './EmployeeInsuranceEventDetailsDrawer';
import { Employee } from 'src/types/Employee';

interface EmployeeInsuranceEventsProps {
  insuranceEvents: InsuranceEvent[];
  employee: Employee;
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  request: EmployeeInsuranceEventsRequest;
  onApplyFilters: (filters: EmployeeInsuranceEventsFilters) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const EmployeeInsuranceEvents: FC<EmployeeInsuranceEventsProps> = ({
  insuranceEvents,
  employee,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  request,
  onApplyFilters,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = getTotalPages(paging.totalNumberOfElements, request.pageSize);
  const showPagination = totalPages > 1;

  const [selectedInsuranceEventId, setSelectedInsuranceEventId] = useState<string>(undefined);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const handleOpenDrawer = (id: string) => {
    setSelectedInsuranceEventId(id);
  };
  const handleCloseDrawer = () => {
    setSelectedInsuranceEventId(undefined);
  };

  return (
    <>
      <div className="employee-insurance-events-overview">
        <EmployeeInsuranceEventsFilterDetails request={request} applyFilters={onApplyFilters} />
        <InsuranceEventsTable insuranceEvents={insuranceEvents} onDrawerOpen={handleOpenDrawer} />
        <When condition={showPagination}>
          <div data-testid="employee-insurance-events-overview-pagination">
            <Pagination
              currentPage={pageNumber + 1}
              totalPages={totalPages}
              onPageChange={onPageChange}
              pageSize={pageSize}
              pageSizeLabel={t('common:results-per-page')}
              pageSizeOptions={pageSizeOptions}
              onPageSizeChange={setPageSize}
              pageSizePosition="left"
            />
          </div>
        </When>
      </div>
      <When condition={filtersModalOpen}>
        <EmployeeInsuranceEventsFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filters={request?.filters}
        />
      </When>
      <div className="employee-insurance-events-overview__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-cy="filter-button"
        />
      </div>
      <When condition={selectedInsuranceEventId != undefined}>
        <EmployeeInsuranceEventDetailsDrawer
          employee={employee}
          insuranceEvent={insuranceEvents.find((selected) => selected.insuranceEventId === selectedInsuranceEventId)}
          open={selectedInsuranceEventId != undefined}
          onClose={handleCloseDrawer}
        />
      </When>
    </>
  );
};

export default EmployeeInsuranceEvents;
