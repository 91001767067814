import { useSearchParams as usp } from 'react-router-dom';

export const useSearchParams = (initialSearchParams?: URLSearchParams) => {
  const [searchParams, _setSearchParams] = usp(initialSearchParams);

  const setSearchParams = (key: string, value: string) => {
    _setSearchParams((prev) => ({ ...getSearchParamsAsJSON(prev), [key]: value }));
  };

  const deleteSearchParams = (keys: string[]) => {
    _setSearchParams((prev) => {
      const newSearchParams = getSearchParamsAsJSON(prev);
      keys.forEach((key) => delete newSearchParams[key]);
      return newSearchParams;
    });
  };

  const setMultipleSearchParams = (params: { [key: string]: string }) => {
    _setSearchParams((prev) => ({ ...getSearchParamsAsJSON(prev), ...params }));
  };

  return { searchParams, setSearchParams, setMultipleSearchParams, deleteSearchParams };
};

const getSearchParamsAsJSON = (params: URLSearchParams) => {
  try {
    return JSON.parse('{"' + decodeURI(params.toString().replace(/&/g, '","').replace(/=/g, '":"')) + '"}');
  } catch (err) {
    return {};
  }
};
