import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteInvoice } from 'src/service/invoice/DeleteInvoice';

interface DeleteInvoiceModalProps {
  open: boolean;
  handleClose: () => void;
  invoiceId: string;
  employerId: string;
}

const DeleteInvoiceModal: React.FunctionComponent<DeleteInvoiceModalProps> = ({
  open,
  handleClose,
  invoiceId,
  employerId,
}) => {
  const { t } = useTranslation();
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);
  const deleteInvoice = useDeleteInvoice();
  const navigate = useNavigate();

  const handleDelete = async () => {
    setDeleteButtonDisabled(true);
    deleteInvoice(invoiceId, employerId)
      .then(() => {
        navigate(-1);
        handleClose();
        setDeleteButtonDisabled(false);
      })
      .catch(() => {
        setDeleteButtonDisabled(false);
      });
  };

  return (
    <Modal data-cy="delete-invoice-modal" centered overlayBackground open={open} onClose={handleClose}>
      <Card
        icon={<Icon type="Alert" stroke="#fda522" />}
        title={t('invoice:delete-invoice.title')}
        defaultClose
        onClose={handleClose}
      >
        <div>
          <Typography component="span">{`${t('invoice:delete-invoice:subtitle')} `}</Typography>
        </div>
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleClose}
            data-cy="delete-invoice-modal__cancel-button"
          />
          <Button
            disabled={deleteButtonDisabled}
            icon={<Icon type="Trash" />}
            label={t('invoice:delete-invoice:delete')}
            onClick={handleDelete}
            data-cy="delete-invoice-modal__delete-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default DeleteInvoiceModal;
