import { gql } from '@apollo/client';

export const GET_POLICY_VERSION_CHANGES = gql`
  query GetPolicyVersionChanges($policyId: UUID!) {
    policyVersionChanges(policyId: $policyId) {
      changedEmploymentGroups {
        versionChangeType
        policyEmploymentGroup {
          id
          employmentGroupId
        }
      }
      changedBenefits {
        id
        name
        benefitId
        benefitType
        versionChangeType
        policyEmploymentGroups {
          id
          employmentGroupId
        }
      }
      changedPglRules {
        id
        name
        versionChangeType
      }
    }
  }
`;
