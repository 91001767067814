import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import LoadingPage from '../LoadingPage';
import ErrorPage from '../ErrorPage';
import { Service } from 'src/types/Service';
import { isArray } from '@apollo/client/cache/inmemory/helpers';

interface ApolloServiceWrapperProps {
  loadingComponent?: JSX.Element;
  errorComponent?: JSX.Element;
  service: Service<unknown> | Service<unknown>[];
  children: JSX.Element | JSX.Element[];
}

const ApolloServiceWrapper = ({ children, loadingComponent, errorComponent, service }: ApolloServiceWrapperProps) => {
  const services = isArray(service) ? service : [service];

  const notLoadedService = services.filter((s) => s.status != 'loaded');

  if (notLoadedService.length == 0) return isArray(children) ? <>{children}</> : children;

  const firstService = notLoadedService.at(0);
  switch (firstService?.status) {
    case INIT:
    case LOADING:
      return loadingComponent || <LoadingPage />;
    case ERROR:
      return errorComponent || <ErrorPage errorMessage={firstService.error.message} />;
    case LOADED:
      return isArray(children) ? <>{children}</> : children;
  }
};

export default ApolloServiceWrapper;
