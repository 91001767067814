import { gql } from '@apollo/client';

export const ACCOUNTING_UNITS = gql`
  query GetAccountingUnits($employerId: UUID!) {
    accountingUnits(employerId: $employerId) {
      id
      name
      description
      defaultAccountingUnit
    }
  }
`;
