import { gql } from '@apollo/client';

export const GET_REPORTING_EVENT_MESSAGE_RECEIPT = gql`
  query GetReportingEventMessageReceipt($messageReceiptId: UUID!) {
    reportingEventMessageReceipt(messageReceiptId: $messageReceiptId) {
      id
      messageId
      fileName
      wageFilePeriod
      insuranceProviderId
      transferChannel
      messageStatus
      messageSentAt
    }
  }
`;
