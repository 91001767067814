import { gql } from '@apollo/client';
import { CreateAccrualDeviationInput } from 'src/types/accountingunit/AccrualRule';
import { ACCRUAL_RULE } from 'src/graphql/schema/query/accountingunit/AcrualRule';
import { useApolloMutation } from '../graphql/Mutation';

export const CREATE_ACCRUAL_DEVATION = gql`
  mutation CreateAccrualDeviation($request: CreateAccrualDeviationInput) {
    createAccrualDeviation(request: $request) {
      id
    }
  }
`;

export const useCreateAccrualDeviation = () => {
  const apolloMutation = useApolloMutation();

  const createAccrualDevation = (request: CreateAccrualDeviationInput, employerId: string) =>
    apolloMutation(CREATE_ACCRUAL_DEVATION, employerId, { request }, [ACCRUAL_RULE]);

  return createAccrualDevation;
};
