import { FileCard, Icon, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WageFileSession } from 'src/types/WageFileSession';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import ValidationErrorModal from '../ValidationErrorModal';

interface FileCardListProps {
  title: string;
  uploadSessions: WageFileSession[];
  error?: boolean;
}
const FileCardList = ({ title, uploadSessions, error }: FileCardListProps) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<WageFileSession>();
  const handleOnClick = (session: WageFileSession) => {
    setSelectedSession(session);
    setModalOpen(true);
  };

  const handleOnClose = () => {
    setModalOpen(false);
  };
  return (
    uploadSessions.length > 0 && (
      <div className="company-upload-status__files__container">
        <div className="company-upload-status__files__header">
          <Typography variant="body2" component="h3" bold>
            {title}
          </Typography>
          <Icon type="Info" className="company-upload-status__files__header__icon" />
        </div>
        <div className="company-upload-status__files__cards">
          {uploadSessions.map((session, index) => {
            const fileFormat = session.filename.split('.').pop();
            return (
              <FileCard
                onClick={() => {
                  handleOnClick(session);
                }}
                className="company-upload-status__files__cards__card"
                error={error}
                key={`FileCard-${session.id}-${index}`}
                title={session.filename}
                subtitle={t('upload:reporting-status.uploaded-by')}
                source={session.uploadSignature}
                timestamp={formatDateTimeString(session.createdAt)}
                icon={<Icon type="FileBlank" />}
                fileFormat={fileFormat}
              />
            );
          })}
        </div>
        {modalOpen && <ValidationErrorModal open={modalOpen} onClose={handleOnClose} session={selectedSession} />}{' '}
      </div>
    )
  );
};

export default FileCardList;
