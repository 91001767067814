import { CREATE_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/mutation/contract/CreateInsuranceProviderContract';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { CreateInsuranceProviderContractRequest } from 'src/types/contract/request/CreateInsuranceProviderContractRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateInsuranceProviderContract = () => {
  const apolloMutation = useApolloMutation();

  const createInsuranceProviderContract = (employerId: string, request: CreateInsuranceProviderContractRequest) =>
    apolloMutation(
      CREATE_INSURANCE_PROVIDER_CONTRACT,
      employerId,
      {
        request,
      },
      [GET_INSURANCE_PROVIDER_CONTRACTS],
    );

  return createInsuranceProviderContract;
};
