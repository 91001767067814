import React, { useState } from 'react';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import { AccountingComponentType } from 'src/types/accountingunit/AccountingComponent';
import { useTranslation } from 'react-i18next';
import './accounting-component-tab.scss';
import CreateAccountingComponentTypeModal from './CreateAccountingComponentTypeModal';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { ACCOUNTING_COMPONENT_TYPES } from 'src/graphql/schema/query/accountingunit/AccountingComponentTypes';
import { useParams } from 'react-router-dom';
import AccountingComponentTypeAccordion from './AccountingComponentTypeAccordion';
import { sortAlphabeticalAsc } from 'src/util/SortUtil';

const AccountingComponentTab = () => {
  const { t } = useTranslation();

  const { employerId } = useParams();

  const [createTypeModalOpen, setCreateTypeModalOpen] = useState(false);
  const handleOpenCreateAccountingComponentTypeModal = () => {
    setCreateTypeModalOpen(true);
  };

  const handleCloseCreateAccountingComponentTypeModal = () => {
    setCreateTypeModalOpen(false);
  };
  const sort = (a: AccountingComponentType, b: AccountingComponentType) => sortAlphabeticalAsc(a.name, b.name);
  return (
    <QueryWrapper query={ACCOUNTING_COMPONENT_TYPES} options={{ variables: { employerId } }}>
      {(accountingComponentTypes: AccountingComponentType[]) => {
        return (
          <>
            <ul>
              {[...accountingComponentTypes].sort(sort).map((accountingComponentType) => {
                return (
                  <AccountingComponentTypeAccordion
                    key={`AccountingComponentTypeAccordion-${accountingComponentType.id}`}
                    accountingComponentType={accountingComponentType}
                  />
                );
              })}
            </ul>
            <CreateAccountingComponentTypeModal
              open={createTypeModalOpen}
              onClose={handleCloseCreateAccountingComponentTypeModal}
            />
            <FloatingAddButton
              label={t('economySettings:accounting-components-tab.modal.title')}
              onClick={handleOpenCreateAccountingComponentTypeModal}
            />
          </>
        );
      }}
    </QueryWrapper>
  );
};

export default AccountingComponentTab;
