import { gql } from '@apollo/client';

export const GET_INSURANCE_PRODUCT_CONDITION = gql`
  query GetInsuranceProductCondition($conditionId: UUID!) {
    insuranceProviderProductCondition(conditionId: $conditionId) {
      id
      name
      version
      documentUrl
      description
      insuranceProviderId
    }
  }
`;
