import { Button, InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeCard } from 'src/types/EmployeeCard';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import ListProperty from 'src/components/Common/ListProperty';
import { CreateEmploymentEventCheckpointWageInput } from 'src/types/reporting/CreateEmploymentEventCheckpointWageInput';
import { UpdateEmployeeCardWage } from 'src/types/UpdateEmployeeCardWage';
import TextInput from 'src/components/Common/TextInput';
import { useCreateEmploymentEventCheckpointWageKubCorrection } from 'src/service/reporting/CreateEmploymentEventCheckpointWageKubCorrection';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import { getInitialCalculatedKubSum, getInitialCorrectKubSum, KubSumInputMap } from './utils';
import './negative-kub-sum-correction.scss';
import { useUpdateEmployeeCardWageKubCorrection } from 'src/service/employeecard/UpdateEmployeeCardWageKubCorrection';

interface NegativeKubSumCorrectionProps {
  checkpoint: EmploymentEventCheckpoint;
  employerId: string;
  employeeCard: EmployeeCard;
  onBackClick: () => void;
}

const NegativeKubSumCorrection = ({
  checkpoint,
  employeeCard,
  employerId,
  onBackClick,
}: NegativeKubSumCorrectionProps) => {
  const { t } = useTranslation();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const existingKubWages = employeeCard?.wages?.filter(
    (wage) => wage.payTypeNumber === 'KUB' && wage?.kubTypes?.length < 2,
  );

  const [correctKubSum, setCorrectKubSum] = useState<KubSumInputMap>(
    getInitialCorrectKubSum(checkpoint, existingKubWages),
  );

  const [calculatedKubSum, setCalculatedKubSum] = useState<KubSumInputMap>(
    getInitialCalculatedKubSum(checkpoint, existingKubWages),
  );

  const handleKubSumInput = (key: string, value: number) => {
    checkpoint.additionalData.kubSums
      .map((kub) => kub.type)
      .forEach((type) => {
        if (type === key) {
          if (!isNaN(value)) {
            setCorrectKubSum(new Map(correctKubSum).set(key, value));
          } else {
            const newMap = new Map(correctKubSum);
            newMap.delete(key);
            setCorrectKubSum(newMap);
          }
        }
      });
  };

  const handleTriggerCalculateKubPayedAmount = (type: string) => {
    const kubSum = checkpoint.additionalData.kubSums.find((kub) => kub.type === type).sum;
    if (correctKubSum.get(type) !== 0 && correctKubSum?.get(type)) {
      setCalculatedKubSum(new Map(calculatedKubSum).set(type, Math.abs(kubSum) + correctKubSum?.get(type)));
    } else {
      setCalculatedKubSum(new Map(calculatedKubSum).set(type, Math.abs(kubSum)));
    }
  };

  useEffect(() => {
    const invalidInput = checkpoint.additionalData.kubSums
      .map((kub) => kub.type)
      .some(
        (type) =>
          isNaN(correctKubSum?.get(type)) ||
          correctKubSum?.get(type)?.toString().length > 10 ||
          correctKubSum?.get(type)?.toString().length < 1 ||
          correctKubSum?.get(type) > 1000000000 ||
          correctKubSum?.get(type) < 0 ||
          correctKubSum?.get(type) % 1 !== 0,
      );
    const amountNotUpdatedForSeveralKubSums =
      existingKubWages.length > 1 && checkpoint.additionalData.kubSums.length > 1
        ? existingKubWages.every((wage) =>
            wage.kubTypes.some((type) => {
              return calculatedKubSum?.get(type) === wage.payedAmount;
            }),
          )
        : false;
    const amountNotUpdatedForOneKubSum =
      existingKubWages.length > 0 && checkpoint.additionalData.kubSums.length === 1
        ? existingKubWages.some((wage) =>
            wage.kubTypes.some((type) => {
              return calculatedKubSum?.get(type) === wage.payedAmount;
            }),
          )
        : false;

    setSubmitButtonDisabled(invalidInput || amountNotUpdatedForSeveralKubSums || amountNotUpdatedForOneKubSum);
  }, [correctKubSum, calculatedKubSum]);

  const createEmploymentEventCheckpointWageKubCorrection = useCreateEmploymentEventCheckpointWageKubCorrection();
  const updateEmployeeCardWageKubCorrection = useUpdateEmployeeCardWageKubCorrection();

  const handleSaveChanges = () => {
    checkpoint.additionalData.kubSums
      .map((kub) => kub.type)
      .forEach((type) => {
        const existingWageByKubType = existingKubWages.find((wage) => wage.kubTypes.includes(type));
        setLoading(true);
        if (!existingWageByKubType) {
          const createRequest: CreateEmploymentEventCheckpointWageInput = {
            employeeCardId: employeeCard?.id ?? '',
            amount: 0,
            payTypeNumber: 'KUB',
            payedAmount: calculatedKubSum?.get(type),
            checkpointId: checkpoint.id,
            kubTypes: [type],
          };
          createEmploymentEventCheckpointWageKubCorrection(createRequest, employerId)
            .then(() => {
              onBackClick();
            })
            .finally(() => setLoading(false));
        }
        if (existingWageByKubType) {
          const updateRequest: UpdateEmployeeCardWage = {
            id: employeeCard?.id,
            wageId: existingWageByKubType.id,
            fieldKey: 'payedAmount',
            fieldValue: calculatedKubSum?.get(type)?.toString(),
          };
          updateEmployeeCardWageKubCorrection({ ...updateRequest, wageId: existingWageByKubType.id }, employerId)
            .then(() => onBackClick())
            .finally(() => setLoading(false));
        }
      });
  };

  return (
    <>
      <div className="negative-kub-sum-correction">
        {checkpoint?.additionalData?.kubSums?.map((kub) => (
          <ListGroup key={`NegativeKubSumCorrection-${kub.type}`} variant="inline-edit">
            <ListProperty
              label={t('examination:drawer.calculated') + ` ${kub.type}`}
              value={formatSweAmountTextWithSuffixNoDecimal(`${kub.sum}`)}
            />
            <InlineEdit bold label={t('examination:drawer.new-kub-sum')}>
              <TextInput
                placeholder={t('examination:drawer.kub-sum-input')}
                value={correctKubSum.get(kub.type)?.toString() ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleKubSumInput(kub.type, e.target.valueAsNumber)}
                onBlur={() => handleTriggerCalculateKubPayedAmount(kub.type)}
                validationKey="positiveInteger"
                variant="inline-edit"
                type="number"
                data-cy="negative-kub-sum-correction__payed-amount-input"
                disabled={loading}
              />
            </InlineEdit>

            <ListProperty
              label={t('examination:drawer.corrected-kub-sum')}
              value={formatSweAmountTextWithSuffixNoDecimal(
                calculatedKubSum ? calculatedKubSum?.get(kub.type)?.toString() : '',
              )}
            />
          </ListGroup>
        ))}
      </div>
      <div className="negative-kub-sum-correction__buttons">
        <Button
          className="negative-kub-sum-correction__button"
          type="primary"
          label={t('common:save-changes')}
          onClick={handleSaveChanges}
          disabled={submitButtonDisabled}
          loading={loading}
          data-cy="negative-kub-sum-correction__save-button"
        />
      </div>
    </>
  );
};

export default NegativeKubSumCorrection;
