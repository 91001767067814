import { Button, Drawer, Icon, Pill, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import './create-new-agreed-product.scss';
import { useState } from 'react';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import AgreedProductInputs from '../AgreedProductInputs';
import { useCreateContractAgreedProduct } from 'src/service/contract/CreateContractAgreedProduct';
import { agreedProductRequestIsValid } from 'src/util/ContractAgreedProductUtil';

interface CreateNewAgreedProductProps {
  contract: InsuranceProviderContract;
  onClose: () => void;
  onBackClick: () => void;
}

const CreateNewAgreedProduct = ({ contract, onClose, onBackClick }: CreateNewAgreedProductProps) => {
  const { t } = useTranslation();
  const createContractAgreedProduct = useCreateContractAgreedProduct();
  const [agreedProductRequest, setAgreedProductRequest] = useState<ContractAgreedProductRequest>({
    name: null,
    insuranceProductId: null,
    reportingRuleConfigurationId: null,
    insuranceCategory: null,
    agreementProductCode: null,
    sellingParty: null,
    certifyInsuranceProductCondition: false,
    certifyInsuranceProductConditionId: null,
  });

  const handleChange = (key: string, value: string | boolean) => {
    setAgreedProductRequest((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const onCreateNewAgreedProduct = () => {
    createContractAgreedProduct(contract.employerId, contract.id, agreedProductRequest).then(() => onBackClick());
  };

  const createButtonDisabled = !agreedProductRequestIsValid(agreedProductRequest);

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="agreement-registry-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {contract.name}
        </Typography>
        <div className="agreement-registry-drawer__sub-title">
          <Typography>{contract.insuranceProvider?.legalName}</Typography>
          <Pill type="neutral" label={contract.agreementNumber} />
        </div>
      </div>

      <div className="create-new-agreed-product">
        <Typography variant="body1" bold>
          {t('agreementRegistrySettings:drawer.new-agreed-product')}
        </Typography>
        <AgreedProductInputs contract={contract} agreedProductRequest={agreedProductRequest} onChange={handleChange} />
        <Button
          label={t('common:add')}
          onClick={onCreateNewAgreedProduct}
          disabled={createButtonDisabled}
          data-cy="create-new-agreed-product-button"
        />
      </div>
    </Drawer>
  );
};

export default CreateNewAgreedProduct;
