import { gql } from '@apollo/client';

export const SEARCH_POSTING_RULES = gql`
  query SearchPostingRules($chartOfAccountsId: UUID!, $request: PostingRulesRequest) {
    searchPostingRules(chartOfAccountsId: $chartOfAccountsId, request: $request) {
      paging {
        totalNumberOfElements
        hasNext
      }
      searchPostingRules {
        id
        name
        insuranceTypeMapping {
          id
          name
          benefitType
          taxRule
          insuranceCategory
          premiumSource
          identifier
          insuranceProvider {
            id
            legalName
          }
        }
        accountingRow {
          id
          accountNumber
          accountingText
          accountingType {
            id
            accountingTypeValue {
              name
              number
            }
          }
        }
      }
    }
  }
`;
