import React from 'react';
import './accounting-settings-tab.scss';
import { AccrualRule } from 'src/types/accountingunit/AccrualRule';
import AccrualRuleCards from './AccrualRuleCards';
import BalancingOfBooksSettingsCards from './BalancingOfBooksSettings/BalancingOfBooksSettingsCards';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { useParams } from 'react-router-dom';
import { ACCRUAL_RULE } from 'src/graphql/schema/query/accountingunit/AcrualRule';
import { BalancingOfBooksSettings } from 'src/types/accountingunit/BalancingOfBooksSettings';
import { BALANCING_OF_BOOKS_SETTINGS } from 'src/graphql/schema/query/accountingunit/BalancingOfBooksSettings';

const AccountingSettingsTab = () => {
  const { employerId } = useParams();

  return (
    <div className="accounting-settings-tab">
      <QueryWrapper query={BALANCING_OF_BOOKS_SETTINGS} options={{ variables: { employerId } }}>
        {(balancingOfBooksSettings: BalancingOfBooksSettings) => (
          <BalancingOfBooksSettingsCards balancingOfBooksSettings={balancingOfBooksSettings} />
        )}
      </QueryWrapper>
      <QueryWrapper query={ACCRUAL_RULE} options={{ variables: { employerId } }}>
        {(accrualRule: AccrualRule) => <AccrualRuleCards accrualRule={accrualRule} />}
      </QueryWrapper>
    </div>
  );
};

export default AccountingSettingsTab;
