import { gql } from '@apollo/client';

export const CREATE_DRAFT_POLICY = gql`
  mutation CreateDraftPolicy($employerId: UUID!) {
    createDraftPolicy(employerId: $employerId) {
      id
      state
      employerId
    }
  }
`;
