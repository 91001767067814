import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import When from 'src/components/Common/When';
import {
  EmployeeInsuranceEventsFilters,
  EmployeeInsuranceEventsRequest,
} from 'src/types/employees/request/EmployeeInsuranceEventsRequest';
import './employee-insurance-events-filter-details.scss';

interface EmployeeInsuranceEventsFilterDetailsProps {
  request: EmployeeInsuranceEventsRequest;
  applyFilters: (filters: EmployeeInsuranceEventsFilters) => void;
}

export const EmployeeInsuranceEventsFilterDetails: FC<EmployeeInsuranceEventsFilterDetailsProps> = ({
  request,
  applyFilters,
}) => {
  const { t } = useTranslation();
  const hasPeriodStartFilter = !!request.filters?.wageFilePeriodFilter?.periodStart;
  const hasPeriodEndFilter = !!request.filters?.wageFilePeriodFilter?.periodEnd;

  const removePeriodStartFilter = () => {
    applyFilters({
      ...request.filters,
      wageFilePeriodFilter: {
        ...request.filters.wageFilePeriodFilter,
        periodStart: null,
      },
    });
  };

  const removePeriodEndFilter = () => {
    applyFilters({
      ...request.filters,
      wageFilePeriodFilter: {
        ...request.filters.wageFilePeriodFilter,
        periodEnd: null,
      },
    });
  };

  return (
    <div className="employee-insurance-events-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      <When condition={!hasPeriodStartFilter && !hasPeriodEndFilter}>
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      </When>
      <When condition={hasPeriodStartFilter}>
        <FilterButton
          label={
            request.filters?.wageFilePeriodFilter?.periodStart +
            ' ' +
            t('employeeInsuranceEvents:filters.period-start-filter')
          }
          onClick={removePeriodStartFilter}
        />
      </When>
      <When condition={hasPeriodEndFilter}>
        <FilterButton
          label={
            request.filters?.wageFilePeriodFilter?.periodEnd +
            ' ' +
            t('employeeInsuranceEvents:filters.period-end-filter')
          }
          onClick={removePeriodEndFilter}
        />
      </When>
    </div>
  );
};

export default EmployeeInsuranceEventsFilterDetails;
