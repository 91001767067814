import { gql } from '@apollo/client';

export const GET_CONTRACT_SETTING = gql`
  query ContractSetting($employerId: UUID!, $key: String!, $insuranceProviderContractId: UUID!) {
    contractSetting(employerId: $employerId, key: $key, insuranceProviderContractId: $insuranceProviderContractId) {
      id
      key
      value
      fromDate
      toDate
    }
  }
`;
