import { Button, ListGroup } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateDraftEmployee } from 'src/service/employee/UpdateDraftEmployee';
import { Employee } from 'src/types/Employee';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { UpdateDraftEmployeeRequest } from 'src/types/employees/request/UpdateDraftEmployeeRequest';
import { isObject, objectEquals } from 'src/util/ObjectUtil';
import { useUpsertDraftEmployeeCard } from 'src/service/employeecard/UpsertDraftEmployeeCard';
import EmployeeCardInput from './EmployeeCardInput';
import EmployeeInput from './EmployeeInput';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import EmployeeStartDateInput from './EmployeeStartDateInput';
import { endDateIsBeforeStartDate } from './dateUtil';
import ErrorMessage from 'src/components/Common/ErrorMessage';

interface EditableEmploymentDetailsProps {
  employeeDraft: Employee;
  employeeCardDraft: AggregatedEmployeeCard;
  activeEmployee?: Employee;
  activeEmployeeCard?: AggregatedEmployeeCard;
}

function EditableEmploymentDetails({
  employeeDraft,
  employeeCardDraft,
  activeEmployee,
  activeEmployeeCard,
}: EditableEmploymentDetailsProps) {
  const [employeeCardToEdit, setEmployeeCardToEdit] = useState<AggregatedEmployeeCard>({ ...employeeCardDraft });
  const [employeeToEdit, setEmployeeToEdit] = useState<Employee>({ ...employeeDraft });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const { t } = useTranslation();

  const updateDraftEmployee = useUpdateDraftEmployee();
  const upsertDraftEmployeeCard = useUpsertDraftEmployeeCard();

  const handleEmployeeChange = (key: string, value: string) => {
    setEmployeeToEdit({ ...employeeToEdit, [key]: value });
  };

  const handleEmployeeCardChange = (key: string, value: string) => {
    setEmployeeCardToEdit({ ...employeeCardToEdit, [key]: value });
  };

  const handleSaveChanges = async () => {
    setSaveButtonDisabled(true);
    const employeeChanges = Object.keys(employeeDraft).filter(
      (key) => employeeDraft[key] !== employeeToEdit[key] && !isObject(employeeDraft[key]),
    );
    const employeeCardChanges = Object.keys(employeeCardDraft).filter(
      (key) => employeeCardDraft[key] !== employeeCardToEdit[key] && !isObject(employeeCardDraft[key]),
    );

    await handleUpdateDraftEmployee(employeeChanges);
    await handleUpsertDraftEmployeeCard(employeeCardChanges);
  };

  const handleUpdateDraftEmployee = async (employeeChanges: string[]) => {
    if (employeeChanges.length === 0) return;

    const request: UpdateDraftEmployeeRequest = {
      employeeId: employeeDraft.id,
      changes: employeeChanges.map((key) => ({
        fieldKey: key,
        fieldValue: employeeToEdit[key],
      })),
    };

    updateDraftEmployee(request, employeeDraft.employerId);
  };

  const handleUpsertDraftEmployeeCard = async (employeeCardChanges: string[]) => {
    if (employeeCardChanges.length === 0) return;

    const request = {
      employeeCardId: employeeCardDraft.id,
      changes: employeeCardChanges.map((key) => ({
        fieldKey: key,
        fieldValue: employeeCardToEdit[key],
      })),
    };

    await upsertDraftEmployeeCard(request, employeeDraft.employerId);
  };

  useEffect(() => {
    setEmployeeToEdit({ ...employeeDraft });
  }, [employeeDraft]);

  useEffect(() => {
    setEmployeeCardToEdit({ ...employeeCardDraft });
  }, [employeeCardDraft]);

  useEffect(() => {
    setSaveButtonDisabled(
      (objectEquals(employeeDraft, employeeToEdit) && objectEquals(employeeCardDraft, employeeCardToEdit)) ||
        endDateIsBeforeStartDate(employeeDraft, employeeToEdit),
    );
  }, [employeeDraft, employeeToEdit, employeeCardDraft, employeeCardToEdit]);

  const employeeInputProps = {
    employeeToEdit,
    activeEmployee,
    employeeDraft,
    handleChange: handleEmployeeChange,
  };

  const employeeCardInputProps = {
    employeeCardToEdit,
    activeEmployeeCard,
    employeeCardDraft,
    handleChange: handleEmployeeCardChange,
  };

  return (
    <div className="editable-employment-details">
      <EmployeeInDraftBanner />
      <ListGroup variant="inline-edit" className="employment-details-overview-tab__list">
        <EmployeeInput {...employeeInputProps} type="employmentNumber" />
        <EmployeeStartDateInput {...employeeInputProps} type="employmentStartDate" />
        <EmployeeInput {...employeeInputProps} type="employmentEndDate" />
        <EmployeeCardInput {...employeeCardInputProps} type="employmentCategory" />
        <EmployeeCardInput {...employeeCardInputProps} type="employmentType" />
        <EmployeeCardInput {...employeeCardInputProps} type="remunerationType" />
        <EmployeeInput {...employeeInputProps} type="economyDepartment" />
        <EmployeeCardInput {...employeeCardInputProps} type="workingPercentage" />
        <EmployeeCardInput {...employeeCardInputProps} type="entitledVacationDays" />
        <EmployeeCardInput {...employeeCardInputProps} type="partTimePension" />
        {endDateIsBeforeStartDate(employeeDraft, employeeToEdit) && (
          <ErrorMessage message={t('employees:employment-details-start-date-error')} />
        )}
      </ListGroup>

      <Button
        onClick={handleSaveChanges}
        label={t('common:save-changes')}
        disabled={saveButtonDisabled}
        data-cy="save-employee-draft-button"
      />
    </div>
  );
}

export default EditableEmploymentDetails;
