import { gql } from '@apollo/client';

export const GET_INSURANCE_EVENT_INFOS = gql`
  query GetInsuranceEventInfos($insuranceEventIds: [UUID]!) {
    insuranceEventInfos(insuranceEventIds: $insuranceEventIds) {
      insuranceEventId
      benefitType
      agreementNumber
    }
  }
`;
