import { gql } from '@apollo/client';

export const ADD_EMPLOYMENT_GROUP_CONNECTIONS = gql`
  mutation AddEmploymentGroupConnections($benefitPackageId: UUID!, $policyEmploymentGroupIds: [UUID]!) {
    addEmploymentGroupConnections(
      benefitPackageId: $benefitPackageId
      policyEmploymentGroupIds: $policyEmploymentGroupIds
    ) {
      id
    }
  }
`;
