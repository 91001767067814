import { gql } from '@apollo/client';

export const GET_POLICY_EMPLOYMENT_GROUPS = gql`
  query GetPolicyEmploymentGroups($policyId: UUID!) {
    policyEmploymentGroups(policyId: $policyId) {
      id
      employmentGroupId
      employmentGroupName
    }
  }
`;
