import { DrawerNavigation, ListGroup, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { PglRule } from 'src/types/policy/PglRule';
import { PolicyPglRuleDrawerTab } from 'src/types/tabs/PolicyPglRuleDrawerTab';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import DrawerBase from 'src/components/Common/DrawerBase';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import React from 'react';
import PglCalculationList from './PglCalculationList';
import { PglBonusComponent } from 'src/types/policy/PglBonusComponent';
import { PglWageType } from 'src/types/policy/PglWageType';
import { isNotBonusComponent } from 'src/util/PolicyUtil';
import When from 'src/components/Common/When';

interface PglRuleOverviewProps {
  pglRule: PglRule;
  onClose: () => void;
  handleSelectPglWageType: (pglWageTypeId: string) => void;
  handleSelectBonusComponent: (pglBonusComponentId: string) => void;
  handleCreateNewCalculation: () => void;
  handleEditMinimumReportableSetting: () => void;
  inDraft: boolean;
  currentTab: PolicyPglRuleDrawerTab;
  setCurrentTab: (tab: PolicyPglRuleDrawerTab) => void;
  employerWageTypes: EmployerWageType[];
}

const PglRuleOverview = ({
  pglRule,
  onClose,
  handleSelectPglWageType,
  handleSelectBonusComponent,
  handleCreateNewCalculation,
  handleEditMinimumReportableSetting,
  inDraft,
  currentTab,
  setCurrentTab,
  employerWageTypes,
}: PglRuleOverviewProps) => {
  const { t } = useTranslation(['account']);

  const handleSelect = (selected: PglBonusComponent | PglWageType) => {
    if (isNotBonusComponent(selected)) {
      handleSelectPglWageType(selected.id);
    } else {
      handleSelectBonusComponent(selected.id);
    }
  };

  return (
    <DrawerBase title={pglRule.name} onClose={onClose}>
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('account:pgl-rules.drawer.calculation.title')}>
          <div className="pgl-rule-drawer">
            {inDraft && (
              <ListGroup variant="drawer-navigation">
                <DrawerNavigation
                  label={t('account:pgl-rules.drawer.calculation.add')}
                  onClick={handleCreateNewCalculation}
                />
              </ListGroup>
            )}
            <Typography variant="body1">{t('account:pgl-rules.drawer.calculation.pglType')}</Typography>
            <Typography variant="caption">{t('account:pgl-rules.drawer.calculation.pglSubTitle')}</Typography>
            <PglCalculationList
              list={[...pglRule.pglWageTypes, ...pglRule.pglBonusComponents]}
              onClick={handleSelect}
              employerWageTypes={employerWageTypes}
            />
            <When condition={pglRule.spglWageTypes.length > 0}>
              <Typography variant="body1">{t('account:pgl-rules.drawer.calculation.spglType')}</Typography>
              <Typography variant="caption">{t('account:pgl-rules.drawer.calculation.sPglSubTitle')}</Typography>

              <PglCalculationList
                list={[...pglRule.spglWageTypes]}
                onClick={handleSelect}
                employerWageTypes={employerWageTypes}
              />
            </When>
          </div>
        </Tab>
        <Tab title={t('account:pgl-rules.drawer.reporting.title')}>
          <div className="pgl-rule-drawer">
            <ListGroup variant={'drawer-navigation'}>
              <DrawerNavigation
                key={pglRule.id}
                onClick={handleEditMinimumReportableSetting}
                label={t`account:pgl-rules:drawer:reporting:reportable-changeable-amount-information-label`}
                value={formatSweAmountTextWithSuffixNoDecimal(pglRule.minimumReportableChangeAmount)}
              />
            </ListGroup>
          </div>
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default PglRuleOverview;
