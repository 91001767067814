import { addToCacheList, removeFromCacheList } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import { Employee } from 'src/types/Employee';

export const updateCheckpointToFinalizeList = (employee: Employee, action: 'ADD' | 'REMOVE') => {
  if (action === 'ADD') {
    addToCacheList(EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE, {
      employeeId: employee.id,
      employerId: employee.employerId,
    });
  } else if (action === 'REMOVE') {
    removeFromCacheList(EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE, employee.id);
  }
};
