import { Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { useTranslation } from 'react-i18next';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import AddAgreedProductInputs from './AddAgreedProductInputs';

interface AddAgreedProductProps {
  policyBenefit: PolicyBenefit;
}

const AddAgreedProduct: FC<AddAgreedProductProps> = ({ policyBenefit }) => {
  const { employerId } = useParams();
  const { t } = useTranslation();

  return (
    <QueryWrapper query={GET_INSURANCE_PROVIDER_CONTRACTS} type="list" options={{ variables: { employerId } }}>
      {(contracts: InsuranceProviderContract[]) => {
        return (
          <>
            <div className="drawer-base__title-container">
              <Typography variant="h3" component="h3" bold>
                {t('policySettings:agreed-products-tab.add-agreed-product')}
              </Typography>
              <div className="sub-title-pill">
                <Pill label={t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)} />
                <Typography variant="body2">{policyBenefit.name}</Typography>
              </div>
            </div>
            <AddAgreedProductInputs contracts={contracts} benefitId={policyBenefit.id} />
          </>
        );
      }}
    </QueryWrapper>
  );
};

export default AddAgreedProduct;
