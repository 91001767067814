import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApolloServiceWrapper from 'src/components/Common/ApolloServiceWrapper';
import FilterComponent from 'src/components/Common/FilterComponent';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import ScrollBase from 'src/components/Common/ScrollBase';
import { resetCacheList } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import useVerifyInsuranceEventsCheckpoints from 'src/service/checkpoint/GetVerifyInsuranceEventsCheckpoints';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { Employer } from 'src/types/employer/Employer';
import VerifyDrawer from '../VerifyDrawer';
import useFilters from '../hooks/useFilters';
import CheckpointListItem from './CheckpointListItem';

interface CheckpointsListProps {
  employers: Employer[];
  wageFilePeriod: string;
  setNumberOfCheckPoints: (numberOfCheckPoints: number) => void;
}

const CheckpointsList: React.FC<CheckpointsListProps> = ({ employers, wageFilePeriod, setNumberOfCheckPoints }) => {
  const { t } = useTranslation();
  const [chosenEmployeeId, setChosenEmployeeId] = useState<string>(null);
  const employerIds = employers.map((e) => e.id);

  const service = useVerifyInsuranceEventsCheckpoints(employerIds, wageFilePeriod);

  const [employerFilterProps, insuranceEventFilterProps, nameFilter, setNameFilter, filteredData] = useFilters(
    service,
    employers,
  );

  useEffect(() => {
    resetCacheList(EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE);
  }, []);

  useEffect(() => {
    setNumberOfCheckPoints(filteredData.length);
  }, [filteredData]);

  return (
    <ApolloServiceWrapper service={service}>
      <FilterComponent
        showSearch
        searchPlaceholder={t('searchTerms:search-employee')}
        searchFilter={nameFilter}
        onSearchFilterChange={(e) => setNameFilter(e.target.value)}
        selectProps={[employerFilterProps, insuranceEventFilterProps]}
      />
      <ScrollBase maxHeightPercentage={65}>
        <QueryWrapper query={GET_INSURANCE_PROVIDERS}>
          {(insuranceProviders: InsuranceProvider[]) => (
            <>
              {filteredData.map((eventReport) => (
                <CheckpointListItem
                  insuranceProviders={insuranceProviders}
                  key={eventReport.employee.id}
                  eventReport={eventReport}
                  employers={employers}
                  setEmployeeId={() => setChosenEmployeeId(eventReport.employee.id)}
                />
              ))}
            </>
          )}
        </QueryWrapper>
      </ScrollBase>
      <VerifyDrawer
        fromPeriod={wageFilePeriod}
        toPeriod={wageFilePeriod}
        open={chosenEmployeeId != null}
        handleClose={() => setChosenEmployeeId(null)}
        eventReport={filteredData.find((eventReport) => eventReport.employee.id == chosenEmployeeId)}
      />
    </ApolloServiceWrapper>
  );
};

export default CheckpointsList;
