import { client } from 'src/graphql';
import { UPLOAD_WAGE_FILE } from 'src/graphql/schema/mutation/UploadWageFile';
import { NEW_UPLOAD_SESSION } from 'src/graphql/schema/query/NewUploadSession';

export const uploadFile = async (period: string, file: File) => {
  const {
    data: { createSession: sessionId },
  } = await client.query({
    query: NEW_UPLOAD_SESSION,
    variables: {
      period,
    },
    fetchPolicy: 'no-cache',
  });
  await uploadWageFile(sessionId, file);
};

const uploadWageFile = (sessionId: string, file: File) => {
  return client.mutate({
    mutation: UPLOAD_WAGE_FILE,
    variables: {
      sessionId,
      file,
    },
    context: {
      useMultipart: true,
    },
  });
};
