import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import ReportingRuleConfigurationInput from './ReportingRuleConfigurationInput/ReportingRuleConfigurationInput';
import InsuranceProductInput from './InsuranceProductInput/InsuranceProductInput';
import ProductConditionInputs from './CertifyConditionInputs/CertifyConditionInputs';
import TextInput from 'src/components/Common/TextInput';
import DefaultReportingDetailLevelInput from './DefaultReportingDetailLevelInput';

interface AgreedProductInputsProps {
  contract: InsuranceProviderContract;
  agreedProductRequest: ContractAgreedProductRequest;
  onChange: (key: string, value: string | boolean) => void;
}

const AgreedProductInputs = ({ agreedProductRequest, contract, onChange }: AgreedProductInputsProps) => {
  const { t } = useTranslation();

  const handleNameChange = (name: string) => {
    onChange('name', name.length > 0 ? name : null);
  };

  const handleInsuranceCategoryChange = (insuranceCategory: string) => {
    onChange('insuranceCategory', insuranceCategory.length > 0 ? insuranceCategory : null);
  };

  const handleAgreementProductCodeChange = (agreementProductCode: string) => {
    onChange('agreementProductCode', agreementProductCode.length > 0 ? agreementProductCode : null);
  };

  return (
    <ListGroup variant="inline-edit">
      <InlineEdit label={t('common:name')} mandatory bold>
        <TextInput
          type="text"
          validationKey="text64"
          variant="inline-edit"
          maxLength={65}
          value={agreedProductRequest.name ?? ''}
          onChange={(e) => handleNameChange(e.target.value)}
          data-cy="agreed-product-name-input"
        />
      </InlineEdit>
      <InsuranceProductInput contract={contract} agreedProductRequest={agreedProductRequest} onChange={onChange} />
      <ReportingRuleConfigurationInput
        contract={contract}
        agreedProductRequest={agreedProductRequest}
        onChange={onChange}
      />
      <DefaultReportingDetailLevelInput agreedProductRequest={agreedProductRequest} onChange={onChange} />
      <InlineEdit label={t('agreementRegistrySettings:category')} bold>
        <TextInput
          type="text"
          validationKey="text64"
          variant="inline-edit"
          maxLength={65}
          value={agreedProductRequest.insuranceCategory ?? ''}
          onChange={(e) => handleInsuranceCategoryChange(e.target.value)}
        />
      </InlineEdit>
      <InlineEdit label={t('agreementRegistrySettings:product-code')} bold>
        <TextInput
          type="text"
          validationKey="text16"
          variant="inline-edit"
          maxLength={17}
          value={agreedProductRequest.agreementProductCode ?? ''}
          onChange={(e) => handleAgreementProductCodeChange(e.target.value)}
        />
      </InlineEdit>
      <ProductConditionInputs contract={contract} agreedProductRequest={agreedProductRequest} onChange={onChange} />
    </ListGroup>
  );
};

export default AgreedProductInputs;
