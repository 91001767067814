import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InlineEdit, ListGroup, Select, Switch, Option } from 'fgirot-k2-ui-components';
import { Collapsible } from 'fgirot-k2-ui-components';
import UpdateInput from 'src/components/Common/UpdateInput';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { GroupedAffiliationRules } from 'src/types/policy/GroupedAffiliationRules';
import { AffiliationRule, PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { RuleValue, RuleValueType } from 'src/types/policy/RuleValue';
import { getDefaultRuleValue, getValidValueTypes } from '../../AffiliationRuleTypeConfig';
import '../../../PolicyEmploymentGroupModal/RuleTypeDrawer/rule-type-drawer.scss';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';

interface OneRuleValueLimitRuleProps {
  policyState: PolicyState;
  selectedAffiliationRuleGroup: GroupedAffiliationRules;
  policyRuleType: PolicyRuleType;
  onUpdateOrDeleteAffiliationRule: (request: UpdateOrDeleteAffiliationRuleRequest) => void;
}

const OneRuleValueLimitRule: React.FunctionComponent<OneRuleValueLimitRuleProps> = ({
  policyState,
  selectedAffiliationRuleGroup,
  policyRuleType,
  onUpdateOrDeleteAffiliationRule,
}) => {
  const { t } = useTranslation();
  const defaultRuleValue: RuleValue = getDefaultRuleValue(policyRuleType);
  const validValueTypes: RuleValueType[] = getValidValueTypes(policyRuleType);

  const valueTypeOptions: Option<RuleValueType>[] = validValueTypes.map((vt) => {
    return {
      label: t(`policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.valueType.${vt}`),
      value: vt,
    };
  });

  const getCurrentRule = (): AffiliationRule =>
    selectedAffiliationRuleGroup.affiliationRules.find((ruleType) => ruleType.policyRuleType === policyRuleType);

  const getCurrentRuleValue = (): RuleValue =>
    getCurrentRule() && getCurrentRule().ruleValues.length > 0 ? getCurrentRule().ruleValues[0] : undefined;

  const getCurrentSelected = (): Option<RuleValueType> => {
    const currentRuleValue = getCurrentRuleValue();
    return currentRuleValue && valueTypeOptions.find((option) => option.value === currentRuleValue.valueType);
  };

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (policyState === 'DRAFT') {
      const ruleValues: RuleValue[] = event.target.checked ? [{ ...defaultRuleValue }] : [];
      onUpdateOrDeleteAffiliationRule({ ruleId: getCurrentRule()?.id, policyRuleType, ruleValues });
    }
  };

  const handleSelectValueType = (option: Option<RuleValueType>) => {
    if (policyState === 'DRAFT') {
      const newOption = option.value as RuleValueType;
      const ruleValues: RuleValue[] = [{ ...getCurrentRuleValue(), valueType: newOption }];
      onUpdateOrDeleteAffiliationRule({ ruleId: getCurrentRule()?.id, policyRuleType, ruleValues });
    }
  };

  const handleOnBlur = (field: string, value: string) => {
    if (policyState === 'DRAFT') {
      const ruleValues: RuleValue[] = [{ ...getCurrentRuleValue(), value: value }];
      onUpdateOrDeleteAffiliationRule({ ruleId: getCurrentRule()?.id, policyRuleType, ruleValues });
    }
  };

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant="inline-edit">
        <InlineEdit
          bold
          key={`OneRuleValueLimitRule-${policyRuleType}-toggle`}
          label={t(`policyRules:policyRuleType.${policyRuleType}`)}
        >
          <Switch
            toggled={!!getCurrentRule()}
            onChange={(event) => handleToggle(event)}
            data-cy={`one-rule-value-limit-rule__switch-${policyRuleType}`}
          />
        </InlineEdit>
      </ListGroup>
      <Collapsible className="rule-type-drawer__list-group-collapsible" open={!!getCurrentRule()}>
        <ListGroup variant="inline-edit">
          <InlineEdit
            bold
            key={`OneRuleValueLimitRule-${policyRuleType}-valueType`}
            label={t(
              `policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.value-type-label`,
            )}
          >
            <Select
              disabled={policyState !== 'DRAFT'}
              options={valueTypeOptions}
              selected={getCurrentSelected()}
              onChange={handleSelectValueType}
              data-cy={`one-rule-value-limit-rule__dropdown-${policyRuleType}`}
              small
            />
          </InlineEdit>
          <InlineEdit
            bold
            key={`OneRuleValueLimitRule-${policyRuleType}-value`}
            label={t(
              `policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.value-label.${policyRuleType}`,
            )}
          >
            <UpdateInput
              placeholder={t(`policyRules:ruleValues.valueUnit.DAYS`)}
              onBlur={handleOnBlur}
              disabled={policyState !== 'DRAFT'}
              fieldKey={policyRuleType}
              fieldValue={getCurrentRuleValue() ? getCurrentRuleValue().value : defaultRuleValue.value}
              type="number"
              validationKey="days"
              data-cy={`one-rule-value-limit-rule__input-${policyRuleType}`}
            />
          </InlineEdit>
        </ListGroup>
      </Collapsible>
    </div>
  );
};

export default OneRuleValueLimitRule;
