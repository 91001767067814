import { PglType, pglTypes } from 'src/types/policy/PglType';
import { InlineEdit, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';

interface PglTypeSelectProps {
  pglType: PglType;
  setPglType: (pglType: PglType) => void;
  disabled: boolean;
}

const PglTypeSelect = ({ pglType, setPglType, disabled }: PglTypeSelectProps) => {
  const { t } = useTranslation();
  const pglTypeOptions: Option<PglType>[] = pglTypes.map((type) => ({ label: type, value: type }));

  return (
    <InlineEdit label={t('policySettings:calculations-tab.create-calculation-modal.choose-type')} bold>
      <Select
        selected={pglTypeOptions.find((v) => v.value == pglType)}
        onChange={(selected: Option<PglType>) => setPglType(selected.value)}
        options={pglTypeOptions}
        disabled={disabled}
        small
      />
    </InlineEdit>
  );
};

export default PglTypeSelect;
