import { DrawerNavigation } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConnectionRuleRowProps {
  title: string;
  value?: boolean;
  onClick: () => void;
}

const ConnectionRuleRow = ({ title, value, onClick }: ConnectionRuleRowProps) => {
  const { t } = useTranslation();
  return (
    <DrawerNavigation
      onClick={onClick}
      label={title}
      value={
        null == value
          ? '-'
          : value
          ? t('account:employers-tab.policy-settings.table.optional')
          : t('account:employers-tab.policy-settings.table.mandatory')
      }
    />
  );
};

export default ConnectionRuleRow;
