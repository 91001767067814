import { gql } from '@apollo/client';

export const CREATE_ACCOUNTING_UNIT = gql`
  mutation CreateAccountingUnit($request: CreateAccountingUnitInput!) {
    createAccountingUnit(request: $request) {
      id
      name
      description
      defaultAccountingUnit
      chartOfAccounts {
        id
        name
        description
      }
    }
  }
`;
