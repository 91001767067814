import { Table } from 'fgirot-k2-ui-components';
import React from 'react';
import ScrollBase from '../ScrollBase';
import './table-base.scss';

interface TableBaseProps {
  children: JSX.Element[];
  maxHeightPercentage?: number;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  loadMore?: boolean;
  compressed?: boolean;
  hiddenColumns?: string[];
  setHiddenColumns?: (columns: string[]) => void;
}

const TableBase = ({
  children,
  maxHeightPercentage = 100,
  onScroll,
  loadMore,
  compressed,
  hiddenColumns,
  setHiddenColumns,
}: TableBaseProps) => {
  return (
    <ScrollBase loadMore={loadMore} onScroll={onScroll} maxHeightPercentage={maxHeightPercentage}>
      <Table compressed={compressed} hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns}>
        {children}
      </Table>
    </ScrollBase>
  );
};

export default TableBase;
