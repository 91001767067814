import { CreateDirectDocumentInput } from 'src/types/documents/CreateDirectDocumentInput';
import { useApolloMutation } from '../graphql/Mutation';
import { CREATE_DIRECT_DOCUMENT_PDF } from 'src/graphql/schema/mutation/document/CreateDirectDocumentPdf';
import { GET_ASSIGNED_DOCUMENTS_TEMPLATES } from 'src/graphql/schema/query/documents/GetAssignedDocumentTemplates';

export const useCreateDirectDocumentPdf = () => {
  const apolloMutation = useApolloMutation();

  const createDirectDocumentPdf = (employerId: string, request: CreateDirectDocumentInput) =>
    apolloMutation(CREATE_DIRECT_DOCUMENT_PDF, employerId, { request }, [GET_ASSIGNED_DOCUMENTS_TEMPLATES]);

  return createDirectDocumentPdf;
};
