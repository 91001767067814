import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmployersTab from 'src/components/Account/EmployersTab';
import PageBase from 'src/components/PageBase';
import { useCustomer } from 'src/service/customer/GetCustomer';
import './account.scss';
import useTabParams from 'src/components/hooks/useTabParams';
import { AccountTab } from 'src/types/tabs/AccountTab';

const Account = () => {
  const { t } = useTranslation('account');
  const customer = useCustomer();
  const [currentTab, setCurrentTab] = useTabParams('tab', AccountTab.EMPLOYERS);

  return (
    customer && (
      <PageBase header title={customer.name}>
        <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
          <Tab title={t('account:employers')}>
            <EmployersTab customer={customer} />
          </Tab>
        </Tabs>
      </PageBase>
    )
  );
};

export default Account;
