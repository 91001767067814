import { ListGroup, InlineEdit, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractAgreedProduct } from 'src/types/contract/ContractAgreedProduct';

interface AgreedProductDataProps {
  agreementNumber: string;
  agreedProduct: ContractAgreedProduct;
}

const AgreedProductData: FC<AgreedProductDataProps> = ({ agreementNumber, agreedProduct }) => {
  const { t } = useTranslation();

  return (
    agreedProduct && (
      <div className="policy-benefit-drawer__tab">
        <ListGroup variant="drawer-navigation" className="policy-benefit-drawer__tab__table">
          <InlineEdit bold label={t('agreementRegistrySettings:insurance-product')}>
            <Typography variant="body2">{agreedProduct.name}</Typography>
          </InlineEdit>
          <InlineEdit bold label={t('agreementRegistrySettings:insurance-provider')}>
            <Typography variant="body2">{agreedProduct.insuranceProduct.insuranceProvider.legalName}</Typography>
          </InlineEdit>
          <InlineEdit bold label={t('agreementRegistrySettings:agreement-number')}>
            <Typography variant="body2">{agreementNumber}</Typography>
          </InlineEdit>
          <InlineEdit bold label={t('agreementRegistrySettings:category')}>
            <Typography variant="body2">{agreedProduct.insuranceProviderProductCategory}</Typography>
          </InlineEdit>
        </ListGroup>
      </div>
    )
  );
};

export default AgreedProductData;
