import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { useEmployersInCustomer } from 'src/service/employer/GetEmployersInCustomer';
import { useState } from 'react';
import { SearchEmployeesFilters } from 'src/types/employees/SearchEmployeesRequest';
import { employmentNumberFilterType, EmploymentNumberFilterType } from 'src/types/Employee';
import { createOptions } from 'src/util/OptionUtil';
import './search-filters-modal.scss';

interface SearchFilterModalProps {
  open: boolean;
  customerId: string;
  initialFilters: SearchEmployeesFilters;
  onCancel: () => void;
  onApply: (newFilters: SearchEmployeesFilters) => void;
}

const SearchFiltersModal: React.FunctionComponent<SearchFilterModalProps> = ({
  open,
  customerId,
  initialFilters,
  onCancel,
  onApply,
}) => {
  const { t } = useTranslation();
  const employers = useEmployersInCustomer(customerId, 'cache-first');
  const [internalFilters, setInternalFilters] = useState<SearchEmployeesFilters>(initialFilters);

  const handleOnClose = () => {
    onCancel();
  };

  const handleApply = () => {
    onApply(internalFilters);
  };

  const updateEmployersFilter = (input: Option<string>[]) => {
    setInternalFilters({
      ...internalFilters,
      employerFilter:
        input.length > 0
          ? {
              employerIds: input.map((it) => it.value),
            }
          : undefined,
    });
  };

  const updateEmploymentNumberFilter = (input: Option<string>) => {
    setInternalFilters({
      ...internalFilters,
      employmentNumberFilter: input.value,
    });
  };

  const employerOptions =
    employers.status !== 'loaded'
      ? []
      : employers.payload.map(
          (employer) =>
            ({
              label: employer.legalName,
              value: employer.id,
            } as Option<string>),
        );

  const employmentNumberFilterOptions: Option<EmploymentNumberFilterType>[] = createOptions(
    employmentNumberFilterType,
    t,
    'employees:employment-number-filter',
  );

  const getSelectedEmployers = () => {
    return employerOptions.filter((option) => internalFilters.employerFilter?.employerIds.includes(option.value));
  };

  return (
    <Modal data-cy="search-filters-modal" centered overlayBackground open={open} onClose={handleOnClose}>
      <Card
        className="search-filters-modal"
        title={t('employees:search-filters:filter')}
        icon={<Icon type="Sliders" className="search-filters-modal__icon" />}
        size="wide"
      >
        <Select
          multiple
          label={t('employees:search-filters:employer')}
          placeholder={t('common:select')}
          selectAll
          options={employerOptions}
          selected={getSelectedEmployers()}
          onChange={updateEmployersFilter}
          data-cy="search-filters-modal__dropdown-employers"
          className="filter-dropdown"
          small
          outlined
        />
        <Select
          label={t('employees:search-filters.employment-number')}
          placeholder={t('common:select')}
          options={employmentNumberFilterOptions}
          selected={employmentNumberFilterOptions.find(
            (option) => option.value === internalFilters.employmentNumberFilter,
          )}
          onChange={updateEmploymentNumberFilter}
          data-cy="search-filters-modal__dropdown-employmentNumberOptions"
          className="filter-dropdown"
          small
          allowDropdownMaxWidth
          outlined
        />
        <CardAction>
          <CardAction>
            <Button
              label={t('common:cancel')}
              type="link"
              onClick={handleOnClose}
              data-cy="search-filters-modal__cancel-button"
            />
            <Button
              label={t('employees:search-filters:use')}
              onClick={handleApply}
              data-cy="search-filters-modal__use-button"
            />
          </CardAction>
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchFiltersModal;
