import { InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePglRules } from 'src/service/policy/GetPglRules';
import { useUpdatePolicyBenefitCalculationRule } from 'src/service/policy/UpdatePolicyBenefitCalculationRule';
import { PglRule } from 'src/types/policy/PglRule';
import { PglRuleReferences } from 'src/types/policy/PglRuleReferences';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyState } from 'src/types/policy/PolicyEntity';

interface PglBlockProps {
  pglRuleReferences: PglRuleReferences;
  policyEntityState: PolicyState;
  policyId: string;
  policyBenefit: PolicyBenefit;
  employerId: string;
}

export const PglBlock: FC<PglBlockProps> = ({
  pglRuleReferences,
  policyEntityState,
  policyId,
  policyBenefit,
  employerId,
}) => {
  const { t } = useTranslation();
  const pgl = pglRuleReferences.pglRuleReference;
  const spgl = pglRuleReferences.spglRuleReference;
  const updatePolicyBenefitCalculationRule = useUpdatePolicyBenefitCalculationRule();

  const pglRules: PglRule[] = usePglRules(policyId);

  const options: Option<string>[] = [{ label: t('common:not-in-use'), value: undefined }].concat(
    pglRules.map((pglRule) => ({
      label: pglRule.name,
      value: pglRule.id,
    })),
  );

  const spglOptions = [
    { label: t('common:no'), value: undefined },
    { label: t('common:yes'), value: pgl?.pglRuleId },
  ];

  const updatePglModel = (newPgl: string, newSpgl: string) => {
    return updatePolicyBenefitCalculationRule(
      {
        policyId,
        benefitPackageId: policyBenefit.benefitPackageId,
        pglRuleId: newPgl,
        spglRuleId: newPgl == undefined ? undefined : newSpgl,
      },
      employerId,
    );
  };

  const pglChanged = (selection: Option<string>) => {
    updatePglModel(selection.value, spgl?.pglRuleId);
  };

  const spglChanged = (selection: Option<string>) => {
    updatePglModel(pgl?.pglRuleId, selection.value);
  };

  return (
    <ListGroup variant="inline-edit" className="policy-benefit-drawer__tab__table">
      <InlineEdit bold label={t('account:employers-tab.policy-settings.table.drawer.pgl-model')} key="pgl">
        <Select
          placeholder={t('common:select')}
          disabled={policyEntityState !== 'DRAFT'}
          options={options}
          selected={options.find((opt) => opt.value === pgl?.pglRuleId)}
          onChange={pglChanged}
          data-cy="policy-benefit-calculation__type_dropdown"
          small
        />
      </InlineEdit>
      <InlineEdit bold label={t('account:employers-tab.policy-settings.table.drawer.spgl-model')} key="spgl">
        <Select
          placeholder={t('common:select')}
          disabled={policyEntityState !== 'DRAFT' || pgl == null}
          options={spglOptions}
          selected={spglOptions.find((opt) => opt.value === spgl?.pglRuleId)}
          onChange={spglChanged}
          data-cy="policy-benefit-calculation__type_dropdown"
          small
        />
      </InlineEdit>
    </ListGroup>
  );
};

export default PglBlock;
