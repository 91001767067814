import { gql } from '@apollo/client';

export const CREATE_EMPLOYMENT_GROUP_RULE_BLOCK = gql`
  mutation CreateEmploymentGroupRuleBlock($employmentGroupId: UUID!) {
    createAffiliationRuleBlock(employmentGroupId: $employmentGroupId) {
      id
    }
  }
`;

export const CREATE_BENEFIT_RULE_BLOCK = gql`
  mutation CreateBenefitRuleBlock($benefitPackageId: UUID!) {
    createAffiliationRuleBlock(benefitPackageId: $benefitPackageId) {
      id
    }
  }
`;
