import { Button, Card, CardAction, Icon, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { createOptionsFromList } from 'src/util/OptionUtil';

import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { unique } from 'src/util/ArrayUtil';
import { Month } from 'src/types/Month';
import { useMonthOptions } from 'src/util/date/Months';
import { useYearOptions } from 'src/util/date/Years';
import InfoMessage from 'src/components/Common/InfoMessage';
import './search-premium-invoice-rows-filters-modal.scss';
import { PremiumInvoiceRowsFilter } from 'src/types/invoice/PremiumInvoiceRowsFilter';

interface SearchPremiumInvoiceRowsFiltersModalProps {
  open: boolean;
  filter: PremiumInvoiceRowsFilter;
  onClose: () => void;
  onApplyFilters: (newFilters: PremiumInvoiceRowsFilter) => void;
}

const SearchPremiumInvoiceRowsFiltersModal: FC<SearchPremiumInvoiceRowsFiltersModalProps> = ({
  open,
  filter,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilter, setNewFilter] = useState<PremiumInvoiceRowsFilter>({ ...filter });
  const yearsOptions = useYearOptions(true);
  const monthOptions = useMonthOptions(true);
  const handlePeriodStartYearChange = (option: Option<number>) => {
    setNewFilter({
      ...newFilter,
      periodStart: {
        year: option.value,
        month: newFilter.periodStart.month,
      },
    });
  };

  const handlePeriodStartMonthChange = (option: Option<Month>) => {
    setNewFilter({
      ...newFilter,
      periodStart: {
        year: newFilter.periodStart.year,
        month: option.value,
      },
    });
  };

  const handlePeriodEndYearChange = (option: Option<number>) => {
    setNewFilter({
      ...newFilter,
      periodEnd: {
        year: option.value,
        month: newFilter.periodEnd.month,
      },
    });
  };

  const handlePeriodEndMonthChange = (option: Option<Month>) => {
    setNewFilter({
      ...newFilter,
      periodEnd: {
        year: newFilter.periodEnd.year,
        month: option.value,
      },
    });
  };

  const handleInsuranceProviderChange = (options: Option<string>[]) => {
    setNewFilter({
      ...newFilter,
      insuranceProviderIds: options.map((option) => option.value),
    });
  };
  const onApply = () => {
    onApplyFilters(newFilter);
    onClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="search-premium-invoice-rows-filters-modal"
        title={t('premiumInvoiceRows:filter-modal.title')}
        icon={<Icon type="Sliders" className="search-premium-invoice-rows-filters-modal__icon" />}
        size="wide"
      >
        <div className="search-premium-invoice-rows-filters-modal__filters">
          <QueryWrapper query={GET_INSURANCE_PROVIDERS}>
            {(insuranceProviders: InsuranceProvider[]) => {
              const insuranceProviderOptions = unique(createOptionsFromList(insuranceProviders, 'id', 'legalName'));
              return (
                <div className="search-premium-invoice-rows-filters-modal__select">
                  <Select
                    label={t('premiumInvoiceRows:filter-modal.insurance-provider-label')}
                    placeholder={t('common:select')}
                    selectAll
                    options={insuranceProviderOptions}
                    selected={insuranceProviderOptions.filter((option) =>
                      newFilter.insuranceProviderIds.includes(option.value),
                    )}
                    onChange={handleInsuranceProviderChange}
                    small
                    outlined
                    multiple
                  />
                </div>
              );
            }}
          </QueryWrapper>

          <div className="search-premium-invoice-rows-filters-modal__date-section">
            <div className="date-filter">
              <div className="date-filter__period">
                <Typography component="label" variant="caption" className="date-filter__label">
                  {t('common:period-filter.choose-period-start')}
                </Typography>
                <div className="date-filter__period__selects">
                  <Select
                    options={yearsOptions}
                    alphabetical={false}
                    onChange={handlePeriodStartYearChange}
                    selected={yearsOptions.find((option) => option.value === newFilter.periodStart.year)}
                    data-testid="start-year-select"
                    small
                    outlined
                  />
                  <Select
                    options={monthOptions}
                    alphabetical={false}
                    onChange={handlePeriodStartMonthChange}
                    selected={monthOptions.find((option) => option.value == newFilter.periodStart.month)}
                    disabled={!newFilter.periodStart.year}
                    data-testid="start-month-select"
                    small
                    outlined
                  />
                </div>
              </div>
              <div className="date-filter__period">
                <Typography component="label" variant="caption" className="date-filter__label">
                  {t('common:period-filter.choose-period-end')}
                </Typography>
                <div className="date-filter__period__selects">
                  <Select
                    options={yearsOptions}
                    alphabetical={false}
                    onChange={handlePeriodEndYearChange}
                    selected={yearsOptions.find((option) => option.value == newFilter.periodEnd.year)}
                    data-testid="end-year-select"
                    small
                    outlined
                  />
                  <Select
                    options={monthOptions}
                    alphabetical={false}
                    onChange={handlePeriodEndMonthChange}
                    selected={monthOptions.find((option) => option.value == newFilter.periodEnd.month)}
                    disabled={!newFilter.periodEnd.year}
                    data-testid="end-month-select"
                    small
                    outlined
                  />
                </div>
              </div>
            </div>
            <InfoMessage message={t('common:period-filter-info-message')} />
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('premiumInvoiceRows:filter-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchPremiumInvoiceRowsFiltersModal;
