import { useApolloMutation } from '../graphql/Mutation';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';
import { CREATE_EMPLOYMENT_EVENT_WAGES } from 'src/graphql/schema/mutation/reporting/CreateEmploymentEventWages';
import { CreateEmploymentEventWagesRequest } from 'src/types/reporting/CreateEmploymentEventWages';

export const useCreateEmploymentEventWages = () => {
  const apolloMutation = useApolloMutation();

  return (request: CreateEmploymentEventWagesRequest, employerId: string) =>
    apolloMutation(
      CREATE_EMPLOYMENT_EVENT_WAGES,
      employerId,
      {
        request,
      },
      [FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES],
    );
};
