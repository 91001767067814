import React, { useState } from 'react';
import './users-tab.scss';
import UsersTable from './UsersTable';
import { GetUsersPage, UserFilter } from 'src/types/user/User';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_USERS } from 'src/graphql/schema/query/user/GetUsers';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import UsersDrawer from './UsersDrawer';
import { useTranslation } from 'react-i18next';
import CreateUserModal from './CreateUserModal';
import useModal from 'src/util/Hooks/ModalHook';

const UsersTab = () => {
  const { t } = useTranslation();
  const { setModalOpen, modalOpen, closeModal } = useModal();
  const [getUsersPageNumber, setUsersPageNumber] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const getUsersQueryPageNumber = getUsersPageNumber - 1;
  const pageSize = 8;

  const handleClickOnUserRow = (userId: string) => {
    setSelectedUserId(userId);
  };

  const handleOnCloseDrawer = () => {
    setSelectedUserId(undefined);
  };

  return (
    <div className="users-tab" data-testid={'users-tab'}>
      <QueryWrapper
        query={GET_USERS}
        options={{ variables: { userFilter: UserFilter.ALL, pageSize, pageNumber: getUsersQueryPageNumber } }}
      >
        {({ externalUsers, paging }: GetUsersPage) => {
          const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
          const showPagination = totalPages > 1;
          return (
            <>
              {externalUsers.length && (
                <UsersTable users={externalUsers} onClick={handleClickOnUserRow} data-testid={`users-tab-table`} />
              )}
              {showPagination && (
                <Pagination
                  currentPage={getUsersPageNumber}
                  onPageChange={(nextPageNumber) => setUsersPageNumber(nextPageNumber)}
                  totalPages={totalPages}
                />
              )}
              {selectedUserId && <UsersDrawer open onClose={handleOnCloseDrawer} userId={selectedUserId} />}
            </>
          );
        }}
      </QueryWrapper>
      <div className="users-tab__create-user-modal-button" data-testid="users-tab-create-user-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'PlusLarge'} />}
          label={t('permission:create-user-button')}
          onClick={setModalOpen}
        />
      </div>

      <CreateUserModal open={modalOpen} onClose={closeModal} />
    </div>
  );
};

export default UsersTab;
