import { UPDATE_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/mutation/contract/UpdateInsuranceProviderContract';
import { GET_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/query/contract/GetInsuranceProviderContract';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { UpdateInsuranceProviderContractRequest } from 'src/types/contract/request/UpdateInsuranceProviderContractRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateInsuranceProviderContract = () => {
  const apolloMutation = useApolloMutation();

  const updateInsuranceProviderContract = (request: UpdateInsuranceProviderContractRequest, employerId: string) =>
    apolloMutation(UPDATE_INSURANCE_PROVIDER_CONTRACT, employerId, { request }, [
      GET_INSURANCE_PROVIDER_CONTRACT,
      GET_INSURANCE_PROVIDER_CONTRACTS,
    ]);

  return updateInsuranceProviderContract;
};
