import DrawerModal from 'src/components/Common/DrawerModal/DrawerModal';
import React from 'react';
import ReportingEventsOverviewDrawer from './ReportingEventsOverviewDrawer';
import ReportingEventDetailsDrawer from './ReportingEventDetailsDrawer';
import { PersonReportingEventsResponse } from 'src/types/PersonReportingEventsResponse';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';

interface ReportingEventsDrawerProps {
  reportingEventsData: PersonReportingEventsResponse;
  onClose: () => void;
  open: boolean;
  selectedReportingEvent?: ReportingEvent;
  onSelectEvent: (event: ReportingEvent) => void;
  onBackClick: () => void;
}

const ReportingEventsDrawer = ({
  onClose,
  open,
  selectedReportingEvent,
  onSelectEvent,
  onBackClick,
  reportingEventsData,
}: ReportingEventsDrawerProps) => {
  return (
    <DrawerModal open={open} onClose={onClose} overlayBackground overlayOnClose>
      {!selectedReportingEvent && (
        <ReportingEventsOverviewDrawer
          reportingEventsData={reportingEventsData}
          onClose={onClose}
          onSelectEvent={onSelectEvent}
        />
      )}
      {selectedReportingEvent && (
        <ReportingEventDetailsDrawer
          personNumber={reportingEventsData.personNumber}
          employees={reportingEventsData.employees}
          onBackClick={onBackClick}
          reportingEvent={selectedReportingEvent}
          onClose={onClose}
        />
      )}
    </DrawerModal>
  );
};

export default ReportingEventsDrawer;
