import React, { ChangeEvent, useEffect, useState } from 'react';
import { Option, Tab, Tabs } from 'fgirot-k2-ui-components';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { Employer } from 'src/types/employer/Employer';
import { useEmploymentEventCheckpoints } from 'src/service/checkpoint/GetEmploymentEventCheckpoints';
import LoadingPage from 'src/components/Common/LoadingPage';
import ExaminationTable from './ExaminationTable';
import { useTranslation } from 'react-i18next';
import { createOptions } from 'src/util/OptionUtil';
import { unique } from 'src/util/ArrayUtil';
import { filterEmployer, filterFullName, filterSubActionCategories } from 'src/util/EmployeeFilter';
import { resetCacheList } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import FilterComponent from 'src/components/Common/FilterComponent';
import { useEmployersIdLegalName } from 'src/service/employer/GetEmployer';
import './examination.scss';

interface ExaminationProps {
  wageFilePeriod: string;
  customerId: string;
}
const Examination = ({ wageFilePeriod, customerId }: ExaminationProps) => {
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState('');
  const [selectedEmployers, setSelectedEmployers] = useState<Option<Employer>[]>([]);
  const [selectedChanges, setSelectedChanges] = useState<Option<string>[]>([]);

  useEffect(() => {
    resetCacheList(EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE);
  }, []);

  const employers: Employer[] = useEmployersIdLegalName(customerId);
  const employerIds = employers.map((e) => e.id);
  const service = useEmploymentEventCheckpoints(employerIds, wageFilePeriod);
  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>{t('common:error')}</div>;
    case LOADED:
      break;
  }

  const employeeResponses = service.payload;
  const handleNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };
  const employerFilterProps = {
    placeholder: t('employeeFilter:all-employers'),
    options: createOptions(employers, null, null, 'legalName'),
    selected: selectedEmployers,
    onChange: (input: Option<Employer>[]): void => {
      setSelectedEmployers([...input]);
    },
  };

  const subActionCategories = unique(
    employeeResponses.flatMap((response) => response.checkpoints.flatMap((checkpoint) => checkpoint.subActionCategory)),
  );
  const changesFilterProps = {
    placeholder: t('employeeFilter:all-subaction-categories'),
    options: createOptions(subActionCategories, t, 'subActionCategories'),
    selected: selectedChanges,
    onChange: (input: Option<string>[]): void => {
      setSelectedChanges([...input]);
    },
  };
  const filteredEmployees = employeeResponses
    .filter((response) => filterFullName(response.employee, nameFilter))
    .filter((response) => filterEmployer(response.employee, selectedEmployers))
    .filter((response) => filterSubActionCategories(response.checkpoints, selectedChanges));

  return (
    <div className={['examination'].join(' ')}>
      <FilterComponent
        showSearch
        searchPlaceholder={t('searchTerms:search-employee')}
        searchFilter={nameFilter}
        onSearchFilterChange={handleNameFilterChange}
        selectProps={[employerFilterProps, changesFilterProps]}
      />
      <Tabs>
        <Tab title={`${t('examination:examine')} (${employeeResponses.length})`}>
          <ExaminationTable
            employeeResponses={filteredEmployees}
            employers={employers}
            wageFilePeriod={wageFilePeriod}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Examination;
