import { DELETE_DRAFT_EMPLOYEE_INDIVIDUAL_CHOICE } from 'src/graphql/schema/mutation/employee/DeleteDraftEmployeeIndividualChoice';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';

export const useDeleteDraftEmployeeIndividualChoice = () => {
  const apolloMutation = useApolloMutation();

  const deleteDraftEmployeeIndividualChoice = (employeeId: string, individualChoiceId: string, employerId: string) =>
    apolloMutation(
      DELETE_DRAFT_EMPLOYEE_INDIVIDUAL_CHOICE,
      employerId,
      {
        employeeId,
        individualChoiceId,
      },
      [GET_EMPLOYEE_INDIVIDUAL_CHOICES],
    );

  return deleteDraftEmployeeIndividualChoice;
};
