import { Customer } from 'src/types/customer/Customer';
import { getCustomerId } from './GetCustomerId';
import { GET_CUSTOMER } from 'src/graphql/schema/query/user/GetCustomer';
import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';

export const useCustomer = (): Customer => {
  const [result, setResult] = useState<Customer>();
  const customerId = getCustomerId();
  const { loading, error, data } = useQuery(GET_CUSTOMER, { variables: { customerId } });

  useEffect(() => {
    data && setResult(data.customer);
  }, [loading, error, data]);

  return result;
};
