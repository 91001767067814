import { DELETE_DRAFT_POLICY } from 'src/graphql/schema/mutation/policy/DeleteDraftPolicy';
import { GET_POLICY_ENTITIES } from 'src/graphql/schema/query/policy/GetPolicyEntities';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeleteDraftPolicy = () => {
  const apolloMutation = useApolloMutation();

  const deleteDraftPolicy = (policyId: string, employerId: string) =>
    apolloMutation(DELETE_DRAFT_POLICY, employerId, { policyId }, [GET_POLICY_ENTITIES]);

  return deleteDraftPolicy;
};
