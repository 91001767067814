import { Button, Icon } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MappingTab from 'src/components/Account/WageTypeMappingSettings/PayTypeTab/PayTypeDrawer/MappingTab';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';

interface EmployerWageTypeMappingDetailsProps {
  employerId: string;
  selectedPayType: EmployerPayType;
  employerWageTypes: EmployerWageType[];
  payTypeName: string;
  payTypeNumber: string;
  handleBackClick: () => void;
  handleClose: () => void;
}

const EmployerWageTypeMappingDetails = ({
  employerId,
  selectedPayType,
  handleBackClick,
  employerWageTypes,
  payTypeName,
  payTypeNumber,
  handleClose,
}: EmployerWageTypeMappingDetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customer = useCustomer();
  const employerLegalName = useEmployerLegalName(employerId);

  const navigateToEmployerWageMappings = () => {
    navigate(`/account/${encodeURIComponent(customer?.name)}/${employerId}/wage-type-mapping-settings`);
  };

  return (
    <DrawerBase
      leftButton={
        <div className="pay-type-mapping-drawer__buttons">
          <Button
            type="link"
            label={t('validating:wage-type-mapping-drawer.left-button-label')}
            iconPlacement="left"
            icon={<Icon type="Before" className="pay-type-mapping-drawer__button__icon" />}
            onClick={navigateToEmployerWageMappings}
          />
          <Button
            className="wage-type-mapping-drawer__buttons__button"
            size="small"
            type="link"
            label={t('common:back')}
            iconPlacement="left"
            icon={<Icon type="ArrowLeft" />}
            onClick={handleBackClick}
          />
        </div>
      }
      onClose={handleClose}
      title={`${selectedPayType?.number || payTypeNumber} - ${selectedPayType?.name || payTypeName}`}
      subTitle={employerLegalName}
    >
      <MappingTab
        employerId={employerId}
        employerPayType={selectedPayType}
        employerWageTypes={employerWageTypes}
        payTypeName={payTypeName}
        payTypeNumber={payTypeNumber}
        onBackClick={handleBackClick}
      />
    </DrawerBase>
  );
};

export default EmployerWageTypeMappingDetails;
