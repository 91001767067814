import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import TextInput from 'src/components/Common/TextInput';
import { useParams } from 'react-router-dom';
import { AccountingRow } from 'src/types/accountingunit/AccountingRow';
import { useUpdateAccountingRow } from 'src/service/accountingunit/AccountingRow';
import { useTranslation } from 'react-i18next';
interface AccountingRowTableRowProps {
  accountingRow: AccountingRow;
}

const AccountingRowTableRow: FC<AccountingRowTableRowProps> = ({ accountingRow }) => {
  const { employerId } = useParams();
  const { t } = useTranslation();
  const updateAccountingRow = useUpdateAccountingRow();
  const [accountingText, setAccountingText] = useState<string>(accountingRow.accountingText);

  const onAccountingTextChange = (e: ChangeEvent<HTMLInputElement>) => setAccountingText(e.target.value);

  const handleTextInputBlur = () => {
    updateAccountingRow(
      {
        accountingRowId: accountingRow.id,
        accountingText: accountingText,
        accountingTypeValue: undefined,
      },
      employerId,
    );
  };

  return (
    <TableRow>
      <Td>{accountingRow.accountNumber}</Td>
      <Td>
        <TextInput
          data-cy={`AccountingRowNameInput-${accountingRow.accountNumber}`}
          value={accountingText}
          onChange={onAccountingTextChange}
          onBlur={handleTextInputBlur}
          type="text"
          validationKey="text64"
          maxLength={65}
        />
      </Td>

      <Td>
        {accountingRow.accountingType.accountingTypeValue.number}
        {' - '}
        {t(`accountingTypeValue:${accountingRow.accountingType.accountingTypeValue.name}`)}
      </Td>
    </TableRow>
  );
};
export default AccountingRowTableRow;
