import { Button, Icon, Pill, Tab, Tabs } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import InsuranceEventsTab from './InsuranceEventsTab/InsuranceEventsTab';
import './insurance-events-overview-drawer.scss';
import { useState } from 'react';
import { InsuranceEventsOverviewDrawerTab } from 'src/types/tabs/InsuranceEventsOverviewDrawerTab';
import { PersonInsuranceEventsResponse } from 'src/types/reporting/PersonInsuranceEventsResponse';
import { Employer } from 'src/types/employer/Employer';
import { useNavigate } from 'react-router-dom';

interface InsuranceEventsOverviewDrawerProps {
  person: PersonInsuranceEventsResponse;
  onClose: () => void;
  onSelectEvent: (event: InsuranceEvent) => void;
  employers: Employer[];
}

const InsuranceEventsOverviewDrawer = ({
  person,
  onSelectEvent,
  onClose,
  employers,
}: InsuranceEventsOverviewDrawerProps) => {
  const [currentTab, setCurrentTab] = useState<InsuranceEventsOverviewDrawerTab>(
    InsuranceEventsOverviewDrawerTab.INSURANCE_EVENTS,
  );
  const { t } = useTranslation();
  const fullName = person.employees[0].firstName + ' ' + person.employees[0].lastName;
  const insuranceEventsTabTitle = `${t('reporting:insurance-events.events')} (${person.insuranceEvents.length})`;
  const employeeId = person.employees.find((employee) => employee.personNumber === person.personNumber).id;
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(`/employees/${employeeId}`);
  };

  const handleSelectEvent = (event: InsuranceEvent) => {
    onSelectEvent(event);
  };

  return (
    <DrawerBase
      onClose={onClose}
      title={fullName}
      subTitle={
        <div className="insurance-events-overview-drawer__sub-title">
          <Pill label={person.personNumber} />
        </div>
      }
      leftButton={
        employeeId && (
          <Button
            type="link"
            label={t('report:employee-drawer.go-to-profile')}
            iconPlacement="left"
            icon={<Icon type="User" className="employee-drawer__button__icon" />}
            onClick={navigateToProfile}
          />
        )
      }
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={insuranceEventsTabTitle}>
          <InsuranceEventsTab person={person} onSelectEvent={handleSelectEvent} employers={employers} />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default InsuranceEventsOverviewDrawer;
