import React from 'react';

type GuardMethod<T> = (props: T) => boolean;
const withGuard =
  <T extends object>(Component: React.ComponentType<T>, method: GuardMethod<T>): React.FC<T> =>
  (props: T) => {
    if (method(props)) return <Component {...props} />;
  };

export default withGuard;
