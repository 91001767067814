import { Checkbox, TableRow, Td } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { Employee } from 'src/types/Employee';
import { Employer } from 'src/types/employer/Employer';
import TdEmployerName from '../../Common/ReportTable/TdEmployerName';
import TdFullname from '../../Common/ReportTable/TdFullname';
import TdViolationType from '../../Common/ReportTable/TdViolationType';
import { isAllCheckpointsResolved, getCheckpointPillType } from 'src/util/CheckpointUtil';
import { DataValidationCheckpoint } from 'src/types/DataValidationCheckpoint';
import { addToCacheList, removeFromCacheList } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import TdSubActionCategory from '../../Common/ReportTable/TdSubActionCategory/TdSubActionCategory';

interface ValidatingTableRowProps {
  employee: Employee;
  checkpoints: DataValidationCheckpoint[];
  employers: Employer[];
  handleEmployeeDrawer: () => void;
}

const ValidatingTableRow = ({ employee, checkpoints, employers, handleEmployeeDrawer }: ValidatingTableRowProps) => {
  const checkpointsResolved = isAllCheckpointsResolved(checkpoints);
  const [checked, setChecked] = useState(checkpointsResolved);
  const fields = checkpoints.flatMap((checkpoint) => checkpoint.additionalData.fields).filter((field) => field);

  useEffect(
    () =>
      checked
        ? addToCacheList(EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE, {
            employeeId: employee.id,
            employerId: employee.employerId,
          })
        : removeFromCacheList(EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE, employee.id),
    [checked],
  );

  const handleCheckboxClicked = () => {
    setChecked((prev) => !prev);
  };

  return (
    <TableRow onClick={handleEmployeeDrawer}>
      <TdFullname
        alert={!isAllCheckpointsResolved(checkpoints)}
        firstName={employee.firstName}
        lastName={employee.lastName}
        hasDraft={employee.hasDraft}
      />
      <Td>{employee.personNumber}</Td>
      <TdEmployerName employers={employers} employee={employee} />
      <TdViolationType fields={fields} type={getCheckpointPillType(checkpoints)} />
      <TdSubActionCategory checkpoints={checkpoints} />
      <Td notClickable>
        <Checkbox disabled={!checkpointsResolved} checked={checked} onChange={handleCheckboxClicked} />
      </Td>
    </TableRow>
  );
};

export default ValidatingTableRow;
