import { gql } from '@apollo/client';

export const UPSERT_PGL_RULE_WAGE_TYPE = gql`
  mutation UpsertPglRuleWageType($request: PglWageTypeRequest!) {
    upsertPglRuleWageType(pglWageTypeRequest: $request) {
      id
      employerWageTypeId
      conversionFactor
      conversionDenominator
      calculationTimeFrame
      recalculationFromMonth
      applyRetroactivelyFromMonth
    }
  }
`;
