import { Icon, Pill, Tooltip, Typography } from 'fgirot-k2-ui-components';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import './employee-section-label.scss';
import { useTranslation } from 'react-i18next';

interface EmployeeSectionLabelProps {
  label: string;
  period?: string;
  employmentEnded?: boolean;
  hasChangesInDraft?: boolean;
  children?: React.ReactNode;
}

const EmployeeSectionLabel = ({
  label,
  period,
  employmentEnded,
  hasChangesInDraft,
  children,
}: EmployeeSectionLabelProps) => {
  const { t } = useTranslation();
  const periodText = getFormattedDateStringFromPeriod(period);

  return (
    <div className="employee-section-label">
      <Typography variant="subtitle" bold>
        {label}
      </Typography>
      {periodText && <Pill type="success" label={periodText} />}
      {employmentEnded && <Pill label={t('employees:employmentEnded')} type="heads-up" />}
      {hasChangesInDraft && (
        <Tooltip content={t('employees:employee-draft-changed')} direction="bottom">
          <div className="employee-section-label__draft-changed">
            <Icon type="Info" stroke="#a6aebb" className="employee-section-label__icon" />
          </div>
        </Tooltip>
      )}
      {children}
    </div>
  );
};

export default EmployeeSectionLabel;
