import { Button, DrawerNavigation, InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import './groups-drawer-users-tab.scss';
import { useTranslation } from 'react-i18next';
import { GroupUser } from 'src/types/group/Group';
import { useRemoveUserFromGroup } from 'src/service/group/RemoveUserFromGroup';

interface GroupsDrawerUsersTab {
  onAddUserClick: () => void;
  groupId: string;
  users: GroupUser[];
}
const GroupsDrawerUsersTab: React.FunctionComponent<GroupsDrawerUsersTab> = ({ users, onAddUserClick, groupId }) => {
  const { t } = useTranslation();
  const removeUserFromGroup = useRemoveUserFromGroup();
  const removeUser = (userId: string) => {
    removeUserFromGroup(groupId, userId);
  };
  return (
    <>
      <ListGroup variant={'inline-edit'} className="groups-drawer-users-tab">
        <DrawerNavigation
          className="groups-drawer-users-tab__navigation"
          label={t('permission:groups-drawer.add-user-label')}
          onClick={onAddUserClick}
        />
      </ListGroup>
      <ListGroup variant={'inline-edit'}>
        {users.map((user) => (
          <InlineEdit bold key={user.id} label={user.name} alignRight>
            <Button label={t('common:delete')} type="secondary" onClick={() => removeUser(user.id)} />
          </InlineEdit>
        ))}
      </ListGroup>
    </>
  );
};

export default GroupsDrawerUsersTab;
