import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageBase from 'src/components/PageBase/PageBase';
import { Select, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import '../accounting-type.scss';
import { useUpdateAccountingType } from 'src/service/accountingunit/AccountingType';
import { AccountingType } from 'src/types/accountingunit/AccountingType';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { ACCOUNTING_TYPE } from 'src/graphql/schema/query/accountingunit/AccountingType';
import AccountingComponentSection from './AccountingComponentSection';
import { createOptions } from 'src/util/OptionUtil';

export const AccountingTypePage: React.FC = () => {
  const { employerId, accountingTypeId } = useParams();
  const { t } = useTranslation();
  const updateAccountingType = useUpdateAccountingType();
  const navigate = useNavigate();
  const [selectedSource, setSelectedSource] = useState('');

  return (
    <QueryWrapper query={ACCOUNTING_TYPE} options={{ variables: { accountingTypeId } }}>
      {(accountingType: AccountingType) => {
        const options = createOptions(
          accountingType.accountingTypeValue.validAccountingComponentSources,
          t,
          'accountingTypeValue:values',
        );

        const handleSourceChange = (selectedOption) => {
          setSelectedSource(selectedOption.value);
          updateAccountingType(
            {
              accountingTypeId,
              accountingComponentsSource: selectedOption.value,
            },
            employerId,
          );
        };

        const currentSource = selectedSource || accountingType.accountingComponentsSource;
        const selectedOption = options.find((option) => option.value === currentSource);
        return (
          <div className="accounting-type-page__title">
            <PageBase
              title={t(`accountingTypeValue:${accountingType.accountingTypeValue.name}`)}
              handleBackButton={() => navigate(-1)}
            >
              <Select
                label={t('accountingTypeValue:SETTINGS')}
                selected={selectedOption}
                onChange={handleSourceChange}
                options={options}
              />
              {currentSource === 'STATIC' ? (
                <AccountingComponentSection accountingComponents={accountingType.accountingComponents} />
              ) : (
                <Typography>{t(`accountingTypeValue:${currentSource}`)}</Typography>
              )}
            </PageBase>
          </div>
        );
      }}
    </QueryWrapper>
  );
};
export default AccountingTypePage;
