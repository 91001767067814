import { PUBLISH_DRAFT_EMPLOYEE } from 'src/graphql/schema/mutation/employee/PublishDraftEmployee';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';
import { PublishDraftEmployeeResponse } from 'src/types/employees/PublishDraftEmployeeResponse';

export const usePublishDraftEmployee = () => {
  const apolloMutation = useApolloMutation(false);

  const publishDraftEmployee: (employeeId: string, employerId: string) => Promise<PublishDraftEmployeeResponse> = (
    employeeId: string,
    employerId: string,
  ) =>
    apolloMutation(
      PUBLISH_DRAFT_EMPLOYEE,
      employerId,
      {
        employeeId,
      },
      [
        GET_EMPLOYEE,
        GET_LATEST_EMPLOYEE_CARD,
        FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES,
        GET_EMPLOYEE_INDIVIDUAL_CHOICES,
      ],
    );

  return publishDraftEmployee;
};
