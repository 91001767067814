import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageBase from 'src/components/PageBase';
import { useCustomer } from 'src/service/customer/GetCustomer';
import './employees.scss';
import SearchEmployeesTab from 'src/components/Employees/SearchEmployeesTab/SearchEmployeesTab';
import useTabParams from 'src/components/hooks/useTabParams';
import { EmployeesTab } from 'src/types/tabs/EmployeesTab';

const Employees = () => {
  const { t } = useTranslation();
  const customer = useCustomer();
  const [currentTab, setCurrentTab] = useTabParams('tab', EmployeesTab.SEARCH);

  return (
    customer && (
      <PageBase breadCrumbs={[{ name: customer.name, steps: 1 }]} title={t('employees:employees')}>
        <Tabs tabIndex={currentTab} onChange={setCurrentTab} fixed>
          <Tab title={t('employees:search')}>
            <SearchEmployeesTab customerId={customer.id} />
          </Tab>
        </Tabs>
      </PageBase>
    )
  );
};

export default Employees;
