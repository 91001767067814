import { InsuranceProvider } from '../InsuranceProvider';
import { PageableResponse } from '../PageableResponse';

export interface InsuranceTypeMapping {
  id: string;
  name: string;
  invoiceRowMappings: InvoiceRowMapping[];
  insuranceProvider: InsuranceProvider;
  benefitType: string;
  taxRule: TaxRule;
  insuranceCategory: string;
  premiumSource: PremiumSource;
  identifier: InsuranceTypeMappingIdentifier;
}

export interface InvoiceRowMapping {
  id: string;
  invoiceRowName: string;
}

export type InsuranceCategory = 'PERSONAL_INSURANCE' | 'COMPANY_INSURANCE';
export const insuranceCategories: InsuranceCategory[] = ['PERSONAL_INSURANCE', 'COMPANY_INSURANCE'];

export type TaxRule = 'P' | 'K' | 'TFA' | 'TGL';
export type PremiumSource = 'EMPLOYER' | 'SALARY_EXCHANGE' | 'BONUS_EXCHANGE';
export type InsuranceTypeMappingIdentifier = 'COLLECTUM_ITP1' | 'COLLECTUM_ITP2' | 'COLLECTUM_TGL';

export interface InsuranceTypeMappingsPage extends PageableResponse {
  searchInsuranceTypeMappings: InsuranceTypeMapping[];
}
