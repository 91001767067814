import { Button, Icon, InlineEdit, ListGroup, Pill, Select } from 'fgirot-k2-ui-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useEmployerPayTypes } from 'src/service/employer/GetEmployerPayTypes';
import { getFormattedDateStringFromPeriod, numberOfDaysInMonth } from 'src/util/date/PeriodUtil';
import { Option } from 'fgirot-k2-ui-components';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import { stringOrBlank } from 'src/util/StringUtil';
import TextInput from 'src/components/Common/TextInput';
import { CreateDraftEmployeeCardWageRequest } from 'src/types/employees/request/CreateDraftEmployeeCardWageRequest';
import { useCreateDraftEmployeeCardWage } from 'src/service/employeecard/CreateDraftEmployeeCardWage';
import { globalAbsenceWageTypes } from 'src/util/WageUtil';
import InfoMessage from 'src/components/Common/InfoMessage';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';

interface CreateDraftAbsenceWageDrawerProps {
  period: string;
  employerId: string;
  employeeCardId: string;
  onBackClick: () => void;
  onClose: () => void;
}

function CreateDraftAbsenceWageDrawer({
  period,
  employerId,
  onBackClick,
  onClose,
  employeeCardId,
}: CreateDraftAbsenceWageDrawerProps) {
  const [selectedEmployerPayType, setSelectedEmployerPayType] = React.useState<Option<EmployerPayType>>();
  const [extentAdjustedAbsenceDays, setExtentAdjustedAbsenceDays] = React.useState<string>('');
  const [fromDate, setFromDate] = React.useState<string>('');
  const [toDate, setToDate] = React.useState<string>('');
  const [createButtonDisabled, setCreateButtonDisabled] = React.useState(false);

  const { t } = useTranslation();

  const formattedPeriod = getFormattedDateStringFromPeriod(period);

  const employerPayTypes = useEmployerPayTypes(employerId);

  const createDraftEmployeeCardWage = useCreateDraftEmployeeCardWage();

  const absenceEmployerPayTypeOptions: Option<EmployerPayType>[] = employerPayTypes
    .filter((payType) => globalAbsenceWageTypes.includes(payType.employerWageType?.wageType))
    .map((payType) => ({
      label: `${payType.number} - ${payType.name}`,
      value: payType,
    }));

  const handleExtentAdjustedAbsenceDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtentAdjustedAbsenceDays(e.target.value);
  };

  const handleDateChange = (field: string, value: string) => {
    field === 'fromDate' && setFromDate(value);
    field === 'toDate' && setToDate(value);
  };

  const handleCreateWage = async () => {
    setCreateButtonDisabled(true);

    const request: CreateDraftEmployeeCardWageRequest = {
      employeeCardId,
      payTypeNumber: selectedEmployerPayType.value.number,
      extentAdjustedAbsenceDays: Number(extentAdjustedAbsenceDays.replaceAll(',', '.')),
      amount: 0,
    };

    fromDate && (request.fromDate = fromDate);
    toDate && (request.toDate = toDate);

    await createDraftEmployeeCardWage(request, employerId);

    setCreateButtonDisabled(false);
    onBackClick();
  };

  const periodYear = +period.slice(0, 4);
  const periodMonth = +period.slice(4, 6);

  useEffect(() => {
    setCreateButtonDisabled(false);

    if (!selectedEmployerPayType || !extentAdjustedAbsenceDays) setCreateButtonDisabled(true);

    const days = Number(extentAdjustedAbsenceDays.replaceAll(',', '.'));
    isNaN(days) && setCreateButtonDisabled(true);
    if (days > numberOfDaysInMonth(periodYear, periodMonth)) {
      setCreateButtonDisabled(true);
    }

    if (fromDate && toDate) {
      const fromDateYear = +fromDate.slice(0, 4);
      const fromDateMonth = +fromDate.slice(5, 7);
      const toDateYear = +toDate.slice(0, 4);
      const toDateMonth = +toDate.slice(5, 7);

      if (
        fromDateYear !== periodYear ||
        fromDateMonth !== periodMonth ||
        toDateYear !== periodYear ||
        toDateMonth !== periodMonth
      ) {
        setCreateButtonDisabled(true);
      }
    }
  }, [extentAdjustedAbsenceDays, selectedEmployerPayType, fromDate, toDate]);

  return (
    <DrawerBase
      title={t('wages:add-absence-wage')}
      subTitle={<Pill type="success" label={formattedPeriod} />}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="wage-details-drawer">
        <EmployeeInDraftBanner />
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('wages:pay-type')} bold>
            <Select
              options={absenceEmployerPayTypeOptions}
              onChange={(option) => setSelectedEmployerPayType(option)}
              selected={selectedEmployerPayType}
              placeholder={t('wages:select-pay-type')}
              small
              data-cy="pay-type-select"
            />
          </InlineEdit>
          <InlineEdit label={t('wages:wage-type')} bold>
            <TextInput value={stringOrBlank(selectedEmployerPayType?.value?.employerWageType?.name)} disabled />
          </InlineEdit>
          <InlineEdit label={t('wages:from-date')} bold>
            <UpdateDatePicker
              fieldKey="fromDate"
              onBlur={handleDateChange}
              fieldValue={fromDate}
              initialYear={periodYear}
              initialMonth={periodMonth - 1}
              data-cy="fromDate-input"
            />
          </InlineEdit>
          <InlineEdit label={t('wages:to-date')} bold>
            <UpdateDatePicker
              fieldKey="toDate"
              onBlur={handleDateChange}
              fieldValue={toDate}
              initialYear={periodYear}
              initialMonth={periodMonth - 1}
              data-cy="toDate-input"
            />
          </InlineEdit>
          <InlineEdit label={t('wages:extent-adjusted-absence-days')} bold>
            <TextInput
              value={extentAdjustedAbsenceDays}
              onChange={handleExtentAdjustedAbsenceDaysChange}
              validationKey="amount2Dec"
              type="text"
              data-cy="extentAdjustedAbsenceDays-input"
            />
          </InlineEdit>
        </ListGroup>
        <InfoMessage message={t('wages:create-absence-wage-info-message')} />
        <Button
          type="primary"
          label={t('wages:add-absence-wage')}
          onClick={handleCreateWage}
          disabled={createButtonDisabled}
          data-cy="add-wage-button"
        />
      </div>
    </DrawerBase>
  );
}

export default CreateDraftAbsenceWageDrawer;
