import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useYearOptions } from 'src/util/date/Years';
import { Option, Select } from 'fgirot-k2-ui-components';
import { useMonths } from 'src/util/date/Months';
import PeriodChangeButton from 'src/components/Common/PeriodChangeButton';
import './period-select.scss';

interface EmployeeActionsProps {
  period: string;
  employeeId: string;
}

function PeriodSelect({ period, employeeId }: EmployeeActionsProps) {
  const [year, month] = period ? [parseInt(period?.slice(0, 4)), parseInt(period?.slice(4))] : [null, null];
  const navigate = useNavigate();

  const yearsOptions = useYearOptions();
  const monthOptions: Option<number>[] = useMonths().map((month, index) => {
    return { label: month, value: index + 1 };
  });

  const changeYear = (newYear: string) =>
    navigate(`/employees/${employeeId}/${newYear}${month.toString().padStart(2, '0')}`);
  const changeMonth = (newMonth: string) =>
    navigate(`/employees/${employeeId}/${year}${newMonth.toString().padStart(2, '0')}`);

  const handleChangePeriod = (newPeriod: string) => {
    navigate(`/employees/${employeeId}/${newPeriod}`);
  };
  return (
    <section className="period-select">
      <PeriodChangeButton type="previous" currentPeriod={period} onChangePeriod={handleChangePeriod} />
      <Select
        options={monthOptions}
        alphabetical={false}
        onChange={(opt) => changeMonth(opt.value)}
        selected={monthOptions.find((opt) => opt.value === month)}
        data-cy="employee-overview__month"
      />
      <Select
        options={yearsOptions}
        onChange={(opt) => changeYear(opt.value)}
        selected={yearsOptions.find((opt) => opt.value === year)}
        data-cy="employee-overview__year"
      />
      <PeriodChangeButton type="next" currentPeriod={period} onChangePeriod={handleChangePeriod} />
    </section>
  );
}

export default PeriodSelect;
