import { gql } from '@apollo/client';

export const GET_INSURANCE_PROVIDER = gql`
  query GetInsuranceProvider($insuranceProviderId: UUID!) {
    insuranceProvider(insuranceProviderId: $insuranceProviderId) {
      id
      legalName
      organisationNumber
      insuranceProductConditions {
        id
        name
        description
        version
        documentUrl
      }
    }
  }
`;
