export const hasAmountChanged = (amount: number, newAmount: string) => {
  if (amount?.toString().replaceAll('.', ',') !== newAmount && !isNaN(Number(newAmount.replaceAll(',', '.')))) {
    return true;
  } else {
    return false;
  }
};

export const hasQuantityValueChanged = (value: string, newValue: string) => {
  if (value !== newValue) {
    return true;
  } else {
    return false;
  }
};
