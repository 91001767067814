import { Button, ButtonProps, Icon } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import './floating-add-button.scss';

const FloatingAddButton: FC<ButtonProps> = (props) => {
  return (
    <div className="floating-add-button">
      <Button type="floating-action-button" icon={<Icon type={'PlusLarge'} />} {...props} />
    </div>
  );
};

export default FloatingAddButton;
