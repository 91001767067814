import { useTranslation } from 'react-i18next';
import { Option } from 'fgirot-k2-ui-components';
import { convertMonthEnumToNumber, convertMonthNumberToEnum, useMonths } from 'src/util/date/Months';
import { createOptions } from 'src/util/OptionUtil';
import { startDateBases, StartDateBasis } from 'src/types/policy/PglBonusComponent';
import { useMemo } from 'react';
import { Month } from 'src/types/Month';

export const useBonusCalculationOptions = (numberOfYearsOfHistory: number, recalculationFromMonth: Month) => {
  const { t } = useTranslation();

  const numberOfYearsOptions = useMemo(() => {
    const options: Option<number>[] = [];
    for (let i = 1; i <= 5; i++) {
      if (numberOfYearsOfHistory !== null && i > numberOfYearsOfHistory) break;
      options.push({ value: i, label: `${i} ${t('account:pgl-rules:drawer:bonus:number-of-years:prefix')}` });
    }
    return options;
  }, [numberOfYearsOfHistory]);

  const generateNumberOfYearsOfHistoryOptions = () => {
    const options: Option<number>[] = [
      { label: t('account:pgl-rules:drawer:bonus:number-of-years-of-history:all'), value: null },
    ];
    for (let i = 1; i <= 10; i++) {
      options.push({
        value: i,
        label: `${i} ${t('account:pgl-rules:drawer:bonus:number-of-years-of-history:prefix')}`,
      });
    }
    return options;
  };

  const monthOptions = useMonths().map((month, index) => {
    return { label: month, value: convertMonthNumberToEnum(index + 1) };
  });

  const recalculationFromMonthOptions = [{ label: t('common:not-in-use'), value: null }, ...monthOptions];

  const recalculationToMonthOptions = useMemo(() => {
    return [
      { label: t('common:not-in-use'), value: null },
      ...monthOptions.filter((option) => {
        if (!recalculationFromMonth) {
          return;
        }
        const selectedRecalculationFromMonthNumber = convertMonthEnumToNumber(recalculationFromMonth);
        const optionMonthNumber = convertMonthEnumToNumber(option.value);
        return optionMonthNumber > selectedRecalculationFromMonthNumber;
      }),
    ] as Option<string>[];
  }, [recalculationFromMonth]);

  const applyRetroactivelyFromMonthOptions = useMemo(() => {
    return [
      { label: t('common:not-in-use'), value: null },
      ...monthOptions.filter((option) => {
        if (!recalculationFromMonth) {
          return;
        }
        const selectedRecalculationFromMonthNumber = convertMonthEnumToNumber(recalculationFromMonth);
        const optionMonthNumber = convertMonthEnumToNumber(option.value);
        return optionMonthNumber < selectedRecalculationFromMonthNumber;
      }),
    ] as Option<string>[];
  }, [recalculationFromMonth]);

  const startDateBasisOptions: Option<StartDateBasis>[] = createOptions(startDateBases, t, 'startDateBasis');

  return {
    numberOfYearsOptions,
    recalculationFromMonthOptions,
    recalculationToMonthOptions,
    applyRetroactivelyFromMonthOptions,
    startDateBasisOptions,
    numberOfYearsOfHistoryOptions: generateNumberOfYearsOfHistoryOptions(),
  };
};
