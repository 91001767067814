import { gql } from '@apollo/client';

export const REMOVE_EMPLOYMENT_GROUP_CONNECTIONS = gql`
  mutation RemoveEmploymentGroupConnections($benefitPackageId: UUID!, $policyEmploymentGroupIds: [UUID]!) {
    removeEmploymentGroupConnections(
      benefitPackageId: $benefitPackageId
      policyEmploymentGroupIds: $policyEmploymentGroupIds
    ) {
      id
    }
  }
`;
