import { TableRow, Td, Pill, Tooltip } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { examinationPillOutcome } from 'src/util/CheckpointUtil';
import { groupBySubActionCategory, getUsedProviders } from '../utils';

interface CheckpointTableRowProps {
  eventReport: InsuranceEventReportEmployeeResponse;
  onClick: () => void;
  providers: InsuranceProvider[];
}

const CheckpointTableRow: React.FC<CheckpointTableRowProps> = ({ eventReport, onClick, providers }) => {
  const { t } = useTranslation();
  const groupedData = groupBySubActionCategory(eventReport);

  return (
    <>
      {Array.from(groupedData.keys()).map((value) => {
        const checkPoints = groupedData.get(value);
        const checkPointId = checkPoints.at(0).id;

        const usedProviders: InsuranceProvider[] = getUsedProviders(checkPoints, providers);

        return (
          <TableRow onClick={onClick} key={checkPointId}>
            <Td compressed>
              {checkPoints && checkPoints.length && (
                <Tooltip content={checkPoints.map((f) => t(`insuranceEventTypes:${f.insuranceEventType}`)).join(', ')}>
                  <Pill
                    key={`insurance-event-type-pill-${checkPointId}`}
                    type={examinationPillOutcome(checkPoints)}
                    label={`${t(`insuranceEventTypes:${checkPoints[0].insuranceEventType}`)}${
                      checkPoints.length > 1 ? ` + ${checkPoints.length - 1}` : ''
                    }`}
                  />
                </Tooltip>
              )}
            </Td>
            <Td compressed>{t(`subActionCategories:${checkPoints.at(0).subActionCategory}`)}</Td>
            <Td compressed>
              {usedProviders.map((provider) => (
                <Pill
                  key={`provider-pill-${provider.id}-${checkPointId}`}
                  type={'neutral'}
                  label={provider.legalName}
                />
              ))}
            </Td>
          </TableRow>
        );
      })}
    </>
  );
};

export default CheckpointTableRow;
