import { useApolloMutation } from '../graphql/Mutation';
import { DELETE_EMPLOYEE_COMMENT } from 'src/graphql/schema/mutation/employee/DeleteEmployeeComment';
import { GET_EMPLOYEE_COMMENTS } from 'src/graphql/schema/query/employee/GetEmployeeComments';

export const useDeleteEmployeeComment = () => {
  const apolloMutation = useApolloMutation();

  const deleteEmployeeComment = (employeeId: string, commentId: string, employerId: string) =>
    apolloMutation(
      DELETE_EMPLOYEE_COMMENT,
      employerId,
      {
        employeeId,
        commentId,
      },
      [GET_EMPLOYEE_COMMENTS],
    );

  return deleteEmployeeComment;
};
