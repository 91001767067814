import { Button, Icon } from 'fgirot-k2-ui-components';
import React from 'react';
import { Wage } from 'src/types/EmployeeCard';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useTranslation } from 'react-i18next';
import WageDetails from './AbsenceWageDetails/AbsenceWageDetails';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import './absence-wage-details-drawer.scss';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import { formatPayType } from 'src/util/WageUtil';

interface AbsenceWageDetailsDrawerProps {
  wage: Wage;
  wageType: AggregatedWageTypeWage;
  onBackClick: (wageTypeId: string) => void;
  onClose: () => void;
  employeeCardId: string;
  employerId: string;
  isDraft: boolean;
  period: string;
}

function AbsenceWageDetailsDrawer({
  wage,
  wageType,
  onBackClick,
  onClose,
  employeeCardId,
  employerId,
  isDraft,
  period,
}: AbsenceWageDetailsDrawerProps) {
  const { t } = useTranslation();
  const formattedPeriod = getFormattedDateStringFromPeriod(period);
  const employerWageTypes = useEmployerWageTypes(employerId);

  const handleBackClick = () => {
    onBackClick(wageType.employerWageTypeId);
  };
  return (
    <DrawerBase
      title={formatPayType(wage.payTypeNumber, wage.payTypeName, t)}
      subTitle={formattedPeriod}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={handleBackClick} />
      }
      onClose={onClose}
    >
      <div className="absence-wage-details-drawer">
        {isDraft && <EmployeeInDraftBanner />}
        <WageDetails
          period={period}
          wage={wage}
          employeeCardId={employeeCardId}
          isDraft={isDraft}
          employerId={employerId}
          onBackClick={handleBackClick}
          employerWageTypes={employerWageTypes}
        />
      </div>
    </DrawerBase>
  );
}

export default AbsenceWageDetailsDrawer;
