import { Button, Card, CardAction, Modal, Switch, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextInput from 'src/components/Common/TextInput/TextInput';
import { useCreateAccrualRule } from 'src/service/accountingunit/AccrualRule';
import { CreateAccrualRuleInput } from 'src/types/accountingunit/AccrualRule';

interface AddAccrualRuleModalProps {
  open: boolean;
  onClose: () => void;
}
const AddAccrualRuleModal: FC<AddAccrualRuleModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const createAccrualRule = useCreateAccrualRule();
  const [createAccrualRuleInput, setCreateAccrualRuleInput] = useState<CreateAccrualRuleInput>({
    threshold: 0,
    enabled: false,
    salaryTaxEnabled: false,
  });

  const handleThresholdChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCreateAccrualRuleInput((prev) => ({ ...prev, threshold: e.target.valueAsNumber }));

  const handleCreateAccrualRule = () => createAccrualRule(createAccrualRuleInput, employerId);
  const toggleAccrualRuleEabled = () => setCreateAccrualRuleInput((prev) => ({ ...prev, enabled: !prev.enabled }));
  const toggleSalaryTaxEnabled = () =>
    setCreateAccrualRuleInput((prev) => ({ ...prev, salaryTaxEnabled: !prev.salaryTaxEnabled }));

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        title={t('economySettings:accounting-settings-tab.accrual-rule-cards.modal.title')}
        defaultClose
        onClose={onClose}
      >
        <TextInput
          type="number"
          validationKey="amount"
          variant="default"
          label={t('economySettings:accounting-settings-tab.accrual-rule-cards.threshold-label')}
          value={createAccrualRuleInput.threshold.toString()}
          onChange={handleThresholdChange}
        />
        <div className="accounting-settings-tab__card-content-row">
          <Typography variant="caption" className="accounting-settings-tab__card-content-label">
            {t('economySettings:accounting-settings-tab.accrual-rule-cards.use-accrual')}
          </Typography>
          <Switch toggled={createAccrualRuleInput.enabled} onChange={toggleAccrualRuleEabled} />
        </div>
        <div className="accounting-settings-tab__card-content-row">
          <Typography variant="caption" className="accounting-settings-tab__card-content-label">
            {t('economySettings:accounting-settings-tab.accrual-rule-cards.use-accrual-salary-tax')}
          </Typography>
          <Switch toggled={createAccrualRuleInput.salaryTaxEnabled} onChange={toggleSalaryTaxEnabled} />
        </div>
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={onClose}
            data-cy="create-policy-modal__cancel-button"
          />
          <Button
            label={t('common:add')}
            onClick={handleCreateAccrualRule}
            data-cy="create-policy-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};
export default AddAccrualRuleModal;
