import { gql } from '@apollo/client';

export const GET_QUALIFIED_CALCULATIONS = gql`
  query GetQualifiedCalculations($employeeCardId: UUID!) {
    qualifiedCalculations(employeeCardId: $employeeCardId) {
      employeeCardId
      evaluationPolicyEntityId
      kubOutcomes {
        id
        kubSum
        kubType
      }
      pglOutcomes {
        id
        ruleId
        pglSum
        name
        pglType
      }
    }
  }
`;
