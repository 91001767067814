import { Button, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingAdjustmentCondition } from 'src/types/accountingunit/AccountingAdjustmentCondition';
import { accountingAdjustmentConditionTypes } from 'src/types/accountingunit/AccountingAdjustmentConditionTypes';
import ConditionSelects from './ConditionSelects';
import ScrollBase from 'src/components/Common/ScrollBase';

interface ConditionSectionProps {
  accountingAdjustmentConditions: AccountingAdjustmentCondition[];
  createAccountingAdjustmentCondition: () => void;
  updateAccountingAdjustmentCondition: (accountingAdjustmentCondition: AccountingAdjustmentCondition) => void;
  deleteAccountingAdjustmentCondition: (id: string) => void;
}

const ConditionSection: FC<ConditionSectionProps> = ({
  accountingAdjustmentConditions,
  createAccountingAdjustmentCondition,
  deleteAccountingAdjustmentCondition,
  updateAccountingAdjustmentCondition,
}) => {
  const { t } = useTranslation();
  const conditionTypeOptions = accountingAdjustmentConditionTypes.map((type) => ({
    label: t(`economySettings:accounting-adjustment-condition-type.${type}`),
    value: type,
  }));

  return (
    <>
      <Typography variant="h3">{t('economySettings:accounting-adjustment-tab.modal.condition-title')}</Typography>

      <ScrollBase maxHeightPercentage={20}>
        {accountingAdjustmentConditions.map((condition) => (
          <ConditionSelects
            key={condition.id}
            accountindAdjustmentCondition={condition}
            updateAccountingAdjustmentCondition={updateAccountingAdjustmentCondition}
            deleteAccountingAdjustmentCondition={deleteAccountingAdjustmentCondition}
            conditionTypeOptions={conditionTypeOptions}
          />
        ))}
      </ScrollBase>
      <Button
        data-cy="add-condition-button"
        label={t('economySettings:accounting-adjustment-tab.modal.add-condition')}
        onClick={createAccountingAdjustmentCondition}
      />
    </>
  );
};

export default ConditionSection;
