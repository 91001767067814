import { useSubscription } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { EMPLOYEE_UPDATED } from 'src/graphql/schema/subscription/EmployeeUpdated';
import { ReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { Service } from 'src/types/Service';
import { LOADED } from 'src/types/Status';
import { handleEmployeeUpdate } from './HandleEmployeeUpdate';

export const useEmployeeSubscription = <T extends ReportEmployeeResponse>(
  employerIds: string[],
  wageFilePeriod: string,
  setResult: Dispatch<SetStateAction<Service<T[]>>>,
) => {
  const { data } = useSubscription(EMPLOYEE_UPDATED, {
    variables: { employerIds, wageFilePeriod },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data !== undefined) {
      setResult((prev) => {
        return prev.status === LOADED
          ? { ...prev, payload: handleEmployeeUpdate(prev.payload, data.employeeUpdated) }
          : prev;
      });
    }
  }, [data]);
};
