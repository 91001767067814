import { Modal, Card, Select, CardAction, Button, Option } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateBalancingOfBooksAdjustment } from 'src/service/accountingunit/BalancingOfBooksAdjustment';
import { Month } from 'src/types/Month';
import { CreateBalancingOfBooksAdjustmentInput } from 'src/types/accountingunit/BalancingOfBooksAdjustment';
import { convertMonthEnumToNumber, useMonthOptions } from 'src/util/date/Months';
import { getMonthDayOptions } from 'src/util/date/PeriodUtil';

interface AddBalancingOfBooksAdjustmentModalProps {
  open: boolean;
  onClose: () => void;
  balancingOfBooksSettingsId: string;
}

const AddBalancingOfBooksAdjustmentModal: FC<AddBalancingOfBooksAdjustmentModalProps> = ({
  open,
  onClose,
  balancingOfBooksSettingsId,
}) => {
  const { employerId } = useParams();
  const createBalancingOfBooksAdjustment = useCreateBalancingOfBooksAdjustment();
  const [createBalancingOfBooksAdjustmentInput, setCreateBalancingOfBooksAdjustmentInput] =
    useState<CreateBalancingOfBooksAdjustmentInput>({
      balancingOfBooksSettingsId,
      month: 'JANUARY',
      day: 1,
    });
  const handleModalClose = () => {
    setCreateBalancingOfBooksAdjustmentInput({
      balancingOfBooksSettingsId,
      month: 'JANUARY',
      day: 1,
    });
    onClose();
  };

  const monthOptions = useMonthOptions();
  const monthDayOptions = getMonthDayOptions(convertMonthEnumToNumber(createBalancingOfBooksAdjustmentInput.month));
  const handleMonthChange = (option: Option<Month>) => {
    setCreateBalancingOfBooksAdjustmentInput((prev) => ({ ...prev, month: option.value, day: 1 }));
  };
  const handleDayChange = (option: Option<string>) => {
    setCreateBalancingOfBooksAdjustmentInput((prev) => ({ ...prev, day: parseInt(option.value) }));
  };

  const handleCreateBalancingOfBookAdjustment = () => {
    createBalancingOfBooksAdjustment(createBalancingOfBooksAdjustmentInput, employerId).then(handleModalClose);
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleModalClose}>
      <Card
        title={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.title')}
        defaultClose
        onClose={handleModalClose}
      >
        <Select
          data-cy="adjustment-month-select"
          options={monthOptions}
          alphabetical={false}
          selected={monthOptions.find((option) => option.value === createBalancingOfBooksAdjustmentInput.month)}
          onChange={handleMonthChange}
        />
        <Select
          data-cy="adjustment-day-select"
          options={monthDayOptions}
          selected={monthDayOptions.find(
            (option) => parseInt(option.value) === createBalancingOfBooksAdjustmentInput.day,
          )}
          onChange={handleDayChange}
        />
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleModalClose} />
          <Button
            label={t('common:add')}
            onClick={handleCreateBalancingOfBookAdjustment}
            data-cy="create-adjustment-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddBalancingOfBooksAdjustmentModal;
