import { client } from 'src/graphql';
import { GET_INSURANCE_PRODUCT_CONDITION } from 'src/graphql/schema/query/insurance/GetInsuranceProductCondition';
import { InsuranceProductCondition } from 'src/types/InsuranceProductCondition';

export const fetchInsuranceProductCondition = async (conditionId: string): Promise<InsuranceProductCondition> => {
  const {
    data: { insuranceProviderProductCondition },
  } = await client.query({ query: GET_INSURANCE_PRODUCT_CONDITION, variables: { conditionId } });

  return insuranceProviderProductCondition;
};
