import { gql } from '@apollo/client';

export const GET_INVOICE = gql`
  query GetInvoice($invoiceId: UUID!, $pageNumber: Int, $pageSize: Int) {
    invoice(invoiceId: $invoiceId, pageNumber: $pageNumber, pageSize: $pageSize) {
      paging {
        totalNumberOfElements
        hasNext
      }
      id
      errandId
      createdAt
      updatedAt
      sequenceNumber
      invoiceNumber
      fileName
      invoiceStatus
      invoiceTypeCode
      invoiceIssuedCreationDate
      arrivalDate
      convertedDate
      hasPaperInvoice
      agreementNumber
      note
      dueDate
      paymentDueDate
      paymentMethod
      ocrNumber
      accountNumberTo
      currencyCode
      totalAmount
      roundingAmount
      vatAmount
      remainingAmount
      invoicePeriod {
        periodStart
        periodEnd
      }
      employer {
        legalName
        id
      }
      insuranceProvider {
        id
        legalName
        organisationNumber
      }
    }
  }
`;
