import { Button, Icon, InlineEdit, ListGroup, Message, Switch, Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import TextInput from 'src/components/Common/TextInput';
import { useUpdateReportingScheduledJob } from 'src/service/reporting/UpdateReportingScheduledJob';
import { Employer } from 'src/types/employer/Employer';
import { ReportingScheduledJob } from 'src/types/reporting/ReportingScheduledJob';
import { UpdateReportingScheduledJobRequest } from 'src/types/reporting/UpdateReportingScheduledJobRequest';
import { ReportingScheduledJobsDetailsDrawerTab } from 'src/types/tabs/ReportingScheduledJobsDetailsDrawer';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import './reporting-scheduled-jobs-details-drawer.scss';

interface ReportingScheduledJobsDetailsDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  reportingScheduledJob: ReportingScheduledJob;
  employer: Employer;
}

function ReportingScheduledJobsDetailsDrawer({
  onClose,
  onBackClick,
  reportingScheduledJob,
  employer,
}: ReportingScheduledJobsDetailsDrawerProps) {
  const [isLoading, setIsLoading] = useState(false);
  const updateReportingScheduledJob = useUpdateReportingScheduledJob();
  const [currentTab, setCurrentTab] = useState<ReportingScheduledJobsDetailsDrawerTab>(
    ReportingScheduledJobsDetailsDrawerTab.ABOUT,
  );
  const [reportingSchedulingJobRequest, setReportingScheduledJobRequest] = useState<UpdateReportingScheduledJobRequest>(
    {
      jobId: reportingScheduledJob.id,
      dayOfMonth: reportingScheduledJob.dayOfMonth,
      time: reportingScheduledJob.time,
      isLastDayOfMonth: reportingScheduledJob.isLastDayOfMonth,
    },
  );

  const { t } = useTranslation();

  const handleIsLastDayOfMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportingScheduledJobRequest({
      ...reportingSchedulingJobRequest,
      isLastDayOfMonth: e.target.checked,
    });
  };

  const handleDayOfMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = +e.target.value;

    if (e.target.value === '') {
      newValue = null;
    }

    setReportingScheduledJobRequest({
      ...reportingSchedulingJobRequest,
      dayOfMonth: newValue,
    });
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportingScheduledJobRequest({
      ...reportingSchedulingJobRequest,
      time: e.target.value,
    });
  };

  const handleSaveChanges = () => {
    setIsLoading(true);
    updateReportingScheduledJob(employer.id, reportingSchedulingJobRequest).then(() => {
      setIsLoading(false);
    });
  };

  const requestIsValid = () => {
    let isValid = true;

    const dayOfMonthValidationResult = inputSchema.validate(
      { ['dayOfMonthMax28OrNull']: reportingSchedulingJobRequest.dayOfMonth },
      { abortEarly: false },
    );

    if (dayOfMonthValidationResult.error) {
      isValid = false;
    }

    const hourValidationResult = inputSchema.validate(
      { ['hourWithMinutes']: reportingSchedulingJobRequest.time },
      { abortEarly: false },
    );

    if (hourValidationResult.error) {
      isValid = false;
    }

    // If day of month is set, isLastDayOfMonth should be false
    if (reportingSchedulingJobRequest.dayOfMonth && reportingSchedulingJobRequest.isLastDayOfMonth) {
      isValid = false;
    }

    // If last day of month is false there has to be a day of month
    if (!reportingSchedulingJobRequest.isLastDayOfMonth && !reportingSchedulingJobRequest.dayOfMonth) {
      isValid = false;
    }

    return isValid;
  };

  const requestHasChanged = () => {
    return (
      reportingSchedulingJobRequest.dayOfMonth !== reportingScheduledJob.dayOfMonth ||
      reportingSchedulingJobRequest.time !== reportingScheduledJob.time ||
      reportingSchedulingJobRequest.isLastDayOfMonth !== reportingScheduledJob.isLastDayOfMonth
    );
  };

  return (
    <DrawerBase
      title={reportingScheduledJob.insuranceProvider?.legalName}
      subTitle={t('reportingSettings:reporting-scheduled-jobs.scheduling')}
      onClose={onClose}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('common:about')}>
          <div className="reporting-scheduled-jobs-details-drawer">
            <ListGroup variant="inline-edit">
              <InlineEdit label={t('reportingSettings:reporting-scheduled-jobs.last-day-of-month')} bold>
                <Switch
                  data-cy="reporting-scheduled-jobs-details-drawer__last-day-of-month-switch"
                  toggled={reportingSchedulingJobRequest.isLastDayOfMonth}
                  onChange={handleIsLastDayOfMonthChange}
                />
              </InlineEdit>
              <InlineEdit label={t('reportingSettings:reporting-scheduled-jobs.day-of-month')} bold>
                <TextInput
                  data-cy="reporting-scheduled-jobs-details-drawer__day-of-month-input"
                  type="number"
                  value={
                    reportingSchedulingJobRequest.dayOfMonth ? reportingSchedulingJobRequest.dayOfMonth.toString() : ''
                  }
                  onChange={handleDayOfMonthChange}
                  validationKey="dayOfMonthMax28OrNull"
                />
              </InlineEdit>
              <InlineEdit label={t('reportingSettings:reporting-scheduled-jobs.hour')} bold>
                <TextInput
                  data-cy="reporting-scheduled-jobs-details-drawer__time-input"
                  value={reportingSchedulingJobRequest.time}
                  onChange={handleTimeChange}
                  validationKey="hourWithMinutes"
                />
              </InlineEdit>
            </ListGroup>
            <Message type="info" message={t('reportingSettings:reporting-scheduled-jobs.scheduling-info-message')} />
            <Button
              data-cy="reporting-scheduled-jobs-details-drawer__save-changes-button"
              type="primary"
              label={t('common:save-changes')}
              onClick={handleSaveChanges}
              disabled={!requestIsValid() || !requestHasChanged() || isLoading}
            />
          </div>
        </Tab>
      </Tabs>
    </DrawerBase>
  );
}

export default ReportingScheduledJobsDetailsDrawer;
