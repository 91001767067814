import { GET_PGL_RULES } from 'src/graphql/schema/query/policy/GetPglRules';
import { PglRule } from 'src/types/policy/PglRule';
import { CREATE_PGL_RULE } from 'src/graphql/schema/mutation/policy/CreatePglRule';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreatePglRule = () => {
  const apolloMutation = useApolloMutation();

  const createPglRule = (employerId: string, policyId: string, name: string): Promise<PglRule> =>
    apolloMutation(
      CREATE_PGL_RULE,
      employerId,
      {
        policyId,
        name,
      },
      [GET_PGL_RULES],
    );

  return createPglRule;
};
