import { ListGroup } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty/ListProperty';
import { EmployeeCardBenefit } from 'src/types/employees/EmployeeCardBenefit';

interface BenefitsDetailsDrawerProps {
  benefit: EmployeeCardBenefit;
}

const BenefitDetailsAboutTab = ({ benefit }: BenefitsDetailsDrawerProps) => {
  const { t } = useTranslation();

  return (
    <ListGroup variant="inline-edit">
      <ListProperty label={t('benefits:benefits-drawer.name')} value={benefit.name} />
      <ListProperty
        label={t('benefits:benefits-drawer.isEntitled.title')}
        value={benefit.isEntitled ? t('common:yes') : t('common:no')}
      />
      <ListProperty
        label={t('benefits:benefits-drawer.benefit-type')}
        value={t(`benefitTypes:${benefit.benefitType}`)}
      />
      <ListProperty
        label={t('benefits:benefits-drawer.optional.title')}
        value={
          benefit.optional ? t('benefits:benefits-drawer.optional.true') : t('benefits:benefits-drawer.optional.false')
        }
      />
    </ListGroup>
  );
};

export default BenefitDetailsAboutTab;
