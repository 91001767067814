import { UPDATE_EMPLOYEE_CARD } from 'src/graphql/schema/mutation/employeecard/UpdateEmployeeCard';
import { UpdateEmployeeCard } from 'src/types/UpdateEmployeeCard';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetEmployeeCard';

export const useUpdateEmployeeCard = () => {
  const apolloMutation = useApolloMutation();

  return (request: UpdateEmployeeCard, employerId: string) =>
    apolloMutation(UPDATE_EMPLOYEE_CARD, employerId, { request }, [GET_EMPLOYEE_CARD]);
};
