import { DELETE_BENEFIT_AGREED_PRODUCT } from 'src/graphql/schema/mutation/policy/DeleteBenefitAgreedProduct';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { BenefitAgreedProductRequest } from 'src/types/policy/request/BenefitAgreedProductRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeleteBenefitAgreedProduct = () => {
  const apolloMutation = useApolloMutation();

  const deleteBenefitAgreedProduct = (request: BenefitAgreedProductRequest, employerId: string) =>
    apolloMutation(DELETE_BENEFIT_AGREED_PRODUCT, employerId, { request }, [GET_POLICY]);

  return deleteBenefitAgreedProduct;
};
