import { gql } from '@apollo/client';

export const PUBLISH_DRAFT_EMPLOYEE = gql`
  mutation PublishDraftEmployee($employeeId: UUID!) {
    publishDraftEmployee(employeeId: $employeeId) {
      employeeId
      success
      checkpointErrors {
        period
        resultingCheckpoints {
          actionCategory
          subActionCategory
        }
      }
    }
  }
`;
