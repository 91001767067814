import { Button, Icon, TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDeleteAccountingAdjustmentRule } from 'src/service/accountingunit/AccountingAdjustmentRule';
import { AccountingAdjustmentAction } from 'src/types/accountingunit/AccountingAdjustmentAction';
import { AccountingAdjustmentCondition } from 'src/types/accountingunit/AccountingAdjustmentCondition';
import { AccountingAdjustmentRule } from 'src/types/accountingunit/AccountingAdjustmentRule';
interface AccountingAdjustmentTableRowProps {
  accountingAdjustmentRule: AccountingAdjustmentRule;
}

const AccountingAdjustmentTableRow: FC<AccountingAdjustmentTableRowProps> = ({ accountingAdjustmentRule }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const deleteAccountingAdjustmentRule = useDeleteAccountingAdjustmentRule();
  const handleDeleteAccountingAdjustmentRule = () => {
    deleteAccountingAdjustmentRule(accountingAdjustmentRule.id, employerId);
  };

  const getAccountingComponentTypeTranslation = (typeName: string): string => {
    if (typeName === 'Cost Centre') {
      return t(`economySettings:accounting-component-types.Cost_Centre`);
    } else if (typeName === 'Cost Object') {
      return t(`economySettings:accounting-component-types.Cost_Object`);
    }
    return typeName;
  };

  const renderConditon = (condition: AccountingAdjustmentCondition): string => {
    const translatedTypeName = getAccountingComponentTypeTranslation(condition.reference.typeName);
    return `${t(
      `economySettings:accounting-adjustment-condition-type.${condition.accountingAdjustmentConditionType}`,
    )}${
      condition.reference.typeName !== null
        ? ` (${translatedTypeName}): ${condition.reference.name}`
        : `: ${condition.reference.name} `
    }`;
  };

  const renderAction = (action: AccountingAdjustmentAction): string => {
    const translatedTypeName = getAccountingComponentTypeTranslation(action.reference.typeName);
    return `${t(
      `economySettings:accounting-adjustment-action-type.${accountingAdjustmentRule.accountingAdjustmentAction.accountingAdjustmentActionType}`,
    )}${
      accountingAdjustmentRule.accountingAdjustmentAction.reference.typeName !== null
        ? ` (${translatedTypeName}): ${accountingAdjustmentRule.accountingAdjustmentAction.reference.name}`
        : `: ${accountingAdjustmentRule.accountingAdjustmentAction.reference.name}`
    }`;
  };

  return (
    <TableRow>
      <Td>
        {accountingAdjustmentRule.accountingAdjustmentConditions
          .map((condition) => renderConditon(condition))
          .join(', ')}
      </Td>
      <Td>{renderAction(accountingAdjustmentRule.accountingAdjustmentAction)}</Td>
      <Td>
        <Button
          data-cy="delete-accountingAdjustment-rule-button"
          type="secondary"
          icon={<Icon type="Trash" />}
          onClick={handleDeleteAccountingAdjustmentRule}
        />
      </Td>
    </TableRow>
  );
};

export default AccountingAdjustmentTableRow;
