import React, { useState } from 'react';
import WageTypeTable from './WageTypeTable';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYER_WAGE_TYPES } from 'src/graphql/schema/query/employer/GetEmployerWageTypes';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import CreateWageTypeModal from './CreateWageTypeModal';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import { useTranslation } from 'react-i18next';
import WageTypeDrawer from './WageTypeDrawer';

interface WageTypeTabProps {
  employerId: string;
}

function WageTypeTab({ employerId }: WageTypeTabProps) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWageTypeId, setSelectedWageTypeId] = useState<string>();

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSelectWageType = (id: string) => {
    setSelectedWageTypeId(id);
  };

  const handleCloseDrawer = () => {
    setSelectedWageTypeId(undefined);
  };

  return (
    <div>
      <QueryWrapper query={GET_EMPLOYER_WAGE_TYPES} options={{ variables: { employerId } }}>
        {(employerWageTypes: EmployerWageType[]) => (
          <>
            <WageTypeTable employerWageTypes={employerWageTypes} onSelectWageType={handleSelectWageType} />
            {modalOpen && <CreateWageTypeModal open={modalOpen} employerId={employerId} onClose={handleCloseModal} />}
            {selectedWageTypeId && (
              <WageTypeDrawer
                open={true}
                employerWageType={employerWageTypes.find(
                  (employerWageType) => employerWageType.id === selectedWageTypeId,
                )}
                onClose={handleCloseDrawer}
                employerId={employerId}
              />
            )}
            <FloatingAddButton
              data-cy="add-wage-type-button"
              label={t('account:employers-tab.wage-type-mapping-settings.add-wage-type')}
              onClick={() => {
                setModalOpen(true);
              }}
            />
          </>
        )}
      </QueryWrapper>
    </div>
  );
}

export default WageTypeTab;
