import { Pill, Td, Tooltip } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ViolationField } from 'src/types/DataValidationCheckpoint';

interface TdViolationTypeProps {
  fields: ViolationField[];
  type: 'neutral' | 'success' | 'heads-up' | 'warning';
}

const TdViolationType = ({ fields, type }: TdViolationTypeProps) => {
  const { t } = useTranslation();

  return (
    <Td>
      {fields.length > 0 && (
        <Tooltip content={fields.map((f) => t(`violationTypes:${f.key}`)).join(', ')}>
          <Pill
            className={'validating-table__pill--margin'}
            type={type}
            label={`${t(`violationTypes:${fields[0]?.key}`)} ${fields.length > 1 ? ` + ${fields.length - 1}` : ''}`}
          />
        </Tooltip>
      )}
    </Td>
  );
};

export default TdViolationType;
