import React, { ChangeEvent, FC, useContext, useState } from 'react';
import {
  Accordion,
  Checkbox,
  InlineEdit,
  ListGroup,
  Pill,
  Select,
  ToastContext,
  Typography,
} from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import { Employee } from 'src/types/Employee';
import { useUpdateEmployee } from 'src/service/employee/UpdateEmployeeMutation';
import { updateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import InfoMessage from 'src/components/Common/InfoMessage';
import { DataValidationEventCheckpoint } from 'src/types/Checkpoint';
import { stringOrBlank } from 'src/util/StringUtil';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { getCheckpointPillType } from 'src/util/CheckpointUtil';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import { isEndDateWithinValidPeriods } from './util';
import './missing-employee-in-wage-file.scss';

interface MissingEmployeeInWageFileCheckpointProps {
  employee: Employee;
  checkpoint: DataValidationEventCheckpoint;
  latestEmployeeCardWithSourceWageFile: AggregatedEmployeeCard;
}

const MissingEmployeeInWageFileCheckpoint: FC<MissingEmployeeInWageFileCheckpointProps> = ({
  employee,
  checkpoint,
  latestEmployeeCardWithSourceWageFile,
}) => {
  const { t } = useTranslation();
  const { displayToast } = useContext(ToastContext);
  const updateEmployee = useUpdateEmployee();

  const wageFileEmploymentEndDate = checkpoint.additionalData?.wageFileEmploymentEndDate;

  const employmentOptions = [
    { label: t('common:yes'), value: true },
    { label: t('common:no'), value: false },
  ];

  const getInitialEmploymentState = (date: string): boolean => {
    const initialEmployment: boolean = wageFileEmploymentEndDate !== date;

    return initialEmployment;
  };

  const [selectedEndDate, setSelectedEndDate] = useState<string>(
    employee.employmentEndDate ? employee.employmentEndDate : null,
  );

  const [employmentChanged, setEmploymentChanged] = useState<boolean>(getInitialEmploymentState(selectedEndDate));

  const [toggled, setToggled] = useState(checkpoint.checkpointStatus !== 'RAISED' ? true : false);

  const handleEmploymentChange = (option: boolean) => {
    setEmploymentChanged(option);
    if (option === false && checkpoint.checkpointStatus === 'RESOLVED') {
      updateEmployee(
        {
          id: employee.id,
          fieldKey: 'employmentEndDate',
          fieldValue: wageFileEmploymentEndDate ? wageFileEmploymentEndDate : null,
        },
        employee.employerId,
      );
      setSelectedEndDate(wageFileEmploymentEndDate ? wageFileEmploymentEndDate : null);
      setToggled(false);
    }
  };

  const handleEmploymentEndDateChange = (__field: string, value: string) => {
    if (value !== selectedEndDate && value !== null && employmentChanged === true) {
      if (isEndDateWithinValidPeriods(value, checkpoint, latestEmployeeCardWithSourceWageFile.period) === true) {
        setSelectedEndDate(value);
        updateEmployee(
          {
            id: employee.id,
            fieldKey: 'employmentEndDate',
            fieldValue: value,
          },
          employee.employerId,
        );
      } else {
        displayToast('error', t('common:error'), t('validating:drawer.error-toast-message'));
      }
    }
  };

  const handleCheckpointResolved = (e: ChangeEvent<HTMLInputElement>) => {
    if (employmentChanged === false) {
      updateCheckpointStatusMutation([checkpoint.id], e.target.checked ? 'RESOLVED' : 'RAISED', employee.employerId);
      setToggled(e.target.checked);
    }
  };

  return (
    <Accordion
      header={
        <Pill
          type={getCheckpointPillType([checkpoint])}
          label={t(`subActionCategories:${checkpoint.subActionCategory}`)}
        />
      }
      icon={<InfoMessage message={t('validating:drawer.checkpoint-info-message')} />}
    >
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('validating:drawer.is-employment-ended-label')} bold>
          <Select
            small
            placeholder={!employmentChanged ? t('common:select') : t(`commmon:${employmentChanged}`)}
            options={employmentOptions}
            selected={employmentOptions.find((option) => option.value === employmentChanged)}
            onChange={(option) => handleEmploymentChange(option.value)}
            disabled={toggled === true && employmentChanged === false}
          />
        </InlineEdit>
      </ListGroup>
      <When condition={employmentChanged === true}>
        <ListGroup variant="inline-edit">
          <InlineEdit bold label={t('validating:drawer.wage-file-employment-end-date-label')}>
            <Typography variant="body2" component="span">
              {stringOrBlank(wageFileEmploymentEndDate)}
            </Typography>
          </InlineEdit>
        </ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit bold label={t('validating:drawer.latest-employee-card-with-source-wage-file-label')}>
            <Typography variant="body2" component="span">
              {usePeriodText(stringOrBlank(latestEmployeeCardWithSourceWageFile.period))}
            </Typography>
          </InlineEdit>
        </ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit bold label={t('validating:drawer.set-new-end-date-label')}>
            <UpdateDatePicker
              placeholder={t('validating:drawer.date-format-placeholder')}
              fieldKey="employmentEndDateNotNull"
              onBlur={handleEmploymentEndDateChange}
              fieldValue={selectedEndDate ? selectedEndDate : null}
            />
          </InlineEdit>
        </ListGroup>
      </When>
      <ListGroup variant="inline-edit">
        <When condition={employmentChanged === false}>
          <div className="missing-employee-in-wage-file__info-message">
            <InfoMessage message={t('validating:drawer.employment-not-ended-info-message')} />
          </div>
          <InlineEdit bold label={t('validating:drawer.checkbox-label')}>
            <Checkbox checked={toggled} onChange={handleCheckpointResolved} />
          </InlineEdit>
        </When>
      </ListGroup>
    </Accordion>
  );
};

export default MissingEmployeeInWageFileCheckpoint;
