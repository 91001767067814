import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { GroupedAffiliationRules } from 'src/types/policy/GroupedAffiliationRules';
import { formatSweAmountText, parseSweAmountText } from 'src/util/Number/AmountFormatter';
import { inputSchema } from 'src/validation/schemas/inputSchema';

export const getDefaultLimitValue = (
  policyRuleType: PolicyRuleType,
  selectedAffiliationRuleGroup: GroupedAffiliationRules,
) => {
  const value = getRuleByRuleType(policyRuleType, selectedAffiliationRuleGroup)?.ruleValues.find(
    (rulevalue) => rulevalue.valueType === 'GREATER_OR_EQUALS_THAN',
  )?.value;

  return value ? formatSweAmountText(value) : '';
};

export const getRuleByRuleType = (
  policyRuleType: PolicyRuleType,
  selectedAffiliationRuleGroup: GroupedAffiliationRules,
) => {
  return selectedAffiliationRuleGroup.affiliationRules.find((rule) => rule.policyRuleType === policyRuleType);
};

export const wageLimitRulesHaveChanged = ({
  selectedAffiliationRuleGroup,
  calculationValues,
  lowerLimit,
  upperLimit,
}) => {
  let changed = false;
  // if any of the rules limit values have changed
  selectedAffiliationRuleGroup.affiliationRules.forEach((rule) => {
    if (rule.policyRuleType === PolicyRuleType.WAGE_LOWER_LIMIT) {
      if (rule.ruleValues[0].value !== parseSweAmountText(lowerLimit)) {
        changed = true;
      }
    } else if (rule.policyRuleType === PolicyRuleType.WAGE_UPPER_LIMIT) {
      if (rule.ruleValues[0].value !== parseSweAmountText(upperLimit)) {
        changed = true;
      }
    }
  });

  // if there is one rule and that rule's specific limit value have changed
  if (selectedAffiliationRuleGroup.affiliationRules.length === 1) {
    if (selectedAffiliationRuleGroup.affiliationRules[0].policyRuleType === PolicyRuleType.WAGE_LOWER_LIMIT) {
      upperLimit && (changed = true);
    }
    if (selectedAffiliationRuleGroup.affiliationRules[0].policyRuleType === PolicyRuleType.WAGE_UPPER_LIMIT) {
      lowerLimit && (changed = true);
    }
  }

  // if there is no rule, all changed values are relevant
  if (selectedAffiliationRuleGroup.affiliationRules.length === 0) {
    lowerLimit && (changed = true);
    upperLimit && (changed = true);
    calculationValues.sourceType && (changed = true);
    calculationValues.sourceReference && (changed = true);
    calculationValues.ruleValueUnit && (changed = true);
  }

  return changed;
};

export const wageLimitRulesAreValid = ({ selectedAffiliationRuleGroup, lowerLimit, upperLimit, calculationValues }) => {
  let valid = true;

  // if there are no current rules there must be a lower or upper limit
  if (selectedAffiliationRuleGroup.affiliationRules.length === 0 && !lowerLimit && !upperLimit) {
    valid = false;
  }

  // if there is no sourceType, sourceReference or ruleValueUnit
  if (!calculationValues.sourceType || !calculationValues.sourceReference || !calculationValues.ruleValueUnit) {
    valid = false;
  }

  // if there is both a lower and upper limit but lower limit is greater than upper limit
  if (lowerLimit && upperLimit) {
    if (+parseSweAmountText(lowerLimit) > +parseSweAmountText(upperLimit)) {
      valid = false;
    }
  }

  const lowerIsValid = inputSchema.validate({ amount2Dec: lowerLimit });
  const upperIsValid = inputSchema.validate({ amount2Dec: upperLimit });

  if (lowerLimit && lowerIsValid.error) {
    valid = false;
  }

  if (upperLimit && upperIsValid.error) {
    valid = false;
  }

  return valid;
};
