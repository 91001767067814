import { UpdateEmployerReportingFlowSettingsRequest } from 'src/graphql/schema/mutation/employer/UpdateEmployerReportingFlowSettingsRequest';
import { UPDATE_EMPLOYER_REPORTING_FLOW_SETTINGS } from 'src/graphql/schema/mutation/employer/UpdateEmployerReportingFlowSettings';
import { GET_EMPLOYERS_REPORTING_FLOW_SETTINGS } from 'src/graphql/schema/query/employer/GetEmployersReportingFlowSettings';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateEmployerReportingFlowSettings = () => {
  const apolloMutation = useApolloMutation();

  const updateEmployerReportingFlowSettings = (request: UpdateEmployerReportingFlowSettingsRequest) =>
    apolloMutation(UPDATE_EMPLOYER_REPORTING_FLOW_SETTINGS, request.employerId, { request }, [
      GET_EMPLOYERS_REPORTING_FLOW_SETTINGS,
    ]);

  return updateEmployerReportingFlowSettings;
};
