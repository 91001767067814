import { gql } from '@apollo/client';

export const GET_REPORTING_PERIOD_SUMMARIES = gql`
  query GetReportingPeriodSummaries($fromPeriod: String!, $toPeriod: String!, $employerIds: [UUID]!) {
    reportingPeriodSummaries(fromPeriod: $fromPeriod, toPeriod: $toPeriod, employerIds: $employerIds) {
      wageFilePeriod
      periodStatus
      employeesWithUnfinishedCheckpoints
      insuranceEventSummary {
        perReportingType {
          reportingType
          numberOfInsuranceEvents
        }
      }
    }
  }
`;
