import { gql } from '@apollo/client';

export const INSURANCE_EVENT_CHECKPOINT = gql`
  subscription InsuranceEventCheckpoint($employerIds: [UUID]!, $wageFilePeriod: String!) {
    checkpoint(employerIds: $employerIds, wageFilePeriod: $wageFilePeriod, actionCategory: "INSURANCE_EVENT") {
      id
      errandId
      errandEntryId
      employeeId
      employerId
      benefitType
      insuranceEventType
      actionCategory
      subActionCategory
      checkpointStatus
      finalized
      additionalData {
        employeeCardId
        employeeCardPeriod
        insuranceProviderProductConditionId
        insuranceProviderId
        insuranceEventId
        eventFlowType
      }
    }
  }
`;
