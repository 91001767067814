import { PolicyBenefitConnectionRuleType } from 'src/types/policy/PolicyBenefitConnectionRule';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { Button, Drawer, Icon, Pill, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import ConnectionRuleDetails from '../../ConnectionRuleDetails';
import { PolicyBenefitDrawerTab } from 'src/types/tabs/PolicyBenefitDrawerTab';

interface BenefitConnectionRuleDetailDrawerProps {
  policyId: string;
  policyEntityState: PolicyState;
  policyBenefit: PolicyBenefit;
  selectedConnectionRuleType: PolicyBenefitConnectionRuleType;
  onClose: () => void;
  navigateToTab: (tab: PolicyBenefitDrawerTab) => void;
  employerId: string;
}

const BenefitConnectionRuleDetailDrawer: React.FC<BenefitConnectionRuleDetailDrawerProps> = ({
  policyId,
  policyEntityState,
  policyBenefit,
  onClose,
  navigateToTab,
  employerId,
}) => {
  const { t } = useTranslation();

  const onBackClick = () => {
    navigateToTab(PolicyBenefitDrawerTab.CONNECTION_RULE);
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="drawer-base__title-container">
        <Typography variant="h3" component="h3" bold>
          {t('account:employers-tab.policy-settings.table.drawer.connection')}
        </Typography>
        <div className={'sub-title-pill'}>
          <Pill label={t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)} />
          <Typography variant="body2">{policyBenefit.name}</Typography>
        </div>
      </div>
      <ConnectionRuleDetails
        policyId={policyId}
        policyState={policyEntityState}
        policyBenefit={policyBenefit}
        employerId={employerId}
      />
    </Drawer>
  );
};

export default BenefitConnectionRuleDetailDrawer;
