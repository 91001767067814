import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfoMessage from 'src/components/Common/InfoMessage';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';

interface NoAgreementCostCentreProps {
  contract: InsuranceProviderContract;
}

function NoAgreementCostCentre({ contract }: NoAgreementCostCentreProps) {
  const customer = useCustomer();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const agreementRegistryUrl = `/account/${encodeURIComponent(customer?.name)}/${
    contract.employerId
  }/agreement-registry-settings`;

  const navigateToAgreementRegistrySettings = () => {
    navigate(agreementRegistryUrl);
  };

  const message = `${t('verify:drawer.no-cost-centre-for-contract')} ${contract.insuranceProvider.legalName} ${t(
    'verify:drawer.with-contract-number',
  )} ${contract.agreementNumber}.`;

  return (
    <InfoMessage
      message={message}
      linkText={t('verify:drawer.go-to-agreement-registry-settings')}
      linkOnClick={navigateToAgreementRegistrySettings}
    />
  );
}

export default NoAgreementCostCentre;
