import { ListItem } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import UnpaidInvoicesListItem from './UnpaidInvoicesListItem';
import { useTranslation } from 'react-i18next';
import { UnpaidInvoice } from 'src/types/invoice/UnpaidInvoice';
import { groupByPaymentDueDate } from '../../utils';
import './unpaid-invoices-overview-section.scss';

interface UnpaidInvoicesOverviewSectionProps {
  invoices: UnpaidInvoice[];
}
const UnpaidInvoicesOverviewSection: FC<UnpaidInvoicesOverviewSectionProps> = ({ invoices }) => {
  const { t } = useTranslation();
  const groupedInvoicesByPaymentDueDate = groupByPaymentDueDate(invoices);
  const sortedEntries = Array.from(groupedInvoicesByPaymentDueDate.entries()).sort((a, b) => {
    const timestampA = new Date(a[0]).getTime();
    const timestampB = new Date(b[0]).getTime();
    return timestampA - timestampB;
  });

  return (
    <ListItem data-cy="unpaid-invoices-overview-section" header={t('economy:economy-overview-tab.section-title')}>
      <div className="unpaid-invoices-overview-section">
        <ul className="unpaid-invoices-overview-section__list">
          {sortedEntries.map(([paymentDueDate, invoiceDetails]) => (
            <UnpaidInvoicesListItem
              key={`UnpaidInvoicesListItem-${paymentDueDate}`}
              groupedInvoicesByPaymentDueDate={invoiceDetails}
              paymentDueDate={paymentDueDate}
            />
          ))}
        </ul>
      </div>
    </ListItem>
  );
};
export default UnpaidInvoicesOverviewSection;
