import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleCard from 'src/components/Common/ModuleCard';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import CreateEmploymentGroupModal from './CreateEmploymentGroupModal';
import './policy-employment-groups-tabs.scss';
import FloatingAddButton from 'src/components/Common/FloatingAddButton/FloatingAddButton';

interface PolicyEmploymentGroupsTabProps {
  policyEntity: PolicyEntity;
  policyEmploymentGroups: PolicyEmploymentGroup[];
  handleSelect: (policyEmploymentGroupId: string) => void;
}

const PolicyEmploymentGroupsTab: React.FunctionComponent<PolicyEmploymentGroupsTabProps> = ({
  policyEntity,
  policyEmploymentGroups,
  handleSelect,
}) => {
  const { t } = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCardClick = (policyEmploymentGroupId: string) => {
    handleSelect(policyEmploymentGroupId);
  };

  return (
    <>
      <div className="policy-tabs--groups-tab">
        {policyEmploymentGroups.map((policyEmploymentGroup, index) => (
          <ModuleCard
            key={`policy-tabs-module-card--${policyEmploymentGroup.employmentGroupId}--${index}`}
            title={policyEmploymentGroup.employmentGroupName}
            iconType={'Users'}
            onClick={() => handleCardClick(policyEmploymentGroup.id)}
          >
            {/* TODO: wait for new requirements */}
            {/* {policyEmploymentGroup.groupedAffiliationRules.map((groupedRules, i) => (
              <EmploymentGroupAffiliationRuleBox
                key={`EmploymentGroupRuleBox-${groupedRules.affiliationRuleGroupType}-${i}`}
                affiliationRuleGroupType={groupedRules.affiliationRuleGroupType}
                affiliationRules={groupedRules.affiliationRules}
              />
            ))} */}
          </ModuleCard>
        ))}
      </div>
      {policyEntity?.state === 'DRAFT' && (
        <FloatingAddButton
          data-cy="add-employment-group-button"
          label={t('policySettings:employment-groups-tab.add-employment-group')}
          onClick={handleOpenCreateModal}
        />
      )}
      <CreateEmploymentGroupModal
        open={createModalOpen}
        onClose={handleCloseCreateModal}
        employerId={policyEntity?.employerId}
        policyId={policyEntity?.id}
      />
    </>
  );
};

export default PolicyEmploymentGroupsTab;
