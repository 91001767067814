import { gql } from '@apollo/client';

export const GET_KUB_OUTCOME = gql`
  query GetKubOutcome($kubId: UUID!) {
    kubOutcome(kubId: $kubId) {
      id
      kubSum
      kubType
      policyBenefitPackageId
      includedWages {
        id
        wageType
        employerWageTypeId
        payTypeName
        payTypeNumber
        amount
        payedAmount
        currency
        source
      }
    }
  }
`;
