import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_EMPLOYER_PAY_TYPE = gql`
  mutation CreateOrUpdateEmployerPayType($request: CreateOrUpdateEmployerPayType!) {
    createOrUpdateEmployerPayType(request: $request) {
      id
      number
      name
    }
  }
`;
