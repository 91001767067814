import { ADD_EMPLOYMENT_GROUP_CONNECTIONS } from 'src/graphql/schema/mutation/policy/AddEmploymentGroupConnections';
import { REMOVE_EMPLOYMENT_GROUP_CONNECTIONS } from 'src/graphql/schema/mutation/policy/RemoveEmploymentGroupConnections';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { useApolloMutation } from '../graphql/Mutation';

export const useAddEmploymentGroupConnection = () => {
  const apolloMutation = useApolloMutation();

  const addEmploymentGroupConnection = (
    benefitPackageId: string,
    policyEmploymentGroupId: string,
    employerId: string,
  ) =>
    apolloMutation(
      ADD_EMPLOYMENT_GROUP_CONNECTIONS,
      employerId,
      {
        benefitPackageId,
        policyEmploymentGroupIds: [policyEmploymentGroupId],
      },
      [GET_POLICY],
    );

  return addEmploymentGroupConnection;
};

export const useRemoveEmploymentGroupConnection = () => {
  const apolloMutation = useApolloMutation();

  const removeEmploymentGroupConnection = (
    benefitPackageId: string,
    policyEmploymentGroupId: string,
    employerId: string,
  ) =>
    apolloMutation(
      REMOVE_EMPLOYMENT_GROUP_CONNECTIONS,
      employerId,
      {
        benefitPackageId,
        policyEmploymentGroupIds: [policyEmploymentGroupId],
      },
      [GET_POLICY],
    );

  return removeEmploymentGroupConnection;
};
