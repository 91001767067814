import { Button, Typography } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployeeComment } from 'src/types/employees/EmployeeComment';
import ScrollBase from 'src/components/Common/ScrollBase';
import EmployeeCommentEntry from '../EmployeeCommentEntry';
import TextArea from 'src/components/Common/TextArea';
import { useState } from 'react';
import { useCreateEmployeeComment } from 'src/service/employee/CreateEmployeeComment';
import { Employee } from 'src/types/Employee';
import { SMALL_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import { useTranslation } from 'react-i18next';
import { sortCommentsFunction } from '../utils';
import './employee-comments-drawer.scss';

interface EmployeeCommentsDrawerProps {
  open: boolean;
  employeeComments: EmployeeComment[];
  employee: Employee;
  onClose: () => void;
}

const EmployeeCommentsDrawer = ({ open, employeeComments, employee, onClose }: EmployeeCommentsDrawerProps) => {
  const { t } = useTranslation();

  const createEmployeeComment = useCreateEmployeeComment();

  const [comment, setComment] = useState('');

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleOnClick = () => {
    createEmployeeComment(employee.id, comment, employee.employerId).then(() => {
      setComment('');
    });
  };

  const sortedEmployeeComments = employeeComments ? [...employeeComments].sort(sortCommentsFunction) : [];

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        title={
          <Typography variant="h2" bold>
            {t('employeeComments:drawer.title')}
          </Typography>
        }
        onClose={onClose}
      >
        <div className="employee-comments-drawer">
          <ScrollBase className="employee-comments-drawer__comments" maxHeightPercentage={58}>
            {sortedEmployeeComments.map((employeeComment, index) => {
              return (
                <EmployeeCommentEntry
                  key={`EmployeeCommentEntry-${employeeComment.id}-${index}`}
                  employeeComment={employeeComment}
                  employee={employee}
                />
              );
            })}
          </ScrollBase>
          <div className="employee-comments-drawer__add-comments">
            <TextArea
              placeholder={t('employeeComments:drawer.comment-placeholder')}
              value={comment}
              onChange={handleOnChange}
              validationKey="text255"
              characterLimit={SMALL_TEXTAREA_MAX_CHARACTERS}
            />
            <Button label={t('common:add')} disabled={comment.length < 3} onClick={handleOnClick} />
          </div>
        </div>
      </DrawerBase>
    </DrawerModal>
  );
};

export default EmployeeCommentsDrawer;
