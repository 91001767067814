import { CREATE_ACCOUNTING_COMPONENT_VALUE } from 'src/graphql/schema/mutation/accountingunit/CreateAccountingComponentValue';
import { ACCOUNTING_COMPONENT_TYPES } from 'src/graphql/schema/query/accountingunit/AccountingComponentTypes';
import { CreateAccountingComponentValueInput } from 'src/types/accountingunit/AccountingComponent';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateAccountingComponentValue = () => {
  const apolloMutation = useApolloMutation();

  const createAccountingComponentValue = (request: CreateAccountingComponentValueInput, employerId: string) =>
    apolloMutation(CREATE_ACCOUNTING_COMPONENT_VALUE, employerId, { request }, [ACCOUNTING_COMPONENT_TYPES]);

  return createAccountingComponentValue;
};
