import { Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/invoice/Invoice';
import CheckpointPendingTd from './CheckpointPendingTd';
interface InvoiceStatusTdProps {
  invoice: Invoice;
}
const InvoiceStatusTd: FC<InvoiceStatusTdProps> = ({ invoice }) => {
  const { t } = useTranslation();
  switch (invoice.invoiceStatus) {
    case 'CHECKPOINT_PENDING':
      return <CheckpointPendingTd invoice={invoice} />;

    default:
      return <Td>{t(`invoice:status.${invoice.invoiceStatus}`)}</Td>;
  }
};

export default InvoiceStatusTd;
