import { ApolloProvider } from '@apollo/client';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import './App.scss';
import { client } from './graphql';
import KeycloakInstance from './keycloak';
import { KeycloakProvider } from 'fgirot-k2-react-keycloak-provider';
import './i18n';
import LoadingPage from './components/Common/LoadingPage';
import ErrorPage from './components/Common/ErrorPage';
import { ToastsProvider } from 'fgirot-k2-ui-components';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <KeycloakProvider
    autoRefreshToken
    LoadingComponent={<LoadingPage />}
    ErrorComponentFunction={(err) => <ErrorPage errorMessage={err} />}
    authClient={KeycloakInstance}
  >
    {/* <React.StrictMode> */}
    <ToastsProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ToastsProvider>
    {/* </React.StrictMode> */}
  </KeycloakProvider>,
);
