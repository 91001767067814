import { Button, Icon, Pill, Tab, Tabs } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import React, { useState } from 'react';
import ReportingEventAboutTab from './ReportingEventAboutTab';
import ReportingEventMessageTab from './ReportingEventMessageTab';
import './reporting-event-details-drawer.scss';
import { Employee } from 'src/types/Employee';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';
import { ReportingEventDetailsDrawerTab } from 'src/types/tabs/ReportingEventDetailsDrawerTab';

interface ReportingEventDetailsDrawerProps {
  reportingEvent: ReportingEvent;
  employees: Employee[];
  personNumber: string;
  onClose: () => void;
  onBackClick: () => void;
}

const ReportingEventDetailDrawer = ({
  reportingEvent,
  personNumber,
  onClose,
  onBackClick,
  employees,
}: ReportingEventDetailsDrawerProps) => {
  const [currentTab, setCurrentTab] = useState<ReportingEventDetailsDrawerTab>(ReportingEventDetailsDrawerTab.ABOUT);
  const { t } = useTranslation();

  return (
    <DrawerBase
      onClose={onClose}
      title={t(`reportingTypes:${reportingEvent.reportingEventType}`)}
      subTitle={
        <div className="reporting-event-details-drawer__sub-title">
          <Pill
            type="neutral"
            size="small"
            label={`${t(`benefitTypes:abbreviations.${reportingEvent.benefitType}`)}`}
          />
          {reportingEvent.insuranceProvider && (
            <Pill type="neutral" size="small" label={reportingEvent.insuranceProvider.legalName} />
          )}
        </div>
      }
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('common:about')}>
          <ReportingEventAboutTab employees={employees} reportingEvent={reportingEvent} personNumber={personNumber} />
        </Tab>
        <Tab title={t('reporting:reporting-events.message')} data-testid="reporting-event-details-drawer__message-tab">
          <ReportingEventMessageTab reportingEvent={reportingEvent} />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default ReportingEventDetailDrawer;
