import { Select, Option } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
interface UpdateSelectProps {
  fieldValue: string;
  options: Option<string>[];
  onClick: (field: string, value: string) => void;
  fieldKey: string;
}

const UpdateSelect = ({ fieldValue, options, onClick, fieldKey, ...props }: UpdateSelectProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(undefined);

  const handleSelect = (option: Option<string>) => {
    setSelected(option);
    onClick(fieldKey, option.value);
  };

  useEffect(() => {
    setSelected(options.find((o) => o.value == fieldValue));
  }, [fieldValue]);

  return (
    <Select
      placeholder={t('common:select')}
      className="update-employee-select"
      options={options}
      selected={selected}
      onChange={handleSelect}
      small
      {...props}
    />
  );
};

export default UpdateSelect;
