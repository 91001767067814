import { gql } from '@apollo/client';

export const CREATE_EMPLOYEE_INDIVIDUAL_SETTINGS = gql`
  mutation CreateEmployeeIndividualSettings($employeeId: UUID!, $request: CreateIndividualSettingsRequest!) {
    createIndividualSettings(employeeId: $employeeId, request: $request) {
      employeeId
      individualSettings {
        settingsType
        settingsValue
        referenceType
        referenceId
      }
    }
  }
`;
