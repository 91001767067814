import { DELETE_CONTRACT_AGREED_PRODUCT } from 'src/graphql/schema/mutation/contract/DeleteContractAgreedProduct';
import { GET_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/query/contract/GetInsuranceProviderContract';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeleteContractAgreedProduct = () => {
  const apolloMutation = useApolloMutation();

  const deleteContractAgreedProduct = (
    employerId: string,
    insuranceProviderContractId: string,
    agreedProductId: string,
  ) =>
    apolloMutation(
      DELETE_CONTRACT_AGREED_PRODUCT,
      employerId,
      {
        insuranceProviderContractId,
        agreedProductId,
      },
      [GET_INSURANCE_PROVIDER_CONTRACT, GET_INSURANCE_PROVIDER_CONTRACTS],
    );

  return deleteContractAgreedProduct;
};
