import { EmployeeCard } from 'src/types/EmployeeCard';
import { ReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';

export const handleEmployeeCardUpdate = <T extends ReportEmployeeResponse>(
  responses: T[],
  subEmployeeCard: EmployeeCard,
): T[] => {
  return responses.map((response) => {
    return response.employeeCard.id === subEmployeeCard.id ? { ...response, employeeCard: subEmployeeCard } : response;
  });
};
