import { gql } from '@apollo/client';

export const GET_EMPLOYEE_REPORTING_EVENTS = gql`
  query GetReportingEvents($employeeId: UUID!, $wageFilePeriod: String!) {
    employeeReportingEvents(employeeId: $employeeId, wageFilePeriod: $wageFilePeriod) {
      id
      employeeId
      wageFilePeriod
      employeeInsuranceId
      availableMessageFormat
      messageFormatCategory
      benefitType
      reportingEventType
      insuranceEventType
      aggregatedInsuranceEvent {
        insuranceEventId
        eventFromDate
        insuranceEventStatus
        eventValues {
          id
          eventValueType
          currentValue
          previousValue
        }
      }
      messageReceipt {
        id
        messageId
        messageStatus
      }
    }
  }
`;
