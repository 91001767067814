import React, { useEffect, useState } from 'react';
import './report.scss';
import { Button, Icon, ProgressStep, ProgressSteps, TopBanner } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import FileUploadContainer from 'src/components/Report/Upload/FileUploadContainer/FileUploadContainer';
import ReportingStatus from '../../components/Report/Upload/ReportingStatus';
import ProgressWithErrorModal from 'src/components/Report/Upload/ProgressWithErrorModal';
import ExitReportModal from 'src/components/Report/Common/ExitReportModal';
import ReportingProgressBar from 'src/components/Report/Common/ReportingProgressBar';
import Validating from 'src/components/Report/Validating';
import Examination from 'src/components/Report/Examination';
import { finalizeCheckpoints } from 'src/service/checkpoint/FinalizeCheckpointsMutation';
import Certify from 'src/components/Report/Certify';
import ShowConditionsModal from 'src/components/Report/Certify/ShowConditionsModal';
import CertifyConditionsModal from 'src/components/Report/Certify/CertifyConditionsModal';
import { useParams } from 'react-router-dom';
import { getFromCache } from 'src/graphql/cache/ApolloCache';
import {
  EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE,
  EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE,
} from 'src/graphql/cache/CacheKeys';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { EmployeeReadyToFinalize } from 'src/types/EmployeeReadyToFinalize';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import { useNavigateWithFallback } from 'src/service/routes/LocationHooks';
import useTabParams from 'src/components/hooks/useTabParams';
import Verify from '../../components/Report/Verify';

const Report = () => {
  const { t } = useTranslation();
  const { year, month } = useParams();
  const wageFilePeriod = `${year}${month}`;

  const [progressStep, setProgressStep] = useTabParams('progress', 0);
  const [showExitModal, setShowExitModal] = useState(false);
  const [openProgressErrorModal, setOpenProgressErrorModal] = useState(false);
  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [showCertifyConditionsModal, setShowCertifyConditionsModal] = useState(false);

  useEffect(() => {
    if (progressStep > 4) {
      setProgressStep(0);
    }
  }, [progressStep]);

  const customer = useCustomer();

  const handleNextProgressStep = () => {
    if (progressStep === 1) {
      const employees = getFromCache<EmployeeReadyToFinalize[]>(EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE);
      finalizeCheckpoints(employees, wageFilePeriod, 'DATA_VALIDATION_EVENT');
    }
    if (progressStep === 2) {
      const employees = getFromCache<EmployeeReadyToFinalize[]>(EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE);
      finalizeCheckpoints(employees, wageFilePeriod, 'EMPLOYMENT_EVENT');
    }

    if (progressStep == 3) {
      // Borde vara 4???
      const employees = getFromCache<EmployeeReadyToFinalize[]>(EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE);
      finalizeCheckpoints(employees, wageFilePeriod, 'VERIFY_INSURANCE_EVENT');
    }
    //Handle Next step
    setProgressStep(progressStep + 1);
    setOpenProgressErrorModal(false);
  };

  const handleBackButton = () => {
    setProgressStep(progressStep - 1);
  };

  const handleNextButton = () => {
    if (progressStep === 0) {
      handleNextProgressStep();
    } else if (progressStep === 4) {
      setShowCertifyConditionsModal(true);
    } else {
      handleNextProgressStep();
    }
  };

  const handleExit = () => {
    setShowExitModal(true);
  };
  const handleOnStay = () => setShowExitModal(false);
  const handleOnClose = useNavigateWithFallback(-1, '/');

  const handleShowConditionsModalClose = () => {
    setShowConditionsModal(false);
  };

  const handleCertifyConditionsModalClose = () => {
    setShowCertifyConditionsModal(false);
  };

  const handleFinalize = (employees: EmployeeReadyToFinalize[]) => {
    handleCertifyConditionsModalClose();
    finalizeCheckpoints(employees, wageFilePeriod, 'INSURANCE_EVENT');
  };

  return (
    <>
      <ExitReportModal open={showExitModal} onStay={handleOnStay} onExit={handleOnClose} />
      <div className="report__container">
        <div className="report__container--banner-content">
          <TopBanner
            className="report__top-banner"
            text={`${t('report:salary-data')} ${usePeriodText(wageFilePeriod)}`}
          />
          <div className="report">
            <div className="report__content">
              <div className={[`report__row--cancel-button`].join(' ')}>
                <Button icon={<Icon type="Cross" />} type="link" label={t('report:exit-report')} onClick={handleExit} />
              </div>
              <div className={[`report__row--h1-progress`].join(' ')}>
                <div className="report__row--h1-progress__progress">
                  <ProgressSteps handleStepChange={setProgressStep} active={progressStep}>
                    <ProgressStep label={t('report:progress-steps.step-1')} />
                    <ProgressStep label={t('report:progress-steps.step-2')} />
                    <ProgressStep label={t('report:progress-steps.step-3')} />
                    <ProgressStep label={t('report:progress-steps.step-4')} />
                    <ProgressStep label={t('report:progress-steps.step-5')} />
                  </ProgressSteps>
                </div>
                {progressStep === 4 && (
                  <Button
                    className="report__row--h1-progress__def-button"
                    icon={<Icon type="Info"></Icon>}
                    type="link"
                    label={t('report:see-terms')}
                    onClick={() => setShowConditionsModal(true)}
                  ></Button>
                )}
              </div>
              {progressStep === 0 && <FileUploadContainer wageFilePeriod={wageFilePeriod} />}
              {progressStep === 1 && <Validating wageFilePeriod={wageFilePeriod} customerId={customer?.id} />}
              {progressStep === 2 && <Examination wageFilePeriod={wageFilePeriod} customerId={customer?.id} />}
              {progressStep === 3 && <Verify wageFilePeriod={wageFilePeriod} customerId={customer?.id} />}
              {progressStep === 4 && <Certify wageFilePeriod={wageFilePeriod} customerId={customer?.id} />}
            </div>
            {progressStep === 0 && <ReportingStatus wageFilePeriod={wageFilePeriod} />}
          </div>
        </div>
        {/* TODO completedPercentage should be based on upload status */}
        <ReportingProgressBar
          progressStep={progressStep}
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
        />
      </div>
      <ProgressWithErrorModal
        open={openProgressErrorModal}
        onClose={() => setOpenProgressErrorModal(false)}
        handleNext={handleNextProgressStep}
      />

      {showConditionsModal && (
        <ShowConditionsModal open={showConditionsModal} handleExit={handleShowConditionsModalClose} />
      )}
      {showCertifyConditionsModal && (
        <CertifyConditionsModal
          open={showCertifyConditionsModal}
          handleExit={handleCertifyConditionsModalClose}
          handleFinalize={handleFinalize}
        />
      )}
    </>
  );
};

export default Report;
