import { useApolloMutation } from '../graphql/Mutation';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';
import { UpdateDraftEmployeeCardWageRequest } from 'src/types/employees/request/UpdateDraftEmployeeCardWageRequest';
import { UPDATE_DRAFT_EMPLOYEE_CARD_WAGE } from 'src/graphql/schema/mutation/employeecard/UpdateDraftEmployeeCardWage';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';

export const useUpdateDraftEmployeeCardWage = () => {
  const apolloMutation = useApolloMutation();

  const updateDraftEmployeeCardWage = (request: UpdateDraftEmployeeCardWageRequest, employerId: string) =>
    apolloMutation(
      UPDATE_DRAFT_EMPLOYEE_CARD_WAGE,
      employerId,
      {
        request,
      },
      [GET_LATEST_EMPLOYEE_CARD, FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES],
    );

  return updateDraftEmployeeCardWage;
};
