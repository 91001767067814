import { DrawerNavigation, ListGroup } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import When from 'src/components/Common/When';
import { EmployeeIndividualChoice, EmployeeIndividualChoices } from 'src/types/employees/EmployeeIndividualChoice';
import './employee-individual-choices-about-tab.scss';
import { Employee } from 'src/types/Employee';

interface EmployeeIndividualChoicesAboutTabProps {
  employee: Employee;
  employeeIndividualChoices: EmployeeIndividualChoices;
  onSelectIndividualChoice: (individualChoice: EmployeeIndividualChoice) => void;
  onCreateIndividualChoice: () => void;
}

function EmployeeIndividualChoicesAboutTab({
  employeeIndividualChoices,
  onSelectIndividualChoice,
  onCreateIndividualChoice,
  employee,
}: EmployeeIndividualChoicesAboutTabProps) {
  const { t } = useTranslation();

  return (
    <div className="employee-individual-choices-about-tab">
      <When condition={employee.isDraft}>
        <EmployeeInDraftBanner />
      </When>
      <ListGroup variant="drawer-navigation">
        {employeeIndividualChoices.individualChoices.map((individualChoice) => (
          <DrawerNavigation
            key={individualChoice.id}
            label={t(`individualChoiceTypes:${individualChoice.type}`)}
            onClick={() => onSelectIndividualChoice(individualChoice)}
          />
        ))}
      </ListGroup>
      <When condition={employee.isDraft}>
        <ListGroup variant="drawer-navigation">
          <DrawerNavigation label={t('employees:individual-choices.add-choice')} onClick={onCreateIndividualChoice} />
        </ListGroup>
      </When>
    </div>
  );
}

export default EmployeeIndividualChoicesAboutTab;
