import { Button, Icon, Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { GET_CONTRACT_AGREED_PRODUCT } from 'src/graphql/schema/query/contract/GetContractAgreedProduct';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import './agreed-product-details.scss';
import { useDeleteContractAgreedProduct } from 'src/service/contract/DeleteContractAgreedProduct';
import AgreedProductEditableFields from './AgreedProductEditableFields/AgreedProductEditableFields';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import { useUpdateContractAgreedProduct } from 'src/service/contract/UpdateContractAgreedProduct';
import { ContractAgreedProduct } from 'src/types/contract/ContractAgreedProduct';
import { stringOrBlank } from 'src/util/StringUtil';
import ApolloServiceWrapper from 'src/components/Common/ApolloServiceWrapper';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useApolloQuery } from 'src/service/graphql/Query';
import AgreedProductGrouping from './AgreedProductGrouping';

interface AgreedProductDetailsProps {
  onClose: () => void;
  onBackClick: () => void;
  contract: InsuranceProviderContract;
  agreedProductId: string;
}

const AgreedProductDetails = ({ onClose, onBackClick, contract, agreedProductId }: AgreedProductDetailsProps) => {
  const { t } = useTranslation();
  const deleteContractAgreedProduct = useDeleteContractAgreedProduct();
  const updateContractAgreedProduct = useUpdateContractAgreedProduct();

  const onDeleteAgreedProduct = (agreedProductId: string) => {
    deleteContractAgreedProduct(contract.employerId, contract.id, agreedProductId).then(() => {
      onBackClick();
    });
  };

  const onUpdateAgreedProduct = (request: ContractAgreedProductRequest) => {
    updateContractAgreedProduct(contract.employerId, contract.id, agreedProductId, request).then(() => {
      onBackClick();
    });
  };
  const apolloQuery = useApolloQuery<ContractAgreedProduct>(GET_CONTRACT_AGREED_PRODUCT, {
    variables: { agreedProductId },
  });
  const agreedProduct = apolloQuery.status == 'loaded' ? apolloQuery.payload : null;

  const _subTitle = () => (
    <div className="agreement-registry-drawer__sub-title">
      <Typography>{stringOrBlank(contract.insuranceProvider?.legalName)}</Typography>
      <Pill type="neutral" label={contract.agreementNumber} />
    </div>
  );

  return (
    <DrawerBase
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
      title={stringOrBlank(agreedProduct?.name)}
      subTitle={<_subTitle />}
    >
      <ApolloServiceWrapper service={apolloQuery}>
        <Tabs>
          <Tab title={t('agreementRegistrySettings:drawer:tabs:about')}>
            <AgreedProductEditableFields
              agreedProduct={agreedProduct}
              onUpdateAgreedProduct={onUpdateAgreedProduct}
              onDeleteAgreedProduct={onDeleteAgreedProduct}
              contract={contract}
            />
          </Tab>
          <Tab title={t('agreementRegistrySettings:drawer:tabs:grouping')}>
            <AgreedProductGrouping
              agreedProduct={agreedProduct}
              employerId={contract.employerId}
              contractId={contract.id}
            />
          </Tab>
        </Tabs>
      </ApolloServiceWrapper>
    </DrawerBase>
  );
};

export default AgreedProductDetails;
