import { gql } from '@apollo/client';

export const CREATE_POLICY_BENEFIT = gql`
  mutation CreatePolicyBenefit($request: CreatePolicyBenefitRequest!) {
    createPolicyBenefit(request: $request) {
      id
      benefitPackageId
      benefitType
      name
    }
  }
`;
