import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYER_WAGE_TYPES } from 'src/graphql/schema/query/employer/GetEmployerWageTypes';
import { UPDATE_EMPLOYER_WAGE_TYPE } from 'src/graphql/schema/mutation/employer/UpdateEmployerWageType';
import { UpdateEmployerWageTypeRequest } from 'src/types/employer/request/UpdateEmployerWageTypeRequest';

export const useUpdateEmployerWageType = () => {
  const mutation = useApolloMutation();

  const updateEmployerWageType = (
    employerWageTypeId: string,
    employerId: string,
    request: UpdateEmployerWageTypeRequest,
  ) => mutation(UPDATE_EMPLOYER_WAGE_TYPE, employerId, { employerWageTypeId, request }, [GET_EMPLOYER_WAGE_TYPES]);

  return updateEmployerWageType;
};
