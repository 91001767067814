import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYEE_INSURANCES } from 'src/graphql/schema/query/employee/GetEmployeeInsurances';
import { Employee } from 'src/types/Employee';
import { EmployeeInsurancesPage } from 'src/types/insurance/Insurance';
import {
  EmployeeInsurancesFilters,
  EmployeeInsurancesRequest,
} from 'src/types/employees/request/EmployeeInsurancesRequest';
import './insurances-overview.scss';
import Insurances from './Insurances/Insurances';

interface InsurancesOverviewProps {
  employee: Employee;
}

const InsurancesOverview = ({ employee }: InsurancesOverviewProps) => {
  const [request, setRequest] = React.useState<EmployeeInsurancesRequest>({
    searchInput: '',
    pageNumber: 0,
    pageSize: 25,
    filters: {
      insuranceProviderIdsFilter: null,
      benefitTypesFilter: null,
      agreedProductIdsFilter: null,
    },
  });

  const handleTextSearch = (searchInput: string) => {
    setRequest({
      ...request,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: EmployeeInsurancesFilters) => {
    setRequest({ ...request, filters });
  };

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  return (
    <QueryWrapper
      query={GET_EMPLOYEE_INSURANCES}
      options={{
        variables: {
          employeeId: employee.id,
          includeInsurancesMissingAgreedProduct: false,
          request: {
            searchInput: request?.searchInput,
            pageNumber: pageNumber,
            pageSize: pageSize,
            filters: request?.filters,
          },
        },
        fetchPolicy: 'no-cache',
      }}
    >
      {({ employeeInsurances, paging }: EmployeeInsurancesPage) => (
        <Insurances
          employeeInsurances={employeeInsurances}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          request={request}
          onTextSearch={handleTextSearch}
          onApplyFilters={handleApplyFilters}
          onPageChange={handlePageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default InsurancesOverview;
