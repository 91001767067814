import { Icon, Tooltip } from 'fgirot-k2-ui-components';
import React from 'react';
import './draft-changed-tooltip.scss';

interface DraftChangedTooltipProps {
  label: string;
  hasChangesInDraft: boolean;
  children: React.ReactNode;
}

function DraftChangedTooltip({ label, children, hasChangesInDraft }: DraftChangedTooltipProps) {
  return (
    <div className="draft-changed-tooltip">
      {hasChangesInDraft ? (
        <Tooltip content={label} direction="bottom">
          <>
            {children}
            {hasChangesInDraft && <Icon type="Info" stroke="#a6aebb" className="employee-section-label__icon" />}
          </>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </div>
  );
}

export default DraftChangedTooltip;
