import { gql } from '@apollo/client';

export const GET_EMPLOYER_WAGE_FILE_SETTING = gql`
  query GetEmployerWageFileSetting($employerId: UUID!) {
    employerWageFileSetting(employerId: $employerId) {
      id
      employerId
      salarySystem
      wageFileFormat
      expectedNoOfWageFiles
    }
  }
`;
