import { Modal, Card, Typography, CardAction, Button } from 'fgirot-k2-ui-components';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextArea from 'src/components/Common/TextArea';
import { usePublishPolicyDraft } from 'src/service/policy/PublishPolicyDraft';
import { PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';
import { getSumChanges } from 'src/util/PolicyUtil';
import { SMALL_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';

interface PublishPolicyDraftModalProps {
  open: boolean;
  changes: PolicyVersionChanges;
  onClose: () => void;
}

const PublishPolicyDraftModal: React.FunctionComponent<PublishPolicyDraftModalProps> = ({ open, changes, onClose }) => {
  const { employerId, policyId } = useParams();
  const publishPolicyDraft = usePublishPolicyDraft();
  const { t } = useTranslation();
  const [comment, setComment] = useState<string>('');

  const handleOnClose = () => {
    onClose();
    setComment('');
  };

  const handleComment = (event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value);

  const handlePublishDraft = async () => {
    publishPolicyDraft(
      {
        policyId,
        fromDate: undefined,
        immediatePublish: true,
        comment,
      },
      employerId,
    ).finally(() => {
      handleOnClose();
    });
  };

  return (
    <Modal data-cy="publish-policy-draft-modal" centered overlayBackground open={open} onClose={handleOnClose}>
      <Card title={t('account:employers-tab.policy-settings.publish-draft-policy-modal.title')} size="narrow">
        <div>
          <Typography component="span">
            {`${t('account:employers-tab.policy-settings.publish-draft-policy-modal.publish-text-pre')} `}
          </Typography>
          <Typography component="span" bold>
            {`${getSumChanges(changes)}`}
          </Typography>
          <Typography component="span">
            {` ${t('account:employers-tab.policy-settings.publish-draft-policy-modal.publish-text-post')}`}
          </Typography>
        </div>
        <TextArea
          value={comment}
          onChange={handleComment}
          validationKey="text255"
          characterLimit={SMALL_TEXTAREA_MAX_CHARACTERS}
          placeholder={t('account:employers-tab.policy-settings.publish-draft-policy-modal.comment-placeholder')}
        />
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleOnClose}
            data-cy="publish-policy-draft-modal__cancel-button"
          />
          <Button
            label={t('account:employers-tab.policy-settings.publish-draft-policy-modal.button-text')}
            disabled={comment.length < 3}
            onClick={handlePublishDraft}
            data-cy="publish-policy-draft-modal__publish-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default PublishPolicyDraftModal;
