import { ProgressBar, Button, Icon } from 'fgirot-k2-ui-components';
import React from 'react';
import './reporting-progress-bar.scss';
import { useTranslation } from 'react-i18next';

interface ReportingProgressBarProps {
  progressStep: number;
  handleBackButton: () => void;
  handleNextButton: () => void;
}

const ReportingProgressBar = ({ progressStep, handleBackButton, handleNextButton }: ReportingProgressBarProps) => {
  const { t } = useTranslation();
  return (
    <ProgressBar className="reporting-progress-bar" completedPercentage={10}>
      {progressStep === 0 ? (
        <div />
      ) : (
        <Button
          type="link"
          label={t('common:back')}
          iconPlacement="left"
          icon={<Icon type="ArrowLeft" />}
          // TODO disabled before files are uploaded
          disabled={false}
          onClick={handleBackButton}
        />
      )}
      <Button
        label={progressStep === 4 ? t('common:finalize') : t('common:next')}
        iconPlacement="right"
        // TODO disabled before files are uploaded
        disabled={false}
        onClick={handleNextButton}
      />
    </ProgressBar>
  );
};

export default ReportingProgressBar;
