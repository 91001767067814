import { GET_PGL_RULES } from 'src/graphql/schema/query/policy/GetPglRules';
import { DELETE_PGL_RULE_BONUS_COMPONENT } from 'src/graphql/schema/mutation/policy/DeletePglRuleBonusComponent';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeletePglRuleBonusComponent = () => {
  const apolloMutation = useApolloMutation();

  const deletePglRuleBonusComponent = (
    policyId: string,
    pglRuleId: string,
    bonusComponentId: string,
    employerId: string,
  ) =>
    apolloMutation(DELETE_PGL_RULE_BONUS_COMPONENT, employerId, { policyId, pglRuleId, bonusComponentId }, [
      GET_PGL_RULES,
    ]);

  return deletePglRuleBonusComponent;
};
