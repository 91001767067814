import { gql } from '@apollo/client';

export const GET_GROUPS = gql`
  query GetGroups($pageSize: Int, $pageNumber: Int) {
    groups(pageSize: $pageSize, pageNumber: $pageNumber) {
      paging {
        totalNumberOfElements
      }
      externalGroups {
        id
        name
        admin
        roles {
          id
          name
        }
        users {
          id
          name
        }
        employers {
          id
          name
        }
        customer {
          id
          name
        }
      }
    }
  }
`;
