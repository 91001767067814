import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, PieChart, Typography } from 'fgirot-k2-ui-components';
import { getMonthKeys } from 'src/util/date/Months';
import './period-card.scss';
import { ReportingPeriodSummary } from 'src/types/reporting/ReportingPeriodSummary';
import LoadingOverlay from 'src/components/Common/LoadingOverlay';
import PeriodStatusPill from './PeriodStatusPill';
import { useEventsPerReportingTypeDataPoints } from '../utils';

interface PeriodCardProps {
  year: number;
  month: number;
  onSelectPeriod: (month: number) => void;
  reportingPeriodSummary: ReportingPeriodSummary;
  reportingPeriodSummaryLoading: boolean;
}

const PeriodCard: React.FunctionComponent<PeriodCardProps> = ({
  year,
  month,
  onSelectPeriod,
  reportingPeriodSummary,
  reportingPeriodSummaryLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const periodCardRef = React.useRef<HTMLLIElement>(null);
  const { getEmptyDataPoints, generateDataPoints } = useEventsPerReportingTypeDataPoints();
  const [dataPoints, setDataPoints] = React.useState(getEmptyDataPoints());

  const goToSalaryData = () => {
    navigate(`/reporting/${year}/${new String(month).padStart(2, '0')}`);
  };

  const goToEvents = () => {
    navigate(`/reporting/${year}/${new String(month).padStart(2, '0')}/insurance-events`);
  };

  useEffect(() => {
    if (reportingPeriodSummary && reportingPeriodSummary?.insuranceEventSummary?.perReportingType.length > 0) {
      setDataPoints(generateDataPoints(reportingPeriodSummary.insuranceEventSummary?.perReportingType));
    }
  }, [reportingPeriodSummary]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onSelectPeriod(month);
      }
    };

    periodCardRef.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      periodCardRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <li
      className="period-card"
      tabIndex={0}
      onClick={() => onSelectPeriod(month)}
      ref={periodCardRef}
      data-testid="period-summary"
    >
      <div className="period-card__content">
        <div className="period-card--title-header">
          <Typography variant="h3" component="h2" bold>{`${t(getMonthKeys()[month - 1])}`}</Typography>
          <Typography variant="h3" component="h2" bold className="period-card--title-year">
            {`${year}`}
          </Typography>
          <div className="period-card--title-pill">
            <PeriodStatusPill periodStatus={reportingPeriodSummary?.periodStatus} />
          </div>
        </div>
        <div className="period-card__summary-data">
          <LoadingOverlay loading={reportingPeriodSummaryLoading} />
          <PieChart
            title={
              <>
                <Typography variant="caption" bold className="period-card--sub-title">
                  {t('report:overview.insurance-events')}
                </Typography>
              </>
            }
            dataPoints={dataPoints}
          />
          <div className="period-card--summary">
            <Typography variant="caption" bold className="period-card--sub-title">
              {t('report:overview.employees-with-unfinished-checkpoints')}
            </Typography>
            <Typography
              variant="body2"
              bold
              data-testid={`period-summary-employees-with-unfinished-checkpoints-${reportingPeriodSummary?.wageFilePeriod}`}
            >
              {reportingPeriodSummary?.employeesWithUnfinishedCheckpoints?.toString() || '0'}
            </Typography>
          </div>
        </div>
        <div className="period-card--buttons">
          <Button
            type="secondary"
            label={t('report:overview.salary-data')}
            onClick={goToSalaryData}
            data-cy={`{'overview.salary-data-button-'}${year}-${month}`}
          />
          <Button
            type="secondary"
            label={t('report:overview.events')}
            onClick={goToEvents}
            data-cy={`insurance-events-button-${year}-${month}`}
          />
        </div>
      </div>
    </li>
  );
};

export default PeriodCard;
