import { UPDATE_EMPLOYMENT_EVENT_CHECKPOINT_INDIVIDUAL_CHOICE } from 'src/graphql/schema/mutation/checkpoint/UpdateEmploymentEventCheckpointIndividualChoice';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';
import { UpdateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/UpdateEmployeeIndividualChoiceRequest';
import { GET_EMPLOYMENT_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetEmploymentEventCheckpoints';

export const useUpdateEmploymentEventCheckpointIndividualChoice = () => {
  const apolloMutation = useApolloMutation();

  const updateEmploymentEventCheckpointIndividualChoice = (
    request: UpdateEmployeeIndividualChoiceRequest,
    employerId: string,
  ) =>
    apolloMutation(
      UPDATE_EMPLOYMENT_EVENT_CHECKPOINT_INDIVIDUAL_CHOICE,
      employerId,
      {
        request,
      },
      [GET_EMPLOYEE_INDIVIDUAL_CHOICES, GET_EMPLOYMENT_EVENT_CHECKPOINTS],
    );

  return updateEmploymentEventCheckpointIndividualChoice;
};
