import { client } from 'src/graphql';
import { GET_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetEmployeeCard';

import { EmployeeCard } from 'src/types/EmployeeCard';

export const fetchEmployeeCard = async (employeeCardId: string): Promise<EmployeeCard> => {
  const {
    data: { employeeCard },
  } = await client.query({ query: GET_EMPLOYEE_CARD, variables: { employeeCardId }, fetchPolicy: 'no-cache' });

  return employeeCard;
};
