import { gql } from '@apollo/client';

export const GET_INSURANCE_EVENT_COMMENTS = gql`
  query GetInsuranceEventInfos($insuranceEventId: UUID!) {
    insuranceEventComments(insuranceEventId: $insuranceEventId) {
      handlingType
      comment
      createdDate
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
