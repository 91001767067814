import { gql } from '@apollo/client';

export const REMOVE_ROLE_FROM_GROUP = gql`
  mutation RemoveRoleFromGroup($groupId: UUID!, $roleId: UUID!) {
    removeRoleFromGroup(groupId: $groupId, roleId: $roleId) {
      groupId
      roleId
    }
  }
`;
