import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import KubTab from 'src/components/Account/WageTypeMappingSettings/KubTab';
import PageBase from 'src/components/PageBase';
import { getEmployer } from 'src/service/employer/GetEmployer';
import { Employer } from 'src/types/employer/Employer';
import './wage-type-mapping-settings.scss';
import PayTypeTab from 'src/components/Account/WageTypeMappingSettings/PayTypeTab/PayTypeTab';
import useTabParams from 'src/components/hooks/useTabParams';
import { WageTypeMappingTab } from 'src/types/tabs/WageTypeMappingTab';
import WageTypeTab from 'src/components/Account/WageTypeMappingSettings/WageTypeTab';

const WageTypeMappingSettings = () => {
  const { t } = useTranslation();
  const { customerName, employerId } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useTabParams('tab', WageTypeMappingTab.EMPLOYER_PAY_TYPES);
  const [employer, setEmployer] = useState<Employer>();

  useEffect(() => {
    getEmployer(employerId).then((res) => setEmployer(res));
  }, [employerId]);

  const breadCrumbs = [
    {
      name: customerName,
      steps: 2,
    },
    {
      name: employer?.legalName,
      steps: 1,
    },
  ];

  const handleBackClick = () => navigate(`/account/${customerName}/${employerId}`);

  return (
    <PageBase
      title={t('account:employers-tab.wage-type-mapping-settings.wage-type-mapping')}
      handleBackButton={handleBackClick}
      breadCrumbs={breadCrumbs}
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab data-cy="pay-type-tab" title={t('account:employers-tab.wage-type-mapping-settings.employer-pay-types')}>
          <PayTypeTab employerId={employerId} />
        </Tab>
        <Tab data-cy="wage-type-tab" title={t('account:employers-tab.wage-type-mapping-settings.employer-wage-types')}>
          <WageTypeTab employerId={employerId} />
        </Tab>
        <Tab data-cy="kub-tab" title={t('account:employers-tab.wage-type-mapping-settings.kub')}>
          <KubTab employerId={employerId} />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default WageTypeMappingSettings;
