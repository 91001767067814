import React, { useEffect, useState } from 'react';
import { Wage } from 'src/types/EmployeeCard';
import { Button, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { UpdateDraftEmployeeCardWageRequest } from 'src/types/employees/request/UpdateDraftEmployeeCardWageRequest';
import { useUpdateDraftEmployeeCardWage } from 'src/service/employeecard/UpdateDraftEmployeeCardWage';
import { stringOrBlank } from 'src/util/StringUtil';
import { hasAmountChanged, hasQuantityValueChanged } from '../utils';
import { formatPayType, globalAbsenceWageTypes, wageIsOnlyMappedToKUB } from 'src/util/WageUtil';
import InfoMessage from 'src/components/Common/InfoMessage';
import { AmountInput } from 'src/components/Common/Wage/inputs/AmountInput';
import { PayedAmountInput } from 'src/components/Common/Wage/inputs/PayedAmountInput';
import { parseSweAmountTextWithNegativeAmounts } from 'src/util/Number/AmountFormatter';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import When from 'src/components/Common/When';
import { QuantityValueInput } from 'src/components/Common/Wage/inputs/QuantityValueInput';
import './wage-details.scss';

interface WageDetailsProps {
  wage: Wage;
  employeeCardId: string;
  employerId: string;
  isDraft: boolean;
  onBackClick: () => void;
  employerWageTypes: EmployerWageType[];
}

const WageDetails = ({
  wage,
  employeeCardId,
  isDraft,
  employerId,
  onBackClick,
  employerWageTypes,
}: WageDetailsProps) => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState(wage.amount.toString().replace('.', ','));
  const [payedAmount, setPayedAmount] = useState(wage.payedAmount ? wage.payedAmount.toString().replace('.', ',') : '');
  const [hoursInput, setHoursInput] = useState(!wage.quantityValue ? '-' : wage.quantityValue.toString());
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const updateDraftEmployeeCardWage = useUpdateDraftEmployeeCardWage();

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handlePayedAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayedAmount(e.target.value);
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHoursInput(e.target.value);
  };

  const handleSaveChanges = async () => {
    setSaveButtonDisabled(true);

    const request: UpdateDraftEmployeeCardWageRequest = {
      employeeCardId,
      wageId: wage.id,
      changes: [
        {
          fieldKey: 'amount',
          fieldValue: parseSweAmountTextWithNegativeAmounts(amount),
        },
      ],
    };

    if (shouldDisplayPayedAmountInput) {
      request.changes.push({
        fieldKey: 'payedAmount',
        fieldValue: parseSweAmountTextWithNegativeAmounts(payedAmount),
      });
    }

    if (shouldDisplayQuantityUnit) {
      request.changes.push({
        fieldKey: 'quantity',
        fieldValue: hoursInput,
      });
    }

    await updateDraftEmployeeCardWage(request, employerId);
    onBackClick();
  };

  useEffect(() => {
    const quantityValueValidation = inputSchema.validate({
      positiveIntegerString: hoursInput,
    });
    const amountValidation = inputSchema.validate({
      amount2DecAllowNegative: amount,
    });
    const payedAmountValidation = inputSchema.validate({
      amount2DecAllowNegative: payedAmount,
    });

    if (
      !quantityValueValidation.error &&
      !amountValidation.error &&
      !payedAmountValidation.error &&
      (hasQuantityValueChanged(wage.quantityValue.toString(), hoursInput) ||
        hasAmountChanged(wage.amount, amount) ||
        hasAmountChanged(wage.payedAmount, payedAmount))
    ) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [amount, payedAmount, hoursInput]);

  const employerWageType = employerWageTypes.find((w) => w.id === wage.employerWageTypeId);

  const isAbsenceWageType = globalAbsenceWageTypes.includes(wage?.wageType);

  const shouldDisplayPayedAmountInput = wage.kubTypes?.length > 0 && !isAbsenceWageType;

  const shouldDisplayQuantityUnit = wage.wageType === 'HOURLY_SALARY';

  const isOnlyMappedToKub = wageIsOnlyMappedToKUB(wage);

  return (
    <div className="wage-details">
      {isDraft && isAbsenceWageType && <InfoMessage message={t('wages:absence-wages-cannot-be-edited')} />}
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('wages:pay-type')} bold>
          {isDraft && !isAbsenceWageType ? (
            <TextInput value={`${wage.payTypeNumber} - ${wage.payTypeName}`} onChange={null} disabled />
          ) : (
            <Typography variant="body2">{formatPayType(wage.payTypeNumber, wage.payTypeName, t)}</Typography>
          )}
        </InlineEdit>

        <When condition={!isOnlyMappedToKub}>
          <InlineEdit label={t('wages:wage-type')} bold>
            {isDraft && !isAbsenceWageType ? (
              <TextInput value={employerWageType?.name || ''} onChange={null} disabled />
            ) : (
              <Typography variant="body2">{stringOrBlank(employerWageType?.name)}</Typography>
            )}
          </InlineEdit>

          <When condition={shouldDisplayQuantityUnit}>
            <InlineEdit label={t('wages:use-quantity-label')} bold data-cy="use-quantity-label">
              {isDraft ? (
                <Typography className="wage-details--disabled-in-draft" variant="body2">
                  {wage.useQuantity ? <Icon type="Check" stroke="#479890" /> : <Icon type="Cross" stroke="#fa556b" />}
                </Typography>
              ) : (
                <Typography className="wage-details--icon-enabled" variant="body2">
                  {wage.useQuantity ? <Icon type="Check" stroke="#479890" /> : <Icon type="Cross" stroke="#fa556b" />}
                </Typography>
              )}
            </InlineEdit>
            <QuantityValueInput
              isEditable={isDraft}
              wage={wage}
              quantityValue={hoursInput}
              onHoursChange={handleHoursChange}
            />
          </When>
          <AmountInput
            isEditable={isDraft && !isAbsenceWageType}
            amount={amount}
            handleAmountChange={handleAmountChange}
          />
        </When>
        <When condition={shouldDisplayPayedAmountInput}>
          <PayedAmountInput
            isEditable={isDraft && !isAbsenceWageType}
            payedAmount={payedAmount}
            handlePayedAmountChange={handlePayedAmountChange}
          />
        </When>
      </ListGroup>
      <When condition={shouldDisplayPayedAmountInput}>
        <InfoMessage message={`${t('wages:payed-amount-info')} ${wage.kubTypes.join(', ')}`} />
      </When>

      <When condition={isDraft}>
        <Button
          type="primary"
          label={t('common:save-changes')}
          onClick={handleSaveChanges}
          disabled={saveButtonDisabled || isAbsenceWageType}
        />
      </When>
    </div>
  );
};

export default WageDetails;
