import { InsuranceProduct } from './InsuranceProviderContract';
import { ReportingRuleConfiguration } from './ReportingRuleConfiguration';

export interface ContractAgreedProduct {
  id: string;
  name: string;
  insuranceProduct: InsuranceProduct;
  reportingRuleConfiguration: ReportingRuleConfiguration;
  agreementProductCode: string;
  insuranceProviderProductCategory: string;
  certifyInsuranceProductCondition: boolean;
  certifyInsuranceProductConditionId: string;
  defaultReportingDetailLevel?: ReportingDetailLevel;
}

export const reportingDetailLevels = ['INSURANCE_PROVIDER_CONTRACT', 'BENEFIT_GROUP', 'INSURANCE', 'DISABLED'] as const;
export type ReportingDetailLevel = typeof reportingDetailLevels[number];
