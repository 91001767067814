import { Pill, Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { EmployeeInsuranceEventDetailsDrawerTab } from 'src/types/tabs/EmployeeInsuranceEventDetailsDrawerTab';
import EmployeeInsuranceEventAboutTab from './EmployeeInsuranceEventAboutTab';
import EmployeeInsuranceEventMessageTab from './EmployeeInsuranceEventMessageTab';
import { Employee } from 'src/types/Employee';
import './employee-insurance-event-details-drawer.scss';
import InsuranceEventCommentsTab from 'src/components/Report/Verify/InsuranceEvents/InsuranceEventsDrawer/InsuranceEventDetailsDrawer/InsuranceEventCommentsTab';
import InsuranceEventHandleTab from 'src/components/Report/Verify/InsuranceEvents/InsuranceEventsDrawer/InsuranceEventDetailsDrawer/InsuranceEventHandleTab';

interface EmployeeInsuranceEventDetailsDrawerProps {
  employee: Employee;
  insuranceEvent: InsuranceEvent;
  open: boolean;
  onClose: () => void;
}

function EmployeeInsuranceEventDetailsDrawer({
  employee,
  insuranceEvent,
  open,
  onClose,
}: EmployeeInsuranceEventDetailsDrawerProps) {
  const [currentTab, setCurrentTab] = useState<EmployeeInsuranceEventDetailsDrawerTab>(
    EmployeeInsuranceEventDetailsDrawerTab.ABOUT,
  );
  const { t } = useTranslation();

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        title={t(`reportingTypes:${insuranceEvent.reportingType}`)}
        subTitle={
          <div className="employee-insurance-event-details-drawer__title-pills">
            <Pill label={t(`benefitTypes:${insuranceEvent.benefitType}`)} />
            <Pill label={insuranceEvent.insuranceProvider?.legalName} />
          </div>
        }
        onClose={onClose}
      >
        <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
          <Tab title={t('employeeInsuranceEvents:drawer.about-tab.title')}>
            <EmployeeInsuranceEventAboutTab insuranceEvent={insuranceEvent} />
          </Tab>
          <Tab title={t('employeeInsuranceEvents:drawer.message-tab.title')}>
            <EmployeeInsuranceEventMessageTab insuranceEvent={insuranceEvent} employee={employee} />
          </Tab>
          <Tab title={t('employeeInsuranceEvents:drawer.handle-tab.title')}>
            <InsuranceEventHandleTab
              insuranceEvent={insuranceEvent}
              employerId={employee.employerId}
              onBackClick={() => setCurrentTab(EmployeeInsuranceEventDetailsDrawerTab.ABOUT)}
            />
          </Tab>
          <Tab title={t('employeeInsuranceEvents:drawer.comments-tab.title')}>
            <InsuranceEventCommentsTab insuranceEvent={insuranceEvent} />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
}

export default EmployeeInsuranceEventDetailsDrawer;
