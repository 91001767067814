import { gql } from '@apollo/client';

export const GET_INSURANCE_PRODUCTS = gql`
  query GetInsuranceProducts($insuranceProviderId: UUID, $pageNumber: Int, $pageSize: Int) {
    insuranceProducts(insuranceProviderId: $insuranceProviderId, pageNumber: $pageNumber, pageSize: $pageSize) {
      paging {
        hasNext
        totalNumberOfElements
      }
      insuranceProducts {
        id
        name
        status
        insuranceProvider {
          id
          legalName
          organisationNumber
        }
      }
    }
  }
`;
