import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Icon, InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import AddAgreedProduct from './AddAgreedProduct';
import { AgreedProduct as PolicyAgreedProduct } from 'src/types/contract/AgreedProduct';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDER_NAME } from 'src/graphql/schema/query/insurance/GetInsuranceProviderName';
import AgreedProductData from './AgreedProductData';
import { GET_CONTRACT_AGREED_PRODUCT } from 'src/graphql/schema/query/contract/GetContractAgreedProduct';
import AgreedProductButtons from './AgreedProductButtons';
import { BenefitAgreedProductRequest } from 'src/types/policy/request/BenefitAgreedProductRequest';
import { useParams } from 'react-router-dom';
import { useDeleteBenefitAgreedProduct } from 'src/service/policy/DeleteBenefitAgreedProduct';
import { ContractAgreedProduct } from 'src/types/contract/ContractAgreedProduct';
import { PolicyBenefitDrawerTab } from 'src/types/tabs/PolicyBenefitDrawerTab';

interface AgreedProductDetailsProps {
  policyAgreedProduct?: PolicyAgreedProduct;
  policyBenefit: PolicyBenefit;
  policyState: PolicyState;
  onClose: () => void;
  navigateToTab: (tab: PolicyBenefitDrawerTab) => void;
  employerId: string;
}

export const AgreedProductDetails: FC<AgreedProductDetailsProps> = ({
  policyAgreedProduct,
  policyBenefit,
  policyState,
  onClose,
  navigateToTab,
  employerId,
}) => {
  const { t } = useTranslation();
  const { policyId } = useParams();
  const isAddingNewProduct = policyAgreedProduct === undefined;
  const inDraft = policyState === 'DRAFT';
  const deleteBenefitAgreedProduct = useDeleteBenefitAgreedProduct();

  const onBackClick = () => {
    navigateToTab(PolicyBenefitDrawerTab.AGREED_PRODUCTS);
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      {policyAgreedProduct && (
        <>
          <div className="drawer-base__title-container">
            <QueryWrapper
              query={GET_INSURANCE_PROVIDER_NAME}
              options={{ variables: { insuranceProviderId: policyAgreedProduct.insuranceProviderId } }}
            >
              {(insuranceProvider: InsuranceProvider) => {
                return (
                  <Typography variant="h3" component="h3" bold>
                    {insuranceProvider.legalName}
                  </Typography>
                );
              }}
            </QueryWrapper>
            <div className="sub-title-pill">
              <Pill label={t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)} />
              <Typography variant="body2">{policyBenefit.name}</Typography>
            </div>
          </div>
          <div className="policy-benefit-drawer__tab">
            <Typography variant="body2">{t('account:employers-tab.policy-settings.table.drawer.subtitle')}</Typography>
            <ListGroup variant="drawer-navigation" className="policy-benefit-drawer__tab__table">
              <InlineEdit bold label={t('account:employers-tab.policy-settings.table.drawer.agreed-product')}>
                <Typography variant="body2">{policyAgreedProduct.name}</Typography>
              </InlineEdit>
            </ListGroup>
            {
              <QueryWrapper
                query={GET_CONTRACT_AGREED_PRODUCT}
                options={{ variables: { agreedProductId: policyAgreedProduct.id } }}
              >
                {(agreedProduct: ContractAgreedProduct) => {
                  const handleRemoveAgreedProduct = () => {
                    const request: BenefitAgreedProductRequest = {
                      contractAgreedProductId: agreedProduct.id,
                      policyId: policyId,
                      benefitId: policyBenefit.id,
                    };
                    deleteBenefitAgreedProduct(request, employerId);
                    onBackClick();
                  };
                  return (
                    <>
                      <AgreedProductData
                        agreementNumber={policyAgreedProduct.agreementNumber}
                        agreedProduct={agreedProduct}
                      />
                      <AgreedProductButtons inDraft={inDraft} onRemove={handleRemoveAgreedProduct} />
                    </>
                  );
                }}
              </QueryWrapper>
            }
          </div>
        </>
      )}
      {isAddingNewProduct && <AddAgreedProduct policyBenefit={policyBenefit} />}
    </Drawer>
  );
};
export default AgreedProductDetails;
