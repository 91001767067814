import { Button, Drawer, Icon, Message, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import React from 'react';
import When from 'src/components/Common/When';

export interface CommonPglCalculationsDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  pglRuleName: string;
  descriptions?: string[];
  title?: string;
}

const CommonPglCalculationsDrawer = ({
  onClose,
  onBackClick,
  pglRuleName,
  children,
  title,
  descriptions = [],
}: CommonPglCalculationsDrawerProps & {
  children: JSX.Element | JSX.Element[];
}) => {
  const { t } = useTranslation(['common']);
  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
      className="pgl-calculations-details-drawer"
    >
      <div className="drawer-base__title-container">
        <Typography variant="h3" component="h3" bold>
          {title ? title : t('common:term')}
        </Typography>
        <Typography variant="body2" component="p">
          {pglRuleName}
        </Typography>
        <When condition={!!descriptions.length}>
          <div className={styles.description}>
            {descriptions.map((desc) => (
              <Message key={desc} message={desc} type={'info'} />
            ))}
          </div>
        </When>
      </div>
      <div className="pgl-rule-drawer">{children}</div>
    </Drawer>
  );
};

export default CommonPglCalculationsDrawer;
