import { useTranslation } from 'react-i18next';
import PageBase from '../../components/PageBase';
import React from 'react';
import useTabParams from 'src/components/hooks/useTabParams';
import { Tab, Tabs } from 'fgirot-k2-ui-components';
import { PermissionTab } from 'src/types/tabs/PermissionTab';
import UsersTab from 'src/components/Permission/UsersTab';
import GroupsTab from 'src/components/Permission/GroupsTab';

const Permission = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useTabParams('tab', PermissionTab.USERS);
  return (
    <PageBase title={t('permission:title')} header={true}>
      <Tabs className="permission" tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('permission:tabs.groups-label')}>
          <GroupsTab />
        </Tab>
        <Tab title={t('permission:tabs.users-label')}>
          <UsersTab />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default Permission;
