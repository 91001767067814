import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { Employee } from 'src/types/Employee';
import EmploymentDetailsOverviewTab from './EmploymentDetailsOverviewTab/EmploymentDetailsOverviewTab';
import { EmploymentDetailsDrawerTab } from 'src/types/tabs/EmploymentDetailsDrawerTab';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import AccountingComponentsOverviewTab from './AccountingComponentsOverviewTab';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';

interface EmploymentDetailsDrawerProps {
  open: boolean;
  onClose: () => void;
  employee: Employee;
  employeeCard: AggregatedEmployeeCard;
  activeEmployeeCard?: AggregatedEmployeeCard;
  activeEmployee?: Employee;
}

const EmploymentDetailsDrawer: FC<EmploymentDetailsDrawerProps> = ({
  open,
  onClose,
  employee,
  employeeCard,
  activeEmployee,
  activeEmployeeCard,
}) => {
  const [currentTab, setCurrentTab] = useState<EmploymentDetailsDrawerTab>(EmploymentDetailsDrawerTab.ABOUT);
  const { t } = useTranslation();
  const fullName = formatEmployeeFullName(employee);

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase title={t('employees:employment')} subTitle={fullName} onClose={onClose}>
        <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
          <Tab title={t('common:about')}>
            <EmploymentDetailsOverviewTab
              employeeCard={employeeCard}
              employee={employee}
              activeEmployee={activeEmployee}
              activeEmployeeCard={activeEmployeeCard}
            />
          </Tab>
          <Tab title={t('employees:employee-accounting-components-drawer-tab.accounting-components')}>
            <AccountingComponentsOverviewTab employeeCard={employeeCard} />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
};

export default EmploymentDetailsDrawer;
