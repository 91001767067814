import { gql } from '@apollo/client';

export const GET_UNPAID_INVOICES = gql`
  query GetUnpaidInvoices {
    unpaidInvoices {
      id
      invoiceTypeCode
      invoiceNumber
      ocrNumber
      invoiceStatus
      insuranceProvider {
        id
        organisationNumber
        legalName
      }
      accountNumberTo
      paymentDueDate
      remainingAmount
    }
  }
`;
