import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { globalAbsenceWageTypes } from 'src/util/WageUtil';

export const filterAbsenceWageTypes = (aggregatedEmployeeCards: AggregatedEmployeeCard[]) => {
  if (!aggregatedEmployeeCards) return [];

  return aggregatedEmployeeCards
    .map((employeeCard) => {
      const newWageTypes = employeeCard.aggregatedWageTypeWages.filter((wageType) => {
        return wageType.wages.some((wage) => globalAbsenceWageTypes.includes(wage.wageType));
      });
      if (newWageTypes.length === 0) {
        return null;
      }
      return { ...employeeCard, aggregatedWageTypeWages: newWageTypes } as AggregatedEmployeeCard;
    })
    .filter((employeeCard) => employeeCard !== null);
};
