export interface Role {
  id: string;
  customerId: string;
  name: string;
  permissions: Permission[];
}

export interface Permission {
  value: string;
}

export interface GetRoles {
  roles: Role[];
}

export const EXTERNAL_ADMIN_ROLE_NAME = 'EXTERNAL_ADMIN';
