import { Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ModuleCard from 'src/components/Common/ModuleCard';
import PageBase from 'src/components/PageBase';
import './employer-settings.scss';
import { useNavigateWithFallback } from 'src/service/routes/LocationHooks';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { Employer } from 'src/types/employer/Employer';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';

const EmployerSettings = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { customerName, employerId } = useParams();
  const handleBackClick = useNavigateWithFallback(-1, '/');

  const breadCrumbs = [
    {
      name: customerName,
      steps: 1,
    },
  ];

  return (
    <QueryWrapper
      query={GET_EMPLOYER}
      options={{
        variables: { employerId },
        skip: !employerId,
      }}
    >
      {(employer: Employer) => (
        <PageBase title={employer.legalName} handleBackButton={handleBackClick} breadCrumbs={breadCrumbs}>
          <div className="employer-settings--settings-container">
            <Typography
              className="employer-settings--settings-container__typography"
              component="h2"
              variant="body2"
              bold
            >
              {t('account:employers-tab.reporting-settings.settings')}
            </Typography>
            <div className="employer-settings--settings-container--card-container">
              <ModuleCard
                key="employer-settings--settings-container--policy"
                title={t('account:employers-tab.policy-settings.policy')}
                iconType="Stats"
                dataCy="policy-settings-card"
                onClick={() => navigate(`/account/${customerName}/${employer.id}/policy-settings`)}
              />
              <ModuleCard
                key="employer-settings--settings-container--wage-mapping"
                title={t('account:employers-tab.wage-type-mapping-settings.wage-type-mapping')}
                iconType="Stats"
                dataCy="wage-type-mapping-settings-card"
                onClick={() => navigate(`/account/${customerName}/${employer.id}/wage-type-mapping-settings`)}
              />
              <ModuleCard
                key="employer-settings--settings-container--reporting"
                title={t('account:employers-tab.report')}
                iconType="Stats"
                dataCy="reporting-settings-card"
                onClick={() => navigate(`/account/${customerName}/${employer.id}/reporting-settings`)}
              />
              <ModuleCard
                key="employer-settings--settings-container--agreement-registry"
                title={t('account:employers-tab.agreement-registry')}
                iconType="Stats"
                dataCy="agreement-registry-settings-card"
                onClick={() => navigate(`/account/${customerName}/${employer.id}/agreement-registry-settings`)}
              />
              <ModuleCard
                key="employer-settings--settings-container--economy"
                title={t('account:employers-tab.economy')}
                iconType="Stats"
                dataCy="economy-settings-card"
                onClick={() => navigate(`/account/${customerName}/${employer.id}/economy-settings`)}
              />
            </div>
          </div>
        </PageBase>
      )}
    </QueryWrapper>
  );
};

export default EmployerSettings;
