import { Loader } from 'fgirot-k2-ui-components';
import React from 'react';
import './loading-overlay.scss';

interface LoadingOverlayProps {
  loading: boolean;
  children?: React.ReactNode;
}

function LoadingOverlay({ loading: loading, children }: LoadingOverlayProps) {
  if (!loading) {
    return null;
  }

  return (
    <div className="loading-overlay">
      <Loader type="blue" size="medium" />
      {children}
    </div>
  );
}

export default LoadingOverlay;
