import { SortDirection, TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useMemo, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import WageTypeTableRow from './WageTypeTableRow/WageTypeTableRow';
import { useTranslation } from 'react-i18next';
import { Option } from 'fgirot-k2-ui-components';
import { isAbsenceWageType } from 'src/util/WageUtil';
import FilterComponent from 'src/components/Common/FilterComponent';

interface WageTypeTableProps {
  employerWageTypes: EmployerWageType[];
  onSelectWageType: (id: string) => void;
}
type SortField = keyof Pick<EmployerWageType, 'name' | 'wageType' | 'negateIncomingAmount' | 'extentCalculation'>;
function WageTypeTable({ employerWageTypes, onSelectWageType }: WageTypeTableProps) {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<SortField>();
  const [sortDirection, setSortDirection] = useState<'UP' | 'DOWN'>('DOWN');
  const [employerWageTypeFilter, setEmployerWageTypeFilter] = useState('');
  const [selectedWageTypes, setSelectedWageTypes] = useState<Option<string>[]>([]);

  const wageTypeFilterProps = {
    placeholder: t('account:employers-tab.wage-type-mapping-settings.all-global-wage-types'),
    options: employerWageTypes.map((employerWageType) => ({
      label: t(`wageTypes:${employerWageType.wageType}`),
      value: employerWageType.wageType,
    })),
    selected: selectedWageTypes,
    onChange: (input: Option<string>[]): void => {
      setSelectedWageTypes([...input]);
    },
  };

  const handleEmployerWageTypeFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmployerWageTypeFilter(event.target.value);
  };

  const sortEmployerWageTypeNames = (a: EmployerWageType, b: EmployerWageType) => {
    const wageTypeA = a.name;
    const wageTypeB = b.name;

    return sortDirection === 'UP'
      ? wageTypeB.toLocaleLowerCase().localeCompare(wageTypeA.toLocaleLowerCase())
      : wageTypeA.toLocaleLowerCase().localeCompare(wageTypeB.toLocaleLowerCase());
  };

  const sortWageTypes = (a: EmployerWageType, b: EmployerWageType) => {
    const wageTypeA = t(`wageTypes:${a.wageType}`);
    const wageTypeB = t(`wageTypes:${b.wageType}`);

    return sortDirection === 'UP'
      ? wageTypeB.toLocaleLowerCase().localeCompare(wageTypeA.toLocaleLowerCase())
      : wageTypeA.toLocaleLowerCase().localeCompare(wageTypeB.toLocaleLowerCase());
  };

  const sortExtentCalculation = (a: EmployerWageType, b: EmployerWageType) => {
    const extentCalculationA = isAbsenceWageType(a.wageType) ? t(`extentCalculation:${a.extentCalculation}`) : '-';
    const extentCalculationB = isAbsenceWageType(b.wageType) ? t(`extentCalculation:${b.extentCalculation}`) : '-';

    return sortDirection === 'UP'
      ? extentCalculationB.toLocaleLowerCase().localeCompare(extentCalculationA.toLocaleLowerCase())
      : extentCalculationA.toLocaleLowerCase().localeCompare(extentCalculationB.toLocaleLowerCase());
  };
  const handleSort = (direction: SortDirection, sortField: SortField) => {
    setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
    setSortField(sortField);
  };

  const tableHeads = [
    {
      title: t('account:employers-tab.wage-type-mapping-settings.wage-type'),
      sort: (direction: SortDirection) => handleSort(direction, 'name'),
    },
    {
      title: t('account:employers-tab.wage-type-mapping-settings.global-wage-type'),
      sort: (direction: SortDirection) => handleSort(direction, 'wageType'),
    },
    {
      title: t('account:employers-tab.wage-type-mapping-settings.extent-calculation'),
      sort: (direction: SortDirection) => handleSort(direction, 'extentCalculation'),
    },
    {
      title: t('account:employers-tab.wage-type-mapping-settings.negate-incoming-amount'),
      sort: (direction: SortDirection) => handleSort(direction, 'negateIncomingAmount'),
    },
  ];

  const sortEmployerWageTypes = (wageTypes: EmployerWageType[]) => {
    switch (sortField) {
      case 'name':
        return wageTypes.sort(sortEmployerWageTypeNames);
      case 'wageType':
        return wageTypes.sort(sortWageTypes);
      case 'extentCalculation':
        return wageTypes.sort(sortExtentCalculation);
      case 'negateIncomingAmount':
        return wageTypes.sort((a, b) =>
          sortDirection === 'UP'
            ? Number(a.negateIncomingAmount) - Number(b.negateIncomingAmount)
            : Number(b.negateIncomingAmount) - Number(a.negateIncomingAmount),
        );
      default:
        return wageTypes;
    }
  };

  const filterEmployerWageType = (employerWageType: EmployerWageType, searchFilter: string): boolean => {
    return !!(
      employerWageType.name.match(searchFilter.toLowerCase()) ||
      employerWageType.name.toLowerCase().match(searchFilter.toLowerCase())
    );
  };

  const filterWageType = (employerWageType: EmployerWageType): boolean => {
    return selectedWageTypes.length > 0
      ? selectedWageTypes.some((selectedPayType) => selectedPayType.value === employerWageType.wageType)
      : true;
  };

  const filteredEmployerWageTypes = employerWageTypes
    .filter((wageType) => filterEmployerWageType(wageType, employerWageTypeFilter))
    .filter((wageType) => filterWageType(wageType));
  const sortedEmployerWageTypes = useMemo(
    () => sortEmployerWageTypes(filteredEmployerWageTypes),
    [sortField, sortDirection, employerWageTypes],
  );

  return (
    <div className="wage-type-table">
      <FilterComponent
        showSearch
        searchPlaceholder={t('account:employers-tab.wage-type-mapping-settings.search-wage-type')}
        searchFilter={employerWageTypeFilter}
        onSearchFilterChange={handleEmployerWageTypeFilterChange}
        selectProps={[wageTypeFilterProps]}
      ></FilterComponent>
      <TableBase maxHeightPercentage={55}>
        <TableHead filled>
          <TableRow>
            {tableHeads.map((head) => (
              <Th
                key={`wage-type-table-${head.title}`}
                includeSortButtons={head?.sort !== undefined}
                handleSortDirectionChange={head.sort}
              >
                {head.title}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedEmployerWageTypes.map((wageType) => (
            <WageTypeTableRow key={wageType.id} wageType={wageType} onSelectWageType={onSelectWageType} />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
}

export default WageTypeTable;
