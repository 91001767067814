import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import UpdateInput from 'src/components/Common/UpdateInput';
import { EmployeeCard } from 'src/types/EmployeeCard';
import './update-gross-salary-info.scss';
import { useUpdateEmployeeCardWage } from 'src/service/employeecard/UpdateEmployeeCardWage';
import { useUpdateEmployeeCard } from 'src/service/employeecard/UpdateEmployeeCard';

interface UpdateGrossSalaryInfoProps {
  employeeCard: EmployeeCard;
  employerId: string;
}

const UpdateGrossSalaryInfo = ({ employeeCard, employerId }: UpdateGrossSalaryInfoProps) => {
  const { t } = useTranslation();
  const wage = employeeCard.wages.find((wage) => wage.wageType == 'GROSS_SALARY');
  const updateEmployeeCardWage = useUpdateEmployeeCardWage();
  const updateEmployeeCard = useUpdateEmployeeCard();
  const [loading, setLoading] = useState<boolean>(false);

  const handleEmployeeCardWageUpdate = (field: 'grossSalaryFromDate' | 'amount', value: string) => {
    const request = {
      id: employeeCard.id,
      fieldKey: field,
      fieldValue: value,
    };

    setLoading(true);
    if (field == 'grossSalaryFromDate') {
      updateEmployeeCard(request, employerId).finally(() => setLoading(false));
    } else {
      updateEmployeeCardWage({ ...request, wageId: wage.id }, employerId).finally(() => setLoading(false));
    }
  };

  return (
    <ListGroup variant="inline-edit">
      <InlineEdit bold label={t(`examination:violation-types.amount`)}>
        <UpdateInput
          type="number"
          fieldKey="amount"
          fieldValue={wage.amount.toString()}
          onBlur={handleEmployeeCardWageUpdate}
          validationKey="amount"
          disabled={loading}
        />
      </InlineEdit>

      <InlineEdit bold label={t(`examination:violation-types.fromDate`)}>
        <UpdateDatePicker
          fieldKey="grossSalaryFromDate"
          onBlur={handleEmployeeCardWageUpdate}
          fieldValue={employeeCard.grossSalaryFromDate}
          disabled={loading}
        />
      </InlineEdit>
    </ListGroup>
  );
};

export default UpdateGrossSalaryInfo;
