import { gql } from '@apollo/client';

export const REMOVE_EMPLOYER_FROM_GROUP = gql`
  mutation RemoveEmployerFromGroup($groupId: UUID!, $employerId: UUID!) {
    removeEmployerFromGroup(groupId: $groupId, employerId: $employerId) {
      groupId
      employerId
    }
  }
`;
