import { Banner } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import ModuleCard from 'src/components/Common/ModuleCard';
import CreateAccountModal from '../CreateAccountModal';

const ActiveAccountCardNotFound = () => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <>
      <ModuleCard
        className="payment-settings-tab__module-card"
        title={t('economySettings:payment-settings-tab.module-card.payment-title')}
        iconType="Stats"
        dataCy="payment-settings-tab__inactive-account-card"
      >
        <Banner
          type="announcement"
          message={t('economySettings:payment-settings-tab.module-card.banner-announcement')}
        />
      </ModuleCard>
      <FloatingAddButton
        data-cy="add-account__button"
        label={t('economySettings:payment-settings-tab.add-account-button')}
        onClick={toggleModal}
      />
      <CreateAccountModal open={modalOpen} onClose={toggleModal} />
    </>
  );
};

export default ActiveAccountCardNotFound;
