import DrawerModal from 'src/components/Common/DrawerModal/DrawerModal';
import InsuranceEventsOverviewDrawer from './InsuranceEventsOverviewDrawer/InsuranceEventsOverviewDrawer';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import InsuranceEventDetailDrawer from './InsuranceEventDetailsDrawer/InsuranceEventDetailsDrawer';
import { PersonInsuranceEventsResponse } from 'src/types/reporting/PersonInsuranceEventsResponse';
import { Employer } from 'src/types/employer/Employer';

interface InsuranceEventsDrawerProps {
  person: PersonInsuranceEventsResponse;
  onClose: () => void;
  open: boolean;
  selectedInsuranceEvent?: InsuranceEvent;
  onSelectEvent: (event: InsuranceEvent) => void;
  onBackClick: () => void;
  employers: Employer[];
}

const InsuranceEventsDrawer = ({
  person,
  onClose,
  open,
  selectedInsuranceEvent,
  onSelectEvent,
  onBackClick,
  employers,
}: InsuranceEventsDrawerProps) => {
  return (
    <DrawerModal open={open} onClose={onClose} overlayBackground overlayOnClose>
      {!selectedInsuranceEvent && (
        <InsuranceEventsOverviewDrawer
          person={person}
          onClose={onClose}
          onSelectEvent={onSelectEvent}
          employers={employers}
        />
      )}
      {selectedInsuranceEvent && (
        <InsuranceEventDetailDrawer
          onBackClick={onBackClick}
          insuranceEvent={selectedInsuranceEvent}
          employee={person.employees.find((employee) => employee.id === selectedInsuranceEvent.employeeId)}
          onClose={onClose}
        />
      )}
    </DrawerModal>
  );
};

export default InsuranceEventsDrawer;
