import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmployerKubTable from './KubTable';
import CreateKubModal from './CreateKubModal';
import { useEmployerKubs } from 'src/service/employer/GetEmployerKubs';
import KubDrawer from './KubDrawer';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import FloatingAddButton from 'src/components/Common/FloatingAddButton/FloatingAddButton';

interface KubTabProps {
  employerId: string;
}

const KubTab: FC<KubTabProps> = ({ employerId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const employerKubs = useEmployerKubs(employerId);
  const [selectedEmployerKub, setSelectedEmployerKub] = useState(null);
  const { t } = useTranslation();

  const handleOpenCreateKubModal = () => {
    setModalOpen(true);
  };

  const handleCloseCreateKubModal = () => {
    setModalOpen(false);
  };

  const handleOpenEmployerKubDrawer = (employerKub: EmployerKub) => {
    setSelectedEmployerKub(employerKub);
  };

  const handleCloseKubDrawer = () => {
    setSelectedEmployerKub(null);
  };

  return (
    <>
      <EmployerKubTable employerKubs={employerKubs} handleOpenEmployerKubDrawer={handleOpenEmployerKubDrawer} />
      {selectedEmployerKub && (
        <KubDrawer
          open={setSelectedEmployerKub !== null}
          onClose={handleCloseKubDrawer}
          employerKub={selectedEmployerKub}
        />
      )}
      <CreateKubModal open={modalOpen} employerId={employerId} onClose={handleCloseCreateKubModal} />

      <FloatingAddButton
        data-cy="add-kub-button"
        label={t('account:employers-tab.wage-type-mapping-settings.add-kub')}
        onClick={handleOpenCreateKubModal}
      />
    </>
  );
};

export default KubTab;
