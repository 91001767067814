import { AccountingComponent } from 'src/types/accountingunit/AccountingComponent';

const hasGoodShare = (accountingComponents: AccountingComponent[]) => {
  if (!accountingComponents || accountingComponents.length === 0) {
    return true;
  }
  const totalShare = accountingComponents.map((component) => component.share).reduce((a, b) => a + b, 0);
  return totalShare === 100;
};

export const recursiveHasGoodShare = (accountingComponents: AccountingComponent[]) => {
  if (accountingComponents.length === 0) {
    return true;
  }

  const currentLevelHasGoodShare = hasGoodShare(accountingComponents);
  const childLevelsHaveGoodShare = accountingComponents
    .map((component) => recursiveHasGoodShare(component.accountingComponents))
    .every((hasGood) => hasGood);

  return currentLevelHasGoodShare && childLevelsHaveGoodShare;
};
