import React from 'react';
import { DRAFT, PolicyEntity } from 'src/types/policy/PolicyEntity';
import { PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';
import PolicyStateDefault from './PolicyStateDefault';
import PolicyStateDraft from './PolicyStateDraft';

interface PolicyStateProps {
  selectedPolicyEntity: PolicyEntity;
  changes: PolicyVersionChanges;
}

const PolicyState: React.FunctionComponent<PolicyStateProps> = ({ selectedPolicyEntity, changes }) => {
  if (selectedPolicyEntity.state === DRAFT) {
    return <PolicyStateDraft changes={changes} />;
  } else {
    return <PolicyStateDefault selectedPolicyEntity={selectedPolicyEntity} />;
  }
};

export default PolicyState;
