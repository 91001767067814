import { SortDirection, TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { PostingRule } from 'src/types/accountingunit/PostingRule';
import { sortAlphabeticalAsc, sortAlphabeticalDesc } from 'src/util/SortUtil';
import InsuranceTypeMappingTableRow from './InsuranceTypeMappingTableRow';
import { InsuranceTypeMapping } from 'src/types/insurancetypemapping/InsuranceTypeMapping';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { POSTING_RULES } from 'src/graphql/schema/query/accountingunit/PostingRules';

interface InsuranceTypeMappingsTableProps {
  insuranceTypeMappings: InsuranceTypeMapping[];
  chartOfAccountsId: string;
}

const InsuranceTypeMappingsTable: FC<InsuranceTypeMappingsTableProps> = ({
  insuranceTypeMappings,
  chartOfAccountsId,
}) => {
  const [sortDirection, setSortDirection] = useState<SortDirection>('DOWN');
  const [sortField, setSortField] = useState<
    | 'insuranceProvider'
    | 'insuranceTypeName'
    | 'invoiceRowName'
    | 'benefitType'
    | 'insuranceCategory'
    | 'taxRule'
    | 'premiumSource'
  >('insuranceProvider');

  const tableHeads = [
    {
      title: t('economySettings:insurance-type-mappings-tab.table.head.insurance-provider'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceProvider');
      },
      fieldName: 'insuranceProvider',
    },
    {
      title: t('economySettings:insurance-type-mappings-tab.table.head.insurance-type-name'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceTypeName');
      },
      fieldName: 'insuranceTypeName',
    },
    {
      title: t('economySettings:insurance-type-mappings-tab.table.head.invoice-row-name'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('invoiceRowName');
      },
      fieldName: 'invoiceRowName',
    },
    {
      title: t('economySettings:insurance-type-mappings-tab.table.head.benefit-type'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('benefitType');
      },
      fieldName: 'benefitType',
    },
    {
      title: t('economySettings:insurance-type-mappings-tab.table.head.insurance-category'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceCategory');
      },
      fieldName: 'insuranceCategory',
    },
    {
      title: t('economySettings:insurance-type-mappings-tab.table.head.tax-rule'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('taxRule');
      },
      fieldName: 'taxRule',
    },
    {
      title: t('economySettings:insurance-type-mappings-tab.table.head.premium-source'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('premiumSource');
      },
      fieldName: 'premiumSource',
    },
  ];

  const sortInsuranceTypeMappings = (a: InsuranceTypeMapping, b: InsuranceTypeMapping) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    switch (sortField) {
      case 'insuranceProvider':
        return sortAlphabetical(a.insuranceProvider.legalName, b.insuranceProvider.legalName);
      case 'insuranceTypeName':
        return sortAlphabetical(a.name, b.name);
      // add case for field invoiceRowName
      case 'benefitType':
        return sortAlphabetical(a.benefitType, b.benefitType);
      case 'insuranceCategory':
        return sortAlphabetical(a.insuranceCategory, b.insuranceCategory);
      case 'taxRule':
        return sortAlphabetical(a.taxRule, b.taxRule);
      case 'premiumSource':
        return sortAlphabetical(a.premiumSource, b.premiumSource);
      default:
        return undefined;
    }
  };

  return (
    <QueryWrapper query={POSTING_RULES} options={{ variables: { chartOfAccountsId } }}>
      {(postingRules: PostingRule[]) => (
        <TableBase>
          <TableHead>
            <TableRow>
              {tableHeads.map((head) => (
                <Th
                  includeSortButtons={head?.sort !== undefined}
                  handleSortDirectionChange={head.sort}
                  key={`InsuranceTypeMappingsTableHead-${head.title}`}
                  sortDirection={head.fieldName === sortField ? sortDirection : null}
                >
                  {head.title}
                </Th>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {insuranceTypeMappings
              .filter(
                (insuranceTypeMapping) =>
                  !postingRules.some((rule) => rule.insuranceTypeMapping.id === insuranceTypeMapping.id),
              )
              .sort(sortInsuranceTypeMappings)
              .map((insuranceTypeMapping) => {
                return (
                  <InsuranceTypeMappingTableRow
                    key={`InsuranceTypeMappingTableRow-${insuranceTypeMapping.id}`}
                    insuranceTypeMapping={insuranceTypeMapping}
                    chartOfAccountsId={chartOfAccountsId}
                  />
                );
              })}
          </TableBody>
        </TableBase>
      )}
    </QueryWrapper>
  );
};
export default InsuranceTypeMappingsTable;
