import { Button, InlineEdit, ListGroup, Switch, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import { fetchInsuranceProductCondition } from 'src/service/insurance/GetInsuranceProductCondition';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { isCheckpointStatusMatching } from 'src/util/CheckpointUtil';
import './about-supplier-table.scss';

interface AboutSupplierTableProps {
  checkpoint: InsuranceEventCheckpoint;
  employerId: string;
}
const AboutSupplierTable = ({ checkpoint, employerId }: AboutSupplierTableProps) => {
  const { t } = useTranslation();
  const handleOnChange = () => {
    checkpoint.checkpointStatus === 'DECLINED'
      ? updateCheckpointStatusMutation([checkpoint.id], 'RAISED', employerId)
      : updateCheckpointStatusMutation([checkpoint.id], 'DECLINED', employerId);
  };

  const handleConditionLinkClick = () => {
    fetchInsuranceProductCondition(checkpoint.additionalData.insuranceProviderProductConditionId).then((data) => {
      window.open(data.documentUrl, '_blank', 'noopener noreferrer');
    });
  };

  return (
    <div className="about-supplier-table">
      <ListGroup variant="drawer-navigation">
        <InlineEdit bold label={t('common:conditions')}>
          <Button label="Länk till villkor" type="link" onClick={handleConditionLinkClick} />
        </InlineEdit>
        <InlineEdit bold label={t('certify:drawer.dont-meet-requirements')}>
          <Switch toggled={isCheckpointStatusMatching(checkpoint, 'DECLINED')} onChange={handleOnChange} />
        </InlineEdit>
      </ListGroup>
      <div className="about-supplier-table__info">
        <Typography className="about-supplier-table__info__text" variant="caption">
          {t('certify:drawer.subtitle-check')}
        </Typography>
      </div>
    </div>
  );
};

export default AboutSupplierTable;
