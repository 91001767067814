export type AccountingAdjustmentActionType =
  | 'ACCOUNTING_COMPONENT_VALUE'
  | 'ACCOUNTING_COMPONENT_VALUE_MISSING'
  | 'ACCOUNTING_UNIT';

export const accountingAdjustmentActionTypes: AccountingAdjustmentActionType[] = [
  'ACCOUNTING_COMPONENT_VALUE',
  'ACCOUNTING_COMPONENT_VALUE_MISSING',
  'ACCOUNTING_UNIT',
];
