import { Pill } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';
import { getSumChanges } from 'src/util/PolicyUtil';

interface PolicyStateProps {
  changes: PolicyVersionChanges;
}
const PolicyStateDraft: React.FunctionComponent<PolicyStateProps> = ({ changes }) => {
  const { t } = useTranslation('account');
  return (
    <div className="policy-version-header__version-info">
      <Pill
        type="gray"
        className="policy-version-header__version-info--pill"
        label={t('account:employers-tab.policy-settings.policy-version-header.draft')}
      />
      {changes && (
        <Pill
          className="policy-version-header__version-info--pill"
          type="heads-up"
          label={`${getSumChanges(changes)} ${t(
            'account:employers-tab.policy-settings.policy-version-header.changes',
          )}`}
        />
      )}
    </div>
  );
};
export default PolicyStateDraft;
