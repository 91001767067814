import { Button, InlineEdit, ListGroup, Switch, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import { UpdateEmployerIndividualChoiceRequest } from 'src/types/employer/request/UpdateEmployerIndividualChoiceRequest';
import './employer-individual-choice-about.tab.scss';
import { useUpdateEmployerIndividualChoice } from 'src/service/employer/UpdateEmployerIndividualChoice';
import InfoMessage from 'src/components/Common/InfoMessage';

interface EmployerIndividualChoiceAboutTabProps {
  employerId: string;
  employerIndividualChoice: EmployerIndividualChoice;
}

function EmployerIndividualChoiceAboutTab({
  employerId,
  employerIndividualChoice,
}: EmployerIndividualChoiceAboutTabProps) {
  const [updateIndividualChoiceRequest, setUpdateIndividualChoiceRequest] =
    React.useState<UpdateEmployerIndividualChoiceRequest>({
      employerId,
      individualChoiceId: employerIndividualChoice.id,
      fromWageFile: employerIndividualChoice.fromWageFile,
      fromInvoice: employerIndividualChoice.fromInvoice,
    });

  const updateEmployerIndividualChoice = useUpdateEmployerIndividualChoice();

  const { t } = useTranslation();

  const saveButtonDisabled =
    updateIndividualChoiceRequest.fromWageFile === employerIndividualChoice.fromWageFile &&
    updateIndividualChoiceRequest.fromInvoice === employerIndividualChoice.fromInvoice;

  const handleToggle = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateIndividualChoiceRequest({
      ...updateIndividualChoiceRequest,
      [field]: event.target.checked,
    });
  };

  const handleUpdate = async () => {
    await updateEmployerIndividualChoice(updateIndividualChoiceRequest);
  };

  return (
    <div className="employer-individual-choice-about-tab">
      <ListGroup variant="inline-edit">
        <InlineEdit
          label={t('account:employers-tab.reporting-settings.individual-choices-tab.individual-choice-type')}
          bold
        >
          <Typography variant="body2">{t(`individualChoiceTypes:${employerIndividualChoice.type}`)}</Typography>
        </InlineEdit>
        <InlineEdit label={t('account:employers-tab.reporting-settings.individual-choices-tab.from-wage-file')} bold>
          <Switch
            toggled={updateIndividualChoiceRequest.fromWageFile}
            onChange={(e) => handleToggle('fromWageFile', e)}
          />
        </InlineEdit>
        <InlineEdit label={t('account:employers-tab.reporting-settings.individual-choices-tab.from-invoice')} bold>
          <Switch
            toggled={updateIndividualChoiceRequest.fromInvoice}
            onChange={(e) => handleToggle('fromInvoice', e)}
          />
        </InlineEdit>
      </ListGroup>
      <InfoMessage
        message={t(
          'account:employers-tab.reporting-settings.individual-choices-tab.add-employer-individual-choice-info',
        )}
      />
      <Button type="primary" onClick={handleUpdate} label={t('common:save-changes')} disabled={saveButtonDisabled} />
    </div>
  );
}

export default EmployerIndividualChoiceAboutTab;
