import { gql } from '@apollo/client';

export const ADD_EMPLOYER_TO_GROUP = gql`
  mutation AddEmployerToGroup($groupId: UUID!, $employerId: UUID!) {
    addEmployerToGroup(groupId: $groupId, employerId: $employerId) {
      groupId
      employerId
    }
  }
`;
