import { ListGroup, DrawerNavigation, Typography, Pill, Tooltip, Icon } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { useMonths } from 'src/util/date/Months';
import './yearly-absence-overview.scss';

interface YearlyAbsenceOverviewProps {
  aggregatedAbsenceEmployeeCards: AggregatedEmployeeCard[];
  period: string;
  onClick: (month: number) => void;
}

const YearlyAbsenceOverview = ({ aggregatedAbsenceEmployeeCards, period, onClick }: YearlyAbsenceOverviewProps) => {
  const { t } = useTranslation();
  const months = useMonths();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear().toString();
  const yearFromPeriod = period.slice(0, 4);
  const monthFromPeriod = +period.slice(4, 6);

  const isCurrentYearSelected = yearFromPeriod === currentYear;

  const monthBased = {};
  aggregatedAbsenceEmployeeCards.forEach((employeeCard) => {
    if (!employeeCard.period) return;
    const month = parseInt(employeeCard.period.slice(4));
    monthBased[month] = employeeCard;
  });

  return (
    <>
      <div className="yearly-absence-overview">
        <ListGroup variant="drawer-navigation">
          {months.map((month, index) => {
            const data = monthBased[index + 1] as AggregatedEmployeeCard;
            const periodHasWageChangesInDraft = data?.aggregatedWageTypeWages?.some((w) =>
              w.wages.some((wage) => wage.isDraftModified),
            );

            return (
              <DrawerNavigation
                key={month}
                className="yearly-absence-overview__month"
                disabled={(isCurrentYearSelected && index > currentMonth) || (!data && monthFromPeriod !== index + 1)}
                label={
                  <>
                    {periodHasWageChangesInDraft ? (
                      <div className="yearly-absence-overview__label">
                        <Tooltip
                          content={t('employees:employee-draft-absence-wages-changed-in-period')}
                          direction="bottom"
                        >
                          <Typography variant="body2" bold className="yearly-absence-overview__label-text">
                            <>
                              {`${month[0].toUpperCase() + month.slice(1)}`}
                              <Icon type="Info" stroke="#a6aebb" className="yearly-absence-overview__icon" />
                            </>
                          </Typography>
                        </Tooltip>
                        <When condition={data == null}>
                          <Pill type={'warning'} label={t('wages:no-absence')} />
                        </When>
                      </div>
                    ) : (
                      <div className="yearly-absence-overview__label">
                        <Typography variant="body2" bold>
                          {`${month[0].toUpperCase() + month.slice(1)}`}
                        </Typography>
                        <When condition={data == null}>
                          <Pill type={'warning'} label={t('wages:no-absence')} />
                        </When>
                      </div>
                    )}
                  </>
                }
                onClick={() => onClick(index + 1)}
              />
            );
          })}
        </ListGroup>
      </div>
    </>
  );
};

export default YearlyAbsenceOverview;
