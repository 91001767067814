import UnpaidInvoicesOverviewSection from './UnpaidInvoicesOverviewSection';
import './economy-overview-tab.scss';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_UNPAID_INVOICES } from 'src/graphql/schema/query/invoice/GetUnpaidInvoices';
import { UnpaidInvoice } from 'src/types/invoice/UnpaidInvoice';

const EconomyOverviewTab = () => {
  return (
    <QueryWrapper query={GET_UNPAID_INVOICES} type="list">
      {(unpaidInvoices: UnpaidInvoice[]) => (
        <div className="economy-overview-tab">
          <UnpaidInvoicesOverviewSection invoices={unpaidInvoices} />
        </div>
      )}
    </QueryWrapper>
  );
};
export default EconomyOverviewTab;
