import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { client } from '../../graphql';
import { Employee } from '../../types/Employee';

export const fetchEmployee = async (employeeId: string): Promise<Employee> => {
  const {
    data: { employee },
  } = await client.query({
    query: GET_EMPLOYEE,
    variables: {
      employeeId,
    },
    fetchPolicy: 'no-cache',
  });
  return employee;
};
