import { Datepicker } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { getDaysShort } from 'src/util/date/DaysShort';
import { useMonths } from 'src/util/date/Months';
import { createOptions } from 'src/util/OptionUtil';
import { getYears } from 'src/util/date/Years';
import { datePickerSchema } from 'src/validation/schemas/datePickerSchema';
import { useEffect, useState } from 'react';

interface DatePickerInputProps {
  fieldValue: string;
  fieldKey: string;
  onBlur: (field: string, value: string) => void;
  label?: string;
  placeholder?: string;
  initialMonth?: number;
  initialYear?: number;
}

const DatePickerInput = ({
  fieldKey,
  fieldValue,
  onBlur,
  label,
  placeholder = 'ÅÅÅÅ-MM-DD',
  initialMonth,
  initialYear,
}: DatePickerInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(fieldValue);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { error } = datePickerSchema.validate({ [fieldKey]: value }, { abortEarly: false });

  const handleValidation = () => {
    error && error.details.length > 0
      ? setErrorMessage(t(`validationErrors:${error.details[0].context.key}.${error.details[0].type}`))
      : onBlur(fieldKey, value);
  };

  const monthOptions = useMonths().map((month, index) => {
    return { label: month, value: index + 1 };
  });

  useEffect(() => {
    error ?? setErrorMessage('');
  }, [error]);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  return (
    <Datepicker
      label={label}
      variant="inline-edit"
      placeholder={placeholder}
      onClose={handleValidation}
      onBlur={handleValidation}
      value={value}
      setValue={setValue}
      months={monthOptions}
      daysShort={createOptions(getDaysShort())}
      years={createOptions(getYears())}
      initialMonth={initialMonth}
      initialYear={initialYear}
      errorMessage={errorMessage}
    />
  );
};

export default DatePickerInput;
