import { gql } from '@apollo/client';

export const GET_PGL_RULES = gql`
  query GetPglRules($policyId: UUID!) {
    pglRules(policyId: $policyId) {
      id
      ruleId
      minimumReportableChangeAmount
      name
      pglWageTypes {
        id
        employerWageTypeId
        conversionFactor
        conversionDenominator
        calculationTimeFrame
        recalculationFromMonth
        applyRetroactivelyFromMonth
      }
      spglWageTypes {
        id
        employerWageTypeId
        conversionFactor
        conversionDenominator
        calculationTimeFrame
        recalculationFromMonth
        applyRetroactivelyFromMonth
      }
      pglBonusComponents {
        id
        bonusAverageType
        employerWageTypeId
        numberOfYearsToInclude
        includePresentYear
        recalculationFromMonth
        recalculationToMonth
        applyRetroactivelyFromMonth
        successiveBonusSpecificSettings {
          numberOfYearsOfHistory
          startDateBasis
        }
      }
    }
  }
`;
