import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import {
  SearchAccountingItemsAccountingPeriodFilter,
  SearchAccountingItemsRequest,
  SearchAccountingItemsRequestFilters,
} from 'src/types/ledgerStorage/SearchAccountingItemsRequest';
import './accounting-items-filter-details.scss';
import When from 'src/components/Common/When';
import { usePeriodText } from 'src/util/date/PeriodUtil';

interface AccountingItemsFilterDetailsProps {
  searchRequest: SearchAccountingItemsRequest;
  applyFilters: (filters: SearchAccountingItemsRequestFilters) => void;
}

export const AccountingItemsFilterDetails: FC<AccountingItemsFilterDetailsProps> = ({
  searchRequest,
  applyFilters,
}) => {
  const { t } = useTranslation();
  const hasInsuranceProviderFilters = searchRequest.filters?.insuranceProviderIdsFilter?.length > 0;
  const hasFromPeriodFilter = !!searchRequest.filters?.accountingPeriodFilter?.fromPeriod;
  const hasToPeriodFilter = !!searchRequest.filters?.accountingPeriodFilter?.toPeriod;

  const removeInsuranceProvidersFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      insuranceProviderIdsFilter: null,
    });
  };

  const removeFromPeriodFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      accountingPeriodFilter: {
        ...searchRequest.filters.accountingPeriodFilter,
        fromPeriod: null,
      },
    });
  };

  const removeToPeriodFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      accountingPeriodFilter: {
        ...searchRequest.filters.accountingPeriodFilter,
        toPeriod: null,
      },
    });
  };

  return (
    <div className="accounting-items-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      <When condition={!hasInsuranceProviderFilters && !hasFromPeriodFilter && !hasToPeriodFilter}>
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      </When>
      <When condition={hasInsuranceProviderFilters}>
        <FilterButton
          label={
            searchRequest.filters?.insuranceProviderIdsFilter?.length +
            ' ' +
            t('economy:accounting-items-filters.insurance-providers-filter')
          }
          onClick={removeInsuranceProvidersFilter}
        />
      </When>
      <When condition={hasFromPeriodFilter}>
        <PeriodFilterButton
          period={searchRequest.filters?.accountingPeriodFilter.fromPeriod}
          onClick={removeFromPeriodFilter}
          type="fromPeriod"
        />
      </When>
      <When condition={hasToPeriodFilter}>
        <PeriodFilterButton
          period={searchRequest.filters?.accountingPeriodFilter.toPeriod}
          onClick={removeToPeriodFilter}
          type="toPeriod"
        />
      </When>
    </div>
  );
};

interface PeriodFilterButtonProps {
  type: keyof SearchAccountingItemsAccountingPeriodFilter;
  period: string;
  onClick: () => void;
}

const PeriodFilterButton = ({ period, onClick, type }: PeriodFilterButtonProps) => {
  const periodText = usePeriodText(period);
  const { t } = useTranslation();

  return (
    <FilterButton
      label={
        t(`economy:accounting-items-filters.${type === 'fromPeriod' ? 'from' : 'to'}-period-filter`) + ' ' + periodText
      }
      onClick={onClick}
    />
  );
};

export default AccountingItemsFilterDetails;
