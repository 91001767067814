import { CREATE_OR_UPDATE_EMPLOYER_PAY_TYPE } from 'src/graphql/schema/mutation/employer/CreateOrUpdateEmployerPayType';
import { useApolloMutation } from '../graphql/Mutation';
import { CreateOrUpdateEmployerPayType } from 'src/types/CreateOrUpdateEmployerPayType';
import { GET_EMPLOYER_PAY_TYPES } from 'src/graphql/schema/query/employer/GetEmployerPayTypes';
import { GET_EMPLOYER_KUBS } from 'src/graphql/schema/query/employer/GetEmployerKubs';

export const useCreateOrUpdateEmployerPayType = () => {
  const apolloMutation = useApolloMutation();

  const createOrUpdateEmployerPayType = (employerId: string, request: CreateOrUpdateEmployerPayType) =>
    apolloMutation(CREATE_OR_UPDATE_EMPLOYER_PAY_TYPE, employerId, { request }, [
      GET_EMPLOYER_PAY_TYPES,
      GET_EMPLOYER_KUBS,
    ]);

  return createOrUpdateEmployerPayType;
};
