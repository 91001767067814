import React, { useState } from 'react';
import { EmploymentEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import When from 'src/components/Common/When';
import DrawerModal from 'src/components/Common/DrawerModal';
import EmployeeCheckpointsList from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointsList';
import DrawerBase from 'src/components/Common/DrawerBase';
import { Button, Icon, Pill, Tab, Tabs } from 'fgirot-k2-ui-components';
import { stringOrBlank } from 'src/util/StringUtil';
import { useTranslation } from 'react-i18next';
import withGuard from 'src/components/Common/WithGuard';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import EmployeeCheckpointDetails from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointDetails';
import { ExaminationEmployeeDrawerState } from './DrawerState';
import { useNavigate } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { Employee } from 'src/types/Employee';
import { useEmployeeCardWithWages } from 'src/service/employeecard/GetEmployeeCardWageQuery';
import ApolloServiceWrapper from 'src/components/Common/ApolloServiceWrapper';

interface ExaminationEmployeeDrawerProps {
  open: boolean;
  employeeResponse: EmploymentEventReportEmployeeResponse;
  onClose: () => void;
  wageFilePeriod: string;
}

const ExaminationEmployeeDrawer = ({
  open,
  employeeResponse,
  onClose,
  wageFilePeriod,
}: ExaminationEmployeeDrawerProps) => {
  const { t } = useTranslation();
  const [navigationState, setNavigationState] = useState<ExaminationEmployeeDrawerState>(
    ExaminationEmployeeDrawerState.START,
  );

  const [selectedCheckpoint, setSelectedCheckpoint] = useState<EmploymentEventCheckpoint>(null);

  const employeeCardService = useEmployeeCardWithWages(employeeResponse?.employeeCard?.id);
  const employeeCard = employeeCardService.status == 'loaded' ? employeeCardService.payload : null;

  const handleBackClick = () => {
    if (navigationState == ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED) setSelectedCheckpoint(null);
    setNavigationState((state) => --state);
  };

  const handleSelectCheckpoint = (checkpoint: EmploymentEventCheckpoint) => {
    setSelectedCheckpoint(checkpoint);
    setNavigationState(ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED);
  };

  const handleNavigate = (drawerState: ExaminationEmployeeDrawerState) => setNavigationState(drawerState);
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(`/employees/${employeeResponse.employee.id}`);
  };

  const navigateToProfileByEmployeeId = (employeeId: string) => window.open(`/employees/${employeeId}`, '_blank');

  const additionalDataBySelectedCheckpoint = employeeResponse?.checkpoints.find(
    (checkpoint) => checkpoint?.id === selectedCheckpoint?.id,
  )?.additionalData;

  const getCurrentEmployement = (): JSX.Element => {
    return (
      <QueryWrapper
        query={GET_EMPLOYEE}
        options={{
          variables: {
            employeeId: employeeResponse.employee.id,
          },
        }}
      >
        {(employee: Employee) => (
          <div className="employee-checkpoint-details__pill">
            <Pill label={t('examination:drawer.current-employment-number-pill') + employee.employmentNumber} />
            <Button
              type="link"
              label={t('report:employee-drawer.go-to-profile')}
              iconPlacement="left"
              icon={<Icon type="User" className="employee-drawer__button__icon" />}
              onClick={() => navigateToProfileByEmployeeId(employee.id)}
            />
          </div>
        )}
      </QueryWrapper>
    );
  };

  const getPreviousEmployment = (): JSX.Element => {
    return (
      <QueryWrapper
        query={GET_EMPLOYEE}
        options={{
          variables: {
            employeeId: additionalDataBySelectedCheckpoint.previousEmployeeId,
          },
          skip: !additionalDataBySelectedCheckpoint.previousEmployeeId,
        }}
      >
        {(employee: Employee) => (
          <div className="employee-checkpoint-details__pill">
            <Pill label={t('examination:drawer.previous-employment-number-pill') + employee.employmentNumber} />
            <Button
              type="link"
              label={t('report:employee-drawer.go-to-profile')}
              iconPlacement="left"
              icon={<Icon type="User" className="employee-drawer__button__icon" />}
              onClick={() => navigateToProfileByEmployeeId(employee.id)}
            />
          </div>
        )}
      </QueryWrapper>
    );
  };

  return (
    <DrawerModal open={open} onClose={onClose} overlayOnClose>
      <DrawerBase
        leftButton={
          navigationState == ExaminationEmployeeDrawerState.START ? (
            <Button
              type="link"
              label={t('report:employee-drawer.go-to-profile')}
              iconPlacement="left"
              icon={<Icon type="User" className="employee-drawer__button__icon" />}
              onClick={navigateToProfile}
            />
          ) : (
            <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={handleBackClick} />
          )
        }
        onClose={onClose}
        title={`${stringOrBlank(employeeResponse.employee?.firstName)} ${stringOrBlank(
          employeeResponse.employee?.lastName,
        )}`}
        subTitle={
          navigationState === ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED &&
          employeeResponse.checkpoints.find((checkpoint) => checkpoint.id === selectedCheckpoint.id)
            .subActionCategory === 'REEMPLOYMENT' ? (
            <>
              {stringOrBlank(employeeResponse.employee?.personNumber)}

              <div className="employee-checkpoint-details__subtitle">
                <When
                  condition={additionalDataBySelectedCheckpoint.previousEmployeeId === employeeResponse.employee.id}
                >
                  {getCurrentEmployement()}
                </When>
                <When
                  condition={additionalDataBySelectedCheckpoint.previousEmployeeId !== employeeResponse.employee.id}
                >
                  {getCurrentEmployement()}
                  {getPreviousEmployment()}
                </When>
              </div>
            </>
          ) : (
            <>{stringOrBlank(employeeResponse.employee?.personNumber)}</>
          )
        }
      >
        <ApolloServiceWrapper service={employeeCardService}>
          <When condition={navigationState == ExaminationEmployeeDrawerState.START}>
            <Tabs>
              <Tab title={t('report:employee-drawer.overview')}>
                <EmployeeCheckpointsList
                  checkpoints={employeeResponse.checkpoints}
                  employee={employeeResponse.employee}
                  onFinalize={onClose}
                  employeeCard={employeeCard}
                  onSelectCheckpoint={handleSelectCheckpoint}
                />
              </Tab>
            </Tabs>
          </When>
          <When condition={navigationState != ExaminationEmployeeDrawerState.START}>
            <EmployeeCheckpointDetails
              employeeCard={employeeCard}
              checkpoint={selectedCheckpoint}
              employee={employeeResponse.employee}
              handleNavigation={handleNavigate}
              currentNavigationState={navigationState}
              wageFilePeriod={wageFilePeriod}
            />
          </When>
        </ApolloServiceWrapper>
      </DrawerBase>
    </DrawerModal>
  );
};

export default withGuard(ExaminationEmployeeDrawer, (props) => props.employeeResponse != null);
