import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import MappingTab from './MappingTab';
import { PayTypeDrawerTab } from 'src/types/tabs/PayTypeDrawerTab';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';

interface PayTypeDrawerProps {
  employerId: string;
  open: boolean;
  onClose: () => void;
  employerPayType: EmployerPayType;
  employerWageTypes: EmployerWageType[];
}
export const PayTypeDrawer: FunctionComponent<PayTypeDrawerProps> = ({
  employerId,
  open,
  onClose,
  employerPayType,
  employerWageTypes,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<PayTypeDrawerTab>(PayTypeDrawerTab.MAPPING);

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        title={`${employerPayType.number} - ${employerPayType.name}`}
        subTitle={t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.employer-wage-type')}
        onClose={onClose}
      >
        <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
          <Tab title={t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer.mapping')}>
            <MappingTab
              employerId={employerId}
              employerPayType={employerPayType}
              employerWageTypes={employerWageTypes}
            />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
};

export default PayTypeDrawer;
