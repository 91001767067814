import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { periodToDate } from '../../../util/date/PeriodUtil';
import { InsuranceEvent } from '../../../types/reporting/InsuranceEvent';

export const groupBySubActionCategory = (
  eventReport: InsuranceEventReportEmployeeResponse,
): Map<string, InsuranceEventCheckpoint[]> => {
  return eventReport.checkpoints.reduce((map, eventReport) => {
    const grouped = map.get(eventReport.subActionCategory);

    if (grouped) {
      grouped.push(eventReport);
    } else {
      map.set(eventReport.subActionCategory, [eventReport]);
    }

    return map;
  }, new Map<string, InsuranceEventCheckpoint[]>());
};

export const groupBySortedSubActionCategory = (
  eventReport: InsuranceEventReportEmployeeResponse,
): Map<string, InsuranceEventCheckpoint[]> => {
  const sortedCheckpoints: InsuranceEventCheckpoint[] = eventReport?.checkpoints.sort((a, b) =>
    a.id.localeCompare(b.id),
  );

  return eventReport?.checkpoints
    .splice(0, sortedCheckpoints.length, ...eventReport.checkpoints)
    .reduce((map, eventReport) => {
      const grouped = map.get(eventReport.subActionCategory);

      if (grouped) {
        grouped.push(eventReport);
      } else {
        map.set(eventReport.subActionCategory, [eventReport]);
      }

      return map;
    }, new Map<string, InsuranceEventCheckpoint[]>());
};

export const groupByInsuranceEvent = (
  checkpoints: InsuranceEventCheckpoint[],
): Map<string, InsuranceEventCheckpoint[]> => {
  return checkpoints.reduce((map, checkpoint) => {
    const grouped = map.get(checkpoint.insuranceEventType);

    if (grouped) {
      grouped.push(checkpoint);
    } else {
      map.set(checkpoint.insuranceEventType, [checkpoint]);
    }
    return map;
  }, new Map<string, InsuranceEventCheckpoint[]>());
};

export const initialDates = (checkpoints: InsuranceEventCheckpoint[], insuranceEvents: Map<string, InsuranceEvent>) => {
  const initialDates = {};
  checkpoints.forEach((checkpoint) => {
    const insuranceEventId = checkpoint.additionalData.insuranceEventId;
    const insuranceEvent = insuranceEvents.get(insuranceEventId);
    if (insuranceEvent) {
      initialDates[insuranceEventId] = insuranceEvent.eventFromDate || '';
    }
  });
  return initialDates;
};

export const getUsedProviders = (checkPoints: InsuranceEventCheckpoint[], providers: InsuranceProvider[]) =>
  [
    ...checkPoints
      .reduce((acc, checkPoints) => acc.add(checkPoints.additionalData.insuranceProviderId), new Set<string>())
      .values(),
  ].reduce((acc, providerId) => {
    const provider = providers.find((provider) => provider.id == providerId);
    if (provider) {
      acc.push(provider);
    }
    return acc;
  }, []);

export const isChosenDateValid = (chosenDate: string, employeeCardPeriod: string) => {
  const chosenDateToPeriod = chosenDate.substring(0, 7).replace('-', '');

  const chosenPeriodAsDate = periodToDate(chosenDateToPeriod);
  const employeePeriodAsDate = periodToDate(employeeCardPeriod);
  const threeMonthsEarlier = new Date(employeePeriodAsDate);
  threeMonthsEarlier.setMonth(threeMonthsEarlier.getMonth() - 2);

  return chosenPeriodAsDate >= threeMonthsEarlier && chosenPeriodAsDate <= employeePeriodAsDate;
};
