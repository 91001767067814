import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackfillDraftEmployeeCards } from 'src/service/employee/BackfillDraftEmployeeCards';

interface RetroactiveEmploymentStartDateModalProps {
  open: boolean;
  employeeId: string;
  employerId: string;
  newEmploymentStartDate: DateTime;
  onCancel: () => void;
  afterBackfillComplete: () => void;
}

function RetroactiveEmploymentStartDateModal({
  open,
  newEmploymentStartDate,
  employeeId,
  employerId,
  onCancel,
  afterBackfillComplete,
}: RetroactiveEmploymentStartDateModalProps) {
  const [loading, setLoading] = useState(false);
  const backfillDraftEmployeeCards = useBackfillDraftEmployeeCards();

  const { t } = useTranslation();

  const performBackfill = async () => {
    setLoading(true);

    try {
      await backfillDraftEmployeeCards(employeeId, employerId, newEmploymentStartDate.toFormat('yyyy-MM-dd'));
      afterBackfillComplete();
    } finally {
      setLoading(false);
    }
    onCancel();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onCancel}>
      <Card
        icon={<Icon type="Alert" stroke="#ae2336" />}
        title={t('employees:actions.retroactive-employment-start-date')}
        defaultClose
        onClose={onCancel}
      >
        <Typography component="span">
          {t('employees:actions.retroactive-employment-start-date-body', {
            startPeriod: newEmploymentStartDate && newEmploymentStartDate.toFormat('yyyy-MM'),
          })}
        </Typography>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={onCancel} />
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            label={t('employees:actions.confirm')}
            onClick={performBackfill}
            data-cy="confirm-retroactive-start-date-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
}

export default RetroactiveEmploymentStartDateModal;
