import { Button, Checkbox, Icon, TableRow, Td } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { Employee } from 'src/types/Employee';
import { Employer } from 'src/types/employer/Employer';
import { isAllCheckpointsAccepted, isCheckpointStatusesMatchingEither } from 'src/util/CheckpointUtil';
import TdEmployerName from '../../Common/ReportTable/TdEmployerName';
import TdFullname from '../../Common/ReportTable/TdFullname';
import TdExaminationPeriodDifference from './TdExaminationPeriodDifference';
import { updateCheckpointToFinalizeList } from '../../Common/utils';
import TdSubActionCategory from '../../Common/ReportTable/TdSubActionCategory/TdSubActionCategory';

interface ExaminationTableRowProps {
  employee: Employee;
  checkpoints: EmploymentEventCheckpoint[];
  employers: Employer[];
  onClick: () => void;
}

const ExaminationTableRow = ({ employee, checkpoints, employers, onClick }: ExaminationTableRowProps) => {
  useEffect(() => {
    const isChecked = isCheckpointStatusesMatchingEither(checkpoints, ['ACCEPTED', 'RESOLVED']);
    setChecked(isChecked);
    updateCheckpointToFinalizeList(employee, isChecked ? 'ADD' : 'REMOVE');
  }, [checkpoints]);

  const [checked, setChecked] = useState(isCheckpointStatusesMatchingEither(checkpoints, ['ACCEPTED', 'RESOLVED']));

  const handleOnChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCheckpointToFinalizeList(employee, e.target.checked ? 'ADD' : 'REMOVE');
    setChecked(e.target.checked);
  };

  return (
    <TableRow onClick={onClick}>
      <TdFullname
        alert={!isAllCheckpointsAccepted(checkpoints)}
        firstName={employee.firstName}
        lastName={employee.lastName}
        hasDraft={employee.hasDraft}
      />
      <Td>{employee.personNumber}</Td>
      <TdEmployerName employers={employers} employee={employee} />
      <TdExaminationPeriodDifference checkpoints={checkpoints} />
      <TdSubActionCategory checkpoints={checkpoints} />
      <Td notClickable>
        <Checkbox
          checked={checked}
          onChange={handleOnChangeChecked}
          disabled={!isCheckpointStatusesMatchingEither(checkpoints, ['ACCEPTED', 'RESOLVED'])}
        />
      </Td>
      <Td notClickable>
        <Button type="link" icon={<Icon type="MoreHorizontal" />} />
      </Td>
    </TableRow>
  );
};

export default ExaminationTableRow;
