import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Service } from 'src/types/Service';
import { INIT, LOADED, LOADING } from 'src/types/Status';
import { EmployerControlPointRule } from 'src/types/employer/EmployerControlPointRule';
import { GET_EMPLOYER_CONTROL_POINT_RULES } from 'src/graphql/schema/query/employer/GetEmployerControlPointRules';

export const useEmployerControlPointRules = (
  employerId: string,
  ruleTypeCategory: string,
): Service<EmployerControlPointRule[]> => {
  const [result, setResult] = useState<Service<EmployerControlPointRule[]>>({
    status: INIT,
  });
  const { loading, error, data } = useQuery(GET_EMPLOYER_CONTROL_POINT_RULES, {
    variables: {
      employerId,
      ruleTypeCategory,
    },
  });

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && result.status !== LOADED && setResult({ status: LOADED, payload: [] });
    data && result.status !== LOADED && setResult({ status: LOADED, payload: data.employerControlPointRules });
  }, [loading, error, data]);

  return result;
};
