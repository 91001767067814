import { client } from 'src/graphql';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import { InsuranceProvider } from 'src/types/InsuranceProvider';

export const fetchInsuranceProviders = async (): Promise<InsuranceProvider[]> => {
  const {
    data: { insuranceProviders },
  } = await client.query({ query: GET_INSURANCE_PROVIDERS });

  return insuranceProviders;
};
