import { Button, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';
import './invoices-filter-details.scss';

interface InvoicesFilterDetailsProps {
  filters: SearchInvoicesFilter;
  applyFilters: (filters: SearchInvoicesFilter) => void;
}

export const InvoicesFilterDetails: FC<InvoicesFilterDetailsProps> = ({ filters, applyFilters }) => {
  const { t } = useTranslation();
  const filtersApplied =
    filters?.paymentDueDate?.fromDate.length > 0 ||
    filters?.paymentDueDate?.toDate.length > 0 ||
    filters?.insuranceProviderIds?.length > 0 ||
    filters?.invoiceTypes?.length > 0 ||
    filters?.employerIds?.length > 0 ||
    filters?.invoiceStatuses?.length > 0;

  const removeFromPaymentDueDateFilter = () => {
    applyFilters({
      ...filters,
      paymentDueDate: { fromDate: '', toDate: filters.paymentDueDate?.toDate },
    });
  };

  const removeToPaymentDueDateFilter = () => {
    applyFilters({
      ...filters,
      paymentDueDate: { fromDate: filters.paymentDueDate?.fromDate, toDate: '' },
    });
  };

  const removeInsuranceProviderFilter = () => {
    applyFilters({
      ...filters,
      insuranceProviderIds: null,
    });
  };

  const removeEmployersFilter = () => {
    applyFilters({
      ...filters,
      employerIds: null,
    });
  };

  const removeInvoiceStatusFilter = () => {
    applyFilters({
      ...filters,
      invoiceStatuses: null,
    });
  };

  const removeInvoiceTypeCodeFilter = () => {
    applyFilters({
      ...filters,
      invoiceTypes: null,
    });
  };

  const removeAllFilters = () => {
    applyFilters({
      searchInput: '',
      paymentDueDate: {
        fromDate: '',
        toDate: '',
      },
      invoiceStatuses: [],
      invoiceTypes: [],
      insuranceProviderIds: [],
      employerIds: [],
    });
  };

  return (
    <div className="invoices-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" bold>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {filters?.paymentDueDate.fromDate?.length > 0 && (
            <FilterButton
              label={
                filters?.paymentDueDate.fromDate + ' ' + t('invoice:search-invoices-filters.from-payment-due-date')
              }
              onClick={removeFromPaymentDueDateFilter}
            />
          )}
          {filters?.paymentDueDate.toDate?.length > 0 && (
            <FilterButton
              label={filters?.paymentDueDate.toDate + ' ' + t('invoice:search-invoices-filters.to-payment-due-date')}
              onClick={removeToPaymentDueDateFilter}
            />
          )}
          {filters?.employerIds?.length > 0 && (
            <FilterButton
              label={filters?.employerIds.length + ' ' + t('invoice:search-invoices-filters.employers')}
              onClick={removeEmployersFilter}
            />
          )}
          {filters?.insuranceProviderIds?.length > 0 && (
            <FilterButton
              label={
                filters?.insuranceProviderIds.length + ' ' + t('invoice:search-invoices-filters.insurance-providers')
              }
              onClick={removeInsuranceProviderFilter}
            />
          )}
          {filters?.invoiceStatuses?.length > 0 && (
            <FilterButton
              label={filters?.invoiceStatuses.length + ' ' + t('invoice:search-invoices-filters:invoice-status')}
              onClick={removeInvoiceStatusFilter}
            />
          )}
          {filters?.invoiceTypes?.length > 0 && (
            <FilterButton
              label={filters?.invoiceTypes.length + ' ' + t('invoice:search-invoices-filters:invoice-type-code')}
              onClick={removeInvoiceTypeCodeFilter}
            />
          )}
          <Button
            type="warning"
            size="small"
            label={t('invoice:search-invoices-filters:remove-all')}
            onClick={removeAllFilters}
          />
        </>
      )}
    </div>
  );
};

export default InvoicesFilterDetails;
