import { Icon, IconPill } from 'fgirot-k2-ui-components';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import { Employee } from 'src/types/Employee';
import './multiple-employments-detail-row.scss';
import { useTranslation } from 'react-i18next';

interface MultipleEmploymentsDetailsRowProps {
  employmentData: Employee;
  onClick: () => void;
}

const MultipleEmploymentsDetailsRow = ({ employmentData, onClick }: MultipleEmploymentsDetailsRowProps) => {
  const { t } = useTranslation();
  const employerLegalName = useEmployerLegalName(employmentData.employerId);
  return (
    <div className="multiple-employment-details-row">
      <div className="multiple-employment-details-row__details">
        <IconPill icon="Building" label={employerLegalName} />
        <IconPill icon="Hash" label={employmentData.employmentNumber} />
      </div>
      <button onClick={onClick} className="multiple-employment-details-row__button">
        <Icon type="MoreHorizontal" aria-hidden="true" />
        <span className="multiple-employment-details-row__sr-only">
          {t('employees:search-tab.details.navigate-to-detail-page')}
        </span>
      </button>
    </div>
  );
};

export default MultipleEmploymentsDetailsRow;
