import { useState, useEffect } from 'react';
import { GET_AGREED_REPORTING_TYPE_CONFIGS } from 'src/graphql/schema/query/contract/GetAgreedReportingtypeConfigs';
import { useCreateAgreedReportingTypeConfig } from 'src/service/contract/CreateAgreedReportingTypeConfig';
import { useDeleteAgreedReportingTypeConfig } from 'src/service/contract/DeleteAgreedReportingTypeConfig';
import { usePatchAgreedReportingTypeConfig } from 'src/service/contract/PatchAgreedReportingTypeConfig';
import { useApolloQuery } from 'src/service/graphql/Query';
import { Service } from 'src/types/Service';
import { LOADED } from 'src/types/Status';
import { AgreedReportingTypeConfig } from 'src/types/contract/AgreedReportingTypeConfig';
import { ReportingDetailLevel } from 'src/types/contract/ContractAgreedProduct';
import { InsuranceEventType } from 'src/types/reporting/InsuranceEvent';
import { ReportingType, reportingTypes } from 'src/types/reporting/ReportingType';

interface ValueObject {
  value: ReportingDetailLevel;
}

type AgreedReportingConfigTypeMappedResult = Record<
  ReportingType,
  {
    reportingType: ValueObject;
    insuranceTypes: Record<string, ValueObject>;
  }
>;

const _availableInsuranceEventsByReportingType: Record<ReportingType, string[]> = {
  CHANGE_OF_BENEFIT: [],
  CHANGE_OF_PREMIUM_AMOUNT: [],
  END_REGISTRATION_DUE_TO_CLOSURE: [
    'END_REGISTRATION_DUE_TO_LEAVE_OF_ABSENCE',
    'END_REGISTRATION_DUE_TO_PARENTAL_LEAVE',
    'END_REGISTRATION_DUE_TO_STUDIES',
    'END_REGISTRATION_DUE_TO_COMPANY_GROUP_RELOCATION',
  ],
  NOTIFICATION_OF_HEALTH: [],
  REGISTRATION_PREMIUM_BASED_AMOUNT: [],
  REPORTING_SICK: [],
  NEW_REGISTRATION: [
    'NEW_REGISTRATION_DUE_TO_PREPONED_START_DATE',
    'BACK_FROM_LEAVE_OF_ABSENCE',
    'BACK_FROM_PARENTAL_LEAVE',
    'BACK_FROM_STUDIES_LEAVE',
    'NEW_REGISTRATION_DUE_TO_COMPANY_GROUP_RELOCATION',
  ],
};
const initializeResult = (): AgreedReportingConfigTypeMappedResult => {
  return reportingTypes.reduce<AgreedReportingConfigTypeMappedResult>((acc, reportingType) => {
    acc[reportingType] = {
      reportingType: { value: null },
      insuranceTypes: {},
    };
    _availableInsuranceEventsByReportingType[reportingType].forEach((insuranceType) => {
      acc[reportingType].insuranceTypes[insuranceType] = { value: null };
    });
    return acc;
  }, {} as AgreedReportingConfigTypeMappedResult);
};

type AgreedReportingTypeHookReturn = [
  result: AgreedReportingConfigTypeMappedResult,
  service: Service<AgreedReportingTypeConfig[]>,
  handleUpdate: (
    reportingType: ReportingType,
    insuranceEventType?: InsuranceEventType,
    reportingDetailLevel?: ReportingDetailLevel,
  ) => void,
];

const useAgreedReportingType = (
  employerId: string,
  agreedProductId: string,
  insuranceProviderContractId: string,
): AgreedReportingTypeHookReturn => {
  const [agreedReportingConfigTypeMappedResult, setAgreedReportingConfigTypeMappedResult] = useState(
    initializeResult(),
  );
  const createAgreedReportingTypeConfig = useCreateAgreedReportingTypeConfig();
  const deleteAgreedReportingTypeConfig = useDeleteAgreedReportingTypeConfig();
  const patchAgreedReportingTypeConfig = usePatchAgreedReportingTypeConfig();

  const agreedReportingTypeQuery = useApolloQuery<AgreedReportingTypeConfig[]>(GET_AGREED_REPORTING_TYPE_CONFIGS, {
    variables: { agreedProductId, insuranceProviderContractId },
  });

  useEffect(() => {
    if (agreedReportingTypeQuery.status != LOADED) return;

    setAgreedReportingConfigTypeMappedResult(() => {
      const payload = agreedReportingTypeQuery.payload;
      const typeMappedResult = initializeResult();
      //Populate the typeMappedResult with the values fetches from graphql

      payload.forEach((value) => {
        const objectValue = { value: value.reportingDetailLevel };
        if (value.insuranceEventType == null) {
          typeMappedResult[value.reportingType].reportingType = objectValue;
        } else {
          typeMappedResult[value.reportingType].insuranceTypes[value.insuranceEventType] = objectValue;
        }
      });
      return typeMappedResult;
    });
  }, [agreedReportingTypeQuery]);

  const handleUpdate = (
    reportingType: ReportingType,
    insuranceEventType?: InsuranceEventType,
    reportingDetailLevel?: ReportingDetailLevel,
  ) => {
    if (agreedReportingTypeQuery.status != LOADED) return;
    return updateAgreedReportingConfig(
      agreedReportingTypeQuery.payload,
      employerId,
      insuranceProviderContractId,
      agreedProductId,
      reportingType,
      insuranceEventType,
      reportingDetailLevel,
    );
  };

  const updateAgreedReportingConfig = (
    payload: AgreedReportingTypeConfig[],
    employerId: string,
    insuranceProviderContractId: string,
    agreedProductId: string,
    reportingType: ReportingType,
    insuranceEventType?: InsuranceEventType,
    reportingDetailLevel?: ReportingDetailLevel,
  ) => {
    const object = payload.find(
      (v) => v.reportingType === reportingType && v.insuranceEventType === insuranceEventType,
    );

    if (reportingDetailLevel == null && object != null) {
      deleteAgreedReportingTypeConfig(employerId, insuranceProviderContractId, agreedProductId, object.id);
    } else if (object == null) {
      createAgreedReportingTypeConfig(employerId, insuranceProviderContractId, agreedProductId, {
        insuranceEventType,
        reportingType,
        reportingDetailLevel,
      });
    } else if (reportingDetailLevel !== object.reportingDetailLevel) {
      patchAgreedReportingTypeConfig(employerId, insuranceProviderContractId, agreedProductId, object.id, {
        reportingDetailLevel,
      });
    }
  };

  return [agreedReportingConfigTypeMappedResult, agreedReportingTypeQuery, handleUpdate];
};

export default useAgreedReportingType;
