import { Option, Select } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PageBase from 'src/components/PageBase';
import ReportingYearOverview from './ReportingYearOverview';
import './reporting.scss';
import { useCustomer } from 'src/service/customer/GetCustomer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYERS_REPORTING_FLOW_SETTINGS } from 'src/graphql/schema/query/employer/GetEmployersReportingFlowSettings';
import { EmployerReportingFlowSetting } from 'src/types/employer/EmployerReportingFlowSetting';
import DisableAutomaticReportingWarningBanner from 'src/components/Reporting/ReportingEvents/DisableAutomaticReporting/DisableAutomaticReportingWarningBanner';
import { useNavigate, useParams } from 'react-router-dom';
import { useYearOptions } from 'src/util/date/Years';
import { useEmployersIdLegalName } from 'src/service/employer/GetEmployer';

const Reporting = () => {
  const { t } = useTranslation();
  const { year } = useParams();
  const navigate = useNavigate();

  // TODO: In upcoming US selectable years and month should be based on expected period and earlier uploaded files
  const selectedYear = (year && parseInt(year)) || new Date().getFullYear();
  const yearsOptions = useYearOptions();

  const handleYearChange = (option: Option<number>) => {
    navigate('/reporting/' + option.value);
  };

  const customer = useCustomer();
  const employers = useEmployersIdLegalName(customer?.id);
  const employerIds = employers.map((e) => e.id);

  return (
    <PageBase
      breadCrumbs={[{ name: customer?.name ?? '', steps: 1 }]}
      header
      title={`${t('navigation:reporting')} ${customer?.name ?? ''}`}
    >
      <div className="reporting-overview-header">
        <div className="reporting-overview-header--actions">
          <Select
            outlined
            options={yearsOptions}
            selected={yearsOptions.find((opt) => opt.value === selectedYear)}
            onChange={handleYearChange}
            data-cy="selected-year-dropdown"
            key={'reporting-overview-selected-year'}
          />
        </div>
        <QueryWrapper
          query={GET_EMPLOYERS_REPORTING_FLOW_SETTINGS}
          options={{ variables: { request: { employerIds } }, skip: !employerIds?.length }}
          loadingComponent={<div></div>}
        >
          {(reportingFlowSettings: EmployerReportingFlowSetting[]) => (
            <div className="reporting-overview-header__banner">
              <DisableAutomaticReportingWarningBanner
                employers={employers}
                reportingFlowSettings={reportingFlowSettings}
              />
            </div>
          )}
        </QueryWrapper>
      </div>
      {selectedYear && <ReportingYearOverview selectedYear={selectedYear} customer={customer} />}
    </PageBase>
  );
};

export default Reporting;
