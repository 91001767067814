import { TableRow, Td, Checkbox, Button, Icon } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import TdEmployerName from 'src/components/Report/Common/ReportTable/TdEmployerName';
import TdFullname from 'src/components/Report/Common/ReportTable/TdFullname';
import { addToCacheList, removeFromCacheList } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { Employee } from 'src/types/Employee';
import { EmployeeCard } from 'src/types/EmployeeCard';
import { Employer } from 'src/types/employer/Employer';
import { hasRaisedCheckpoints, isCheckpointStatusesMatching } from 'src/util/CheckpointUtil';
import TdBenefitType from './TdBenefitType';
import TdCertifyInsuranceEventType from './TdCertifyInsuranceEventType';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { stringOrBlank } from 'src/util/StringUtil';

interface CertifyTableRowProps {
  employee: Employee;
  employeeCard: EmployeeCard;
  checkpoints: InsuranceEventCheckpoint[];
  employers: Employer[];
  handleEmployeeDrawer: () => void;
  alert: boolean;
  insuranceProviders: InsuranceProvider[];
}

const CertifyTableRow = ({
  employee,
  employeeCard,
  checkpoints,
  employers,
  handleEmployeeDrawer,
  alert,
  insuranceProviders,
}: CertifyTableRowProps) => {
  const checkpointsAccepted = isCheckpointStatusesMatching(checkpoints, 'ACCEPTED');
  const [checked, setChecked] = useState(checkpointsAccepted);

  const insuranceProviderNames = checkpoints
    .map((checkpoint) =>
      stringOrBlank(
        insuranceProviders?.find(
          (insuranceProvider) => insuranceProvider.id === checkpoint.additionalData.insuranceProviderId,
        )?.legalName,
      ),
    )
    .join(', ');

  useEffect(() => {
    handleEmployeeIdFinalizedCache();
  }, [checked]);

  const handleEmployeeIdFinalizedCache = () => {
    const value = {
      employeeId: employee.id,
      employerId: employee.employerId,
      conditionsMetadata: checkpoints.map((checkpoint) => {
        return {
          benefitType: checkpoint.benefitType,
          insuranceProviderId: checkpoint.additionalData.insuranceProviderId,
          insuranceProviderProductConditionId: checkpoint.additionalData.insuranceProviderProductConditionId,
        };
      }),
    };

    if (checked) {
      addToCacheList(EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE, value);
    } else {
      removeFromCacheList(EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE, value.employeeId);
    }
  };

  const handleOnChangeChecked = () => {
    setChecked((prev) => !prev);
  };
  return (
    <TableRow onClick={handleEmployeeDrawer}>
      <TdFullname
        alert={alert}
        firstName={employee.firstName}
        lastName={employee.lastName}
        hasDraft={employee.hasDraft}
      />
      <Td>{employee.personNumber}</Td>
      <TdEmployerName employers={employers} employee={employee} />
      <Td>{employeeCard?.workingPercentage}%</Td>
      <Td>{employeeCard?.sickDays}</Td>
      <TdCertifyInsuranceEventType checkpoints={checkpoints} />
      <TdBenefitType insuranceEvents={checkpoints} />
      <Td>{insuranceProviderNames}</Td>
      <Td>{alert && <Icon className="certify-table__row__alert" type="Alert" />}</Td>
      <Td notClickable>
        <Checkbox disabled={hasRaisedCheckpoints(checkpoints)} checked={checked} onChange={handleOnChangeChecked} />
      </Td>
      <Td notClickable>
        <Button type="link" icon={<Icon type="MoreHorizontal" />} />
      </Td>
    </TableRow>
  );
};

export default CertifyTableRow;
