import { ListGroup } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PolicyVersionDrawer from '../PolicyVersionDrawer';
import PolicyVersionDrawerNavigation from './PolicyVersionDrawerNavigation';
import './policy-versions-drawer.scss';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import { useNavigate, useParams } from 'react-router-dom';
import DrawerModal from 'src/components/Common/DrawerModal';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import { Employer } from 'src/types/employer/Employer';
import DrawerBase from 'src/components/Common/DrawerBase';

interface PolicyVersionsDrawerProps {
  open: boolean;
  policyEntities: PolicyEntity[];
  onClose: () => void;
}

const PolicyVersionsDrawer: React.FunctionComponent<PolicyVersionsDrawerProps> = ({
  open,
  policyEntities,
  onClose,
}) => {
  const { employerId, customerName } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [displayedPolicyId, setDisplayedPolicyId] = useState<string>(undefined);

  const handleGoToPolicyVersion = (policyId: string) => {
    handleCloseDisplayedPolicyDrawer();
    onClose();
    navigate(`/account/${customerName}/${employerId}/policy-settings/${policyId}`);
  };

  const handleDisplayPolicyEntity = (policyId: string) => {
    setDisplayedPolicyId(policyId);
  };
  const handleCloseDrawer = () => {
    setDisplayedPolicyId(undefined);
    onClose();
  };
  const handleCloseDisplayedPolicyDrawer = () => {
    setDisplayedPolicyId(undefined);
  };

  const displayedPolicyEntity = policyEntities.find((entity) => entity.id === displayedPolicyId);

  return (
    <QueryWrapper query={GET_EMPLOYER} options={{ variables: { employerId } }}>
      {(employer: Employer) => (
        <>
          <DrawerModal overlayBackground overlayOnClose open={open} onClose={handleCloseDrawer}>
            {!displayedPolicyEntity ? (
              <DrawerBase
                title={t('account:employers-tab.policy-settings.drawer.policy-versions')}
                onClose={handleCloseDrawer}
                subTitle={employer.legalName}
              >
                <ListGroup className="policy-versions-drawer--list-group" variant="drawer-navigation">
                  {policyEntities.map((entity, index) => (
                    <PolicyVersionDrawerNavigation
                      key={`PolicyVersionsDrawer-${entity.id}-${index}`}
                      policyEntity={entity}
                      handleDisplayPolicyEntity={handleDisplayPolicyEntity}
                    />
                  ))}
                </ListGroup>
              </DrawerBase>
            ) : (
              <PolicyVersionDrawer
                selectedPolicyEntity={displayedPolicyEntity}
                handleSelectVersion={handleGoToPolicyVersion}
                handleGoBack={handleCloseDisplayedPolicyDrawer}
                onClose={handleCloseDrawer}
              />
            )}
          </DrawerModal>
        </>
      )}
    </QueryWrapper>
  );
};

export default PolicyVersionsDrawer;
