import React from 'react';
import { DRAFT } from 'src/types/policy/PolicyEntity';
import { leaveLimitRuleTypes, PolicyRuleType } from 'src/types/policy/AffiliationRule';
import OneRuleValueLimitRule from '../Components/OneRuleValueLimitRule';
import { RuleGroupProps } from '../RuleGroupProps';

const LeaveLimitRuleGroup: React.FunctionComponent<RuleGroupProps> = ({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
}) => {
  const isRuleTypeConfigured = (ruleType: PolicyRuleType) =>
    selectedAffiliationRuleGroup.affiliationRules.some((r) => r.policyRuleType === ruleType);

  return (
    <>
      {leaveLimitRuleTypes
        .filter((ruleType) => policyState === DRAFT || isRuleTypeConfigured(ruleType))
        .map((policyRuleType) => {
          return (
            <OneRuleValueLimitRule
              key={`LeaveLimitRuleGroup-${policyRuleType}`}
              policyState={policyState}
              selectedAffiliationRuleGroup={selectedAffiliationRuleGroup}
              policyRuleType={policyRuleType}
              onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
            />
          );
        })}
    </>
  );
};

export default LeaveLimitRuleGroup;
