import { Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { wageTypes } from 'src/types/employer/EmployerWageType';
import {
  EmployerWageTypeRequest,
  ExtentCalculation,
  extentCalculations,
} from 'src/types/employer/request/EmployerWageTypeRequest';
import { isAbsenceWageType } from 'src/util/WageUtil';
import './create-wage-type-modal.scss';
import { useCreateEmployerWageType } from 'src/service/employer/CreateEmployerWageType';

interface CreateWageTypeModalProps {
  open: boolean;
  employerId: string;
  onClose: () => void;
}

function CreateWageTypeModal({ open, employerId, onClose }: CreateWageTypeModalProps) {
  const { t } = useTranslation();
  const createEmployerWageType = useCreateEmployerWageType();

  const wageTypeOptions: Option<string>[] = wageTypes.map((wt) => ({
    label: t(`wageTypes:${wt}`),
    value: wt,
  }));

  const extentCalculationOptions: Option<ExtentCalculation>[] = extentCalculations.map((ec) => ({
    label: t(`extentCalculation:${ec}`),
    value: ec,
  }));

  const [request, setRequest] = React.useState<EmployerWageTypeRequest>({
    name: '',
    wageType: null,
    extentCalculation: 'IGNORE',
  });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequest((prev) => {
      return { ...prev, name: event.target.value };
    });
  };

  const handleSelectWageType = (option: Option<string>) => {
    setRequest((prev) => {
      return { ...prev, wageType: option.value };
    });
  };

  const handleSelectExtentCalculation = (option: Option<ExtentCalculation>) => {
    setRequest((prev) => {
      return { ...prev, extentCalculation: option.value };
    });
  };

  const handleAddWageType = () => {
    createEmployerWageType(employerId, request).then(() => {
      onClose();
    });
  };

  useEffect(() => {
    if (!isAbsenceWageType(request.wageType) && request.extentCalculation !== 'IGNORE') {
      setRequest((prev) => {
        return { ...prev, extentCalculation: 'IGNORE' };
      });
    }
  }, [request]);

  return (
    <Modal data-cy="create-wage-type-modal" centered overlayBackground open={open} onClose={onClose}>
      <Card title={t('account:employers-tab.wage-type-mapping-settings.add-wage-type')} size="narrow">
        <div className="create-wage-type-modal">
          <div>
            <TextInput
              label={`${t('account:employers-tab.wage-type-mapping-settings.wage-type-name')}`}
              type="text"
              validationKey="text255"
              variant="default"
              value={request.name}
              maxLength={256}
              mandatory
              onChange={handleNameChange}
            />
          </div>
          <div>
            <Select
              label={`${t('account:employers-tab.wage-type-mapping-settings.global-wage-type')}`}
              options={wageTypeOptions}
              selected={wageTypeOptions.find((option) => option.value === request.wageType)}
              onChange={handleSelectWageType}
              placeholder={t('common:select')}
              mandatory
              small
              outlined
            />
          </div>
          {isAbsenceWageType(request.wageType) && (
            <div>
              <Select
                label={`${t('account:employers-tab.wage-type-mapping-settings.extent-calculation')}`}
                options={extentCalculationOptions}
                selected={extentCalculationOptions.find((option) => option.value === request.extentCalculation)}
                onChange={handleSelectExtentCalculation}
                placeholder={t('common:select')}
                mandatory
                small
                outlined
              />
            </div>
          )}
        </div>
        <CardAction>
          <Button
            label={t('common:cancel')}
            type="link"
            onClick={onClose}
            data-cy="create-pay-type-modal__cancel-button"
          />
          <Button
            label={t('common:add')}
            disabled={!request.name || !request.wageType || !request.extentCalculation}
            onClick={handleAddWageType}
            data-cy="create-pay-type-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
}

export default CreateWageTypeModal;
