import { Banner, Button, Switch, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useEffect, useState } from 'react';
import {
  AccountingComponent,
  AccountingComponentFlat,
  AccountingComponentInput,
} from 'src/types/accountingunit/AccountingComponent';
import { arrangeFlatArrayAsTree } from 'src/util/ArrayUtil';
import { recursiveHasGoodShare } from 'src/util/AccountingComponentUtil';
import { useTranslation } from 'react-i18next';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import { useParams } from 'react-router-dom';
import { useUpdatePostingRuleComponents } from 'src/service/accountingunit/PostingRule';
import { refetchQuery } from 'src/service/graphql/Query';
import PostingRuleComponentAccordion from './PostingRuleComponentAccordion';
import AddPostingRuleComponentModal from './AddPostingRuleComponentModal';
import { UpdatePostingRuleComponentsInput } from 'src/types/accountingunit/PostingRule';
import { POSTING_RULE } from 'src/graphql/schema/query/accountingunit/PostingRule';

interface PostingRuleComponentSectionProps {
  accountingComponents: AccountingComponentFlat[];
}

const PostingRuleComponentSection: FC<PostingRuleComponentSectionProps> = ({ accountingComponents: ac }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [accountingComponents, setAccountingComponents] = useState<AccountingComponentFlat[]>(ac);
  const [arrangedAccountingComponents, setArrangedAccountingComponents] = useState<AccountingComponent[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editAccountingComponents, setEditAccountingComponents] = useState(false);
  const toggleEditAccountingComponents = () => setEditAccountingComponents((prev) => !prev);
  const { postingRuleId } = useParams();
  const updatePostingRuleComponents = useUpdatePostingRuleComponents();
  const toggleModal = () => setModalOpen((prev) => !prev);

  const addNewAccountingComponent = (newComponent: AccountingComponentFlat) => {
    setAccountingComponents((prev) => {
      const parent = prev.find((component) => component.id === newComponent.parentId);
      return parent
        ? [
            ...prev.filter((component) => component.id !== parent?.id),
            { ...parent, accountingComponents: [...parent.accountingComponents, newComponent.id] },
            newComponent,
          ]
        : [...prev, { ...newComponent, parentId: null }];
    });
  };

  const deleteAccountingComponent = (accountingComponentId: string) => {
    setAccountingComponents((prev) => {
      const componentToRemove = prev.find((component) => component.id === accountingComponentId);
      return [
        ...prev
          .map((component) => ({
            ...component,
            accountingComponents: component.accountingComponents.filter((childId) => childId !== componentToRemove.id),
          }))
          .filter((component) => component.id !== accountingComponentId),
      ];
    });
  };

  const updateAccountingComponentShare = (accountingComponentId: string, share: number) => {
    setAccountingComponents((prev) => [
      ...prev.map((component) => (component.id === accountingComponentId ? { ...component, share } : component)),
    ]);
  };

  const handleSavePostingRuleComponents = () => {
    const request: UpdatePostingRuleComponentsInput = {
      postingRuleId,
      accountingComponents: getInputComponent(arrangedAccountingComponents),
    };
    updatePostingRuleComponents(request, employerId).then(toggleEditAccountingComponents);
  };

  const getInputComponent = (accountingComponents: AccountingComponent[]): AccountingComponentInput[] =>
    accountingComponents.map((component) => ({
      id: component.id,
      accountingComponentValueId: component.accountingComponentValueId,
      share: component.share,
      accountingComponents: getInputComponent(component.accountingComponents),
    }));

  const handleEditCancel = () => {
    refetchQuery(POSTING_RULE);
    toggleEditAccountingComponents();
    setAccountingComponents(ac);
  };

  useEffect(() => {
    setArrangedAccountingComponents(
      arrangeFlatArrayAsTree<AccountingComponentFlat, AccountingComponent>(
        accountingComponents,
        'accountingComponents',
      ),
    );
  }, [accountingComponents]);

  return (
    <div className="posting-rule-components__container">
      {arrangedAccountingComponents && !recursiveHasGoodShare(arrangedAccountingComponents) && (
        <Banner
          className="posting-rule-components__container--share-banner"
          message={t(
            'economySettings:posting-rules-tab.posting-rule-details.posting-rule-components.wrong-share-banner',
          )}
          type="warning"
        />
      )}
      <Typography variant="h2">
        {t('economySettings:posting-rules-tab.posting-rule-details.posting-rule-components.accounting-components')}
      </Typography>
      <div className="posting-rule-components__container__edit">
        <div className="posting-rule-components__container__edit--switch">
          <Typography variant="caption">Lås upp för att editera</Typography>
          <Switch toggled={editAccountingComponents} onChange={toggleEditAccountingComponents} />
        </div>
        <div className="posting-rule-components__container__edit--buttons">
          <Button
            disabled={
              !editAccountingComponents ||
              (arrangedAccountingComponents && !recursiveHasGoodShare(arrangedAccountingComponents))
            }
            onClick={handleSavePostingRuleComponents}
            label={t('economySettings:posting-rules-tab.posting-rule-details.posting-rule-components.save-changes')}
          />
          <Button
            type="secondary"
            onClick={handleEditCancel}
            disabled={!editAccountingComponents}
            label={t('common:cancel')}
          />
        </div>
      </div>
      {arrangedAccountingComponents.map((accountingComponent) => (
        <PostingRuleComponentAccordion
          isEditable={editAccountingComponents}
          addNewAccountingComponent={addNewAccountingComponent}
          deleteAccountingComponent={deleteAccountingComponent}
          updateAccountingComponentShare={updateAccountingComponentShare}
          key={`PostingRuleComponentAccordion-${accountingComponent.id}`}
          accountingComponent={accountingComponent}
        />
      ))}
      {accountingComponents && accountingComponents.length === 0 && (
        <Typography>
          {t('economySettings:posting-rules-tab.posting-rule-details.posting-rule-components.no-accounting-component')}
        </Typography>
      )}
      <FloatingAddButton
        label={t(
          'economySettings:posting-rules-tab.posting-rule-details.posting-rule-components.add-accounting-component',
        )}
        onClick={toggleModal}
        disabled={!editAccountingComponents}
      />
      <AddPostingRuleComponentModal
        createAccountingComponent={addNewAccountingComponent}
        open={modalOpen}
        onClose={toggleModal}
        parentId={postingRuleId}
        parentType={'POSTING_RULE'}
      />
    </div>
  );
};

export default PostingRuleComponentSection;
