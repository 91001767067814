import { useEffect, useState } from 'react';
import { useCache } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import { CertifyConditionMetadata } from 'src/types/CertifyConditionMetadata';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';

export const useConditionsMetadata = () => {
  const [result, setResult] = useState<
    Service<{ employeeId: string; employerId: string; conditionsMetadata: CertifyConditionMetadata[] }[]>
  >({ status: INIT });
  const employeesReadyToFinalize = useCache<
    { employeeId: string; employerId: string; conditionsMetadata: CertifyConditionMetadata[] }[]
  >(
    EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE,
    [
      'employeeId',
      'employerId',
      { conditionsMetadata: ['benefitType', 'insuranceProviderId', 'insuranceProviderProductConditionId'] },
    ],
    true,
  );

  useEffect(() => {
    setResult({ status: LOADING });
    try {
      setResult({ status: LOADED, payload: employeesReadyToFinalize });
    } catch (error) {
      setResult({ status: ERROR, error });
    }
  }, [employeesReadyToFinalize]);

  return result;
};
