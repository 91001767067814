import React, { useEffect, useState } from 'react';
import './scroll-base.scss';
import ScrollLoadMore from './ScrollLoadMore';

interface ScrollBaseProps {
  children: JSX.Element[] | JSX.Element;
  maxHeightPercentage: number;
  className?: string;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  loadMore?: boolean;
}

const ScrollBase = ({ children, maxHeightPercentage, className, onScroll, loadMore }: ScrollBaseProps) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const outerRef = React.useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState(true);
  const maxHeightString = maxHeightPercentage.toString() + 'vh';

  useEffect(() => {
    document.fonts.ready.then(() => {
      contentRef?.current?.offsetHeight &&
        setOverflow(contentRef.current.offsetHeight > window.innerHeight * (maxHeightPercentage / 100));
    });
  }, [children]);

  return (
    <div
      onScroll={onScroll}
      style={{
        maxHeight: maxHeightString,
      }}
      className={[`scroll-base__container${overflow ? '--overflow' : ''}`, className].join(' ')}
      ref={outerRef}
    >
      <div className={[`scroll-base${overflow ? '--overflow' : ''}`].join(' ')} ref={contentRef}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            className: `scroll-base${overflow ? '--overflow' : ''}`,
          });
        })}
        {loadMore && <ScrollLoadMore />}
      </div>
    </div>
  );
};

export default ScrollBase;
