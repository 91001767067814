import { gql } from '@apollo/client';

export const CREATE_EMPLOYER_WAGE_TYPE = gql`
  mutation CreateEmployerWageType($request: EmployerWageTypeRequest!) {
    createEmployerWageType(request: $request) {
      id
      name
      wageType
      extentCalculation
    }
  }
`;
