import { gql } from '@apollo/client';

export const REMOVE_USER_FROM_GROUP = gql`
  mutation RemoveUserFromGroup($groupId: UUID!, $userId: UUID!) {
    removeUserFromGroup(groupId: $groupId, userId: $userId) {
      groupId
      userId
    }
  }
`;
