import { gql } from '@apollo/client';

export const UPDATE_EMPLOYER_REPORTING_FLOW_SETTINGS = gql`
  mutation UpdateEmployerReportingFlowSettings($request: UpdateEmployerReportingFlowSettingsRequest!) {
    updateEmployerReportingFlowSettings(request: $request) {
      id
      employerId
      disableAutomaticReporting
    }
  }
`;
