import React, { useState } from 'react';
import { Tabs, Tab, ListGroup, InlineEdit, Typography, Button, Icon } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { PolicyEntity, DRAFT } from 'src/types/policy/PolicyEntity';
import PolicyComments from './PolicyComments/PolicyComments';
import './policy-version-drawer.scss';
import PolicyChangesNavigation from './PolicyChangesNavigation';
import PolicyChangesDrawer from '../PolicyChangesDrawer';
import { ChangedObjectType, PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import { default as PolicyDrawerTitle } from './Title';
import { PolicyVersionDrawerTab } from 'src/types/tabs/PolicyVersionDrawerTab';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_POLICY_VERSION_CHANGES } from 'src/graphql/schema/query/policy/GetPolicyVersionChanges';
import DrawerBase from 'src/components/Common/DrawerBase/DrawerBase';

interface PolicyVersionDrawerProps {
  selectedPolicyEntity: PolicyEntity;
  handleSelectVersion: (policyId: string) => void;
  handleGoBack: () => void;
  onClose: () => void;
}

const PolicyVersionDrawer: React.FunctionComponent<PolicyVersionDrawerProps> = ({
  selectedPolicyEntity,
  handleSelectVersion,
  handleGoBack,
  onClose,
}) => {
  const [typeOfChanges, setTypeOfChanges] = useState<ChangedObjectType>();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<PolicyVersionDrawerTab>(PolicyVersionDrawerTab.ABOUT);

  const handleGoToPolicy = () => {
    handleSelectVersion(selectedPolicyEntity.id);
  };

  const handleSelectChanges = (type: ChangedObjectType) => {
    setTypeOfChanges(type);
  };

  const handleCloseChangesDrawer = () => {
    setTypeOfChanges(undefined);
    setCurrentTab(PolicyVersionDrawerTab.CHANGES);
  };

  const handleClose = () => {
    handleCloseChangesDrawer();
    onClose();
  };

  const fromDateText = formatDateTimeString(selectedPolicyEntity?.fromDate);
  const toDateText = formatDateTimeString(selectedPolicyEntity?.toDate);

  return (
    <QueryWrapper
      query={GET_POLICY_VERSION_CHANGES}
      options={{
        variables: { policyId: selectedPolicyEntity?.id },
        skip: !selectedPolicyEntity?.id,
      }}
    >
      {(policyVersionChanges: PolicyVersionChanges) => (
        <>
          {!typeOfChanges && (
            <DrawerBase
              leftButton={
                <Button type="link" label="Tillbaka" icon={<Icon type={'ArrowLeft'} />} onClick={handleGoBack} />
              }
              onClose={onClose}
            >
              <PolicyDrawerTitle policyEntity={selectedPolicyEntity} />
              <Button
                className="policy-version-drawer__go-to-version"
                size="large"
                type="secondary"
                label={t('account:employers-tab.policy-settings.policy-version-header.go-to-version')}
                icon={<Icon type="ArrowUndoDownLeft" />}
                onClick={handleGoToPolicy}
              />
              <Tabs tabIndex={currentTab}>
                <Tab key="policyAboutTab" title={t('account:employers-tab.policy-settings.drawer.about')}>
                  <ListGroup variant="drawer-navigation">
                    <InlineEdit bold label={t('account:employers-tab.policy-settings.drawer.version')}>
                      <Typography>{selectedPolicyEntity.version}</Typography>
                    </InlineEdit>
                    {selectedPolicyEntity.state !== DRAFT && (
                      <InlineEdit bold label={t('account:employers-tab.policy-settings.drawer.from-date')}>
                        <Typography variant="body2">{fromDateText}</Typography>
                      </InlineEdit>
                    )}
                    {selectedPolicyEntity.state !== DRAFT && (
                      <InlineEdit bold label={t('account:employers-tab.policy-settings.drawer.to-date')}>
                        <Typography variant="body2">
                          {toDateText || t('account:employers-tab.policy-settings.until-further-notice')}
                        </Typography>
                      </InlineEdit>
                    )}
                  </ListGroup>
                  <PolicyComments selectedPolicyEntity={selectedPolicyEntity} />
                </Tab>
                <Tab key="policyChangesTab" title={t('account:employers-tab.policy-settings.drawer.changes')}>
                  {policyVersionChanges && (
                    <PolicyChangesNavigation changes={policyVersionChanges} handleSelectChanges={handleSelectChanges} />
                  )}
                </Tab>
              </Tabs>
            </DrawerBase>
          )}
          {typeOfChanges && (
            <PolicyChangesDrawer
              changes={policyVersionChanges}
              typeOfChanges={typeOfChanges}
              handleCloseChangesDrawer={handleCloseChangesDrawer}
              onClose={handleClose}
              policyId={selectedPolicyEntity.id}
            />
          )}
        </>
      )}
    </QueryWrapper>
  );
};

export default PolicyVersionDrawer;
