import React, { FC, useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import { InsuranceTypeMappingsPage } from 'src/types/insurancetypemapping/InsuranceTypeMapping';
import InsuranceTypeMappingsTable from './InsuranceTypeMappingsTable';
import InsuranceTypeMappingsSearchInput from './InsuranceTypeMappingsSearchInput';
import { SEARCH_INSURANCE_TYPE_MAPPINGS } from 'src/graphql/schema/query/insurancetypemapping/SearchInsuranceTypeMappings';
import { InsuranceTypeMappingsRequest } from 'src/types/insurancetypemapping/InsuranceTypeMappingsRequest';
import { getInitialInsuranceTypeMappingsRequest } from './utils/insuranceTypeMappingUtils';
import { Banner, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import './insurance-type-mappings-tab.scss';

interface InsuranceTypeMappingsTabProps {
  accountingUnit: AccountingUnit;
}
const InsuranceTypeMappingsTab: FC<InsuranceTypeMappingsTabProps> = ({ accountingUnit }) => {
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 100;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const [insuranceTypeMappingsRequest, setInsuranceTypeMappingsRequest] = useState<InsuranceTypeMappingsRequest>(
    getInitialInsuranceTypeMappingsRequest(),
  );

  const handleTextSearch = (searchInput: string) => {
    setInsuranceTypeMappingsRequest({
      ...insuranceTypeMappingsRequest,
      searchInput,
    });
  };

  return (
    <div className="insurance-type-mappings-tab">
      <QueryWrapper
        query={SEARCH_INSURANCE_TYPE_MAPPINGS}
        options={{
          variables: {
            request: { searchInput: insuranceTypeMappingsRequest.searchInput, pageNumber: queryPageNumber, pageSize },
          },
          fetchPolicy: 'no-cache',
        }}
      >
        {({ searchInsuranceTypeMappings, paging }: InsuranceTypeMappingsPage) => {
          const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
          const showPagination = totalPages > 1;

          return (
            <>
              <div className="insurance-type-mappings-tab__search-input">
                <InsuranceTypeMappingsSearchInput
                  searchRequest={insuranceTypeMappingsRequest}
                  onSearch={handleTextSearch}
                />
              </div>
              {searchInsuranceTypeMappings.length === 0 ? (
                <Banner
                  type="success"
                  message={t('economySettings:insurance-type-mappings-tab.no-insurance-type-mappings-banner')}
                />
              ) : (
                <>
                  <InsuranceTypeMappingsTable
                    insuranceTypeMappings={searchInsuranceTypeMappings}
                    chartOfAccountsId={accountingUnit.chartOfAccounts.id}
                  />
                  {showPagination && (
                    <div data-testid="insurance-type-mappings-tab-pagination">
                      <Pagination
                        currentPage={pageNumber}
                        onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                        totalPages={totalPages}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          );
        }}
      </QueryWrapper>
    </div>
  );
};

export default InsuranceTypeMappingsTab;
