import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PayTypeTable from './PayTypeTable';
import PayTypeDrawer from './PayTypeDrawer';
import CreatePayTypeModal from './CreatePayTypeModal/CreatePayTypeModal';
import FloatingAddButton from 'src/components/Common/FloatingAddButton/FloatingAddButton';
import { GET_EMPLOYER_WAGE_TYPES } from 'src/graphql/schema/query/employer/GetEmployerWageTypes';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { GET_EMPLOYER_PAY_TYPES } from 'src/graphql/schema/query/employer/GetEmployerPayTypes';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';

interface PayTypeTabProps {
  employerId: string;
}

const PayTypeTab: React.FunctionComponent<PayTypeTabProps> = ({ employerId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPayTypeId, setSelectedPayTypeId] = useState<string>();
  const { t } = useTranslation();

  const handleOpenCreatePayTypeModal = () => {
    setModalOpen(true);
  };

  const handleCloseCreatePayTypeModal = () => {
    setModalOpen(false);
  };

  const handleDrawerOpen = (id: string) => {
    setSelectedPayTypeId(id);
  };

  const handleCloseDrawer = () => {
    setSelectedPayTypeId(undefined);
  };

  return (
    <QueryWrapper query={GET_EMPLOYER_WAGE_TYPES} options={{ variables: { employerId } }}>
      {(employerWageTypes: EmployerWageType[]) => (
        <QueryWrapper query={GET_EMPLOYER_PAY_TYPES} options={{ variables: { employerId } }}>
          {(employerPayTypes: EmployerPayType[]) => (
            <>
              <PayTypeTable
                employerId={employerId}
                employerPayTypes={employerPayTypes}
                employerWageTypes={employerWageTypes}
                handleDrawerOpen={handleDrawerOpen}
              />
              {selectedPayTypeId !== undefined && (
                <PayTypeDrawer
                  employerId={employerId}
                  open={selectedPayTypeId !== undefined}
                  onClose={handleCloseDrawer}
                  employerPayType={employerPayTypes.find((employerPayType) => employerPayType.id === selectedPayTypeId)}
                  employerWageTypes={employerWageTypes}
                />
              )}
              {modalOpen && (
                <CreatePayTypeModal
                  open={modalOpen}
                  employerId={employerId}
                  onClose={handleCloseCreatePayTypeModal}
                  employerWageTypes={employerWageTypes}
                />
              )}
              <FloatingAddButton
                data-cy="add-wage-type-mapping-button"
                label={t('account:employers-tab.wage-type-mapping-settings.add-wage-type-mapping')}
                onClick={handleOpenCreatePayTypeModal}
              />
            </>
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  );
};

export default PayTypeTab;
