import { Loader, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentSlot from '../ContentSlot';
import './loading-page.scss';

interface LoadingPageProps {
  className?: string;
}

const LoadingPage = ({ className }: LoadingPageProps) => {
  const { t } = useTranslation();
  return (
    <ContentSlot className={['loading-page', className].join(' ')}>
      <Loader type="blue" size="large" />
      <Typography className="loading-page__text" variant="h3">
        {t('common:loading-content')}
      </Typography>
    </ContentSlot>
  );
};

export default LoadingPage;
