import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ContractedInsuranceProviders } from 'src/types/contract/ContractedInsuranceProviders';
import { GET_CONTRACTED_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/contract/GetContractedInsuranceProviders';

export const useContractedInsuranceProviders = (): ContractedInsuranceProviders[] => {
  const [result, setResult] = useState<ContractedInsuranceProviders[]>();
  const { loading, error, data } = useQuery(GET_CONTRACTED_INSURANCE_PROVIDERS);

  useEffect(() => {
    data && setResult(data.contractedInsuranceProviders);
  }, [loading, error, data]);

  return result;
};
