import React from 'react';
import { RuleGroupProps } from '../RuleGroupProps';
import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import IndividualChoiceITP1Rule from './IndividualChoiceITP1Rule';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import { employerHasIndividualChoice } from 'src/util/IndividualChoiceUtil';

interface IndividualChoiceRuleGroupProps extends RuleGroupProps {
  employerIndividualChoices: EmployerIndividualChoice[];
}

function IndividualChoiceRuleGroup({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
  employerIndividualChoices,
}: IndividualChoiceRuleGroupProps) {
  const employerHasITP1IndividualChoice = employerHasIndividualChoice('ITP1', employerIndividualChoices);

  if (!employerHasITP1IndividualChoice) {
    return null;
  }

  return (
    <div className="rule-type-drawer__list-group">
      <IndividualChoiceITP1Rule
        policyState={policyState}
        selectedAffiliationRuleGroup={selectedAffiliationRuleGroup}
        onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        policyRuleType={PolicyRuleType.INDIVIDUAL_CHOICE_ITP1}
      />
    </div>
  );
}

export default IndividualChoiceRuleGroup;
