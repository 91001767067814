import { Banner, IconPill, InteractiveCard, Pill, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_QUALIFIED_BENEFITS } from 'src/graphql/schema/query/employee/GetQualifiedBenefits';
import { EmployeeCardBenefit } from 'src/types/employees/EmployeeCardBenefit';
import { useState } from 'react';
import BenefitsOverviewDrawer from '../BenefitsOverviewDrawer/BenefitsOverviewDrawer';
import EmployeeSectionLabel from '../../EmployeeSectionLabel/EmployeeSectionLabel';
import InteractiveCardBox from 'src/components/Common/InteractiveCardBox';
import { GET_POLICY_EMPLOYMENT_GROUP } from 'src/graphql/schema/query/policy/GetPolicyEmploymentGroup';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { stringOrBlank } from 'src/util/StringUtil';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import When from 'src/components/Common/When';
import './benefits-overview-section.scss';

interface BenefitsOverviewSectionProps {
  employeeId: string;
  period: string;
  employeeCard: AggregatedEmployeeCard;
}

const BenefitsOverviewSection = ({ employeeId, period, employeeCard }: BenefitsOverviewSectionProps) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState<EmployeeCardBenefit>();
  const policyId = employeeCard?.evaluationPolicyEntityId;
  const employmentGroupId = employeeCard?.employmentGroupId;

  const handleDisplayDetailsDrawer = (benefit: EmployeeCardBenefit) => {
    setDrawerOpen(true);
    setSelectedBenefit(benefit);
  };

  const handleDisplayOverviewDrawer = () => {
    setDrawerOpen(true);
  };

  const handleOnClose = () => {
    setDrawerOpen(false);
    setSelectedBenefit(null);
  };

  const handleOnBackClick = () => {
    setSelectedBenefit(null);
  };

  return (
    <>
      {!employeeCard?.isDraft && (
        <QueryWrapper query={GET_QUALIFIED_BENEFITS} options={{ variables: { employeeId, period } }}>
          {(qualifiedBenefits: EmployeeCardBenefit[]) => {
            const onlyEntitledQualifiedBenefits = qualifiedBenefits.filter((benefit) => benefit.isEntitled);
            const isDrawerDisabled = qualifiedBenefits.length === 0 || !policyId;

            return (
              <InteractiveCard
                label={
                  <EmployeeSectionLabel label={t('employees:benefits')} period={period}>
                    {(!policyId && !employmentGroupId) || !employmentGroupId ? (
                      <Pill label={t('employees:employmentGroup')} type="heads-up" />
                    ) : (
                      <QueryWrapper
                        query={GET_POLICY_EMPLOYMENT_GROUP}
                        options={{ variables: { policyId, employmentGroupId } }}
                      >
                        {(data: PolicyEmploymentGroup) => (
                          <IconPill icon="Users" label={stringOrBlank(data?.employmentGroupName)} />
                        )}
                      </QueryWrapper>
                    )}
                  </EmployeeSectionLabel>
                }
                buttonLabel={t('benefits:open-benefits-drawer-label')}
                onClick={handleDisplayOverviewDrawer}
                disabledDrawer={isDrawerDisabled}
              >
                <>
                  <When condition={!policyId}>
                    <Banner type="announcement" message={t('employees:no-qualified-benefits')} />
                  </When>
                  <When condition={policyId && onlyEntitledQualifiedBenefits.length === 0}>
                    <Banner type="announcement" message={t('employees:not-entitled-to-qualified-benefits')} />
                  </When>
                  <div className="benefits-overview-section">
                    {onlyEntitledQualifiedBenefits.slice(0, 3).map((benefit, index) => (
                      <InteractiveCardBox
                        className="benefits-overview-section__box"
                        key={`${benefit.benefitType}${index}`}
                        label={benefit.name}
                        onClick={onlyEntitledQualifiedBenefits ? () => handleDisplayDetailsDrawer(benefit) : undefined}
                      >
                        <Pill type="neutral" size="small" label={t(`benefitTypes:${benefit.benefitType}`)} />
                      </InteractiveCardBox>
                    ))}
                    {onlyEntitledQualifiedBenefits.length > 3 && (
                      <InteractiveCardBox
                        className="benefits-overview-section__box"
                        onClick={handleDisplayOverviewDrawer}
                        label={
                          <Typography className="benefits-overview-section__box-label" variant="body2" bold>
                            {`+${onlyEntitledQualifiedBenefits.length - 3}`}
                          </Typography>
                        }
                      />
                    )}
                  </div>
                  {drawerOpen && (
                    <BenefitsOverviewDrawer
                      open={drawerOpen}
                      benefits={qualifiedBenefits}
                      selectedBenefit={selectedBenefit}
                      handleSelectBenefit={handleDisplayDetailsDrawer}
                      onBackClick={handleOnBackClick}
                      onClose={handleOnClose}
                      period={period}
                    />
                  )}
                </>
              </InteractiveCard>
            );
          }}
        </QueryWrapper>
      )}
    </>
  );
};

export default BenefitsOverviewSection;
