import { Accordion, Typography, Button, Icon } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { toNumber } from 'src/util/StringUtil';
import { useTranslation } from 'react-i18next';
import { AccountingComponent, AccountingComponentFlat } from 'src/types/accountingunit/AccountingComponent';
import AddAccountingComponentModal from './AddAccountingComponentModal';
import AccountingComponentAccordionHeader from './AccountingComponentAccordionHeader';

interface AccountingComponentAccordionProps {
  accountingComponent: AccountingComponent;
  addNewAccountingComponent: (newComponent: AccountingComponentFlat) => void;
  deleteAccountingComponent: (accountingComponentId: string) => void;
  updateAccountingComponentShare: (accountingComponentId: string, share: number) => void;
  isEditable: boolean;
}

const AccountingComponentAccordion: FC<AccountingComponentAccordionProps> = ({
  accountingComponent,
  addNewAccountingComponent,
  deleteAccountingComponent,
  updateAccountingComponentShare,
  isEditable,
}) => {
  const { t } = useTranslation();
  const [share, setShare] = useState(accountingComponent.share);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((prev) => !prev);
  const handleShareChange = (e: ChangeEvent<HTMLInputElement>) => setShare(toNumber(e.target.value));

  const handleShareUpdate = () => {
    updateAccountingComponentShare(accountingComponent.id, share);
  };
  const handleDeleteAccountingComponent = () => {
    deleteAccountingComponent(accountingComponent.id);
  };
  return (
    <Accordion
      className="accounting-type__accounting-component-accordion"
      header={
        <AccountingComponentAccordionHeader
          accountingComponent={accountingComponent}
          isEditable={isEditable}
          share={share}
          onShareChange={handleShareChange}
          onShareUpdate={handleShareUpdate}
        />
      }
      icon={
        <Button
          disabled={!isEditable}
          data-cy={`delete-accounting-component-${accountingComponent.name.replace(' ', '')}-button`}
          className="agreed-product-details__button"
          icon={<Icon type="Trash" />}
          type="secondary"
          onClick={handleDeleteAccountingComponent}
        />
      }
    >
      <div className="accounting-type__accounting-component-accordion__body">
        <div className="accounting-type__accounting-component-accordion__body--component">
          <Typography variant="h4">{`${t('economySettings:accounting-type-page.accounting-components')} ${
            accountingComponent.type
          } - ${accountingComponent.name}`}</Typography>
          <div className="accounting-type__accounting-component-accordion__body--component--accordion">
            {accountingComponent.accountingComponents.length > 0 ? (
              accountingComponent.accountingComponents.map((accountingComponent) => (
                <AccountingComponentAccordion
                  isEditable={isEditable}
                  addNewAccountingComponent={addNewAccountingComponent}
                  deleteAccountingComponent={deleteAccountingComponent}
                  updateAccountingComponentShare={updateAccountingComponentShare}
                  key={`AccountingComponentAccordion-${accountingComponent.id}`}
                  accountingComponent={accountingComponent}
                />
              ))
            ) : (
              <Typography>{`${t('economySettings:accounting-type-page.no-accounting-components')} ${
                accountingComponent.type
              } ${accountingComponent.name}`}</Typography>
            )}
          </div>
          <div>
            <Button
              onClick={toggleModalOpen}
              disabled={!isEditable}
              label={t('economySettings:accounting-type-page.add-accounting-component')}
            />
            <AddAccountingComponentModal
              parentId={accountingComponent.id}
              parentType="ACCOUNTING_COMPONENT"
              open={modalOpen}
              onClose={toggleModalOpen}
              createAccountingComponent={addNewAccountingComponent}
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default AccountingComponentAccordion;
