import { Banner, InteractiveCard, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeComment } from 'src/types/employees/EmployeeComment';
import EmployeeCommentsDrawer from '../EmployeeCommentsDrawer';
import { Employee } from 'src/types/Employee';
import InteractiveCardBox from 'src/components/Common/InteractiveCardBox';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import { sortCommentsFunction } from '../utils';
import './employee-comments-overview-section.scss';

interface EmployeeCommentsOverviewSectionProps {
  employee: Employee;
  employeeComments: EmployeeComment[];
}

const EmployeeCommentsOverviewSection: FC<EmployeeCommentsOverviewSectionProps> = ({ employeeComments, employee }) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const hasComments = employeeComments?.length > 0;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const sortedEmployeeComments = employeeComments ? [...employeeComments].sort(sortCommentsFunction) : [];

  return (
    <>
      <InteractiveCard
        label={t('employeeComments:comment-overview-section.title')}
        onClick={handleDrawerOpen}
        data-cy="employee-comment-overview-section"
      >
        <>
          {hasComments ? (
            <div className="employee-comments-overview-section">
              {sortedEmployeeComments.slice(0, 3).map((comment, index) => (
                <InteractiveCardBox
                  className="employee-comments-overview-section__box"
                  key={`EmployeeCommentInteractiveCardBox-${comment.id}-${index}`}
                  label={formatDateTimeString(comment.createdAt)}
                  value={comment.comment}
                  onClick={handleDrawerOpen}
                />
              ))}
              {sortedEmployeeComments.length > 3 && (
                <InteractiveCardBox
                  className="employee-comments-overview-section__box"
                  onClick={handleDrawerOpen}
                  label={
                    <Typography className="employee-comments-overview-section__box-label" variant="body2" bold>{`+${
                      sortedEmployeeComments.length - 3
                    }`}</Typography>
                  }
                />
              )}
            </div>
          ) : (
            <Banner type="announcement" message={t('employeeComments:comment-overview-section.no-comments-message')} />
          )}
        </>
      </InteractiveCard>
      {drawerOpen && (
        <EmployeeCommentsDrawer
          open={drawerOpen}
          onClose={handleCloseDrawer}
          employeeComments={employeeComments}
          employee={employee}
        />
      )}
    </>
  );
};

export default EmployeeCommentsOverviewSection;
