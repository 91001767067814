import { ListGroup, InlineEdit, Select, Option } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { isBlank } from 'src/util/StringUtil';
import AgreedProductData from '../AgreedProductData';
import { BenefitAgreedProductRequest } from 'src/types/policy/request/BenefitAgreedProductRequest';
import { useCreateBenefitAgreedProduct } from 'src/service/policy/CreateBenefitAgreedProduct';
import { useUpdateBenefitAgreedProduct } from 'src/service/policy/UpdateBenefitAgreedProduct';
import { UpdateBenefitAgreedProductRequest } from 'src/types/policy/request/UpdateBenefitAgreedProductRequest';
import { useTranslation } from 'react-i18next';

interface AddAgreedProductInputsProps {
  contracts: InsuranceProviderContract[];
  benefitId: string;
}

const AddAgreedProductInputs = ({ contracts, benefitId }: AddAgreedProductInputsProps) => {
  const { employerId, policyId } = useParams();
  const [contractAgreedProductId, setContractAgreedProductId] = useState<string>();
  const agreedProducts = contracts.flatMap((contract) => contract.agreedProducts);
  const agreedProductOptions = createOptionsFromList(agreedProducts, 'id', 'name');
  const [selectedContract, setselectedContract] = useState<InsuranceProviderContract>();
  const createBenefitAgreedProduct = useCreateBenefitAgreedProduct();
  const updateBenefitAgreedProduct = useUpdateBenefitAgreedProduct();
  const { t } = useTranslation();

  useEffect(() => {
    setselectedContract(
      contracts.find((contract) => contract.agreedProducts.find((product) => product.id === contractAgreedProductId)),
    );
  }, [contractAgreedProductId]);

  const handleAgreedProductChange = (option: Option<string>) => {
    if (contractAgreedProductId) {
      const request: UpdateBenefitAgreedProductRequest = {
        fromContractAgreedProductId: contractAgreedProductId,
        toContractAgreedProductId: option.value,
        policyId,
        benefitId,
      };
      updateBenefitAgreedProduct(request, employerId);
    } else {
      const request: BenefitAgreedProductRequest = {
        contractAgreedProductId: option.value,
        policyId,
        benefitId,
      };

      createBenefitAgreedProduct(employerId, request);
    }

    setContractAgreedProductId(option.value);
  };

  return (
    <>
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('agreementRegistrySettings:agreed-product-name')} bold>
          <Select
            placeholder={t('common:select')}
            options={agreedProductOptions}
            selected={agreedProductOptions.find((option) => option.value === contractAgreedProductId)}
            onChange={handleAgreedProductChange}
            data-cy="contract-agreed-products__dropdown"
            small
          />
        </InlineEdit>
      </ListGroup>

      {!isBlank(contractAgreedProductId) && selectedContract && (
        <div className="policy-benefit-drawer__tab">
          <AgreedProductData
            agreementNumber={selectedContract.agreementNumber}
            agreedProduct={selectedContract.agreedProducts.find((product) => product.id === contractAgreedProductId)}
          />
        </div>
      )}
    </>
  );
};

export default AddAgreedProductInputs;
