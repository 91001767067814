import React from 'react';
import { useTranslation } from 'react-i18next';
import './reporting-event-details-row.scss';
import { TableRow, Td, Typography, Pill } from 'fgirot-k2-ui-components';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_POLICY_EMPLOYMENT_GROUP } from 'src/graphql/schema/query/policy/GetPolicyEmploymentGroup';
import { Employee } from 'src/types/Employee';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';
import { getCorrectEventValue } from 'src/util/InsuranceEventUtil';

interface ReportingEventDetailsRowProps {
  reportingEvent: ReportingEvent;
  employee: Employee;
  onSelectEvent: (event: ReportingEvent) => void;
}

function ReportingEventDetailsRow({ reportingEvent, employee, onSelectEvent }: ReportingEventDetailsRowProps) {
  const { t } = useTranslation();
  const { reportingEventType, insuranceEventType, insuranceProvider, benefitType } = reportingEvent;
  const { employmentNumber } = employee;
  const { evaluationPolicyEntityId, employmentGroupId } = reportingEvent.employeeCard;
  const hasBeenAssignedEmploymentGroup = evaluationPolicyEntityId && employmentGroupId;
  const messageStatus = reportingEvent.messageReceipt?.messageStatus || 'QUEUED';

  return (
    <TableRow
      onClick={() => onSelectEvent(reportingEvent)}
      data-testid={`reporting-events-details-row-${reportingEvent.id}`}
    >
      <Td compressed>
        <div className="reporting-event-details-row__status">
          <Typography variant="caption">{t(`reportingTypes:${reportingEventType}`)}</Typography>
          <Pill size="small" type="heads-up" label={t(`insuranceEventTypes:${insuranceEventType}`)} />
        </div>
      </Td>
      <Td compressed>{employmentNumber}</Td>
      <Td compressed>
        {hasBeenAssignedEmploymentGroup ? (
          <QueryWrapper
            query={GET_POLICY_EMPLOYMENT_GROUP}
            options={{
              variables: {
                policyId: evaluationPolicyEntityId,
                employmentGroupId,
              },
            }}
          >
            {(data: PolicyEmploymentGroup) => <>{stringOrBlank(data?.employmentGroupName)}</>}
          </QueryWrapper>
        ) : (
          '-'
        )}
      </Td>
      <Td compressed>{stringOrBlank(reportingEvent.aggregatedInsuranceEvent?.eventFromDate)}</Td>
      <Td compressed>
        <Pill type="neutral" size="small" label={t(`benefitTypes:abbreviations.${benefitType}`)} />
      </Td>
      <Td compressed>{stringOrBlank(insuranceProvider?.legalName)}</Td>
      <Td compressed>
        {reportingEvent.aggregatedInsuranceEvent?.eventValues?.length > 0
          ? reportingEvent.aggregatedInsuranceEvent?.eventValues?.map((eventValue) => (
              <div className="insurance-event-details-row__calculations" key={eventValue.eventValueType}>
                <div className="insurance-event-details-row__calculations--details">
                  <Pill type="neutral" size="small" label={eventValue.eventValueType} />
                  <Typography variant="caption">
                    {formatSweAmountTextWithSuffixNoDecimal(getCorrectEventValue(eventValue))}
                  </Typography>
                </div>
              </div>
            ))
          : '-'}
      </Td>
      <Td compressed>
        {reportingEvent.aggregatedInsuranceEvent?.insuranceEventStatus
          ? t(`insuranceEventStatus:${reportingEvent.aggregatedInsuranceEvent?.insuranceEventStatus}`)
          : '-'}
      </Td>
      <Td compressed>{t(`reporting:message-status.${messageStatus}`)}</Td>
    </TableRow>
  );
}

export default ReportingEventDetailsRow;
