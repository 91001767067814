import { Select } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { Option } from 'fgirot-k2-ui-components';
import { useCreateEmployeeIndividualSettings } from 'src/service/employee/CreateEmployeeIndividualSetting';
import { CreateEmployeeIndividualSettingsRequest } from 'src/types/employees/request/CreateEmployeeIndividualSettingsRequest';
import { EmployeeIndividualSettings } from 'src/types/employees/EmployeeIndividualSettings';
import { useTranslation } from 'react-i18next';

interface CostCentreInputProps {
  contract: InsuranceProviderContract;
  settings: EmployeeIndividualSettings;
  employeeId: string;
}

function CostCentreInput({ contract, employeeId, settings }: CostCentreInputProps) {
  const [selectedCostCentre, setSelectedCostCentre] = React.useState<string>(null);
  const createEmployeeIndividualSettings = useCreateEmployeeIndividualSettings();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const costCentreOptions: Option<string>[] = contract.agreementCostCentres.map((costCentre) => ({
    label: costCentre.code,
    value: costCentre.code,
  }));

  useEffect(() => {
    const relevantCostCentreSettings = settings?.individualSettingsRows.find(
      (setting) =>
        setting.referenceId === contract.insuranceProvider.id &&
        setting.referenceType === 'INSURANCE_PROVIDER_ID' &&
        setting.settingsType === 'INSURANCE_AGREEMENT_COST_CENTRE',
    );

    if (relevantCostCentreSettings) {
      setSelectedCostCentre(relevantCostCentreSettings.settingsValue);
    }
  }, [settings]);

  const handleSelectCostCentre = (option: Option<string>) => {
    setSelectedCostCentre(option.value);

    const employerId = contract.employerId;

    const createEmployeeIndividualSettingsRequest: CreateEmployeeIndividualSettingsRequest = {
      settingsType: 'INSURANCE_AGREEMENT_COST_CENTRE',
      settingsValue: option.value,
      referenceType: 'INSURANCE_PROVIDER_ID',
      referenceId: contract.insuranceProvider.id,
    };

    setLoading(true);
    createEmployeeIndividualSettings(employeeId, createEmployeeIndividualSettingsRequest, employerId).finally(() =>
      setLoading(false),
    );
  };

  return (
    <Select
      options={costCentreOptions}
      selected={costCentreOptions.find((option) => option.value === selectedCostCentre)}
      onChange={handleSelectCostCentre}
      placeholder={t('common:select')}
      small
      disabled={loading}
    />
  );
}

export default CostCentreInput;
