import { Banner, Button, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeInsurance } from 'src/types/insurance/Insurance';

interface ReplacingInsurancesBannersProps {
  employeeInsurance: EmployeeInsurance;
  employeeInsurances: EmployeeInsurance[];
  replaceSelectedInsurance: (employeeInsurance: EmployeeInsurance) => void;
}

const ReplacingInsurancesBanners = ({
  employeeInsurance,
  employeeInsurances,
  replaceSelectedInsurance,
}: ReplacingInsurancesBannersProps) => {
  const replacedByInsurance = getReplacedByInsurance(employeeInsurance, employeeInsurances);
  const replacesInsurance = getReplacesInsurance(employeeInsurance, employeeInsurances);
  const { t } = useTranslation();

  const CustomBanner = ({ message, insuranceName, onClick }) => (
    <Banner
      type="announcement"
      message={
        <div className="insurance-details-drawer__replacing-insurances-banner">
          <Typography variant="body2">{`${message} `}</Typography>
          <Button type="secondary" label={insuranceName} onClick={onClick} size="small" />
        </div>
      }
    />
  );

  return (
    <section>
      {replacedByInsurance && (
        <CustomBanner
          message={t('insurances:insurance-replaced-by')}
          insuranceName={replacedByInsurance?.insurance.name}
          onClick={() => replaceSelectedInsurance(replacedByInsurance)}
        />
      )}
      {replacesInsurance && (
        <CustomBanner
          message={t('insurances:insurance-replaces')}
          insuranceName={replacesInsurance?.insurance.name}
          onClick={() => replaceSelectedInsurance(replacesInsurance)}
        />
      )}
    </section>
  );
};

function getReplacedByInsurance(employeeInsurance: EmployeeInsurance, employeeInsurances: EmployeeInsurance[]) {
  const replacedByInsurance = employeeInsurances.find(
    (insurance) => insurance.insurance.id === employeeInsurance.insurance.replacedByInsuranceId,
  );
  return replacedByInsurance;
}

function getReplacesInsurance(employeeInsurance: EmployeeInsurance, employeeInsurances: EmployeeInsurance[]) {
  const replacesInsurance = employeeInsurances.find(
    (insurance) => insurance.insurance.id === employeeInsurance.insurance.replacesInsuranceId,
  );
  return replacesInsurance;
}

export default ReplacingInsurancesBanners;
