import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PostingRule } from 'src/types/accountingunit/PostingRule';
import { useTranslation } from 'react-i18next';
import { stringOrBlank } from 'src/util/StringUtil';

interface PostingRuleTableRowProps {
  postingRule: PostingRule;
}

const PostingRuleTableRow: FC<PostingRuleTableRowProps> = ({ postingRule }) => {
  const { employerId, customerName } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/account/${customerName}/${employerId}/economy-settings/posting-rule/${postingRule.id}`);
  };

  return (
    <TableRow onClick={handleNavigate}>
      <Td data-cy="posting-rule-insurance-provider">
        {stringOrBlank(postingRule.insuranceTypeMapping.insuranceProvider?.legalName)}
      </Td>
      <Td>{stringOrBlank(postingRule.insuranceTypeMapping?.name)}</Td>
      <Td data-cy="posting-rule-benefit-type">
        {postingRule.insuranceTypeMapping?.benefitType
          ? stringOrBlank(t(`benefitTypes:${postingRule.insuranceTypeMapping?.benefitType}`))
          : '-'}
      </Td>
      <Td data-cy="posting-rule-insurance-category">
        {stringOrBlank(
          t(`insuranceTypeMapping:insurance-category.${postingRule.insuranceTypeMapping.insuranceCategory}`),
        )}
      </Td>
      <Td>{stringOrBlank(`${postingRule.accountingRow.accountNumber}`)}</Td>
    </TableRow>
  );
};
export default PostingRuleTableRow;
