import { Banner } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import EmploymentOverviewSection from '../EmploymentOverviewSection';
import { Employee } from 'src/types/Employee';
import { useTranslation } from 'react-i18next';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';

interface EmployeeCardSectionProps {
  employee: Employee;
  employeeCard?: AggregatedEmployeeCard;
  period: string;
}
const EmployeeCardSection: FC<EmployeeCardSectionProps> = ({ employee, employeeCard, period }) => {
  const { t } = useTranslation();

  return employeeCard !== null ? (
    <EmploymentOverviewSection period={period} employee={employee} employeeCard={employeeCard} />
  ) : (
    <Banner type="warning" message={t('employees:employee-overview.no-employee-card-for-period')} />
  );
};

export default EmployeeCardSection;
