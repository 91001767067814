import { gql } from '@apollo/client';

export const GET_MISSING_EMPLOYEE_CHECKPOINT = gql`
  query GetMissingEmployeeCheckpoint($employerIds: [UUID]!, $errandId: UUID) {
    checkpoints(employerIds: $employerIds, errandId: $errandId, actionCategory: "INVOICE_EMPLOYEE_MISSING") {
      id
      employeeId
      employerId
      errandId
      errandEntryId
      subActionCategory
      checkpointStatus
    }
  }
`;
