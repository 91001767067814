import React, { useEffect, useState } from 'react';
import { getInitialSearchAccountingItemsRequest } from './utils/accountingItemUtils';
import { GET_ACCOUNTING_ITEMS } from 'src/graphql/schema/query/ledgerstorage/GetAccountingItems';
import {
  SearchAccountingItemsRequest,
  SearchAccountingItemsRequestFilters,
} from 'src/types/ledgerStorage/SearchAccountingItemsRequest';
import { AccountingItemsPage } from 'src/types/ledgerStorage/AccountingItem';
import AccountingItems from './AccountingItems';
import './accounting-items-tab.scss';
import { useQuery } from '@apollo/client';

const AccountingItemsTab = () => {
  const [searchAccountingItemsRequest, setSearchAccountingItemsRequest] = useState<SearchAccountingItemsRequest>(
    getInitialSearchAccountingItemsRequest(),
  );
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);
  const [itemPage, setItemPage] = useState<AccountingItemsPage>();

  const handleTextSearch = (searchInput: string) => {
    setSearchAccountingItemsRequest({
      ...searchAccountingItemsRequest,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: SearchAccountingItemsRequestFilters) => {
    setSearchAccountingItemsRequest({
      ...searchAccountingItemsRequest,
      filters,
    });
  };

  const { data, loading } = useQuery<{
    accountingItems: AccountingItemsPage;
  }>(GET_ACCOUNTING_ITEMS, {
    fetchPolicy: 'no-cache',
    variables: {
      request: {
        searchInput: searchAccountingItemsRequest?.searchInput,
        pageNumber: pageNumber,
        pageSize: pageSize,
        filters: {
          ...searchAccountingItemsRequest?.filters,
        },
      },
    },
    skip: !searchAccountingItemsRequest?.filters?.employerId,
  });

  useEffect(() => {
    if (!loading && data) {
      setItemPage(data.accountingItems);
    }
  }, [loading]);
  return (
    <AccountingItems
      loading={loading}
      accountingItems={itemPage?.accountingItems}
      paging={itemPage?.paging}
      pageNumber={pageNumber}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchRequest={searchAccountingItemsRequest}
      onTextSearch={handleTextSearch}
      onApplyFilters={handleApplyFilters}
      onPageChange={handlePageChange}
    />
  );
};

export default AccountingItemsTab;
