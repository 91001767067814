import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InvoicesTab from 'src/components/Economy/InvoicesTab/InvoicesTab';
import EconomyOverviewTab from 'src/components/Economy/EconomyOverviewTab/EconomyOverviewTab';
import PageBase from 'src/components/PageBase';
import useTabParams from 'src/components/hooks/useTabParams';
import { EconomyTab } from 'src/types/tabs/EconomyTab';
import AccountingItemsTab from 'src/components/Economy/AccountingItemsTab';
import { useCustomer } from 'src/service/customer/GetCustomer';

const Economy = () => {
  const { t } = useTranslation();
  const customer = useCustomer();
  const [currentTab, setCurrentTab] = useTabParams('tab', EconomyTab.OVERVIEW);

  return (
    <PageBase header title={t('economy:title')} breadCrumbs={[{ name: customer?.name, steps: 1 }]}>
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('economy:tabs.overview')}>
          <EconomyOverviewTab />
        </Tab>
        <Tab title={t('economy:tabs.invoices')}>
          <InvoicesTab />
        </Tab>
        <Tab title={t('economy:tabs.accounting-items')}>
          <AccountingItemsTab />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default Economy;
