import React from 'react';
import QueryWrapperList from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_EVENT_COMMENTS } from 'src/graphql/schema/query/insurance/GetInsuranceEventComments';
import { InsuranceEvent, InsuranceEventComment } from 'src/types/reporting/InsuranceEvent';
import InsuranceEventCommentEntry from './InsuranceEventCommentEntry';
import { Divider } from 'fgirot-k2-ui-components';
import './insurance-event-comments-tab.scss';

interface InsuranceEventCommentsTabProps {
  insuranceEvent: InsuranceEvent;
}

function InsuranceEventCommentsTab({ insuranceEvent }: InsuranceEventCommentsTabProps) {
  return (
    <div className="insurance-event-comments-tab">
      <QueryWrapperList
        query={GET_INSURANCE_EVENT_COMMENTS}
        options={{ variables: { insuranceEventId: insuranceEvent.insuranceEventId } }}
      >
        {(data: InsuranceEventComment[]) => {
          const sortedComments = [...data].sort((a, b) => {
            return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          });

          return (
            <ul className="insurance-event-comments-tab__comments">
              {sortedComments.map((comment: InsuranceEventComment) => {
                return (
                  <div key={comment.createdDate} className="insurance-event-comments-tab__comment">
                    <InsuranceEventCommentEntry comment={comment} />
                    <Divider />
                  </div>
                );
              })}
            </ul>
          );
        }}
      </QueryWrapperList>
    </div>
  );
}

export default InsuranceEventCommentsTab;
