import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployerWageFileSetting } from 'src/types/employer/EmployerWageFileSetting';
import AboutDrawerTab from './AboutDrawerTab';
import './wage-file-settings-drawer.scss';
import { Employer } from 'src/types/employer/Employer';

interface WageFileSettingsDrawerProps {
  open: boolean;
  employer: Employer;
  employerWageFileSetting: EmployerWageFileSetting;
  onClose: () => void;
}

const WageFileSettingsDrawer: FC<WageFileSettingsDrawerProps> = ({
  open,
  employer,
  employerWageFileSetting,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase title={t('reportingSettings:wage-file')} subTitle={employer.legalName} onClose={onClose}>
        <Tabs>
          <Tab title={t('common:about')}>
            <AboutDrawerTab employer={employer} employerWageFileSetting={employerWageFileSetting} />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
};

export default WageFileSettingsDrawer;
