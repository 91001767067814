import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { createOptions, createOptionsFromList } from 'src/util/OptionUtil';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { unique } from 'src/util/ArrayUtil';
import { InvoiceStatus, InvoiceTypeCode } from 'src/types/invoice/Invoice';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import './search-invoices-filters-modal.scss';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { useEmployersInCustomer } from 'src/service/employer/GetEmployersInCustomer';
import { Customer } from 'src/types/customer/Customer';

interface SearchInvoicesFiltersModalProps {
  customer: Customer;
  open: boolean;
  filters: SearchInvoicesFilter;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchInvoicesFilter) => void;
}

const SearchInvoicesFiltersModal: FC<SearchInvoicesFiltersModalProps> = ({
  customer,
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();
  const employers = useEmployersInCustomer(customer.id, 'cache-first');
  const [newFilters, setNewFilters] = useState<SearchInvoicesFilter>({ ...filters });

  const handleInvoiceStatusesChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      invoiceStatuses: options.map((option) => option.value),
    });
  };

  const handleSearchInvoiceTypeCodeChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      invoiceTypes: options.map((option) => option.value),
    });
  };

  const handleEmployersChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      employerIds: options.map((option) => option.value),
    });
  };

  const handleInsuranceProviderChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceProviderIds: options.map((option) => option.value),
    });
  };

  const handleFromPaymentDueDateChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      paymentDueDate: {
        fromDate: value,
        toDate: newFilters.paymentDueDate.toDate,
      },
    });
  };

  const handleToPaymentDueDateChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      paymentDueDate: {
        fromDate: newFilters.paymentDueDate.fromDate,
        toDate: value,
      },
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  const employerOptions =
    employers.status !== 'loaded'
      ? []
      : employers.payload.map(
          (employer) =>
            ({
              label: employer.legalName,
              value: employer.id,
            } as Option<string>),
        );

  const invoiceStatuses: InvoiceStatus[] = [
    'INVOICE_ARRIVED',
    'CHECKPOINT_PENDING',
    'VALIDATION_DONE',
    'INVOICE_MATCHING_DONE',
    'NO_PAYMENT_SERVICE',
    'CREDIT_OPEN',
    'ACCOUNTING_ONLY',
    'CREDIT_CLOSED',
    'PREPARED_FOR_PAYMENTS',
    'PRELIMINARY_ACCOUNTING_DONE',
    'ACCOUNTING_DONE',
    'INVOICE_PAYMENT_REQUEST_SENT',
    'INVOICE_PAYMENT_FAILED',
    'INVOICE_PAID',
    'PAUSED',
  ];

  const invoiceTypeCodes: InvoiceTypeCode[] = ['DEBIT', 'CREDIT', 'BOOKKEEPING'];

  const invoiceStatusOptions: Option<InvoiceStatus>[] = createOptions(invoiceStatuses, t, 'invoice:status');
  const invoiceTypeCodeOptions: Option<InvoiceTypeCode>[] = createOptions(
    invoiceTypeCodes,
    t,
    'invoice:invoice-type-code',
  );
  return (
    <QueryWrapper query={GET_INSURANCE_PROVIDERS} type="list">
      {(insuranceProviders: InsuranceProvider[]) => {
        insuranceProviders = insuranceProviders ?? [];
        const insuranceProvidersOptions = unique(createOptionsFromList(insuranceProviders, 'id', 'legalName'));
        return (
          <Modal centered overlayBackground open={open} onClose={onClose}>
            <Card
              className="search-invoices-filters-modal"
              title={t('invoice:search-invoices-filters-modal.title')}
              icon={<Icon type="Sliders" className="search-invoices-filters-modal__icon" />}
              size="wide"
            >
              <div className="search-invoices-filters-modal__filters">
                <div className="search-invoices-filters-modal__select">
                  <Select
                    label={t('invoice:search-invoices-filters-modal.employers')}
                    placeholder={t('common:select')}
                    selectAll
                    options={employerOptions}
                    selected={employerOptions?.filter((option) => newFilters?.employerIds?.includes(option.value))}
                    onChange={handleEmployersChange}
                    small
                    outlined
                    multiple
                  />
                </div>
                <div className="search-invoices-filters-modal__select">
                  <Select
                    label={t('invoice:search-invoices-filters-modal.insurance-providers')}
                    placeholder={t('common:select')}
                    selectAll
                    options={insuranceProvidersOptions}
                    selected={insuranceProvidersOptions?.filter((option) =>
                      newFilters?.insuranceProviderIds?.includes(option.value),
                    )}
                    onChange={handleInsuranceProviderChange}
                    small
                    outlined
                    multiple
                  />
                </div>
                <div className="search-invoices-filters-modal__select">
                  <Select
                    label={t('invoice:search-invoices-filters-modal.invoice-status')}
                    placeholder={t('common:select')}
                    selectAll
                    options={invoiceStatusOptions}
                    selected={invoiceStatusOptions?.filter((option) =>
                      newFilters?.invoiceStatuses?.includes(option.value),
                    )}
                    onChange={handleInvoiceStatusesChange}
                    small
                    outlined
                    multiple
                  />
                </div>
                <div className="search-invoices-filters-modal__select">
                  <Select
                    label={t('invoice:search-invoices-filters-modal.invoice-type-code')}
                    placeholder={t('common:select')}
                    selectAll
                    options={invoiceTypeCodeOptions}
                    selected={invoiceTypeCodeOptions?.filter((option) =>
                      newFilters?.invoiceTypes?.includes(option.value),
                    )}
                    onChange={handleSearchInvoiceTypeCodeChange}
                    small
                    outlined
                    multiple
                  />
                </div>
                <div className="search-invoices-filters-modal__date-section">
                  <UpdateDatePicker
                    label={t('invoice:search-invoices-filters-modal.from-payment-due-date-label')}
                    fieldValue={newFilters?.paymentDueDate?.fromDate ?? ''}
                    fieldKey="paymentDueDate"
                    onBlur={(_, value) => {
                      handleFromPaymentDueDateChange(value);
                    }}
                  />
                  <UpdateDatePicker
                    label={t('invoice:search-invoices-filters-modal.to-payment-due-date-label')}
                    fieldValue={newFilters?.paymentDueDate?.toDate ?? ''}
                    fieldKey="paymentDueDate"
                    onBlur={(_, value) => {
                      handleToPaymentDueDateChange(value);
                    }}
                  />
                </div>
              </div>
              <CardAction>
                <Button label={t('common:cancel')} type="link" onClick={onClose} />
                <Button label={t('invoice:search-invoices-filters-modal.use-button')} onClick={onApply} />
              </CardAction>
            </Card>
          </Modal>
        );
      }}
    </QueryWrapper>
  );
};

export default SearchInvoicesFiltersModal;
