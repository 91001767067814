import React from 'react';
import { useTranslation } from 'react-i18next';
import EditableProperty from '../EditableProperty';
import { Employee } from 'src/types/Employee';
import TextInput from 'src/components/Common/TextInput';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';

export interface EmployeeInputProps {
  employeeToEdit: Employee;
  activeEmployee: Employee;
  employeeDraft: Employee;
  handleChange: (key: string, value: string) => void;
  type: EmployeeInputType;
}

export type EmployeeInputType = 'employmentNumber' | 'employmentStartDate' | 'employmentEndDate' | 'economyDepartment';

function EmployeeInput({ employeeToEdit, activeEmployee, handleChange, employeeDraft, type }: EmployeeInputProps) {
  const { t } = useTranslation();

  return (
    <EditableProperty label={t(`employees:${type}`)} hasChanged={activeEmployee?.[type] !== employeeDraft[type]}>
      <Input type={type} employeeToEdit={employeeToEdit} handleChange={handleChange} />
    </EditableProperty>
  );
}

interface InputProps {
  type: EmployeeInputType;
  employeeToEdit: Employee;
  handleChange: (key: string, value: string) => void;
}

function Input({ type, employeeToEdit, handleChange }: InputProps) {
  switch (type) {
    case 'employmentNumber':
      return <TextInput disabled value={employeeToEdit.employmentNumber} onChange={null} data-cy={`${type}-input`} />;
    case 'employmentStartDate':
    case 'employmentEndDate':
      return (
        <UpdateDatePicker
          fieldKey={type}
          onBlur={handleChange}
          fieldValue={employeeToEdit[type]}
          initialYear={employeeToEdit[type] ? new Date(employeeToEdit[type]).getFullYear() : new Date().getFullYear()}
          initialMonth={employeeToEdit[type] ? new Date(employeeToEdit[type]).getMonth() : new Date().getMonth()}
          data-cy={`${type}-input`}
        />
      );
    case 'economyDepartment':
      return (
        <TextInput value={employeeToEdit.accountingUnit?.name} onChange={null} disabled data-cy={`${type}-input`} />
      );
  }
}

export default EmployeeInput;
