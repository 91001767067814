import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import KeycloakInstance from 'src/keycloak';

export const post = <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  return axios.post(url, data, {
    ...config,
    headers: {
      ...config.headers,
      Authorization: 'Bearer ' + KeycloakInstance.token,
    },
  });
};
