import { Button, Icon } from 'fgirot-k2-ui-components';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './accounting-component-tab.scss';
import { useParams } from 'react-router-dom';
import { useCreateAccountingComponentValue } from 'src/service/accountingunit/CreateAccountingComponentValue';
import TextInput from 'src/components/Common/TextInput/TextInput';
import { AccountingComponentType } from 'src/types/accountingunit/AccountingComponent';
interface CreateAccountingComponentValueProps {
  accountingComponentType: AccountingComponentType;
}

const CreateAccountingComponentValue: FC<CreateAccountingComponentValueProps> = ({ accountingComponentType }) => {
  const { employerId } = useParams();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [valueInputOpen, setValueInputOpen] = useState(false);
  const [accountingComponentValue, setAccountingComponentValue] = useState('');
  const createAccountingComponentValue = useCreateAccountingComponentValue();

  const toggleInputOpen = () =>
    setValueInputOpen((prev) => {
      !prev &&
        setTimeout(() => {
          inputRef.current.focus();
        }, 1);
      return !prev;
    });
  const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => setAccountingComponentValue(e.target.value);
  const handleCreateAccountingComponentValue = () => {
    createAccountingComponentValue(
      {
        name: accountingComponentValue,
        accountingComponentTypeId: accountingComponentType.id,
      },
      employerId,
    ).then(() => {
      toggleInputOpen();

      setAccountingComponentValue('');
    });
  };

  return (
    <div className="accounting-component-type__accordion__list__item__inner-list__add-container">
      {valueInputOpen ? (
        <>
          <TextInput
            data-cy={`add-${accountingComponentType.name.replace(' ', '')}-value-input`}
            innerRef={inputRef}
            type="text"
            validationKey="text64"
            maxLength={65}
            label={t('economySettings:accounting-components-tab.accordion.add-accounting-component-value.label')}
            placeholder={t('economySettings:accounting-components-tab.accordion.add-accounting-component-value.label')}
            className="accounting-component-type__accordion__list__item__inner-list__add-container--input"
            value={accountingComponentValue}
            onChange={handleTypeChange}
          />
          <div className="accounting-component-type__accordion__list__item__inner-list__add-container--buttons">
            <Button
              data-cy={`create-${accountingComponentType.name}-value-button`}
              type="primary"
              label={t('common:add')}
              onClick={handleCreateAccountingComponentValue}
            />
            <Button
              data-cy={`cancel-${accountingComponentType.name.replace(' ', '')}-value-button`}
              type="secondary"
              label={t('common:cancel')}
              onClick={toggleInputOpen}
            />
          </div>
        </>
      ) : (
        <div className="accounting-component-type__accordion__list__item__inner-list__add-container--buttons">
          <Button
            data-cy={`add-${accountingComponentType.name}-value-button`}
            type="secondary"
            icon={<Icon type="Plus" />}
            label={t('economySettings:accounting-components-tab.accordion.add-accounting-component-value.add')}
            onClick={toggleInputOpen}
          />
        </div>
      )}
    </div>
  );
};

export default CreateAccountingComponentValue;
