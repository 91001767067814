import { split, ApolloClient, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import KeycloakInstance from 'src/keycloak';
import cache from './cache';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const webSocketLink = new GraphQLWsLink(
  createClient({
    url: () => {
      const token = KeycloakInstance.token;
      return `${window.location.protocol === 'https:' ? 'wss' : 'ws'}:${window.location.hostname}:${
        window.location.port
      }/subscriptions?authToken=${token ? `${token}` : ''}`;
    },
  }),
);

const options = {
  uri: `/graphql`,
  headers: { 'Apollo-Require-Preflight': 'true' },
};

const uploadLink = createUploadLink(options);

const authLink = new ApolloLink((operation, forward) => {
  const token = KeycloakInstance.token;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'employer-id': operation.getContext().employerId ? operation.getContext().employerId : '',
    },
  });
  return forward(operation);
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  webSocketLink,
  uploadLink,
);

export const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: cache,
});
