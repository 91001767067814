import {
  insuranceEventCheckpoints,
  dataValidationEventCheckpoints,
  employmentEventCheckpoints,
} from './CheckpointsToFinalize';
import { employers } from './Employers';

const Query = {
  fields: {
    EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE: dataValidationEventCheckpoints,
    EMPLOYEE_IDS_EXAMINATION_READY_TO_FINALIZE: employmentEventCheckpoints,
    EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE: insuranceEventCheckpoints,
    EMPLOYERS: employers,
  },
};
export default Query;
