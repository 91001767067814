import React, { useEffect, useState } from 'react';
import { FgLogo, Icon, Navigation, NavigationTab } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { navOptions } from './NavOptions';
import './navigation.scss';

const NavigationBar = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number>();
  const { t } = useTranslation();

  useEffect(() => {
    setSelected(navOptions.find((option) => location.pathname.split('/')[1] === option.label)?.value);
  }, [location.pathname]);

  return (
    <Navigation logo={<FgLogo />} selected={selected} className="navigation">
      <NavigationTab
        title={t('navigation:reporting')}
        onClick={() => navigate('/reporting')}
        icon={<Icon type="PieChart" />}
        data-cy="navigation-reporting"
      />
      <NavigationTab
        title={t('navigation:employees')}
        onClick={() => navigate('/employees')}
        icon={<Icon type="Users" />}
        data-cy="navigation-employees"
      />
      <NavigationTab
        title={t('navigation:economy')}
        onClick={() => navigate('/economy')}
        icon={<Icon type="Bell" />}
        data-cy="navigation-economy"
      />
      <NavigationTab
        title={t('navigation:documents')}
        onClick={() => navigate('/documents')}
        icon={<Icon type="Invoice" />}
        data-cy="navigation-documents"
      />
      <NavigationTab
        title={t('navigation:permission')}
        onClick={() => navigate('/permission')}
        icon={<Icon type="Users" />}
        data-cy="navigation-permission"
      />
      <NavigationTab
        title={t('navigation:account')}
        onClick={() => navigate('/account')}
        icon={<Icon type="Settings" />}
        data-cy="navigation-account"
      />
    </Navigation>
  );
};
export default NavigationBar;
