import { Icon, ListItem, Pill, Typography } from 'fgirot-k2-ui-components';
import './accounting-type.scss';
import { AccountingType } from 'src/types/accountingunit/AccountingType';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { arrangeFlatArrayAsTree } from 'src/util/ArrayUtil';
import { AccountingComponent, AccountingComponentFlat } from 'src/types/accountingunit/AccountingComponent';
import AccountingComponentsList from './AccountingComponentsList';

interface AccountingTypesProps {
  accountingType: AccountingType;
  onClick: () => void;
}

const AccountingTypeItem: FC<AccountingTypesProps> = ({ accountingType, onClick }) => {
  const { t } = useTranslation();
  return (
    <ListItem
      data-cy={`accounting-type-list-item-${accountingType.accountingTypeValue.name}`}
      onClick={onClick}
      buttonLabel={'TODO: accessibility-label-text: Visa kontoplansdetaljer?'}
      header={
        <div className="accounting-type__title">
          <div className="accounting-type__icon">
            <Icon type="Invoice" />
          </div>
          <Typography variant="subtitle" component="h3" bold>
            {t(`accountingTypeValue:${accountingType.accountingTypeValue.name}`)}
          </Typography>
        </div>
      }
    >
      <ul className="accounting-type__accounting-component-list">
        {accountingType.accountingComponentsSource ? (
          arrangeFlatArrayAsTree<AccountingComponentFlat, AccountingComponent>(
            accountingType.accountingComponents,
            'accountingComponents',
          ).map((accountingComponent) => (
            <div
              key={`AccountingComponent-${accountingComponent.id}`}
              className="accounting-type__accounting-component"
            >
              <div className="accounting-type__accounting-component--title">
                <div className="accounting-type__icon">
                  <Icon type="FileBlank" />
                </div>
                <Typography variant="body1" bold>
                  {`${accountingComponent.type} - ${accountingComponent.name}`}
                </Typography>
                <Pill label={`${t('economySettings:accounting-type-item.share')}: ${accountingComponent.share} %`} />
              </div>
              <li key={`AccountingComponentLi-${accountingComponent.id}`}>
                <ul className="accounting-type__accounting-component-list">
                  {accountingComponent.accountingComponents.map((ac) => (
                    <AccountingComponentsList
                      key={`AccountingComponentsList-${ac.id}`}
                      whiteBackground={true}
                      accountingComponent={ac}
                    />
                  ))}
                </ul>
              </li>
            </div>
          ))
        ) : (
          <Typography>{t('economySettings:accounting-type-page.use-invoice-accounting-components')}</Typography>
        )}
      </ul>
    </ListItem>
  );
};

export default AccountingTypeItem;
