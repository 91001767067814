import { InlineEdit, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';

interface AmountInputProps {
  isEditable: boolean;
  amount: string;
  handleAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AmountInput = ({ isEditable, amount, handleAmountChange }: AmountInputProps) => {
  const { t } = useTranslation();

  return (
    <InlineEdit
      label={
        <span className="wage-details__label">
          <Typography variant="body2" bold className="wage-details__label--text">
            {t('wages:amount')}
          </Typography>
        </span>
      }
      bold
    >
      {isEditable ? (
        <TextInput
          value={amount.toString()}
          onChange={handleAmountChange}
          validationKey="amount2DecAllowNegative"
          type="text"
          data-cy="amount-input"
        />
      ) : (
        <Typography variant="body2">{formatSweAmountTextWithSuffixWithTwoDecimals(amount)}</Typography>
      )}
    </InlineEdit>
  );
};
