import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchInsuranceEventInfos } from 'src/service/insurance/GetInsuranceEventInfos';
import { fetchInsuranceProviders } from 'src/service/insurance/GetInsuranceProviders';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEventInfo } from 'src/types/InsuranceEventInfo';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import AboutSupplierTable from './AboutSupplierTable';
import './update-supplier-info.scss';

interface UpdateSupplierInfoProps {
  checkpoint?: InsuranceEventCheckpoint;
  employerId: string;
}

const UpdateSupplierInfo = ({ checkpoint, employerId }: UpdateSupplierInfoProps) => {
  const [insuranceEventInfo, setInsuranceEventInfo] = useState<InsuranceEventInfo>();
  const [insuranceProvider, setInsuranceProvider] = useState<InsuranceProvider>();
  const { t } = useTranslation();
  useEffect(() => {
    const insuranceEventId = checkpoint?.additionalData?.insuranceEventId;
    const insuranceProviderId = checkpoint?.additionalData?.insuranceProviderId;
    insuranceEventId &&
      fetchInsuranceEventInfos([insuranceEventId]).then((res) => {
        setInsuranceEventInfo(res[0]);
      });
    insuranceProviderId &&
      fetchInsuranceProviders().then((res) =>
        setInsuranceProvider(res.find((provider) => provider.id === insuranceProviderId)),
      );
  }, [checkpoint]);
  return (
    checkpoint && (
      <div className={['update-supplier-info'].join(' ')}>
        <Typography variant="h2" component="h1" bold>
          {insuranceProvider?.legalName}
        </Typography>
        <div className="update-supplier-info__subtitle">
          <Pill type="neutral" label={t(`benefitTypes:${insuranceEventInfo?.benefitType}`)} />
          <Typography className="update-supplier-info__subtitle__number" variant="body2">
            {`${t('common:number')}: ${insuranceEventInfo?.agreementNumber}`}
          </Typography>
        </div>
        <Tabs>
          <Tab title={t('certify:drawer.about')}>
            <AboutSupplierTable checkpoint={checkpoint} employerId={employerId} />
          </Tab>
        </Tabs>
      </div>
    )
  );
};

export default UpdateSupplierInfo;
