import { SortDirection, TableBody, TableHead, TableRow, Td, Th } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { PremiumInvoiceRowSortField } from './PremiumInvoiceRowSortField';
import { PremiumInvoiceRow } from 'src/types/invoice/PremiumInvoiceRow';
import { getPremiumInvoiceTableHeads, translateBenefitType } from '../utils/premiumInvoiceRowUtils';
import { stringOrBlank } from 'src/util/StringUtil';
import { formatSweAmountText, formatSweAmountTextAlwaysWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { Sort } from 'src/types/common/Sort';

interface PremiumInvoiceRowsTableProps {
  premiumInvoiceRows: PremiumInvoiceRow[];
  sort: Sort;
  applySorting(property: string, direction: SortDirection);
}

const PremiumInvoiceRowsTable: FC<PremiumInvoiceRowsTableProps> = ({ premiumInvoiceRows, sort, applySorting }) => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const handleSort = (property: PremiumInvoiceRowSortField) => {
    if (property == sort.property) {
      applySorting(property, sort.direction == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sort.direction);
    }
  };
  const tableHeads = getPremiumInvoiceTableHeads(handleSort);
  return (
    <TableBase compressed hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns}>
      <TableHead>
        <TableRow>
          {tableHeads.map((head, index) => (
            <Th
              compressed
              includeSortButtons={head.handleSortDirectionChange != undefined}
              sortDirection={head.fieldName === sort.property ? sort.direction : undefined}
              handleSortDirectionChange={head.handleSortDirectionChange}
              key={`PremiumInvoiceRowsTableHead-${head.title}-${index}`}
            >
              {head.title}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {premiumInvoiceRows.map((premiumInvoiceRow, index) => (
          <TableRow
            data-testid={`premium-invoice-rows-table-row__${premiumInvoiceRow.id}`}
            key={`PremiumInvoiceRowsTableRow-${premiumInvoiceRow.id}-${index}`}
          >
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insuranceProvider.legalName)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insurance?.agreedProduct?.name)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insurance?.insuranceNumber)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insuranceTypeMapping?.name)}</Td>
            <Td alignRight compressed>
              {stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(premiumInvoiceRow.amount))}
            </Td>
            <Td alignRight compressed>
              {stringOrBlank(formatSweAmountText(premiumInvoiceRow.salaryTax))}
            </Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.periodStart)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.periodEnd)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insurance?.agreementNumber)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.invoiceNumber)}</Td>
            <Td compressed>{translateBenefitType(premiumInvoiceRow.insuranceTypeMapping?.benefitType)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.contractBenefitGroup)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.contractBenefitGroupName)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.dueDate)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.paymentDueDate)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.note)}</Td>
          </TableRow>
        ))}
      </TableBody>
    </TableBase>
  );
};

export default PremiumInvoiceRowsTable;
