import { Icon, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { AccountingComponent } from 'src/types/accountingunit/AccountingComponent';
import { recursiveHasGoodShare } from 'src/util/AccountingComponentUtil';
import { stringOrBlank } from 'src/util/StringUtil';

interface PostingRuleComponentAccordionHeaderProps {
  accountingComponent: AccountingComponent;
  isEditable: boolean;
  share: number;
  onShareChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onShareUpdate: () => void;
}
const PostingRuleComponentAccordionHeader: FC<PostingRuleComponentAccordionHeaderProps> = ({
  accountingComponent,
  isEditable,
  share,
  onShareChange,
  onShareUpdate,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="posting-rule__posting-rule-component-accordion--header"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Typography
        data-cy="posting-rule-component-accordion-title"
        className="posting-rule__posting-rule-component-accordion--text"
      >
        {`${accountingComponent.type} - ${accountingComponent.name}`}
      </Typography>
      <TextInput
        disabled={!isEditable}
        data-cy={`${accountingComponent.name.replace(' ', '')}-share-input`}
        onBlur={onShareUpdate}
        type="number"
        validationKey="percent"
        variant="default"
        onChange={onShareChange}
        value={stringOrBlank(share)}
        className="posting-rule-components__posting-rule-component-share-input"
      />
      {!recursiveHasGoodShare(accountingComponent.accountingComponents) && (
        <div className="posting-rule__posting-rule-component-accordion--header--share-error">
          <Icon
            type="Alert"
            stroke="red"
            className="posting-rule__posting-rule-component-accordion--header--share-error--icon"
          />
          <Typography
            className="posting-rule__posting-rule-component-accordion--header--share-error--text"
            variant="caption"
          >
            {t(
              'economySettings:posting-rules-tab.posting-rule-details.posting-rule-components.wrong-share-sub-components',
            )}
          </Typography>
        </div>
      )}
    </div>
  );
};
export default PostingRuleComponentAccordionHeader;
