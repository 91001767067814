import { useApolloMutation } from '../graphql/Mutation';
import { GET_INVOICE } from '../../graphql/schema/query/invoice/GetInvoice';
import { DELETE_INVOICE } from '../../graphql/schema/mutation/invoice/DeleteInvoice';
import { GET_INVOICES } from '../../graphql/schema/query/invoice/GetInvoices';

export const useDeleteInvoice = () => {
  const apolloMutation = useApolloMutation();

  const deleteInvoice = (invoiceId, employerId: string) =>
    apolloMutation(DELETE_INVOICE, employerId, { invoiceId }, [GET_INVOICE, GET_INVOICES]);
  return deleteInvoice;
};
