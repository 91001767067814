import { Select, Option } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import InfoMessage from 'src/components/Common/InfoMessage';
import { useUpdateBalancingOfBooksSettings } from 'src/service/accountingunit/BalancingOfBooksSettings';
import { Month } from 'src/types/Month';
import { UpdateBalancingOfBooksSettingsInput } from 'src/types/accountingunit/BalancingOfBooksSettings';
import { useMonthOptions } from 'src/util/date/Months';
interface YearEndClosingMonthProps {
  balancingOfBooksSettingsId: string;
  yearEndClosingMonth: Month;
}
const YearEndClosingMonth: FC<YearEndClosingMonthProps> = ({ balancingOfBooksSettingsId, yearEndClosingMonth }) => {
  const { employerId } = useParams();
  const { t } = useTranslation();
  const updateBalancingOfBooksSettings = useUpdateBalancingOfBooksSettings();

  const monthOptions = useMonthOptions();

  const handleEndMonthChange = (option: Option<Month>) => {
    const request: UpdateBalancingOfBooksSettingsInput = {
      balancingOfBooksSettingsId,
      yearEndClosingMonth: option.value,
    };
    updateBalancingOfBooksSettings(request, employerId);
  };

  return (
    <div className="accounting-settings-tab__card-content-row">
      <div>
        <Select
          label={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.year-end-closing-month')}
          data-cy="annual-end-month-select"
          options={monthOptions}
          alphabetical={false}
          selected={monthOptions.find((option) => option.value === yearEndClosingMonth)}
          onChange={handleEndMonthChange}
        />
        <InfoMessage
          message={t(
            'economySettings:accounting-settings-tab.balancing-of-books-settings-cards.year-end-closing-month-info',
          )}
        />
      </div>
    </div>
  );
};

export default YearEndClosingMonth;
