import { Icon, Pill, VersionPill } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';

interface PolicyStateProps {
  selectedPolicyEntity: PolicyEntity;
}

const PolicyStateDefault: React.FunctionComponent<PolicyStateProps> = ({ selectedPolicyEntity }) => {
  const { t } = useTranslation();

  const fromDateText = formatDateTimeString(selectedPolicyEntity?.fromDate);
  const toDateText = formatDateTimeString(selectedPolicyEntity?.toDate);

  return (
    <div className="policy-version-header__version-info">
      <VersionPill className="policy-version-header__version-info--pill" label={`V${selectedPolicyEntity.version}`} />{' '}
      <Pill className="policy-version-header__version-info--pill" type="gray" label={fromDateText} />
      <Icon className="policy-version-header__version-info--icon" type="ArrowRight" />
      <Pill
        className="policy-version-header__version-info--pill"
        type="gray"
        label={toDateText || t('account:employers-tab.policy-settings.until-further-notice')}
      />
    </div>
  );
};

export default PolicyStateDefault;
