import { Button, Drawer, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { ChangeEvent, useState } from 'react';
import './add-employer-to-group.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import { GroupEmployer } from 'src/types/group/Group';
import { Employer } from 'src/types/employer/Employer';
import { useAddEmployerToGroup } from 'src/service/group/AddEmployerToGroup';

interface AddEmployerToGroup {
  onClose: () => void;
  groupName: string;
  groupId: string;
  groupEmployers: GroupEmployer[];
  allEmployers: Employer[];
  breadcrumbs: string[];
}

const AddEmployerToGroup: React.FunctionComponent<AddEmployerToGroup> = ({
  onClose,
  groupName,
  groupId,
  groupEmployers,
  allEmployers,
  breadcrumbs,
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');
  const addEmployerToGroup = useAddEmployerToGroup();
  const searchUserInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const addEmployer = (employerId: string) => {
    addEmployerToGroup(groupId, employerId);
  };

  const employersAlreadyPartOfGroup = (employer: Employer) =>
    !groupEmployers.find((gEmployer) => gEmployer.id === employer.id);
  const nameMatchSearchString = (employer: Employer) =>
    employer.legalName.toLowerCase().includes(searchInput.toLowerCase());

  return (
    <Drawer
      leftButton={<Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onClose} />}
      type="elements"
      onClose={onClose}
    >
      <div className="add-employer-to-group__title">
        <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
        <Typography variant="h3" component="h3" bold>
          {t('permission:groups-drawer.add-employer-label')}
        </Typography>
        <div className="add-employer-to-group__sub-title">
          <Typography>{groupName}</Typography>
        </div>
        <ListGroup variant="inline-edit">
          <InlineEdit
            label={t('permission:groups-drawer.search-employer-label')}
            className="add-employer-to-group__search-input"
          >
            <TextInput
              value={searchInput}
              onChange={searchUserInputOnChange}
              placeholder={t('common:name')}
              data-testid="search-input"
            />
          </InlineEdit>
        </ListGroup>
      </div>
      <ListGroup variant="inline-edit">
        {allEmployers
          .filter(employersAlreadyPartOfGroup)
          .filter(nameMatchSearchString)
          .slice(0, 15)
          .map((employer) => (
            <InlineEdit label={employer.legalName} alignRight key={employer.id} data-testid="employer-list-item">
              <Button label={t('common:add')} onClick={() => addEmployer(employer.id)} />
            </InlineEdit>
          ))}
      </ListGroup>
    </Drawer>
  );
};

export default AddEmployerToGroup;
