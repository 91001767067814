import { Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { INSURANCE_TYPE_MAPPINGS } from 'src/graphql/schema/query/insurancetypemapping/GetInsuranceTypeMappings';
import { useCreateAccrualDeviation } from 'src/service/accountingunit/AccrualDeviation';
import { AccrualDeviation } from 'src/types/accountingunit/AccrualRule';
import { InsuranceTypeMapping } from 'src/types/insurancetypemapping/InsuranceTypeMapping';
interface AddAccrualDeviationModalProps {
  open: boolean;
  onClose: () => void;
  accrualRuleId: string;
  accrualDeviations: AccrualDeviation[];
}
const AddAccrualDeviationModal: FC<AddAccrualDeviationModalProps> = ({
  open,
  onClose,
  accrualRuleId,
  accrualDeviations,
}) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [insuranceTypeMappingId, setInsuranceTypeMappingId] = useState<string>();
  const createAccrualDevation = useCreateAccrualDeviation();

  const handleRowMappingChange = (option: Option<string>) => setInsuranceTypeMappingId(option.value);

  const handleCreateAccrualDeviation = () =>
    createAccrualDevation({ accrualRuleId, insuranceTypeMappingId }, employerId).then(handleModalClose);

  const handleModalClose = () => {
    setInsuranceTypeMappingId(null);
    onClose();
  };
  return (
    <Modal centered overlayBackground open={open} onClose={handleModalClose}>
      <Card
        title={t('economySettings:accounting-settings-tab.accrual-rule-cards.deviation-modal.title')}
        defaultClose
        onClose={handleModalClose}
      >
        <QueryWrapper query={INSURANCE_TYPE_MAPPINGS}>
          {(insuranceTypeMappings: InsuranceTypeMapping[]) => {
            const options: Option<string>[] = insuranceTypeMappings
              .filter(
                (insuranceTypeMapping) =>
                  !accrualDeviations.some((deviation) => deviation.insuranceTypeMapping.id === insuranceTypeMapping.id),
              )
              .map((mapping) => ({
                label: `${mapping.insuranceProvider.legalName} - ${mapping.name}`,
                value: mapping.id,
              }));
            return (
              <Select
                data-cy="invoice-row-mapping-select"
                placeholder={t(
                  'economySettings:accounting-settings-tab.accrual-rule-cards.deviation-modal.invoice-row-mapping-placeholder',
                )}
                options={options}
                selected={options.find((option) => option.value === insuranceTypeMappingId)}
                onChange={handleRowMappingChange}
              />
            );
          }}
        </QueryWrapper>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleModalClose} />
          <Button
            data-cy="create-accrual-deviation-button"
            disabled={insuranceTypeMappingId === null}
            label={t('common:add')}
            onClick={handleCreateAccrualDeviation}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddAccrualDeviationModal;
