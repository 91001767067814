import React, { FC } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/query/contract/GetInsuranceProviderContract';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import NoAgreementCostCentre from './NoAgreementCostCentre';
import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import CostCentreInput from './CostCentreInput';
import useEmployeeIndividualSettings from 'src/service/employee/GetEmployeeIndividualSettings';
import { LOADED } from 'src/types/Status';
import { useTranslation } from 'react-i18next';

interface MissingAgreementCostCentreCheckpointProps {
  checkpoints: InsuranceEventCheckpoint[];
  employeeId: string;
}

const MissingAgreementCostCentreCheckpoint: FC<MissingAgreementCostCentreCheckpointProps> = ({
  checkpoints,
  employeeId,
}) => {
  const service = useEmployeeIndividualSettings(employeeId);
  const settings = service.status === LOADED ? service.payload : null;
  const { t } = useTranslation();

  return (
    <div>
      <QueryWrapper
        query={GET_INSURANCE_PROVIDER_CONTRACT}
        options={{
          variables: { insuranceProviderContractId: checkpoints[0].additionalData.insuranceProviderContractId },
          skip: !checkpoints[0].additionalData.insuranceProviderContractId,
        }}
      >
        {(contract: InsuranceProviderContract) => {
          if (contract.agreementCostCentres.length === 0) {
            return <NoAgreementCostCentre contract={contract} />;
          }
          return (
            <ListGroup variant="inline-edit">
              <InlineEdit bold label={t('verify:drawer.cost-centre')}>
                <CostCentreInput contract={contract} employeeId={employeeId} settings={settings} />
              </InlineEdit>
            </ListGroup>
          );
        }}
      </QueryWrapper>
    </div>
  );
};

export default MissingAgreementCostCentreCheckpoint;
