import { UPSERT_PGL_RULE_BONUS_COMPONENT } from 'src/graphql/schema/mutation/policy/UpsertPglRuleBonusComponent';
import { PglBonusComponentRequest } from 'src/types/policy/request/PglBonusComponentRequest';
import { GET_PGL_RULES } from 'src/graphql/schema/query/policy/GetPglRules';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpsertPglRuleBonusComponent = () => {
  const apolloMutation = useApolloMutation();

  const upsertPglRuleBonusComponent = (employerId: string, request: PglBonusComponentRequest) =>
    apolloMutation(UPSERT_PGL_RULE_BONUS_COMPONENT, employerId, { request }, [GET_PGL_RULES]);

  return upsertPglRuleBonusComponent;
};
