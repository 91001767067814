import { ListItem, Typography, IconPill } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import AbsenceTable from './AbsenceTable/AbsenceTable';
import './absence-details.scss';
import { useTranslation } from 'react-i18next';

interface AbsenceDetailsProps {
  wageType: AggregatedWageTypeWage;
  employerWageType: EmployerWageType;
}

function AbsenceDetails({ wageType, employerWageType }: AbsenceDetailsProps) {
  const [displayDetails, setDisplayDetails] = useState(false);
  const { t } = useTranslation();

  return (
    <ListItem
      header={
        <div className="absence-details__header">
          <Typography variant="subtitle" className="absence-details__heading" bold>
            {employerWageType.name}
          </Typography>
          <IconPill
            icon="Calendar"
            label={
              wageType.extentAdjustedAbsenceDays
                ? `${wageType.extentAdjustedAbsenceDays} ${
                    wageType.extentAdjustedAbsenceDays === 1
                      ? t('wages:extent-adjusted-absence-days-singular')
                      : t('wages:extent-adjusted-absence-days-plural')
                  }`
                : '-'
            }
          />
        </div>
      }
      onClick={() => setDisplayDetails(!displayDetails)}
      buttonLabel={displayDetails ? t('common:hide-details') : t('common:show-details')}
    >
      {displayDetails && (
        <section className="absence-details__content">
          <AbsenceTable wageType={wageType} />
        </section>
      )}
    </ListItem>
  );
}

export default AbsenceDetails;
