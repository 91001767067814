import React from 'react';
import { Employee } from 'src/types/Employee';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import { useNavigate, useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';
import LatestEmployeeCard from './LatestEmployeeCard';
import EmployeeActions from './EmployeeActions';
import { getCurrentPeriod } from 'src/util/date/PeriodUtil';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { Banner } from 'fgirot-k2-ui-components';
import When from 'src/components/Common/When';
import { useTranslation } from 'react-i18next';
import './employee-overview.scss';

interface EmployeeOverviewProps {
  employee: Employee;
}

const EmployeeOverview = ({ employee }: EmployeeOverviewProps) => {
  const { t } = useTranslation();

  const { employeeId, period } = useParams();
  const navigate = useNavigate();
  const employerName = useEmployerLegalName(employee.employerId);

  return (
    <QueryWrapper
      query={GET_LATEST_EMPLOYEE_CARD}
      options={{
        variables: { employeeId, period, allowDraft: true },
        skip: !employeeId,
        fetchPolicy: 'no-cache',
      }}
    >
      {(employeeCard: AggregatedEmployeeCard) => {
        !period &&
          (employeeCard
            ? navigate(`/employees/${employeeId}/${employeeCard.period}`, { replace: true })
            : navigate(`/employees/${employeeId}/${getCurrentPeriod()}`, { replace: true }));

        return (
          period && (
            <>
              <EmployeeActions period={period} employee={employee} />
              <When
                condition={
                  period < employee.earliestEmploymentStartDate?.replace('-', '').substring(0, 6) &&
                  employeeCard !== null
                }
              >
                <Banner className="employee-overview__info-banner" message={t('employees:info-banner-message')} />
              </When>
              <LatestEmployeeCard
                employerName={employerName}
                employee={employee}
                employeeCard={employeeCard}
                period={period}
                employeeId={employeeId}
              />
            </>
          )
        );
      }}
    </QueryWrapper>
  );
};

export default EmployeeOverview;
