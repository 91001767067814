export interface PolicyBenefitConnectionRule {
  optional?: boolean;
}

export enum PolicyBenefitConnectionRuleType {
  OPTIONAL = 'OPTIONAL',
}

export enum PolicyBenefitConnectionRuleValue {
  OPTIONAL = 'OPTIONAL',
  MANDATORY = 'MANDATORY',
}
