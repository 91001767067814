import { UPDATE_BENEFIT_AGREED_PRODUCT } from 'src/graphql/schema/mutation/policy/UpdateBenefitAgreedProduct';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { UpdateBenefitAgreedProductRequest } from 'src/types/policy/request/UpdateBenefitAgreedProductRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateBenefitAgreedProduct = () => {
  const apolloMutation = useApolloMutation();

  const updateBenefitAgreedProduct = (request: UpdateBenefitAgreedProductRequest, employerId: string) =>
    apolloMutation(UPDATE_BENEFIT_AGREED_PRODUCT, employerId, { request }, [GET_POLICY]);

  return updateBenefitAgreedProduct;
};
