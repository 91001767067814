import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYER_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employer/GetEmployerIndividualChoices';
import { UpdateEmployerIndividualChoiceRequest } from 'src/types/employer/request/UpdateEmployerIndividualChoiceRequest';
import { UPDATE_EMPLOYER_INDIVIDUAL_CHOICE } from 'src/graphql/schema/mutation/employer/UpdateEmployerIndividualChoice';

export const useUpdateEmployerIndividualChoice = () => {
  const apolloMutation = useApolloMutation();

  const updateEmployerIndividualChoice = (request: UpdateEmployerIndividualChoiceRequest) =>
    apolloMutation(UPDATE_EMPLOYER_INDIVIDUAL_CHOICE, request.employerId, { request }, [
      GET_EMPLOYER_INDIVIDUAL_CHOICES,
    ]);

  return updateEmployerIndividualChoice;
};
