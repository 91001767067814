import React from 'react';
import { Typography } from 'fgirot-k2-ui-components';
import FilterButton from 'src/components/Common/FilterButton';
import { useTranslation } from 'react-i18next';
import {
  EmployeeInsurancesFilters,
  EmployeeInsurancesRequest,
} from 'src/types/employees/request/EmployeeInsurancesRequest';

interface InsurancesFilterDetailsProps {
  request: EmployeeInsurancesRequest;
  applyFilters: (filters: EmployeeInsurancesFilters) => void;
}
import './insurances-filter-details.scss';

function InsurancesFilterDetails({ request, applyFilters }: InsurancesFilterDetailsProps) {
  const { t } = useTranslation();

  const removeFilter = (key: string) => {
    const newFilters = { ...request?.filters };
    newFilters[key] = null;
    applyFilters(newFilters);
  };

  const noFiltersApplied =
    request?.filters?.insuranceProviderIdsFilter?.length === 0 &&
    request?.filters?.benefitTypesFilter?.length === 0 &&
    request?.filters?.agreedProductIdsFilter?.length === 0;

  return (
    <div className="insurances-filter-details">
      <Typography variant="caption" bold>
        Filter: &nbsp;
      </Typography>
      {noFiltersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      <div className="insurances-filter-details__buttons">
        {request?.filters?.insuranceProviderIdsFilter?.length > 0 && (
          <FilterButton
            label={
              request.filters.insuranceProviderIdsFilter.length + ' ' + t('insurances:filters.insurance-providers')
            }
            onClick={() => removeFilter('insuranceProviderIdsFilter')}
          />
        )}
        {request?.filters?.benefitTypesFilter?.length > 0 && (
          <FilterButton
            label={request?.filters.benefitTypesFilter.length + ' ' + t('insurances:filters.benefit-types')}
            onClick={() => removeFilter('benefitTypesFilter')}
          />
        )}
        {request?.filters?.agreedProductIdsFilter?.length > 0 && (
          <FilterButton
            label={request?.filters.agreedProductIdsFilter.length + ' ' + t('insurances:filters.agreed-products')}
            onClick={() => removeFilter('agreedProductIdsFilter')}
          />
        )}
      </div>
    </div>
  );
}

export default InsurancesFilterDetails;
