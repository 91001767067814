import { Option, Select, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingAdjustmentActionInput } from 'src/types/accountingunit/AccountingAdjustmentAction';
import {
  AccountingAdjustmentActionType,
  accountingAdjustmentActionTypes,
} from 'src/types/accountingunit/AccountingAdjustmentActionType';
import { useReferenceOptions } from './Hooks';
import { useParams } from 'react-router-dom';

const FlexibleActionSelects = ({
  accountingAdjustmentAction,
  onAccountingActionReferenceChange,
}: {
  accountingAdjustmentAction: AccountingAdjustmentActionInput;
  onAccountingActionReferenceChange: (option: Option<string>) => void;
}) => {
  const { t } = useTranslation();
  const [selectedAccountingComponentType, setSelectedAccountingComponentType] = React.useState<Option<string>>();
  const { employerId } = useParams();
  const [referenceOptions, accountingComponentValues] = useReferenceOptions(
    accountingAdjustmentAction.accountingAdjustmentActionType,
    employerId,
  );

  useEffect(() => {
    setSelectedAccountingComponentType(referenceOptions[0]);
  }, [referenceOptions]);

  const handleAccountingComponentTypeSelect = (e: Option<string>) => {
    setSelectedAccountingComponentType({ label: e.label, value: e.value });
  };

  const mappedAccountingComponentValues = accountingComponentValues
    .get(selectedAccountingComponentType?.value)
    ?.accountingComponentValues.map((object) => ({ label: object.name, value: object.id }));
  const mappedAccountingComponentValue = accountingComponentValues
    .get(selectedAccountingComponentType?.value)
    ?.accountingComponentValues.find((option) => option.id === accountingAdjustmentAction.referenceId);

  if (accountingAdjustmentAction.accountingAdjustmentActionType === null) {
    return null;
  } else if (accountingAdjustmentAction.accountingAdjustmentActionType === 'ACCOUNTING_COMPONENT_VALUE') {
    return (
      <>
        <Select
          data-cy="action-reference-type-select"
          selected={referenceOptions.find((option) => option.label === selectedAccountingComponentType?.label)}
          options={referenceOptions}
          label={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          disabled={!accountingAdjustmentAction.accountingAdjustmentActionType}
          onChange={handleAccountingComponentTypeSelect}
          allowSelectMaxWidth
        />

        <Select
          data-cy="action-reference-select"
          selected={{ label: mappedAccountingComponentValue?.name, value: mappedAccountingComponentValue?.id }}
          options={mappedAccountingComponentValues ?? []}
          label={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          disabled={!accountingAdjustmentAction.accountingAdjustmentActionType}
          onChange={onAccountingActionReferenceChange}
          allowSelectMaxWidth
        />
      </>
    );
  }
  return (
    <Select
      data-cy="action-reference-select"
      selected={referenceOptions.find((option) => option.value === accountingAdjustmentAction.referenceId)}
      options={referenceOptions}
      label={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
      placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
      disabled={!accountingAdjustmentAction.accountingAdjustmentActionType}
      onChange={onAccountingActionReferenceChange}
      allowSelectMaxWidth
    />
  );
};

interface ActionSectionProps {
  accountingAdjustmentAction: AccountingAdjustmentActionInput;
  onAccountingActionTypeChange: (option: Option<AccountingAdjustmentActionType>) => void;
  onAccountingActionReferenceChange: (option: Option<string>) => void;
}

const ActionSection: FC<ActionSectionProps> = ({
  accountingAdjustmentAction,
  onAccountingActionReferenceChange,
  onAccountingActionTypeChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3">{t('economySettings:accounting-adjustment-tab.modal.action-title')}</Typography>
      <div className="accounting-adjustments-tab__add-rule-modal--action-select-section">
        <Select
          data-cy="action-type-select"
          selected={
            accountingAdjustmentAction.accountingAdjustmentActionType && {
              label: t(
                `economySettings:accounting-adjustment-action-type.${accountingAdjustmentAction.accountingAdjustmentActionType}`,
              ),
              value: accountingAdjustmentAction.accountingAdjustmentActionType,
            }
          }
          options={accountingAdjustmentActionTypes.map((type) => ({
            label: t(`economySettings:accounting-adjustment-action-type.${type}`),
            value: type,
          }))}
          label={t('economySettings:accounting-adjustment-tab.modal.choose-action-type')}
          placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-action-type')}
          onChange={onAccountingActionTypeChange}
          allowSelectMaxWidth
        />
        <div className="accounting-adjustments-tab__add-rule-modal--value-selection">
          <FlexibleActionSelects
            accountingAdjustmentAction={accountingAdjustmentAction}
            onAccountingActionReferenceChange={onAccountingActionReferenceChange}
          />
        </div>
      </div>
    </>
  );
};

export default ActionSection;
