import { CREATE_DIRECT_DOCUMENT_ACCOUNTING } from 'src/graphql/schema/mutation/document/CreateDirectDocumentAccounting';
import { useApolloMutation } from '../graphql/Mutation';
import { CreateDirectDocumentInput } from 'src/types/documents/CreateDirectDocumentInput';
import { GET_ASSIGNED_DOCUMENTS_TEMPLATES } from 'src/graphql/schema/query/documents/GetAssignedDocumentTemplates';

export const useCreateDirectDocumentAccounting = () => {
  const apolloMutation = useApolloMutation();

  const createDirectDocumentAccounting = (employerId: string, request: CreateDirectDocumentInput) =>
    apolloMutation(CREATE_DIRECT_DOCUMENT_ACCOUNTING, employerId, { request }, [GET_ASSIGNED_DOCUMENTS_TEMPLATES]);

  return createDirectDocumentAccounting;
};
