import { gql } from '@apollo/client';

export const GET_POLICY_EMPLOYMENT_GROUP = gql`
  query GetPolicyEmploymentGroup($policyId: UUID!, $employmentGroupId: UUID!) {
    policyEmploymentGroup(policyId: $policyId, employmentGroupId: $employmentGroupId) {
      id
      employmentGroupId
      employmentGroupName
    }
  }
`;
