import KeycloakInstance from 'src/keycloak';
import jwtDecode from 'jwt-decode';
import { AccessTokenUser } from 'src/types/user/AccessTokenUser';

interface AccessToken {
  user_id: string;
  user_type: string;
  admin: string;
}

export const getLoggedInUser = (): AccessTokenUser => {
  const token = KeycloakInstance.token;
  if (token) {
    const decodedToken: AccessToken = jwtDecode(token);
    return { userId: decodedToken.user_id, admin: decodedToken.admin, userType: decodedToken.user_type };
  }
  return undefined;
};

export const loggedInUserIsAdmin = () => {
  const loggedInUser = getLoggedInUser();
  return loggedInUser.admin && loggedInUser.userType === 'EXTERNAL';
};
