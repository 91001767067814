import { Icon } from 'fgirot-k2-ui-components';
import React from 'react';
import './period-change-button.scss';
import { useTranslation } from 'react-i18next';

interface PeriodChangeButtonProps {
  type: 'next' | 'previous';
  currentPeriod: string;
  onChangePeriod: (newPeriod: string) => void;
}

function PeriodChangeButton({ type, currentPeriod, onChangePeriod }: PeriodChangeButtonProps) {
  const { t } = useTranslation();

  const handlePeriodChange = () => {
    const year = parseInt(currentPeriod.slice(0, 4));
    const month = parseInt(currentPeriod.slice(4));
    if (type === 'next') {
      if (month === 12) {
        onChangePeriod(`${year + 1}01`);
      } else {
        onChangePeriod(`${year}${(month + 1).toString().padStart(2, '0')}`);
      }
    } else {
      if (month === 1) {
        onChangePeriod(`${year - 1}12`);
      } else {
        onChangePeriod(`${year}${(month - 1).toString().padStart(2, '0')}`);
      }
    }
  };

  return (
    <button onClick={handlePeriodChange} className={`period-change-button period-change-button--${type}`}>
      <Icon type="Chevron" />
      <span className="period-change-button__sr-only">
        {type === 'next' ? t('common:next-period') : t('common:previous-period')}
      </span>
    </button>
  );
}

export default PeriodChangeButton;
