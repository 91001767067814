import { GET_AGREED_REPORTING_TYPE_CONFIGS } from '../../graphql/schema/query/contract/GetAgreedReportingtypeConfigs';
import { DELETE_AGREED_REPORTING_TYPE_CONFIG } from '../../graphql/schema/mutation/contract/DeleteAgreedReportingTypeConfig';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeleteAgreedReportingTypeConfig = () => {
  const apolloMutation = useApolloMutation();

  const deleteAgreedReportingTypeConfig = (
    employerId: string,
    insuranceProviderContractId: string,
    agreedProductId: string,
    agreedReportingTypeConfigId: string,
  ) =>
    apolloMutation(
      DELETE_AGREED_REPORTING_TYPE_CONFIG,
      employerId,
      {
        insuranceProviderContractId,
        agreedProductId,
        agreedReportingTypeConfigId,
      },
      [GET_AGREED_REPORTING_TYPE_CONFIGS],
    );

  return deleteAgreedReportingTypeConfig;
};
