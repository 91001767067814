import { Pill, Typography } from 'fgirot-k2-ui-components';
import { ListGroup } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import ScrollBase from 'src/components/Common/ScrollBase/ScrollBase';
import ListProperty from 'src/components/Common/ListProperty/ListProperty';
import React from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_POLICY_EMPLOYMENT_GROUP } from 'src/graphql/schema/query/policy/GetPolicyEmploymentGroup';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import { Employee } from 'src/types/Employee';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';
import { stringOrBlank } from 'src/util/StringUtil';
import { getCorrectEventValue } from 'src/util/InsuranceEventUtil';
import './reporting-event-about-tab.scss';

interface ReportingEventAboutTabProps {
  reportingEvent: ReportingEvent;
  employees: Employee[];
  personNumber: string;
}

const ReportingEventAboutTab = ({ reportingEvent, employees, personNumber }: ReportingEventAboutTabProps) => {
  const { t } = useTranslation();
  const policyId = reportingEvent.employeeCard.evaluationPolicyEntityId;
  const employmentGroupId = reportingEvent.employeeCard.employmentGroupId;
  const hasBeenAssignedEmploymentGroup = policyId && employmentGroupId;
  const employee = employees.find((employee) => employee.id === reportingEvent.employeeId);

  return (
    <ScrollBase maxHeightPercentage={60} className="reporting-event-about-tab">
      <ListGroup variant="inline-edit" key={employee.id}>
        <ListProperty
          label={t('reporting:reporting-events.employee')}
          value={`${employee.firstName} ${employee.lastName}`}
          data-testid="reporting-event-about-tab__full-name"
        />
        <ListProperty
          label={t('reporting:reporting-events.person-number')}
          value={personNumber}
          data-testid="reporting-event-about-tab__person-number"
        />
        <EmployerDetails employerId={employee.employerId} />
        <ListProperty
          label={t('reporting:reporting-events.employment-number')}
          value={employee.employmentNumber}
          data-testid="reporting-event-about-tab__employment-number"
        />
        <ListProperty
          label={t('reporting:reporting-events.employment-type')}
          value={t(`employmentTypes:${reportingEvent.employeeCard.employmentType}`)}
          data-testid="reporting-event-about-tab__reporting-employment-card-employment-type"
        />
        <ListProperty
          label={t('reporting:reporting-events.employment-category')}
          value={t(`employmentCategories:${reportingEvent.employeeCard.employmentCategory}`)}
          data-testid="reporting-event-about-tab__reporting-employment-card-employment-category"
        />
        <ListProperty
          label={t('reporting:reporting-events.remuneration-type')}
          value={t(`remunerationTypes:${reportingEvent.employeeCard.remunerationType}`)}
          data-testid="reporting-event-about-tab__reporting-employment-card-remuneration-type"
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('reporting:employment-group')}
          data-testid="reporting-event-about-tab__employment-group"
          value={
            <>
              {hasBeenAssignedEmploymentGroup && (
                <QueryWrapper
                  query={GET_POLICY_EMPLOYMENT_GROUP}
                  options={{
                    variables: {
                      policyId,
                      employmentGroupId,
                    },
                  }}
                >
                  {(data: PolicyEmploymentGroup) => <>{stringOrBlank(data?.employmentGroupName)}</>}
                </QueryWrapper>
              )}
            </>
          }
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        <ListProperty
          data-testid="reporting-event-about-tab__reporting-event-type"
          label={t('reporting:reporting-events.reporting-event-type')}
          value={t(`reportingTypes:${reportingEvent.reportingEventType}`)}
        />
        <ListProperty
          data-testid="reporting-event-about-tab__insurance-event-type"
          label={t('reporting:reporting-events.insurance-event-type')}
          value={t(`insuranceEventTypes:${reportingEvent.insuranceEventType}`)}
        />
        <ListProperty
          data-testid="reporting-event-about-tab__recipient"
          label={t('reporting:reporting-events.recipient')}
          value={t(`benefitTypes:${reportingEvent.insuranceProvider?.legalName}`)}
        />
        <ListProperty
          data-testid="reporting-event-about-tab__benefit-type"
          label={t('reporting:reporting-events.benefit-type')}
          value={t(`benefitTypes:${reportingEvent.benefitType}`)}
        />
        <ListProperty
          data-testid="reporting-event-about-tab__event-from-date"
          label={t('reporting:reporting-events.event-from-date')}
          value={
            reportingEvent.aggregatedInsuranceEvent?.eventFromDate
              ? t(`benefitTypes:${reportingEvent.aggregatedInsuranceEvent.eventFromDate}`)
              : '-'
          }
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        {reportingEvent.aggregatedInsuranceEvent?.eventValues?.length === 0 ? (
          <ListProperty label={t('reporting:reporting-events.value')} value="-" />
        ) : (
          <>
            {reportingEvent.aggregatedInsuranceEvent?.eventValues?.map((eventValue) => (
              <ListProperty
                key={`EventValue-${eventValue.id}`}
                label={t('reporting:reporting-events.value')}
                value={
                  <div className="reporting-event-about-tab__values">
                    <Pill type="neutral" size="small" label={eventValue.eventValueType} />
                    <Typography variant="body2" component="span">
                      {formatSweAmountTextWithSuffixNoDecimal(getCorrectEventValue(eventValue))}
                    </Typography>
                  </div>
                }
              />
            ))}
          </>
        )}
      </ListGroup>
    </ScrollBase>
  );
};

const EmployerDetails = ({ employerId }: { employerId: string }) => {
  const { t } = useTranslation();
  const employerLegalName = useEmployerLegalName(employerId);

  return (
    <ListProperty
      label={t('reporting:reporting-events.employer')}
      data-testid="reporting-event-about-tab__employer"
      value={employerLegalName}
    />
  );
};

export default ReportingEventAboutTab;
