import { Typography, Tabs, Tab } from 'fgirot-k2-ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import When from 'src/components/Common/When';
import { Employee } from 'src/types/Employee';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import AbsenceWageTypesOverviewDrawer from './AbsenceWageTypesOverviewDrawer';
import YearlyAbsenceOverview from './YearlyAbsenceOverview';
import AbsenceWageTypeDetailsDrawer from './AbsenceWageTypeDetailsDrawer';
import WageDetailsDrawer from './AbsenceWageDetailsDrawer';
import CreateDraftAbsenceWageDrawer from './CreateDraftAbsenceWageDrawer';
import './absence-overview-drawer.scss';
import { EmployeeAbsenceOverviewDrawerTab } from 'src/types/tabs/EmployeeAbsenceOverviewDrawerTab';

interface AbsenceOverviewDrawerProps {
  open: boolean;
  employee: Employee;
  onDisplayAbsenceWageTypesOverviewDrawer: (month: number) => void;
  onClose: () => void;
  onBackClick: () => void;
  period: string;
  selectedWageMonth: number;
  isDraft: boolean;
  aggregatedAbsenceEmployeeCards: AggregatedEmployeeCard[];
  employeeCardId: string;
}

const AbsenceOverviewDrawer = ({
  open,
  onClose,
  onBackClick,
  period,
  onDisplayAbsenceWageTypesOverviewDrawer,
  aggregatedAbsenceEmployeeCards,
  employee,
  selectedWageMonth,
  isDraft,
  employeeCardId,
}: AbsenceOverviewDrawerProps) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<EmployeeAbsenceOverviewDrawerTab>(
    EmployeeAbsenceOverviewDrawerTab.PERIOD,
  );

  const [selectedWageTypeId, setSelectedWageTypeId] = useState<string>();
  const [selectedWageId, setSelectedWageId] = useState<string>();
  const [isCreatingWage, setIsCreatingWage] = useState(false);

  const yearFromPeriod = period.slice(0, 4);

  const selectedPeriod = period.slice(0, 4) + selectedWageMonth?.toString().padStart(2, '0');

  const handleDisplayOverviewDrawer = () => {
    setSelectedWageId(null);
    setSelectedWageTypeId(null);
    setIsCreatingWage(false);
  };

  const handleDisplayCreateAbsenceWageDrawer = () => {
    setIsCreatingWage(true);
    setSelectedWageTypeId(null);
    setSelectedWageId(null);
  };

  const handleDisplayWageTypeDrawer = (wageTypeId: string) => {
    setSelectedWageId(null);
    setIsCreatingWage(false);
    setSelectedWageTypeId(wageTypeId);
  };

  const selectedWageType = useMemo(() => {
    return aggregatedAbsenceEmployeeCards
      .find((employeeCard) => employeeCard.period === selectedPeriod)
      ?.aggregatedWageTypeWages.find((wageType) => wageType.employerWageTypeId === selectedWageTypeId);
  }, [aggregatedAbsenceEmployeeCards, selectedWageTypeId]);

  const selectedWage = useMemo(() => {
    return aggregatedAbsenceEmployeeCards
      .find((employeeCard) => employeeCard.period === selectedPeriod)
      ?.aggregatedWageTypeWages.find((wageType) => wageType.employerWageTypeId === selectedWageTypeId)
      ?.wages.find((wage) => wage.id === selectedWageId);
  }, [aggregatedAbsenceEmployeeCards, selectedWageId, selectedWageTypeId]);

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <When condition={!selectedWageMonth}>
        <DrawerBase
          title={
            <div className="absence-overview-drawer__title">
              <Typography variant="h2" bold>
                {t('wages:absence')}
              </Typography>
              <Typography variant="subtitle">{yearFromPeriod}</Typography>
            </div>
          }
          onClose={onClose}
        >
          <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
            <Tab title={t('wages:period')}>
              <YearlyAbsenceOverview
                aggregatedAbsenceEmployeeCards={aggregatedAbsenceEmployeeCards}
                period={period}
                onClick={onDisplayAbsenceWageTypesOverviewDrawer}
              />
            </Tab>
          </Tabs>
        </DrawerBase>
      </When>
      <When condition={!!selectedWageTypeId && !selectedWageId && !isCreatingWage}>
        <AbsenceWageTypeDetailsDrawer
          selectedPeriod={selectedPeriod}
          employerId={employee.employerId}
          selectedWageType={selectedWageType}
          onBackClick={() => setSelectedWageTypeId(null)}
          onClose={onClose}
          onSelectWage={setSelectedWageId}
          isDraft={isDraft}
        />
      </When>
      <When condition={selectedWageMonth && !selectedWageType && !selectedWage && !isCreatingWage}>
        <AbsenceWageTypesOverviewDrawer
          employeeCardId={employeeCardId}
          employerId={employee.employerId}
          aggregatedAbsenceEmployeeCards={aggregatedAbsenceEmployeeCards}
          onSelectAbsenceWageType={setSelectedWageTypeId}
          displayCreateDraftAbsenceWageDrawer={handleDisplayCreateAbsenceWageDrawer}
          selectedPeriod={selectedPeriod}
          onClose={onClose}
          onBackClick={onBackClick}
          isDraft={isDraft}
        />
      </When>
      <When condition={selectedWageMonth && !!selectedWageType && !!selectedWage && !isCreatingWage}>
        <WageDetailsDrawer
          wage={selectedWage}
          wageType={selectedWageType}
          onBackClick={handleDisplayWageTypeDrawer}
          onClose={onClose}
          employeeCardId={employeeCardId}
          isDraft={isDraft}
          period={selectedPeriod}
          employerId={employee.employerId}
        />
      </When>
      <When condition={isDraft && selectedWageMonth && isCreatingWage && !selectedWageType && !selectedWage}>
        <CreateDraftAbsenceWageDrawer
          period={selectedPeriod}
          employerId={employee.employerId}
          employeeCardId={employeeCardId}
          onBackClick={handleDisplayOverviewDrawer}
          onClose={onClose}
        />
      </When>
    </DrawerModal>
  );
};

export default AbsenceOverviewDrawer;
