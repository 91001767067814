import { gql } from '@apollo/client';

export const DELETE_AGREED_REPORTING_TYPE_CONFIG = gql`
  mutation DeleteAgreedReportingTypeConfig(
    $insuranceProviderContractId: UUID!
    $agreedProductId: UUID!
    $agreedReportingTypeConfigId: UUID!
  ) {
    deleteAgreedReportingTypeConfig(
      insuranceProviderContractId: $insuranceProviderContractId
      agreedProductId: $agreedProductId
      agreedReportingTypeConfigId: $agreedReportingTypeConfigId
    ) {
      id
    }
  }
`;
