import { UPSERT_DRAFT_EMPLOYEE_CARD } from 'src/graphql/schema/mutation/employeecard/UpsertDraftEmployeeCard';
import { useApolloMutation } from '../graphql/Mutation';
import { UpsertDraftEmployeeCardRequest } from 'src/types/employees/request/UpsertDraftEmployeeCardRequest';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';

export const useUpsertDraftEmployeeCard = () => {
  const apolloMutation = useApolloMutation();

  const upsertDraftEmployeeCard = (request: UpsertDraftEmployeeCardRequest, employerId: string) =>
    apolloMutation(
      UPSERT_DRAFT_EMPLOYEE_CARD,
      employerId,
      {
        request,
      },
      [GET_LATEST_EMPLOYEE_CARD],
    );

  return upsertDraftEmployeeCard;
};
