import { DELETE_PGL_RULE_WAGE_TYPE } from 'src/graphql/schema/mutation/policy/DeletePglRuleWageType';
import { GET_PGL_RULES } from 'src/graphql/schema/query/policy/GetPglRules';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeletePglRuleWageType = () => {
  const apolloMutation = useApolloMutation();

  const deletePglRuleWageType = (policyId: string, pglRuleId: string, wageTypeId: string, employerId: string) =>
    apolloMutation(DELETE_PGL_RULE_WAGE_TYPE, employerId, { policyId, pglRuleId, wageTypeId }, [GET_PGL_RULES]);

  return deletePglRuleWageType;
};
