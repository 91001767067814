import { Button, InlineEdit, ListGroup, Switch } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyRuleType, RuleValueUnit } from 'src/types/policy/AffiliationRule';
import { GroupedAffiliationRules } from 'src/types/policy/GroupedAffiliationRules';
import { DRAFT, PolicyState } from 'src/types/policy/PolicyEntity';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';
import { RuleValue } from 'src/types/policy/RuleValue';
import { formatSweAmountInput, formatSweAmountText, parseSweAmountText } from 'src/util/Number/AmountFormatter';
import CalculationVariableListGroup from './CalculationVariableListGroup';
import LimitListGroup from './LimitListGroup';
import './wage-limit-rule-group.scss';
import {
  getDefaultLimitValue,
  getRuleByRuleType,
  wageLimitRulesAreValid,
  wageLimitRulesHaveChanged,
} from './wageLimitRuleGroupUtils';

interface WageLimitRuleGroupProps {
  policyId: string;
  employerId: string;
  policyState: PolicyState;
  selectedAffiliationRuleGroup: GroupedAffiliationRules;
  onUpdateOrDeleteAffiliationRule: (request: UpdateOrDeleteAffiliationRuleRequest) => void;
}

const WageLimitRuleGroup: FC<WageLimitRuleGroupProps> = ({
  policyId,
  employerId,
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
}) => {
  const inDraft = policyState === DRAFT;
  const { t } = useTranslation();
  const [ruleIsActive, setRuleIsActive] = useState(selectedAffiliationRuleGroup.affiliationRules.length > 0);
  const [lowerLimit, setLowerLimit] = useState(
    getDefaultLimitValue(PolicyRuleType.WAGE_LOWER_LIMIT, selectedAffiliationRuleGroup),
  );
  const [upperLimit, setUpperLimit] = useState(
    getDefaultLimitValue(PolicyRuleType.WAGE_UPPER_LIMIT, selectedAffiliationRuleGroup),
  );
  const [calculationValues, setCalculationValues] = useState<{
    sourceType: string;
    sourceReference: string;
    ruleValueUnit?: RuleValueUnit;
  }>(
    selectedAffiliationRuleGroup.affiliationRules
      ? {
          sourceType: selectedAffiliationRuleGroup.affiliationRules[0]?.sourceType,
          sourceReference: selectedAffiliationRuleGroup.affiliationRules[0]?.sourceReference,
          ruleValueUnit: selectedAffiliationRuleGroup.affiliationRules[0]?.ruleValueUnit,
        }
      : {
          sourceType: '',
          sourceReference: '',
          ruleValueUnit: undefined,
        },
  );

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setRuleIsActive(true);
    } else {
      setRuleIsActive(false);
      setUpperLimit('');
      setLowerLimit('');
      handleRemoveRules();
    }
  };

  const handleCalculationValuesChange = (input: string, field: string) =>
    setCalculationValues((prev) => {
      return { ...prev, [field]: input };
    });

  const updateOrCreateRule = (policyRuleType?: PolicyRuleType) => {
    const selectedRule = getRuleByRuleType(policyRuleType, selectedAffiliationRuleGroup);
    const ruleId = selectedRule?.id;

    let ruleValues: RuleValue[] = [];

    if (policyRuleType === PolicyRuleType.WAGE_LOWER_LIMIT && lowerLimit) {
      ruleValues = [{ value: parseSweAmountText(lowerLimit), valueType: 'GREATER_OR_EQUALS_THAN' }];
    }

    if (policyRuleType === PolicyRuleType.WAGE_UPPER_LIMIT && upperLimit) {
      ruleValues = [{ value: parseSweAmountText(upperLimit), valueType: 'GREATER_OR_EQUALS_THAN' }];
    }

    onUpdateOrDeleteAffiliationRule({
      ruleId,
      policyRuleType,
      ruleValues,
      ruleValueUnit: calculationValues.ruleValueUnit,
      sourceType: calculationValues.sourceType,
      sourceReference: calculationValues.sourceReference,
    });

    setLowerLimit(formatSweAmountText(parseSweAmountText(lowerLimit)));
    setUpperLimit(formatSweAmountText(parseSweAmountText(upperLimit)));
  };

  const handleUpperLimitOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUpperLimit(formatSweAmountInput(e.target.value));

  const handleLowerLimitOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLowerLimit(formatSweAmountInput(e.target.value));

  const handleCreateRules = () => {
    if (!lowerLimit && !upperLimit) {
      handleRemoveRules();
    } else {
      updateOrCreateRule(PolicyRuleType.WAGE_LOWER_LIMIT);
      updateOrCreateRule(PolicyRuleType.WAGE_UPPER_LIMIT);
    }
  };

  const handleRemoveRules = () => {
    selectedAffiliationRuleGroup.affiliationRules.forEach((rule) => {
      onUpdateOrDeleteAffiliationRule({
        ruleId: rule.id,
        policyRuleType: rule.policyRuleType,
        ruleValues: [],
        ruleValueUnit: calculationValues.ruleValueUnit,
        sourceType: calculationValues.sourceType,
        sourceReference: calculationValues.sourceReference,
      });
    });

    setRuleIsActive(false);
    setCalculationValues({
      sourceType: '',
      sourceReference: '',
      ruleValueUnit: undefined,
    });
  };

  return (
    <div className="wage-limit-rule-group">
      <ListGroup variant={'inline-edit'}>
        <InlineEdit label={t('policyRules:ruleValues.apply-rule')} bold>
          <Switch toggled={ruleIsActive} onChange={handleToggle} disabled={!inDraft} />
        </InlineEdit>
      </ListGroup>
      {ruleIsActive && (
        <>
          <CalculationVariableListGroup
            employerId={employerId}
            policyId={policyId}
            locked={!inDraft}
            sourceType={calculationValues.sourceType}
            sourceReference={calculationValues.sourceReference}
            ruleValueUnit={calculationValues.ruleValueUnit}
            handleCalculationValuesChange={handleCalculationValuesChange}
          />
          <LimitListGroup
            policyState={policyState}
            locked={!inDraft}
            upperLimit={upperLimit}
            handleUpperLimitOnChange={handleUpperLimitOnChange}
            lowerLimit={lowerLimit}
            handleLowerLimitOnChange={handleLowerLimitOnChange}
          />
        </>
      )}
      {inDraft && ruleIsActive && (
        <div className="wage-limit-rule-group__buttons">
          <Button
            label={t(`common:save-changes`)}
            onClick={handleCreateRules}
            data-cy="wage-limit-rule-group__use-button"
            disabled={
              !wageLimitRulesAreValid({ selectedAffiliationRuleGroup, lowerLimit, upperLimit, calculationValues }) ||
              !wageLimitRulesHaveChanged({ selectedAffiliationRuleGroup, lowerLimit, upperLimit, calculationValues })
            }
          />
        </div>
      )}
    </div>
  );
};

export default WageLimitRuleGroup;
