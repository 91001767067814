import { gql } from '@apollo/client';

export const UPDATE_CONTRACT_AGREED_PRODUCT = gql`
  mutation UpdateContractAgreedProduct(
    $insuranceProviderContractId: UUID!
    $agreedProductId: UUID!
    $request: ContractAgreedProductRequest!
  ) {
    updateContractAgreedProduct(
      insuranceProviderContractId: $insuranceProviderContractId
      agreedProductId: $agreedProductId
      request: $request
    ) {
      id
      name
      insuranceProviderProductCategory
      agreementProductCode
      certifyInsuranceProductCondition
      certifyInsuranceProductConditionId
      insuranceProduct {
        id
        name
        insuranceProvider {
          id
          legalName
        }
      }
      reportingRuleConfiguration {
        id
        name
      }
    }
  }
`;
