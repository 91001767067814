import React from 'react';
import { Button, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { finalizeCheckpoints } from 'src/service/checkpoint/FinalizeCheckpointsMutation';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { groupBySortedSubActionCategory } from '../../../utils';
import InsuranceEventPausedCheckpoint from '../InsuranceEventPausedCheckpoint';
import MandatoryFieldEventFromDateCheckpoint from '../MandatoryFieldEventFromDateCheckpoint';
import MissingAgreementCostCentreCheckpoint from '../MissingAgreementCostCentreCheckpoint';
import RetroactiveNewRegistrationCheckpoint from '../RetroactiveNewRegistrationCheckpoint';
import './verify-drawer-checkpoints-tab.scss';

interface VerifyDrawerCheckpointsTabProps {
  eventReport: InsuranceEventReportEmployeeResponse;
  insuranceEvents: InsuranceEvent[];
  onFinalize: () => void;
}

const VerifyDrawerCheckpointsTab: React.FC<VerifyDrawerCheckpointsTabProps> = ({
  eventReport,
  insuranceEvents,
  onFinalize,
}) => {
  const { t } = useTranslation();
  const subActionCategoryToCheckpointMap = groupBySortedSubActionCategory(eventReport);

  const insuranceEventMap = Array.from(insuranceEvents).reduce((map, insuranceEvent) => {
    map.set(insuranceEvent.insuranceEventId, insuranceEvent);
    return map;
  }, new Map<string, InsuranceEvent>());

  const handleFinalizeCheckpoints = () => {
    const employeeId = eventReport.employee.id;
    const employerId = eventReport.employee.employerId;
    const wageFilePeriod = eventReport.employeeCard.period;
    finalizeCheckpoints([{ employeeId, employerId }], wageFilePeriod, 'VERIFY_INSURANCE_EVENT').then(onFinalize);
  };

  return (
    <div className="verify-drawer-checkpoints-tab__drawer-container">
      {Array.from(subActionCategoryToCheckpointMap.keys()).map((key) => {
        const subActionCategoryCheckpoints = subActionCategoryToCheckpointMap.get(key);

        return (
          <div key={`VerifyDrawerCheckpointsTab-${key}`} className="verify-drawer-checkpoints-tab__checkpoint-wrapper">
            <Typography variant="body1" component="h3" bold>
              {t(`subActionCategories:${subActionCategoryCheckpoints.at(0).subActionCategory}`)}
            </Typography>
            {key === 'MANDATORY_FIELD_EVENT_FROM_DATE' && (
              <MandatoryFieldEventFromDateCheckpoint
                employerId={eventReport.employee.employerId}
                checkpoints={subActionCategoryCheckpoints}
                insuranceEvents={insuranceEventMap}
              />
            )}
            {key === 'INSURANCE_EVENT_PAUSED' && (
              <InsuranceEventPausedCheckpoint
                checkpoints={subActionCategoryCheckpoints}
                insuranceEvents={insuranceEventMap}
              />
            )}
            {key === 'MANDATORY_FIELD_AGREEMENT_COST_CENTRE' && (
              <MissingAgreementCostCentreCheckpoint
                checkpoints={subActionCategoryCheckpoints}
                employeeId={eventReport.employee.id}
              />
            )}
            {key === 'RETROACTIVE_NEW_REGISTRATION' &&
              subActionCategoryCheckpoints?.map((checkpoint) => (
                <div
                  key={`RetroactiveNewRegistrationCheckpoint-div-${checkpoint.id}`}
                  className="verify-drawer-checkpoints-tab__checkpoint-wrapper"
                >
                  <RetroactiveNewRegistrationCheckpoint
                    key={`RetroactiveNewRegistrationCheckpoint-${checkpoint.id}`}
                    checkpoint={checkpoint}
                    insuranceEvents={insuranceEventMap}
                    employerId={eventReport.employee.employerId}
                  />
                </div>
              ))}
          </div>
        );
      })}
      <Button
        type={'primary'}
        disabled={!!eventReport.checkpoints.find((checkPoint) => checkPoint.checkpointStatus == 'RAISED')}
        onClick={handleFinalizeCheckpoints}
        label={t(`verify:drawer.complete`)}
      />
    </div>
  );
};
export default VerifyDrawerCheckpointsTab;
