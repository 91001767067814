import { CertifyConditionMetadata } from 'src/types/CertifyConditionMetadata';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';

export const uniqueConditions = (metadatas: CertifyConditionMetadata[]): Map<string, CertifyConditionMetadata> => {
  return metadatas.reduce((acc: Map<string, CertifyConditionMetadata>, item: CertifyConditionMetadata) => {
    const key = item.benefitType + item.insuranceProviderId + item.insuranceProviderProductConditionId;
    acc.set(key, item);

    return acc;
  }, new Map());
};

export const extractUniqueConditions = (
  insuranceEventCheckpoints: InsuranceEventReportEmployeeResponse[],
): CertifyConditionMetadata[] => {
  const checkpointConditions = insuranceEventCheckpoints
    .flatMap((field) => field.checkpoints)
    .flatMap((checkpoint) => {
      return {
        benefitType: checkpoint.benefitType,
        insuranceProviderId: checkpoint.additionalData.insuranceProviderId,
        insuranceProviderProductConditionId: checkpoint.additionalData.insuranceProviderProductConditionId,
      };
    });
  return Array.from(uniqueConditions(checkpointConditions).values());
};
