import { useApolloMutation } from '../graphql/Mutation';
import { CREATE_DRAFT_EMPLOYEE_CARD_WAGE } from 'src/graphql/schema/mutation/employeecard/CreateDraftEmployeeCardWage';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';
import { CreateDraftEmployeeCardWageRequest } from 'src/types/employees/request/CreateDraftEmployeeCardWageRequest';

export const useCreateDraftEmployeeCardWage = () => {
  const apolloMutation = useApolloMutation();

  const createDraftEmployeeCardWage = (request: CreateDraftEmployeeCardWageRequest, employerId: string) =>
    apolloMutation(
      CREATE_DRAFT_EMPLOYEE_CARD_WAGE,
      employerId,
      {
        request,
      },
      [GET_LATEST_EMPLOYEE_CARD, FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES],
    );

  return createDraftEmployeeCardWage;
};
