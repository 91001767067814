import React from 'react';
import { Avatar, ChatBubble, Typography } from 'fgirot-k2-ui-components';
import { PolicyComment } from 'src/types/policy/PolicyEntity';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import { mapNamesToOneString } from 'src/util/NameUtil/NameUtil';
import './policy-comment-entry.scss';

interface PolicyCommentEntryProps {
  policyComment: PolicyComment;
}

const PolicyCommentEntry: React.FunctionComponent<PolicyCommentEntryProps> = ({ policyComment }) => {
  const formattedDateTime = formatDateTimeString(policyComment.createdDate);

  return (
    <div className="policy-comment-entry">
      <Avatar className="policy-comment-entry__avatar" size="small" />
      <div className="policy-comment-entry__content">
        <ChatBubble chatText={policyComment.comment} />
        <div className="policy-comment-entry__content__meta">
          <Typography className="policy-comment-entry__content__meta--name" variant="body2">
            {mapNamesToOneString(policyComment.createdBy.firstName, policyComment.createdBy.lastName)}
          </Typography>
          <Typography className="policy-comment-entry__content__meta--date" variant="body2">
            {formattedDateTime}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PolicyCommentEntry;
