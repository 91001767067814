import { CreateReportingTypeConfigRequest } from '../../types/contract/request/CreateReportingTypeConfigRequest';
import { CREATE_AGREED_REPORTING_TYPE_CONFIG } from '../../graphql/schema/mutation/contract/CreateAgreedReportingTypeConfig';
import { GET_AGREED_REPORTING_TYPE_CONFIGS } from '../../graphql/schema/query/contract/GetAgreedReportingtypeConfigs';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateAgreedReportingTypeConfig = () => {
  const apolloMutation = useApolloMutation();

  const createAgreedReportingTypeConfig = (
    employerId: string,
    insuranceProviderContractId: string,
    agreedProductId: string,
    request: CreateReportingTypeConfigRequest,
  ) =>
    apolloMutation(
      CREATE_AGREED_REPORTING_TYPE_CONFIG,
      employerId,
      {
        insuranceProviderContractId,
        agreedProductId,
        request,
      },
      [GET_AGREED_REPORTING_TYPE_CONFIGS],
    );

  return createAgreedReportingTypeConfig;
};
