import { TableBody, TableHead, TableRow, Th, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingPage from 'src/components/Common/LoadingPage';
import TableBase from 'src/components/Common/TableBase';
import { useEmployersInCustomer } from 'src/service/employer/GetEmployersInCustomer';
import { Customer } from 'src/types/customer/Customer';
import { INIT, LOADING, ERROR, LOADED } from 'src/types/Status';
import './employers-tab.scss';
import EmployersTableRow from './EmployersTableRow';

interface EmployersTabProps {
  customer: Customer;
}

const EmployersTab = ({ customer }: EmployersTabProps) => {
  const { t } = useTranslation();

  const service = useEmployersInCustomer(customer.id);
  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>{t('common:error')}</div>;
    case LOADED:
      break;
  }

  const employers = service.payload;

  const head = [{ name: t('account:employers-tab.employers') }, { name: t('account:employers-tab.org-no') }];

  return (
    <div className={['employers-tab'].join(' ')}>
      <Typography className="employers-tab__title" bold component="h2" variant="h3">
        <>
          {t('account:employers-tab.employers')} ({employers?.length.toString()})
        </>
      </Typography>

      <TableBase maxHeightPercentage={50}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => {
              return <Th key={`ValidatingTable-${title.name}-${index}`}>{title.name}</Th>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {employers?.map((employer) => (
            <EmployersTableRow key={employer.id} employer={employer} customerName={customer.name} />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default EmployersTab;
