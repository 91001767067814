import { GET_EMPLOYER_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employer/GetEmployerIndividualChoices';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import { Service } from 'src/types/Service';
import { useEffect, useState } from 'react';
import { INIT, LOADED, LOADING } from 'src/types/Status';
import { useQuery } from '@apollo/client';

export const useEmployerIndividualChoices = (employerId: string): Service<EmployerIndividualChoice[]> => {
  const [result, setResult] = useState<Service<EmployerIndividualChoice[]>>({
    status: INIT,
  });
  const { loading, error, data } = useQuery(GET_EMPLOYER_INDIVIDUAL_CHOICES, {
    variables: {
      employerId,
    },
  });

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && result.status !== LOADED && setResult({ status: LOADED, payload: [] });
    data && result.status !== LOADED && setResult({ status: LOADED, payload: data.employerIndividualChoices });
  }, [loading, error, data]);

  return result;
};
