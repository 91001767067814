import { gql } from '@apollo/client';

export const CREATE_CONTRACT_AGREEMENT_COST_CENTRE = gql`
  mutation CreateContractAgreementCostCentre(
    $insuranceProviderContractId: UUID!
    $request: AgreementCostCentreRequest!
  ) {
    createContractAgreementCostCentre(insuranceProviderContractId: $insuranceProviderContractId, request: $request) {
      id
    }
  }
`;
