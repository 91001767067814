import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeInsurancesFilters } from 'src/types/employees/request/EmployeeInsurancesRequest';
import { EmployeeInsurance } from 'src/types/insurance/Insurance';
import useInsurancesFilterOptions from './useInsurancesFilterOptions';
import './insurances-filter-modal.scss';

interface InsurancesFilterModalProps {
  employeeInsurances: EmployeeInsurance[];
  open: boolean;
  onClose: () => void;
  onApplyFilters: (filters: EmployeeInsurancesFilters) => void;
  filters: EmployeeInsurancesFilters;
}

const InsurancesFilterModal = ({
  employeeInsurances,
  open,
  onClose,
  onApplyFilters,
  filters,
}: InsurancesFilterModalProps) => {
  const [newFilters, setNewFilters] = React.useState<EmployeeInsurancesFilters>({ ...filters });
  const { t } = useTranslation();

  const { insuranceProviderFilterOptions, benefitTypeFilterOptions, agreedProductFilterOptions } =
    useInsurancesFilterOptions(employeeInsurances);

  const handleInsuranceProviderFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceProviderIdsFilter: options.map((option) => option.value),
    });
  };

  const handleBenefitTypeFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      benefitTypesFilter: options.map((option) => option.value),
    });
  };

  const handleAgreedProductFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      agreedProductIdsFilter: options.map((option) => option.value),
    });
  };

  const handleApplyChanges = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="insurances-filter-modal"
        title={t('insurances:filter')}
        icon={<Icon type="Sliders" className="insurances-filter-modal__icon" />}
        size="wide"
      >
        <div className="insurances-filter-modal__filters">
          <div className="insurances-filter-modal__select">
            <Select
              label={t('insurances:filters.insurance-providers')}
              placeholder={t('insurances:filters.choose-insurance-providers')}
              selectAll
              options={insuranceProviderFilterOptions}
              selected={insuranceProviderFilterOptions.filter((option) =>
                newFilters.insuranceProviderIdsFilter?.includes(option.value),
              )}
              onChange={handleInsuranceProviderFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="insurances-filter-modal__select">
            <Select
              label={t('insurances:filters.agreed-products')}
              placeholder={t('insurances:filters.choose-agreed-products')}
              selectAll
              options={agreedProductFilterOptions}
              selected={agreedProductFilterOptions.filter((option) =>
                newFilters.agreedProductIdsFilter?.includes(option.value),
              )}
              onChange={handleAgreedProductFilterChange}
              small
              outlined
              multiple
            />
          </div>

          <div className="insurances-filter-modal__select">
            <Select
              label={t('insurances:filters.benefit-types')}
              placeholder={t('insurances:filters.choose-benefit-types')}
              options={benefitTypeFilterOptions}
              selected={benefitTypeFilterOptions.filter((option) =>
                newFilters.benefitTypesFilter?.includes(option.value),
              )}
              onChange={handleBenefitTypeFilterChange}
              small
              outlined
              multiple
            />
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('common:use')} onClick={handleApplyChanges} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default InsurancesFilterModal;
