import { Divider, InlineEdit, Option, Select } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Month } from 'src/types/Month';
import { convertMonthEnumToStringWithZeroes, useMonthOptions } from 'src/util/date/Months';
import { useYearOptions } from 'src/util/date/Years';
import './period-from-to-input.scss';

interface PeriodFromToInputProps {
  selectedFromYear: Option<number>;
  setSelectedFromYear: React.Dispatch<React.SetStateAction<Option<number>>>;
  selectedFromMonth: Option<Month>;
  setSelectedFromMonth: React.Dispatch<React.SetStateAction<Option<Month>>>;
  selectedToYear: Option<number>;
  setSelectedToYear: React.Dispatch<React.SetStateAction<Option<number>>>;
  selectedToMonth: Option<Month>;
  setSelectedToMonth: React.Dispatch<React.SetStateAction<Option<Month>>>;
  setSelectedFromPeriod: React.Dispatch<React.SetStateAction<string>>;
  setSelectedToPeriod: React.Dispatch<React.SetStateAction<string>>;
}

const PeriodFromToInput = ({
  selectedFromYear,
  setSelectedFromYear,
  selectedFromMonth,
  setSelectedFromMonth,
  selectedToYear,
  setSelectedToYear,
  selectedToMonth,
  setSelectedToMonth,
  setSelectedFromPeriod,
  setSelectedToPeriod,
}: PeriodFromToInputProps) => {
  const { t } = useTranslation();

  const yearsOptions = useYearOptions();
  const monthOptions = useMonthOptions();

  const handleFromPeriodChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedFromYear(yearOption);
    setSelectedFromMonth(monthOption);

    if (monthOption && yearOption) {
      const period = yearOption.label + convertMonthEnumToStringWithZeroes(monthOption.value);
      setSelectedFromPeriod(period);
    }
  };

  const handleToPeriodChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedToYear(yearOption);
    setSelectedToMonth(monthOption);

    if (monthOption && yearOption) {
      const period = yearOption.label + convertMonthEnumToStringWithZeroes(monthOption.value);
      setSelectedToPeriod(period);
    }
  };

  return (
    <>
      <InlineEdit
        label={t('documents:document-templates-tab.drawer.generate-document-drawer-tab.period-start-label')}
        bold
        mandatory
      >
        <div className="period-from-to-input__period__selects">
          <Select
            placeholder={
              !selectedFromMonth ? t('common:month').toLowerCase() : t(`common:months.${selectedFromMonth.value}`)
            }
            options={monthOptions}
            onChange={(option: Option<Month>) => handleFromPeriodChange(option, selectedFromYear)}
            selected={selectedFromMonth}
            alphabetical={false}
            data-testid="dropdown-select-from-month"
            small
          />
          <Select
            placeholder={!selectedFromYear ? t('common:year').toLowerCase() : selectedFromYear.value.toString()}
            options={yearsOptions}
            onChange={(option: Option<number>) => handleFromPeriodChange(selectedFromMonth, option)}
            selected={selectedFromYear}
            data-testid="dropdown-select-from-year"
            small
          />
        </div>
      </InlineEdit>
      <Divider />
      <InlineEdit
        label={t('documents:document-templates-tab.drawer.generate-document-drawer-tab.period-end-label')}
        bold
        mandatory
      >
        <div className="period-from-to-input__period__selects">
          <Select
            placeholder={
              !selectedToMonth ? t('common:month').toLowerCase() : t(`common:months.${selectedToMonth.value}`)
            }
            options={monthOptions}
            onChange={(option: Option<Month>) => handleToPeriodChange(option, selectedToYear)}
            selected={selectedToMonth}
            alphabetical={false}
            data-testid="dropdown-select-to-month"
            small
          />
          <Select
            placeholder={!selectedToYear ? t('common:year').toLowerCase() : selectedToYear.value.toString()}
            options={yearsOptions}
            onChange={(option: Option<number>) => handleToPeriodChange(selectedToMonth, option)}
            selected={selectedToYear}
            data-testid="dropdown-select-to-year"
            small
          />
        </div>
      </InlineEdit>
    </>
  );
};

export default PeriodFromToInput;
