import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { useDeletePaymentTransaction } from 'src/service/invoice/PaymentTransaction';
import { Invoice } from 'src/types/invoice/Invoice';
import { PaymentTransaction } from 'src/types/invoice/PaymentTransaction';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';
import './payment-transactions-details.scss';

interface PaymentTransactionDetailsProps {
  invoice: Invoice;
  paymentTransaction: PaymentTransaction;
  onClose: () => void;
  onBackClick: () => void;
}

const PaymentTransactionDetails: FC<PaymentTransactionDetailsProps> = ({
  invoice,
  paymentTransaction,
  onClose,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const deletePaymentTransaction = useDeletePaymentTransaction();

  const handleDeleteTransaction = () => {
    deletePaymentTransaction(paymentTransaction.id, invoice.employer.id).then(() => onBackClick());
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="invoice-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('invoice:invoice-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="invoice-drawer__sub-title">
          <Typography>{invoice.employer?.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('invoice:invoice-drawer.transactions-tab.payment-due-date-label')}
          value={stringOrBlank(paymentTransaction.createdDate).substring(0, 10)}
        />
        <ListProperty
          label={t('invoice:invoice-drawer.transactions-tab.amount-label')}
          value={formatSweAmountText(`${paymentTransaction.amount}`)}
        />
        <ListProperty
          label={t('invoice:invoice-drawer.transactions-tab.note-label')}
          value={`${paymentTransaction.note}`}
        />
      </ListGroup>
      <div className="payment-transactions-details__buttons">
        <Button
          className="payment-transactions-details__button"
          icon={<Icon type="Trash" />}
          type="secondary"
          label={t('common:delete')}
          disabled={invoice.invoiceTypeCode === 'BOOKKEEPING'}
          onClick={handleDeleteTransaction}
          data-cy="payment-transaction-details__delete-button"
        />
      </div>
    </Drawer>
  );
};

export default PaymentTransactionDetails;
