import { gql } from '@apollo/client';

export const ACCOUNTING_TYPES = gql`
  query GetAccountingTypes($employerId: UUID!) {
    accountingTypes(employerId: $employerId) {
      id
      accountingComponentsSource
      accountingTypeValue {
        name
        number
        defaultAccountingComponentSource
        validAccountingComponentSources
      }
      accountingComponents {
        id
        name
        type
        parentId
        share
        accountingComponents
      }
    }
  }
`;

export const ACCOUNTING_TYPE = gql`
  query GetAccountingType($accountingTypeId: UUID!) {
    accountingType(accountingTypeId: $accountingTypeId) {
      id
      accountingComponentsSource
      accountingTypeValue {
        name
        number
        allowedMultipleAccountingRows
        defaultAccountingComponentSource
        validAccountingComponentSources
      }
      accountingComponents {
        id
        name
        accountingComponentValueId
        type
        parentId
        share
        accountingComponents
      }
    }
  }
`;
