import { gql } from '@apollo/client';

export const UPDATE_DRAFT_EMPLOYEE = gql`
  mutation UpdateDraftEmployee($request: UpdateDraftEmployee!) {
    updateDraftEmployee(request: $request) {
      id
      employerId
      employeeSourceType
      employmentNumber
      firstName
      lastName
      personNumber
      dateOfBirth
      employmentStartDate
      employmentEndDate
      isDraft
    }
  }
`;
