import React, { useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { ReportingScheduledJob } from 'src/types/reporting/ReportingScheduledJob';
import ReportingScheduledJobsOverviewDrawer from './ReportingScheduledJobsOverviewDrawer';
import ReportingScheduledJobsDetailsDrawer from './ReportingScheduledJobsDetailsDrawer';
import { Employer } from 'src/types/employer/Employer';

interface ReportingScheduledJobsDrawerProps {
  open: boolean;
  onClose: () => void;
  reportingScheduledJobs: ReportingScheduledJob[];
  employer: Employer;
}

function ReportingScheduledJobsDrawer({
  open,
  onClose,
  reportingScheduledJobs,
  employer,
}: ReportingScheduledJobsDrawerProps) {
  const [selectedReportingScheduledJob, setSelectedReportingScheduledJob] = useState<ReportingScheduledJob>();

  const handleSelectReportingScheduledJob = (reportingScheduledJob: ReportingScheduledJob) => {
    setSelectedReportingScheduledJob(reportingScheduledJob);
  };

  const handleBackClick = () => {
    setSelectedReportingScheduledJob(null);
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      {!selectedReportingScheduledJob && (
        <ReportingScheduledJobsOverviewDrawer
          reportingScheduledJobs={reportingScheduledJobs}
          onClose={onClose}
          onSelectReportingScheduledJob={handleSelectReportingScheduledJob}
        />
      )}
      {selectedReportingScheduledJob && (
        <ReportingScheduledJobsDetailsDrawer
          reportingScheduledJob={selectedReportingScheduledJob}
          onClose={onClose}
          onBackClick={handleBackClick}
          employer={employer}
        />
      )}
    </DrawerModal>
  );
}

export default ReportingScheduledJobsDrawer;
