import { Button, InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import TextInput from 'src/components/Common/TextInput';
import { GET_EMPLOYER_SALARY_SYSTEMS } from 'src/graphql/schema/query/employer/GetEmployerSalarySystems';
import { useUpsertEmployerWageFileSetting } from 'src/service/employer/UpsertEmployerWageFileSetting';
import { Employer, WageFileFormat, wageFileFormats } from 'src/types/employer/Employer';
import { EmployerWageFileSetting } from 'src/types/employer/EmployerWageFileSetting';
import { EmployerWageFileSettingRequest } from 'src/types/employer/request/EmployerWageFileSettingRequest';
import {
  hasWageFileSettingRequestChangedFromWageFileSetting,
  wageFileSettingRequestIsValid,
} from 'src/util/EmployerUtil';
import { createOptions } from 'src/util/OptionUtil';

interface AboutDrawerTabProps {
  employer: Employer;
  employerWageFileSetting: EmployerWageFileSetting;
}

const AboutDrawerTab: FC<AboutDrawerTabProps> = ({ employer, employerWageFileSetting }) => {
  const { t } = useTranslation();
  const upsertEmployerWageFileSetting = useUpsertEmployerWageFileSetting();

  const [wageFileSettingRequest, setWageFileSettingRequest] = useState<EmployerWageFileSettingRequest>({
    salarySystem: employerWageFileSetting?.salarySystem ? employerWageFileSetting.salarySystem : null,
    wageFileFormat: employerWageFileSetting?.wageFileFormat ? employerWageFileSetting.wageFileFormat : null,
    expectedNoOfWageFiles: employerWageFileSetting?.expectedNoOfWageFiles
      ? employerWageFileSetting.expectedNoOfWageFiles
      : null,
  });

  const saveButtonDisabled =
    !hasWageFileSettingRequestChangedFromWageFileSetting(wageFileSettingRequest, employerWageFileSetting) ||
    !wageFileSettingRequestIsValid(wageFileSettingRequest);

  const wageFileFormatsOptions: Option<WageFileFormat>[] = createOptions(wageFileFormats);

  const handleSalarySystemChange = (option: Option<string>) => {
    setWageFileSettingRequest({ ...wageFileSettingRequest, salarySystem: option.value });
  };

  const handleWageFileFormatChange = (option: Option<WageFileFormat>) => {
    setWageFileSettingRequest({ ...wageFileSettingRequest, wageFileFormat: option.value });
  };

  const handleExpectedNoOfFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWageFileSettingRequest({ ...wageFileSettingRequest, expectedNoOfWageFiles: e.target.valueAsNumber });
  };

  const handleUpsertEmployerWageFileSettings = () => {
    upsertEmployerWageFileSetting(employer.id, wageFileSettingRequest);
  };

  return (
    <div className="wage-file-settings-drawer">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('reportingSettings:salary-system')} bold>
          <QueryWrapper query={GET_EMPLOYER_SALARY_SYSTEMS} type="list">
            {(employerSalarySystems: string[]) => {
              const employerSalarySystemsOptions: Option<string>[] = createOptions(employerSalarySystems);
              return (
                <Select
                  small
                  selected={employerSalarySystemsOptions.find(
                    (option) => option.value === wageFileSettingRequest.salarySystem,
                  )}
                  options={employerSalarySystemsOptions}
                  onChange={handleSalarySystemChange}
                  placeholder={t('reportingSettings:salary-system-placeholder')}
                  data-cy="salary-system__dropdown"
                />
              );
            }}
          </QueryWrapper>
        </InlineEdit>
        <InlineEdit label={t('reportingSettings:wage-file-format')} bold>
          <Select
            small
            selected={wageFileFormatsOptions.find((option) => option.value === wageFileSettingRequest.wageFileFormat)}
            options={wageFileFormatsOptions}
            onChange={handleWageFileFormatChange}
            placeholder={t('reportingSettings:wage-file-format-placeholder')}
            data-cy="wage-file-format__dropdown"
          />
        </InlineEdit>
        <InlineEdit label={t('reportingSettings:expected-no-of-files')} bold>
          <TextInput
            value={
              wageFileSettingRequest.expectedNoOfWageFiles === null
                ? ''
                : wageFileSettingRequest.expectedNoOfWageFiles.toString()
            }
            type="number"
            validationKey="integer"
            onChange={handleExpectedNoOfFilesChange}
            variant="inline-edit"
            placeholder={t('reportingSettings:expected-no-of-files-placeholder')}
            data-cy="expected-no-of-files__input"
          />
        </InlineEdit>
      </ListGroup>
      <Button
        className="wage-file-settings-drawer__button"
        type="primary"
        label={t('common:save-changes')}
        onClick={handleUpsertEmployerWageFileSettings}
        disabled={saveButtonDisabled}
        data-cy="wage-file-setting__about-drawer-tab__save-button"
      />
    </div>
  );
};

export default AboutDrawerTab;
