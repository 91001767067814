import { Button, ListGroup } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import {
  EmployeeIndividualChoice,
  EmployeeIndividualChoiceProperty,
} from 'src/types/employees/EmployeeIndividualChoice';
import { UpdateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/UpdateEmployeeIndividualChoiceRequest';
import { Employee } from 'src/types/Employee';
import { canIndividualChoiceBeRemoved, employeeIndividualChoiceRequestIsValid } from 'src/util/IndividualChoiceUtil';
import { useDeleteDraftEmployeeIndividualChoice } from 'src/service/employee/DeleteDraftEmployeeIndividualChoice';
import { useUpdateDraftEmployeeIndividualChoice } from 'src/service/employee/UpdateDraftEmployeeIndividualChoice';
import IndividualChoiceField from '../../IndividualChoiceField';
import IndividualChoiceProperty from '../../../IndividualChoiceProperty';

interface EmployeeIndividualChoiceEditableFieldsProps {
  individualChoice: EmployeeIndividualChoice;
  relevantFields: (keyof EmployeeIndividualChoice)[];
  employee: Employee;
  onBackClick: () => void;
}

const EmployeeIndividualChoiceEditableFields: React.FC<EmployeeIndividualChoiceEditableFieldsProps> = ({
  individualChoice,
  relevantFields,
  employee,
  onBackClick,
}) => {
  const [request, setRequest] = useState<UpdateEmployeeIndividualChoiceRequest>({
    employeeId: employee.id,
    individualChoiceId: individualChoice.id,
    fromPeriod: individualChoice.fromPeriod,
    individualAge: individualChoice.individualAge,
  });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const { t } = useTranslation();

  const deleteDraftEmployeeIndividualChoice = useDeleteDraftEmployeeIndividualChoice();
  const updateDraftEmployeeIndividualChoice = useUpdateDraftEmployeeIndividualChoice();

  const isRemovable = canIndividualChoiceBeRemoved(individualChoice.type);

  const handleUpdate = () => {
    let req = request;
    if (req.individualAge) {
      req = { ...req, individualAge: +req.individualAge };
    }
    updateDraftEmployeeIndividualChoice(req, employee.employerId).then(() => {
      onBackClick();
    });
  };

  const handleDelete = async () => {
    await deleteDraftEmployeeIndividualChoice(employee.id, individualChoice.id, employee.employerId);
    onBackClick();
  };

  const handleChange = (key: EmployeeIndividualChoiceProperty, value: string) => {
    setRequest((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setSaveButtonDisabled(
      !employeeIndividualChoiceRequestIsValid(relevantFields, request, employee.employmentStartDate, individualChoice),
    );
  }, [request, individualChoice]);

  return (
    <>
      <ListGroup variant="inline-edit">
        <IndividualChoiceProperty property="type" individualChoice={individualChoice} isEditing />
        <IndividualChoiceProperty property="source" individualChoice={individualChoice} isEditing />
        {relevantFields.map((field) => (
          <IndividualChoiceField
            key={field}
            property={field}
            individualChoice={individualChoice}
            value={request[field]}
            onChange={handleChange}
          />
        ))}
      </ListGroup>
      <Button type="primary" label={t('common:save-changes')} disabled={saveButtonDisabled} onClick={handleUpdate} />
      <When condition={isRemovable}>
        <Button type="secondary" label={t('employees:individual-choices.remove-choice')} onClick={handleDelete} />
      </When>
    </>
  );
};

export default EmployeeIndividualChoiceEditableFields;
