import { Button, Icon, ListGroup } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { Employee } from 'src/types/Employee';
import { EmployeeIndividualChoice } from 'src/types/employees/EmployeeIndividualChoice';
import { getRelevantFieldsForEmployeeIndividualChoice } from 'src/util/IndividualChoiceUtil';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';
import IndividualChoiceProperty from '../../IndividualChoiceProperty';
import When from 'src/components/Common/When';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import EmployeeIndividualChoiceEditableFields from './EditableFields';
import './employee-individual-choice-details-drawer.scss';

interface EmployeeIndividualChoiceDetailsDrawerProps {
  individualChoice: EmployeeIndividualChoice;
  isDraft: boolean;
  employee: Employee;
  onBackClick: () => void;
  onClose: () => void;
}

function EmployeeIndividualChoiceDetailsDrawer({
  individualChoice,
  isDraft,
  employee,
  onBackClick,
  onClose,
}: EmployeeIndividualChoiceDetailsDrawerProps) {
  const { t } = useTranslation();

  const fullName = formatEmployeeFullName(employee);

  const relevantFields = getRelevantFieldsForEmployeeIndividualChoice(individualChoice.type);

  return (
    <DrawerBase
      title={t(`individualChoiceTypes:${individualChoice.type}`)}
      subTitle={fullName}
      onClose={onClose}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
    >
      <div className="employee-individual-choice-details-drawer">
        <When condition={isDraft}>
          <EmployeeInDraftBanner />
          <EmployeeIndividualChoiceEditableFields
            individualChoice={individualChoice}
            relevantFields={relevantFields}
            employee={employee}
            onBackClick={onBackClick}
          />
        </When>
        <When condition={!isDraft}>
          <ListGroup variant="inline-edit">
            <IndividualChoiceProperty property="type" individualChoice={individualChoice} />
            <IndividualChoiceProperty property="source" individualChoice={individualChoice} />
            {relevantFields.map((field) => (
              <IndividualChoiceProperty key={field} property={field} individualChoice={individualChoice} />
            ))}
          </ListGroup>
        </When>
      </div>
    </DrawerBase>
  );
}

export default EmployeeIndividualChoiceDetailsDrawer;
