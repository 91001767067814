import { gql } from '@apollo/client';

export const POSTING_RULE = gql`
  query GetPostingRule($postingRuleId: UUID!) {
    postingRule(postingRuleId: $postingRuleId) {
      id
      name
      insuranceTypeMapping {
        id
        name
        benefitType
        taxRule
        insuranceCategory
        premiumSource
        identifier
        insuranceProvider {
          id
          legalName
        }
        invoiceRowMappings {
          id
          invoiceRowName
        }
      }
      accountingRow {
        accountNumber
      }
      accountingComponents {
        id
        name
        accountingComponentValueId
        type
        parentId
        share
        accountingComponents
      }
    }
  }
`;
