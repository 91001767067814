import React, { useEffect, useState } from 'react';
import { Wage } from 'src/types/EmployeeCard';
import { Button, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { UpdateDraftEmployeeCardWageRequest } from 'src/types/employees/request/UpdateDraftEmployeeCardWageRequest';
import { useUpdateDraftEmployeeCardWage } from 'src/service/employeecard/UpdateDraftEmployeeCardWage';
import { stringOrBlank } from 'src/util/StringUtil';
import './absence-wage-details.scss';
import { numberOfDaysInMonth } from 'src/util/date/PeriodUtil';
import InfoMessage from 'src/components/Common/InfoMessage';
import { formatPayType } from 'src/util/WageUtil';

interface AbsenceWageDetailsProps {
  period: string;
  wage: Wage;
  employeeCardId: string;
  employerId: string;
  isDraft: boolean;
  onBackClick: () => void;
  employerWageTypes: EmployerWageType[];
}

function AbsenceWageDetails({
  period,
  wage,
  employeeCardId,
  isDraft,
  employerId,
  onBackClick,
  employerWageTypes,
}: AbsenceWageDetailsProps) {
  const [extentAdjustedAbsenceDays, setExtentAdjustedAbsenceDays] = useState<string>(
    wage.extentAdjustedAbsenceDays ? wage.extentAdjustedAbsenceDays.toString() : '',
  );
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const updateDraftEmployeeCardWage = useUpdateDraftEmployeeCardWage();

  const { t } = useTranslation();

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtentAdjustedAbsenceDays(e.target.value);
  };

  const handleSaveChanges = async () => {
    setSaveButtonDisabled(true);

    const request: UpdateDraftEmployeeCardWageRequest = {
      employeeCardId,
      wageId: wage.id,
      changes: [
        {
          fieldKey: 'extentAdjustedAbsenceDays',
          fieldValue: extentAdjustedAbsenceDays.replaceAll(',', '.'),
        },
      ],
    };

    await updateDraftEmployeeCardWage(request, employerId);
    onBackClick();
  };

  const periodYear = +period.slice(0, 4);
  const periodMonth = +period.slice(4, 6) + 1;

  useEffect(() => {
    setSaveButtonDisabled(false);

    const days = Number(extentAdjustedAbsenceDays.replaceAll(',', '.'));

    isNaN(days) && setSaveButtonDisabled(true);
    if (days > numberOfDaysInMonth(periodYear, periodMonth)) {
      setSaveButtonDisabled(true);
    }

    days === wage.extentAdjustedAbsenceDays && setSaveButtonDisabled(true);
    !extentAdjustedAbsenceDays && setSaveButtonDisabled(true);
  }, [extentAdjustedAbsenceDays]);

  const employerWageType = employerWageTypes.find((w) => w.id === wage.employerWageTypeId);
  const payTypeTranslation = formatPayType(wage.payTypeNumber, wage.payTypeName, t);

  return (
    <div className="absence-wage-details">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('wages:absence-wage-type')} bold>
          {isDraft ? (
            <TextInput value={employerWageType?.name || ''} onChange={null} disabled />
          ) : (
            <Typography variant="body2">{stringOrBlank(employerWageType?.name)}</Typography>
          )}
        </InlineEdit>
        <InlineEdit label={t('wages:absence-pay-type')} bold>
          {isDraft ? (
            <TextInput value={payTypeTranslation} onChange={null} disabled />
          ) : (
            <Typography variant="body2">{payTypeTranslation}</Typography>
          )}
        </InlineEdit>
        <InlineEdit label={t('wages:from-date')} bold>
          {isDraft ? (
            <TextInput value={stringOrBlank(wage.fromDate)} onChange={null} data-cy="fromDate-input" disabled />
          ) : (
            <Typography variant="body2">{stringOrBlank(wage.fromDate)}</Typography>
          )}
        </InlineEdit>
        <InlineEdit label={t('wages:to-date')} bold>
          {isDraft ? (
            <TextInput value={stringOrBlank(wage.toDate)} onChange={null} data-cy="toDate-input" disabled />
          ) : (
            <Typography variant="body2">{stringOrBlank(wage.toDate)}</Typography>
          )}
        </InlineEdit>
        <InlineEdit label={t('wages:extent-adjusted-absence-days')} bold>
          {isDraft ? (
            <TextInput
              value={extentAdjustedAbsenceDays?.toString()}
              onChange={handleValueChange}
              validationKey="amount2Dec"
              type="text"
              data-cy="extentAdjustedAbsenceDays-input"
            />
          ) : (
            <Typography variant="body2">
              {wage.extentAdjustedAbsenceDays
                ? `${wage.extentAdjustedAbsenceDays} ${
                    wage.extentAdjustedAbsenceDays === 1 ? t('wages:day') : t('wages:days')
                  }`
                : '-'}
            </Typography>
          )}
        </InlineEdit>
      </ListGroup>
      {isDraft && <InfoMessage message={t('wages:update-absence-wage-info-message')} />}
      {isDraft && (
        <Button
          type="primary"
          label={t('common:save-changes')}
          onClick={handleSaveChanges}
          disabled={saveButtonDisabled || extentAdjustedAbsenceDays === ''}
          data-cy="save-changes-button"
        />
      )}
    </div>
  );
}

export default AbsenceWageDetails;
