import { gql } from '@apollo/client';

export const SEARCH_EMPLOYEES = gql`
  query SearchEmployees($searchEmployeesRequest: SearchEmployeesRequest!) {
    employees(searchEmployeesRequest: $searchEmployeesRequest) {
      persons {
        personNumber
        employments {
          id
          employerId
          employmentNumber
          firstName
          lastName
          personNumber
          dateOfBirth
          employmentStartDate
          employmentEndDate
          customerEmploymentStartDate
          accountingUnit {
            id
            name
          }
        }
      }
      paging {
        totalNumberOfElements
        hasNext
      }
    }
  }
`;
