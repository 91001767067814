import { UPDATE_EMPLOYEE_CARD_WAGE } from 'src/graphql/schema/mutation/employeecard/UpdateEmployeeCardWage';
import { UpdateEmployeeCardWage } from 'src/types/UpdateEmployeeCardWage';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_CARD, GET_EMPLOYEE_CARD_WITH_WAGES } from 'src/graphql/schema/query/employee/GetEmployeeCard';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';

export const useUpdateEmployeeCardWage = () => {
  const apolloMutation = useApolloMutation();

  return (request: UpdateEmployeeCardWage, employerId: string) =>
    apolloMutation(UPDATE_EMPLOYEE_CARD_WAGE, employerId, { request }, [
      GET_EMPLOYEE_CARD,
      GET_EMPLOYEE_CARD_WITH_WAGES,
      FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES,
    ]);
};
