import { Tabs, Tab } from 'fgirot-k2-ui-components';
import React from 'react';
import DrawerBase from 'src/components/Common/DrawerBase';
import ReportingScheduledJobsAboutTab from './ReportingScheduledJobsAboutTab';
import { useTranslation } from 'react-i18next';
import { ReportingScheduledJob } from 'src/types/reporting/ReportingScheduledJob';

interface ReportingScheduledJobsOverviewDrawerProps {
  onClose: () => void;
  reportingScheduledJobs: ReportingScheduledJob[];
  onSelectReportingScheduledJob: (reportingScheduledJob: ReportingScheduledJob) => void;
}

function ReportingScheduledJobsOverviewDrawer({
  onClose,
  reportingScheduledJobs,
  onSelectReportingScheduledJob,
}: ReportingScheduledJobsOverviewDrawerProps) {
  const { t } = useTranslation();

  return (
    <DrawerBase
      title={t('reportingSettings:reporting-scheduled-jobs.schedulings')}
      subTitle={t('reportingSettings:reporting-scheduled-jobs.active-schedulings')}
      onClose={onClose}
    >
      <Tabs>
        <Tab title={t('common:about')}>
          <ReportingScheduledJobsAboutTab
            reportingScheduledJobs={reportingScheduledJobs}
            onSelectReportingScheduledJob={onSelectReportingScheduledJob}
          />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
}

export default ReportingScheduledJobsOverviewDrawer;
