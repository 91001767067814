import { gql, useQuery } from '@apollo/client';

export const useEmployerLegalName = (employerId: string): string => {
  const { data } = useQuery(GET_EMPLOYER_LEGAL_NAME, { variables: { employerId } });

  return data?.employer?.legalName || '';
};

export const GET_EMPLOYER_LEGAL_NAME = gql`
  query ($employerId: UUID!) {
    employer(employerId: $employerId) {
      id
      legalName
    }
  }
`;
