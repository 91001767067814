import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateWithFallback = (delta: number, fallbackPath: string) => {
  const location = useLocation();
  const nav = useNavigate();
  const navigate = useCallback(() => {
    const doesAnyHistoryExist = location.key !== 'default';
    doesAnyHistoryExist ? nav(delta) : nav(fallbackPath);
  }, [delta, fallbackPath, location, nav]);

  return navigate;
};
