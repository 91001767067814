import { IconType, Icon, Typography } from 'fgirot-k2-ui-components';

interface PeriodDetailsCardBoxProps {
  title: string;
  value: string;
  icon: IconType;
}

const PeriodDetailsCardBox = ({ title, value, icon, ...props }: PeriodDetailsCardBoxProps) => {
  return (
    <div className="period-details-card--info-box" {...props}>
      <div className="period-details-card--info-box-icon">
        <Icon type={icon} />
      </div>
      <div className="period-details-card--info-box-content">
        <Typography variant="caption" className="period-details-card--info-box-title">
          {title}
        </Typography>
        <Typography variant="body2" bold className="period-details-card--info-box-value">
          {value}
        </Typography>
      </div>
    </div>
  );
};

export default PeriodDetailsCardBox;
