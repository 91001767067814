import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser($userId: UUID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
      email
      phone
      identifier
      identifierType
      orderRef
      userType
      admin
      validTo
      accountNonLocked
      customers {
        name
        id
      }
    }
  }
`;
