import { Td, Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import './td-benefit-type.scss';

interface TdBenefitTypeProps {
  insuranceEvents: InsuranceEventCheckpoint[] | InsuranceEvent[];
  warning?: boolean;
}

const TdBenefitType = ({ insuranceEvents, warning }: TdBenefitTypeProps) => {
  const { t } = useTranslation();
  return (
    <Td>
      {insuranceEvents && (
        <Pill
          type={warning ? 'warning' : 'neutral'}
          className={'pill--margin'}
          label={`${t(`benefitTypes:abbreviations.${insuranceEvents[0]?.benefitType}`)}${
            insuranceEvents.length > 1 ? ` + ${insuranceEvents.length - 1}` : ''
          }`}
        />
      )}
    </Td>
  );
};

export default TdBenefitType;
