import { gql } from '@apollo/client';

export const EMPLOYER_PAY_TYPE_UPDATED = gql`
  subscription EmployerPayTypeUpdated($employerId: UUID!) {
    employerPayTypeUpdated(employerId: $employerId) {
      id
      number
      name
      employerWageType {
        id
        name
        wageType
      }
      employerId
      inUse
      useQuantity
      employerKubs {
        id
        employerId
        kubType
        description
      }
    }
  }
`;
