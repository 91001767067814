import { AccountingAdjustmentActionInput } from 'src/types/accountingunit/AccountingAdjustmentAction';
import { AccountingAdjustmentCondition } from 'src/types/accountingunit/AccountingAdjustmentCondition';

export const isValidCondition = (condition) => {
  if (condition.accountingAdjustmentConditionType === 'ACCOUNTING_COMPONENT_VALUE') {
    return condition.accountingAdjustmentConditionType !== null;
  } else {
    return condition.accountingAdjustmentConditionType !== null && condition.reference.id !== null;
  }
};

export const isValidAction = (accountingAdjustmentAction) => {
  if (accountingAdjustmentAction.accountingAdjustmentActionType === 'ACCOUNTING_COMPONENT_VALUE') {
    return accountingAdjustmentAction.accountingAdjustmentActionType !== null;
  } else {
    return (
      accountingAdjustmentAction.accountingAdjustmentActionType !== null &&
      accountingAdjustmentAction.referenceId !== null
    );
  }
};

export const isValidRequest = (
  accountingAdjustmentConditions: AccountingAdjustmentCondition[],
  accountingAdjustmentAction: AccountingAdjustmentActionInput,
): boolean => {
  const areConditionsValid =
    accountingAdjustmentConditions.length > 0 && accountingAdjustmentConditions.every(isValidCondition);

  const isActionValid = isValidAction(accountingAdjustmentAction);

  return areConditionsValid && isActionValid;
};
