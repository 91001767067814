import React from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { Employee } from 'src/types/Employee';
import { EmployeeIndividualChoice, EmployeeIndividualChoices } from 'src/types/employees/EmployeeIndividualChoice';
import EmployeeIndividualChoicesOverviewDrawer from './OverviewDrawer';
import EmployeeIndividualChoiceDetailsDrawer from './DetailsDrawer';
import When from 'src/components/Common/When';
import EmployeeIndividualChoiceCreateDrawer from './CreateDrawer';

interface EmployeeIndividualChoicesDrawerProps {
  open: boolean;
  onClose: () => void;
  employeeIndividualChoices: EmployeeIndividualChoices;
  employee: Employee;
}

function EmployeeIndividualChoicesDrawer({
  open,
  onClose,
  employeeIndividualChoices,
  employee,
}: EmployeeIndividualChoicesDrawerProps) {
  const [selectedEmployeeIndividualChoice, setSelectedEmployeeIndividualChoice] =
    React.useState<EmployeeIndividualChoice>(null);
  const [isCreating, setIsCreating] = React.useState<boolean>(false);

  const handleSelectEmployeeIndividualChoice = (employeeIndividualChoice: EmployeeIndividualChoice) => {
    setSelectedEmployeeIndividualChoice(employeeIndividualChoice);
    setIsCreating(false);
  };

  const handleDisplayCreateDrawer = () => {
    setSelectedEmployeeIndividualChoice(null);
    setIsCreating(true);
  };

  const handleBackClick = () => {
    setSelectedEmployeeIndividualChoice(null);
    setIsCreating(false);
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <When condition={!!selectedEmployeeIndividualChoice && !isCreating}>
        <EmployeeIndividualChoiceDetailsDrawer
          individualChoice={selectedEmployeeIndividualChoice}
          isDraft={employee.isDraft}
          employee={employee}
          onBackClick={handleBackClick}
          onClose={onClose}
        />
      </When>
      <When condition={!selectedEmployeeIndividualChoice && !isCreating}>
        <EmployeeIndividualChoicesOverviewDrawer
          employeeIndividualChoices={employeeIndividualChoices}
          employee={employee}
          onSelectIndividualChoice={handleSelectEmployeeIndividualChoice}
          onClose={onClose}
          onCreateIndividualChoice={handleDisplayCreateDrawer}
        />
      </When>
      <When condition={!selectedEmployeeIndividualChoice && isCreating}>
        <EmployeeIndividualChoiceCreateDrawer employee={employee} onClose={onClose} onBackClick={handleBackClick} />
      </When>
    </DrawerModal>
  );
}

export default EmployeeIndividualChoicesDrawer;
