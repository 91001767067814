import { Button, Icon, ListGroup, InlineEdit, Typography, DrawerNavigation } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import { stringOrBlank } from 'src/util/StringUtil';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import React from 'react';
import './absence-wage-type-details-drawer.scss';
import DraftChangedTooltip from 'src/components/Common/DraftChangedTooltip';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import { formatPayType } from 'src/util/WageUtil';

interface AbsenceWageTypeDetailsDrawerProps {
  selectedWageType: AggregatedWageTypeWage;
  selectedPeriod: string;
  employerId: string;
  onClose: () => void;
  onBackClick: () => void;
  onSelectWage: (wageId: string) => void;
  isDraft: boolean;
}

const AbsenceWageTypeDetailsDrawer = ({
  selectedWageType,
  onClose,
  onBackClick,
  employerId,
  onSelectWage,
  selectedPeriod,
  isDraft,
}: AbsenceWageTypeDetailsDrawerProps) => {
  const { t } = useTranslation();
  const employerWageTypes = useEmployerWageTypes(employerId);
  const formattedPeriod = getFormattedDateStringFromPeriod(selectedPeriod);
  const employerWageType = employerWageTypes.find((w) => w.id === selectedWageType.employerWageTypeId);

  return (
    <DrawerBase
      title={stringOrBlank(employerWageType?.name)}
      subTitle={formattedPeriod}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="absence-wage-type-details-drawer">
        {isDraft && <EmployeeInDraftBanner />}
        <Typography variant="subtitle" bold>
          {t('wages:employer-absence-wage-types')}
        </Typography>
        <ListGroup variant="drawer-navigation">
          {selectedWageType?.wages.map((wage) => {
            return (
              <DrawerNavigation
                key={wage.id}
                onClick={() => onSelectWage(wage.id)}
                label={
                  <div>
                    <DraftChangedTooltip
                      label={t('employees:employee-draft-absence-wage-type-changed')}
                      hasChangesInDraft={wage.isDraftModified}
                    >
                      <Typography bold variant={'body2'}>
                        {formatPayType(wage.payTypeNumber, wage.payTypeName, t)}
                      </Typography>
                    </DraftChangedTooltip>
                  </div>
                }
                value={
                  wage.extentAdjustedAbsenceDays
                    ? `${wage.extentAdjustedAbsenceDays} ${
                        wage.extentAdjustedAbsenceDays === 1
                          ? t('wages:extent-adjusted-absence-days-singular')
                          : t('wages:extent-adjusted-absence-days-plural')
                      }`
                    : '-'
                }
              />
            );
          })}
        </ListGroup>
        <ListGroup variant={'inline-edit'}>
          <InlineEdit bold label={t('wages:total')} className="absence-wage-type-details-drawer__total">
            <Typography variant="body2">{`${selectedWageType.extentAdjustedAbsenceDays} ${
              selectedWageType.extentAdjustedAbsenceDays === 1
                ? t('wages:extent-adjusted-absence-days-singular')
                : t('wages:extent-adjusted-absence-days-plural')
            }`}</Typography>
          </InlineEdit>
        </ListGroup>
      </div>
    </DrawerBase>
  );
};

export default AbsenceWageTypeDetailsDrawer;
