export const saveToLocalStorage = (key: string, value: unknown) => {
  try {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    }
    const jsonString = JSON.stringify(value);
    localStorage.setItem(key, jsonString);
  } catch (e) {
    console.error(e);
  }
};

export const getFromLocalStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    console.error(e);
  }
};
