import { gql } from '@apollo/client';

export const GET_GLOBAL_INDIVIDUAL_CHOICES = gql`
  query GetEmployerIndividualChoices {
    globalIndividualChoices {
      id
      type
      canBeRevoked
    }
  }
`;
