import { InlineEdit, Switch } from 'fgirot-k2-ui-components';
import React from 'react';
import { UpdateEmployerReportingFlowSettingsRequest } from 'src/graphql/schema/mutation/employer/UpdateEmployerReportingFlowSettingsRequest';
import { useUpdateEmployerReportingFlowSettings } from 'src/service/employer/UpdateEmployerReportingFlowSettings';
import { Employer } from 'src/types/employer/Employer';
import { EmployerReportingFlowSetting } from 'src/types/employer/EmployerReportingFlowSetting';

interface AutomaticReportingToggleProps {
  employer: Employer;
  reportingFlowSettings: EmployerReportingFlowSetting;
}

function AutomaticReportingToggle({ employer, reportingFlowSettings }: AutomaticReportingToggleProps) {
  const updateEmployerReportingFlowSettings = useUpdateEmployerReportingFlowSettings();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const request: UpdateEmployerReportingFlowSettingsRequest = {
      id: reportingFlowSettings.id,
      employerId: employer.id,
      disableAutomaticReporting: e.target.checked,
    };

    handleUpdate(request);
  };

  const handleUpdate = async (request: UpdateEmployerReportingFlowSettingsRequest) => {
    await updateEmployerReportingFlowSettings(request);
  };

  return (
    <InlineEdit label={employer.legalName} bold>
      <Switch toggled={reportingFlowSettings.disableAutomaticReporting} onChange={handleOnChange} warning />
    </InlineEdit>
  );
}

export default AutomaticReportingToggle;
