import { Button, InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { useCreateDirectDocumentAccounting } from 'src/service/document/CreateDirectDocumentAccounting';
import { useCreateDirectDocumentExcel } from 'src/service/document/CreateDirectDocumentExcel';
import { useCreateDirectDocumentPdf } from 'src/service/document/CreateDirectDocumentPdf';
import { CreateDirectDocumentInput } from 'src/types/documents/CreateDirectDocumentInput';
import { CreateExcelDirectDocumentInput } from 'src/types/documents/CreateExcelDirectDocumentInput';
import { DocumentTemplate, InputPeriodParameter, InputPeriodParameterEnum } from 'src/types/documents/DocumentTemplate';
import { Employer } from 'src/types/employer/Employer';
import { Month } from 'src/types/Month';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { getInputParametersOptions } from '../../utils/DocumentTemplateUtils';
import SingleYearMonth from './SingleYearMonth';
import FromToYearMonth from './FromToYearMonth';
import './generate-document-drawer-tab.scss';
import { Customer } from 'src/types/customer/Customer';

interface GenerateDocumentDrawerTabProps {
  documentTemplate: DocumentTemplate;
  employers: Employer[];
  customer: Customer;
  onClose: () => void;
}

const GenerateDocumentDrawerTab: FC<GenerateDocumentDrawerTabProps> = ({
  documentTemplate,
  employers,
  customer,
  onClose,
}) => {
  const { t } = useTranslation();

  const [selectedEmployerId, setSelectedEmployerId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [selectedInputParameter, setSelectedInputParameter] = useState<Option<string>>();

  const [selectedMonth, setSelectedMonth] = useState<Option<Month>>();
  const [selectedYear, setSelectedYear] = useState<Option<number>>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');

  const [selectedFromMonth, setSelectedFromMonth] = useState<Option<Month>>();
  const [selectedFromYear, setSelectedFromYear] = useState<Option<number>>();
  const [selectedToMonth, setSelectedToMonth] = useState<Option<Month>>();
  const [selectedToYear, setSelectedToYear] = useState<Option<number>>();
  const [selectedFromPeriod, setSelectedFromPeriod] = useState<string>('');
  const [selectedToPeriod, setSelectedToPeriod] = useState<string>('');

  const createDocumentDocumentPdf = useCreateDirectDocumentPdf();
  const createDirectDocumentExcel = useCreateDirectDocumentExcel();
  const createDirectDocumentAccounting = useCreateDirectDocumentAccounting();

  const employerOptions: Option<string>[] = createOptionsFromList(documentTemplate?.employers, 'id', 'legalName');
  const inputParameterOptions = getInputParametersOptions(documentTemplate?.inputParameters);

  const handleSelectInputParameter = (option: Option<string>) => {
    setSelectedInputParameter(
      inputParameterOptions?.find((inputParameterOption) => inputParameterOption?.value === option?.value),
    );
  };

  const getButtonDisabled = (inputPeriodParameter: InputPeriodParameter): boolean => {
    let buttonDisabled = false;
    if (inputPeriodParameter === 'PERIOD_SINGLE_YEAR_MONTH') {
      buttonDisabled = !selectedEmployerId || !selectedPeriod || selectedPeriod.includes(null);
    }
    if (inputPeriodParameter === 'PERIOD_FROMTO_YEAR_MONTH') {
      buttonDisabled =
        !selectedEmployerId ||
        ((!selectedPeriod || selectedPeriod.includes(null) || !selectedFromPeriod) &&
          (selectedFromPeriod.includes(null) || !selectedToPeriod || selectedToPeriod.includes(null))) ||
        !selectedInputParameter;
    }
    return buttonDisabled;
  };

  const handleEmployerChange = (selectedEmployerOption: Option<string>) => {
    setSelectedEmployerId(selectedEmployerOption?.value);

    const employer = employers?.find((e) => e.id === selectedEmployerOption?.value);
    setSelectedEmployerId(employer.id);
  };

  const handleGenerateDocument = () => {
    setLoading(true);
    const createDirectDocumentRequest: CreateDirectDocumentInput = {
      documentTemplateId: documentTemplate.id,
      employerId: selectedEmployerId,
      fromPeriod: selectedPeriod,
      toPeriod: selectedPeriod,
    };

    if (documentTemplate.formatType === 'PDF') {
      createDocumentDocumentPdf(selectedEmployerId, createDirectDocumentRequest).finally(() => {
        setLoading(false);
        onClose();
      });
    }

    if (documentTemplate.formatType === 'ACCOUNTING') {
      createDirectDocumentAccounting(selectedEmployerId, createDirectDocumentRequest).finally(() => {
        setLoading(false);
        onClose();
      });
    }

    if (documentTemplate.formatType === 'EXCEL') {
      const createExcelDirectDocumentRequest: CreateExcelDirectDocumentInput = {
        documentTemplateId: documentTemplate.id,
        employerId: selectedEmployerId,
        customerId: customer.id,
        fromPeriod: selectedFromPeriod,
        toPeriod: selectedToPeriod,
        inputParameter: selectedInputParameter.value,
      };
      createDirectDocumentExcel(selectedEmployerId, createExcelDirectDocumentRequest).finally(() => {
        setLoading(false);
        onClose();
      });
    }
  };

  return (
    <div className="generate-document-drawer-tab">
      <ListGroup variant="inline-edit">
        <InlineEdit
          label={t('documents:document-templates-tab.drawer.generate-document-drawer-tab.employer-label')}
          mandatory
          bold
        >
          <Select
            placeholder={t(
              'documents:document-templates-tab.drawer.generate-document-drawer-tab.select-employer-placeholder',
            )}
            options={employerOptions}
            selected={employerOptions?.find((option) => option.value === selectedEmployerId)}
            onChange={handleEmployerChange}
            small
            data-testid="dropdown-select-employer"
          />
        </InlineEdit>
      </ListGroup>

      <When
        condition={
          documentTemplate?.inputPeriodParameter === InputPeriodParameterEnum.PERIOD_SINGLE_YEAR_MONTH &&
          selectedEmployerId !== undefined
        }
      >
        <SingleYearMonth
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          setSelectedPeriod={setSelectedPeriod}
        />
      </When>

      <When
        condition={
          documentTemplate?.inputPeriodParameter === InputPeriodParameterEnum.PERIOD_FROMTO_YEAR_MONTH &&
          selectedEmployerId !== undefined
        }
      >
        <FromToYearMonth
          selectedFromYear={selectedFromYear}
          setSelectedFromYear={setSelectedFromYear}
          selectedFromMonth={selectedFromMonth}
          setSelectedFromMonth={setSelectedFromMonth}
          selectedToYear={selectedToYear}
          setSelectedToYear={setSelectedToYear}
          selectedToMonth={selectedToMonth}
          setSelectedToMonth={setSelectedToMonth}
          setSelectedFromPeriod={setSelectedFromPeriod}
          setSelectedToPeriod={setSelectedToPeriod}
        />
      </When>

      <When condition={documentTemplate.formatType === 'EXCEL' && documentTemplate.inputParameters.length > 0}>
        <ListGroup variant="inline-edit">
          <InlineEdit
            label={t('documents:document-templates-tab.drawer.generate-document-drawer-tab.period-selection-label')}
            mandatory
            bold
          >
            <Select
              placeholder={t(
                'documents:document-templates-tab.drawer.generate-document-drawer-tab.period-selection-placeholder',
              )}
              options={inputParameterOptions}
              selected={inputParameterOptions?.find((option) => option?.value === selectedInputParameter?.value)}
              onChange={handleSelectInputParameter}
              small
              data-testid="dropdown-select-input-parameter"
            />
          </InlineEdit>
        </ListGroup>
      </When>

      <Button
        onClick={handleGenerateDocument}
        size="large"
        label={t('documents:document-templates-tab.drawer.generate-document-drawer-tab.generate-button')}
        className="document-template-drawer__generate-btn"
        data-testid="generate-button"
        disabled={getButtonDisabled(documentTemplate.inputPeriodParameter)}
        loading={loading}
      />
    </div>
  );
};

export default GenerateDocumentDrawerTab;
