import { Checkpoint } from 'src/types/Checkpoint';
import { CheckpointStatus } from 'src/types/DataValidationCheckpoint';

export const isCheckpointStatusesMatching = (checkpoints: Checkpoint[], status: CheckpointStatus) => {
  return checkpoints.map((checkpoint) => isCheckpointStatusMatching(checkpoint, status)).every((x) => x);
};

export const isCheckpointStatusesMatchingEither = (checkpoints: Checkpoint[], status: CheckpointStatus[]) =>
  checkpoints.every((x) => isCheckpointStatusMatchingEither(x, status));
export const isCheckpointStatusMatching = (checkpoint: Checkpoint, status: CheckpointStatus) => {
  return checkpoint.checkpointStatus === status;
};

export const isCheckpointStatusMatchingEither = (checkpoint: Checkpoint, status: CheckpointStatus[]) => {
  return status.includes(checkpoint.checkpointStatus);
};

export const isAllCheckpointsResolved = (checkpoints: Checkpoint[]) => {
  return checkpoints.every((checkpoint) => {
    return checkpoint.checkpointStatus === 'RESOLVED';
  });
};

export const isAllCheckpointsAccepted = (checkpoints: Checkpoint[]) => {
  return checkpoints.every((checkpoint) => {
    return checkpoint.checkpointStatus === 'ACCEPTED';
  });
};

export const hasRaisedCheckpoints = (checkpoints: Checkpoint[]) => {
  return hasMatchingCheckpointStatus(checkpoints, 'RAISED');
};

const hasResolvedCheckpoints = (checkpoints: Checkpoint[]) => {
  return hasMatchingCheckpointStatus(checkpoints, 'RESOLVED');
};

export const hasDeclinedCheckpoints = (checkpoints: Checkpoint[]) => {
  return hasMatchingCheckpointStatus(checkpoints, 'DECLINED');
};

const hasMatchingCheckpointStatus = (checkpoints: Checkpoint[], status: CheckpointStatus) => {
  return checkpoints.some((checkpoint) => checkpoint.checkpointStatus === status);
};

export const getCheckpointPillType = (checkpoints: Checkpoint[]): 'neutral' | 'success' | 'heads-up' | 'warning' => {
  if (isAllCheckpointsResolved(checkpoints)) {
    return 'success';
  } else if (hasRaisedCheckpoints(checkpoints) && hasResolvedCheckpoints(checkpoints)) {
    return 'heads-up';
  } else if (hasRaisedCheckpoints(checkpoints) && !hasResolvedCheckpoints(checkpoints)) {
    return 'warning';
  }
  return 'neutral';
};

export const getBannerType = (checkpoints: Checkpoint[]): 'announcement' | 'success' | 'warning' => {
  if (isAllCheckpointsResolved(checkpoints)) {
    return 'success';
  } else if (hasRaisedCheckpoints(checkpoints) && hasResolvedCheckpoints(checkpoints)) {
    return 'announcement';
  } else {
    return 'warning';
  }
};

export const examinationPillOutcome = (checkpoints: Checkpoint[]) => {
  const statuses = checkpoints.map((cp) => cp.checkpointStatus);
  const hasRaised = statuses.find((status) => status === 'RAISED');
  const hasAccepted = statuses.find((status) => status === 'ACCEPTED');
  return hasRaised && hasAccepted ? 'heads-up' : hasRaised ? 'warning' : 'success';
};

export const isEmploymentEventCheckpointAbleToAcceptWithoutChanges = (subActionCategory: string) => {
  switch (subActionCategory) {
    case 'WAGE_TYPES_POSITIVE_SUM_NEGATIVE_REQUIRED':
    case 'INDIVIDUAL_CHOICE_ITP1_FROM_PERIOD_REQUIRED':
    case 'HANDLE_NEGATIVE_KUB':
    case 'REEMPLOYMENT':
      return false;
    default:
      return true;
  }
};
