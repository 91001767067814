import { useQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { client } from 'src/graphql';
import { GET_EMPLOYER_PAY_TYPES } from 'src/graphql/schema/query/employer/GetEmployerPayTypes';
import { EMPLOYER_PAY_TYPE_UPDATED } from 'src/graphql/schema/subscription/EmployerPayTypeUpdated';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';

export const useEmployerPayTypes = (employerId: string): EmployerPayType[] => {
  const [result, setResult] = useState<EmployerPayType[]>([]);
  const { data } = useQuery(GET_EMPLOYER_PAY_TYPES, {
    variables: {
      employerId,
    },
    skip: !employerId,
    fetchPolicy: 'no-cache',
    context: { employerId },
  });

  const { data: subData } = useSubscription(EMPLOYER_PAY_TYPE_UPDATED, {
    variables: {
      employerId,
    },
    context: { employerId },
  });

  useEffect(() => {
    // TODO: handle error
    data && setResult(data.employerPayTypes);
  }, [data]);

  useEffect(() => {
    data && subData && setResult((prev) => handleNewEmployerPayType(prev, subData.employerWageTypeMappingUpdated));
  }, [subData]);

  return result;
};

const handleNewEmployerPayType = (
  employerPayTypes: EmployerPayType[],
  newEmployerPayType: EmployerPayType,
): EmployerPayType[] => {
  return employerPayTypes?.some((employerPayType) => employerPayType?.id === newEmployerPayType?.id)
    ? employerPayTypes?.map((employerPayType) =>
        employerPayType?.id === newEmployerPayType?.id ? newEmployerPayType : employerPayType,
      )
    : [...employerPayTypes, newEmployerPayType];
};

export const getEmployerPayTypes = async (employerId: string): Promise<EmployerPayType[]> => {
  const {
    data: { employerPayTypes },
  } = await client.query({
    query: GET_EMPLOYER_PAY_TYPES,
    variables: {
      employerId,
    },
    context: { employerId },
  });
  return employerPayTypes;
};
