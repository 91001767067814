import { CREATE_DRAFT_POLICY } from 'src/graphql/schema/mutation/policy/CreateDraftPolicy';
import { GET_POLICY_ENTITIES } from 'src/graphql/schema/query/policy/GetPolicyEntities';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateDraftPolicy = () => {
  const apolloMutation = useApolloMutation();

  const createDraftPolicy = (employerId: string) =>
    apolloMutation(CREATE_DRAFT_POLICY, employerId, { employerId }, [GET_POLICY_ENTITIES]);

  return createDraftPolicy;
};
