import React, { FC, useMemo, useState } from 'react';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { InvoiceRow } from 'src/types/invoice/InvoiceRow';
import { SearchInvoiceRowRequest } from 'src/types/invoice/SearchInvoiceRowsRequest';
import InvoiceRowsTable from './InvoiceRowTable/InvoiceRowsTable';
import InvoiceRowsSearchInput from './InvoiceRowsSearchInput';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import { exportInvoiceRows } from 'src/service/invoice/ExportInvoiceRows';
import { Button, Loader, Pagination } from 'fgirot-k2-ui-components';
import { useParams } from 'react-router-dom';
import When from 'src/components/Common/When';

interface InvoiceRowsProps {
  invoiceRows: InvoiceRow[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  searchInvoiceRowRequest: SearchInvoiceRowRequest;
  onTextSearch: (searchInput: string) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const InvoiceRows: FC<InvoiceRowsProps> = ({
  invoiceRows,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  searchInvoiceRowRequest,
  onTextSearch,

  onPageChange,
}) => {
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);
  const showPagination = totalPages > 0;
  const { invoiceId } = useParams();
  const handleDownloadInvoiceClick = () => {
    setFileDownloadLoading(true);
    exportInvoiceRows({ invoiceId, searchInput: searchInvoiceRowRequest.searchInput }).finally(() =>
      setFileDownloadLoading(false),
    );
  };

  return (
    <>
      <div className="invoice-rows-overview">
        <InvoiceRowsSearchInput onSearch={onTextSearch} searchRequest={searchInvoiceRowRequest} />
      </div>
      <div className="accounting-items-tab__export-container">
        <Button
          className="accounting-items-tab__export-container__button"
          label={
            t('economy:accounting-items-tab.download-button') +
            (paging?.totalNumberOfElements ? ` (${paging?.totalNumberOfElements})` : ' (0)')
          }
          onClick={handleDownloadInvoiceClick}
        />
        <When condition={fileDownloadLoading}>
          <Loader type="blue" size="medium"></Loader>
        </When>
      </div>
      <InvoiceRowsTable invoiceRows={invoiceRows} />
      {showPagination && (
        <div data-testid="invoice-rows-overview-tab-pagination" className="invoice-rows-overview__pagination">
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            pageSizeLabel={t('common:results-per-page')}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={setPageSize}
            pageSizePosition="left"
          />
        </div>
      )}
    </>
  );
};

export default InvoiceRows;
