import { UPDATE_EMPLOYEE_CARD_WAGE } from 'src/graphql/schema/mutation/employeecard/UpdateEmployeeCardWage';
import { UpdateEmployeeCardWage } from 'src/types/UpdateEmployeeCardWage';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_CARD_WITH_WAGES } from 'src/graphql/schema/query/employee/GetEmployeeCard';
import { GET_EMPLOYMENT_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetEmploymentEventCheckpoints';

export const useUpdateEmployeeCardWageKubCorrection = () => {
  const apolloMutation = useApolloMutation();

  return (request: UpdateEmployeeCardWage, employerId: string) =>
    apolloMutation(UPDATE_EMPLOYEE_CARD_WAGE, employerId, { request }, [
      GET_EMPLOYEE_CARD_WITH_WAGES,
      GET_EMPLOYMENT_EVENT_CHECKPOINTS,
    ]);
};
