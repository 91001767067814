import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { Button, Icon, InlineEdit, ListGroup, Option, Pill, Select, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { BenefitCalculationType } from '../PolicyBenefitOverview/PolicyBenefitCalculationDrawerTab/PolicyBenefitCalculationDrawerTab';
import { useState } from 'react';
import { useUpdatePolicyBenefitCalculationRule } from 'src/service/policy/UpdatePolicyBenefitCalculationRule';
import DrawerBase from 'src/components/Common/DrawerBase';
import PglBlock from './PglBlock';
import KubBlock from './KubBlock';
import { useUpdatePolicyBenefitKub } from 'src/service/policy/UpdatePolicyBenefitKub';
import { PolicyBenefitDrawerTab } from 'src/types/tabs/PolicyBenefitDrawerTab';

interface BenefitCalculationDetailDrawerProps {
  policyId: string;
  policyEntityState: PolicyState;
  policyBenefit: PolicyBenefit;
  calculationType: BenefitCalculationType;
  onClose: () => void;
  navigateToTab: (tab: PolicyBenefitDrawerTab) => void;
  employerId: string;
}

const BenefitConnectionRuleDetailDrawer: React.FC<BenefitCalculationDetailDrawerProps> = ({
  policyId,
  policyEntityState,
  calculationType,
  policyBenefit,
  onClose,
  navigateToTab,
  employerId,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<BenefitCalculationType>(calculationType);
  const updatePolicyBenefitCalculationRule = useUpdatePolicyBenefitCalculationRule();
  const updatePolicyBenefitKub = useUpdatePolicyBenefitKub();

  const onBackClick = () => {
    navigateToTab(PolicyBenefitDrawerTab.CALCULATION);
  };

  const options: Option<BenefitCalculationType>[] = [
    { value: 'UNUSED', label: t('common:not-in-use') },
    { value: 'PGL', label: t('account:employers-tab.policy-settings.table.drawer.pgl-model') },
    { value: 'KUB', label: t('account:employers-tab.policy-settings.table.drawer.kub-model') },
  ];

  const removePglAndSpglCalculations = () => {
    updatePolicyBenefitCalculationRule(
      {
        policyId,
        benefitPackageId: policyBenefit.benefitPackageId,
        pglRuleId: undefined,
        spglRuleId: undefined,
      },
      employerId,
    );
  };

  const removeKub = () => {
    updatePolicyBenefitKub(policyId, policyBenefit.benefitPackageId, undefined, employerId);
  };

  const handleSelect = (input: Option<BenefitCalculationType>) => {
    setSelected(input.value);
    if (input.value === 'UNUSED') {
      removePglAndSpglCalculations();
      removeKub();
    }
    if (input.value === 'PGL') {
      removeKub();
    }
    if (input.value === 'KUB') {
      removePglAndSpglCalculations();
    }
  };

  return (
    <DrawerBase
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      title={t('account:employers-tab.policy-settings.table.drawer.calculation')}
      subTitle={
        <div className={'sub-title-pill'}>
          <Pill label={t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)} />
          <Typography variant="body2">{policyBenefit.name}</Typography>
        </div>
      }
      onClose={onClose}
    >
      <ListGroup variant="inline-edit">
        <InlineEdit bold label={t('account:employers-tab.policy-settings.table.drawer.calculation-type')}>
          <Select
            placeholder={t('common:select')}
            disabled={policyEntityState !== 'DRAFT'}
            options={options}
            selected={options.find((opt) => opt.value === selected)}
            onChange={handleSelect}
            data-cy={'policy-benefit-calculation__type_dropdown'}
            small
          />
        </InlineEdit>
      </ListGroup>

      {selected === 'PGL' && (
        <PglBlock
          pglRuleReferences={policyBenefit.pglRuleReferences}
          policyEntityState={policyEntityState}
          policyId={policyId}
          policyBenefit={policyBenefit}
          employerId={employerId}
        />
      )}

      {selected === 'KUB' && (
        <KubBlock
          policyId={policyId}
          policyEntityState={policyEntityState}
          policyBenefit={policyBenefit}
          employerId={employerId}
        />
      )}
    </DrawerBase>
  );
};

export default BenefitConnectionRuleDetailDrawer;
