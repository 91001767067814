import { useApolloMutation } from '../graphql/Mutation';
import { CREATE_DRAFT_EMPLOYEE } from 'src/graphql/schema/mutation/employee/CreateDraftEmployee';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';

export const useCreateDraftEmployee = () => {
  const apolloMutation = useApolloMutation();

  const createDraftEmployee = (employeeId: string, employerId: string) =>
    apolloMutation(
      CREATE_DRAFT_EMPLOYEE,
      employerId,
      {
        employeeId,
      },
      [GET_EMPLOYEE, GET_EMPLOYEE_INDIVIDUAL_CHOICES],
    );

  return createDraftEmployee;
};
