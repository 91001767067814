import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Icon, Pill, Typography } from 'fgirot-k2-ui-components';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import useUpdateOrDeleteAffiliationRule from '../../AffiliationRuleGroupDetails/UpdatePolicyAffiliationRuleFunctions';
import AffiliationRuleGroupDetails from '../../AffiliationRuleGroupDetails';
import '../policy-benefit-drawer.scss';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';
import { useParams } from 'react-router-dom';
import { PolicyBenefitDrawerTab } from 'src/types/tabs/PolicyBenefitDrawerTab';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';

interface BenefitAffiliationRuleDetailDrawerProps {
  policyId: string;
  policyEntityState: PolicyState;
  selectedRuleGroupType: AffiliationRuleGroupType;
  ruleBlockId: string;
  policyBenefit: PolicyBenefit;
  onClose: () => void;
  navigateToTab: (tab: PolicyBenefitDrawerTab) => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}

const BenefitAffiliationRuleDetailDrawer: React.FunctionComponent<BenefitAffiliationRuleDetailDrawerProps> = ({
  policyId,
  policyEntityState,
  selectedRuleGroupType,
  ruleBlockId,
  policyBenefit,
  onClose,
  navigateToTab,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const updateOrDeleteAffiliationRule = useUpdateOrDeleteAffiliationRule();

  const onBackClick = () => {
    navigateToTab(PolicyBenefitDrawerTab.AFFILIATION_RULES);
  };

  const handleUpdateOrDeleteAffiliationRule = (request: UpdateOrDeleteAffiliationRuleRequest) => {
    return updateOrDeleteAffiliationRule(
      'BENEFIT',
      policyBenefit.benefitPackageId,
      employerId,
      ruleBlockId,
      request.ruleId,
      request.policyRuleType,
      request.ruleValues,
      request.ruleValueUnit,
      request.sourceType,
      request.sourceReference,
    );
  };

  const selectedRuleGroup = policyBenefit.affiliationRuleBlocks
    ?.find((ruleblock) => ruleblock.id === ruleBlockId)
    .groupedAffiliationRules?.find((group) => group.affiliationRuleGroupType === selectedRuleGroupType) || {
    affiliationRuleGroupType: selectedRuleGroupType,
    affiliationRules: [],
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="drawer-base__title-container">
        <Typography variant="h3" component="h3" bold>
          {t(`policyRules:ruleTypeGroup.${selectedRuleGroupType}.name`)}
        </Typography>
        <div className={'sub-title-pill'}>
          <Pill label={t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)} />
          <Typography variant="body2">{policyBenefit.name}</Typography>
        </div>
      </div>
      <Typography variant="body2" component="p">
        {t(`policyRules:ruleTypeGroup.${selectedRuleGroupType}.info-text`)}
      </Typography>
      <AffiliationRuleGroupDetails
        employerId={employerId}
        selectedRuleGroupType={selectedRuleGroupType}
        policyEntityState={policyEntityState}
        selectedRuleGroup={selectedRuleGroup}
        onUpdateOrDeleteAffiliationRule={handleUpdateOrDeleteAffiliationRule}
        policyId={policyId}
        employerIndividualChoices={employerIndividualChoices}
      />
    </Drawer>
  );
};

export default BenefitAffiliationRuleDetailDrawer;
