import { CreateEmployeeIndividualSettingsRequest } from 'src/types/employees/request/CreateEmployeeIndividualSettingsRequest';
import { useApolloMutation } from '../graphql/Mutation';
import { CREATE_EMPLOYEE_INDIVIDUAL_SETTINGS } from 'src/graphql/schema/mutation/employee/CreateEmployeeIndividualSettings';
import { GET_INSURANCE_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetInsuranceEventCheckpoints';

export const useCreateEmployeeIndividualSettings = () => {
  const apolloMutation = useApolloMutation();

  const createEmployeeIndividualSettings = (
    employeeId: string,
    request: CreateEmployeeIndividualSettingsRequest,
    employerId: string,
  ) =>
    apolloMutation(
      CREATE_EMPLOYEE_INDIVIDUAL_SETTINGS,
      employerId,
      {
        employeeId,
        request,
      },
      [GET_INSURANCE_EVENT_CHECKPOINTS],
    );

  return createEmployeeIndividualSettings;
};
