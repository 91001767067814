import { gql } from '@apollo/client';

export const GET_EMPLOYEE_INSURANCES = gql`
  query GetEmployeeInsurances(
    $employeeId: UUID!
    $includeInsurancesMissingAgreedProduct: Boolean
    $request: EmployeeInsurancesRequest
  ) {
    employeeInsurances(
      employeeId: $employeeId
      includeInsurancesMissingAgreedProduct: $includeInsurancesMissingAgreedProduct
      request: $request
    ) {
      paging {
        totalNumberOfElements
        hasNext
      }
      employeeInsurances {
        id
        employeeId
        insurance {
          id
          name
          insuranceNumber
          insuranceProvider {
            id
            legalName
          }
          agreedProduct {
            id
            name
            certifyInsuranceProductCondition
            insuranceProduct {
              id
              name
            }
          }
          agreementNumber
          benefitType
          insuranceTypeMappings {
            id
            name
          }
          insuranceStatus {
            status
            statusCategory
            latestStatusChange
          }
          startDate
          exitDate
          replacedByInsuranceId
          replacesInsuranceId
        }
      }
    }
  }
`;
