import { Checkbox } from 'fgirot-k2-ui-components';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { updateCheckpointToFinalizeList } from 'src/components/Report/Common/utils';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';

interface CheckpointListItemCheckboxProps {
  eventReport: InsuranceEventReportEmployeeResponse;
}
const CheckpointListItemCheckbox: React.FC<CheckpointListItemCheckboxProps> = ({ eventReport }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const [disabled, setDisabled] = useState(
    eventReport.checkpoints.find((s) => s.checkpointStatus != 'RESOLVED') != null,
  );

  useEffect(() => {
    const isResolved = eventReport.checkpoints.find((s) => s.checkpointStatus != 'RESOLVED') == null;
    setDisabled(!isResolved);
    setChecked(isResolved);
    updateCheckpointToFinalizeList(eventReport.employee, isResolved ? 'ADD' : 'REMOVE');
  }, [eventReport]);

  const handleChecked = (checked: boolean) => {
    setChecked(checked);
    updateCheckpointToFinalizeList(eventReport.employee, checked ? 'ADD' : 'REMOVE');
  };

  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      onChange={(e) => handleChecked(e.target.checked)}
      label={t('verify:done')}
    />
  );
};
export default CheckpointListItemCheckbox;
