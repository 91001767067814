import { useApolloMutation } from 'src/service/graphql/Mutation';
import { CREATE_GROUP } from 'src/graphql/schema/mutation/group/CreateGroup';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { CreateGroupInput } from 'src/types/group/Group';

export const useCreateGroup = () => {
  const apolloMutation = useApolloMutation();

  const createGroup = (request: CreateGroupInput) => apolloMutation(CREATE_GROUP, '', { request }, [GET_GROUPS]);
  return createGroup;
};
