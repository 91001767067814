import { Tab, Tabs } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DocumentArchiveTab from 'src/components/Documents/DocumentArchiveTab/DocumentArchiveTab';
import DocumentTemplateTab from 'src/components/Documents/DocumentTemplatesTab/DocumentTemplatesTab';
import PageBase from 'src/components/PageBase';
import useTabParams from 'src/components/hooks/useTabParams';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { DocumentsTab } from 'src/types/tabs/DocumentsTab';

const Documents = () => {
  const { t } = useTranslation();
  const customer = useCustomer();

  const [currentTab, setCurrentTab] = useTabParams('tab', DocumentsTab.ARCHIVE);

  return (
    <PageBase header title={t('documents:title')} breadCrumbs={[{ name: customer?.name, steps: 1 }]}>
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('documents:tabs.document-archive')}>
          <DocumentArchiveTab />
        </Tab>
        <Tab title={t('documents:tabs.document-templates')}>
          <DocumentTemplateTab customer={customer} />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default Documents;
