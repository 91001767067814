import { Td, Option } from 'fgirot-k2-ui-components';
import React from 'react';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import { employmentTypes } from 'src/types/EmploymentTypes';
import { createOptions } from 'src/util/OptionUtil';
import { useTranslation } from 'react-i18next';
import { employmentCategories } from 'src/types/EmploymentCategory';
import { remunerationTypes } from 'src/types/RemunerationType';
import UpdateInput from 'src/components/Common/UpdateInput';
import UpdateSelect from 'src/components/Report/Common/UpdateField/UpdateSelect';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';

interface EmployeeRowFieldProps {
  fieldKey?: string;
  onBlur: (field: string, value: string) => void;
  accountingUnits: AccountingUnit[];
  fieldValue: string;
}

const EmployeeRowField = ({ fieldKey, onBlur, accountingUnits, fieldValue }: EmployeeRowFieldProps) => {
  const { t } = useTranslation();

  switch (fieldKey) {
    case 'firstName':
    case 'lastName':
    case 'employmentNumber':
      return (
        <UpdateInput
          fieldKey={fieldKey}
          fieldValue={fieldValue}
          onBlur={onBlur}
          validationKey="text64"
          maxLength={65}
          placeholder={t(`violationTypes:${fieldKey}`)}
        />
      );
    case 'personNumber':
      return (
        <UpdateInput
          fieldKey={fieldKey}
          fieldValue={fieldValue}
          onBlur={onBlur}
          maxLength={13}
          validationKey="personNumber"
        />
      );
    case 'employmentType':
      return (
        <UpdateSelect
          fieldKey={fieldKey}
          onClick={onBlur}
          fieldValue={fieldValue}
          options={createOptions(employmentTypes, t, 'employmentTypes')}
        />
      );
    case 'accountingUnitId':
      return (
        <UpdateSelect
          fieldKey={fieldKey}
          onClick={onBlur}
          fieldValue={fieldValue}
          options={accountingUnits.map((accountingUnit): Option<string> => {
            return { label: accountingUnit.name, value: accountingUnit.id };
          })}
        />
      );
    case 'employmentStartDate':
      return <UpdateDatePicker fieldKey={fieldKey} onBlur={onBlur} fieldValue={fieldValue} />;
    case 'employmentCategory':
      return (
        <UpdateSelect
          fieldKey={fieldKey}
          onClick={onBlur}
          fieldValue={fieldValue}
          options={createOptions(employmentCategories, t, 'employmentCategories')}
        />
      );
    case 'remunerationType':
      return (
        <UpdateSelect
          fieldKey={fieldKey}
          onClick={onBlur}
          fieldValue={fieldValue}
          options={createOptions(remunerationTypes, t, 'remunerationTypes')}
        />
      );
    default:
      return <Td>här ska ett fält in beroende på {fieldKey}</Td>;
  }
};

export default EmployeeRowField;
