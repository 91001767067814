import { useApolloMutation } from '../graphql/Mutation';
import { UpsertReEmploymentCheckpointStateRequest } from '../../types/reporting/ReEmploymentCheckpointState';
import { UPSERT_REEMPLOYMENT_CHECKPOINT_STATE } from '../../graphql/schema/mutation/reporting/UpsertReEmploymentCheckpointState';
import { GET_REEMPLOYMENT_CHECKPOINT_STATE } from 'src/graphql/schema/query/reporting/GetReEmploymentCheckpointState';

export const useUpsertReEmploymentCheckpointState = () => {
  const apolloMutation = useApolloMutation();

  return (request: UpsertReEmploymentCheckpointStateRequest, employerId: string) =>
    apolloMutation(
      UPSERT_REEMPLOYMENT_CHECKPOINT_STATE,
      employerId,
      {
        request,
      },
      [GET_REEMPLOYMENT_CHECKPOINT_STATE],
    );
};
