import { InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { calculationTimeFrames } from 'src/types/CalculationTimeFrameMappings';
import { CalculationTimeFrame, PglWageType } from 'src/types/policy/PglWageType';
import { createOptions } from 'src/util/OptionUtil';
import CalculationButtons from '../CalculationButtons';
import { PglWageTypeRequest } from 'src/types/policy/request/PglWageTypeRequest';
import { useUpsertPglRuleWageType } from 'src/service/policy/UpsertPglRuleWageType';
import { useDeletePglRuleWageType } from 'src/service/policy/DeletePglRuleWageType';
import { useWageTypeCalculationOptions } from './useWageTypeCalculationOptions';
import TextInput from 'src/components/Common/TextInput';
import { Month } from 'src/types/Month';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { PglType } from 'src/types/policy/PglType';
import {
  isValidWageTypeTimeFrameCombination,
  canEditCalculationTimeFrame,
  shouldWageTypeHaveDenomination,
} from 'src/util/PolicyUtil';
import When from 'src/components/Common/When';

interface WageTypeCalculationProps {
  employerId: string;
  pglWageType?: PglWageType;
  selectedWageType: Option<EmployerWageType>;
  inDraft: boolean;
  policyId: string;
  pglRuleId: string;
  onComplete: () => void;
  pglType: PglType;
}

const WageTypeCalculation = ({
  employerId,
  pglWageType,
  selectedWageType,
  inDraft,
  policyId,
  pglRuleId,
  onComplete,
  pglType,
}: WageTypeCalculationProps) => {
  const { t } = useTranslation(['common, account, wageTypes']);
  const deletePglRuleWageType = useDeletePglRuleWageType();
  const upsertPglRuleWageType = useUpsertPglRuleWageType();
  const [loading, setLoading] = useState<boolean>(false);

  const [pglWageTypeRequest, setPglWageTypeRequest] = useState<PglWageTypeRequest>({
    policyId: policyId,
    pglRuleId: pglRuleId,
    id: pglWageType?.id || null,
    wageType: selectedWageType?.value.wageType || null,
    employerWageTypeId: selectedWageType?.value.id,
    calculationTimeFrame: pglWageType?.calculationTimeFrame,
    conversionFactor: pglWageType?.conversionFactor || 12.2,
    conversionDenominator: pglWageType?.conversionDenominator,
    recalculationFromMonth: pglWageType?.recalculationFromMonth || null,
    applyRetroactivelyFromMonth: pglWageType?.applyRetroactivelyFromMonth || null,
    isPgl: pglType == 'PGL',
  });

  const { recalculationFromMonthOptions, applyRetroactivelyFromMonthOptions } = useWageTypeCalculationOptions(
    pglWageTypeRequest.recalculationFromMonth,
  );

  const isCreating = !pglWageType;
  const calculationTimeFrameOptions = createOptions(calculationTimeFrames, t, 'calculationTimeFrames');

  const fieldsEmptyOrInvalid =
    !selectedWageType ||
    !pglWageTypeRequest.conversionFactor ||
    (shouldWageTypeHaveDenomination(selectedWageType.value.wageType) &&
      (!pglWageTypeRequest.conversionDenominator || pglWageTypeRequest.conversionDenominator < 1)) ||
    (canEditCalculationTimeFrame(pglWageTypeRequest.wageType) && !pglWageTypeRequest.calculationTimeFrame) ||
    pglWageTypeRequest.conversionFactor > 100 ||
    pglWageTypeRequest.conversionFactor < 1;

  const handleConversionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      const numberWithFourDecimalPointsRegex = /^-?\d*(\.\d{0,4})?$/;
      if (!numberWithFourDecimalPointsRegex.test(e.target.value)) return;
    }
    setPglWageTypeRequest({
      ...pglWageTypeRequest,
      [e.target.name]: parseFloat(e.target.value),
    });
  };

  const handleOptionUpdate = (option: Option<CalculationTimeFrame | Month>, property: keyof PglWageTypeRequest) => {
    setPglWageTypeRequest({
      ...pglWageTypeRequest,
      [property]: option.value,
    });
  };

  const handleRemove = () => {
    setLoading(true);
    deletePglRuleWageType(policyId, pglRuleId, pglWageType?.id, employerId).finally(() => {
      onComplete();
      setLoading(false);
    });
  };

  const handleCreateOrUpdate = () => {
    setLoading(true);
    upsertPglRuleWageType(employerId, pglWageTypeRequest).finally(() => {
      onComplete();
      setLoading(false);
    });
  };

  useEffect(() => {
    const newRequest = {
      ...pglWageTypeRequest,
      wageType: selectedWageType.value.wageType,
      employerWageTypeId: selectedWageType.value.id,
    } as PglWageTypeRequest;
    if (!pglWageType?.recalculationFromMonth) {
      newRequest.recalculationFromMonth =
        selectedWageType.value.wageType === 'VARIABLE_PARTS_PREVIOUS_YEAR'
          ? recalculationFromMonthOptions[0].value
          : null;
    }

    if (!isValidWageTypeTimeFrameCombination(selectedWageType.value.wageType, newRequest?.calculationTimeFrame)) {
      newRequest.calculationTimeFrame = 'ACCUMULATE_PREVIOUS_YEAR';
    }

    if (!canEditCalculationTimeFrame(selectedWageType.value.wageType)) {
      newRequest.calculationTimeFrame = 'ACCUMULATE_CONSECUTIVE_3_TO_12_MONTHS';
    }

    newRequest.conversionDenominator = shouldWageTypeHaveDenomination(selectedWageType.value.wageType)
      ? newRequest.conversionDenominator ?? 12
      : null;

    setPglWageTypeRequest(newRequest);
  }, [selectedWageType]);

  return (
    <>
      <ListGroup variant="inline-edit">
        <When condition={canEditCalculationTimeFrame(selectedWageType.value.wageType)}>
          <InlineEdit label={`${t('account:pgl-rules:drawer:wage-type:calculation-time-frame')}*`} bold>
            <Select
              placeholder={t('common:select')}
              options={calculationTimeFrameOptions.filter((v) =>
                isValidWageTypeTimeFrameCombination(selectedWageType.value.wageType, v.value),
              )}
              selected={calculationTimeFrameOptions.find(
                (option) => option.value === pglWageTypeRequest?.calculationTimeFrame,
              )}
              onChange={(option: Option<CalculationTimeFrame | Month>) =>
                handleOptionUpdate(option, 'calculationTimeFrame')
              }
              disabled={!inDraft || loading}
              small
            />
          </InlineEdit>
        </When>
        <InlineEdit label={`${t('account:pgl-rules:drawer:wage-type:multiplication-factor')}*`} bold>
          <TextInput
            variant="inline-edit"
            type="number"
            name={'conversionFactor'}
            validationKey="decimal"
            value={pglWageTypeRequest.conversionFactor.toString()}
            onChange={handleConversionChange}
            disabled={!inDraft || loading}
          />
        </InlineEdit>
        <When condition={shouldWageTypeHaveDenomination(selectedWageType.value.wageType)}>
          <InlineEdit label={`${t('account:pgl-rules:drawer:wage-type:denominator')}*`} bold>
            <TextInput
              variant="inline-edit"
              type="number"
              validationKey="decimal"
              name={'conversionDenominator'}
              value={pglWageTypeRequest.conversionDenominator?.toString()}
              onChange={handleConversionChange}
              disabled={!inDraft || loading}
            />
          </InlineEdit>
        </When>
        {selectedWageType.value.wageType === 'VARIABLE_PARTS_PREVIOUS_YEAR' && (
          <InlineEdit label={`${t('account:pgl-rules:drawer:wage-type:recalculation-from-month')}`} bold>
            <Select
              placeholder={t('common:select')}
              options={recalculationFromMonthOptions}
              alphabetical={false}
              onChange={(option: Option<CalculationTimeFrame | Month>) =>
                handleOptionUpdate(option, 'recalculationFromMonth')
              }
              selected={recalculationFromMonthOptions.find(
                (option) => option.value === pglWageTypeRequest?.recalculationFromMonth,
              )}
              disabled={!inDraft || loading}
              small
            />
          </InlineEdit>
        )}
        {selectedWageType.value.wageType === 'VARIABLE_PARTS_PREVIOUS_YEAR' && (
          <InlineEdit label={`${t('account:pgl-rules:drawer:wage-type:apply-retroactively-from-month')}`} bold>
            <Select
              placeholder={t('common:select')}
              options={applyRetroactivelyFromMonthOptions}
              onChange={(option: Option<CalculationTimeFrame | Month>) =>
                handleOptionUpdate(option, 'applyRetroactivelyFromMonth')
              }
              alphabetical={false}
              selected={applyRetroactivelyFromMonthOptions.find(
                (option) => option.value === pglWageTypeRequest?.applyRetroactivelyFromMonth,
              )}
              disabled={!inDraft || loading}
              small
            />
          </InlineEdit>
        )}
      </ListGroup>
      <CalculationButtons
        inDraft={inDraft}
        isCreating={isCreating}
        handleRemove={handleRemove}
        handleCreateOrUpdate={handleCreateOrUpdate}
        fieldsEmptyOrInvalid={fieldsEmptyOrInvalid}
        loading={loading}
      />
    </>
  );
};

export default WageTypeCalculation;
