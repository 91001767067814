import { DrawerNavigation, ListGroup, Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployeeCardBenefit } from 'src/types/employees/EmployeeCardBenefit';
import './benefits-overview-drawer.scss';
import BenefitDetailsDrawer from '../BenefitDetailsDrawer/BenefitDetailsDrawer';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import { useState } from 'react';
import { EmployeeBenefitsOverviewDrawerTab } from 'src/types/tabs/EmployeeBenefitsOverviewDrawerTab';

interface BenefitsOverviewDrawerProps {
  open: boolean;
  benefits: EmployeeCardBenefit[];
  selectedBenefit?: EmployeeCardBenefit;
  handleSelectBenefit: (benefit: EmployeeCardBenefit) => void;
  onBackClick: () => void;
  onClose: () => void;
  period: string;
}

const BenefitsOverviewDrawer = ({
  open,
  onClose,
  selectedBenefit,
  benefits,
  handleSelectBenefit,
  onBackClick,
  period,
}: BenefitsOverviewDrawerProps) => {
  const { t } = useTranslation();
  const periodText = getFormattedDateStringFromPeriod(period);
  const [currentTab, setCurrentTab] = useState<EmployeeBenefitsOverviewDrawerTab>(
    EmployeeBenefitsOverviewDrawerTab.ABOUT,
  );

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      {!selectedBenefit ? (
        <DrawerBase
          title={
            <>
              <Typography variant="h2" bold>
                {t('benefits:benefits')}
              </Typography>
              <Pill type="success" label={periodText} />
            </>
          }
          onClose={onClose}
        >
          <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
            <Tab title={t('common:about')}>
              <div className="benefits-overview-drawer">
                <ListGroup variant="drawer-navigation">
                  {benefits.map((benefit, index) => (
                    <DrawerNavigation
                      key={benefit.benefitType + index}
                      label={
                        <div className="benefits-overview-drawer__list-item-label">
                          <Typography variant="body2" bold>
                            {benefit.name}
                          </Typography>
                          <Pill size="small" label={t(`benefitTypes:abbreviations.${benefit.benefitType}`)} />
                        </div>
                      }
                      value={
                        benefit.isEntitled
                          ? t('benefits:benefits-drawer.isEntitled.true')
                          : t('benefits:benefits-drawer.isEntitled.false')
                      }
                      onClick={() => handleSelectBenefit(benefit)}
                    />
                  ))}
                </ListGroup>
              </div>
            </Tab>
          </Tabs>
        </DrawerBase>
      ) : (
        <BenefitDetailsDrawer onBackClick={onBackClick} onClose={onClose} benefit={selectedBenefit} period={period} />
      )}
    </DrawerModal>
  );
};

export default BenefitsOverviewDrawer;
