import React, { FunctionComponent } from 'react';
import { DrawerNavigation } from 'fgirot-k2-ui-components';
import { useGroupedAffiliationRuleTexts } from 'src/util/PolicyUtil';
import { GroupedAffiliationRules } from 'src/types/policy/GroupedAffiliationRules';

interface RuleDrawerNavigationProps {
  groupedRules: GroupedAffiliationRules;
  handleDisplayRuleTypeDrawer: () => void;
}
const RuleDrawerNavigation: FunctionComponent<RuleDrawerNavigationProps> = ({
  groupedRules,
  handleDisplayRuleTypeDrawer,
}) => {
  const { label, value } = useGroupedAffiliationRuleTexts(groupedRules);
  return <DrawerNavigation label={label} value={value} onClick={handleDisplayRuleTypeDrawer} />;
};

export default RuleDrawerNavigation;
