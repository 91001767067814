import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'src/util/Hooks/SearchParamsHook';
import PeriodFilter from 'src/components/Common/PeriodFilter';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYEE_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/GetEmployeeAggregatedWages';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import { Option, Select } from 'fgirot-k2-ui-components';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { useTranslation } from 'react-i18next';
import './employee-absence-overview.scss';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { globalAbsenceWageTypes } from 'src/util/WageUtil';
import AbsenceDetails from './AbsenceDetails';

interface EmployeeAbsenceOverviewProps {
  employerId: string;
}

function EmployeeAbsenceOverview({ employerId }: EmployeeAbsenceOverviewProps) {
  const { t } = useTranslation();
  const { employeeId, period } = useParams();
  const { searchParams } = useSearchParams(
    new URLSearchParams({
      periodStart: period,
      periodEnd: period,
    }),
  );
  const employerWageTypes = useEmployerWageTypes(employerId);
  const [selectedWageTypes, setSelectedWageTypes] = useState<Option<EmployerWageType>[]>([]);

  const wageTypeOptions: Option<EmployerWageType>[] = useMemo(
    () =>
      employerWageTypes
        .filter((wageType) => globalAbsenceWageTypes.includes(wageType.wageType))
        .map((wageType) => ({
          label: wageType.name,
          value: wageType,
        })),
    [employerWageTypes],
  );

  const fromPeriod = searchParams?.get('periodStart');
  const toPeriod = searchParams?.get('periodEnd');

  const wageTypes = selectedWageTypes.map((wageType) => wageType.value.wageType);

  return (
    <div className="employee-absence-overview">
      <div className="employee-absence-overview__filters">
        <div className="employee-absence-overview__wage-type-filter">
          <Select
            label={t('wages:select-absence-wage-type')}
            placeholder={t('wages:select-absence-wage-type')}
            multiple
            selectAll
            options={wageTypeOptions}
            selected={selectedWageTypes}
            onChange={setSelectedWageTypes}
            data-testid="absence-wage-type-filter"
          />
        </div>
        <PeriodFilter useInitialFilter periodStart={period} periodEnd={period} />
      </div>
      <QueryWrapper
        query={GET_EMPLOYEE_AGGREGATED_WAGES}
        options={{ variables: { employeeId, fromPeriod, toPeriod, wageTypes, allowDrafts: false } }}
      >
        {(aggregatedAbsenceWages: AggregatedWageTypeWage[]) => {
          const filteredWages = aggregatedAbsenceWages.filter((wageType) => {
            return selectedWageTypes.some(
              (selectedWageType) => selectedWageType.value.id === wageType.employerWageTypeId,
            );
          });

          return (
            <ul className="employee-absence-overview__list" data-testid="absence-wage-type-list">
              {filteredWages.map((wageType) => {
                const employerWageType = employerWageTypes.find(
                  (employerWageType) => employerWageType.id === wageType.employerWageTypeId,
                );
                return (
                  <AbsenceDetails
                    wageType={wageType}
                    employerWageType={employerWageType}
                    key={wageType.employerWageTypeId}
                  />
                );
              })}
            </ul>
          );
        }}
      </QueryWrapper>
    </div>
  );
}

export default EmployeeAbsenceOverview;
