import { DocumentNode, gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CacheOperation, CacheOperationAdd, CacheOperationRemove } from 'src/types/CacheOperation';
import { QueryParam } from 'src/types/QueryParam';
import { getFromLocalStorage, saveToLocalStorage } from 'src/util/LocalStorage';
import { client } from '..';

export const writeToCache = <T>(key: string, object: T): void => {
  saveToLocalStorage(key, object);
  try {
    const query = convertToQueryFromObject<T>(key, object);
    client.writeQuery({
      query,
      data: Array.isArray(object) ? { [key]: [...object] } : { ...object },
    });
  } catch (e) {
    console.error(`Failed to write cache, key: ${key}`);
    console.error(e);
  }
};

export const addToCacheList = <T>(key: string, value: T) => {
  const operation: CacheOperationAdd<T> = { operation: 'ADD', value };
  modifyCacheList(key, operation);
};

export const removeFromCacheList = (key: string, id: string) => {
  const operation: CacheOperationRemove = { operation: 'REMOVE', id };
  modifyCacheList(key, operation);
};

const modifyCacheList = <T>(key: string, operation: CacheOperation<T>) => {
  const query = convertToQueryFromQueryParams(key, [key]);
  client.cache.updateQuery({ query }, (data) => {
    return {
      ...data,
      [key]: operation,
    };
  });
};

export const resetCacheList = (key: string) => {
  client.cache.evict({ fieldName: key });
};

export const useCache = <T>(key: string, fields: QueryParam[], wantArray = false): T => {
  const [result, setResult] = useState<T>(wantArray ? ([] as unknown as T) : undefined);

  const query = convertToQueryFromQueryParams(key, fields, wantArray);
  const { data } = useQuery(query, { fetchPolicy: 'cache-only' });
  useEffect(() => {
    if (!data) {
      const ls = getFromLocalStorage(key) as T;
      ls && writeToCache(key, ls);
      setResult(ls);
    } else if (wantArray) {
      setResult(data ? data[key] : []);
    } else {
      setResult(data);
    }
  }, [data]);
  return result;
};

export const getFromCache = <T>(key: string, queryParams?: QueryParam[]) => {
  try {
    const query = convertToQueryFromQueryParams(key, queryParams || [key]);
    const res = client.cache.readQuery({ query });
    return res[key] as T;
  } catch (e) {
    // returning null if query fails for some reason
    return null;
  }
};

export const convertToQueryFromQueryParams = (key: string, params: QueryParam[], wantArray = false): DocumentNode => {
  if (wantArray) {
    return gql`
  query  ${key} {
    ${convertToQueryStringFromParam(key, params)}
} 
`;
  } else {
    return gql`query 
  ${convertToQueryStringFromParam(key, params)}
`;
  }
};

const convertToQueryStringFromParam = (queryKey: string, params: QueryParam[]): string => {
  const keys = params.map((param) => {
    if (param === 'string-array') {
      return queryKey;
    }
    if (typeof param === 'string') {
      return param;
    } else {
      const key = Object.keys(param)[0];
      return convertToQueryStringFromParam(key, param[key]);
    }
  });

  return `${queryKey} {
    ${keys.join('\r\n')}
  }`;
};

export const convertToQueryFromObject = <T>(key: string, object: T): DocumentNode => {
  if (Array.isArray(object)) {
    return gql`
  query ${key} {
    ${convertToQueryStringFromObject(key, object)} 
  }
`;
  } else {
    return gql`query ${convertToQueryStringFromObject(key, object)}`;
  }
};

const convertToQueryStringFromObject = <T>(queryKey: string, object: T): string => {
  if (Array.isArray(object) && object.length > 0) {
    if (object.every((entry) => typeof entry === 'string')) {
      return convertToQueryStringFromParam(queryKey, [queryKey]);
    } else {
      return convertToQueryStringFromObject(queryKey, object[0]);
    }
  } else if (Array.isArray(object) && object.length === 0) {
    return;
  } else if (object === null || undefined) {
    return;
  } else {
    const keys = Object.keys(object).map((key) => {
      if (Array.isArray(object[key]) && object[key].length > 0) {
        return convertToQueryStringFromObject(key, object[key]);
      } else if (typeof object[key] !== 'object') {
        if (key !== null || undefined) {
          return key;
        } else {
          return;
        }
      } else {
        return convertToQueryStringFromObject(key, object[key]);
      }
    });

    return `${queryKey} {
    ${keys.join('\r\n')}
  }`;
  }
};
