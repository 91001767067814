import React, { useEffect, useState } from 'react';
import LoadingPage from 'src/components/Common/LoadingPage';
import { Employer } from 'src/types/employer/Employer';
import { Pagination } from 'fgirot-k2-ui-components';
import { useQuery } from '@apollo/client';
import FilterModalButton from 'src/components/Common/FilterModalButton';
import { GET_REPORTING_EVENTS } from 'src/graphql/schema/query/reporting/GetReportingEvents';
import { ReportingEventsPage } from 'src/types/reporting/ReportingEventsPage';
import { SearchEventsRequest, SearchEventsFilters } from 'src/types/reporting/SearchEventsRequest';
import { CalculateReportingQueueButton } from './CalculateReportingQueueButton';
import ReportingEventsSearchResult from './ReportingEventsSearchResult';
import ReportingEventsSearchResultSummary from './ReportingEventsSearchResultSummary';
import EventsFilterDetails from 'src/components/Common/EventsFilterDetails';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import When from 'src/components/Common/When';
import { DEFAULT_PAGE_SIZE } from 'src/tests/constants';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import { useTranslation } from 'react-i18next';
import DisableAutomaticReporting from './DisableAutomaticReporting';
import ReportingEventsFilterModal from './ReportingEventsFilterModal';
import { getInitialSearchReportingEventsRequest } from './utils/reportingEventUtils';
import './reporting-events-overview.scss';
import TriggerReportingQueueButton from './TriggerReportingQueueButton.tsx';

interface ReportingEventsOverviewProps {
  reportingPeriod: string;
  employers: Employer[];
  searchInput: string;
}

const ReportingEventsOverview = ({ reportingPeriod, employers, searchInput }: ReportingEventsOverviewProps) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchRequest, setSearchRequest] = useState<SearchEventsRequest>(
    getInitialSearchReportingEventsRequest(reportingPeriod, searchInput),
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const handlePageChange = (page: number) => setPageNumber(page - 1);
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);

  const { data, loading, startPolling, refetch } = useQuery<{
    reportingEvents: ReportingEventsPage;
  }>(GET_REPORTING_EVENTS, {
    variables: {
      searchReportingEventsRequest: { ...searchRequest, pageNumber, pageSize, wageFilePeriod: reportingPeriod },
    },
  });

  const openFilterModal = () => setFilterModalOpen(true);
  const closeFilterModal = () => setFilterModalOpen(false);

  const applyFilters = (filters: SearchEventsFilters) => {
    setSearchRequest({
      ...searchRequest,
      filters,
    });
    setPageNumber(0);
  };

  useEffect(() => {
    startPolling(10000);
  }, []);

  useEffect(() => {
    if (searchInput !== searchRequest.searchInput) {
      setSearchRequest({
        ...searchRequest,
        searchInput,
      });
      setPageNumber(0);
    }
  }, [searchInput]);

  const totalPages = getTotalPages(data?.reportingEvents?.paging.totalNumberOfElements, DEFAULT_PAGE_SIZE);

  return (
    <div className="reporting-events-overview" data-testid="reporting-events-overview">
      <div className="reporting-events-overview__table-header">
        <div className="reporting-events-overview__table-header__info">
          <ReportingEventsSearchResultSummary reportingEvents={data?.reportingEvents} period={reportingPeriod} />
          <EventsFilterDetails searchRequest={searchRequest} applyFilters={applyFilters} />
        </div>
        <div className="reporting-events-overview__table-header__buttons">
          <CalculateReportingQueueButton employers={employers} wageFilePeriod={reportingPeriod} onSuccess={refetch} />
          <TriggerReportingQueueButton employers={employers} onSuccess={refetch} wageFilePeriod={reportingPeriod} />
          <DisableAutomaticReporting employers={employers} />
        </div>
      </div>
      <When condition={loading}>
        <LoadingPage />
      </When>
      <When condition={data?.reportingEvents?.persons.length > 0}>
        <ul>
          {data?.reportingEvents?.persons.map((person) => (
            <ReportingEventsSearchResult key={person.personNumber} person={person} />
          ))}
        </ul>
      </When>
      <Pagination
        currentPage={pageNumber + 1}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        pageSize={pageSize}
        pageSizeLabel={t('common:results-per-page')}
        pageSizeOptions={pageSizeOptions}
        onPageSizeChange={setPageSize}
        pageSizePosition="left"
      />
      <When condition={filterModalOpen}>
        <ReportingEventsFilterModal
          open={filterModalOpen}
          onClose={closeFilterModal}
          filters={searchRequest.filters}
          period={reportingPeriod}
          employers={employers}
          applyFilters={applyFilters}
        />
      </When>
      <FilterModalButton onClick={openFilterModal} />
    </div>
  );
};

export default ReportingEventsOverview;
