import { Badge, IconPill, ListItem, Typography } from 'fgirot-k2-ui-components';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import './contract-list-item.scss';
import { useTranslation } from 'react-i18next';
import { stringOrBlank } from 'src/util/StringUtil';

interface ContractListItemProps {
  contract: InsuranceProviderContract;
  handleDrawerOpen: (id: string) => void;
}

const ContractListItem = ({ contract, handleDrawerOpen }: ContractListItemProps) => {
  const { t } = useTranslation();

  const header = (
    <div className="contract-list-item__header">
      <Typography
        variant="subtitle"
        bold
        className="contract-list-item__header-title"
        data-cy="contract-list-item__title"
      >
        {contract.name}
      </Typography>
      <IconPill
        label={stringOrBlank(contract.insuranceProvider?.legalName)}
        data-cy="contract-list-item__insurance-provider"
      />
      <IconPill icon="Hash" label={contract.agreementNumber} data-cy="contract-list-item__agreement-number" />
      {contract.invoiceInterval > 0 && (
        <IconPill
          icon="Invoice"
          label={
            t(`agreementRegistrySettings:interval`) +
            t(`agreementRegistrySettings:invoice-interval.month`, { count: contract.invoiceInterval })
          }
          data-cy="contract-list-item__invoice-interval"
        />
      )}

      {contract.status === 'ACTIVE' ? (
        <Badge
          label={t(`agreementRegistrySettings:agreement-status.${'ACTIVE'}`)}
          type="success"
          circle="small"
          background="light"
        />
      ) : (
        <Badge
          label={t(`agreementRegistrySettings:agreement-status.${'TERMINATED'}`)}
          type="warning"
          circle="small"
          background="light"
        />
      )}
    </div>
  );

  const Title = ({ title }: { title: string }) => (
    <Typography variant="caption" bold>
      {title}
    </Typography>
  );

  return (
    <ListItem
      key={contract.id}
      onClick={() => handleDrawerOpen(contract.id)}
      header={header}
      className="contract-list-item"
    >
      <div className="contract-list-item__titles">
        <Title title={t('agreementRegistrySettings:agreed-product-name')} />
        <Title title={t('agreementRegistrySettings:insurance-product')} />
        <Title title={t('agreementRegistrySettings:reporting-rule-configuration')} />
      </div>
      <ul className="contract-list-item__list">
        {contract.agreedProducts.map((product) => (
          <li key={product.id} className="contract-list-item__row">
            <Typography variant="body2" data-cy="contract-list-item__name">
              {product.name}
            </Typography>
            <Typography variant="body2" data-cy="contract-list-item__insurance-product">
              {stringOrBlank(product.insuranceProduct?.name)}
            </Typography>
            <Typography variant="body2" data-cy="contract-list-item__reporting-rule-configuration">
              {stringOrBlank(product.reportingRuleConfiguration?.name)}
            </Typography>
          </li>
        ))}
      </ul>
    </ListItem>
  );
};

export default ContractListItem;
