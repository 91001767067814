import { Button, Icon } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { Employee } from 'src/types/Employee';
import './employee-actions.scss';
import PeriodSelect from './PeriodSelect';
import CreateDraftEmployeeModal from './PeriodSelect/CreateDraftEmployeeModal';
import DeleteDraftEmployeeModal from './PeriodSelect/DeleteDraftEmployeeModal';
import PublishDraftEmployeeModal from './PeriodSelect/PublishDraftEmployeeModal';
import { useTranslation } from 'react-i18next';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';

interface EmployeeActionsProps {
  period: string;
  employee: Employee;
}

function EmployeeActions({ period, employee }: EmployeeActionsProps) {
  const { t } = useTranslation();

  const [createDraftModalOpen, setCreateDraftModalOpen] = useState(false);
  const [deleteDraftModalOpen, setDeleteDraftModalOpen] = useState(false);
  const [publishDraftModalOpen, setPublishDraftModalOpen] = useState(false);

  const handleOpenCreateDraftModal = () => setCreateDraftModalOpen(true);
  const handleOpenDeleteDraftModal = () => setDeleteDraftModalOpen(true);
  const handleOpenPublishDraftModal = () => setPublishDraftModalOpen(true);

  const handleCloseCreateDraftModal = () => setCreateDraftModalOpen(false);
  const handleCloseDeleteDraftModal = () => setDeleteDraftModalOpen(false);
  const handleClosePublishDraftModal = () => setPublishDraftModalOpen(false);

  const isDraft = employee.isDraft;

  return (
    <div className="employee-actions">
      <div className="employee-actions__header">
        <PeriodSelect period={period} employeeId={employee.id} />
        <div className="employee-actions__buttons">
          {!isDraft && (
            <Button
              size="small"
              className="employee-actions__button"
              type="secondary"
              icon={<Icon type="Edit" className="employee-actions__icon" stroke="#243757" />}
              label={t('employees:actions.edit')}
              onClick={handleOpenCreateDraftModal}
              data-cy="open-create-draft-employee-modal"
            />
          )}
          {isDraft && (
            <>
              <Button
                size="small"
                className="employee-actions__button"
                type="primary"
                icon={<Icon type="Check" className="employee-actions__icon" stroke="#FFFFFF" />}
                label={t('employees:actions.publish')}
                onClick={handleOpenPublishDraftModal}
                data-cy="open-publish-draft-employee-modal"
              />
              <Button
                size="small"
                className="employee-actions__button"
                type="secondary"
                icon={<Icon type="Trash" className="employee-actions__icon" stroke="#243757" />}
                label={t('employees:actions.delete')}
                onClick={handleOpenDeleteDraftModal}
                data-cy="open-delete-draft-employee-modal"
              />
            </>
          )}
        </div>
      </div>
      <CreateDraftEmployeeModal
        open={createDraftModalOpen}
        handleClose={handleCloseCreateDraftModal}
        employeeId={employee.id}
        employerId={employee.employerId}
      />
      <DeleteDraftEmployeeModal
        open={deleteDraftModalOpen}
        handleClose={handleCloseDeleteDraftModal}
        employeeId={employee.id}
        employerId={employee.employerId}
      />
      <PublishDraftEmployeeModal
        open={publishDraftModalOpen}
        handleClose={handleClosePublishDraftModal}
        employeeId={employee.id}
        employerId={employee.employerId}
      />
      {isDraft && <EmployeeInDraftBanner />}
    </div>
  );
}

export default EmployeeActions;
