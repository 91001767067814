import { Pill } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextInput from 'src/components/Common/TextInput';
import { useUpdateAccountingComponentType } from 'src/service/accountingunit/AccountingComponent';
import { AccountingComponentType } from 'src/types/accountingunit/AccountingComponent';
interface AccountingComponentTypeAccordionHeaderProps {
  accountingComponentType: AccountingComponentType;
}
const AccountingComponentTypeAccordionHeader: FC<AccountingComponentTypeAccordionHeaderProps> = ({
  accountingComponentType,
}) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [name, setName] = useState(accountingComponentType.name);
  const updateAccountingComponentType = useUpdateAccountingComponentType();

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleOnBlur = () => {
    updateAccountingComponentType({ accountingComponentTypeId: accountingComponentType.id, name }, employerId);
  };
  return (
    <div className="accounting-component-type__accordion__header">
      <TextInput
        data-cy={`accounting-component-name-${accountingComponentType.name.replace(' ', '')}`}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
        type="text"
        validationKey="text64"
        maxLength={65}
        onChange={handleNameChange}
        value={name}
        onBlur={handleOnBlur}
      />
      <Pill
        label={`${
          accountingComponentType.accountingComponentValues
            ? accountingComponentType.accountingComponentValues.length
            : '0'
        } ${t('economySettings:accounting-components-tab.accordion.pill-label')}`}
      />
      {accountingComponentType.wageFileAccountingComponentType && (
        <Pill
          label={`${t('economySettings:accounting-components-tab.accordion.pill2-label')}: ${t(
            `economySettings:wage-file-accounting-components.${accountingComponentType.wageFileAccountingComponentType}`,
          )}`}
        />
      )}
    </div>
  );
};

export default AccountingComponentTypeAccordionHeader;
