import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import ListProperty from 'src/components/Common/ListProperty/ListProperty';
import { useDeleteContractAgreementCostCentre } from 'src/service/contract/DeleteContractAgreementCostCentre';
import { stringOrBlank } from 'src/util/StringUtil';

interface AgreementCostCentreDetailsProps {
  onClose: () => void;
  onBackClick: () => void;
  contract: InsuranceProviderContract;
  agreementCostCentreId: string;
}

const AgreementCostCentreDetails = ({
  onClose,
  onBackClick,
  contract,
  agreementCostCentreId,
}: AgreementCostCentreDetailsProps) => {
  const { t } = useTranslation();
  const deleteContractAgreementCostCentre = useDeleteContractAgreementCostCentre();

  const selectedCostCentre = contract.agreementCostCentres.find(
    (costCentre) => costCentre.id === agreementCostCentreId,
  );

  const handleDeleteAgreementCostCentre = () => {
    deleteContractAgreementCostCentre(contract.employerId, contract.id, agreementCostCentreId).then(() => {
      onBackClick();
    });
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="agreement-registry-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {contract.name}
        </Typography>
        <div className="agreement-registry-drawer__sub-title">
          <Typography>{stringOrBlank(contract.insuranceProvider?.legalName)}</Typography>
          <Pill type="neutral" label={contract.agreementNumber} />
        </div>
      </div>
      <div className="agreement-registry-drawer__content">
        <ListGroup variant="inline-edit">
          <ListProperty label={t('agreementRegistrySettings:drawer.code')} value={selectedCostCentre?.code} />
          <ListProperty
            label={t('agreementRegistrySettings:agreement-cost-centre-is-standard')}
            value={selectedCostCentre?.isDefault ? t('common:yes') : t('common:no')}
          />
        </ListGroup>

        <Button
          className="agreed-product-details__button"
          icon={<Icon type="Trash" />}
          type="secondary"
          label={t('common:delete')}
          onClick={handleDeleteAgreementCostCentre}
          data-cy="delete-agreement-cost-centre-button"
        />
      </div>
    </Drawer>
  );
};

export default AgreementCostCentreDetails;
