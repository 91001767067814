import { useApolloMutation } from '../graphql/Mutation';
import { UPDATE_DRAFT_EMPLOYEE } from 'src/graphql/schema/mutation/employee/UpdateDraftEmployee';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { UpdateDraftEmployeeRequest } from 'src/types/employees/request/UpdateDraftEmployeeRequest';

export const useUpdateDraftEmployee = () => {
  const apolloMutation = useApolloMutation();

  const updateDraftEmployee = (request: UpdateDraftEmployeeRequest, employerId: string) =>
    apolloMutation(
      UPDATE_DRAFT_EMPLOYEE,
      employerId,
      {
        request,
      },
      [GET_EMPLOYEE],
    );

  return updateDraftEmployee;
};
