import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { INSURANCE_EVENT_UPDATED } from '../../graphql/schema/subscription/InsuranceEventUpdated';
import { InsuranceEvent } from '../../types/reporting/InsuranceEvent';

export const useInsuranceEventSubscription = (
  insuranceEventIds: string[],
  update: (insuranceEvent: InsuranceEvent) => void,
) => {
  const { data } = useSubscription<{ insuranceEventUpdated: InsuranceEvent }>(INSURANCE_EVENT_UPDATED, {
    variables: { insuranceEventIds },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data != undefined) update(data.insuranceEventUpdated);
  }, [data]);
};
export default useInsuranceEventSubscription;
