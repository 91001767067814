import React from 'react';
import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import EmployerIndividualChoiceRow from './EmployerIndividualChoiceRow';

interface EmployerIndividualChoicesTableProps {
  employerIndividualChoices: EmployerIndividualChoice[];
  onSelectIndividualChoice: (individualChoice: EmployerIndividualChoice) => void;
}

function EmployerIndividualChoicesTable({
  employerIndividualChoices,
  onSelectIndividualChoice,
}: EmployerIndividualChoicesTableProps) {
  const { t } = useTranslation();

  const tableHeads = [
    { name: t('account:employers-tab.reporting-settings.individual-choices-tab.individual-choice-type') },
    { name: t('account:employers-tab.reporting-settings.individual-choices-tab.from-wage-file') },
    { name: t('account:employers-tab.reporting-settings.individual-choices-tab.from-invoice') },
  ];

  return (
    <TableBase maxHeightPercentage={50} data-testid="employer-individual-choices-table">
      <TableHead filled>
        <TableRow>
          {tableHeads.map((title, index) => (
            <Th key={`employer-individual-choices-table-${title.name}-${index}`}>{title.name}</Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {employerIndividualChoices.map((individualChoice, index) => (
          <EmployerIndividualChoiceRow
            individualChoice={individualChoice}
            key={`employer-individual-choice-row-${individualChoice.type}-${index}`}
            onSelectIndividualChoice={onSelectIndividualChoice}
          />
        ))}
      </TableBody>
    </TableBase>
  );
}

export default EmployerIndividualChoicesTable;
