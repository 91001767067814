import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { GroupsDrawerTab } from 'src/types/tabs/GroupsDrawerTab';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import { GetGroup } from 'src/types/group/Group';
import GroupsDrawerRolesTab from './GroupsDrawerRolesTab';
import { Role } from 'src/types/Role';
import GroupsDrawerUsersTab from './GroupsDrawerUsersTab';
import AddUserToGroup from './AddUserToGroup';
import { ExternalUser } from 'src/types/user/User';
import GroupsDrawerEmployerTab from './GroupsDrawerEmployerTab';
import AddEmployerToGroup from './AddEmployerToGroup';
import { Employer } from 'src/types/employer/Employer';

interface GroupsDrawerProps {
  open: boolean;
  group: GetGroup;
  allRoles: Role[];
  allUsers: ExternalUser[];
  allEmployers: Employer[];
  onClose: () => void;
}

const GroupsDrawer: React.FC<GroupsDrawerProps> = ({ open, group, allRoles, allUsers, allEmployers, onClose }) => {
  const { t } = useTranslation();
  const isAdminGroup = group.admin;
  const [currentTab, setCurrentTab] = useState<GroupsDrawerTab>(
    isAdminGroup ? GroupsDrawerTab.USERS : GroupsDrawerTab.EMPLOYERS,
  );
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddEmployer, setShowAddEmployer] = useState(false);
  const breadcrumbs = [t('permission:breadcrumbs.permission'), t('permission:breadcrumbs.groups')];

  return (
    <div className="groups-drawer">
      <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
        <>
          {!showAddUser && !showAddEmployer && (
            <DrawerBase
              onClose={onClose}
              title={
                <>
                  <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
                  <Typography variant="h3" component="h3" bold className="users-drawer__title">
                    {group.name}
                  </Typography>
                </>
              }
            >
              <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
                {isAdminGroup ? (
                  <></>
                ) : (
                  <Tab title={`${t('permission:drawer-tabs.employers-label')}`}>
                    <GroupsDrawerEmployerTab
                      groupId={group.id}
                      employers={group.employers}
                      onAddEmployerClick={() => setShowAddEmployer(true)}
                    />
                  </Tab>
                )}
                <Tab title={`${t('permission:drawer-tabs.users-label')}`}>
                  <GroupsDrawerUsersTab
                    users={group.users}
                    onAddUserClick={() => setShowAddUser(true)}
                    groupId={group.id}
                  />
                </Tab>
                <Tab title={`${t('permission:drawer-tabs.roles-label')}`}>
                  <GroupsDrawerRolesTab
                    groupId={group.id}
                    allRoles={allRoles}
                    groupRoles={group.roles}
                    adminGroup={group.admin}
                  />
                </Tab>
              </Tabs>
              {/* <EditGroupUser onClose={null} user={mockUsers[0]} groupId="groupID" /> */}
            </DrawerBase>
          )}
          {showAddEmployer && (
            <AddEmployerToGroup
              onClose={() => setShowAddEmployer(false)}
              groupName={group.name}
              groupId={group.id}
              groupEmployers={group.employers}
              allEmployers={allEmployers}
              breadcrumbs={breadcrumbs}
            />
          )}
          {showAddUser && (
            <AddUserToGroup
              onClose={() => setShowAddUser(false)}
              group={group}
              groupUsers={group.users}
              allUsers={allUsers}
              breadcrumbs={breadcrumbs}
            />
          )}
        </>
      </DrawerModal>
    </div>
  );
};

export default GroupsDrawer;
