import { t } from 'i18next';
import React, { FC, useState } from 'react';
import ModuleCard from 'src/components/Common/ModuleCard';
import ModuleCardBox from 'src/components/Common/ModuleCardBox';
import { Employer } from 'src/types/employer/Employer';
import WageFileSettingsDrawer from '../WageFileSettingsDrawer';
import { stringOrBlank } from 'src/util/StringUtil';
import './reporting-settings-tab.scss';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_REPORTING_SCHEDULED_JOBS } from 'src/graphql/schema/query/reporting/GetReportingScheduledJobs';
import { ReportingScheduledJob } from 'src/types/reporting/ReportingScheduledJob';
import ReportingScheduledJobsDrawer from './ReportingScheduledJobsDrawer';
import { GET_EMPLOYER_WAGE_FILE_SETTING } from 'src/graphql/schema/query/employer/GetEmployerWagFileSetting';
import { EmployerWageFileSetting } from 'src/types/employer/EmployerWageFileSetting';

interface ReportingSettingsTabProps {
  employer: Employer;
}

const ReportingSettingsTab: FC<ReportingSettingsTabProps> = ({ employer }) => {
  const [selectedEmployerId, setSelectedEmployerId] = useState<string>(undefined);
  const [reportingScheduledJobsDrawerOpen, setReportingScheduledJobsDrawerOpen] = useState<boolean>(false);

  const handleOpenWageFileDrawer = (id: string) => {
    setSelectedEmployerId(id);
  };

  const handleCloseWageFileDrawer = () => {
    setSelectedEmployerId(undefined);
  };

  const handleOpenReportingScheduledJobsDrawer = () => {
    setReportingScheduledJobsDrawerOpen(true);
  };

  const handleCloseReportingScheduledJobsDrawer = () => {
    setReportingScheduledJobsDrawerOpen(false);
  };

  return (
    <div className="reporting-settings-tab">
      <QueryWrapper query={GET_EMPLOYER_WAGE_FILE_SETTING} options={{ variables: { employerId: employer.id } }}>
        {(employerWageFileSetting: EmployerWageFileSetting) => (
          <>
            <ModuleCard
              className="reporting-settings-tab__module-card"
              title={t('reportingSettings:wage-file')}
              iconType="Settings"
              onClick={() => handleOpenWageFileDrawer(employer.id)}
              dataCy="wage-file-settings-tab__module-card"
            >
              <div className="reporting-settings-tab__module-card-content">
                <ModuleCardBox
                  label={t('reportingSettings:salary-system')}
                  value={stringOrBlank(employerWageFileSetting?.salarySystem)}
                  dataCy="wage-file-settings-tab__salary-system-box"
                />
                <ModuleCardBox
                  label={t('reportingSettings:wage-file-format')}
                  value={stringOrBlank(employerWageFileSetting?.wageFileFormat)}
                  dataCy="wage-file-settings-tab__wage-file-format-box"
                />
                <ModuleCardBox
                  label={t('reportingSettings:expected-no-of-files')}
                  value={stringOrBlank(employerWageFileSetting?.expectedNoOfWageFiles?.toString())}
                  dataCy="wage-file-settings-tab__expected-no-of-files-box"
                />
              </div>
            </ModuleCard>

            {selectedEmployerId && (
              <WageFileSettingsDrawer
                open={selectedEmployerId !== undefined}
                onClose={handleCloseWageFileDrawer}
                employer={employer}
                employerWageFileSetting={employerWageFileSetting}
              />
            )}
          </>
        )}
      </QueryWrapper>

      <QueryWrapper query={GET_REPORTING_SCHEDULED_JOBS} options={{ variables: { employerId: employer.id } }}>
        {(reportingScheduledJobs: ReportingScheduledJob[]) => (
          <>
            <ModuleCard
              className="reporting-settings-tab__module-card"
              title={t('reportingSettings:reporting-scheduled-jobs.schedulings')}
              iconType="Settings"
              onClick={handleOpenReportingScheduledJobsDrawer}
              dataCy="wage-file-settings-tab__reporting-scheduled-jobs"
            >
              <div className="reporting-settings-tab__module-card-content">
                <ModuleCardBox
                  label={t('reportingSettings:reporting-scheduled-jobs.active-schedulings')}
                  value={reportingScheduledJobs.length.toString()}
                  dataCy="wage-file-settings-tab__number-of-scheduled-jobs"
                />
              </div>
            </ModuleCard>
            {reportingScheduledJobsDrawerOpen && (
              <ReportingScheduledJobsDrawer
                reportingScheduledJobs={reportingScheduledJobs}
                open={reportingScheduledJobsDrawerOpen}
                onClose={handleCloseReportingScheduledJobsDrawer}
                employer={employer}
              />
            )}
          </>
        )}
      </QueryWrapper>
    </div>
  );
};

export default ReportingSettingsTab;
