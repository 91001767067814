import { PayedAmountPerPeriod, PeriodsToWages } from './UpdateWagesPayedAmount';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import { sortPeriods } from 'src/util/SortUtil';

export const payedAmountsAreValid = (
  payedAmounts: PayedAmountPerPeriod[],
  kubWagesPerPeriodAndCardId: PeriodsToWages,
) => {
  let valid = true;

  valid =
    valid &&
    payedAmounts.every((payedAmount) => {
      if (payedAmount.payedAmount) {
        return true;
      }
    });

  valid =
    valid &&
    Object.keys(kubWagesPerPeriodAndCardId)
      .sort(sortPeriods)
      .slice(1)
      .some(
        (key) =>
          kubWagesPerPeriodAndCardId[key].length === 0 ||
          kubWagesPerPeriodAndCardId[key].some((wage) =>
            payedAmounts.some((payedAmount) => {
              if (
                payedAmount.payedAmount !== undefined &&
                wage.payedAmount.toString() !== payedAmount.payedAmount &&
                wage.id === payedAmount.wageId
              ) {
                return true;
              }
            }),
          ),
      );

  const errors = payedAmounts
    .map((p) => {
      const { error } = inputSchema.validate({ amount2DecAllowNegative: p.payedAmount }, { abortEarly: false });
      return error;
    })
    .filter((e) => e !== undefined);

  if (errors.length > 0) {
    valid = false;
  }

  return valid;
};
