import { Month } from '../Month';

export interface PglBonusComponent {
  id: string;
  bonusAverageType: BonusAverageType;
  employerWageTypeId: string;
  numberOfYearsToInclude: number;
  includePresentYear: boolean;
  recalculationFromMonth: Month;
  recalculationToMonth: Month;
  applyRetroactivelyFromMonth: string;
  successiveBonusSpecificSettings: SuccessiveBonusSpecificSettings;
}

export type BonusAverageType = 'STRAIGHT' | 'SUCCESSIVE';

export interface SuccessiveBonusSpecificSettings {
  startDateBasis: StartDateBasis;
  numberOfYearsOfHistory: number;
}

export type StartDateBasis = 'EMPLOYMENT_DATE' | 'FIRST_BONUS_PAYMENT_DATE';

export const startDateBases: StartDateBasis[] = ['EMPLOYMENT_DATE', 'FIRST_BONUS_PAYMENT_DATE'];
