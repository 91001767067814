import { gql } from '@apollo/client';

export const GET_REPORTING_EVENTS = gql`
  query GetReportingEvents($searchReportingEventsRequest: SearchReportingEventsRequest!) {
    reportingEvents(searchReportingEventsRequest: $searchReportingEventsRequest) {
      paging {
        totalNumberOfElements
        hasNext
      }
      persons {
        personNumber
        employees {
          id
          firstName
          lastName
          employerId
          employmentNumber
          hasDraft
        }
        reportingEvents {
          id
          employeeId
          wageFilePeriod
          employeeInsuranceId
          availableMessageFormat
          messageFormatCategory
          benefitType
          reportingEventType
          insuranceEventType
          aggregatedInsuranceEvent {
            insuranceEventId
            eventFromDate
            insuranceEventStatus
            eventValues {
              id
              eventValueType
              currentValue
              previousValue
            }
          }
          employeeCard {
            id
            period
            employmentGroupId
            evaluationPolicyEntityId
            employmentCategory
            remunerationType
            employmentType
          }
          insuranceProvider {
            id
            organisationNumber
            legalName
          }
          messageReceipt {
            id
            messageId
            messageStatus
          }
        }
      }
    }
  }
`;
