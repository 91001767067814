import { Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EmployeeHasDraftPillProps {
  hasDraft: boolean;
}

function EmployeeHasDraftPill({ hasDraft }: EmployeeHasDraftPillProps) {
  const { t } = useTranslation();

  if (!hasDraft) return null;

  return <Pill type="warning" label={t('reporting:insurance-events.employee-has-draft')} size="small" />;
}

export default EmployeeHasDraftPill;
