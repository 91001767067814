import { gql } from '@apollo/client';

export const GET_EMPLOYEE_INSURANCE_EVENTS = gql`
  query GetEmployeeInsuranceEvents($employeeId: UUID!, $request: EmployeeInsuranceEventsRequest) {
    employeeInsuranceEvents(employeeId: $employeeId, request: $request) {
      paging {
        totalNumberOfElements
        hasNext
      }
      employeeInsuranceEvents {
        insuranceEventId
        benefitType
        reportingType
        insuranceEventType
        status
        wageFilePeriod
        eventFromDate
        certifyInsuranceProductCondition
        employeeInsurance {
          id
          insurance {
            agreementNumber
            agreedProduct {
              name
            }
          }
        }
        eventValues {
          id
          currentValue
          previousValue
          eventValueType
        }
        insuranceProvider {
          id
          legalName
        }
        employeeCard {
          period
          employmentGroupId
          employmentCategory
          evaluationPolicyEntityId
        }
      }
    }
  }
`;
