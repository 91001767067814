import { Button, Icon, Loader, Pagination, Typography } from 'fgirot-k2-ui-components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import EmployeeSearchInput from './EmployeeSearchInput';
import { useQuery } from '@apollo/client';
import { SEARCH_EMPLOYEES } from 'src/graphql/schema/query/employee/SearchEmployees';
import SearchFiltersModal from '../SearchFiltersModal/SearchFiltersModal';
import { SearchEmployeesFilters, SearchEmployeesRequest } from 'src/types/employees/SearchEmployeesRequest';
import { SearchEmployeesPage } from 'src/types/employees/SearchEmployeesPage';
import EmployeeSearchResult from './EmployeeSearchResult/EmployeeSearchResult';
import {
  getInitialSearchEmployeeRequest,
  isSearchEmployeeRequestValid,
  searchEmployeeHasActiveFilters,
} from './search-employee-utils';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from 'src/tests/constants';
import When from 'src/components/Common/When';
import EmployeesFilterDetails from './EmployeesFilterDetails';
import './employee-tab.scss';

const SearchEmployeesTab = ({ customerId }: { customerId: string }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(0);
  const [searchRequest, setSearchRequest] = useState<SearchEmployeesRequest>(getInitialSearchEmployeeRequest());
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(undefined);
  const pageRendered = useRef(false);

  const handlePageChange = (page: number) => setPageNumber(page - 1);
  const openFilterModal = () => setFiltersModalOpen(true);
  const closeFilterModal = () => setFiltersModalOpen(false);

  const { data, loading } = useQuery<{
    employees: SearchEmployeesPage;
  }>(SEARCH_EMPLOYEES, {
    variables: { searchEmployeesRequest: { ...searchRequest, pageNumber } },
    skip: !searchRequest.searchInput,
  });

  const handleApplyFilters = (newFilters: SearchEmployeesFilters) => {
    const isValid = isSearchEmployeeRequestValid({
      ...searchRequest,
      filters: newFilters,
    });
    setErrorMessage(isValid ? undefined : t('employees:search-tab:invalid-search'));
    setSearchRequest({
      ...searchRequest,
      filters: newFilters,
    });
    closeFilterModal();
  };

  const updateSearchParams = () => {
    setSearchParams((prev) => {
      if (searchRequest.filters?.employerFilter?.employerIds.length > 0) {
        prev.set('employerIds', searchRequest.filters?.employerFilter.employerIds.join(','));
      } else {
        prev.delete('employerIds');
      }

      if (searchRequest.filters?.employmentNumberFilter?.length > 0) {
        prev.set('employmentNumberFilter', searchRequest.filters?.employmentNumberFilter);
      } else {
        prev.delete('employmentNumberFilter');
      }

      if (searchRequest.searchInput) {
        prev.set('searchTerm', searchRequest.searchInput);
      } else {
        prev.delete('searchTerm');
      }

      return prev;
    });
  };

  useEffect(() => {
    if (pageRendered.current) {
      const isValid = isSearchEmployeeRequestValid(searchRequest);
      setErrorMessage(isValid ? undefined : t('employees:search-tab:invalid-search'));
      updateSearchParams();
    } else if (!pageRendered.current) {
      const initialEmployerIds = searchParams.get('employerIds');
      const initialEmploymentNumberFilter = searchParams.get('employmentNumberFilter');
      const initialSearchTerm = searchParams.get('searchTerm');
      if (initialSearchTerm || initialEmployerIds) {
        setSearchRequest({
          ...searchRequest,
          searchInput: searchParams.get('searchTerm') || '',
          filters: {
            employerFilter: {
              employerIds: initialEmployerIds ? initialEmployerIds.split(',') : [],
            },
            employmentNumberFilter: initialEmploymentNumberFilter ? initialEmploymentNumberFilter : null,
          },
        });
      }
    }
    pageRendered.current = true;
  }, [searchRequest]);

  const searchTermChanged = (searchTerm) => {
    setSearchRequest({
      ...searchRequest,
      searchInput: searchTerm,
    });
  };

  const totalPages = getTotalPages(data?.employees?.paging.totalNumberOfElements, DEFAULT_PAGE_SIZE);

  return (
    <div className="employee-tab">
      <EmployeeSearchInput
        prefilledText={searchRequest.searchInput}
        onTriggerSearch={searchTermChanged}
        disabled={loading}
        errorMessage={errorMessage}
      />
      <EmployeesFilterDetails searchRequest={searchRequest} applyFilters={handleApplyFilters} />
      <div className="employee-tab__label-row">
        <Typography className="employee-tab__label" variant="caption">
          <>
            {t('employees:search-tab:showing')}&nbsp;
            <Typography component="span" className="employee-tab__label" variant="caption" bold>
              {String(data?.employees?.persons.length || 0)}
            </Typography>
            &nbsp;{t('employees:search-tab:of')}&nbsp;
            <Typography className="employee-tab__label" component="span" variant="caption" bold>
              {String(data?.employees?.paging.totalNumberOfElements || 0)}
            </Typography>
            &nbsp;{t('employees:search-tab:employees')}
          </>
        </Typography>
        <When condition={searchEmployeeHasActiveFilters(searchRequest)}>
          <Typography component="span" className="employee-tab__activeFilters" variant="caption" italic>
            <span onClick={openFilterModal}>{t('employees:search-tab:filters-in-use')}</span>
          </Typography>
        </When>
      </div>
      <When condition={loading}>
        <Loader type="blue" />
      </When>
      <section className="employee-tab__search-results">
        <ul>
          {data?.employees?.persons?.map((person) => (
            <EmployeeSearchResult key={person.personNumber} employeeData={person.employments} />
          ))}
        </ul>
      </section>
      <Pagination currentPage={pageNumber + 1} totalPages={totalPages} onPageChange={handlePageChange} />
      <When condition={filtersModalOpen}>
        <SearchFiltersModal
          open={filtersModalOpen}
          customerId={customerId}
          initialFilters={searchRequest.filters}
          onCancel={closeFilterModal}
          onApply={handleApplyFilters}
        />
      </When>

      <div className="employee-tab__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={openFilterModal}
          data-cy="filter-button"
        />
      </div>
    </div>
  );
};

export default SearchEmployeesTab;
