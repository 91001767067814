import { useApolloMutation } from '../graphql/Mutation';
import { CreateEmploymentEventCheckpointWageInput } from 'src/types/reporting/CreateEmploymentEventCheckpointWageInput';
import { CREATE_EMPLOYMENT_EVENT_CHECKPOINT_WAGE } from 'src/graphql/schema/mutation/reporting/CreateEmploymentEventCheckpointWage';
import { GET_EMPLOYEE_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/GetEmployeeAggregatedWages';

export const useCreateEmploymentEventCheckpointWage = () => {
  const apolloMutation = useApolloMutation();

  return (request: CreateEmploymentEventCheckpointWageInput, employerId: string) =>
    apolloMutation(
      CREATE_EMPLOYMENT_EVENT_CHECKPOINT_WAGE,
      employerId,
      {
        request,
      },
      [GET_EMPLOYEE_AGGREGATED_WAGES],
    );
};
