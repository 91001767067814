import { UPDATE_EMPLOYEE_CARD } from 'src/graphql/schema/mutation/employeecard/UpdateEmployeeCard';
import { UpdateEmployeeCard } from 'src/types/UpdateEmployeeCard';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_DATA_VALIDATION_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetDataValidationCheckpoints';

export const useUpdateEmployeeCardCheckpoints = () => {
  const apolloMutation = useApolloMutation();

  return (request: UpdateEmployeeCard, employerId: string) =>
    apolloMutation(UPDATE_EMPLOYEE_CARD, employerId, { request }, [GET_DATA_VALIDATION_CHECKPOINTS]);
};
