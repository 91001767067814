import { Option, Select } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Month } from 'src/types/Month';
import { convertMonthEnumToStringWithZeroes, useMonthOptions } from 'src/util/date/Months';
import { useYearOptions } from 'src/util/date/Years';
import './period-input.scss';

interface PeriodInputProps {
  selectedMonth: Option<Month>;
  setSelectedMonth: React.Dispatch<React.SetStateAction<Option<Month>>>;
  selectedYear: Option<number>;
  setSelectedYear: React.Dispatch<React.SetStateAction<Option<number>>>;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<string>>;
}

const PeriodInput = ({
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear,
  setSelectedPeriod,
}: PeriodInputProps) => {
  const { t } = useTranslation();
  const monthOptions = useMonthOptions();
  const yearsOptions = useYearOptions();

  const handlePeriodChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedMonth(monthOption);
    setSelectedYear(yearOption);

    if (monthOption && yearOption) {
      const period = yearOption.label + convertMonthEnumToStringWithZeroes(monthOption.value);
      setSelectedPeriod(period);
    }
  };

  return (
    <div className="period-input__period__selects">
      <Select
        placeholder={!selectedMonth ? t('common:month').toLowerCase() : t(`common:months.${selectedMonth.value}`)}
        options={monthOptions}
        onChange={(option: Option<Month>) => handlePeriodChange(option, selectedYear)}
        selected={selectedMonth}
        alphabetical={false}
        data-testid="dropdown-select-month"
        small
      />
      <Select
        placeholder={!selectedYear ? t('common:year').toLowerCase() : selectedYear.value.toString()}
        options={yearsOptions}
        onChange={(option: Option<number>) => handlePeriodChange(selectedMonth, option)}
        selected={selectedYear}
        data-testid="dropdown-select-year"
        small
      />
    </div>
  );
};

export default PeriodInput;
