import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListGroup, Pill, Tab, Tabs } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase';
import { EmployeeInsurance } from 'src/types/insurance/Insurance';
import { EmployeeInsuranceDetailsDrawerTab } from 'src/types/tabs/EmployeeInsuranceDetailsDrawerTab';
import { stringOrBlank } from 'src/util/StringUtil';
import DrawerModal from 'src/components/Common/DrawerModal';
import ListProperty from 'src/components/Common/ListProperty';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import ReplacingInsurancesBanners from './ReplacingInsurancesBanners';
import './insurance-details-drawer.scss';

interface InsuraceDetailsDrawerProps {
  drawerOpen: boolean;
  onClose: () => void;
  employeeInsurance: EmployeeInsurance;
  replaceSelectedInsurance: (employeeInsurance: EmployeeInsurance) => void;
  employeeInsurances: EmployeeInsurance[];
}

const InsuranceDetailsDrawer: FC<InsuraceDetailsDrawerProps> = ({
  employeeInsurance,
  onClose,
  drawerOpen,
  replaceSelectedInsurance,
  employeeInsurances,
}) => {
  const [currentTab, setCurrentTab] = useState<EmployeeInsuranceDetailsDrawerTab>(
    EmployeeInsuranceDetailsDrawerTab.ABOUT,
  );
  const { t } = useTranslation();

  const { benefitType, insuranceNumber, agreementNumber } = employeeInsurance.insurance;
  const agreedProduct = employeeInsurance.insurance.agreedProduct;
  const insuranceProviderName = stringOrBlank(employeeInsurance?.insurance?.insuranceProvider?.legalName);

  return (
    <DrawerModal overlayBackground overlayOnClose open={drawerOpen} onClose={onClose}>
      <DrawerBase
        title={insuranceProviderName}
        subTitle={<Pill label={t(`benefitTypes:${benefitType}`)} />}
        onClose={onClose}
      >
        <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
          <Tab title={t('common:about')}>
            <div className="insurance-details-drawer">
              <ReplacingInsurancesBanners
                employeeInsurance={employeeInsurance}
                employeeInsurances={employeeInsurances}
                replaceSelectedInsurance={replaceSelectedInsurance}
              />
              <ListGroup variant="inline-edit">
                <ListProperty
                  label={t('insurances:insurance-provider')}
                  value={insuranceProviderName}
                  data-testid="insurance-details-drawer__insurance-provider"
                />
                <ListProperty
                  label={t('insurances:benefit-type')}
                  value={t(`benefitTypes:${employeeInsurance.insurance.benefitType}`)}
                  data-testid="insurance-details-drawer__benefit-type"
                />
                <ListProperty
                  label={t('insurances:Försäkringstyp')}
                  value={
                    employeeInsurance.insurance.insuranceTypeMappings.length === 1
                      ? employeeInsurance.insurance.insuranceTypeMappings[0]?.name
                      : employeeInsurance.insurance.insuranceTypeMappings
                          .map((insuranceTypeMapping) => insuranceTypeMapping?.name)
                          .join(', ')
                  }
                  data-testid="insurance-details-drawer__insurance-type-mapping"
                />
                <ListProperty
                  label={t('insurances:agreed-product')}
                  value={stringOrBlank(agreedProduct?.name)}
                  data-testid="insurance-details-drawer__agreed-product"
                />
                <ListProperty
                  label={t('insurances:full-employability-decision.required')}
                  value={t(
                    `insurances:certify-insurance-product-condition.${agreedProduct?.certifyInsuranceProductCondition}`,
                  )}
                  data-testid="insurance-details-drawer__full-employability-decision"
                />
                <ListProperty
                  label={t('insurances:insurance-number')}
                  value={stringOrBlank(insuranceNumber)}
                  data-testid="insurance-details-drawer__insurance-number"
                />
                <ListProperty
                  label={t('insurances:agreement-number')}
                  value={stringOrBlank(agreementNumber)}
                  data-testid="insurance-details-drawer__agreement-number"
                />
              </ListGroup>
              <ListGroup variant="inline-edit">
                {/* TODO: Fix this */}
                <ListProperty
                  label={t('insurances:status-category')}
                  value={t(
                    `insurances:insurance-status-category.${employeeInsurance.insurance.insuranceStatus.statusCategory}`,
                  )}
                  data-testid="insurance-details-drawer__status-category"
                />
                <ListProperty
                  label={t('insurances:status')}
                  value={t(`insurances:insurance-status.${employeeInsurance.insurance.insuranceStatus.status}`)}
                  data-testid="insurance-details-drawer__status"
                />
                <ListProperty
                  label={t('insurances:latest-status-change')}
                  value={stringOrBlank(
                    formatDateTimeString(employeeInsurance.insurance.insuranceStatus.latestStatusChange),
                  )}
                  data-testid="insurance-details-drawer__latest-status-change"
                />
              </ListGroup>
            </div>
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
};

export default InsuranceDetailsDrawer;
