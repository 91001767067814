import { REMOVE_EMPLOYER_FROM_GROUP } from 'src/graphql/schema/mutation/group/RemoveEmployerFromGroup';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { useApolloMutation } from 'src/service/graphql/Mutation';

export const useRemoveEmployerFromGroup = () => {
  const apolloMutation = useApolloMutation();

  const removeEmployerFromGroup = (groupId: string, employerId: string) =>
    apolloMutation(REMOVE_EMPLOYER_FROM_GROUP, '', { groupId, employerId }, [GET_GROUPS]);

  return removeEmployerFromGroup;
};
