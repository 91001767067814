import { Button, Icon } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './filter-modal-button.scss';

interface FilterModalButtonProps {
  className?: string;
  onClick: () => void;
}

function FilterModalButton({ className, onClick }: FilterModalButtonProps) {
  const { t } = useTranslation();

  return (
    <div className={className ? className : 'filter-modal-button'}>
      <Button
        type="floating-action-button"
        icon={<Icon type={'Sliders'} stroke="white" />}
        label={t('common:filter')}
        onClick={onClick}
        data-cy="filter-button"
      />
    </div>
  );
}

export default FilterModalButton;
