import { gql } from '@apollo/client';

export const UPDATE_EMPLOYER_WAGE_TYPE = gql`
  mutation UpdateEmployerWageType($employerWageTypeId: UUID!, $request: UpdateEmployerWageTypeRequest!) {
    updateEmployerWageType(employerWageTypeId: $employerWageTypeId, request: $request) {
      id
      name
      wageType
      extentCalculation
      negateIncomingAmount
    }
  }
`;
