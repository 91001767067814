import { InlineEdit, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import React from 'react';
import { reportingDetailLevels } from 'src/types/contract/ContractAgreedProduct';

interface DefaultReportingDetailLevelInputProps {
  agreedProductRequest: ContractAgreedProductRequest;
  onChange: (key: string, value: string) => void;
}

export const DefaultReportingDetailLevelInput = ({
  agreedProductRequest,
  onChange,
}: DefaultReportingDetailLevelInputProps) => {
  const { t } = useTranslation();
  const onDefaultReportingDetailLevelChange = (defaultReportingDetailLevel: string) => {
    onChange('defaultReportingDetailLevel', defaultReportingDetailLevel);
  };

  const reportingDetailLevelOptions = reportingDetailLevels.map((reportingDetailLevel) => ({
    label: t(`reportingDetailLevels:${reportingDetailLevel}`),
    value: reportingDetailLevel,
  }));

  return (
    <InlineEdit label={t('agreementRegistrySettings:drawer:tabs:default-reporting-level')} bold>
      <Select
        small
        disabled={agreedProductRequest.reportingRuleConfigurationId == null}
        placeholder={t('common:select')}
        options={reportingDetailLevelOptions}
        selected={reportingDetailLevelOptions.find(
          (option) => option.value === agreedProductRequest.defaultReportingDetailLevel,
        )}
        onChange={(option: Option<string>) => onDefaultReportingDetailLevelChange(option.value)}
        data-cy="default-reporting-detail-level-dropdown"
      />
    </InlineEdit>
  );
};

export default DefaultReportingDetailLevelInput;
