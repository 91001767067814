import { gql } from '@apollo/client';

export const ACCRUAL_RULE = gql`
  query GetAccrualRule($employerId: UUID!) {
    accrualRule(employerId: $employerId) {
      id
      enabled
      salaryTaxEnabled
      threshold
      accrualDeviations {
        id
        insuranceTypeMapping {
          id
          name
          benefitType
          insuranceProvider {
            id
            legalName
          }
        }
      }
    }
  }
`;
