import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Employer } from 'src/types/employer/Employer';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { filterEmployer } from 'src/util/EmployeeFilter';
import { createOptions } from 'src/util/OptionUtil';
import { Option } from 'fgirot-k2-ui-components';

const useEmployerFilterProps = (employers: Employer[]) => {
  const { t } = useTranslation();
  const [selectedEmployers, setSelectedEmployers] = useState<Option<Employer>[]>([]);

  return {
    placeholder: t('employeeFilter:all-employers'),
    options: createOptions(employers, null, null, 'legalName'),
    selected: selectedEmployers,
    onChange: (input: Option<Employer>[]): void => {
      setSelectedEmployers([...input]);
    },
    filter: (employee: InsuranceEventReportEmployeeResponse) => filterEmployer(employee.employee, selectedEmployers),
  };
};

export default useEmployerFilterProps;
