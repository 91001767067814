import { Accordion, Select, ListItem, Option } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingComponentType } from 'src/types/accountingunit/AccountingComponent';
import CreateAccountingComponentValue from './CreateAccountingComponentValue';
import { useUpdateAccountingComponentType } from 'src/service/accountingunit/AccountingComponent';
import { useParams } from 'react-router-dom';
import AccountingComponentTypeAccordionHeader from './AccountingComponentTypeAccordionHeader';

interface AccountingComponentTypeAccordionProps {
  accountingComponentType: AccountingComponentType;
}

const AccountingComponentTypeAccordion: FC<AccountingComponentTypeAccordionProps> = ({ accountingComponentType }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const updateAccountingComponentType = useUpdateAccountingComponentType();
  const options = [
    { value: 'COST_CENTRE', label: t(`economySettings:wage-file-accounting-components.COST_CENTRE`) },
    { value: 'COST_OBJECT', label: t(`economySettings:wage-file-accounting-components.COST_OBJECT`) },
  ];

  const handleWageTypeComponentChange = (option: Option<string>) =>
    updateAccountingComponentType(
      { accountingComponentTypeId: accountingComponentType.id, wageFileAccountingComponentType: option.value },
      employerId,
    );

  return (
    <li>
      <Accordion
        data-cy={`accounting-component-accordion-${accountingComponentType.name}`}
        key={`AccountingComponentType-${accountingComponentType.id}`}
        header={<AccountingComponentTypeAccordionHeader accountingComponentType={accountingComponentType} />}
        className="accounting-component-type__accordion__list__item"
      >
        <div className="accounting-component-type__accordion__list__item__inner-list">
          <Select
            label={t('economySettings:accounting-components-tab.accordion.mapping-to-wage-file-component')}
            placeholder={t(
              'economySettings:accounting-components-tab.accordion.select-wage-type-component-placeholder',
            )}
            options={options}
            selected={
              accountingComponentType.wageFileAccountingComponentType
                ? {
                    label: t(
                      `economySettings:wage-file-accounting-components.${accountingComponentType.wageFileAccountingComponentType}`,
                    ),
                    value: accountingComponentType.wageFileAccountingComponentType,
                  }
                : undefined
            }
            onChange={handleWageTypeComponentChange}
          />
          <ul>
            {accountingComponentType.accountingComponentValues.map((value) => (
              <ListItem data-cy={`accounting-component-value`} key={`AccountingComponentValue-${value.id}`}>
                {value.name}
              </ListItem>
            ))}
          </ul>
          <CreateAccountingComponentValue accountingComponentType={accountingComponentType} />
        </div>
      </Accordion>
    </li>
  );
};

export default AccountingComponentTypeAccordion;
