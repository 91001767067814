import { gql } from '@apollo/client';

export const CREATE_ACCOUNTING_COMPONENT_VALUE = gql`
  mutation CreateAccountingComponentValue($request: CreateAccountingComponentValueInput) {
    createAccountingComponentValue(request: $request) {
      id
      name
    }
  }
`;
