import { Button, Card, CardAction, ErrorSection, Icon, Modal, ToastContext } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import './unhandled-error-component.scss';
import { useContext, useEffect } from 'react';

const UnhandledErrorComponent = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}) => {
  const { t } = useTranslation();
  const { displayToast } = useContext(ToastContext);

  useEffect(() => {
    displayToast('error', t('common:error'), t('common:unhandled-error'));
  }, [error]);

  return (
    <div className="unhandled-error">
      <Modal overlayBackground centered open={true} onClose={resetErrorBoundary}>
        <Card
          defaultClose
          icon={<Icon type="Alert" className="unhandled-error__icon" />}
          title={t('common:unhandled-error')}
          onClose={resetErrorBoundary}
          error
        >
          <ErrorSection className="unhandled-error__error-section">
            <code>
              {error.message}
              <br />
              {error.stack}
            </code>
          </ErrorSection>
          <CardAction>
            <Button label={t('common:unhandled-error-retry')} onClick={resetErrorBoundary} />
            <Button
              label={t('common:unhandled-error-copy-to-clipboard')}
              onClick={() => navigator.clipboard.writeText(`${error.message}\n${error.stack}`)}
            />
          </CardAction>
        </Card>
      </Modal>
    </div>
  );
};

export default UnhandledErrorComponent;
