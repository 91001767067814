import { CREATE_POLICY_EMPLOYMENT_GROUP } from 'src/graphql/schema/mutation/policy/CreatePolicyEmploymentGroup';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateEmploymentGroup = () => {
  const apolloMutation = useApolloMutation();

  const createEmploymentGroup = async (employerId: string, policyId, employmentGroupName: string) =>
    apolloMutation(
      CREATE_POLICY_EMPLOYMENT_GROUP,
      employerId,
      {
        employerId,
        policyId,
        employmentGroupName,
      },
      [GET_POLICY, GET_EMPLOYER],
    );

  return createEmploymentGroup;
};
