import { Option } from 'fgirot-k2-ui-components';
import { TFunction } from 'i18next';

export const createOptions = <T>(values: T[], t?: TFunction, translation?: string, labelField?: string): Option<T>[] =>
  values.map((value) => createOption<typeof value>(value, t, translation, labelField));

export const createOptionsFromList = <T>(list: T[], valueField: string, labelField: string): Option<string>[] =>
  list
    ? list.map((item) => {
        return { label: item[labelField], value: item[valueField] };
      })
    : [];

export const createOption = <T>(value: T, t?: TFunction, translation?: string, labelField?: string): Option<T> => {
  const label = getLabel<typeof value>(value, t, translation, labelField);
  return { label, value };
};

const getLabel = <T>(value: T, t?: TFunction, translation?: string, labelField?: string): string => {
  return t
    ? translation
      ? labelField
        ? t(`${translation}:${value[labelField]}`)
        : t(`${translation}:${value}`)
      : labelField
      ? (value[labelField] as unknown as string)
      : (value as unknown as string)
    : labelField
    ? (value[labelField] as unknown as string)
    : (value as unknown as string);
};

export const createPageSizeOptions = (pageSizeOptions: number[]): Option<number>[] =>
  pageSizeOptions.map((pageSize) => {
    return { label: pageSize.toString(), value: pageSize };
  });
