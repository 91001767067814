import { Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PeriodStatus } from 'src/types/reporting/ReportingPeriodSummary';

interface PeriodStatusPillProps {
  periodStatus: PeriodStatus;
}

function PeriodStatusPill({ periodStatus }: PeriodStatusPillProps) {
  const { t } = useTranslation();

  const label = t(`report:overview.period-status.${periodStatus}`);

  switch (periodStatus) {
    case 'NOT_STARTED':
      return <Pill type="heads-up" label={label} />;
    case 'ONGOING':
      return <Pill type="neutral" label={label} />;
    case 'FINISHED':
      return <Pill type="success" label={label} />;
    default:
      return null;
  }
}

export default PeriodStatusPill;
