import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import EmployerIndividualChoiceAboutTab from './EmployerIndividualChoiceAboutTab';

interface EmployerIndividualChoiceDrawerProps {
  open: boolean;
  onClose: () => void;
  employerId: string;
  employerIndividualChoice: EmployerIndividualChoice;
}

function EmployerIndividualChoiceDrawer({
  open,
  onClose,
  employerId,
  employerIndividualChoice,
}: EmployerIndividualChoiceDrawerProps) {
  const { t } = useTranslation();

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        title={t(`individualChoiceTypes:${employerIndividualChoice.type}`)}
        subTitle={t('account:employers-tab.reporting-settings.individual-choices-tab.employer-individual-choice')}
        onClose={onClose}
      >
        <Tabs>
          <Tab title={t('common:about')}>
            <EmployerIndividualChoiceAboutTab
              employerId={employerId}
              employerIndividualChoice={employerIndividualChoice}
            />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
}

export default EmployerIndividualChoiceDrawer;
