import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import './users-drawer.scss';
import UsersDrawerAboutTab from './UsersDrawerAboutTab';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import { User } from 'src/types/user/User';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_USER } from 'src/graphql/schema/query/user/GetUser';

interface UsersDrawerProps {
  open: boolean;
  onClose: () => void;
  userId: string;
}

const UsersDrawer: React.FC<UsersDrawerProps> = ({ open, onClose, userId }) => {
  const { t } = useTranslation();
  const breadcrumbs = [t('permission:breadcrumbs.permission'), t('permission:breadcrumbs.users')];

  return (
    <div className="users-drawer">
      <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
        <QueryWrapper query={GET_USER} options={{ variables: { userId } }}>
          {(user: User) => (
            <DrawerBase
              onClose={onClose}
              title={
                <>
                  <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
                  <Typography variant="h3" component="h3" bold className="users-drawer__title">
                    {`${user.firstName ?? ''} ${user.lastName ?? ''}`}
                  </Typography>
                </>
              }
            >
              <Tabs>
                <Tab title={`${t('permission:drawer-tabs.about-label')}`}>
                  <UsersDrawerAboutTab user={user} />
                </Tab>
              </Tabs>
            </DrawerBase>
          )}
        </QueryWrapper>
      </DrawerModal>
    </div>
  );
};

export default UsersDrawer;
