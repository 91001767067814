import { UPDATE_POLICY_BENEFIT_KUB } from 'src/graphql/schema/mutation/policy/UpdatePolicyBenefitKub';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdatePolicyBenefitKub = () => {
  const apolloMutation = useApolloMutation(false);

  const updatePolicyBenefitKub = (policyId: string, benefitPackageId: string, kubType: string, employerId: string) =>
    apolloMutation(UPDATE_POLICY_BENEFIT_KUB, employerId, { policyId, benefitPackageId, kubType }, [GET_POLICY]);

  return updatePolicyBenefitKub;
};
