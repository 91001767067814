import React, { useEffect, useState } from 'react';
import { useApolloQueryEmployeeAggregatedWages } from 'src/service/employeecard/GetEmployeeAggregatedWages';
import { EmployeeCard } from 'src/types/EmployeeCard';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { useApolloQueryEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { EmployerWageType, WageType } from 'src/types/employer/EmployerWageType';
import UpdateWageTypeSumsList from './UpdateWageTypeSumsList';
import UpdateWageTypeSum from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointDetails/UpdateWageTypeSums/UpdateWageTypeSum';
import { ExaminationEmployeeDrawerState } from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/DrawerState';
import { Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import ApolloServiceWrapper from 'src/components/Common/ApolloServiceWrapper';

interface UpdateWageTypeSumsProps {
  employeeCard: EmployeeCard;
  employerId: string;
  checkpoint: EmploymentEventCheckpoint;
  onNavigateDrawerState: (state: ExaminationEmployeeDrawerState) => void;
  currentNavigationState: ExaminationEmployeeDrawerState;
}

const UpdateWageTypeSums: React.FC<UpdateWageTypeSumsProps> = ({
  employeeCard,
  employerId,
  checkpoint,
  onNavigateDrawerState,
  currentNavigationState,
}) => {
  const { t } = useTranslation();
  const [selectedEmployerWageType, setSelectedEmployerWageType] = useState<EmployerWageType>(null);

  const employerWagesService = useApolloQueryEmployerWageTypes(employerId);

  useEffect(() => {
    if (currentNavigationState == ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED) setSelectedEmployerWageType(null);
  }, [currentNavigationState]);

  const wagesWithInvalidSums = checkpoint.additionalData.wageTypesWithInvalidSum;
  const aggregatedWagesService = useApolloQueryEmployeeAggregatedWages(
    employeeCard.employeeId,
    employeeCard.period,
    employeeCard.period,
    wagesWithInvalidSums,
    false,
  );

  const aggregatedWages = aggregatedWagesService.status == 'loaded' ? aggregatedWagesService.payload : [];
  const employerWages = employerWagesService.status == 'loaded' ? employerWagesService.payload : [];

  const handleSelectWageType = (wageType: WageType) => {
    setSelectedEmployerWageType(employerWages.find((employerWage) => employerWage.wageType == wageType));
    onNavigateDrawerState(ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED_DEPTH_1);
  };

  if (!selectedEmployerWageType)
    return (
      <ApolloServiceWrapper service={[aggregatedWagesService, employerWagesService]}>
        <UpdateWageTypeSumsList
          aggregatedWages={aggregatedWages}
          employerWages={employerWages}
          onSelectWageType={handleSelectWageType}
          checkpoint={checkpoint}
        />
      </ApolloServiceWrapper>
    );

  const selectedAggregatedWage = aggregatedWages?.find((w) => w.employerWageTypeId == selectedEmployerWageType.id);

  return (
    <ApolloServiceWrapper service={[aggregatedWagesService, employerWagesService]}>
      <div className={styles.container}>
        <Typography bold variant={'body2'}>
          {t('wageTypes:' + selectedEmployerWageType.wageType)}
        </Typography>
      </div>
      <UpdateWageTypeSum
        aggregatedWage={selectedAggregatedWage}
        wageType={selectedEmployerWageType.wageType}
        employerId={employerId}
        employeeCardId={employeeCard.id}
        currentNavigationState={currentNavigationState}
        onNavigate={onNavigateDrawerState}
        checkpointId={checkpoint.id}
      />
    </ApolloServiceWrapper>
  );
};

export default UpdateWageTypeSums;
