import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import React, { useState } from 'react';
import { InsuranceEventsOverviewDrawerTab } from 'src/types/tabs/InsuranceEventsOverviewDrawerTab';
import ReportingEventsTab from './ReportingEventsTab';
import './reporting-events-overview-drawer.scss';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';
import { PersonReportingEventsResponse } from 'src/types/PersonReportingEventsResponse';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';

interface ReportingEventsOverviewDrawerProps {
  reportingEventsData: PersonReportingEventsResponse;
  onClose: () => void;
  onSelectEvent: (event: ReportingEvent) => void;
}

const ReportingEventsOverviewDrawer = ({
  reportingEventsData,
  onSelectEvent,
  onClose,
}: ReportingEventsOverviewDrawerProps) => {
  const [currentTab, setCurrentTab] = useState<InsuranceEventsOverviewDrawerTab>(
    InsuranceEventsOverviewDrawerTab.INSURANCE_EVENTS,
  );
  const { t } = useTranslation();
  const fullName = formatEmployeeFullName(reportingEventsData.employees[0]);
  const reportingEventsTabTitle = `${t('reporting:insurance-events.events')} (${
    reportingEventsData.reportingEvents.length
  })`;

  const handleSelectEvent = (event: ReportingEvent) => {
    onSelectEvent(event);
  };

  return (
    <DrawerBase
      onClose={onClose}
      title={fullName}
      subTitle={
        <div className="reporting-events-overview-drawer__sub-title">
          <Pill label={reportingEventsData.personNumber} />
          {reportingEventsData.employees.map((e) => (
            <>
              <Pill label={`# ${e.employmentNumber}`} />
              <Typography variant="body2">
                {t(
                  `employmentCategories:${
                    reportingEventsData.reportingEvents.find((i) => i?.employeeCard.employmentCategory)?.employeeCard
                      .employmentCategory
                  }`,
                )}
              </Typography>
            </>
          ))}
        </div>
      }
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={reportingEventsTabTitle}>
          <ReportingEventsTab reportingEventsData={reportingEventsData} onSelectEvent={handleSelectEvent} />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default ReportingEventsOverviewDrawer;
