import { Button, Icon, Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { Employee } from 'src/types/Employee';
import { stringOrBlank } from 'src/util/StringUtil';

interface ReportEmployeeDrawerProps {
  open: boolean;
  onClose: () => void;
  employee: Employee;
  children: JSX.Element;
  infoPageVisible?: boolean;
  onInfoPageClose?: () => void;
}

const ReportEmployeeDrawer = ({
  open,
  onClose,
  employee,
  children,
  infoPageVisible,
  onInfoPageClose,
}: ReportEmployeeDrawerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(`/employees/${employee.id}`);
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        leftButton={
          infoPageVisible ? (
            <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onInfoPageClose} />
          ) : (
            <Button
              type="link"
              label={t('report:employee-drawer.go-to-profile')}
              iconPlacement="left"
              icon={<Icon type="User" className="employee-drawer__button__icon" />}
              onClick={navigateToProfile}
            />
          )
        }
        onClose={onClose}
        title={`${stringOrBlank(employee?.firstName)} ${stringOrBlank(employee?.lastName)}`}
        subTitle={stringOrBlank(employee?.personNumber)}
      >
        {infoPageVisible ? (
          <>{children}</>
        ) : (
          <Tabs>
            <Tab title={t('report:employee-drawer.overview')}>{children}</Tab>
          </Tabs>
        )}
      </DrawerBase>
    </DrawerModal>
  );
};

export default ReportEmployeeDrawer;
