import { gql } from '@apollo/client';

export const UPSERT_EMPLOYER_WAGE_FILE_SETTING = gql`
  mutation UpsertEmployerWageFileSetting($employerId: UUID!, $request: EmployerWageFileSettingRequest!) {
    upsertEmployerWageFileSetting(employerId: $employerId, request: $request) {
      salarySystem
      wageFileFormat
      expectedNoOfWageFiles
    }
  }
`;
