import {
  CREATE_BENEFIT_RULE_BLOCK,
  CREATE_EMPLOYMENT_GROUP_RULE_BLOCK,
} from 'src/graphql/schema/mutation/policy/CreateAffiliationRuleBlock';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateEmploymentGroupRuleBlock = () => {
  const apolloMutation = useApolloMutation();

  const createEmploymentGroupRuleBlock = (employerId: string, employmentGroupId: string) =>
    apolloMutation(
      CREATE_EMPLOYMENT_GROUP_RULE_BLOCK,
      employerId,
      {
        employmentGroupId,
      },
      [GET_POLICY],
    );

  return createEmploymentGroupRuleBlock;
};

export const useCreateBenefitRuleBlock = () => {
  const apolloMutation = useApolloMutation();

  const createBenefitRuleBlock = (employerId: string, benefitPackageId: string) =>
    apolloMutation(
      CREATE_BENEFIT_RULE_BLOCK,
      employerId,
      {
        benefitPackageId,
      },
      [GET_POLICY],
    );

  return createBenefitRuleBlock;
};
