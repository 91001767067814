import { CacheOperation } from 'src/types/CacheOperation';
import { Employer } from 'src/types/employer/Employer';
import { unique } from 'src/util/ArrayUtil';

export const employers = {
  keyArgs: ['id'],

  // existing should always be overided when we add, not merged with incoming
  merge(existing: Employer[] = [], incoming: CacheOperation<Employer[]>) {
    return incoming.operation === 'ADD'
      ? unique(incoming.value, 'id')
      : existing.filter((employer) => employer.id !== incoming.id);
  },
};
