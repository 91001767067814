import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteDraftEmployee } from 'src/service/employee/DeleteEmployeeDraft';

interface DeleteDraftEmployeeModalProps {
  open: boolean;
  handleClose: () => void;
  employeeId: string;
  employerId: string;
}

function DeleteDraftEmployeeModal({ open, handleClose, employeeId, employerId }: DeleteDraftEmployeeModalProps) {
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const deleteEmployeeDraft = useDeleteDraftEmployee();

  const { t } = useTranslation();

  const handleDeleteDraft = async () => {
    setDeleteButtonDisabled(true);
    await deleteEmployeeDraft(employeeId, employerId);
    setDeleteButtonDisabled(false);
    handleClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleClose}>
      <Card
        icon={<Icon type="Trash" stroke="#ae2336" />}
        title={t('employees:actions.delete')}
        defaultClose
        onClose={handleClose}
      >
        <Typography component="span">{t('employees:actions.delete-draft-body')}</Typography>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleClose} />
          <Button
            disabled={deleteButtonDisabled}
            type="warning"
            label={t('employees:actions.delete')}
            onClick={handleDeleteDraft}
            data-cy="delete-draft-employee-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
}

export default DeleteDraftEmployeeModal;
