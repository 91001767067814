import { Button, Card, Switch, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextInput from 'src/components/Common/TextInput';
import { useUpdateAccrualRule } from 'src/service/accountingunit/AccrualRule';
import { AccrualRule } from 'src/types/accountingunit/AccrualRule';
import AddAccrualRuleModal from './AddAccrualRuleModal';
interface AccrualRuleCardProps {
  accrualRule: AccrualRule;
}
const AccrualRuleCard: FC<AccrualRuleCardProps> = ({ accrualRule }) => {
  const { t } = useTranslation();
  const updateAccrualRule = useUpdateAccrualRule();
  const { employerId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const toggleAddAccrualRuleModal = () => setModalOpen((prev) => !prev);
  const [threshold, setThreshold] = useState(accrualRule?.threshold || 0);
  const onAccrualEnabledChange = (e: ChangeEvent<HTMLInputElement>) =>
    updateAccrualRule({ accrualRuleId: accrualRule.id, enabled: e.target.checked }, employerId);

  const handleThresholdUpdate = () => updateAccrualRule({ accrualRuleId: accrualRule.id, threshold }, employerId);

  const handleThresholdChange = (e: ChangeEvent<HTMLInputElement>) => setThreshold(e.target.valueAsNumber);

  const handleAccrualSalaryTaxChange = (e: ChangeEvent<HTMLInputElement>) =>
    accrualRule.enabled &&
    updateAccrualRule({ accrualRuleId: accrualRule.id, salaryTaxEnabled: e.target.checked }, employerId);

  return (
    <Card
      title={
        accrualRule
          ? t('economySettings:accounting-settings-tab.accrual-rule-cards.accrual-title-active')
          : t('economySettings:accounting-settings-tab.accrual-rule-cards.accrual-title-inactive')
      }
      className="accounting-settings-tab__card-content"
    >
      {accrualRule ? (
        <>
          <div className="accounting-settings-tab__card-content-row">
            <Typography variant="caption" className="accounting-settings-tab__card-content-label">
              {t('economySettings:accounting-settings-tab.accrual-rule-cards.use-accrual')}
            </Typography>
            <Switch data-cy="accrual-enabled-switch" toggled={accrualRule.enabled} onChange={onAccrualEnabledChange} />
          </div>
          <div className="accounting-settings-tab__card-content-row">
            <Typography variant="caption" className="accounting-settings-tab__card-content-label">
              {t('economySettings:accounting-settings-tab.accrual-rule-cards.use-accrual-salary-tax')}
            </Typography>
            <Switch
              data-cy="accrual-salary-tax-enabled-switch"
              disabled={!accrualRule.enabled}
              toggled={accrualRule.salaryTaxEnabled}
              onChange={handleAccrualSalaryTaxChange}
            />
          </div>
          <div className="accounting-settings-tab__card-content-row">
            <TextInput
              className="accounting-settings-tab__text-input"
              data-cy="accrual-threshold-input"
              variant="default"
              label={t('economySettings:accounting-settings-tab.accrual-rule-cards.threshold-label')}
              type="number"
              validationKey="amount"
              onBlur={handleThresholdUpdate}
              value={threshold.toString()}
              onChange={handleThresholdChange}
            />
          </div>
        </>
      ) : (
        <div className="accounting-settings-tab__card-content-row">
          <Button
            label={t('economySettings:accounting-settings-tab.accrual-rule-cards.add-accrual-rule')}
            onClick={toggleAddAccrualRuleModal}
          />
          <AddAccrualRuleModal open={modalOpen} onClose={toggleAddAccrualRuleModal} />
        </div>
      )}
    </Card>
  );
};

export default AccrualRuleCard;
