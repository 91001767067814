import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Icon, Typography } from 'fgirot-k2-ui-components';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import useUpdatePolicyAffiliationRule from '../../AffiliationRuleGroupDetails/UpdatePolicyAffiliationRuleFunctions';
import AffiliationRuleGroupDetails from '../../AffiliationRuleGroupDetails';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';
import { AffiliationRuleBlock } from 'src/types/policy/AffiliationRuleBlock';
import ScrollBase from 'src/components/Common/ScrollBase/ScrollBase';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';

interface RuleTypeDrawerProps {
  selectedRuleGroupType: AffiliationRuleGroupType;
  policyId: string;
  employerId: string;
  policyEntityState: PolicyState;
  policyEmploymentGroup: PolicyEmploymentGroup;
  selectedRuleBlock: AffiliationRuleBlock;
  onClose: () => void;
  onBackClick: () => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}

const RuleTypeDrawer: React.FunctionComponent<RuleTypeDrawerProps> = ({
  policyEmploymentGroup,
  policyId,
  employerId,
  policyEntityState,
  selectedRuleGroupType,
  selectedRuleBlock,
  onClose,
  onBackClick,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();
  const updateOrDeleteAffiliationRule = useUpdatePolicyAffiliationRule();

  const handleUpdateOrDeleteAffiliationRule = (request: UpdateOrDeleteAffiliationRuleRequest) => {
    return updateOrDeleteAffiliationRule(
      'EMPLOYMENT_GROUP',
      policyEmploymentGroup.id,
      employerId,
      selectedRuleBlock.id,
      request.ruleId,
      request.policyRuleType,
      request.ruleValues,
      request.ruleValueUnit,
      request.sourceType,
      request.sourceReference,
    );
  };

  const selectedRuleGroup = selectedRuleBlock.groupedAffiliationRules?.find(
    (group) => group.affiliationRuleGroupType === selectedRuleGroupType,
  ) || { affiliationRuleGroupType: selectedRuleGroupType, affiliationRules: [] };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="drawer-base__title-container">
        <Typography variant="h3" component="h3" bold>
          {t(`policyRules:ruleTypeGroup.${selectedRuleGroupType}.name`)}
        </Typography>

        <Typography variant="body2">{policyEmploymentGroup.employmentGroupName}</Typography>
      </div>
      <Typography variant="body2" component="p">
        {t(`policyRules:ruleTypeGroup.${selectedRuleGroupType}.info-text`)}
      </Typography>

      <ScrollBase maxHeightPercentage={70}>
        <AffiliationRuleGroupDetails
          onBackClick={onBackClick}
          employerId={employerId}
          policyId={policyId}
          selectedRuleGroupType={selectedRuleGroupType}
          policyEntityState={policyEntityState}
          selectedRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={handleUpdateOrDeleteAffiliationRule}
          employerIndividualChoices={employerIndividualChoices}
        />
      </ScrollBase>
    </Drawer>
  );
};

export default RuleTypeDrawer;
