import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { DocumentTemplate } from 'src/types/documents/DocumentTemplate';
import DocumentTemplateSearchInput from './DocumentTemplatesSearchInput';
import DocumentTemplatesTable from './DocumentTemplatesTable';
import { Customer } from 'src/types/customer/Customer';
import { GET_ASSIGNED_DOCUMENTS_TEMPLATES } from 'src/graphql/schema/query/documents/GetAssignedDocumentTemplates';
import DocumentTemplateDrawer from './DocumentTemplateDrawer';
import When from 'src/components/Common/When';
import { Banner } from 'fgirot-k2-ui-components';
import './document-template-tab.scss';

interface DocumentTemplatesTabProps {
  customer: Customer;
}

const DocumentTemplatesTab = ({ customer }: DocumentTemplatesTabProps) => {
  const { t } = useTranslation();
  const [searchFilter, setSearchFilter] = useState('');
  const handleSearchFilterChange = (e: ChangeEvent<HTMLInputElement>) => setSearchFilter(e.target.value);

  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(undefined);

  const handleOpenDrawer = (id: string) => {
    setSelectedTemplateId(id);
  };

  const handleCloseDrawer = () => {
    setSelectedTemplateId(undefined);
  };

  return (
    <QueryWrapper query={GET_ASSIGNED_DOCUMENTS_TEMPLATES} type="list">
      {(assignedDocumentTemplates: DocumentTemplate[]) => {
        return (
          <>
            <DocumentTemplateSearchInput
              searchValue={searchFilter}
              onSearchFilterChange={handleSearchFilterChange}
              searchPlaceholder={t('documents:document-templates-tab.search-placeholder')}
            />
            <When condition={assignedDocumentTemplates.length === 0}>
              <Banner
                className="document-template-tab__table"
                message={t('documents:document-templates-tab.banner-no-templates-info')}
                type="announcement"
              />
            </When>
            <When condition={assignedDocumentTemplates.length > 0}>
              <DocumentTemplatesTable
                documentFilterText={searchFilter}
                documentTemplates={assignedDocumentTemplates}
                onTemplateRowClick={handleOpenDrawer}
              />
            </When>
            <When condition={selectedTemplateId !== undefined}>
              <DocumentTemplateDrawer
                open={selectedTemplateId !== undefined}
                onClose={handleCloseDrawer}
                documentTemplate={assignedDocumentTemplates.find((template) => template.id === selectedTemplateId)}
                customer={customer}
              />
            </When>
          </>
        );
      }}
    </QueryWrapper>
  );
};

export default DocumentTemplatesTab;
