import { client } from 'src/graphql';
import { GET_INSURANCE_EVENT_INFOS } from 'src/graphql/schema/query/insurance/GetInsuranceEventInfos';
import { InsuranceEventInfo } from 'src/types/InsuranceEventInfo';

export const fetchInsuranceEventInfos = async (insuranceEventIds: string[]): Promise<InsuranceEventInfo[]> => {
  const {
    data: { insuranceEventInfos },
  } = await client.query({
    query: GET_INSURANCE_EVENT_INFOS,
    variables: { insuranceEventIds },
  });
  return insuranceEventInfos;
};
