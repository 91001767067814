import { useTranslation } from 'react-i18next';
import { EventsPerReportingType } from 'src/types/reporting/ReportingPeriodSummary';
import { reportingTypes } from 'src/types/reporting/ReportingType';

export const useEventsPerReportingTypeDataPoints = () => {
  const { t } = useTranslation();

  const getEmptyDataPoints = () =>
    reportingTypes
      .map((reportingType) => ({
        label: t(`reportingTypes:${reportingType}`),
        value: 0,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

  const generateDataPoints = (eventsPerReportingType: EventsPerReportingType[]) =>
    eventsPerReportingType
      .map(({ reportingType, numberOfInsuranceEvents }) => ({
        label: t(`reportingTypes:${reportingType}`),
        value: numberOfInsuranceEvents,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

  return { getEmptyDataPoints, generateDataPoints };
};
