import { InlineEdit, ListGroup, Select, Option } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RuleValueUnit } from 'src/types/policy/AffiliationRule';
import { useSourceReferenceOptions } from './SourceReferenceHook';

interface CalculationVariableListGroupProps {
  employerId: string;
  policyId: string;
  locked: boolean;
  sourceType: string;
  sourceReference: string;
  ruleValueUnit?: RuleValueUnit;
  handleCalculationValuesChange: (input: string, field: string) => void;
}

const CalculationVariableListGroup: FC<CalculationVariableListGroupProps> = ({
  employerId,
  policyId,
  locked,
  sourceType,
  sourceReference,
  ruleValueUnit,
  handleCalculationValuesChange,
}) => {
  const { t } = useTranslation();
  const sourceTypeOptions: Option<string>[] = [
    { label: 'Lönetyp', value: 'WAGE_TYPE' },
    { label: 'PGL', value: 'PGL' },
  ];

  const getSelectedSourceType = () => {
    return sourceTypeOptions.find((option) => option.value === sourceType);
  };

  const ruleValueUnitOptions = [
    { label: t(`policyRules:ruleValues.valueUnit.SEK`), value: RuleValueUnit.SEK },
    { label: t(`policyRules:ruleValues.valueUnit.IBB`), value: RuleValueUnit.IBB },
  ];

  const sourceReferenceOptions = useSourceReferenceOptions(sourceType, employerId, policyId);

  const handleSourceTypeChange = (option: Option<string>) => {
    handleCalculationValuesChange(option.value, 'sourceType');
  };

  const handleSourceReferenceChange = (option: Option<string>) => {
    handleCalculationValuesChange(option.value, 'sourceReference');
  };

  const handleRuleValueUnitChange = (option: Option<string>) => {
    handleCalculationValuesChange(option.value, 'ruleValueUnit');
  };

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant={'inline-edit'}>
        <InlineEdit label={t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.calculation-variables.type.label`)}>
          <Select
            disabled={locked}
            placeholder={t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.calculation-variables.type.placeholder`)}
            options={sourceTypeOptions}
            selected={getSelectedSourceType()}
            onChange={handleSourceTypeChange}
            data-cy="calculation-variable-list-group__dropdown-type"
            small
          />
        </InlineEdit>
        <InlineEdit label={t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.calculation-variables.reference.label`)}>
          <Select
            placeholder={t(
              `policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.calculation-variables.reference.placeholder`,
            )}
            options={sourceReferenceOptions}
            disabled={!sourceType || locked}
            selected={sourceReferenceOptions.find((option) => option.value === sourceReference)}
            onChange={handleSourceReferenceChange}
            data-cy="calculation-variable-list-group__dropdown-calculation"
            small
          />
        </InlineEdit>
        <InlineEdit label={t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.calculation-variables.unit.label`)}>
          <Select
            disabled={locked}
            placeholder={t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.calculation-variables.unit.placeholder`)}
            options={ruleValueUnitOptions}
            selected={ruleValueUnitOptions.find((option) => option.value === ruleValueUnit)}
            onChange={handleRuleValueUnitChange}
            data-cy="calculation-variable-list-group__dropdown-unit"
            small
          />
        </InlineEdit>
      </ListGroup>
    </div>
  );
};

export default CalculationVariableListGroup;
