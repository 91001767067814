import { ReportingEventsSelectableSearchValues } from '../../types/reporting/ReportingEventsSelectableSearchValues';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_REPORTING_EVENTS_SELECTABLE_SEARCH_VALUES } from 'src/graphql/schema/query/reporting/ReportingEventsSelectableSearchValues';

export const useReportingEventsSelectableSearchValues = (period: string): ReportingEventsSelectableSearchValues => {
  const [result, setResult] = useState<ReportingEventsSelectableSearchValues>();
  const { loading, error, data } = useQuery(GET_REPORTING_EVENTS_SELECTABLE_SEARCH_VALUES, {
    variables: { wageFilePeriod: period },
  });

  useEffect(() => {
    data && setResult(data.reportingEventsSelectableSearchValues);
  }, [loading, error, data]);

  return result;
};
