import { DELETE_EMPLOYER_KUB } from 'src/graphql/schema/mutation/employer/DeleteEmployerKub';
import { GET_EMPLOYER_KUBS } from 'src/graphql/schema/query/employer/GetEmployerKubs';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYER_PAY_TYPES } from 'src/graphql/schema/query/employer/GetEmployerPayTypes';

export const useDeleteEmployerKub = () => {
  const apolloMutation = useApolloMutation();

  const deleteEmployerKub = (employerKubId: string, employerId: string) =>
    apolloMutation(DELETE_EMPLOYER_KUB, employerId, { employerKubId }, [GET_EMPLOYER_KUBS, GET_EMPLOYER_PAY_TYPES]);

  return deleteEmployerKub;
};
