import { client } from 'src/graphql';
import { FINALIZE_CHECKPOINTS } from 'src/graphql/schema/mutation/checkpoint/FinalizeCheckpoints';
import { EmployeeReadyToFinalize } from 'src/types/EmployeeReadyToFinalize';

export const finalizeCheckpointsMutation = (
  employeeIds: string[],
  period: string,
  actionCategory: string,
  employerId: string,
) =>
  client.mutate({
    mutation: FINALIZE_CHECKPOINTS,
    variables: {
      employeeIds,
      period,
      actionCategory,
    },
    context: { employerId },
  });

export const finalizeCheckpoints = async (
  employees: EmployeeReadyToFinalize[],
  period: string,
  actionCategory: string,
) => {
  if (employees !== null && employees.length > 0) {
    const groupedEmployees: Map<string, string[]> = groupByEmployerId(employees);

    return Promise.all(
      Object.keys(groupedEmployees).map((employerId) => {
        return finalizeCheckpointsMutation(groupedEmployees[employerId], period, actionCategory, employerId);
      }),
    );
  }
};

const groupByEmployerId = (employees: EmployeeReadyToFinalize[]): Map<string, string[]> => {
  return employees.reduce((result, employee) => {
    (result[employee.employerId] ||= []).push(employee.employeeId);
    return result;
  }, {} as Map<string, string[]>);
};
