import { ADD_ROLE_TO_GROUP } from 'src/graphql/schema/mutation/group/AddRoleToGroup';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { GET_USERS } from 'src/graphql/schema/query/user/GetUsers';

export const useAddRoleToGroup = () => {
  const apolloMutation = useApolloMutation();

  const addRoleToGroup = (groupId: string, roleId: string) =>
    apolloMutation(ADD_ROLE_TO_GROUP, '', { groupId, roleId }, [GET_GROUPS, GET_USERS]);

  return addRoleToGroup;
};
