import { useApolloMutation } from '../graphql/Mutation';
import { GET_INVOICE } from 'src/graphql/schema/query/invoice/GetInvoice';
import { CREATE_PAYMENT_DUE_DATE } from 'src/graphql/schema/mutation/invoice/CreatePaymentDueDate';
import { CreatePaymentDueDateInput } from 'src/types/invoice/CreatePaymentDueDateInput';
import { GET_PAYMENT_DUE_DATES } from 'src/graphql/schema/query/invoice/GetPaymentDueDates';

export const useCreatePaymentDueDate = () => {
  const apolloMutation = useApolloMutation();
  const createPaymentDueDate = (invoiceHeaderId: string, request: CreatePaymentDueDateInput, employerId: string) =>
    apolloMutation(CREATE_PAYMENT_DUE_DATE, employerId, { invoiceHeaderId, request }, [
      GET_PAYMENT_DUE_DATES,
      GET_INVOICE,
    ]);
  return createPaymentDueDate;
};
