import { DrawerNavigation, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import './verify-drawer-insurance-events.scss';

interface VerifyDrawerInsuranceEventsProps {
  insuranceEvents: InsuranceEvent[];
  onSelectEvent: (event: InsuranceEvent) => void;
}

const VerifyDrawerInsuranceEventsTab: React.FC<VerifyDrawerInsuranceEventsProps> = ({
  insuranceEvents,
  onSelectEvent,
}) => {
  const { t } = useTranslation();

  return (
    <ListGroup variant={'drawer-navigation'} className="policy-employment-group-drawer__tabs__rule-list-group">
      {insuranceEvents.map((event) => (
        <DrawerNavigation
          key={event.insuranceEventId}
          label={
            <div className="insurance-events-tab__event-label">
              <Typography variant="body2" bold>
                {t(`reportingTypes:${event.reportingType}`)}
              </Typography>
              <Pill type="neutral" size="small" label={`${t(`benefitTypes:abbreviations.${event.benefitType}`)}`} />
              {event.insuranceProvider && (
                <Pill type="neutral" size="small" label={event.insuranceProvider.legalName} />
              )}
              <Typography variant="body2" className="verify-drawer-insurance-events__event-status">
                {t(`insuranceEventStatus:${event.status}`)}
              </Typography>
            </div>
          }
          value={event.eventFromDate ? event.eventFromDate : ''}
          onClick={() => onSelectEvent(event)}
        />
      ))}
    </ListGroup>
  );
};

export default VerifyDrawerInsuranceEventsTab;
