import { InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import './accounting-components-overview-tab.scss';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';

interface AccountingComponentsOverviewTabProps {
  employeeCard: AggregatedEmployeeCard;
}

const AccountingComponentsOverviewTab = ({ employeeCard }: AccountingComponentsOverviewTabProps) => {
  return (
    <div className="accounting-components-overview-tab">
      {employeeCard && (
        <div>
          {employeeCard?.accountingComponents.map((component, index) => {
            return (
              <div key={`AccountingComponentsOverviewTab-${component.id}-${index}`}>
                <div className="accounting-components-overview-tab__headings">
                  <Typography variant="body1" component="h3" bold>
                    {component.accountingComponentValue.accountingComponentType.name}
                  </Typography>
                  {component.subAccountingComponents.length > 0 && (
                    <Typography
                      className="accounting-components-overview-tab__right-heading"
                      variant="body1"
                      component="h3"
                      bold
                    >
                      {component.subAccountingComponents[0].accountingComponentValue.accountingComponentType.name}
                    </Typography>
                  )}
                </div>

                <ListGroup variant="inline-edit" className="accounting-components-overview-tab__list">
                  <InlineEdit
                    bold
                    label={
                      component?.accountingComponentValue.name +
                      ' ' +
                      (component.share === 100 ? '' : component?.share?.toString() + ' %')
                    }
                  >
                    {component.subAccountingComponents.length > 0 &&
                      [...component.subAccountingComponents]
                        .sort((a, b) => (a.accountingComponentValue.name > b.accountingComponentValue.name ? 1 : -1))
                        .map((subComponent) => (
                          <Typography
                            variant="body2"
                            component="span"
                            key={`AccountingComponentsOverviewTypography-${subComponent.id}-${index}`}
                          >
                            {subComponent.accountingComponentValue.name + ' ' + subComponent?.share?.toString() + ' %'}
                          </Typography>
                        ))}
                  </InlineEdit>
                </ListGroup>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AccountingComponentsOverviewTab;
