import { gql } from '@apollo/client';

export const GET_EMPLOYEE_INDIVIDUAL_SETTINGS = gql`
  query GetEmployeeIndividualSettings($employeeId: UUID!) {
    individualSettings(employeeId: $employeeId) {
      employeeId
      individualSettingsRows {
        settingsType
        settingsValue
        referenceType
        referenceId
      }
    }
  }
`;
