import { Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { usePeriodText } from 'src/util/date/PeriodUtil';

interface PeriodPillProps {
  period: string;
  type?: 'neutral' | 'success' | 'heads-up' | 'warning' | 'gray';
  size?: 'small' | 'large';
}

function PeriodPill({ period, type = 'neutral', size = 'large' }: PeriodPillProps) {
  const periodText = usePeriodText(period);
  return <Pill type={type} label={periodText} size={size} />;
}

export default PeriodPill;
