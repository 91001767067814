import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Switch, ListGroup, InlineEdit } from 'fgirot-k2-ui-components';
import { ControlPointRuleValue, mandatoryDataEmployerFields } from 'src/types/employer/EmployerControlPointRule';
import { doEmployerListContainsField } from './ContainsField';

interface EmployerFieldsTabContentProps {
  employerFieldsRuleValues: ControlPointRuleValue[];
}

const EmployerFieldsTabContent = ({ employerFieldsRuleValues }: EmployerFieldsTabContentProps) => {
  const { t } = useTranslation();

  // TODO: Implement this when we do the add/update story for employer fields
  const handleToggle = () => void 0;

  return (
    <div>
      <Typography variant="body2">
        {t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer:type-employer-control-text')}
      </Typography>

      <ListGroup className={'drawer-sub-tab__table'} variant="inline-edit">
        {mandatoryDataEmployerFields.map((fieldName, index) => {
          return (
            <InlineEdit key={`{${fieldName}-${index}}`} label={`${t(`violationTypes:${fieldName}`)}`}>
              <Switch
                toggled={doEmployerListContainsField(employerFieldsRuleValues, fieldName)}
                onChange={handleToggle}
              />
            </InlineEdit>
          );
        })}
      </ListGroup>
    </div>
  );
};
export default EmployerFieldsTabContent;
