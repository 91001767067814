import { gql } from '@apollo/client';

export const GET_INSURANCE_PROVIDER_CONTRACTS = gql`
  query GetInsuranceProviderContracts($employerId: UUID!) {
    insuranceProviderContracts(employerId: $employerId) {
      id
      employerId
      name
      status
      invoiceInterval
      agreementNumber
      insuranceProvider {
        id
        legalName
      }
      agreedProducts {
        id
        name
        insuranceProduct {
          id
          name
          insuranceProvider {
            id
            legalName
          }
        }
        reportingRuleConfiguration {
          id
          name
        }
        certifyInsuranceProductCondition
        certifyInsuranceProductConditionId
        insuranceProviderProductCategory
      }
    }
  }
`;
