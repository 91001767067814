import { CreateAccrualRuleInput, UpdateAccrualRuleInput } from 'src/types/accountingunit/AccrualRule';
import { gql } from '@apollo/client';
import { ACCRUAL_RULE } from 'src/graphql/schema/query/accountingunit/AcrualRule';
import { useApolloMutation } from '../graphql/Mutation';

export const CREATE_ACCRUAL_RULE = gql`
  mutation CreateAccrualRule($request: CreateAccrualRuleInput!) {
    createAccrualRule(request: $request) {
      id
    }
  }
`;

export const UPDATE_ACCRUAL_RULE = gql`
  mutation UpdateAccrualRule($request: UpdateAccrualRuleInput!) {
    updateAccrualRule(request: $request) {
      id
    }
  }
`;

export const useCreateAccrualRule = () => {
  const apolloMutation = useApolloMutation();

  const createAccrualRule = (request: CreateAccrualRuleInput, employerId: string) =>
    apolloMutation(CREATE_ACCRUAL_RULE, employerId, { request }, [ACCRUAL_RULE]);

  return createAccrualRule;
};

export const useUpdateAccrualRule = () => {
  const apolloMutation = useApolloMutation(false);

  const updateAccrualRule = (request: UpdateAccrualRuleInput, employerId: string) =>
    apolloMutation(UPDATE_ACCRUAL_RULE, employerId, { request }, [ACCRUAL_RULE]);

  return updateAccrualRule;
};
