import {
  EmployeeIndividualChoice,
  EmployeeIndividualChoiceProperty,
} from 'src/types/employees/EmployeeIndividualChoice';
import { CreateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/CreateEmployeeIndividualChoiceRequest';
import { UpdateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/UpdateEmployeeIndividualChoiceRequest';
import { EmployerIndividualChoice, IndividualChoiceType } from 'src/types/employer/EmployerIndividualChoice';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import { formatDateStringToYearMonth, formatPeriodToDate } from './date/PeriodUtil';

export const employerHasIndividualChoice = (
  type: IndividualChoiceType,
  employerIndividualChoices: EmployerIndividualChoice[],
): boolean => {
  if (!employerIndividualChoices) {
    return false;
  }

  return employerIndividualChoices.some((choice) => choice.type === type);
};

export const getRelevantFieldsForEmployeeIndividualChoice = (
  type: IndividualChoiceType,
): EmployeeIndividualChoiceProperty[] => {
  switch (type) {
    case 'ITP1':
      return ['fromPeriod'];
    case 'INDIVIDUAL_RETIREMENT_AGE':
      return ['individualAge'];
    default:
      return [];
  }
};

export const canIndividualChoiceBeRemoved = (type: IndividualChoiceType): boolean => {
  switch (type) {
    case 'ITP1':
      return false;
    case 'INDIVIDUAL_RETIREMENT_AGE':
      return true;
    default:
      return false;
  }
};

export const employeeIndividualChoiceRequestIsValid = (
  relevantFields: EmployeeIndividualChoiceProperty[],
  request: UpdateEmployeeIndividualChoiceRequest | CreateEmployeeIndividualChoiceRequest,
  employmentStartDate: string,
  individualChoice?: EmployeeIndividualChoice,
) => {
  let isValid = true;

  if ('type' in request && request.type === null) {
    isValid = false;
  }

  if (
    individualChoice &&
    individualChoice.individualAge == request.individualAge &&
    individualChoice.fromPeriod == request.fromPeriod
  ) {
    isValid = false;
  }

  relevantFields.forEach((field) => {
    if (request[field] === null) {
      isValid = false;
    }
  });

  const individualAgeError = inputSchema.validate({ individualRetirementAge: request.individualAge }).error;
  const fromPeriodError = inputSchema.validate({ period: request?.fromPeriod }).error;

  if (relevantFields.includes('individualAge') && individualAgeError) {
    isValid = false;
  }

  if (
    relevantFields.includes('fromPeriod') &&
    (fromPeriodError || isFromPeriodBeforeEmploymentStartDate(request?.fromPeriod, employmentStartDate))
  ) {
    isValid = false;
  }

  return isValid;
};

export const isFromPeriodBeforeEmploymentStartDate = (
  requestFromPeriod: string,
  employmentStartDate: string,
): boolean => {
  if (requestFromPeriod && requestFromPeriod.length === 6 && employmentStartDate) {
    const fromPeriod = formatPeriodToDate(requestFromPeriod);
    const startPeriod = formatDateStringToYearMonth(employmentStartDate);
    return fromPeriod < startPeriod;
  } else {
    return false;
  }
};
