import { Icon, IconPill, Typography } from 'fgirot-k2-ui-components';
import { Employee } from 'src/types/Employee';
import './multiple-employment-details.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleEmploymentsDetailsRow from './MultipleEmploymentsDetailsRow/MultipleEmploymentsDetailsRow';

interface MultipleEmploymentsDetailsProps {
  employmentData: Employee[];
  navigateToEmployee: (uuid: string) => void;
}

const MultipleEmploymentsDetails = ({ employmentData, navigateToEmployee }: MultipleEmploymentsDetailsProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const personNumber = employmentData[0].personNumber;
  const { t } = useTranslation();

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <div className="multiple-employment-details">
        <IconPill icon="User" label={personNumber} />
        <button
          id={`multiple-employment-details-button-${personNumber}`}
          className={[
            'multiple-employment-details__button',
            showDetails && 'multiple-employment-details__button--toggled',
          ].join(' ')}
          onClick={handleShowDetails}
          aria-controls={`multiple-employment-details-${personNumber}`}
          aria-expanded={showDetails}
        >
          <Icon type="Chevron" size="small" aria-hidden="true" />
          <Typography variant="caption" bold>
            {`${showDetails ? t('employees:search-tab.details.hide') : t('employees:search-tab.details.show')} ${t(
              'employees:search-tab.details.employments',
            )} (${employmentData.length.toString()})`}
          </Typography>
        </button>
      </div>
      {showDetails && (
        <div
          className="multiple-employment-details__employments"
          id={`multiple-employment-details-${personNumber}`}
          aria-labelledby={`multiple-employment-details-button-${personNumber}`}
        >
          {employmentData.map((employment) => (
            <MultipleEmploymentsDetailsRow
              key={employment.employmentNumber}
              employmentData={employment}
              onClick={() => navigateToEmployee(employment.id)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default MultipleEmploymentsDetails;
