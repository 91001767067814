import React from 'react';
import { sickLeaveLimitRuleTypes } from 'src/types/policy/AffiliationRule';
import OneRuleValueLimitRule from '../Components/OneRuleValueLimitRule';
import { RuleGroupProps } from '../RuleGroupProps';

const SickLeaveLimitRuleGroup: React.FunctionComponent<RuleGroupProps> = ({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
}) => {
  return (
    <>
      {sickLeaveLimitRuleTypes.map((policyRuleType) => {
        return (
          <OneRuleValueLimitRule
            key={`SickLeaveLimitRuleGroup-${policyRuleType}`}
            policyState={policyState}
            selectedAffiliationRuleGroup={selectedAffiliationRuleGroup}
            policyRuleType={policyRuleType}
            onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
          />
        );
      })}
    </>
  );
};

export default SickLeaveLimitRuleGroup;
