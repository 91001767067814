import React, { useState } from 'react';
import { DataValidationCheckpoint } from 'src/types/DataValidationCheckpoint';
import DrawerModal from 'src/components/Common/DrawerModal';
import './pay-type-mapping-drawer.scss';
import When from 'src/components/Common/When';
import PayTypeMappingsOverviewDrawer from './OverviewDrawer/PayTypeMappingsOverviewDrawer';
import EmployerWageTypeMappingDetails from './DetailsDrawer';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { useEmployerPayTypes } from 'src/service/employer/GetEmployerPayTypes';

interface PayTypeMappingDrawerProps {
  open: boolean;
  wageTypeCheckpoints: {
    employerId: string;
    checkpoints: DataValidationCheckpoint[];
  }[];
  employerIds?: string[];
  handleClose: () => void;
}

interface SelectedPayType {
  payTypeNumber: string;
  payTypeName: string;
  existingPayTypeId: string;
}

const PayTypeMappingDrawer = ({ open, wageTypeCheckpoints, employerIds, handleClose }: PayTypeMappingDrawerProps) => {
  const [selectedPayType, setSelectedPayType] = useState<SelectedPayType>(null);
  const [selectedEmployerIndex, setSelectedEmployerIndex] = useState(0);

  const employerWageTypes = useEmployerWageTypes(employerIds[selectedEmployerIndex]);
  const employerPayTypes = useEmployerPayTypes(employerIds[selectedEmployerIndex]);

  const onClose = () => {
    setSelectedEmployerIndex(0);
    setSelectedPayType(null);
    handleClose();
  };

  const handleDisplayOverviewDrawer = () => {
    setSelectedPayType(null);
  };

  const handlePayTypeSelect = async (payTypeNumber: string, payTypeName: string, existingPayTypeId: string) => {
    setSelectedPayType({
      payTypeNumber,
      payTypeName,
      existingPayTypeId,
    });
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <When condition={!selectedPayType}>
        <PayTypeMappingsOverviewDrawer
          wageTypeCheckpoints={wageTypeCheckpoints}
          employerIds={employerIds}
          selectedEmployerIndex={selectedEmployerIndex}
          setSelectedEmployerIndex={setSelectedEmployerIndex}
          handleClose={onClose}
          handleSelectPayType={handlePayTypeSelect}
          employerPayTypes={employerPayTypes}
        />
      </When>
      <When condition={!!selectedPayType}>
        <EmployerWageTypeMappingDetails
          employerId={employerIds[selectedEmployerIndex]}
          handleBackClick={handleDisplayOverviewDrawer}
          selectedPayType={employerPayTypes?.find((pt) => pt?.id === selectedPayType?.existingPayTypeId)}
          payTypeName={selectedPayType?.payTypeName}
          payTypeNumber={selectedPayType?.payTypeNumber}
          employerWageTypes={employerWageTypes}
          handleClose={onClose}
        />
      </When>
    </DrawerModal>
  );
};

export default PayTypeMappingDrawer;
