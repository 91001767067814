import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { Invoice } from 'src/types/invoice/Invoice';
import { Payment } from 'src/types/payment/Payment';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';

interface PaymentDetailsProps {
  invoice: Invoice;
  payment: Payment;
  onClose: () => void;
  onBackClick: () => void;
}

const PaymentDetails: FC<PaymentDetailsProps> = ({ invoice, payment, onClose, onBackClick }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="invoice-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('invoice:invoice-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="invoice-drawer__sub-title">
          <Typography>{invoice.employer?.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('invoice:invoice-drawer.transactions-tab.payment-due-date-label')}
          value={stringOrBlank(payment.paymentCreatedDate).substring(0, 10)}
        />
        <ListProperty
          label={t('invoice:invoice-drawer.transactions-tab.amount-label')}
          value={formatSweAmountText(`${payment.amount}`)}
        />
        <ListProperty label={t('invoice:invoice-drawer.transactions-tab.bank-label')} value={payment.bank} />
        <ListProperty
          label={t('invoice:invoice-drawer.transactions-tab.payment-service-provider-label')}
          value={payment.paymentServiceProvider}
        />
        <ListProperty
          label={t('invoice:invoice-drawer.transactions-tab.account-from-label')}
          value={payment.accountFrom}
        />
        <ListProperty label={t('invoice:invoice-drawer.transactions-tab.account-to-label')} value={payment.accountTo} />
      </ListGroup>
    </Drawer>
  );
};

export default PaymentDetails;
