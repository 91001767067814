import { gql } from '@apollo/client';

export const GET_EMPLOYER_CONTROL_POINT_RULES = gql`
  query GetEmployerControlPointRules($employerId: UUID!, $ruleTypeCategory: String!) {
    employerControlPointRules(employerId: $employerId, ruleTypeCategory: $ruleTypeCategory) {
      ruleName
      ruleValueUnit
      ruleValues {
        value
        valueType
      }
    }
  }
`;
