import React, { useEffect } from 'react';
import { bodyStringToMap, mapToBodyString } from 'src/util/MapUtil';

const XMLHttpRequestInterceptor: React.FC = () => {
  useEffect(() => {
    const originalOpen = XMLHttpRequest.prototype.open;
    const originalSend = XMLHttpRequest.prototype.send;

    XMLHttpRequest.prototype.open = function (method: string, url: string | URL, ...args) {
      this._interceptedUrl = url;
      originalOpen.apply(this, [method, url, ...args]);
    };

    XMLHttpRequest.prototype.send = function (body) {
      // Check if the URL matches the Keycloak endpoint
      const windowConfig = window['config'];
      const keycloakUrl = windowConfig['KEYCLOAK_URL'];
      if (this._interceptedUrl.includes(keycloakUrl)) {
        if (body && typeof body === 'string') {
          const bodyMap = bodyStringToMap(body);
          // Only intercept refresh request
          if (bodyMap.get('grant_type') == 'refresh_token') {
            const customerId = JSON.parse(sessionStorage.getItem('customerId'));
            if (customerId != null) {
              bodyMap.set('customer_id', customerId);
              originalSend.call(this, mapToBodyString(bodyMap));
            } else {
              // This happens if nothing is found session storage
              window.location.href = '/';
            }
          }
        }
      } else {
        originalSend.call(this, body);
      }
    };

    // Restore original methods
    return () => {
      XMLHttpRequest.prototype.open = originalOpen;
      XMLHttpRequest.prototype.send = originalSend;
    };
  }, []);

  return null;
};

export default XMLHttpRequestInterceptor;
