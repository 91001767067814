import { Drawer, Button, Icon, Typography, Pill, ListGroup } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC } from 'react';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import EmploymentGroupInlineEdit from './EmploymentGroupInlineEdit';
import { PolicyBenefitDrawerTab } from 'src/types/tabs/PolicyBenefitDrawerTab';
import { GET_POLICY_EMPLOYMENT_GROUPS } from 'src/graphql/schema/query/policy/GetPolicyEmploymentGroups';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';

interface EmploymentGroupConnectionDrawerProps {
  onClose: () => void;
  navigateToTab: (tab: PolicyBenefitDrawerTab) => void;
  policyId: string;
  policyEntityState: PolicyState;
  policyBenefit: PolicyBenefit;
}
const EmploymentGroupConnectionDrawer: FC<EmploymentGroupConnectionDrawerProps> = ({
  onClose,
  navigateToTab,
  policyId,
  policyEntityState,
  policyBenefit,
}) => {
  const onBackClick = () => {
    navigateToTab(PolicyBenefitDrawerTab.AFFILIATION_RULES);
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="drawer-base__title-container">
        <Typography variant="h3" component="h3" bold>
          {t('account:employers-tab.policy-settings.table.drawer.connection')}
        </Typography>
        <div className={'sub-title-pill'}>
          <Pill label={t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)} />
        </div>
        <QueryWrapper
          query={GET_POLICY_EMPLOYMENT_GROUPS}
          type="list"
          options={{ variables: { policyId }, skip: !policyId }}
        >
          {(availableEmploymentGroups: PolicyEmploymentGroup[]) => (
            <ListGroup variant={'drawer-navigation'}>
              {availableEmploymentGroups.map((group) => {
                return (
                  <EmploymentGroupInlineEdit
                    policyEntityState={policyEntityState}
                    employmentGroup={group}
                    policyBenefit={policyBenefit}
                    key={group.id}
                  />
                );
              })}
            </ListGroup>
          )}
        </QueryWrapper>
      </div>
    </Drawer>
  );
};

export default EmploymentGroupConnectionDrawer;
