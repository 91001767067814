import { gql } from '@apollo/client';

export const GET_EMPLOYEE = gql`
  query GetEmployee($employeeId: UUID!, $allowDraft: Boolean = false) {
    employee(employeeId: $employeeId, allowDraft: $allowDraft) {
      id
      employeeSourceType
      firstName
      lastName
      employmentNumber
      dateOfBirth
      personNumber
      isDraft
      accountingUnit {
        id
        name
      }
      employerId
      employmentStartDate
      employmentEndDate
      earliestEmploymentStartDate
    }
  }
`;
