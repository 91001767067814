import { TableRow, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { canChangeNegateIncomingAmount, isAbsenceWageType } from 'src/util/WageUtil';

interface WageTypeTableRowProps {
  wageType: EmployerWageType;
  onSelectWageType: (id: string) => void;
}

function WageTypeTableRow({ wageType, onSelectWageType }: WageTypeTableRowProps) {
  const { t } = useTranslation();
  const isAbsence = isAbsenceWageType(wageType.wageType);
  return (
    <TableRow
      key={wageType.id}
      onClick={() => onSelectWageType(wageType.id)}
      data-cy={`wage-type-row-${wageType.wageType}`}
    >
      <Td>{wageType.name}</Td>
      <Td>{t(`wageTypes:${wageType.wageType}`)}</Td>
      <Td>{isAbsence ? t(`extentCalculation:${wageType.extentCalculation}`) : '-'}</Td>
      <Td>
        {canChangeNegateIncomingAmount(wageType.wageType)
          ? t(`common:${wageType.negateIncomingAmount ? 'yes' : 'no'}`)
          : '-'}
      </Td>
    </TableRow>
  );
}

export default WageTypeTableRow;
