import React, { FC } from 'react';
import { AccrualRule } from 'src/types/accountingunit/AccrualRule';
import AccrualRuleCard from './AccrualRuleCard';
import AccrualDeviationCard from './AccrualDeviationCard';

interface AccrualRuleCardsProps {
  accrualRule: AccrualRule;
}

const AccrualRuleCards: FC<AccrualRuleCardsProps> = ({ accrualRule }) => {
  return (
    <div className="accounting-settings-tab__cards">
      <AccrualRuleCard accrualRule={accrualRule} />
      {accrualRule?.enabled && <AccrualDeviationCard accrualRule={accrualRule} />}
    </div>
  );
};

export default AccrualRuleCards;
