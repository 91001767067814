import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { PglBonusComponent } from 'src/types/policy/PglBonusComponent';
import { PglWageType } from 'src/types/policy/PglWageType';
import PglCalculationDrawerNavigation from './PglCalculationDrawerNavigation';
import { ListGroup } from 'fgirot-k2-ui-components';

interface PglCalculationListProps {
  list: (PglWageType | PglBonusComponent)[];
  onClick: (selected: PglWageType | PglBonusComponent) => void;
  employerWageTypes: EmployerWageType[];
  disabled?: boolean;
}

const PglCalculationList = ({ list, onClick, employerWageTypes, disabled = false }: PglCalculationListProps) => {
  return (
    <ListGroup variant="drawer-navigation">
      {list.map((value) => {
        const employerWageTypeName = employerWageTypes.find((wt) => wt.id === value.employerWageTypeId)?.name;

        return (
          <PglCalculationDrawerNavigation
            key={value.id}
            type={value}
            onClick={() => onClick(value)}
            label={employerWageTypeName}
            disabled={disabled}
          />
        );
      })}
    </ListGroup>
  );
};

export default PglCalculationList;
