import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_EMPLOYMENT_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetEmploymentEventCheckpoints';
import { EMPLOYMENT_EVENT_CHECKPOINT } from 'src/graphql/schema/subscription/EmploymentEventCheckpoints';
import { EmploymentEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { useEmployeeSubscription } from '../employee/EmployeeSubscription';
import { useCheckpointSubscription } from './CheckpointSubscription';

export const useEmploymentEventCheckpoints = (
  employerIds: string[],
  wageFilePeriod: string,
): Service<EmploymentEventReportEmployeeResponse[]> => {
  const [result, setResult] = useState<Service<EmploymentEventReportEmployeeResponse[]>>({ status: INIT });
  const { loading, error, data } = useQuery(GET_EMPLOYMENT_EVENT_CHECKPOINTS, {
    variables: {
      employerIds,
      wageFilePeriod,
    },
    skip: !employerIds || employerIds.length === 0 || !wageFilePeriod,
    fetchPolicy: 'no-cache',
  });

  useCheckpointSubscription(EMPLOYMENT_EVENT_CHECKPOINT, employerIds, wageFilePeriod, setResult);
  useEmployeeSubscription(employerIds, wageFilePeriod, setResult);

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data &&
      result.status !== LOADED &&
      setResult({
        status: LOADED,
        payload: data.reportEmployees,
      });
  }, [loading, error, data]);
  return result;
};
