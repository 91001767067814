import { Button, Icon, Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { Wage } from 'src/types/EmployeeCard';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useTranslation } from 'react-i18next';
import GrossSalaryWageDetails from './GrossSalaryWageDetails';
import WageDetails from './WageDetails/WageDetails';
import './wage-details-drawer.scss';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import { formatPayType } from 'src/util/WageUtil';

interface WageDetailsDrawerProps {
  wage: Wage;
  wageType: AggregatedWageTypeWage;
  onBackClick: (wageTypeId: string, payTypeNumber: string) => void;
  onClose: () => void;
  employeeCardId: string;
  employerId: string;
  isDraft: boolean;
  period: string;
  onDisplayOverviewDrawer: () => void;
}

const WageDetailsDrawer = ({
  wage,
  wageType,
  onBackClick,
  onClose,
  employeeCardId,
  employerId,
  isDraft,
  period,
  onDisplayOverviewDrawer,
}: WageDetailsDrawerProps) => {
  const { t } = useTranslation();
  const formattedPeriod = getFormattedDateStringFromPeriod(period);
  const employerWageTypes = useEmployerWageTypes(employerId);

  const handleBackClick = () => {
    onBackClick(wageType ? wageType.employerWageTypeId : null, wage.payTypeNumber);
  };

  return (
    <DrawerBase
      title={
        wage.wageType === 'GROSS_SALARY'
          ? t(`wageTypes:${wage.wageType}`)
          : formatPayType(wage.payTypeNumber, wage.payTypeName, t)
      }
      subTitle={<Pill type="success" label={formattedPeriod} />}
      leftButton={
        <Button
          type="link"
          label={t('common:back')}
          icon={<Icon type={'ArrowLeft'} />}
          onClick={wage.wageType === 'GROSS_SALARY' ? onDisplayOverviewDrawer : handleBackClick}
        />
      }
      onClose={onClose}
    >
      <div className="wage-details-drawer">
        {isDraft && <EmployeeInDraftBanner />}
        {wage.wageType === 'GROSS_SALARY' ? (
          <GrossSalaryWageDetails
            employeeCardId={employeeCardId}
            wage={wage}
            isDraft={isDraft}
            period={period}
            employerId={employerId}
            onBackClick={onDisplayOverviewDrawer}
          />
        ) : (
          <WageDetails
            wage={wage}
            employeeCardId={employeeCardId}
            isDraft={isDraft}
            employerId={employerId}
            onBackClick={handleBackClick}
            employerWageTypes={employerWageTypes}
          />
        )}
      </div>
    </DrawerBase>
  );
};

export default WageDetailsDrawer;
