import React, { FC } from 'react';
import { Employer } from 'src/types/employer/Employer';
import { PaymentAccount } from 'src/types/payment/PaymentAccount';
import './payment-settings-tab.scss';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_ACTIVE_ACCOUNT } from 'src/graphql/schema/query/payment/GetActiveAccount';
import ActiveAccountCard from './ActiveAccountCard';
import ActiveAccountCardNotFound from './ActiveAccountCardNotFound';

interface PaymentSettingsTabProps {
  employer: Employer;
}

const PaymentSettingsTab: FC<PaymentSettingsTabProps> = ({ employer }) => {
  return (
    <QueryWrapper query={GET_ACTIVE_ACCOUNT} type="default" options={{ variables: { employerId: employer.id } }}>
      {(account: PaymentAccount) => {
        return (
          <div className="payment-settings-tab">
            {!account ? <ActiveAccountCardNotFound /> : <ActiveAccountCard account={account} employer={employer} />}
          </div>
        );
      }}
    </QueryWrapper>
  );
};

export default PaymentSettingsTab;
