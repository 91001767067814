import { Pill } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { Wage } from 'src/types/EmployeeCard';
import { isNotBlank } from 'src/util/StringUtil';

interface WageDateProps {
  wage: Wage;
}
const WageDatePill: FC<WageDateProps> = ({ wage }) => {
  const wageDateLabel = wage.fromDate && wage.toDate ? `${wage.fromDate} - ${wage.toDate}` : wage.fromDate || '';

  return isNotBlank(wageDateLabel) ? <Pill size="small" type="heads-up" label={wageDateLabel} /> : <></>;
};

export default WageDatePill;
