import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteDraftPolicy } from 'src/service/policy/DeleteDraftPolicy';
import { ACTIVE, PolicyEntity } from 'src/types/policy/PolicyEntity';
import { PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';
import { getSumChanges } from 'src/util/PolicyUtil';

interface DeletePolicyDraftModalProps {
  changes: PolicyVersionChanges;
  open: boolean;
  handleClose: () => void;
  policyEntities: PolicyEntity[];
  policyId: string;
}

const DeletePolicyDraftModal: React.FunctionComponent<DeletePolicyDraftModalProps> = ({
  changes,
  open,
  handleClose,
  policyEntities,
  policyId,
}) => {
  const { t } = useTranslation();
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { customerName, employerId } = useParams();
  const deleteDraftPolicy = useDeleteDraftPolicy();

  const handleDelete = async () => {
    setDeleteButtonDisabled(true);
    deleteDraftPolicy(policyId, employerId)
      .then(() => {
        const activePolicyId = policyEntities.find((policyEntity) => policyEntity.state === ACTIVE)?.id;
        navigate(`/account/${customerName}/${employerId}/policy-settings/${activePolicyId}`);
        handleClose();
        setDeleteButtonDisabled(false);
      })
      .catch(() => {
        setDeleteButtonDisabled(false);
      });
  };

  return (
    <Modal data-cy="delete-policy-modal" centered overlayBackground open={open} onClose={handleClose}>
      <Card
        icon={<Icon type="Alert" stroke="#fda522" />}
        title={t('account:employers-tab.policy-settings.delete-policy-modal.delete-draft')}
        defaultClose
        onClose={handleClose}
      >
        <div>
          <Typography component="span">
            {`${t('account:employers-tab.policy-settings.delete-policy-modal.delete-text-pre')} `}
          </Typography>
          <Typography component="span" bold>
            {`${getSumChanges(changes)} ${t('account:employers-tab.policy-settings.delete-policy-modal.changes')}`}
          </Typography>
          <Typography component="span">
            {` ${t('account:employers-tab.policy-settings.delete-policy-modal.delete-text-post')}`}
          </Typography>
        </div>
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleClose}
            data-cy="delete-policy-modal__cancel-button"
          />
          <Button
            disabled={deleteButtonDisabled}
            icon={<Icon type="Trash" />}
            label={t('account:employers-tab.policy-settings.delete-policy-modal.delete')}
            onClick={handleDelete}
            data-cy="delete-policy-modal__delete-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default DeletePolicyDraftModal;
