import { DrawerNavigation, ListGroup } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './products-tab.scss';
import { ContractAgreedProduct } from 'src/types/contract/ContractAgreedProduct';

interface ProductsTabProps {
  agreedProducts: ContractAgreedProduct[];
  onCreateNewAgreedProduct: () => void;
  onSelectAgreedProduct: (id: string) => void;
}

const ProductsTab: FC<ProductsTabProps> = ({ agreedProducts, onCreateNewAgreedProduct, onSelectAgreedProduct }) => {
  const { t } = useTranslation();

  return (
    <div className="products-tab">
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('agreementRegistrySettings:drawer.add-new-agreed-product')}
          onClick={onCreateNewAgreedProduct}
        />
      </ListGroup>
      <ListGroup variant="drawer-navigation">
        {agreedProducts.map((product) => (
          <DrawerNavigation
            key={product.id}
            label={product.name}
            value={product.insuranceProduct?.name}
            onClick={() => onSelectAgreedProduct(product.id)}
          />
        ))}
      </ListGroup>
    </div>
  );
};

export default ProductsTab;
