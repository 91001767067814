import { Button, Card, CardAction, Modal } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextInput from 'src/components/Common/TextInput';
import { useCreateAccountingComponentType } from 'src/service/accountingunit/CreateAccountingComponentType';
import { isBlank } from 'src/util/StringUtil';

interface CreateAccountingComponentTypeModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateAccountingComponentTypeModal: FC<CreateAccountingComponentTypeModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const createAccountingComponentType = useCreateAccountingComponentType();

  const [accountingComponentType, setAccountingComponentType] = useState('');
  const handleOnClose = () => {
    setAccountingComponentType('');
    onClose();
  };
  const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => setAccountingComponentType(e.target.value);
  const handleCreateAccountingComponentType = () => {
    createAccountingComponentType({ name: accountingComponentType }, employerId).then(handleOnClose);
  };
  return (
    <Modal centered overlayBackground open={open} onClose={handleOnClose}>
      <Card title={t('economySettings:accounting-components-tab.modal.title')} defaultClose onClose={handleOnClose}>
        <TextInput
          data-cy="accounting-component-type-input"
          variant="default"
          type="text"
          validationKey="text64"
          maxLength={65}
          label={t('economySettings:accounting-components-tab.modal.name')}
          value={accountingComponentType}
          onChange={handleTypeChange}
        />

        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleOnClose} />
          <Button
            data-cy="add-accounting-component-type-button"
            disabled={isBlank(accountingComponentType)}
            label={t('economySettings:accounting-components-tab.modal.add')}
            onClick={handleCreateAccountingComponentType}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateAccountingComponentTypeModal;
