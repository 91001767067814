import { DrawerNavigation, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import './insurance-events-tab.scss';
import { Employer } from 'src/types/employer/Employer';
import { PersonInsuranceEventsResponse } from 'src/types/reporting/PersonInsuranceEventsResponse';

interface InsuranceEventsTabProps {
  person: PersonInsuranceEventsResponse;
  onSelectEvent: (event: InsuranceEvent) => void;
  employers: Employer[];
}

const InsuranceEventsTab = ({ person, onSelectEvent, employers }: InsuranceEventsTabProps) => {
  const { t } = useTranslation();

  const handleSelectEvent = (event: InsuranceEvent) => {
    onSelectEvent(event);
  };

  const insuranceEventsPerEmployeeId = new Map<string, InsuranceEvent[]>();
  person.insuranceEvents.forEach((event) => {
    if (insuranceEventsPerEmployeeId.has(event.employeeId)) {
      insuranceEventsPerEmployeeId.get(event.employeeId).push(event);
    } else {
      insuranceEventsPerEmployeeId.set(event.employeeId, [event]);
    }
  });

  return (
    <div className="insurance-events-tab">
      {Array.from(insuranceEventsPerEmployeeId.entries()).map((entry) => {
        const [employeeId, events] = entry;
        const employee = person.employees.find((employee) => employee.id === employeeId);
        const employer = employers.find((employer) => employer.id === employee.employerId);
        const employmentCategory = person.insuranceEvents.find((i) => i?.employeeCard.employmentCategory)?.employeeCard
          .employmentCategory;

        return (
          <section key={employeeId}>
            <div className="insurance-events-tab__employee-header">
              <Typography component="h4" variant="subtitle" bold>
                {employer.legalName}
              </Typography>
              <Pill key={employee.employmentNumber} label={`# ${employee.employmentNumber}`} size="small" />
              <Pill size="small" label={t(`employmentCategories:${employmentCategory}`)} />
            </div>
            <ListGroup variant={'drawer-navigation'} className="policy-employment-group-drawer__tabs__rule-list-group">
              {events.map((event) => (
                <DrawerNavigation
                  key={event.insuranceEventId}
                  label={
                    <div className="insurance-events-tab__event-label">
                      <Typography variant="body2" bold>
                        {t(`reportingTypes:${event.reportingType}`)}
                      </Typography>
                      <Pill
                        type="neutral"
                        size="small"
                        label={`${t(`benefitTypes:abbreviations.${event.benefitType}`)}`}
                      />
                      {event.insuranceProvider && (
                        <Pill type="neutral" size="small" label={event.insuranceProvider.legalName} />
                      )}
                    </div>
                  }
                  value={event.eventFromDate ? event.eventFromDate : ''}
                  onClick={() => handleSelectEvent(event)}
                />
              ))}
            </ListGroup>
          </section>
        );
      })}
    </div>
  );
};

export default InsuranceEventsTab;
