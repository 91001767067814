import { Button, Icon, Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import CreateWage from 'src/components/Common/Wage';
import { useCreateDraftEmployeeCardWage } from 'src/service/employeecard/CreateDraftEmployeeCardWage';
import { CreateWageRequest } from 'src/types/CreateWageRequest';

interface CreateDraftWageDrawerProps {
  period: string;
  employerId: string;
  employeeCardId: string;
  onBackClick: () => void;
  onClose: () => void;
}

function CreateDraftWageDrawer({
  period,
  employerId,
  onBackClick,
  onClose,
  employeeCardId,
}: CreateDraftWageDrawerProps) {
  const { t } = useTranslation();
  const formattedPeriod = getFormattedDateStringFromPeriod(period);

  const createDraftEmployeeCardWage = useCreateDraftEmployeeCardWage();

  const handleCreateWage = async (request: CreateWageRequest) => {
    await createDraftEmployeeCardWage(request, employerId);
    onBackClick();
  };

  return (
    <DrawerBase
      title={t('wages:add-wage')}
      subTitle={<Pill type="success" label={formattedPeriod} />}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <CreateWage employerId={employerId} employeeCardId={employeeCardId} onCreate={handleCreateWage} />
    </DrawerBase>
  );
}

export default CreateDraftWageDrawer;
