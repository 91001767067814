import { useEffect, useState } from 'react';
import { CreateExternalUser, IdentifierType } from 'src/types/user/User';
import { inputSchema } from 'src/validation/schemas/inputSchema';

export const useValidateCreateUserForm = (request: CreateExternalUser, selectedConnection: string) => {
  const [hasInvalidPatterns, setHasInvalidPatterns] = useState(true);

  useEffect(() => {
    const firstNameValidation = inputSchema.validate({
      text255: request.firstName,
    });
    const lastNameValidation = inputSchema.validate({
      text255: request.lastName,
    });
    const identifierValidation = validateIdentifier(request);

    const phoneNumberValidation = inputSchema.validate({
      phoneNumber: request.phone,
    });
    const emailValidation = inputSchema.validate({
      email: request.email,
    });

    const orderRefValidation = inputSchema.validate({
      text255: request.orderRef,
    });

    const invalidPatternFound = [
      firstNameValidation,
      lastNameValidation,
      identifierValidation,
      phoneNumberValidation,
      emailValidation,
      orderRefValidation,
    ].some((item) => item.error);

    setHasInvalidPatterns(invalidPatternFound);
  }, [
    request.identifier,
    request.phone,
    request.email,
    request.firstName,
    request.lastName,
    request.orderRef,
    request.identifierType,
  ]);

  const connectionLimitedWithNoValidToDate = selectedConnection === 'LIMITED' && !request.validTo?.length;

  const validateIdentifier = (request: CreateExternalUser) => {
    if (request.identifierType === IdentifierType.BANK_ID)
      return inputSchema.validate({
        personNumber: request.identifier,
      });
    if (request.identifierType === IdentifierType.E_ID)
      return inputSchema.validate({
        expisoft: request.identifier,
      });

    return { error: {} };
  };

  return (
    !request.lastName ||
    !request.firstName ||
    !request.identifier ||
    !request.identifierType ||
    !request.email ||
    !request.phone ||
    connectionLimitedWithNoValidToDate ||
    hasInvalidPatterns
  );
};

export default useValidateCreateUserForm;
