import { gql } from '@apollo/client';

export const ACCOUNTING_COMPONENT_TYPES = gql`
  query GetAccountingComponentTypes($employerId: UUID!) {
    accountingComponentTypes(employerId: $employerId) {
      id
      name
      wageFileAccountingComponentType
      accountingComponentValues {
        id
        name
      }
    }
  }
`;
