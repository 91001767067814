import { AccountingUnit } from './accountingunit/AccountingUnit';
export interface Employee {
  id: string;
  employeeSourceType: string;
  firstName: string;
  lastName: string;
  personNumber: string;
  employmentNumber: string;
  dateOfBirth: string;
  employerId: string;
  accountingUnit: AccountingUnit;
  employmentStartDate: string;
  employmentEndDate?: string;
  earliestEmploymentStartDate?: string;
  isDraft: boolean;
  hasDraft: boolean;
}

export type EmploymentNumberFilterType =
  | 'ALL'
  | 'EMPLOYEES_WITH_EMPLOYMENT_NUMBER'
  | 'EMPLOYEES_WITHOUT_EMPLOYMENT_NUMBER';

export const employmentNumberFilterType: EmploymentNumberFilterType[] = [
  'ALL',
  'EMPLOYEES_WITH_EMPLOYMENT_NUMBER',
  'EMPLOYEES_WITHOUT_EMPLOYMENT_NUMBER',
];
