import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { PostingRulesRequest, SearchPostingRulesFilter } from 'src/types/accountingunit/PostingRulesRequest';
import './posting-rules-filter-details.scss';

interface PostingRulesFilterDetailsProps {
  searchRequest: PostingRulesRequest;
  applyFilters: (filters: SearchPostingRulesFilter) => void;
}

export const PostingRulesFilterDetails: FC<PostingRulesFilterDetailsProps> = ({ searchRequest, applyFilters }) => {
  const { t } = useTranslation();
  const filtersApplied = searchRequest.filters?.insuranceCategoryFilter?.length > 0;

  const removeInsuranceCategoryFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      insuranceCategoryFilter: null,
    });
  };

  return (
    <div className="posting-rules-filter-details">
      <Typography variant="caption" bold>
        {'Filter: '}
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          {'Inga filter'}
        </Typography>
      )}
      {filtersApplied && (
        <>
          {searchRequest.filters?.insuranceCategoryFilter?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.insuranceCategoryFilter.length +
                ' ' +
                t('economySettings:posting-rules-tab.search-posting-rules-filter.insurance-category')
              }
              onClick={removeInsuranceCategoryFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PostingRulesFilterDetails;
