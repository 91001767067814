import {
  DELETE_BENEFIT_RULE_BLOCK,
  DELETE_EMPLOYMENT_GROUP_RULE_BLOCK,
} from 'src/graphql/schema/mutation/policy/DeleteRuleBlock';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { useApolloMutation } from '../graphql/Mutation';

export const useDeleteEmploymentGroupRuleBlock = () => {
  const apolloMutation = useApolloMutation();

  const deleteEmploymentGroupRuleBlock = (ruleBlockId: string, employerId) =>
    apolloMutation(DELETE_EMPLOYMENT_GROUP_RULE_BLOCK, employerId, { ruleBlockId }, [GET_POLICY]);

  return deleteEmploymentGroupRuleBlock;
};

export const useDeleteBenefitRuleBlock = () => {
  const apolloMutation = useApolloMutation();

  const deleteBenefitRuleBlock = (ruleBlockId: string, employerId: string) =>
    apolloMutation(DELETE_BENEFIT_RULE_BLOCK, employerId, { ruleBlockId }, [GET_POLICY]);

  return deleteBenefitRuleBlock;
};
