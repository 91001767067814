import { gql } from '@apollo/client';

export const GET_ASSIGNED_DOCUMENTS_TEMPLATES = gql`
  query GetAssignedDocumentTemplates {
    assignedDocumentTemplates {
      id
      name
      description
      formatType
      inputPeriodParameter
      inputParameters
      employers {
        id
        legalName
      }
    }
  }
`;
