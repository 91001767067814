import { TableHead, TableRow, Th, TableBody } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { AccountingAdjustmentRule } from 'src/types/accountingunit/AccountingAdjustmentRule';
import AccountingAdjustmentTableRow from './AccountingAdjustmentTableRow';
import { useTranslation } from 'react-i18next';
interface AccountingAdjustmentTableProps {
  accountingAdjustmentRules: AccountingAdjustmentRule[];
}
const AccountingAdjustmentTable: FC<AccountingAdjustmentTableProps> = ({ accountingAdjustmentRules }) => {
  const { t } = useTranslation();
  return (
    <TableBase>
      <TableHead>
        <TableRow>
          <Th>{t('economySettings:accounting-adjustment-tab.table.head.conditions')}</Th>
          <Th>{t('economySettings:accounting-adjustment-tab.table.head.action')}</Th>
        </TableRow>
      </TableHead>

      <TableBody>
        {accountingAdjustmentRules.map((rule) => (
          <AccountingAdjustmentTableRow accountingAdjustmentRule={rule} />
        ))}
      </TableBody>
    </TableBase>
  );
};

export default AccountingAdjustmentTable;
