import { useTranslation } from 'react-i18next';
import { Option } from 'fgirot-k2-ui-components';
import { convertMonthEnumToNumber, convertMonthNumberToEnum, useMonths } from 'src/util/date/Months';
import { useMemo } from 'react';
import { Month } from 'src/types/Month';

export const useWageTypeCalculationOptions = (recalculationFromMonth: Month) => {
  const { t } = useTranslation();

  const monthOptions = useMonths().map((month, index) => {
    return { label: month, value: convertMonthNumberToEnum(index + 1) };
  });

  const recalculationFromMonthOptions = [...monthOptions];

  const applyRetroactivelyFromMonthOptions = useMemo(() => {
    return [
      { label: t('common:not-in-use'), value: null },
      ...monthOptions.filter((option) => {
        if (!recalculationFromMonth) {
          return;
        }
        const selectedRecalculationFromMonthNumber = convertMonthEnumToNumber(recalculationFromMonth);
        const optionMonthNumber = convertMonthEnumToNumber(option.value);
        return optionMonthNumber < selectedRecalculationFromMonthNumber;
      }),
    ] as Option<string>[];
  }, [recalculationFromMonth]);

  return {
    recalculationFromMonthOptions,
    applyRetroactivelyFromMonthOptions,
  };
};
