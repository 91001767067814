import { DrawerNavigation, ListGroup } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingScheduledJob } from 'src/types/reporting/ReportingScheduledJob';

interface ReportingScheduledJobsAboutTabProps {
  reportingScheduledJobs: ReportingScheduledJob[];
  onSelectReportingScheduledJob: (reportingScheduledJob: ReportingScheduledJob) => void;
}

function ReportingScheduledJobsAboutTab({
  reportingScheduledJobs,
  onSelectReportingScheduledJob,
}: ReportingScheduledJobsAboutTabProps) {
  return (
    <div>
      <ListGroup variant="drawer-navigation">
        {reportingScheduledJobs.map((reportingScheduledJob) => (
          <DrawerNavigation
            key={reportingScheduledJob.id}
            label={reportingScheduledJob.insuranceProvider.legalName}
            value={getFormattedSchedulingTime(reportingScheduledJob)}
            onClick={() => onSelectReportingScheduledJob(reportingScheduledJob)}
          />
        ))}
      </ListGroup>
    </div>
  );
}

const getFormattedSchedulingTime = (reportingScheduledJob: ReportingScheduledJob): string => {
  const { t } = useTranslation();

  const day = reportingScheduledJob.isLastDayOfMonth
    ? t('reportingSettings:reporting-scheduled-jobs.last-day-of-month')
    : reportingScheduledJob.dayOfMonth + t('reportingSettings:reporting-scheduled-jobs.day-suffix');
  const time = t('reportingSettings:reporting-scheduled-jobs.at-time') + reportingScheduledJob.time;
  return day + ' ' + time;
};

export default ReportingScheduledJobsAboutTab;
