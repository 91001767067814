import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployerWageType, WageType } from 'src/types/employer/EmployerWageType';
import { DrawerNavigation, Icon, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import styles from './styles.module.scss';

interface UpdateWageTypeSumsProps {
  checkpoint: EmploymentEventCheckpoint;
  onSelectWageType: (wage: WageType) => void;
  aggregatedWages: AggregatedWageTypeWage[];
  employerWages: EmployerWageType[];
}

const UpdateWageTypeSumsList: React.FC<UpdateWageTypeSumsProps> = ({
  checkpoint,
  onSelectWageType,
  aggregatedWages,
  employerWages,
}) => {
  const { t } = useTranslation();

  const wagesWithInvalidSums = checkpoint.additionalData.wageTypesWithInvalidSum;

  return (
    <>
      <ListGroup variant="drawer-navigation">
        {wagesWithInvalidSums.map((wageType) => {
          const aggregatedWage = aggregatedWages.find(
            (aggregatedWage) =>
              aggregatedWage.employerWageTypeId ==
              employerWages.find((employerWage) => employerWage.wageType == wageType).id,
          );
          const hasFaultySum = aggregatedWage.amount > 0;
          return (
            <DrawerNavigation
              key={wageType}
              label={
                <>
                  <Icon type={hasFaultySum ? 'Warning' : 'Check'} className={styles.listIcon} />
                  <span className={styles.srOnly}>
                    {t(`report:employee-drawer.wages${hasFaultySum ? '-need-adjustment' : '-adjusted'}`)}
                  </span>
                  <Typography variant="body2" bold>
                    {t('wageTypes:' + wageType)}
                  </Typography>
                </>
              }
              onClick={() => onSelectWageType(wageType)}
              value={formatSweAmountTextWithSuffixWithTwoDecimals(aggregatedWage?.amount)}
            />
          );
        })}
      </ListGroup>
    </>
  );
};

export default UpdateWageTypeSumsList;
