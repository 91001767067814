import {
  Button,
  Collapsible,
  Drawer,
  Icon,
  InlineEdit,
  ListGroup,
  Pill,
  Select,
  Typography,
  Option,
} from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/Common/TextArea';
import { useUpdateInvoiceHeaderTypeCode } from 'src/service/invoice/UpdateInvoiceHeaderTypeCode';
import { Invoice, InvoiceTypeCode } from 'src/types/invoice/Invoice';
import { UpdateInvoiceHeaderTypeCodeInput } from 'src/types/invoice/UpdateInvoiceHeaderTypeCodeInput';
import { isBlank } from 'src/util/StringUtil';
import { LARGE_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import './update-invoice.scss';

interface UpdateInvoiceProps {
  invoice: Invoice;
  onClose: () => void;
  onBackClick: () => void;
}

const UpdateInvoice: FC<UpdateInvoiceProps> = ({ invoice, onClose, onBackClick }) => {
  const { t } = useTranslation();

  const [selectedInvoiceTypeCode, setSelectedInvoiceTypeCode] = useState<Option<InvoiceTypeCode>>({
    label: t(`invoice:invoice-type-code.${invoice.invoiceTypeCode}`),
    value: invoice.invoiceTypeCode,
  });
  const updateInvoiceHeaderTypeCode = useUpdateInvoiceHeaderTypeCode();
  const [note, setNote] = useState<string>('');

  const invoiceTypeCodeDebitOptions: Option<string>[] = [
    { label: t(`invoice:invoice-type-code.${'DEBIT'}`), value: 'DEBIT' },
    { label: t(`invoice:invoice-type-code.${'BOOKKEEPING'}`), value: 'BOOKKEEPING' },
  ];

  const invoiceTypeCodeCreditOptions: Option<string>[] = [
    { label: t(`invoice:invoice-type-code.${'CREDIT'}`), value: 'CREDIT' },
    { label: t(`invoice:invoice-type-code.${'BOOKKEEPING'}`), value: 'BOOKKEEPING' },
  ];

  const handleUpdateInvoice = () => {
    const request: UpdateInvoiceHeaderTypeCodeInput = {
      id: invoice.id,
      invoiceTypeCode: selectedInvoiceTypeCode?.value,
      note: note,
      amount: invoice?.totalAmount,
    };
    updateInvoiceHeaderTypeCode(request, invoice.employer.id)
      .then(() => onBackClick())
      .catch(() => {
        alert('Error when patching invoice type code');
      });
    setNote('');
  };

  const getInvoiceTypeCodeOptions = (invoice: Invoice): Option<string>[] => {
    if (invoice.totalAmount > 0) {
      return invoiceTypeCodeDebitOptions;
    }

    if (invoice.totalAmount < 0) {
      return invoiceTypeCodeCreditOptions;
    }
  };

  const isUpdateButtonDisabled =
    invoice.invoiceTypeCode === selectedInvoiceTypeCode.value ||
    ((isBlank(note) || note.length < 3) && selectedInvoiceTypeCode.value === 'BOOKKEEPING');

  const handleNoteInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const handleInvoiceTypeCodeChange = (option: Option<InvoiceTypeCode>) => {
    setSelectedInvoiceTypeCode(option);
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="invoice-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('invoice:invoice-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="invoice-drawer__sub-title">
          <Typography>{invoice.employer?.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>

      <div className="update-invoice">
        <Typography variant="body1" bold>
          {t('invoice:invoice-drawer.about-tab.update-invoice-title')}
        </Typography>
      </div>

      <div className="update-invoice__list-group">
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('invoice:invoice-drawer.about-tab.invoice-type-code-label')} bold>
            <Select
              small
              placeholder={t('common:select')}
              options={getInvoiceTypeCodeOptions(invoice)}
              selected={getInvoiceTypeCodeOptions(invoice).find(
                (option) => option.value === selectedInvoiceTypeCode.value,
              )}
              onChange={handleInvoiceTypeCodeChange}
              data-cy="update-invoice__invoice-type-code-dropdown"
            />
          </InlineEdit>
        </ListGroup>

        <Collapsible open={selectedInvoiceTypeCode.value === 'BOOKKEEPING'}>
          <ListGroup variant={'inline-edit'}>
            <InlineEdit label={t('invoice:invoice-drawer.about-tab.note-label')} bold>
              <div className="update-invoice__note-input">
                <TextArea
                  small
                  value={note}
                  onChange={handleNoteInput}
                  validationKey="text1000"
                  placeholder={t('invoice:invoice-drawer.about-tab.note-placeholder')}
                  characterLimit={LARGE_TEXTAREA_MAX_CHARACTERS}
                />
              </div>
            </InlineEdit>
          </ListGroup>
        </Collapsible>
      </div>
      <div className="update-invoice__buttons">
        <Button
          className="update-invoice__button"
          label={t('common:update')}
          onClick={handleUpdateInvoice}
          disabled={isUpdateButtonDisabled}
          data-cy="update-invoice__update-button"
        />
      </div>
    </Drawer>
  );
};

export default UpdateInvoice;
