import { Button, DrawerNavigation, Icon, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DRAFT, PolicyState } from 'src/types/policy/PolicyEntity';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { AffiliationRuleGroupType, benefitRuleGroups } from 'src/types/policy/GroupedAffiliationRules';
import RuleBlocks from 'src/components/Common/RuleBlocks';
import { useCreateBenefitRuleBlock } from 'src/service/policy/CreateRuleBlock';
import { useDeleteBenefitRuleBlock } from 'src/service/policy/DeleteRuleBlock';
import ScrollBase from 'src/components/Common/ScrollBase';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';

interface PolicyBenefitRulesDrawerTabProps {
  employerId: string;
  policyEntityState: PolicyState;
  policyBenefit: PolicyBenefit;
  onDisplayAffiliationRuleDetailDrawer: (
    affiliationRuleGroupType: AffiliationRuleGroupType,
    ruleBlockId: string,
  ) => void;
  onDisplayEmploymentGroupConnectionDrawer: (benefitId: string) => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}

const PolicyBenefitRulesDrawerTab: FC<PolicyBenefitRulesDrawerTabProps> = ({
  employerId,
  policyEntityState,
  policyBenefit,
  onDisplayAffiliationRuleDetailDrawer,
  onDisplayEmploymentGroupConnectionDrawer,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();
  const createBenefitRuleBlock = useCreateBenefitRuleBlock();
  const deleteBenefitRuleBlock = useDeleteBenefitRuleBlock();

  const handleNewRuleBlock = () => createBenefitRuleBlock(employerId, policyBenefit.benefitPackageId);

  const handleDeleteRuleBlock = (ruleBlockId: string) => deleteBenefitRuleBlock(ruleBlockId, employerId);

  return (
    <div className={['policy-benefit-overview__tab'].join(' ')}>
      <Typography bold variant="subtitle">
        {t('account:employers-tab.policy-settings.table.drawer.rules')}
      </Typography>
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('account:employers-tab.policy-settings.table.employmentGroup')}
          value={policyBenefit.policyEmploymentGroups.map((eg) => eg.employmentGroupName).join(', ')}
          onClick={() => onDisplayEmploymentGroupConnectionDrawer(policyBenefit.id)}
        />
      </ListGroup>
      <ScrollBase maxHeightPercentage={60}>
        <RuleBlocks
          employerId={employerId}
          ruleGroupTypes={benefitRuleGroups}
          affiliationRuleBlocks={policyBenefit.affiliationRuleBlocks || []}
          policyState={policyEntityState}
          handleDeleteRuleBlock={handleDeleteRuleBlock}
          onDisplayRuleTypeDrawer={onDisplayAffiliationRuleDetailDrawer}
          employerIndividualChoices={employerIndividualChoices}
        />
      </ScrollBase>
      {policyEntityState === DRAFT && (
        <div className="policy-employment-group-drawer__tabs__or-container">
          <Button
            icon={<Icon className="policy-employment-group-drawer__tabs__or-container__button--icon" type="Plus" />}
            className="policy-employment-group-drawer__tabs__or-container__button"
            label={t('account:employers-tab.policy-settings.table.add-rule-block')}
            onClick={handleNewRuleBlock}
          />
        </div>
      )}
    </div>
  );
};

export default PolicyBenefitRulesDrawerTab;
