import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { UPDATE_POLICY_AFFILIATION_RULE } from 'src/graphql/schema/mutation/policy/UpdatePolicyAffiliationRule';
import { UpdatePolicyAffiliationRuleRequest } from 'src/types/policy/request/UpdatePolicyAffiliationRuleRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdatePolicyAffiliationRule = () => {
  const apolloMutation = useApolloMutation(false);

  const updatePolicyAffiliationRule = async (employerId: string, request: UpdatePolicyAffiliationRuleRequest) =>
    apolloMutation(UPDATE_POLICY_AFFILIATION_RULE, employerId, { request }, [GET_POLICY]);

  return updatePolicyAffiliationRule;
};
