import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { Wage } from 'src/types/EmployeeCard';

export type KubSumInputMap = Map<string, number>;

export const getInitialCorrectKubSum = (
  checkpoint: EmploymentEventCheckpoint,
  existingKubWages: Wage[],
): KubSumInputMap => {
  const initialCorrectKubSum: KubSumInputMap = new Map<string, number>();
  if (checkpoint.additionalData.kubSums) {
    checkpoint.additionalData.kubSums.forEach((kubSum) => {
      const existingWageByType = existingKubWages?.find((wage) => wage.kubTypes.includes(kubSum.type));
      if (existingWageByType) {
        initialCorrectKubSum.set(kubSum.type, existingWageByType.payedAmount - Math.abs(kubSum.sum));
      }
    });
  }
  return initialCorrectKubSum;
};

export const getInitialCalculatedKubSum = (
  checkpoint: EmploymentEventCheckpoint,
  existingKubWages: Wage[],
): KubSumInputMap => {
  const initialCalculatedKubSum: KubSumInputMap = new Map<string, number>();
  if (checkpoint.additionalData.kubSums) {
    checkpoint.additionalData.kubSums.forEach((kubSum) => {
      const existingWageByType = existingKubWages?.find((wage) => wage.kubTypes.includes(kubSum.type));
      if (existingWageByType) {
        initialCalculatedKubSum.set(kubSum.type, existingWageByType.payedAmount);
      }
    });
  }
  return initialCalculatedKubSum;
};
