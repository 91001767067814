import { useApolloMutation } from '../graphql/Mutation';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { ADD_USER_TO_GROUP } from 'src/graphql/schema/mutation/group/AddUserToGroup';
import { AddUserToGroupInput } from 'src/types/group/Group';

export const useAddUserToGroup = () => {
  const apolloMutation = useApolloMutation();

  const addUserToGroup = (groupId: string, userId: string, request: AddUserToGroupInput) =>
    apolloMutation(ADD_USER_TO_GROUP, '', { groupId, userId, request }, [GET_GROUPS]);

  return addUserToGroup;
};
