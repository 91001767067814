import { gql } from '@apollo/client';

export const ACCOUNTING_ROWS = gql`
  query GetAccountingRows($accountingUnitId: UUID, $employerId: UUID) {
    accountingRows(accountingUnitId: $accountingUnitId, employerId: $employerId) {
      id
      accountingText
      accountNumber
      accountingType {
        id
        accountingTypeValue {
          name
          number
        }
      }
    }
  }
`;
