import { DrawerNavigation, ListGroup } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { AgreementCostCentre } from 'src/types/contract/InsuranceProviderContract';
import './cost-centers-tab.scss';
import { useTranslation } from 'react-i18next';

interface CostCentresTabProps {
  agreementCostCenters: AgreementCostCentre[];
  onCreateNewAgreementCostCentre: () => void;
  onSelectAgreementCostCentre: (id: string) => void;
}

const CostCentresTab: FC<CostCentresTabProps> = ({
  agreementCostCenters,
  onCreateNewAgreementCostCentre,
  onSelectAgreementCostCentre,
}) => {
  const { t } = useTranslation();

  return (
    <div className="cost-centres-tab">
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('agreementRegistrySettings:drawer.add-new-agreement-cost-centre')}
          onClick={onCreateNewAgreementCostCentre}
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        {agreementCostCenters.map((costCentre) => (
          <DrawerNavigation
            key={costCentre.id}
            label={costCentre.code}
            value={costCentre.isDefault ? t('agreementRegistrySettings:agreement-cost-centre-is-standard') : ''}
            onClick={() => onSelectAgreementCostCentre(costCentre.id)}
          />
        ))}
      </ListGroup>
    </div>
  );
};

export default CostCentresTab;
