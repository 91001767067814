import { gql } from '@apollo/client';

export const UPDATE_CEHCKPOINT_STATUS = gql`
  mutation UpdateCheckpointStatus($checkpointIds: [UUID]!, $checkpointStatus: String!) {
    updateCheckpointStatus(checkpointIds: $checkpointIds, checkpointStatus: $checkpointStatus) {
      checkpointIds
      checkpointStatus
    }
  }
`;
