import { Button, Icon, Pill, Tab, Tabs } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import './insurance-event-details-drawer.scss';
import InsuranceEventAboutTab from './InsuranceEventAboutTab/InsuranceEventAboutTab';
import InsuranceEventHandleTab from './InsuranceEventHandleTab/InsuranceEventHandleTab';
import { InsuranceEventDetailsDrawerTab } from 'src/types/tabs/InsuranceEventDetailsDrawerTab';
import { useState } from 'react';
import InsuranceEventCommentsTab from './InsuranceEventCommentsTab';
import { Employee } from 'src/types/Employee';

interface InsuranceEventDetailsDrawerProps {
  insuranceEvent: InsuranceEvent;
  onClose: () => void;
  onBackClick: () => void;
  employee: Employee;
}

const InsuranceEventDetailDrawer = ({
  insuranceEvent,
  onClose,
  onBackClick,
  employee,
}: InsuranceEventDetailsDrawerProps) => {
  const [currentTab, setCurrentTab] = useState<InsuranceEventDetailsDrawerTab>(InsuranceEventDetailsDrawerTab.ABOUT);
  const { t } = useTranslation();

  return (
    <DrawerBase
      onClose={onClose}
      title={t(`reportingTypes:${insuranceEvent.reportingType}`)}
      subTitle={
        <div className="insurance-event-details-drawer__sub-title">
          <Pill
            type="neutral"
            size="small"
            label={`${t(`benefitTypes:abbreviations.${insuranceEvent.benefitType}`)}`}
          />
          {insuranceEvent.insuranceProvider && (
            <Pill type="neutral" size="small" label={insuranceEvent.insuranceProvider?.legalName} />
          )}
        </div>
      }
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('common:about')}>
          <InsuranceEventAboutTab insuranceEvent={insuranceEvent} />
        </Tab>
        <Tab title={t('reporting:insurance-events.handle.title')}>
          <InsuranceEventHandleTab
            insuranceEvent={insuranceEvent}
            employerId={employee.employerId}
            onBackClick={onBackClick}
          />
        </Tab>
        <Tab title={t('reporting:insurance-events.comments')}>
          <InsuranceEventCommentsTab insuranceEvent={insuranceEvent} />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default InsuranceEventDetailDrawer;
