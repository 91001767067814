import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateDraftEmployee } from 'src/service/employee/CreateDraftEmployee';

interface CreateDraftEmployeeModalProps {
  open: boolean;
  handleClose: () => void;
  employeeId: string;
  employerId: string;
}

function CreateDraftEmployeeModal({ open, handleClose, employeeId, employerId }: CreateDraftEmployeeModalProps) {
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
  const createDraftEmployee = useCreateDraftEmployee();

  const { t } = useTranslation();

  const handleCreateDraft = async () => {
    setCreateButtonDisabled(true);
    await createDraftEmployee(employeeId, employerId);
    setCreateButtonDisabled(false);
    handleClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleClose}>
      <Card
        icon={<Icon type="Alert" stroke="#fdb448" />}
        title={t('employees:actions.edit')}
        defaultClose
        onClose={handleClose}
      >
        <Typography component="span">{t('employees:actions.create-draft-body')}</Typography>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleClose} />
          <Button
            disabled={createButtonDisabled}
            label={t('employees:actions.create-draft')}
            onClick={handleCreateDraft}
            data-cy="create-draft-employee-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
}

export default CreateDraftEmployeeModal;
