import { DataValidationEventCheckpoint } from 'src/types/Checkpoint';
import { getPreviousPeriod } from 'src/util/date/PeriodUtil';

export const isEndDateWithinValidPeriods = (
  endDate: string,
  checkpoint: DataValidationEventCheckpoint,
  latestEmployeeCardWithSourceWageFilePeriod: string,
) => {
  const givenEndPeriod = endDate.substring(0, 7).replace('-', '');
  const previousWageFilePeriod = getPreviousPeriod(checkpoint.wageFilePeriod);

  if (givenEndPeriod > checkpoint.wageFilePeriod) {
    return false;
  }

  if (
    givenEndPeriod === previousWageFilePeriod ||
    givenEndPeriod === checkpoint.wageFilePeriod ||
    givenEndPeriod === latestEmployeeCardWithSourceWageFilePeriod ||
    givenEndPeriod > latestEmployeeCardWithSourceWageFilePeriod
  ) {
    return true;
  }
};
