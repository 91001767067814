import { CREATE_ACCOUNTING_COMPONENT } from 'src/graphql/schema/mutation/accountingunit/CreateAccountingComponent';
import { ACCOUNTING_TYPE } from 'src/graphql/schema/query/accountingunit/AccountingType';
import {
  CreateAccountingComponentInput,
  UpdateAccountingComponentInput,
  UpdateAccountingComponentTypeInput,
} from 'src/types/accountingunit/AccountingComponent';
import { useApolloMutation } from '../graphql/Mutation';
import { DELETE_ACCOUNTING_COMPONENT } from 'src/graphql/schema/mutation/accountingunit/DeleteAccountingComponent';
import { UPDATE_ACCOUNTING_COMPONENT } from 'src/graphql/schema/mutation/accountingunit/UpdateAccountingComponent';
import { UPDATE_ACCOUNTING_COMPONENT_TYPE } from 'src/graphql/schema/mutation/accountingunit/UpdateAccountingComponentType';
import { ACCOUNTING_COMPONENT_TYPES } from 'src/graphql/schema/query/accountingunit/AccountingComponentTypes';

export const useCreateAccountingComponent = () => {
  const apolloMutation = useApolloMutation();

  const createAccountingComponent = (request: CreateAccountingComponentInput, employerId: string) =>
    apolloMutation(CREATE_ACCOUNTING_COMPONENT, employerId, { request }, [ACCOUNTING_TYPE]);

  return createAccountingComponent;
};

export const useUpdateAccountingComponent = () => {
  const apolloMutation = useApolloMutation(false);

  const updateAccountingComponent = (request: UpdateAccountingComponentInput, employerId: string) =>
    apolloMutation(UPDATE_ACCOUNTING_COMPONENT, employerId, { request }, [ACCOUNTING_TYPE]);

  return updateAccountingComponent;
};

export const useUpdateAccountingComponentType = () => {
  const apolloMutation = useApolloMutation(false);

  const updateAccountingComponentType = (request: UpdateAccountingComponentTypeInput, employerId: string) =>
    apolloMutation(UPDATE_ACCOUNTING_COMPONENT_TYPE, employerId, { request }, [ACCOUNTING_COMPONENT_TYPES]);

  return updateAccountingComponentType;
};

export const useDeleteAccountingComponent = () => {
  const apolloMutation = useApolloMutation();

  const deleteAccountingComponent = (accountingComponentId: string, employerId: string) =>
    apolloMutation(DELETE_ACCOUNTING_COMPONENT, employerId, { accountingComponentId }, [ACCOUNTING_TYPE]);

  return deleteAccountingComponent;
};
