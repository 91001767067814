import common from './common/common.json';
import overview from './overview.json';
import navigation from './common/navigation.json';
import report from './report/report.json';
import upload from './report/upload.json';
import validating from './report/validating.json';
import examination from './report/examination.json';
import verify from './report/verify.json';
import certify from './report/certify.json';
import errors from './common/errors.json';
import violationTypes from './violation-types.json';
import wageTypes from './wage-types.json';
import employmentTypes from './employment-types.json';
import employmentCategories from './employment-categories.json';
import periodDifference from './period-difference.json';
import searchTerms from './search-terms.json';
import employeeFilter from './employee-filter.json';
import subActionCategories from './subaction-categories.json';
import remunerationTypes from './remuneration-types.json';
import validationErrors from './validation-errors.json';
import insuranceEventTypes from './insurance-event-types.json';
import benefitTypes from './benefit-types.json';
import employmentControlPointRules from './employment-control-point-rules.json';
import account from './account/account.json';
import reporting from './reporting/reporting.json';
import reportingSettings from './account/reporting-settings.json';
import reportingTypes from './reporting-types.json';
import reportingDetailLevels from './reporting-detail-levels.json';
import policySettings from './account/policy-settings.json';
import policyRules from './policy-rules.json';
import calculationTimeFrames from './calculation-time-frames.json';
import startDateBasis from './start-date-basis.json';
import wageTypeFilter from './wage-type-filter.json';
import benefitConnection from './benefit-connection.json';
import bonusAverageType from './bonus-average-type.json';
import agreementRegistrySettings from './account/agreement-registry-settings.json';
import employees from './employees/employees.json';
import insurances from './employees/insurances.json';
import benefits from './employees/benefits.json';
import wages from './employees/wages.json';
import premiumInvoiceRows from './employees/premium-invoice-rows.json';
import calculations from './employees/calculations.json';
import insuranceEventStatus from './insurance-event-status.json';
import invoice from './economy/invoice.json';
import economy from './economy/economy.json';
import economySettings from './account/economy-settings.json';
import accountingItems from './economy/accounting-items.json';
import accountingTypeValue from './economy/accounting-type-value.json';
import userFeedback from './user-feedback.json';
import extentCalculation from './extent-calculation.json';
import insuranceEventHandlingTypes from './reporting/insurance-event-handling-types.json';
import documents from './documents/documents.json';
import individualChoiceTypes from './individual-choice-types.json';
import permission from './permission.json';
import selectCustomer from './select-customer.json';
import identifierTypes from './identifier-types.json';
import insuranceTypeMapping from './insurance-type-mapping.json';
import rolesTranslations from './roles-translations.json';
import employeeInsuranceEvents from './employees/employee-insurance-events.json';
import employeeComments from './employees/employee-comments.json';
import unitTypes from './unit-types.json';

export default {
  accountingTypeValue,
  common,
  overview,
  navigation,
  report,
  errors,
  validating,
  verify,
  examination,
  extentCalculation,
  certify,
  violationTypes,
  wageTypes,
  employmentTypes,
  periodDifference,
  searchTerms,
  employeeFilter,
  subActionCategories,
  employmentCategories,
  remunerationTypes,
  validationErrors,
  individualChoiceTypes,
  insuranceEventTypes,
  insuranceEventHandlingTypes,
  insuranceEventStatus,
  benefitTypes,
  employmentControlPointRules,
  account,
  reporting,
  reportingSettings,
  reportingTypes,
  policySettings,
  upload,
  policyRules,
  calculationTimeFrames,
  startDateBasis,
  wageTypeFilter,
  benefitConnection,
  bonusAverageType,
  agreementRegistrySettings,
  employees,
  insurances,
  benefits,
  wages,
  premiumInvoiceRows,
  calculations,
  invoice,
  economy,
  accountingItems,
  economySettings,
  reportingDetailLevels,
  userFeedback,
  documents,
  permission,
  selectCustomer,
  identifierTypes,
  insuranceTypeMapping,
  rolesTranslations,
  employeeInsuranceEvents,
  employeeComments,
  unitTypes,
};
