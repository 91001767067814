import { TableBody } from 'fgirot-k2-ui-components';
import React from 'react';
import TableBase from 'src/components/Common/TableBase';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import './absence-table.scss';
import AbsenceTableHead from './AbsenceTableHead';
import AbsenceSumRow from './AbsenceSumRow';
import AbsenceTableRow from './AbsenceTableRow';

interface AbsenceTableProps {
  wageType: AggregatedWageTypeWage;
}

function AbsenceTable({ wageType }: AbsenceTableProps) {
  return (
    <section className="absence-table" data-testid="absence-table">
      <TableBase>
        <AbsenceTableHead />
        <TableBody>
          <>
            {wageType.wages.map((wage) => {
              return <AbsenceTableRow key={wage.id} wage={wage} />;
            })}
            <AbsenceSumRow sum={wageType.extentAdjustedAbsenceDays} />
          </>
        </TableBody>
      </TableBase>
    </section>
  );
}

export default AbsenceTable;
