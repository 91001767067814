import { Button, Card, CardAction, Modal, Option, Select, ToastContext } from 'fgirot-k2-ui-components';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../TextInput';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { Customer, GetCustomerPage } from 'src/types/customer/Customer';
import { updateKeycloakWithSelectedCustomer } from 'src/service/updateKeycloakWithSelectedCustomer';
import QueryWrapper from '../QueryWrapper';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/user/GetCustomers';
import { useNavigate } from 'react-router-dom';

interface SelectCustomerModalProps {
  open: boolean;
  onClose: () => void;
}

const SelectCustomerModal: React.FC<SelectCustomerModalProps> = ({ open, onClose }) => {
  const [searchInput, setSearchInput] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const { displayToast } = useContext(ToastContext);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSelectCustomerChange = (option: Option<string>) => {
    setSelectedCustomerId(option.value);
  };

  const customerOptionList = (customers: Customer[]) => {
    return createOptionsFromList(customers, 'id', 'name');
  };

  const handleOnSetCustomer = async (customerId: string) => {
    const displayToastSuccess = () =>
      displayToast('success', t('common:success'), t('userFeedback:SelectCustomer.success'));
    const displayToastError = () => displayToast('error', t('common:error'), t('userFeedback:SelectCustomer.error'));
    const response = await updateKeycloakWithSelectedCustomer(customerId, displayToastSuccess, displayToastError);
    if (response) {
      navigate(0);
      onClose();
    }
  };

  const filterBySearchInput = (customer: Option<string>) => {
    return customer.label.toLowerCase().includes(searchInput.toLowerCase());
  };

  return (
    <Modal centered overlayBackground onClose={onClose} open={open}>
      <Card title={t('selectCustomer:title')} size="narrow">
        <QueryWrapper query={GET_ALL_CUSTOMERS}>
          {({ customers }: GetCustomerPage) => {
            const customerOptions = customerOptionList(customers);
            const filteredCustomerOptions = customerOptions.filter(filterBySearchInput);
            return (
              <div className="select-customer-modal__wrapper">
                {customerOptions.length > 10 && (
                  <TextInput
                    className="select-customer-modal__filter-customers"
                    type="text"
                    validationKey="text64"
                    variant="default"
                    maxLength={65}
                    value={searchInput}
                    placeholder={t('selectCustomer:filter-customers')}
                    onChange={(e) => setSearchInput(e.target.value)}
                    data-testid="select-customer-modal-input"
                  />
                )}
                <Select
                  allowSelectMaxWidth
                  placeholder={t('common:select')}
                  label={t('selectCustomer:customer')}
                  selected={customerOptions.find((option) => option.value === selectedCustomerId)}
                  options={filteredCustomerOptions}
                  onChange={handleSelectCustomerChange}
                />
              </div>
            );
          }}
        </QueryWrapper>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button
            label={t('selectCustomer:change-customer')}
            disabled={!selectedCustomerId}
            onClick={() => handleOnSetCustomer(selectedCustomerId)}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SelectCustomerModal;
