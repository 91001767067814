import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_DATA_VALIDATION_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetDataValidationCheckpoints';
import { DATA_VALIDATION_CHECKPOINT } from 'src/graphql/schema/subscription/DataValidationCheckpoints';
import { DataValidationEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { useEmployeeSubscription } from '../employee/EmployeeSubscription';
import { useEmployeeCardSubscription } from '../employeecard/EmployeeCardSubscription';
import { useCheckpointSubscription } from './CheckpointSubscription';

export const useDataValidationCheckpoints = (
  employerIds: string[],
  wageFilePeriod: string,
): Service<DataValidationEventReportEmployeeResponse[]> => {
  const [result, setResult] = useState<Service<DataValidationEventReportEmployeeResponse[]>>({ status: INIT });
  const [employeeCardIds, setEmployeeCardIds] = useState<string[]>([]);
  const { loading, error, data } = useQuery(GET_DATA_VALIDATION_CHECKPOINTS, {
    variables: {
      employerIds: employerIds,
      wageFilePeriod,
    },
    skip: !employerIds || employerIds.length === 0 || !wageFilePeriod,
    fetchPolicy: 'no-cache',
  });

  useCheckpointSubscription(DATA_VALIDATION_CHECKPOINT, employerIds, wageFilePeriod, setResult);
  useEmployeeSubscription(employerIds, wageFilePeriod, setResult);
  useEmployeeCardSubscription(employeeCardIds, setResult);

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data &&
      result.status !== LOADED &&
      setResult({
        status: LOADED,
        payload: data.reportEmployees,
      });
    data &&
      result.status !== LOADED &&
      setEmployeeCardIds([...data.reportEmployees.map((reportEmployee) => reportEmployee.employeeCard.id)]);
  }, [loading, error, data]);
  return result;
};
