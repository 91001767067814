import { gql } from '@apollo/client';

export const GET_DOCUMENT_REFERENCES = gql`
  query GetDocumentReferences {
    documentReferences {
      id
      employerId
      createdDate
      fromPeriod
      toPeriod
      contentType
      fileName
      latestDownloadDate
    }
  }
`;
