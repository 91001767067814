import { EmployerKubRequest } from 'src/types/employer/EmployerKub';
import { CREATE_EMPLOYER_KUB } from 'src/graphql/schema/mutation/employer/CreateEmployerKub';
import { GET_EMPLOYER_KUBS } from 'src/graphql/schema/query/employer/GetEmployerKubs';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateEmployerKub = () => {
  const apolloMutation = useApolloMutation();

  const createEmployerKub = (employerId: string, request: EmployerKubRequest) =>
    apolloMutation(CREATE_EMPLOYER_KUB, employerId, { request }, [GET_EMPLOYER_KUBS]);

  return createEmployerKub;
};
