import { gql } from '@apollo/client';

export const PATCH_AGREED_REPORTING_TYPE_CONFIG = gql`
  mutation PatchAgreedReportingTypeConfig(
    $insuranceProviderContractId: UUID!
    $agreedProductId: UUID!
    $agreedReportingTypeConfigId: UUID!
    $request: PatchAgreedReportingTypeConfigRequest!
  ) {
    patchAgreedReportingTypeConfig(
      insuranceProviderContractId: $insuranceProviderContractId
      agreedProductId: $agreedProductId
      agreedReportingTypeConfigId: $agreedReportingTypeConfigId
      request: $request
    ) {
      id
    }
  }
`;
