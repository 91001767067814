import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';

interface AccountingItemsTableHeadsProps {
  accountingComponentTypes: string[];
  sortDirection: SortDirection;
  sortField: string;
  onSortDirectionChange: () => void;
  onSortFieldChange: (sortField: string) => void;
}

const AccountingItemsTableHeads: FC<AccountingItemsTableHeadsProps> = ({
  accountingComponentTypes,
  sortDirection,
  sortField,
  onSortDirectionChange,
  onSortFieldChange,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t('economy:accounting-items-tab.table.head.first-name'),
      sortDirection,
      fieldName: 'firstName',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('firstName');
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.last-name'),
      sortDirection,
      fieldName: 'lastName',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('lastName');
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.person-number'),
      sortDirection,
      fieldName: 'personNumber',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('personNumber');
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-period'),
      sortDirection,
      fieldName: 'accountingPeriod',
      handleSortDirectionChange: () => {
        onSortFieldChange('accountingPeriod');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.premium-period'),
      sortDirection,
      fieldName: 'premiumPeriod',
      handleSortDirectionChange: () => {
        onSortFieldChange('premiumPeriod');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-status'),
      sortDirection,
      fieldName: 'status',
      handleSortDirectionChange: () => {
        onSortFieldChange('status');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-number'),
      sortDirection,
      fieldName: 'accountingNumber',
      handleSortDirectionChange: () => {
        onSortFieldChange('accountingNumber');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-type'),
      sortDirection,
      fieldName: 'accountingTypeValue',
      handleSortDirectionChange: () => {
        onSortFieldChange('accountingTypeValue');
        onSortDirectionChange();
      },
    },
    ...accountingComponentTypes.map((componentType) => ({
      title: componentType,
      sortDirection,
      fieldName: componentType,
      handleSortDirectionChange: () => {
        onSortFieldChange(componentType);
        onSortDirectionChange();
      },
    })),
    {
      title: t('economy:accounting-items-tab.table.head.amount'),
      sortDirection,
      fieldName: 'amount',
      handleSortDirectionChange: () => {
        onSortFieldChange('amount');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.invoice-number'),
      sortDirection,
      fieldName: 'invoiceNumber',
      handleSortDirectionChange: () => {
        onSortFieldChange('invoiceNumber');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.insurance-provider'),
      sortDirection,
      fieldName: 'insuranceProvider',
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceProvider');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.benefit-type'),
      sortDirection,
      fieldName: 'benefitType',
      handleSortDirectionChange: () => {
        onSortFieldChange('benefitType');
        onSortDirectionChange();
      },
    },
    {
      title: t('economy:accounting-items-tab.table.head.insurance-type'),
      sortDirection,
      fieldName: 'insuranceType',
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceType');
        onSortDirectionChange();
      },
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head) => {
        return (
          <Th
            compressed
            includeSortButtons
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`accounting-items-table-heads-${head.fieldName}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default AccountingItemsTableHeads;
