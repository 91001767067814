export const bodyStringToMap = (body: string): Map<string, string> => {
  const map = new Map<string, string>();
  body?.split('&').forEach((item) => {
    if (item) {
      const keyValue = item.split('=');
      map.set(keyValue[0], keyValue[1]);
    }
  });
  return map;
};

export const mapToBodyString = (map: Map<string, string>): string => {
  let i = 0;
  const arr: string[] = new Array(map?.size);
  if (map) {
    for (const [key, value] of map) {
      arr[i++] = key + '=' + value;
    }
  }
  return arr.join('&');
};
