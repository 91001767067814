import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import './kub-table.scss';
import EmployerKubTableRow from './EmployerKubTableRow';

interface EmployerKubTableProps {
  employerKubs: EmployerKub[];
  handleOpenEmployerKubDrawer: (employerKub: EmployerKub) => void;
}
const EmployerKubTable: React.FunctionComponent<EmployerKubTableProps> = ({
  employerKubs,
  handleOpenEmployerKubDrawer,
}) => {
  const { t } = useTranslation();

  const tableHeads = [
    {
      title: t('account:employers-tab.wage-type-mapping-settings.kub-table.name'),
    },
    {
      title: t('account:employers-tab.wage-type-mapping-settings.kub-table.content'),
    },
  ];

  return (
    <div className={['kub-table'].join(' ')} data-cy="kub-table">
      <TableBase maxHeightPercentage={55}>
        <TableHead filled>
          <TableRow>
            {tableHeads.map((head, index) => (
              <Th key={`EmploymentControlPointSettingsTable-${head.title}-${index}`}>{head.title}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {employerKubs.map((employerKub) => (
            <EmployerKubTableRow
              handleOpenEmployerKubrawer={handleOpenEmployerKubDrawer}
              key={employerKub.id}
              employerKub={employerKub}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default EmployerKubTable;
