import { gql } from '@apollo/client';

export const UPDATE_ACCOUNTING_TYPE = gql`
  mutation UpdateAccountingType($request: UpdateAccountingTypeInput!) {
    updateAccountingType(request: $request) {
      id
    }
  }
`;

export const UPDATE_ACCOUNTING_TYPE_COMPONENTS = gql`
  mutation UpdateAccountingTypeComponents($request: UpdateAccountingTypeComponentsInput!) {
    updateAccountingTypeComponents(request: $request) {
      id
    }
  }
`;
