import { Typography, Pill } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingComponent } from 'src/types/accountingunit/AccountingComponent';

interface AccountingComponentsListProps {
  accountingComponent: AccountingComponent;
  whiteBackground: boolean;
}
const AccountingComponentsList: FC<AccountingComponentsListProps> = ({ accountingComponent, whiteBackground }) => {
  const { t } = useTranslation();
  return (
    accountingComponent.accountingComponents.length > 0 && (
      <ul className="accounting-type__accounting-component-list">
        {accountingComponent.accountingComponents.map((accountingComponent) => (
          <li
            style={{ background: whiteBackground ? '#ffffff' : '#f5f6f7' }}
            key={`CostObjectLi-${accountingComponent.id}`}
            className="accounting-type__accounting-component"
          >
            <Typography variant="caption" bold>
              {`${accountingComponent.type} - ${accountingComponent.name}`}
            </Typography>
            <Pill
              label={`${t('economySettings:accounting-type-item.share')}: ${accountingComponent.share} %`}
              size={'small'}
            />
            <AccountingComponentsList whiteBackground={!whiteBackground} accountingComponent={accountingComponent} />
          </li>
        ))}
      </ul>
    )
  );
};

export default AccountingComponentsList;
