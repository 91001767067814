import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { client } from 'src/graphql';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import {
  GET_EMPLOYERS_ID_LEGALNAME,
  GET_EMPLOYERS,
  GET_EMPLOYERS_WAGE_FILE_SESSIONS,
} from 'src/graphql/schema/query/employer/GetEmployers';
import { Employer, ReportingStatusEmployer } from 'src/types/employer/Employer';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { WageFileSession } from 'src/types/WageFileSession';

export const useEmployersInPeriod = (
  customerId: string,
  period: string,
  wageFileSessions: WageFileSession[],
): ReportingStatusEmployer[] => {
  const [result, setResult] = useState<ReportingStatusEmployer[]>([]);

  const { loading, error, data } = useQuery(GET_EMPLOYERS_WAGE_FILE_SESSIONS, {
    variables: { customerId, period },
  });

  useEffect(() => {
    const queryData = () => {
      if (data) {
        try {
          const employersInPeriod: ReportingStatusEmployer[] = data.employers.map((employer) => {
            return {
              ...employer,
              wageFileSessions: [
                ...employer.wageFileSessions,
                ...wageFileSessions.filter((wageFileSession) => wageFileSession.employerId === employer.id),
              ],
            };
          });
          setResult(employersInPeriod);
        } catch (e) {
          alert(e.message);
        }
      }
    };
    queryData();
  }, [loading, error, data, wageFileSessions, customerId]);

  return result;
};

export const useEmployersIdLegalName = (customerId: string) => {
  const [result, setResult] = useState<Employer[]>([]);

  const { loading, error, data } = useQuery(GET_EMPLOYERS_ID_LEGALNAME, {
    variables: { customerId },
    skip: !customerId,
  });
  useEffect(() => {
    data && setResult(data.employers);
  }, [loading, error, data]);

  return result;
};

export const useFullEmployers = (customerId: string) => {
  const [result, setResult] = useState<Employer[]>([]);

  const { loading, error, data } = useQuery(GET_EMPLOYERS, {
    variables: { customerId },
    skip: !customerId,
  });
  useEffect(() => {
    data && setResult(data.employers);
  }, [loading, error, data]);

  return result;
};

export const useEmployer = (employerId: string): Service<Employer> => {
  const [result, setResult] = useState<Service<Employer>>({ status: INIT });

  const { loading, error, data } = useQuery(GET_EMPLOYER, {
    variables: { employerId },
    skip: !employerId,
  });

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data && setResult({ status: LOADED, payload: data.employer });
  }, [loading, error, data]);
  return result;
};

export const getEmployer = async (employerId: string): Promise<Employer> => {
  const {
    data: { employer },
  } = await client.query({ query: GET_EMPLOYER, variables: { employerId } });

  return employer;
};
