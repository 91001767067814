import React, { useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import RuleTypeDrawer from './RuleTypeDrawer';
import './policy-employment-group-drawer.scss';
import PolicyEmploymentGroupDrawer from './PolicyEmploymentGroupDrawer';
import { AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import { useEmployerIndividualChoices } from 'src/service/employer/GetEmployerIndividualChoices';

interface PolicyEmploymentGroupModalProps {
  employerId: string;
  policyId: string;
  policyEntityState: PolicyState;
  policyEmploymentGroup: PolicyEmploymentGroup;
  open: boolean;
  onClose: () => void;
}

const PolicyEmploymentGroupModal: React.FunctionComponent<PolicyEmploymentGroupModalProps> = ({
  employerId,
  policyId,
  policyEntityState,
  policyEmploymentGroup,
  open,
  onClose,
}) => {
  const [selectedRuleType, setSelectedRuleType] = useState<AffiliationRuleGroupType>(undefined);
  const [selectedRuleBlockId, setSelectedRuleBlockId] = useState<string>();

  const employerIndividualChoicesService = useEmployerIndividualChoices(employerId);
  const employerIndividualChoices =
    employerIndividualChoicesService.status === 'loaded' && employerIndividualChoicesService.payload;

  const handleDisplayRuleTypeDrawer = (affiliationRuleGroupType: AffiliationRuleGroupType, ruleBlockId: string) => {
    setSelectedRuleType(affiliationRuleGroupType);
    setSelectedRuleBlockId(ruleBlockId);
  };
  const handleRuleTypeOnClose = () => {
    setSelectedRuleType(undefined);
    setSelectedRuleBlockId(undefined);
    onClose();
  };
  const handleOnBackClick = () => {
    setSelectedRuleType(undefined);
    setSelectedRuleBlockId(undefined);
  };

  return (
    <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
      {selectedRuleType ? (
        <RuleTypeDrawer
          employerId={employerId}
          selectedRuleGroupType={selectedRuleType}
          policyId={policyId}
          policyEntityState={policyEntityState}
          policyEmploymentGroup={policyEmploymentGroup}
          onClose={handleRuleTypeOnClose}
          onBackClick={handleOnBackClick}
          selectedRuleBlock={policyEmploymentGroup.affiliationRuleBlocks.find(
            (ruleBlock) => ruleBlock.id === selectedRuleBlockId,
          )}
          employerIndividualChoices={employerIndividualChoices}
        />
      ) : (
        <PolicyEmploymentGroupDrawer
          employerId={employerId}
          policyEntityState={policyEntityState}
          policyEmploymentGroup={policyEmploymentGroup}
          onDisplayRuleTypeDrawer={handleDisplayRuleTypeDrawer}
          onClose={onClose}
          employerIndividualChoices={employerIndividualChoices}
        />
      )}
    </DrawerModal>
  );
};

export default PolicyEmploymentGroupModal;
