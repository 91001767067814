import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableRow, Td, Typography } from 'fgirot-k2-ui-components';
import TableHeadEmpty from 'src/components/Common/TableHeadEmpty';
import '../../mandatory-data-settings-tab.scss';

interface BasicFieldsTabContentProps {
  basicFields: string[];
}

const BasicFieldsTabContent = ({ basicFields }: BasicFieldsTabContentProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="body2">
        {t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer:type-basic-control-text')}
      </Typography>

      <Table dividers className={'drawer-sub-tab__table'}>
        <TableHeadEmpty />
        <TableBody>
          {basicFields.map((fieldName) => {
            return (
              <TableRow>
                <Td>
                  <Typography variant="body2" bold>
                    {`${t(`violationTypes:${fieldName}`)}`}
                  </Typography>
                </Td>
                <Td>
                  <Typography variant="body2">
                    {t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer.type-basic-value')}
                  </Typography>
                </Td>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
export default BasicFieldsTabContent;
