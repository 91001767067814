import { TableHead, TableRow, Th, TableBody } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoMessage from 'src/components/Common/InfoMessage';
import LoadingPage from 'src/components/Common/LoadingPage';
import TableBase from 'src/components/Common/TableBase';
import { useEmployerControlPointRules } from 'src/service/employer/GetEmployerControlPointRules';
import { INIT, LOADING, ERROR, LOADED } from 'src/types/Status';
import './employment-control-point-settings-table.scss';
import EmploymentControlPointRow from './EmploymentControlPointRow';

interface EmploymentControlPointSettingsTableProps {
  employerId: string;
}

const EmploymentControlPointSettingsTable = ({ employerId }: EmploymentControlPointSettingsTableProps) => {
  const { t } = useTranslation();

  const service = useEmployerControlPointRules(employerId, 'EMPLOYMENT_EVENT_CATEGORY');

  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>Error...</div>;
    case LOADED:
      break;
  }

  const rules = service.payload;

  const head = [
    { name: t('account:employers-tab.reporting-settings.control-points-tab.control-point') },
    { name: t('account:employers-tab.reporting-settings.control-points-tab.control') },
    { name: t('account:employers-tab.reporting-settings.control-points-tab.rule') },
  ];

  return (
    <div className={['employment-checkpoint-settings-table'].join(' ')}>
      <TableBase maxHeightPercentage={50}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th key={`EmploymentControlPointSettingsTable-${title.name}-${index}`}>{title.name}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rules.map((rule, index) => (
            <EmploymentControlPointRow rule={rule} key={`EmploymentControlPointRow-${rule.ruleName}-${index}`} />
          ))}
        </TableBody>
      </TableBase>
      <InfoMessage
        message={t('account:employers-tab.reporting-settings.control-points-tab.page-info-text')}
        linkText={t('account:employers-tab.reporting-settings.control-points-tab.page-info-link-text')}
      />
    </div>
  );
};

export default EmploymentControlPointSettingsTable;
