import React, { useState } from 'react';
import './groups-tab.scss';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import useModal from 'src/util/Hooks/ModalHook';
import GroupsTable from './GroupsTable';
import CreateGroupModal from './CreateGroupModal';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { GetGroupsPage } from 'src/types/group/Group';
import GroupsDrawer from './GroupsDrawer';
import { Role } from 'src/types/Role';
import { GET_ROLES } from 'src/graphql/schema/query/role/GetRoles';
import { GET_ALL_USERS } from 'src/graphql/schema/query/user/GetUsers';
import { GetUsersPage } from 'src/types/user/User';
import { GET_EMPLOYERS_ID_LEGALNAME } from 'src/graphql/schema/query/employer/GetEmployers';
import { Employer } from 'src/types/employer/Employer';
import { getCustomerId } from 'src/service/customer/GetCustomerId';

const GroupsTab = () => {
  const { t } = useTranslation();
  const customerId = getCustomerId();
  const { modalOpen, setModalOpen, closeModal } = useModal();
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 20;

  const handleOpenDrawer = (groupId: string) => {
    setSelectedGroupId(groupId);
  };

  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };
  return (
    <div className="groups-tab">
      <QueryWrapper query={GET_GROUPS} options={{ variables: { pageSize, pageNumber: queryPageNumber } }}>
        {({ externalGroups, paging }: GetGroupsPage) => (
          <QueryWrapper query={GET_EMPLOYERS_ID_LEGALNAME} options={{ variables: { customerId } }}>
            {(employers: Employer[]) => (
              <QueryWrapper query={GET_ALL_USERS}>
                {({ externalUsers }: GetUsersPage) => (
                  <QueryWrapper query={GET_ROLES} options={{ variables: { pageSize: 40, pageNumber: 0 } }}>
                    {(roles: Role[]) => {
                      const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
                      const selectedGroup = externalGroups.find((group) => group.id === selectedGroupId);
                      return (
                        <>
                          <GroupsTable groups={externalGroups} onClick={handleOpenDrawer} />
                          {totalPages > 1 && (
                            <Pagination
                              currentPage={pageNumber}
                              onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                              totalPages={totalPages}
                            />
                          )}
                          {selectedGroupId && (
                            <GroupsDrawer
                              group={selectedGroup}
                              open={!!selectedGroup}
                              allRoles={roles}
                              allUsers={externalUsers}
                              allEmployers={employers}
                              onClose={() => setSelectedGroupId(undefined)}
                            />
                          )}
                        </>
                      );
                    }}
                  </QueryWrapper>
                )}
              </QueryWrapper>
            )}
          </QueryWrapper>
        )}
      </QueryWrapper>
      <CreateGroupModal onClose={closeModal} open={modalOpen} />
      <div className="groups-tab__create-group-modal-button" data-testid="create-group-modal-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'PlusLarge'} />}
          label={t('permission:create-group-button')}
          onClick={setModalOpen}
        />
      </div>
    </div>
  );
};

export default GroupsTab;
