import { client } from 'src/graphql';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';

import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';

export const fetchLatestNonDraftEmployeeCard = async (
  employeeId: string,
  period: string,
): Promise<AggregatedEmployeeCard> => {
  const {
    data: { latestEmployeeCard },
  } = await client.query({
    query: GET_LATEST_EMPLOYEE_CARD,
    variables: { employeeId, period, allowDraft: false },
    fetchPolicy: 'no-cache',
  });

  return latestEmployeeCard;
};
