import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { RuleValue, RuleValueType } from 'src/types/policy/RuleValue';

export type RuleTypeConfig = {
  defaultRuleValue: RuleValue;
  validValueTypes?: RuleValueType[];
  validRuleValues?: RuleValue[];
};

const ruleTypeConfigMap = new Map<PolicyRuleType, RuleTypeConfig>([
  [
    PolicyRuleType.AGE_LIMIT,
    {
      defaultRuleValue: { value: '65', valueType: 'LESS_OR_EQUALS_THAN' },
      validValueTypes: ['GREATER_THAN', 'GREATER_OR_EQUALS_THAN', 'LESS_OR_EQUALS_THAN', 'LESS_THAN'],
    },
  ],
  [
    PolicyRuleType.YEAR_OF_BIRTH_LIMIT,
    {
      defaultRuleValue: { value: '1979', valueType: 'LESS_THAN' },
      validRuleValues: [
        { value: '1979', valueType: 'LESS_THAN' },
        { value: '1979', valueType: 'GREATER_OR_EQUALS_THAN' },
      ],
    },
  ],
  [
    PolicyRuleType.EMPLOYMENT_START_DATE,
    {
      defaultRuleValue: { value: undefined, valueType: 'BEFORE_OR_WITHIN_PERIOD' },
      validValueTypes: ['BEFORE_OR_WITHIN_PERIOD'],
    },
  ],
  [
    PolicyRuleType.EMPLOYMENT_END_DATE,
    {
      defaultRuleValue: { value: undefined, valueType: 'AFTER_OR_WITHIN_PERIOD' },
      validValueTypes: ['AFTER_OR_WITHIN_PERIOD'],
    },
  ],
  [
    PolicyRuleType.LEAVE_OF_ABSENCE_LIMIT,
    {
      defaultRuleValue: { value: '30', valueType: 'LESS_OR_EQUALS_THAN' },
      validValueTypes: ['LESS_OR_EQUALS_THAN', 'GREATER_THAN'],
    },
  ],
  [
    PolicyRuleType.STUDIES_LEAVE_LIMIT,
    {
      defaultRuleValue: { value: '30', valueType: 'LESS_OR_EQUALS_THAN' },
      validValueTypes: ['LESS_OR_EQUALS_THAN', 'GREATER_THAN'],
    },
  ],
  [
    PolicyRuleType.MILITARY_LEAVE_LIMIT,
    {
      defaultRuleValue: { value: '30', valueType: 'LESS_OR_EQUALS_THAN' },
      validValueTypes: ['LESS_OR_EQUALS_THAN', 'GREATER_THAN'],
    },
  ],
  [
    PolicyRuleType.PARENTAL_LEAVE_LIMIT,
    {
      defaultRuleValue: { value: '330', valueType: 'LESS_OR_EQUALS_THAN' },
      validValueTypes: ['LESS_OR_EQUALS_THAN', 'GREATER_THAN'],
    },
  ],
  [
    PolicyRuleType.SICK_LEAVE_LIMIT,
    {
      defaultRuleValue: { value: '90', valueType: 'LESS_OR_EQUALS_THAN' },
      validValueTypes: ['LESS_OR_EQUALS_THAN'],
    },
  ],
  [
    PolicyRuleType.ALTERNATIVE_SICK_LEAVE_LIMIT,
    {
      defaultRuleValue: { value: '105', valueType: 'LESS_OR_EQUALS_THAN' },
      validValueTypes: ['LESS_OR_EQUALS_THAN'],
    },
  ],
  [
    PolicyRuleType.INDIVIDUAL_CHOICE_ITP1,
    {
      defaultRuleValue: { value: undefined, valueType: 'EQUALS' },
      validValueTypes: ['EQUALS', 'NOT_EQUALS'],
    },
  ],
  [
    PolicyRuleType.WORKING_HOURS_LIMIT,
    {
      defaultRuleValue: { value: '96', valueType: 'GREATER_OR_EQUALS_THAN' },
      validValueTypes: ['GREATER_OR_EQUALS_THAN'],
    },
  ],
]);

export const getDefaultRuleValue = (policyRuleType: PolicyRuleType): RuleValue => {
  return ruleTypeConfigMap.get(policyRuleType).defaultRuleValue;
};

export const getValidRuleValues = (policyRuleType: PolicyRuleType): RuleValue[] => {
  return ruleTypeConfigMap.get(policyRuleType).validRuleValues;
};

export const getValidValueTypes = (policyRuleType: PolicyRuleType): RuleValueType[] => {
  return ruleTypeConfigMap.get(policyRuleType).validValueTypes;
};
