import { gql } from '@apollo/client';

export const GET_REPORTING_PERIOD_DETAILS = gql`
  query GetReportingPeriodDetails($wageFilePeriod: String, $employerIds: [UUID]!) {
    reportingPeriodDetails(wageFilePeriod: $wageFilePeriod, employerIds: $employerIds) {
      wageFilePeriod
      wageFileDetails {
        expectedNumberOfFiles
        receivedNumberOfFiles
        receivedNumberOfEmployees
      }
      dataValidationEventDetails {
        employeesWithUnfinishedCheckpoints
      }
      employmentEventDetails {
        employeesWithUnfinishedCheckpoints
      }
      verifyInsuranceEventDetails {
        employeesWithUnfinishedCheckpoints
      }
      certifyInsuranceEventDetails {
        employeesWithUnfinishedCheckpoints
      }
      reportingEventsDetails {
        employeesWithReportingEvents
        numberOfReportedReportingEvents
      }
    }
  }
`;
