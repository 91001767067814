import { gql } from '@apollo/client';

export const GET_EMPLOYER_INDIVIDUAL_CHOICES = gql`
  query GetEmployerIndividualChoices($employerId: UUID!) {
    employerIndividualChoices(employerId: $employerId) {
      id
      type
      fromWageFile
      fromInvoice
    }
  }
`;
