import { Button, Card, CardAction, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/Common/TextArea';
import { useCreateEmployerKub } from 'src/service/employer/CreateEmployerKub';
import { useKubTypes } from 'src/service/employer/GetKubTypes';
import { EmployerKubRequest } from 'src/types/employer/EmployerKub';
import './create-kub-modal.scss';
import { createOptions } from 'src/util/OptionUtil';

interface CreateKubModalProps {
  open: boolean;
  employerId: string;
  onClose: () => void;
}

const CreateKubModal: React.FunctionComponent<CreateKubModalProps> = ({ open, onClose, employerId }) => {
  const createEmployerKub = useCreateEmployerKub();
  const { t } = useTranslation();
  const kubTypes = useKubTypes();
  const [selectedKubType, setSelectedKubType] = useState<Option<string>>();
  const [kubTypeDescription, setKubTypeDescription] = useState<string>();

  const createButtonDisabled = !selectedKubType;

  const handleKubTypeChange = (option: Option<string>) => {
    setSelectedKubType(option);
  };

  const handleKubTypeDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setKubTypeDescription(event.target.value);
  };

  const handleAddKub = () => {
    const request: EmployerKubRequest = {
      employerId,
      kubType: selectedKubType?.value,
      description: kubTypeDescription,
    };
    createEmployerKub(employerId, request);
    onClose();
  };

  const kubTypeOptions: Option<string>[] = createOptions(kubTypes);

  return (
    <Modal data-cy="create-kub-modal" centered overlayBackground open={open} onClose={onClose}>
      <Card title={t('account:employers-tab.wage-type-mapping-settings.add-kub-modal.title')} size="narrow">
        <div className="create-kub-modal">
          <div>
            <Select
              label={`${t('account:employers-tab.wage-type-mapping-settings.add-kub-modal.kub')}`}
              placeholder={t('common:select')}
              options={kubTypeOptions}
              selected={selectedKubType}
              onChange={handleKubTypeChange}
              small
              mandatory
              outlined
            />
          </div>
          <div>
            <Typography variant="caption">
              {`${t('account:employers-tab.wage-type-mapping-settings.add-kub-modal.description')}`}
            </Typography>
            <TextArea
              value={kubTypeDescription}
              onChange={handleKubTypeDescriptionChange}
              placeholder={`${t(
                'account:employers-tab.wage-type-mapping-settings.add-kub-modal.description-placeholder',
              )}`}
            />
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} data-cy="create-kub-modal__cancel-button" />
          <Button
            label={t('common:add')}
            disabled={createButtonDisabled}
            onClick={handleAddKub}
            data-cy="create-kub-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateKubModal;
