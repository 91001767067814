import { Pill, TableRow, Td, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import './insurance-event-table-row.scss';
import { getCorrectEventValue } from 'src/util/InsuranceEventUtil';

interface InsuranceEventTableRowProps {
  insuranceEvent: InsuranceEvent;
  onDrawerOpen: (insuranceEventId: string) => void;
}

function InsuranceEventTableRow({ insuranceEvent, onDrawerOpen }: InsuranceEventTableRowProps) {
  const { t } = useTranslation();

  return (
    <TableRow
      data-testid={`insurance-event-table-row__${insuranceEvent.insuranceEventId}`}
      onClick={() => onDrawerOpen(insuranceEvent.insuranceEventId)}
    >
      <Td>{stringOrBlank(insuranceEvent.insuranceProvider?.legalName)}</Td>
      <Td>{t(`reportingTypes:${insuranceEvent.reportingType}`)}</Td>
      <Td>{t(`insuranceEventTypes:${insuranceEvent.insuranceEventType}`)}</Td>
      <Td>{t(`benefitTypes:${insuranceEvent.benefitType}`)}</Td>
      <Td>{stringOrBlank(insuranceEvent.employeeInsurance?.insurance?.agreedProduct?.name)}</Td>
      <Td>{stringOrBlank(usePeriodText(insuranceEvent.wageFilePeriod))}</Td>
      <Td>{stringOrBlank(insuranceEvent.eventFromDate)}</Td>
      <Td>
        {t(
          `reporting:insurance-events.certify-insurance-product-condition.${
            !insuranceEvent.certifyInsuranceProductCondition ? 'false' : 'true'
          }`,
        )}
      </Td>
      <Td>
        {insuranceEvent.eventValues.length > 0
          ? insuranceEvent.eventValues.map((eventValue) => (
              <div className="insurance-event-table-row__calculations" key={eventValue.eventValueType}>
                <div className="insurance-event-table-row__calculations--details">
                  <Pill type="neutral" size="small" label={eventValue.eventValueType} />
                  <Typography variant="caption">
                    {formatSweAmountTextWithSuffixNoDecimal(getCorrectEventValue(eventValue))}
                  </Typography>
                </div>
              </div>
            ))
          : '-'}
      </Td>
      <Td>{t(`insuranceEventStatus:${insuranceEvent.status}`)}</Td>
    </TableRow>
  );
}

export default InsuranceEventTableRow;
