import { Button, Icon, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import './info-message.scss';

interface InfoMessageProps {
  message: string;
  linkText?: string;
  linkOnClick?: () => void;
}

const InfoMessage = ({ message, linkText, linkOnClick }: InfoMessageProps) => {
  return (
    <div className="info-message">
      <Icon className="info-message__icon" type="Info" />
      <div className="info-message__content">
        <Typography className="info-message__text" variant="caption">
          {message}
        </Typography>
        {linkText && <Button className="info-message__link" type="inline" label={linkText} onClick={linkOnClick} />}
      </div>
    </div>
  );
};

export default InfoMessage;
