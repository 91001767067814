import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { Option } from 'fgirot-k2-ui-components';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { unique } from 'src/util/ArrayUtil';
import { filterInsuranceEventTypes } from 'src/util/EmployeeFilter';
import { createOptions } from 'src/util/OptionUtil';

const useInsuranceEventFilterProps = (checkpoints: InsuranceEventCheckpoint[][]) => {
  const { t } = useTranslation();
  const [selectedInsuranceEvent, setSelectedInsuranceEvent] = useState<Option<string>[]>([]);
  const insuranceEventTypes = useMemo(
    () => unique(checkpoints.flatMap((checkpoint) => checkpoint.flatMap((c) => c.insuranceEventType))),
    [checkpoints],
  );

  return {
    placeholder: t('employeeFilter:all-subaction-categories'),
    options: createOptions(insuranceEventTypes, t, 'insuranceEventTypes'),
    selected: selectedInsuranceEvent,
    onChange: (input: Option<string>[]): void => {
      setSelectedInsuranceEvent([...input]);
    },
    filter: (employee: InsuranceEventReportEmployeeResponse) =>
      filterInsuranceEventTypes(employee.checkpoints, selectedInsuranceEvent),
  };
};
export default useInsuranceEventFilterProps;
