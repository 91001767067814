import { Checkbox, DrawerNavigation, ListItem, Pill, Typography } from 'fgirot-k2-ui-components';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { EmployeeCard } from 'src/types/EmployeeCard';
import { Employee } from 'src/types/Employee';
import { updateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import {
  isCheckpointStatusMatchingEither,
  isEmploymentEventCheckpointAbleToAcceptWithoutChanges,
} from 'src/util/CheckpointUtil';

interface EmployeeCheckpointsListItemProps {
  checkpoint: EmploymentEventCheckpoint;
  employeeCard: EmployeeCard;
  employee: Employee;
  onClick: () => void;
}

const EmployeeCheckpointsList: React.FC<EmployeeCheckpointsListItemProps> = ({
  checkpoint,
  onClick,
  employee,
  employeeCard,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(isCheckpointStatusMatchingEither(checkpoint, ['ACCEPTED', 'RESOLVED']));
  const [loading, setLoading] = useState<boolean>(false);

  const status = checkpoint.checkpointStatus;
  const hasRaised = status === 'RAISED';
  const hasAccepted = status === 'ACCEPTED';
  const outcome = hasRaised && hasAccepted ? 'heads-up' : hasRaised ? 'warning' : 'success';

  const handleOnChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    updateCheckpointStatusMutation(
      [checkpoint.id],
      e.target.checked ? 'ACCEPTED' : 'RAISED',
      employee.employerId,
    ).finally(() => setLoading(false));
    setChecked(e.target.checked);
  };

  const Label = (
    <div className={styles.labelContainer}>
      <Typography variant={'body2'} bold>
        {t(`periodDifference:${checkpoint.subActionCategory}`)}
      </Typography>
      <span className={styles.pillContainer}>
        <Pill
          key={`examination-rule-pill-${checkpoint.id}`}
          className={styles.employeeInfoTableRow__pillMargin}
          type={outcome}
          label={`${t(`subActionCategories:${checkpoint.subActionCategory}`)}`}
        />
      </span>
    </div>
  );

  const getValue = () => {
    if (checkpoint.subActionCategory === 'GROSS_SALARY_CHANGE') {
      const wage = employeeCard.wages.find((wage) => wage.wageType === 'GROSS_SALARY');
      return `${wage?.amount} ${wage?.currency}`;
    }

    if (checkpoint.subActionCategory === 'RETROACTIVELY_EMPLOYMENT_START_DATE') {
      return employee?.employmentStartDate;
    }

    if (checkpoint.subActionCategory === 'HANDLE_NEGATIVE_KUB') {
      const kubSums = checkpoint.additionalData.kubSums;
      return kubSums?.length ? kubSums.length.toString() + ' kubsumma' : '';
    }
  };

  const checkboxDisabled = !isEmploymentEventCheckpointAbleToAcceptWithoutChanges(checkpoint.subActionCategory);

  return (
    <ListItem className={styles.drawerContainer} compressed>
      <Checkbox
        checked={checked}
        onChange={handleOnChangeChecked}
        className={styles.drawerContainer__checkbox}
        disabled={checkboxDisabled || loading}
      />
      <DrawerNavigation onClick={onClick} label={Label} value={getValue()} />
    </ListItem>
  );
};
export default EmployeeCheckpointsList;
