import { Pill, TableRow, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { getEmploymentGroupNamesForBenefit } from 'src/util/PolicyUtil';
import './policy-benefit-table-row.scss';

interface PolicyBenefitTableRowProps {
  policyBenefit: PolicyBenefit;
  handlePolicyDrawer: () => void;
}

const PolicyBenefitTableRow = ({ policyBenefit, handlePolicyDrawer }: PolicyBenefitTableRowProps) => {
  const { t } = useTranslation();

  const getCalculationValue = () => {
    const pglRuleReferences = policyBenefit.pglRuleReferences;
    const pglRuleReference = pglRuleReferences?.pglRuleReference;
    const benefitKub = policyBenefit.kubType;

    if (pglRuleReference) {
      return pglRuleReference.name;
    }

    if (benefitKub) {
      return benefitKub;
    }

    return '-';
  };

  const getOptional = () => {
    const optional = policyBenefit.connectionRule?.optional;
    if (null == optional) {
      return '-';
    }
    return optional
      ? t('account:employers-tab.policy-settings.table.optional')
      : t('account:employers-tab.policy-settings.table.mandatory');
  };

  return (
    <TableRow onClick={handlePolicyDrawer} className={['policy-benefit-table-row'].join(' ')}>
      <Td>{policyBenefit.name}</Td>
      <Td>
        <Pill type="neutral" label={`${t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)}`} />
      </Td>
      <Td>{getEmploymentGroupNamesForBenefit(policyBenefit)}</Td>
      <Td>{getOptional()}</Td>
      <Td data-cy="policy-benefit-table__calculations-cell">{getCalculationValue()}</Td>
      <Td centered>{policyBenefit.agreedProducts?.length}</Td>
    </TableRow>
  );
};

export default PolicyBenefitTableRow;
