import React, { FC, useState } from 'react';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Button, Icon, Pagination, SortDirection } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { PremiumInvoiceRow } from 'src/types/invoice/PremiumInvoiceRow';
import PremiumInvoiceRowsTable from './PremiumInvoiceRowsTable';
import PremiumInvoiceRowsFiltersModal from './SearchPremiumInvoiceRowsFiltersModal';
import PremiumInvoiceRowsSearchInput from './PremiumInvoiceRowsSearchInput';
import PremiumInvoiceRowsFilterDetails from './PremiumInvoiceRowsFilterDetails';

import { createPageSizeOptions } from 'src/util/OptionUtil';
import { PremiumInvoiceRowsFilter } from 'src/types/invoice/PremiumInvoiceRowsFilter';
import { PremiumInvoiceRowSortField } from './PremiumInvoiceRowsTable/PremiumInvoiceRowSortField';
import { Sort } from 'src/types/common/Sort';

interface PremiumInvoiceRowsProps {
  premiumInvoiceRows: PremiumInvoiceRow[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  searchInput?: string;
  filter: PremiumInvoiceRowsFilter;
  sort: Sort;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: PremiumInvoiceRowsFilter) => void;
  onApplySorting: (property: PremiumInvoiceRowSortField, direction: SortDirection) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const PremiumInvoiceRows: FC<PremiumInvoiceRowsProps> = ({
  premiumInvoiceRows,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  searchInput,
  filter,
  sort,
  onTextSearch,
  onApplyFilters,
  onApplySorting,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
  const showPagination = totalPages > 1;

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  return (
    <>
      <div className="premium-invoice-rows-overview">
        <PremiumInvoiceRowsSearchInput onSearch={onTextSearch} prevSearch={searchInput} />
        <PremiumInvoiceRowsFilterDetails filter={filter} applyFilters={onApplyFilters} />
        <PremiumInvoiceRowsTable premiumInvoiceRows={premiumInvoiceRows} sort={sort} applySorting={onApplySorting} />
        <When condition={showPagination}>
          <div data-testid="premium-invoice-rows-overview-pagination">
            <Pagination
              currentPage={pageNumber + 1}
              totalPages={totalPages}
              onPageChange={onPageChange}
              pageSize={pageSize}
              pageSizeLabel={t('common:results-per-page')}
              pageSizeOptions={pageSizeOptions}
              onPageSizeChange={setPageSize}
              pageSizePosition="left"
            />
          </div>
        </When>
      </div>
      <When condition={filtersModalOpen}>
        <PremiumInvoiceRowsFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filter={filter}
        />
      </When>
      <div className="premium-invoice-rows-overview__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-cy="filter-button"
        />
      </div>
    </>
  );
};

export default PremiumInvoiceRows;
