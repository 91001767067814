import { GET_EMPLOYERS_IN_CUSTOMER } from 'src/graphql/schema/query/employer/GetEmployersInCustomer';
import { Employer } from 'src/types/employer/Employer';
import { Service } from 'src/types/Service';
import { useApolloQuery } from '../graphql/Query';
import { FetchPolicy } from '@apollo/client';

export const useEmployersInCustomer = (customerId: string, fetchPolicy?: FetchPolicy): Service<Employer[]> =>
  useApolloQuery(GET_EMPLOYERS_IN_CUSTOMER, {
    variables: { customerId: customerId },
    fetchPolicy: fetchPolicy || 'no-cache',
    skip: !customerId,
  });
