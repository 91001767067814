export const unique = <T>(array: T[], field?: string): T[] => {
  if (field) {
    return array.filter((value, index, self) => index === self.findIndex((t) => t[field] === value[field]));
  } else {
    return [...new Set(array)];
  }
};

export const arrayToMap = <T>(obj: T[], key: string): Map<string, Array<T>> => {
  return obj?.reduce((acc, item) => {
    const keyValue = item[key];

    if (!acc.has(keyValue)) {
      acc.set(keyValue, []);
    }

    acc.get(keyValue).push(item);

    return acc;
  }, new Map());
};

export const extractParam = <T extends object, S>(list: T[], callback: (item: T) => boolean, param: string): S => {
  const found = list && list.find(callback);

  if (found && found[param]) {
    return found[param];
  } else {
    return undefined;
  }
};

export const arrangeFlatArrayHierarchically = <T extends { id: string; parentId: string }, Z>(
  flatArray: T[],
  childKey: string,
): Z => {
  if (!flatArray || flatArray.length === 0) {
    return null;
  }
  const root = flatArray.find((obj) => !obj.parentId);

  const objectMap = new Map(flatArray.map((obj) => [obj.id, obj]));

  const tree = (object: T) => {
    objectMap.delete(object.id);

    return objectMap.size > 0 && object[childKey]
      ? { ...object, [childKey]: tree(objectMap.get(object[childKey])) }
      : object;
  };

  return { ...root, [childKey]: tree(objectMap.get(root[childKey])) } as Z;
};
export const replaceObjectById = <T extends { id: string }>(arr: T[], object: T): T[] => {
  const objectToReplace = arr.find((o) => o.id === object.id);
  arr[arr.indexOf(objectToReplace)] = object;
  return arr;
};
export const arrangeFlatArrayAsTree = <T extends { id: string; parentId: string }, Z>(
  flatArray: T[],
  childKey: string,
): Z[] => {
  const rootObjects = [...flatArray.map((obj) => ({ ...obj })).filter((t) => !t.parentId)];
  const mapChildren = (childId: string) => {
    const t = flatArray.find((t) => t.id === childId);

    if (t && Array.isArray(t[childKey]) && t[childKey].length > 0) {
      const z: Z = {
        ...t,
        [childKey]: t[childKey].map((id) => ({
          id,
        })),
      } as Z;
      const children = t[childKey].map(mapChildren).filter((id: string) => id !== null);
      z[childKey] = children;
      return z;
    }
    return t;
  };
  return rootObjects.map((t) => {
    const z: Z = {
      ...t,
      [childKey]: t[childKey].map(mapChildren).filter((z: Z) => z !== null),
    } as Z;
    return z;
  }) as Z[];
};
