import { gql } from '@apollo/client';

export const GET_REPORTING_SCHEDULED_JOBS = gql`
  query GetReportingScheduledJobs($employerId: UUID!) {
    reportingScheduledJobs(employerId: $employerId) {
      id
      dayOfMonth
      time
      isLastDayOfMonth
      cronExpression
      insuranceProvider {
        id
        legalName
      }
    }
  }
`;
