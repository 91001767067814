import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckpointsList from './CheckpointsList/CheckpointsList';
import InsuranceEventsOverview from 'src/components/Report/Verify/InsuranceEvents';
import { useEmployersIdLegalName } from 'src/service/employer/GetEmployer';
import { Employer } from 'src/types/employer/Employer';
import EventsSearchInput from 'src/components/Common/EventsSearchInput';
import './verify.scss';

interface VerifyProps {
  customerId: string;
  wageFilePeriod: string;
}

const Verify: React.FC<VerifyProps> = ({ customerId, wageFilePeriod }) => {
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [numberOfCheckPoints, setNumberOfCheckPoints] = React.useState<number>(0);
  const { t } = useTranslation();
  const employers: Employer[] = useEmployersIdLegalName(customerId);

  const onSearch = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  return (
    <>
      <Tabs>
        <Tab title={`${t('verify:control')} (${numberOfCheckPoints})`}>
          <CheckpointsList
            employers={employers}
            wageFilePeriod={wageFilePeriod}
            setNumberOfCheckPoints={setNumberOfCheckPoints}
          />
        </Tab>
        <Tab title={`${t('reporting:insurance-events.insurance-events')}`}>
          <EventsSearchInput onSearch={onSearch} initialSearchInput={searchInput} />
          <div className="verify">
            <InsuranceEventsOverview
              className="button-above-progress-bar"
              reportingPeriod={wageFilePeriod}
              employers={employers}
              searchInput=""
            />
          </div>
        </Tab>
      </Tabs>
    </>
  );
};
export default Verify;
