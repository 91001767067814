import { DrawerNavigation, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { stringOrBlank } from 'src/util/StringUtil';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import CreateWage from 'src/components/Common/Wage';
import React from 'react';
import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import { WageType } from 'src/types/employer/EmployerWageType';
import { useTranslation } from 'react-i18next';
import { ExaminationEmployeeDrawerState } from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/DrawerState';
import styles from './styles.module.scss';
import { CreateEmploymentEventCheckpointWageInput } from 'src/types/reporting/CreateEmploymentEventCheckpointWageInput';
import { useCreateEmploymentEventCheckpointWage } from 'src/service/reporting/CreateEmploymentEventCheckpointWage';

interface UpdateWageTypeSumProps {
  checkpointId: string;
  aggregatedWage: AggregatedWageTypeWage;
  wageType: WageType;
  employerId: string;
  employeeCardId: string;
  onNavigate: (navigationState: ExaminationEmployeeDrawerState) => void;
  currentNavigationState: ExaminationEmployeeDrawerState;
}

const UpdateWageTypeSum: React.FC<UpdateWageTypeSumProps> = ({
  checkpointId,
  aggregatedWage,
  wageType,
  employerId,
  employeeCardId,
  onNavigate,
  currentNavigationState,
}) => {
  const { t } = useTranslation();

  const createWage = useCreateEmploymentEventCheckpointWage();
  const handleCreate = async (request: CreateEmploymentEventCheckpointWageInput) => {
    const createRequest = {
      ...request,
      checkpointId,
    };

    await createWage(createRequest, employerId);
    onNavigate(ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED_DEPTH_1);
  };

  if (currentNavigationState == ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED_DEPTH_2)
    return (
      <CreateWage
        isDraft={false}
        employerId={employerId}
        employeeCardId={employeeCardId}
        onCreate={handleCreate}
        availableWageTypes={[wageType]}
      />
    );

  return (
    <>
      <ListGroup variant="drawer-navigation" className={styles.container}>
        <DrawerNavigation
          disabled={aggregatedWage.amount <= 0}
          label={t('wages:add-wage')}
          onClick={() => onNavigate(ExaminationEmployeeDrawerState.CHECKPOINT_SELECTED_DEPTH_2)}
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        {aggregatedWage.wages.map((wage) => {
          return (
            <InlineEdit
              label={stringOrBlank(wage.payTypeNumber) + (wage.payTypeName ? ' - ' + wage.payTypeName : '')}
              className="wage-type-details-drawer__sum"
            >
              <Typography variant="body2">{formatSweAmountTextWithSuffixWithTwoDecimals(wage.amount)}</Typography>
            </InlineEdit>
          );
        })}
        <InlineEdit bold label={t('wages:sum')} className="wage-type-details-drawer__sum">
          <Typography variant="body2">{formatSweAmountTextWithSuffixWithTwoDecimals(aggregatedWage.amount)}</Typography>
        </InlineEdit>
      </ListGroup>
    </>
  );
};

export default UpdateWageTypeSum;
