import { CacheOperation } from 'src/types/CacheOperation';
import { CertifyConditionMetadata } from 'src/types/CertifyConditionMetadata';
import { unique } from 'src/util/ArrayUtil';

export const insuranceEventCheckpoints = {
  keyArgs: ['employeeId'],

  merge(
    existing: { employeeId: string; employerId: string; conditionsMetaData: CertifyConditionMetadata[] }[] = [],
    incoming: CacheOperation<{
      employeeId: string;
      employerId: string;
      conditionsMetaData: CertifyConditionMetadata[];
    }>,
  ) {
    return incoming.operation === 'ADD'
      ? unique([...existing, incoming.value], 'employeeId')
      : existing.filter((obj) => obj.employeeId !== incoming.id);
  },
};

export const dataValidationEventCheckpoints = {
  merge(
    existing: { employeeId: string; employerId: string }[] = [],
    incoming: CacheOperation<{
      employeeId: string;
      employerId: string;
    }>,
  ) {
    return incoming.operation === 'ADD'
      ? unique([...existing, incoming.value], 'employeeId')
      : existing.filter((obj) => obj.employeeId !== incoming.id);
  },
};

export const employmentEventCheckpoints = {
  merge(
    existing: { employeeId: string; employerId: string }[] = [],
    incoming: CacheOperation<{
      employeeId: string;
      employerId: string;
    }>,
  ) {
    return incoming.operation === 'ADD'
      ? unique([...existing, incoming.value], 'employeeId')
      : existing.filter((obj) => obj.employeeId !== incoming.id);
  },
};
