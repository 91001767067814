import React from 'react';
import { Td } from 'fgirot-k2-ui-components';
import { Employer } from 'src/types/employer/Employer';
import { Employee } from 'src/types/Employee';

interface TdEmployerNameProps {
  employers: Employer[];
  employee: Employee;
}

const TdEmployerName = ({ employers, employee }: TdEmployerNameProps) => {
  return <Td>{employers?.find((employer) => employer.id === employee?.employerId)?.legalName}</Td>;
};

export default TdEmployerName;
