import React from 'react';
import { remunerationTypes } from 'src/types/RemunerationType';
import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import '../../PolicyEmploymentGroupModal/RuleTypeDrawer/rule-type-drawer.scss';
import ToggleRuleComponent from '../Components/ToggleRuleComponent';
import { RuleGroupProps } from '../RuleGroupProps';

const EmploymentRemunerationTypeRule: React.FunctionComponent<RuleGroupProps> = ({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
}) => (
  <ToggleRuleComponent
    policyState={policyState}
    selectedAffiliationRuleGroup={selectedAffiliationRuleGroup}
    onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
    labelResource={'remunerationTypes'}
    values={remunerationTypes}
    policyRuleType={PolicyRuleType.EMPLOYMENT_REMUNERATION_TYPE}
  />
);

export default EmploymentRemunerationTypeRule;
