import { ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoMessage from 'src/components/Common/InfoMessage';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { EmployeeCard } from 'src/types/EmployeeCard';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import MonthlSalaryWageRow from './MonthlySalaryWageRow';
import './update-multiple-monthly-salary-fromdate.scss';

interface UpdateMultipleMonthlySalaryFromDateProps {
  employeeCard: EmployeeCard;
  employerId: string;
}

function UpdateMultipleMonthlySalaryFromDate({ employeeCard, employerId }: UpdateMultipleMonthlySalaryFromDateProps) {
  const { t } = useTranslation();

  const currentPeriod = employeeCard.period;
  const employerWageTypes = useEmployerWageTypes(employerId);

  return (
    <section className="multiple-monthly-salary-fromdate">
      <div className="multiple-monthly-salary-fromdate__period-header">
        <Typography variant="body2">{t('examination:drawer.current-period')}</Typography>
        <PeriodPill period={currentPeriod} />
      </div>

      <div>
        {employeeCard.wages
          .filter((w) => w.wageType === 'MONTHLY_SALARY')
          .map((wage) => {
            const wageType = employerWageTypes.find((wt) => wt.id === wage.employerWageTypeId);
            return (
              <React.Fragment key={wage.id}>
                <div className="multiple-monthly-salary-fromdate__wage-type-row-section">
                  <Typography
                    bold
                    variant="body2"
                  >{`${wageType?.name}: ${wage.payTypeNumber} - ${wage.payTypeName}`}</Typography>
                  <ListGroup variant="inline-edit">
                    <MonthlSalaryWageRow
                      key={wage.employerWageTypeId}
                      wage={wage}
                      employeeCardId={employeeCard.id}
                      employerId={employerId}
                    />
                  </ListGroup>
                </div>
              </React.Fragment>
            );
          })}
      </div>
      <InfoMessage message={t('examination:drawer.multiple-monthly-salary-rows-in-period')} />
    </section>
  );
}

const PeriodPill = ({ period }: { period: string }) => {
  const periodText = usePeriodText(period);
  return <Pill type="neutral" label={periodText} />;
};

export default UpdateMultipleMonthlySalaryFromDate;
