import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_REPORTING_PERIOD_DETAILS } from 'src/graphql/schema/query/reporting/GetReportingPeriodDetails';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { ReportingPeriodDetails } from 'src/types/reporting/ReportingPeriodDetails';

export const useReportingPeriodDetails = (
  employerIds: string[],
  wageFilePeriod?: string,
): Service<ReportingPeriodDetails> => {
  const [result, setResult] = useState<Service<ReportingPeriodDetails>>({
    status: INIT,
  });

  const { loading, error, data } = useQuery(GET_REPORTING_PERIOD_DETAILS, {
    variables: { wageFilePeriod, employerIds },
    skip: !employerIds?.length,
  });

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data && setResult({ status: LOADED, payload: data.reportingPeriodDetails });
  }, [loading, error, data]);

  return result;
};
