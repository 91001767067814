import { useApolloMutation } from '../graphql/Mutation';
import { PATCH_CONTRACT_SETTING } from 'src/graphql/schema/mutation/contract/PatchContractSetting';
import { GET_CONTRACT_SETTING } from 'src/graphql/schema/query/contract/GetContractSetting';
import { PatchContractSettingInput } from 'src/types/contract/ContractSetting';

export const usePatchContractSetting = () => {
  const apolloMutation = useApolloMutation();

  const patchContractSetting = (employerId: string, patchContractSettingInput: PatchContractSettingInput) =>
    apolloMutation(PATCH_CONTRACT_SETTING, employerId, { request: patchContractSettingInput }, [GET_CONTRACT_SETTING]);

  return patchContractSetting;
};
