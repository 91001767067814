import { gql } from '@apollo/client';

export const GET_PAYMENT_DUE_DATES = gql`
  query GetPaymentDueDates($invoiceHeaderId: UUID!) {
    paymentDueDates(invoiceHeaderId: $invoiceHeaderId) {
      id
      dueDate
      active
      note
    }
  }
`;
