import { gql } from '@apollo/client';

export const GET_EMPLOYEE_INDIVIDUAL_CHOICES = gql`
  query GetEmployeeIndividualChoices($employeeId: UUID!, $allowDraft: Boolean) {
    employeeIndividualChoices(employeeId: $employeeId, allowDraft: $allowDraft) {
      isDraft
      individualChoices {
        id
        type
        source
        fromPeriod
        individualAge
      }
    }
  }
`;
