import { gql } from '@apollo/client';
export const SEARCH_INVOICE_ROWS = gql`
  query SearchInvoiceRows($invoiceHeaderId: UUID!, $request: SearchInvoiceRowsRequest) {
    searchInvoiceRows(invoiceHeaderId: $invoiceHeaderId, request: $request) {
      paging {
        hasNext
        totalNumberOfElements
      }
      searchInvoiceRows {
        id
        invoiceRowNumber
        note
        category
        insuranceNumber
        insuranceId
        amount
        insuranceTypeMapping {
          id
          name
          benefitType
        }
        invoicePeriod {
          periodStart
          periodEnd
        }
        employee {
          id
          employerId
          employmentNumber
          firstName
          lastName
          personNumber
        }
        contractBenefitGroup
        contractBenefitGroupName
      }
    }
  }
`;
