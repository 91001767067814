import React, { FC, useEffect } from 'react';
import { AccountingAdjustmentCondition } from 'src/types/accountingunit/AccountingAdjustmentCondition';
import { useReferenceOptions } from './Hooks';
import { Button, Icon, Option, Select } from 'fgirot-k2-ui-components';
import { AccountingAdjustmentConditionType } from 'src/types/accountingunit/AccountingAdjustmentConditionTypes';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FlexibleConditionSelects = ({
  accountindAdjustmentCondition,
  employerId,
  updateAccountingAdjustmentCondition,
}: {
  accountindAdjustmentCondition: AccountingAdjustmentCondition;
  employerId: string;
  updateAccountingAdjustmentCondition: (accountindAdjustmentCondition: AccountingAdjustmentCondition) => void;
}) => {
  const { t } = useTranslation();
  const [selectedAccountingComponentType, setSelectedAccountingComponentType] = React.useState<Option<string>>();
  const [referenceOptions, accountingComponentValues] = useReferenceOptions(
    accountindAdjustmentCondition.accountingAdjustmentConditionType,
    employerId,
  );

  useEffect(() => {
    setSelectedAccountingComponentType(referenceOptions[0]);
  }, [referenceOptions]);

  const handleAccountingComponentTypeSelect = (e: Option<string>) => {
    setSelectedAccountingComponentType({ label: e.label, value: e.value });
  };

  const handleReferenceChange = (option: Option<string>) =>
    updateAccountingAdjustmentCondition({
      ...accountindAdjustmentCondition,
      reference: { name: option.label, id: option.value },
    });

  const mappedAccountingComponentValues = accountingComponentValues
    .get(selectedAccountingComponentType?.value)
    ?.accountingComponentValues.map((object) => ({ label: object.name, value: object.id }));

  const mappedAccountingComponentValue = accountingComponentValues
    .get(selectedAccountingComponentType?.value)
    ?.accountingComponentValues.find((option) => option.id === accountindAdjustmentCondition.reference.id);

  if (accountindAdjustmentCondition.accountingAdjustmentConditionType === null) {
    return null;
  } else if (accountindAdjustmentCondition.accountingAdjustmentConditionType === 'ACCOUNTING_COMPONENT_VALUE') {
    return (
      <>
        <Select
          data-cy="condition-reference-type-select"
          selected={referenceOptions.find((option) => option.label === selectedAccountingComponentType?.label)}
          options={referenceOptions}
          label={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          onChange={handleAccountingComponentTypeSelect}
          allowSelectMaxWidth
        />

        <Select
          data-cy="condition-reference-select"
          selected={{ label: mappedAccountingComponentValue?.name, value: mappedAccountingComponentValue?.id }}
          options={mappedAccountingComponentValues ?? []}
          label={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
          disabled={!accountindAdjustmentCondition.accountingAdjustmentConditionType}
          onChange={handleReferenceChange}
          allowSelectMaxWidth
        />
      </>
    );
  }
  return (
    <Select
      data-cy="condition-reference-select"
      selected={referenceOptions.find((option) => option.value === accountindAdjustmentCondition.reference.id)}
      options={referenceOptions}
      label={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
      placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-value')}
      disabled={!accountindAdjustmentCondition.accountingAdjustmentConditionType}
      onChange={handleReferenceChange}
      allowSelectMaxWidth
    />
  );
};
interface ConditionSelectsProps {
  accountindAdjustmentCondition: AccountingAdjustmentCondition;
  updateAccountingAdjustmentCondition: (accountindAdjustmentCondition: AccountingAdjustmentCondition) => void;
  deleteAccountingAdjustmentCondition: (id: string) => void;
  conditionTypeOptions: Option<AccountingAdjustmentConditionType>[];
}
const ConditionSelects: FC<ConditionSelectsProps> = ({
  accountindAdjustmentCondition,
  updateAccountingAdjustmentCondition,
  deleteAccountingAdjustmentCondition,
  conditionTypeOptions,
}) => {
  const { employerId } = useParams();
  const { t } = useTranslation();
  const handleConditionTypeChange = (option: Option<AccountingAdjustmentConditionType>) =>
    updateAccountingAdjustmentCondition({
      ...accountindAdjustmentCondition,
      accountingAdjustmentConditionType: option.value,
    });

  const handleDeleteCondition = () => {
    deleteAccountingAdjustmentCondition(accountindAdjustmentCondition.id);
  };

  return (
    <div className="accounting-adjustments-tab__add-rule-modal--select-section">
      <Select
        data-cy="condition-type-select"
        selected={conditionTypeOptions.find(
          (option) => option.value === accountindAdjustmentCondition.accountingAdjustmentConditionType,
        )}
        options={conditionTypeOptions}
        label={t('economySettings:accounting-adjustment-tab.modal.choose-condition-type')}
        placeholder={t('economySettings:accounting-adjustment-tab.modal.choose-action-type')}
        onChange={handleConditionTypeChange}
        allowSelectMaxWidth
      />

      <div className="accounting-adjustments-tab__add-rule-modal--value-selection">
        <FlexibleConditionSelects
          accountindAdjustmentCondition={accountindAdjustmentCondition}
          employerId={employerId}
          updateAccountingAdjustmentCondition={updateAccountingAdjustmentCondition}
        />
      </div>
      <Button type="secondary" icon={<Icon type="Trash" />} onClick={handleDeleteCondition} />
    </div>
  );
};

export default ConditionSelects;
