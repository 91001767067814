import { gql } from '@apollo/client';

export const CREATE_GROUP = gql`
  mutation CreateGroup($request: CreateGroupInput!) {
    createGroup(request: $request) {
      id
      name
    }
  }
`;
