import { CreateAccountingRowInput, UpdateAccountingRowInput } from 'src/types/accountingunit/AccountingRow';
import { UPDATE_ACCOUNTING_ROW } from 'src/graphql/schema/mutation/accountingunit/UpdateAccountingRow';
import { CREATE_ACCOUNTING_ROW } from 'src/graphql/schema/mutation/accountingunit/CreateAccountingRow';
import { ACCOUNTING_UNIT } from 'src/graphql/schema/query/accountingunit/AccountingUnit';
import { ACCOUNTING_ROWS } from 'src/graphql/schema/query/accountingunit/AccountingRows';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateAccountingRow = () => {
  const apolloMutation = useApolloMutation(false);

  const updateAccountingRow = (request: UpdateAccountingRowInput, employerId: string) =>
    apolloMutation(UPDATE_ACCOUNTING_ROW, employerId, { request }, [ACCOUNTING_UNIT, ACCOUNTING_ROWS]);

  return updateAccountingRow;
};

export const useCreateAccountingRow = () => {
  const apolloMutation = useApolloMutation();

  const createAccountingRow = (request: CreateAccountingRowInput, employerId: string) =>
    apolloMutation(CREATE_ACCOUNTING_ROW, employerId, { request }, [ACCOUNTING_UNIT, ACCOUNTING_ROWS]);

  return createAccountingRow;
};
