import React, { useState } from 'react';
import { EmployeeInsurance } from 'src/types/insurance/Insurance';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import {
  EmployeeInsurancesFilters,
  EmployeeInsurancesRequest,
} from 'src/types/employees/request/EmployeeInsurancesRequest';
import When from 'src/components/Common/When';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Paging } from 'src/types/PageableResponse';
import InsurancesSearchInput from './InsurancesSearchInput';
import InsurancesFilterDetails from './InsurancesFilterDetails';
import InsurancesTable from './InsurancesTable';
import InsurancesFilterModal from './InsurancesFilterModal';
import InsuranceDetailsDrawer from '../InsuranceDetailsDrawer';

interface InsurancesProps {
  employeeInsurances: EmployeeInsurance[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  request: EmployeeInsurancesRequest;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: EmployeeInsurancesFilters) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const Insurances = ({
  employeeInsurances,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  request,
  onTextSearch,
  onApplyFilters,
  onPageChange,
}: InsurancesProps) => {
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = getTotalPages(paging?.totalNumberOfElements, request.pageSize);
  const showPagination = totalPages > 1;

  const [selectedEmployeeInsurance, setSelectedEmployeeInsurance] = useState<EmployeeInsurance>();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleOpenModal = () => {
    setFiltersModalOpen(true);
  };

  const handleOpenDrawer = (employeeInsurance: EmployeeInsurance) => setSelectedEmployeeInsurance(employeeInsurance);
  const handleCloseDrawer = () => setSelectedEmployeeInsurance(undefined);

  return (
    <div className="insurances-overview">
      <InsurancesSearchInput searchRequest={request} onSearch={onTextSearch} />
      <InsurancesFilterDetails applyFilters={onApplyFilters} request={request} />
      <InsurancesTable employeeInsurances={employeeInsurances} onDrawerOpen={handleOpenDrawer} />
      <When condition={showPagination}>
        <div data-testid="insurances-overview-pagination">
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            pageSizeLabel={t('common:results-per-page')}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={setPageSize}
            pageSizePosition="left"
          />
        </div>
      </When>
      <div className="insurances-overview__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleOpenModal}
          data-cy="filter-button"
        />
      </div>
      {filtersModalOpen && (
        <InsurancesFilterModal
          employeeInsurances={employeeInsurances}
          open={filtersModalOpen}
          onClose={handleCloseModal}
          filters={request?.filters}
          onApplyFilters={onApplyFilters}
        />
      )}
      <When condition={selectedEmployeeInsurance !== undefined}>
        <InsuranceDetailsDrawer
          onClose={handleCloseDrawer}
          drawerOpen={selectedEmployeeInsurance !== undefined}
          replaceSelectedInsurance={handleOpenDrawer}
          employeeInsurances={employeeInsurances}
          employeeInsurance={employeeInsurances?.find((selected) => selected === selectedEmployeeInsurance)}
        />
      </When>
    </div>
  );
};

export default Insurances;
