import { gql } from '@apollo/client';

export const INSURANCE_TYPE_MAPPINGS = gql`
  query GetInsuranceTypeMappings {
    insuranceTypeMappings {
      id
      name
      benefitType
      taxRule
      insuranceCategory
      premiumSource
      identifier
      insuranceProvider {
        id
        legalName
        organisationNumber
      }
      invoiceRowMappings {
        id
        invoiceRowName
      }
    }
  }
`;
