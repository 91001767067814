import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query {
    roles {
      id
      customerId
      name
      permissions {
        value
      }
    }
  }
`;
