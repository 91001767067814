import { gql } from '@apollo/client';

export const ACCOUNTING_ADJUSTMENT_RULES = gql`
  query GetAccountingAdjustmentRules($employerId: UUID!) {
    accountingAdjustmentRules(employerId: $employerId) {
      id
      accountingAdjustmentConditions {
        id
        accountingAdjustmentConditionType
        reference {
          id
          name
          typeName
        }
      }
      accountingAdjustmentAction {
        id
        accountingAdjustmentActionType
        reference {
          id
          name
          typeName
        }
      }
    }
  }
`;
