import { DocumentNode, useSubscription } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Service } from 'src/types/Service';
import { LOADED } from 'src/types/Status';
import { EmployeeIndividualSettings } from 'src/types/employees/EmployeeIndividualSettings';

export const useEmployeeIndividualSettingsCreatedSubscription = <T extends EmployeeIndividualSettings>(
  subscription: DocumentNode,
  employeeId: string,
  setResult: Dispatch<SetStateAction<Service<T>>>,
) => {
  const { data } = useSubscription(subscription, {
    variables: { employeeIds: [employeeId] },
    fetchPolicy: 'no-cache',
    skip: !employeeId,
  });

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const individualSettings = data.individualSettingsCreated;
      setResult((prev) => {
        if (prev.status === LOADED) {
          return {
            ...prev,
            payload: {
              ...prev.payload,
              individualSettingsRows: [...individualSettings.individualSettingsRows],
            },
          };
        } else {
          return prev;
        }
      });
    }
  }, [data, subscription]);
};
