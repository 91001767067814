import { Icon, TableRow, Td } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import './employer-individual-choice-row.scss';

interface EmployerIndividualChoiceRowProps {
  individualChoice: EmployerIndividualChoice;
  onSelectIndividualChoice: (individualChoice: EmployerIndividualChoice) => void;
}

function EmployerIndividualChoiceRow({ individualChoice, onSelectIndividualChoice }: EmployerIndividualChoiceRowProps) {
  const { t } = useTranslation();

  const handleClick = () => onSelectIndividualChoice(individualChoice);

  return (
    <TableRow
      className="employer-individual-choice-row"
      onClick={handleClick}
      data-testid={`employer-individual-choice-row-${individualChoice.id}`}
    >
      <Td>{t(`individualChoiceTypes:${individualChoice.type}`)}</Td>
      <Td>
        {individualChoice.fromWageFile && <Icon type="Check" stroke="#218379" />}
        <span className="employer-individual-choice-row__sr-only">{t(`common:${individualChoice.fromWageFile}`)}</span>
      </Td>
      <Td>
        {individualChoice.fromInvoice && <Icon type="Check" stroke="#218379" />}
        <span className="employer-individual-choice-row__sr-only">{t(`common:${individualChoice.fromInvoice}`)}</span>
      </Td>
    </TableRow>
  );
}

export default EmployerIndividualChoiceRow;
