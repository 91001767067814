export const isBlank = (str: string): boolean => {
  return str === null || str === undefined || str.match(/^ *$/) !== null;
};

export const isNotBlank = (str: string): boolean => {
  return !isBlank(str);
};

export const stringOrBlank = (str: unknown): string => {
  if (str === 0) {
    return '0';
  }
  return str ? `${str}` : '-';
};

export const toNumber = (str: string) => parseFloat(str.replace(/[^0-9.]/g, ''));
