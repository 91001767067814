import { UPDATE_EMPLOYEE } from 'src/graphql/schema/mutation/employee/UpdateEmployee';
import { UpdateEmployee } from 'src/types/UpdateEmployee';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateEmployee = () => {
  const apolloMutation = useApolloMutation();

  const updateEmployee = (request: UpdateEmployee, employerId: string) =>
    apolloMutation(UPDATE_EMPLOYEE, employerId, { request });

  return updateEmployee;
};
