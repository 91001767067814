import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { Icon, Input } from 'fgirot-k2-ui-components';

interface PremiumInvoiceRowsSearchInputProps {
  prevSearch?: string;
  onSearch: (searchInput: string) => void;
  disabled?: boolean;
}

const PremiumInvoiceRowsSearchInput = ({ onSearch, disabled, prevSearch }: PremiumInvoiceRowsSearchInputProps) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(prevSearch);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (prevSearch !== event.target.value) {
      onSearch(searchInput);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchInput);
    } else if (e.key === 'Escape') {
      setSearchInput('');
    }
  };

  return (
    <Input.Text
      disabled={disabled}
      placeholder={t('premiumInvoiceRows:search-placeholder')}
      type="search"
      icon={<Icon type="Search" />}
      value={searchInput}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
    />
  );
};

export default PremiumInvoiceRowsSearchInput;
