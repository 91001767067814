import { CREATE_ACCOUNTING_UNIT } from 'src/graphql/schema/mutation/accountingunit/CreateAccountingUnit';
import { ACCOUNTING_UNITS } from 'src/graphql/schema/query/accountingunit/AccountingUnits';
import { CreateAccountingUnitInput } from 'src/types/accountingunit/AccountingUnit';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateAccountingUnit = () => {
  const apolloMutation = useApolloMutation();

  const createAccountingUnit = (request: CreateAccountingUnitInput, employerId: string) =>
    apolloMutation(CREATE_ACCOUNTING_UNIT, employerId, { request }, [ACCOUNTING_UNITS]);

  return createAccountingUnit;
};
