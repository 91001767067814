import { Button, ListGroup } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkpoint, EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import EmployeeCheckpointsListItem from './EmployeeCheckpointsListItem';
import styles from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/styles.module.scss';
import { finalizeCheckpoints } from 'src/service/checkpoint/FinalizeCheckpointsMutation';
import { Employee } from 'src/types/Employee';
import { useParams } from 'react-router-dom';
import { EmployeeCard } from 'src/types/EmployeeCard';

interface EmployeeCheckpointsListProps {
  checkpoints: EmploymentEventCheckpoint[];
  onSelectCheckpoint: (checkpoint: Checkpoint) => void;
  employee: Employee;
  employeeCard: EmployeeCard;
  onFinalize: () => void;
}

const EmployeeCheckpointsList: React.FC<EmployeeCheckpointsListProps> = ({
  onFinalize,
  employee,
  checkpoints,
  onSelectCheckpoint,
  employeeCard,
}) => {
  const { t } = useTranslation();
  const { year, month } = useParams();
  const handleFinalizeCheckpoints = () => {
    const employeeId = employee.id;
    const employerId = employee.employerId;
    finalizeCheckpoints([{ employeeId, employerId }], `${year}${month}`, 'EMPLOYMENT_EVENT').then(onFinalize);
  };

  return (
    <>
      <ListGroup variant="drawer-navigation">
        {checkpoints.map((checkpoint) => (
          <EmployeeCheckpointsListItem
            employeeCard={employeeCard}
            employee={employee}
            checkpoint={checkpoint}
            key={checkpoint.id}
            onClick={() => onSelectCheckpoint(checkpoint)}
          />
        ))}
      </ListGroup>
      <Button
        className={styles.finalizeButton}
        type={'primary'}
        disabled={!!checkpoints.find((checkPoint) => checkPoint.checkpointStatus == 'RAISED')}
        onClick={handleFinalizeCheckpoints}
        label={t(`verify:drawer.complete`)}
      />
    </>
  );
};
export default EmployeeCheckpointsList;
