import { t } from 'i18next';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AgreementRegistryOverview from 'src/components/Account/AgreementRegistrySettings/AgreementRegistryOverview/AgreementRegistryOverview';
import CreateContractModal from 'src/components/Account/AgreementRegistrySettings/CreateContractModal';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import PageBase from 'src/components/PageBase';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { useNavigateWithFallback } from 'src/service/routes/LocationHooks';
import { Employer } from 'src/types/employer/Employer';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import FloatingAddButton from 'src/components/Common/FloatingAddButton/FloatingAddButton';

const AgreementRegistrySettings = () => {
  const { customerName, employerId } = useParams();
  const [createContractModalOpen, setCreateContractModalOpen] = useState(false);

  const handleBackClick = useNavigateWithFallback(-1, '/');

  const handleOpenCreateContractModal = () => {
    setCreateContractModalOpen(true);
  };

  const handleCloseCreateContractModal = () => {
    setCreateContractModalOpen(false);
  };

  return (
    <QueryWrapper query={GET_EMPLOYER} options={{ variables: { employerId } }}>
      {(employer: Employer) => {
        const breadCrumbs = [
          {
            name: customerName,
            steps: 2,
          },
          {
            name: employer?.legalName,
            steps: 1,
          },
        ];
        return (
          <PageBase
            title={t('agreementRegistrySettings:agreement-registry')}
            handleBackButton={handleBackClick}
            breadCrumbs={breadCrumbs}
          >
            <QueryWrapper query={GET_INSURANCE_PROVIDER_CONTRACTS} type="list" options={{ variables: { employerId } }}>
              {(insuranceProviderContracts: InsuranceProviderContract[]) => (
                <AgreementRegistryOverview insuranceProviderContracts={insuranceProviderContracts} />
              )}
            </QueryWrapper>
            {createContractModalOpen && (
              <CreateContractModal
                open={createContractModalOpen}
                onClose={handleCloseCreateContractModal}
                employerId={employerId}
              />
            )}
            <FloatingAddButton
              data-cy="add-contract-button"
              label={t('agreementRegistrySettings:add-contract')}
              onClick={handleOpenCreateContractModal}
            />
          </PageBase>
        );
      }}
    </QueryWrapper>
  );
};

export default AgreementRegistrySettings;
