import { gql } from '@apollo/client';

export const CREATE_ACCOUNTING_COMPONENT = gql`
  mutation CreateAccountingComponent($request: CreateAccountingComponentInput!) {
    createAccountingComponent(request: $request) {
      id
      parentId
      name
      type
      share
    }
  }
`;
