import { gql } from '@apollo/client';

export const GET_EMPLOYEE_CARD = gql`
  query GetEmployeeCard($employeeCardId: UUID!) {
    employeeCard(employeeCardId: $employeeCardId) {
      id
      employeeId
      evaluationPolicyEntityId
      employmentGroupId
      employmentCategory
      remunerationType
      sickDays
      workingPercentage
      employmentType
    }
  }
`;

export const GET_EMPLOYEE_CARD_WITH_WAGES = gql`
  query GetEmployeeCard($employeeCardId: UUID!) {
    employeeCard(employeeCardId: $employeeCardId) {
      id
      employeeId
      evaluationPolicyEntityId
      employmentGroupId
      employmentCategory
      remunerationType
      sickDays
      workingPercentage
      employmentType
      grossSalaryFromDate
      period
      wages {
        id
        wageType
        employerWageTypeId
        payTypeNumber
        payTypeName
        payedAmount
        kubTypes
        amount
        currency
        fromDate
        toDate
      }
    }
  }
`;
