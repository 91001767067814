import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRow, Th, TableBody, Icon, Option } from 'fgirot-k2-ui-components';
import FilterComponent from 'src/components/Common/FilterComponent';
import TableBase from 'src/components/Common/TableBase';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { createOptions } from 'src/util/OptionUtil';
import { unique } from 'src/util/ArrayUtil';
import './policy-benefits.scss';
import PolicySettingsTableRow from './PolicySettingsTableRow';
import PolicyBenefitDrawer from '../PolicyBenefitDrawer';
import CreateBenefitModal from './CreateBenefitModal';
import './policy-benefits.scss';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';

interface PolicyBenefitsProps {
  policyEntity: PolicyEntity;
  policyEmploymentGroups: PolicyEmploymentGroup[];
  policyBenefits: PolicyBenefit[];
  employerId: string;
}

const PolicyBenefits = ({ policyEntity, policyEmploymentGroups, policyBenefits, employerId }: PolicyBenefitsProps) => {
  const { t } = useTranslation();
  const [policyDrawerOpen, setPolicyDrawerOpen] = useState(false);
  const [policyBenefitId, setPolicyBenefitId] = useState<string>(null);
  const [selectedBenefitTypes, setSelectedBenefitTypes] = useState<Option<string>[]>([]);
  const [selectedEmploymentGroups, setSelectedEmploymentGroups] = useState<Option<string>[]>([]);
  const [createBenefitModalOpen, setCreateBenefitModalOpen] = useState(false);

  const handleOpenCreateBenefitModal = () => {
    setCreateBenefitModalOpen(true);
  };

  const handleCloseCreateBenefitModal = () => {
    setCreateBenefitModalOpen(false);
  };

  const head = [
    { name: t('account:employers-tab.policy-settings.table.benefit'), centered: false },
    { name: t('account:employers-tab.policy-settings.table.benefit-type'), centered: false },
    { name: t('account:employers-tab.policy-settings.table.employmentGroup'), centered: false },
    { name: t('account:employers-tab.policy-settings.table.connection'), centered: false },
    { name: t('account:employers-tab.policy-settings.table.pgl'), centered: false },
    { name: t('account:employers-tab.policy-settings.table.products'), centered: true },
  ];

  const handleDrawerOpen = (policyBenefitId: string) => {
    setPolicyDrawerOpen(true);
    setPolicyBenefitId(policyBenefitId);
  };

  const handleDrawerClose = () => {
    setPolicyDrawerOpen(false);
    setPolicyBenefitId(null);
  };

  const benefitTypes = unique(policyBenefits.map((benefit) => benefit.benefitType));

  const benefitTypeFilterProps = {
    placeholder: t('account:employers-tab.policy-settings.table.all-benefits'),
    options: createOptions(benefitTypes, t, 'benefitTypes:abbreviations'),
    selected: selectedBenefitTypes,
    onChange: (input: Option<string>[]): void => {
      setSelectedBenefitTypes([...input]);
    },
  };

  const employmentGroupOptions = policyEmploymentGroups.map((group) => ({
    value: group.id,
    label: group.employmentGroupName,
  }));

  const policyEmploymentGroupsProps = {
    placeholder: t('account:employers-tab.policy-settings.table.all-policy-employment-groups'),
    options: employmentGroupOptions,
    selected: selectedEmploymentGroups,
    onChange: (input: Option<string>[]): void => {
      setSelectedEmploymentGroups([...input]);
    },
  };

  const filterBenefitByType = (policyBenefit: PolicyBenefit): boolean => {
    return selectedBenefitTypes.length > 0
      ? selectedBenefitTypes.some((benefitType) => policyBenefit.benefitType === benefitType.value)
      : true;
  };

  const filterBenefitByGroup = (policyBenefit: PolicyBenefit): boolean => {
    return selectedEmploymentGroups.length > 0
      ? selectedEmploymentGroups.some((selectedGroup) =>
          policyBenefit.policyEmploymentGroups.some((gr) => gr.id === selectedGroup.value),
        )
      : true;
  };

  const filteredPolicyBenefits = policyBenefits
    .filter((benefit) => filterBenefitByType(benefit))
    .filter((benefit) => filterBenefitByGroup(benefit));

  return (
    <>
      <div className={['policy-benefits'].join(' ')}>
        <FilterComponent selectProps={[benefitTypeFilterProps, policyEmploymentGroupsProps]} />
        <TableBase maxHeightPercentage={50}>
          <TableHead filled>
            <TableRow>
              {head.map((title, index) => (
                <Th centered={title.centered} key={`EmploymentControlPointSettingsTable-${title.name}-${index}`}>
                  {title.name}
                </Th>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPolicyBenefits.map((benefit) => (
              <PolicySettingsTableRow
                handlePolicyDrawer={() => handleDrawerOpen(benefit.id)}
                key={`policy-settings-row--${benefit.id}`}
                policyBenefit={benefit}
              />
            ))}
          </TableBody>
        </TableBase>
      </div>
      {policyDrawerOpen && (
        <PolicyBenefitDrawer
          policyBenefit={policyBenefits.find((benefit) => benefit.id === policyBenefitId)}
          policyId={policyEntity.id}
          policyEntityState={policyEntity.state}
          open={policyDrawerOpen}
          onClose={handleDrawerClose}
          employerId={employerId}
        />
      )}
      {createBenefitModalOpen && (
        <CreateBenefitModal
          open={createBenefitModalOpen}
          employerId={employerId}
          onClose={handleCloseCreateBenefitModal}
          policyId={policyEntity.id}
          employmentGroups={policyEmploymentGroups}
        />
      )}
      {policyEntity?.state === 'DRAFT' && (
        <FloatingAddButton
          icon={<Icon type={'PlusLarge'} />}
          label={t('policySettings:benefits-tab.add-benefit')}
          onClick={handleOpenCreateBenefitModal}
        />
      )}
    </>
  );
};

export default PolicyBenefits;
