export interface RuleValue {
  id?: string;
  value: string;
  valueType: RuleValueType;
  condition?: string;
}

export type RuleValueType =
  | 'AFTER_OR_WITHIN_PERIOD'
  | 'BEFORE_OR_WITHIN_PERIOD'
  | 'GREATER_THAN'
  | 'GREATER_OR_EQUALS_THAN'
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'LESS_THAN'
  | 'LESS_OR_EQUALS_THAN';

export enum RuleValueCharCode {
  GREATER_THAN = 62, // >
  GREATER_OR_EQUALS_THAN = 8805, // >=
  LESS_THAN = 60, //  <
  LESS_OR_EQUALS_THAN = 8804, // <=
}
