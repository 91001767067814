import { PostingRulesRequest } from 'src/types/accountingunit/PostingRulesRequest';
import { insuranceCategories } from 'src/types/insurancetypemapping/InsuranceTypeMapping';
export const getInitialPostingRulesRequest = (): PostingRulesRequest => {
  return {
    searchInput: '',
    pageNumber: 0,
    pageSize: 25,
    filters: {
      insuranceCategoryFilter: insuranceCategories,
    },
  };
};
