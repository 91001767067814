import { client } from 'src/graphql';
import { UPDATE_CEHCKPOINT_STATUS } from 'src/graphql/schema/mutation/checkpoint/UpdateCheckpointStatus';
import { CheckpointStatus } from 'src/types/DataValidationCheckpoint';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_INSURANCE_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetInsuranceEventCheckpoints';
import { GET_INSURANCE_EVENTS } from 'src/graphql/schema/query/insurance/GetInsuranceEvents';
import { GET_INSURANCE_EVENT_COMMENTS } from 'src/graphql/schema/query/insurance/GetInsuranceEventComments';

export const useUpdateCheckpointStatusMutation = () => {
  const apolloMutation = useApolloMutation();

  const updateCheckpointStatusMutation = (
    checkpointIds: string[],
    checkpointStatus: CheckpointStatus,
    employerId: string,
  ) =>
    apolloMutation(UPDATE_CEHCKPOINT_STATUS, employerId, { checkpointIds, checkpointStatus }, [
      GET_INSURANCE_EVENT_CHECKPOINTS,
      GET_INSURANCE_EVENTS,
      GET_INSURANCE_EVENT_COMMENTS,
    ]);

  return updateCheckpointStatusMutation;
};

export const updateCheckpointStatusMutation = async (
  checkpointIds: string[],
  checkpointStatus: CheckpointStatus,
  employerId,
) => {
  await client.mutate({
    mutation: UPDATE_CEHCKPOINT_STATUS,
    variables: {
      checkpointIds,
      checkpointStatus,
    },
    context: {
      employerId,
    },
  });
};
