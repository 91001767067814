import { gql } from '@apollo/client';

export const UPSERT_DRAFT_EMPLOYEE_CARD_GROSS_SALARY_WAGE = gql`
  mutation UpsertDraftEmployeeCardGrossSalaryWage($request: UpsertDraftEmployeeCardGrossSalaryWage) {
    upsertDraftEmployeeCardGrossSalaryWage(request: $request) {
      id
      wageType
      employerWageTypeId
      amount
      currency
    }
  }
`;
