import { Avatar, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import './td-full-name.scss';
import { stringOrBlank } from 'src/util/StringUtil';
import EmployeeHasDraftPill from 'src/components/Common/EmployeeHasDraftPill/EmployeeHasDraftPill';

interface TdFullnameProps {
  firstName: string;
  lastName: string;
  alert?: boolean;
  hasDraft: boolean;
}

const TdFullname = ({ firstName, lastName, alert, hasDraft }: TdFullnameProps) => {
  return (
    <Td className="td-full-name">
      <Avatar className="td-full-name__avatar" alert={alert} alertBackgroundColor="#ffffff" />
      {`${stringOrBlank(firstName)} ${stringOrBlank(lastName)}`}
      <EmployeeHasDraftPill hasDraft={hasDraft} />
    </Td>
  );
};

export default TdFullname;
