import { gql } from '@apollo/client';

export const GET_EMPLOYEE_INSURANCE = gql`
  query GetEmployeeInsurance($employeeInsuranceId: UUID!) {
    employeeInsurance(employeeInsuranceId: $employeeInsuranceId) {
      id
      employeeId
      insurance {
        id
        name
        insuranceNumber
        agreedProduct {
          id
          name
        }
        agreementNumber
        benefitType
        insuranceStatus {
          status
          statusCategory
          latestStatusChange
        }
        startDate
        exitDate
        replacedByInsuranceId
        replacesInsuranceId
      }
    }
  }
`;
