/**
 * Parse a user input text string representing an amount to computer format
 *
 * Swedish user format: 45 120,12
 * Computer format: 45120.12
 *
 * @param inString User Amount format
 */
export const parseSweAmountText = (inString: string): string => {
  inString = inString.replace(' kr', '');
  const parsed = inString.trim().replaceAll(new RegExp(`[^0-9.,]`, 'g'), '').replace(',', '.');
  return parsed.endsWith('.') ? parsed.slice(0, -1) : parsed;
};

export const parseSweAmountTextWithNegativeAmounts = (inString: string): string => {
  inString = inString.replace(' kr', '');
  const parsed = inString.trim().replaceAll(new RegExp(`[^-0-9.,]`, 'g'), '').replace(',', '.');
  return parsed.endsWith('.') ? parsed.slice(0, -1) : parsed;
};

/**
 * Format an amount from computer format to a Swedish amount string representation
 *
 * Computer format: 45120.12
 * Swedish user format: 45 120,12
 *
 * @param inputString Computer Amount to format
 */
export const formatSweAmountText = (
  inputString: string | number,
  includeSuffix?: boolean,
  excludeDecimal?: boolean,
): string => {
  if (inputString === undefined || inputString === null) {
    return '';
  }

  const getFormattedAmountRoundedUpWithoutDecimal = (amountInput: string | number): string => {
    const amountString = amountInput?.toString();
    const parsedAmount = parseFloat(amountString.replace(',', '.'));
    const roundedAmount: number = Math.round(parsedAmount);
    return roundedAmount?.toString();
  };

  const getFormattedAmountRoundedUpWithTwoDecimals = (amountInput: string | number): string => {
    const amountString = amountInput?.toString();
    const parsedAmount = parseFloat(amountString.replace(',', '.'));
    const roundedAmount: number = Math.round(parsedAmount * 100) / 100;
    return roundedAmount?.toString();
  };

  const rawString = typeof inputString == 'number' ? inputString.toString() : inputString;

  const sweCurrencySuffix = ' kr';

  if (rawString && rawString.length > 0) {
    const formattedAmountNoDecimal = new Intl.NumberFormat('sv-SE').format(
      +getFormattedAmountRoundedUpWithoutDecimal(rawString),
    );
    const formattedAmountWithDecimal = new Intl.NumberFormat('sv-SE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(+getFormattedAmountRoundedUpWithTwoDecimals(rawString));

    const formatted = excludeDecimal ? formattedAmountNoDecimal : formattedAmountWithDecimal;

    return includeSuffix ? formatted + sweCurrencySuffix : formatted;
  }

  return rawString;
};

/**
 * Format an amount when typing in a Swedish amount string representation,
 * allowing negative amounts and incomplete state, like ending with a comma
 *
 * Raw input: 45120,1
 * Swedish input format: 45 120,1
 *
 * @param inputString Raw string to format
 * @param allowNegative Allow negative amounts
 */
export const formatSweAmountInput = (inputString: string, allowNegative = false): string => {
  if (!inputString) {
    return '';
  }

  let formattedInputString = inputString;

  const isNegativeAmount = formattedInputString.startsWith('-');

  if (!allowNegative && isNegativeAmount) {
    formattedInputString = formattedInputString.substring(1);
  }

  formattedInputString = formattedInputString.replace(/[^0-9,]/g, '');

  const endsWithComma = formattedInputString.endsWith(',');

  const parts = formattedInputString.split(',');

  let integerPart = parts[0] || '';
  let decimalPart = parts[1] || '';

  integerPart = integerPart.replace(/^0+(?!$)/, '');

  integerPart = integerPart ? new Intl.NumberFormat('sv-SE').format(parseInt(integerPart, 10)) : '0';

  if (endsWithComma && decimalPart === '') {
    decimalPart = ',';
  } else if (decimalPart !== '') {
    decimalPart = ',' + decimalPart;
  }

  return (allowNegative && isNegativeAmount ? '-' : '') + integerPart + decimalPart;
};

export const formatSweAmountTextWithSuffixWithTwoDecimals = (inputString: string | number): string => {
  return formatSweAmountText(inputString, true, false);
};

export const formatSweAmountTextAlwaysWithTwoDecimals = (inputString: string | number): string => {
  return formatSweAmountText(inputString, false, false);
};

export const formatSweAmountTextWithSuffixNoDecimal = (inputString: string | number): string => {
  return formatSweAmountText(inputString, true, true);
};

/**
 * Validate that raw string confirms to computer amount format
 *
 * Computer format: 123456789.1234
 *
 * @param rawString Computer Amount to validate
 */
export const validateAmountWithDec = (rawString: string): boolean => {
  return rawString && new RegExp('^([0-9]{1,9}((\\.)[0-9]{0,2})?)$').test(rawString);
};
