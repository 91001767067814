import { UPDATE_INVOICE_HEADER_TYPE_CODE } from 'src/graphql/schema/mutation/invoice/UpdateInvoiceHeaderTypeCode';
import { GET_INVOICE } from 'src/graphql/schema/query/invoice/GetInvoice';
import { GET_PAYMENT_TRANSACTIONS } from 'src/graphql/schema/query/invoice/GetPaymentTransactions';
import { UpdateInvoiceHeaderTypeCodeInput } from 'src/types/invoice/UpdateInvoiceHeaderTypeCodeInput';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateInvoiceHeaderTypeCode = () => {
  const apolloMutation = useApolloMutation();

  const updateInvoiceHeaderTypeCode = (request: UpdateInvoiceHeaderTypeCodeInput, employerId: string) =>
    apolloMutation(UPDATE_INVOICE_HEADER_TYPE_CODE, employerId, { request }, [GET_INVOICE, GET_PAYMENT_TRANSACTIONS]);

  return updateInvoiceHeaderTypeCode;
};
