import React from 'react';
import { IconPill } from 'fgirot-k2-ui-components';
import QueryWrapper from '../../Common/QueryWrapper';
import './reporting-employment-details.scss';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import { GET_POLICY_EMPLOYMENT_GROUP } from 'src/graphql/schema/query/policy/GetPolicyEmploymentGroup';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';

interface ReportingEmploymentDetailsProps {
  employmentNumber: string;
  policyId?: string;
  employmentGroupId?: string;
  employerId: string;
}

function ReportingEmploymentDetails({
  employmentNumber,
  employerId,
  policyId,
  employmentGroupId,
}: ReportingEmploymentDetailsProps) {
  const employerLegalName = useEmployerLegalName(employerId);
  const hasBeenAssignedEmploymentGroup = policyId && employmentGroupId;

  return (
    <div className="reporting-employment-details__pills">
      <div className="reporting-employment-details__employment-details">
        <IconPill icon="Hash" label={employmentNumber} data-testid="reporting-employment-details__employment-number" />
        {hasBeenAssignedEmploymentGroup && (
          <QueryWrapper
            query={GET_POLICY_EMPLOYMENT_GROUP}
            options={{
              variables: {
                policyId,
                employmentGroupId,
              },
            }}
          >
            {(data: PolicyEmploymentGroup) => (
              <IconPill
                data-testid="reporting-employment-details__employment-group"
                icon="Users"
                label={data?.employmentGroupName}
              />
            )}
          </QueryWrapper>
        )}
        <IconPill icon="Home" label={employerLegalName} data-testid="reporting-employment-details__employer" />
      </div>
    </div>
  );
}

export default ReportingEmploymentDetails;
