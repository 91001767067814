import { Modal, Card, Icon, Typography, CardAction, Button } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ExitReportModalProps {
  open: boolean;
  onStay: () => void;
  onExit: () => void;
}

const ExitReportModal = ({ open, onStay, onExit }: ExitReportModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal centered overlayBackground className="report__modal" open={open} onClose={onStay}>
      <Card icon={<Icon type="Alert" className="report__modal__icon" />} title={t('report:exit-modal.title')}>
        <Typography>{t('report:exit-modal.text')}</Typography>
        <CardAction>
          <Button type="link" label={t('common:stay')} onClick={onStay} />
          <Button label={t('common:yes-cancel')} onClick={onExit} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default ExitReportModal;
