import { Button, Card, CardAction, Icon, Modal } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { Checkpoint, CheckpointStatus } from 'src/types/Checkpoint';
import { Employee } from 'src/types/Employee';
import EmployeeListItem from './EmployeeListItem';
import { useTranslation } from 'react-i18next';
import { updateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import { finalizeCheckpointsMutation } from 'src/service/checkpoint/FinalizeCheckpointsMutation';
import { unique } from 'src/util/ArrayUtil';
import { ACCOUNTING_UNITS } from 'src/graphql/schema/query/accountingunit/AccountingUnits';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import './employee-missing-modal.scss';

interface EmployeeMissingModalProps {
  open: boolean;
  onClose: () => void;
  employerId: string;
  checkpoints: Checkpoint[];
}

const EmployeeMissingModal: FC<EmployeeMissingModalProps> = ({ open, onClose, employerId, checkpoints }) => {
  const { t } = useTranslation();

  const updateStatusAndFinalize = (status: CheckpointStatus) =>
    updateCheckpointStatusMutation(
      checkpoints.map((checkpoint) => checkpoint.id),
      status,
      employerId,
    ).then(() =>
      finalizeCheckpointsMutation(
        checkpoints.map((checkpoint) => checkpoint.employeeId),
        null,
        'INVOICE_EMPLOYEE_MISSING',
        employerId,
      ),
    );

  const handleAcceptClick = () => updateStatusAndFinalize('ACCEPTED').then(() => onClose());
  const handleDeclineClick = () => updateStatusAndFinalize('DECLINED').then(() => onClose());

  return (
    <Modal className="employee-missing-modal" centered overlayBackground open={open} onClose={onClose}>
      <Card
        icon={<Icon type={'Alert'} />}
        title={t('invoice:employee-missing-modal.title')}
        subtitle={t('invoice:employee-missing-modal.subtitle')}
      >
        <QueryWrapper query={ACCOUNTING_UNITS} options={{ variables: { employerId }, skip: !employerId }}>
          {(accountingUnits: AccountingUnit[]) => (
            <div className="employee-missing-modal__container">
              {unique(checkpoints, 'employeeId').map((checkpoint) => {
                return (
                  <QueryWrapper
                    key={`EmployeeListItem-${checkpoint.employeeId}`}
                    query={GET_EMPLOYEE}
                    options={{ variables: { employeeId: checkpoint.employeeId }, skip: !checkpoint.employeeId }}
                  >
                    {(employee: Employee) => {
                      return <EmployeeListItem employee={employee} accountingUnits={accountingUnits} />;
                    }}
                  </QueryWrapper>
                );
              })}
            </div>
          )}
        </QueryWrapper>
        <CardAction>
          <Button label={t('invoice:employee-missing-modal.button-deny')} onClick={handleDeclineClick} />
          <Button
            label={t('invoice:employee-missing-modal.button-accept')}
            type="primary"
            onClick={handleAcceptClick}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default EmployeeMissingModal;
