import { gql } from '@apollo/client';

export const UPDATE_POLICY_BENEFIT_CALCULATION_RULE = gql`
  mutation UpdatePolicyBenefitCalculationRule($request: UpdatePolicyBenefitCalculationRuleRequest!) {
    updatePolicyBenefitCalculationRule(request: $request) {
      id
      benefitPackageId
      name
      benefitType
      agreedProducts {
        id
        agreementNumber
        name
        insuranceProviderId
        insuranceProduct {
          id
          name
        }
      }
      policyEmploymentGroups {
        id
        employmentGroupId
        employmentGroupName
      }
      connectionRule {
        optional
      }
      affiliationRuleBlocks {
        id
        groupedAffiliationRules {
          affiliationRuleGroupType
          affiliationRules {
            id
            policyRuleType
            ruleValueUnit
            sourceType
            sourceReference
            ruleValues {
              id
              value
              valueType
              condition
            }
          }
        }
      }
    }
  }
`;
