import { Button, Card } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { BalancingOfBooksSettings } from 'src/types/accountingunit/BalancingOfBooksSettings';
import AddBalancingOfBooksSettingsModal from './AddBalancingOfBooksSettingsModal';
import MonthlyAdjustmentsCard from './MonthlyAdjustmentsCard';
import { useTranslation } from 'react-i18next';
import BalancingOfBooksSettingsCard from './BalancingOfBooksSettingsCard';
interface BalancingOfBooksSettingsCardsProps {
  balancingOfBooksSettings: BalancingOfBooksSettings;
}
const BalancingOfBooksSettingsCards: FC<BalancingOfBooksSettingsCardsProps> = ({ balancingOfBooksSettings }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const toggleBalancingOfBooksSettingsModal = () => setModalOpen((prev) => !prev);

  return (
    <div className="accounting-settings-tab__cards">
      {balancingOfBooksSettings ? (
        <BalancingOfBooksSettingsCard balancingOfBooksSettings={balancingOfBooksSettings} />
      ) : (
        <Card
          title={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.settings-title-inactive')}
          className="accounting-settings-tab__card-content"
        >
          <div className="accounting-settings-tab__card-content-row">
            <Button
              label={t(
                'economySettings:accounting-settings-tab.balancing-of-books-settings-cards.add-balancing-of-books-settings',
              )}
              onClick={toggleBalancingOfBooksSettingsModal}
            />
            <AddBalancingOfBooksSettingsModal open={modalOpen} onClose={toggleBalancingOfBooksSettingsModal} />
          </div>
        </Card>
      )}

      {balancingOfBooksSettings?.useMonthlyAdjustments && (
        <MonthlyAdjustmentsCard
          balancingOfBooksSettingsId={balancingOfBooksSettings.id}
          balancingOfBooksAdjustments={balancingOfBooksSettings.balancingOfBooksAdjustments}
        />
      )}
    </div>
  );
};

export default BalancingOfBooksSettingsCards;
