import { Button, Card, CardAction, ListItem, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { ACCOUNTING_ROWS } from 'src/graphql/schema/query/accountingunit/AccountingRows';
import { useCreatePostingRule } from 'src/service/accountingunit/PostingRule';
import { AccountingRow } from 'src/types/accountingunit/AccountingRow';
import { CreatePostingRuleInput } from 'src/types/accountingunit/PostingRule';
import { InsuranceTypeMapping } from 'src/types/insurancetypemapping/InsuranceTypeMapping';
import './add-posting-rule-modal.scss';

interface AddPostingRuleModalProps {
  open: boolean;
  onClose: () => void;
  chartOfAccountsId: string;
  insuranceTypeMapping: InsuranceTypeMapping;
}
const AddPostingRuleModal: FC<AddPostingRuleModalProps> = ({
  open,
  onClose,
  chartOfAccountsId,
  insuranceTypeMapping,
}) => {
  const { employerId, accountingUnitId } = useParams();
  const [createPostingRuleInput, setCreatePostingRuleInput] = useState<CreatePostingRuleInput>({
    name: '',
    chartOfAccountsId,
    accountingRowId: null,
    insuranceTypeMappingId: insuranceTypeMapping.id,
  });

  const createPostingRule = useCreatePostingRule();

  const handleAccountChange = (option: Option<AccountingRow>) =>
    setCreatePostingRuleInput((prev) => ({ ...prev, accountingRowId: option.value.id }));

  const handleClose = () => {
    setCreatePostingRuleInput({
      name: '',
      chartOfAccountsId,
      accountingRowId: null,
      insuranceTypeMappingId: insuranceTypeMapping.id,
    });
    onClose();
  };

  const handleCreatePostingRule = () => {
    const input: CreatePostingRuleInput = {
      name: '',
      chartOfAccountsId: createPostingRuleInput.chartOfAccountsId,
      accountingRowId: createPostingRuleInput.accountingRowId,
      insuranceTypeMappingId: createPostingRuleInput.insuranceTypeMappingId,
    };
    return createPostingRule(input, employerId).then(handleClose);
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleClose}>
      <Card
        className="add-posting-rule-modal"
        title={t('economySettings:insurance-type-mappings-tab.add-posting-rule-modal.title')}
        defaultClose
        onClose={handleClose}
      >
        <ListItem border header={insuranceTypeMapping.name} />

        <QueryWrapper query={ACCOUNTING_ROWS} options={{ variables: { accountingUnitId, employerId } }}>
          {(accountingRows: AccountingRow[]) => {
            const filteredAccountingRows = accountingRows.filter(
              (row) => row.accountingType.accountingTypeValue.name === 'RESULT_ACCOUNT',
            );
            const options = filteredAccountingRows.map((row) => ({
              value: row,
              label: `${row.accountNumber} - ${row.accountingText || ''}`,
            }));

            return (
              <Select
                label={t('economySettings:insurance-type-mappings-tab.add-posting-rule-modal.account')}
                placeholder={t(
                  'economySettings:insurance-type-mappings-tab.add-posting-rule-modal.account-placeholder',
                )}
                selected={options.find((option) => option.value.id === createPostingRuleInput.accountingRowId)}
                options={options}
                onChange={handleAccountChange}
                className="add-posting-rule-modal__dropdown"
                small
                outlined
              />
            );
          }}
        </QueryWrapper>
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleClose}
            data-cy="add-posting-rule-modal__cancel-button"
          />
          <Button
            data-cy="add-posting-rule-modal__button"
            disabled={!createPostingRuleInput.accountingRowId}
            label={t('economySettings:insurance-type-mappings-tab.add-posting-rule-modal.add')}
            onClick={handleCreatePostingRule}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddPostingRuleModal;
