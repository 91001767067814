import { gql, useQuery } from '@apollo/client';

export const useDownloadDocument = (documentReferenceId: string) => {
  const { data, loading, error } = useQuery(GET_DOWNLOAD_DOCUMENT, {
    variables: { documentReferenceId },
  });
  return { data, loading, error };
};

export const GET_DOWNLOAD_DOCUMENT = gql`
  query ($documentReferenceId: UUID!) {
    downloadDocument(documentReferenceId: $documentReferenceId) {
      documentId
      name
      base64EncodedDocument
    }
  }
`;
