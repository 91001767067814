import { client } from 'src/graphql';
import { GET_PGL_RULES } from 'src/graphql/schema/query/policy/GetPglRules';
import { PglRule } from 'src/types/policy/PglRule';
import { useApolloQueryList } from '../graphql/Query';

export const usePglRules = (policyId: string): PglRule[] =>
  useApolloQueryList(GET_PGL_RULES, { variables: { policyId } });

export const getPglRules = async (policyId: string): Promise<PglRule[]> => {
  const {
    data: { pglRules },
  } = await client.query({
    query: GET_PGL_RULES,
    variables: {
      policyId,
    },
  });
  return pglRules;
};
