import { CREATE_POLICY_COMMENT } from 'src/graphql/schema/mutation/policy/CreatePolicyComment';
import { GET_POLICY_ENTITIES } from 'src/graphql/schema/query/policy/GetPolicyEntities';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreatePolicyComment = () => {
  const apolloMutation = useApolloMutation();

  const createPolicyComment = (policyId: string, comment: string, employerId: string) =>
    apolloMutation(
      CREATE_POLICY_COMMENT,
      employerId,
      {
        policyId,
        comment,
      },
      [GET_POLICY_ENTITIES],
    );

  return createPolicyComment;
};
