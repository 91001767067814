import { Accordion, ListItem, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { Invoice } from 'src/types/invoice/Invoice';
import { stringOrBlank } from 'src/util/StringUtil';
import './invoice-header.scss';
import { useTranslation } from 'react-i18next';
import InvoiceDrawer from './InvoiceDrawer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_PAYMENTS_BY_INVOICE_ID } from 'src/graphql/schema/query/payment/GetPaymentsByInvoiceId';
import { Payment } from 'src/types/payment/Payment';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
interface InvoiceHeaderProps {
  invoice: Invoice;
}
const InvoiceHeader: FC<InvoiceHeaderProps> = ({ invoice }) => {
  const { t } = useTranslation();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>();

  const handleOpenInvoiceDrawer = (id: string) => {
    setSelectedInvoiceId(id);
  };

  const handleCloseInvoiceDrawer = () => {
    setSelectedInvoiceId(undefined);
  };

  return (
    <QueryWrapper
      type="list"
      query={GET_PAYMENTS_BY_INVOICE_ID}
      options={{ variables: { invoiceHeaderId: invoice.id } }}
    >
      {(payments: Payment[]) => {
        return (
          <>
            <ListItem onClick={() => handleOpenInvoiceDrawer(invoice.id)}>
              <Accordion preopened header={t('invoice:invoice-header.invoice-header')} className="invoice-header">
                <div className="invoice-header__container">
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.company',
                  )}: ${stringOrBlank(invoice.employer.legalName)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.insurance-provider',
                  )}: ${stringOrBlank(invoice.insuranceProvider?.legalName)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.insurance-provider-account',
                  )}: ${stringOrBlank(invoice.accountNumberTo)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.invoice-number',
                  )}: ${stringOrBlank(invoice.invoiceNumber)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.agreement-number',
                  )}: ${stringOrBlank(invoice.agreementNumber)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.invoice-date',
                  )}: ${stringOrBlank(invoice.invoiceIssuedCreationDate)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.arrival-date',
                  )}: ${stringOrBlank(invoice.arrivalDate)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.due-date',
                  )}: ${stringOrBlank(invoice.dueDate)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.payment-due-date',
                  )}: ${stringOrBlank(invoice.paymentDueDate)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.total-amount',
                  )}: ${formatSweAmountText(String(invoice.totalAmount))}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.remaining-amount',
                  )}: ${formatSweAmountText(String(invoice.remainingAmount))}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.rounding-amount',
                  )}: ${stringOrBlank(invoice.roundingAmount)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.vat-amount',
                  )}: ${stringOrBlank(invoice.vatAmount)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.ocr-number',
                  )}: ${stringOrBlank(invoice.ocrNumber)}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.period',
                  )}: ${stringOrBlank(invoice.invoicePeriod.periodStart)} - ${stringOrBlank(
                    invoice.invoicePeriod.periodEnd,
                  )}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.status',
                  )}: ${stringOrBlank(t(`invoice:status.${invoice.invoiceStatus}`))}`}</Typography>
                  <Typography className="invoice-header__property">{`${t(
                    'invoice:invoice-header.invoice-type-code',
                  )}: ${stringOrBlank(t(`invoice:invoice-type-code.${invoice.invoiceTypeCode}`))}`}</Typography>
                </div>
              </Accordion>
            </ListItem>
            {selectedInvoiceId !== undefined && (
              <InvoiceDrawer
                open={selectedInvoiceId !== undefined}
                onClose={handleCloseInvoiceDrawer}
                invoice={invoice}
                payments={payments}
              />
            )}
          </>
        );
      }}
    </QueryWrapper>
  );
};

export default InvoiceHeader;
