import { CreateOrUpdateEmployerPayType } from 'src/types/CreateOrUpdateEmployerPayType';
import { Wage } from 'src/types/EmployeeCard';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import { EmployerWageType, WageType } from 'src/types/employer/EmployerWageType';

export const payTypeIsCorrectlyMapped = (payType: EmployerPayType) => {
  if (!payType) {
    return false;
  }
  return (
    (payType.inUse === false &&
      payType?.employerWageType.wageType === 'NOT_MAPPED' &&
      payType?.employerKubs.length === 0) ||
    (payType.inUse === true && payType.employerWageType.wageType !== 'NOT_MAPPED') ||
    (payType.inUse === true && payType.employerKubs.length > 0)
  );
};

export const payTypeRequestIsCorrectlyMapped = (
  payTypeRequest: CreateOrUpdateEmployerPayType,
  notMappedWageType: EmployerWageType,
) => {
  if (!payTypeRequest) {
    return false;
  }

  return (
    (payTypeRequest.inUse === false &&
      payTypeRequest?.employerWageTypeId === notMappedWageType.id &&
      payTypeRequest?.kubTypeMappings.length === 0) ||
    (payTypeRequest.inUse === true && payTypeRequest?.employerWageTypeId !== notMappedWageType.id) ||
    (payTypeRequest.inUse === true && payTypeRequest?.kubTypeMappings.length > 0)
  );
};

export const payTypeRequestHasChanged = (payType: EmployerPayType, payTypeRequest: CreateOrUpdateEmployerPayType) => {
  if (!payType && payTypeRequest) {
    return true;
  }

  const kubsHaveChanged =
    payType.employerKubs.length !== payTypeRequest.kubTypeMappings.length ||
    payType.employerKubs.some((kub) => !payTypeRequest.kubTypeMappings.includes(kub.kubType));

  return (
    payType.inUse !== payTypeRequest.inUse ||
    payType.useQuantity !== payTypeRequest.useQuantity ||
    payType.employerWageType.id !== payTypeRequest.employerWageTypeId ||
    kubsHaveChanged
  );
};

export const wageIsOnlyMappedToKUB = (wage: Wage) => {
  if (!wage) {
    return false;
  }
  return wage.inUse && wage.kubTypes.length > 0 && wage.wageType === 'NOT_MAPPED';
};

export const payTypeIsOnlyMappedToKUB = (payType: EmployerPayType) => {
  if (!payType) {
    return false;
  }
  return payType.inUse && payType.employerKubs.length > 0 && payType.employerWageType.wageType === 'NOT_MAPPED';
};

export const isHourlySalary = (wageType: string) => {
  return wageType === 'HOURLY_SALARY';
};

export const isAbsenceWageType = (wageType: string) => {
  return (
    wageType === 'LEAVE_OF_ABSENCE_SICK' ||
    wageType === 'LEAVE_OF_ABSENCE_GENERAL' ||
    wageType === 'LEAVE_OF_ABSENCE_MILITARY' ||
    wageType === 'LEAVE_OF_ABSENCE_STUDIES' ||
    wageType === 'LEAVE_OF_ABSENCE_PARENTAL' ||
    wageType === 'LEAVE_OF_ABSENCE_CARE_OF_CHILD' ||
    wageType === 'QUALIFYING_DEDUCTION'
  );
};

export const globalAbsenceWageTypes = [
  'LEAVE_OF_ABSENCE_SICK',
  'LEAVE_OF_ABSENCE_GENERAL',
  'LEAVE_OF_ABSENCE_MILITARY',
  'LEAVE_OF_ABSENCE_STUDIES',
  'LEAVE_OF_ABSENCE_PARENTAL',
  'LEAVE_OF_ABSENCE_CARE_OF_CHILD',
  'QUALIFYING_DEDUCTION',
];

export const isSickLeaveWageType = (wageType: string) => {
  return wageType === 'LEAVE_OF_ABSENCE_SICK' || wageType === 'QUALIFYING_DEDUCTION';
};

export const canChangeNegateIncomingAmount = (wageType: WageType) => {
  const validWageTypes: WageType[] = ['GROSS_DEDUCTION', 'SALARY_EXCHANGE'];

  return validWageTypes.includes(wageType);
};

export const findAggregatedWageByWageTypeId = (aggregatedEmployeeCard: AggregatedEmployeeCard, wageTypeId: string) => {
  if (!aggregatedEmployeeCard || !wageTypeId) {
    return null;
  }
  return aggregatedEmployeeCard?.aggregatedWageTypeWages.find((wageType) => wageType.employerWageTypeId === wageTypeId);
};

export const findAggregatedWageByPayTypeNumber = (
  aggregatedEmployeeCard: AggregatedEmployeeCard,
  payTypeNumber: string,
) => {
  if (!aggregatedEmployeeCard || !payTypeNumber) {
    return null;
  }
  return aggregatedEmployeeCard?.aggregatedPayTypeWages.find((payType) => payType.payTypeNumber === payTypeNumber);
};

export const findWageByIdInAggregatedEmployeeCard = (
  aggregatedEmployeeCard: AggregatedEmployeeCard,
  wageId: string,
) => {
  if (!aggregatedEmployeeCard || !wageId) {
    return null;
  }

  const allWages = aggregatedEmployeeCard?.aggregatedWageTypeWages
    .flatMap((wageType) => wageType.wages)
    .concat(aggregatedEmployeeCard?.aggregatedPayTypeWages.flatMap((payType) => payType.wages));

  return allWages.find((wage) => wage.id === wageId);
};

/**
 * Returns a formatted string describing the pay type
 *
 * If the payTypeNumber is not present, the payTypeName will be returned
 * If the payTypeName is not present, the payTypeNumber will be returned
 * If both are present, the payTypeNumber and payTypeName will be returned in a formatted string
 * If neither are present, a default translation string will be returned
 *
 * @param payTypeNumber The pay type number string
 * @param payTypeName The pay type name string
 * @param t The translation function used to return a fallback string
 */
export const formatPayType = (payTypeNumber: string, payTypeName: string, t: (key: string) => string) => {
  if (payTypeNumber && !payTypeName) {
    return payTypeNumber;
  }

  if (!payTypeNumber && payTypeName) {
    return payTypeName;
  }

  return payTypeName && payTypeNumber ? `${payTypeNumber} - ${payTypeName}` : t('wages:no-pay-type');
};
