import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import PageBase from 'src/components/PageBase/PageBase';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { Employee } from 'src/types/Employee';
import EmployeeOverview from 'src/components/Employees/EmployeeOverview';
import { Tab, Tabs } from 'fgirot-k2-ui-components';
import PremiumInvoiceRowsOverview from 'src/components/Employees/PremiumInvoiceRowsOverview';
import { EmployeeOverviewTab } from 'src/types/tabs/EmployeeOverviewTab';
import useTabParams from 'src/components/hooks/useTabParams';
import InsurancesOverview from 'src/components/Employees/InsurancesOverview';
import EmployeeInsuranceEventsOverview from 'src/components/Employees/EmployeeInsuranceEventsOverview';
import EmployeeAbsenceOverview from 'src/components/Employees/EmployeeAbsenceOverview';
import { Employer } from 'src/types/employer/Employer';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import EmploymentHistoryOverview from 'src/components/Employees/EmploymentHistoryOverview';
import './employee-page.scss';

const EmployeePage = () => {
  const { t } = useTranslation();
  const customer = useCustomer();
  const { employeeId } = useParams();
  const [currentTab, setCurrentTab] = useTabParams('tab', EmployeeOverviewTab.OVERVIEW);

  return (
    <QueryWrapper query={GET_EMPLOYEE} options={{ variables: { employeeId, allowDraft: true } }}>
      {(employee: Employee) => (
        <QueryWrapper query={GET_EMPLOYER} options={{ variables: { employerId: employee.employerId } }}>
          {(employer: Employer) => (
            <PageBase
              breadCrumbs={[
                { name: customer?.name, steps: 1 },
                { name: employer.legalName, steps: 1 },
                { name: t('employees:employees'), steps: 1 },
              ]}
              title={`${employee.firstName} ${employee.lastName}`}
              subtitle={employee.personNumber}
            >
              <div className="employee-page">
                <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
                  <Tab title={t('employees:overview')}>
                    <EmployeeOverview employee={employee} />
                  </Tab>
                  <Tab title={t('premiumInvoiceRows:premium-invoice-rows')}>
                    <PremiumInvoiceRowsOverview />
                  </Tab>
                  <Tab title={t('insurances:insurances')}>
                    <InsurancesOverview employee={employee} />
                  </Tab>
                  <Tab title={t('reporting:insurance-events.insurance-events')}>
                    <EmployeeInsuranceEventsOverview employee={employee} />
                  </Tab>
                  <Tab title={t('employees:absence')}>
                    <EmployeeAbsenceOverview employerId={employee.employerId} />
                  </Tab>
                  <Tab title={t('employees:employee-history-tab.tab-title')}>
                    <EmploymentHistoryOverview employee={employee} />
                  </Tab>
                </Tabs>
              </div>
            </PageBase>
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  );
};

export default EmployeePage;
