import React from 'react';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { AffiliationRule, PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { getDefaultEmploymentRuleValues } from './EmploymentTypeRuleConfig';
import EmploymentTypeRuleValue from './EmploymentTypeRuleValue';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';

interface EmploymentTypeRuleProps {
  policyState: PolicyState;
  affiliationRule: AffiliationRule;
  onUpdateOrDeleteAffiliationRule: (request: UpdateOrDeleteAffiliationRuleRequest) => void;
}

const EmploymentTypeRule: React.FunctionComponent<EmploymentTypeRuleProps> = ({
  policyState,
  affiliationRule,
  onUpdateOrDeleteAffiliationRule,
}) => {
  const defaultRuleValues = getDefaultEmploymentRuleValues(PolicyRuleType.EMPLOYMENT_TYPE);

  return (
    <>
      {defaultRuleValues.map((defaultRuleValue, index) => (
        <EmploymentTypeRuleValue
          key={`employmentTypeRuleValue-${defaultRuleValue.id}-${index}`}
          policyState={policyState}
          affiliationRule={affiliationRule}
          defaultRuleValue={defaultRuleValue}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      ))}
    </>
  );
};

export default EmploymentTypeRule;
