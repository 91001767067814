import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { PostingRulesRequest } from 'src/types/accountingunit/PostingRulesRequest';
import { Icon, Input } from 'fgirot-k2-ui-components';

interface PostingRulesSearchInputProps {
  searchRequest: PostingRulesRequest;
  onSearch: (searchInput: string) => void;
  disabled?: boolean;
}

const PostingRulesSearchInput = ({ onSearch, disabled, searchRequest }: PostingRulesSearchInputProps) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(searchRequest.searchInput);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (searchRequest.searchInput !== event.target.value) {
      onSearch(searchInput);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchInput);
    } else if (e.key === 'Escape') {
      setSearchInput('');
    }
  };

  return (
      <Input.Text
        disabled={disabled}
        placeholder={t('economySettings:posting-rules-tab.search-placeholder')}
        type="search"
        icon={<Icon type="Search" />}
        value={searchInput}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyPress}
      />
  );
};

export default PostingRulesSearchInput;
