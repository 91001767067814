import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { PUBLISH_POLICY_DRAFT } from 'src/graphql/schema/mutation/policy/PublishPolicyDraft';
import { PublishPolicyDraftRequest } from 'src/types/policy/PublishPolicyDraftRequest';
import { GET_POLICY_ENTITIES } from 'src/graphql/schema/query/policy/GetPolicyEntities';
import { useApolloMutation } from '../graphql/Mutation';

export const usePublishPolicyDraft = () => {
  const apolloMutation = useApolloMutation();

  const publishPolicyDraft = (request: PublishPolicyDraftRequest, employerId: string) =>
    apolloMutation(PUBLISH_POLICY_DRAFT, employerId, { request }, [GET_POLICY, GET_POLICY_ENTITIES]);

  return publishPolicyDraft;
};
