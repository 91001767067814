import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { writeToCache } from 'src/graphql/cache/ApolloCache';
import { CERTIFY_CONDITION_METADATA } from 'src/graphql/cache/CacheKeys';
import { GET_INSURANCE_EVENT_CHECKPOINTS } from 'src/graphql/schema/query/checkpoint/GetInsuranceEventCheckpoints';
import { INSURANCE_EVENT_CHECKPOINT } from 'src/graphql/schema/subscription/InsuranceEventCheckpoints';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { Service } from 'src/types/Service';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { extractUniqueConditions } from 'src/util/ConditionUtil';
import { useEmployeeSubscription } from '../employee/EmployeeSubscription';
import { useEmployeeCardSubscription } from '../employeecard/EmployeeCardSubscription';
import { useCheckpointSubscription } from './CheckpointSubscription';

export const useInsuranceEventCheckpoints = (
  employerIds: string[],
  wageFilePeriod: string,
): Service<InsuranceEventReportEmployeeResponse[]> => {
  const [result, setResult] = useState<Service<InsuranceEventReportEmployeeResponse[]>>({ status: INIT });
  const [employeeCardIds, setEmployeeCardIds] = useState<string[]>([]);

  const { loading, error, data } = useQuery(GET_INSURANCE_EVENT_CHECKPOINTS, {
    variables: {
      employerIds,
      wageFilePeriod,
    },
    skip: !employerIds || employerIds.length === 0 || !wageFilePeriod,
    fetchPolicy: 'no-cache',
  });

  useCheckpointSubscription(INSURANCE_EVENT_CHECKPOINT, employerIds, wageFilePeriod, setResult);
  useEmployeeSubscription(employerIds, wageFilePeriod, setResult);
  useEmployeeCardSubscription(employeeCardIds, setResult);

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });

    if (data && result.status !== LOADED) {
      setResult({
        status: LOADED,
        payload: data.reportEmployees,
      });
      const conditions = extractUniqueConditions(data.reportEmployees);
      writeToCache(CERTIFY_CONDITION_METADATA, conditions);
      setEmployeeCardIds([...data.reportEmployees.map((reportEmployee) => reportEmployee.employeeCard.id)]);
    }
  }, [loading, error, data]);

  return result;
};
