import { Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import { Employer } from 'src/types/employer/Employer';
import { useTranslation } from 'react-i18next';
import { useTriggerReportingQueue } from 'src/service/reporting/TriggerReportingQueue';
import { TriggerReportingQueueRequest } from 'src/types/reporting/TriggerReportingQueueRequest';
import { useContractedInsuranceProviderOptions } from './useContractedInsuranceProviderOptions';

interface TriggerReportingQueueButtonProps {
  onSuccess: () => void;
  employers: Employer[];
  wageFilePeriod: string;
}

const TriggerReportingQueueButton = ({ employers, onSuccess, wageFilePeriod }: TriggerReportingQueueButtonProps) => {
  const triggerReportingQueue = useTriggerReportingQueue();
  const { t } = useTranslation();

  const [request, setRequest] = useState<TriggerReportingQueueRequest>({
    employerId: '',
    insuranceProviderId: '',
    wageFilePeriod: wageFilePeriod,
  });

  const { employerOptions, insuranceProviderOptions } = useContractedInsuranceProviderOptions(
    request.employerId,
    employers,
  );

  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const handleEmployerChange = (option: Option<string>) => {
    setRequest((prev) => {
      return { ...prev, employerId: option.value };
    });
  };

  const handleInsuranceProviderChange = (option: Option<string>) => {
    setRequest((prev) => {
      return { ...prev, insuranceProviderId: option.value };
    });
  };

  const triggerQueueCalculation = () => {
    triggerReportingQueue(request.employerId, request).then(() => {
      onSuccess();
      closeModal();
    });
  };

  return (
    <>
      <Button label={t('reporting:queue-triggering.trigger-button')} size="small" onClick={openModal} />
      <Modal open={open} centered overlayBackground onClose={closeModal}>
        <Card title={t('reporting:queue-triggering.title')}>
          <div>
            <Select
              label={t('reporting:queue-triggering.employer')}
              small
              outlined
              placeholder={t('reporting:queue-triggering.choose-employer')}
              options={employerOptions}
              selected={employerOptions?.find((option) => option.value === request.employerId)}
              onChange={handleEmployerChange}
              disabled={!employerOptions || employerOptions?.length === 0}
            />
            <Select
              label={t('reporting:queue-triggering.insurance-provider')}
              small
              outlined
              placeholder={t('reporting:queue-triggering.choose-insurance-provider')}
              options={insuranceProviderOptions}
              selected={insuranceProviderOptions?.find((option) => option.value === request.insuranceProviderId)}
              onChange={handleInsuranceProviderChange}
              disabled={!request.employerId || !insuranceProviderOptions || insuranceProviderOptions?.length === 0}
            />
          </div>
          <CardAction>
            <Button label={t('common:cancel')} type="link" onClick={closeModal} />
            <Button
              label={t('reporting:queue-triggering.ok-button')}
              onClick={triggerQueueCalculation}
              disabled={!request.employerId || !request.insuranceProviderId}
            />
          </CardAction>
        </Card>
      </Modal>
    </>
  );
};

export default TriggerReportingQueueButton;
