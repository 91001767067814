import { Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useState } from 'react';
import {
  PaymentServiceProviderType,
  BankType,
  CreateAccountInput,
  paymentServiceProviderType,
  bankType,
} from 'src/types/payment/PaymentAccount';
import TextInput from 'src/components/Common/TextInput';
import './create-account-modal.scss';
import { useCreateAccount } from 'src/service/payment/Account';
import { createOptions } from 'src/util/OptionUtil';
import { useParams } from 'react-router-dom';
import { isBlank } from 'src/util/StringUtil';

interface CreateAccountModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateAccountModal: FC<CreateAccountModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();

  const [selectedPaymentServiceProvider, setSelectedPaymentServiceProvider] =
    useState<Option<PaymentServiceProviderType>>();
  const [selectedBank, setSelectedBank] = useState<Option<BankType>>();
  const [accountNumber, setAccountNumber] = useState<string>('');

  const isCreateButtonDisabled = !selectedPaymentServiceProvider || !selectedBank || isBlank(accountNumber);

  const createAccount = useCreateAccount();

  const handlePaymentServiceProviderChange = (option: Option<PaymentServiceProviderType>) =>
    setSelectedPaymentServiceProvider(option);

  const handleBankChange = (option: Option<BankType>) => setSelectedBank(option);

  const handleAccountNumberChange = (e: ChangeEvent<HTMLInputElement>) => setAccountNumber(e.target.value);

  const handleCloseModal = () => {
    onClose();
  };

  const handleCreateAccount = () => {
    const request: CreateAccountInput = {
      accountNumber: accountNumber,
      paymentServiceProvider: selectedPaymentServiceProvider?.value,
      accountActive: true,
      bank: selectedBank?.value,
    };
    createAccount(request, employerId).then(handleCloseModal);
  };

  const paymentServiceProviderOptions: Option<string>[] = createOptions(
    paymentServiceProviderType,
    t,
    'economySettings:payment-settings-tab.payment-service-provider',
  );

  const bankOptions: Option<string>[] = createOptions(bankType, t, 'economySettings:payment-settings-tab.bank');

  return (
    <Modal data-cy="create-account-modal" centered overlayBackground open={open} onClose={handleCloseModal}>
      <Card
        className="create-account-modal"
        title={t('economySettings:payment-settings-tab.create-account-modal.title')}
        size="narrow"
      >
        <TextInput
          placeholder={t('economySettings:payment-settings-tab.create-account-modal.account-number-placeholder')}
          label={t('economySettings:payment-settings-tab.create-account-modal.account-number-label')}
          type="text"
          validationKey="text10"
          variant="default"
          value={accountNumber}
          maxLength={11}
          onChange={handleAccountNumberChange}
          mandatory
          data-cy="create-account-modal__input-account-number"
        />
        <Select
          placeholder={t(
            'economySettings:payment-settings-tab.create-account-modal.payment-service-provider-placeholder',
          )}
          label={t('economySettings:payment-settings-tab.create-account-modal.payment-service-provider-label')}
          options={paymentServiceProviderOptions}
          selected={selectedPaymentServiceProvider}
          onChange={handlePaymentServiceProviderChange}
          outlined
          small
          mandatory
          data-cy="create-account-modal__select-payment-service-provider"
        />
        <Select
          placeholder={t('economySettings:payment-settings-tab.create-account-modal.bank-placeholder')}
          label={t('economySettings:payment-settings-tab.create-account-modal.bank-label')}
          options={bankOptions}
          selected={selectedBank}
          onChange={handleBankChange}
          outlined
          small
          mandatory
          data-cy="create-account-modal__select-bank"
        />
        <CardAction>
          <CardAction>
            <Button
              label={t('common:cancel')}
              type="link"
              onClick={handleCloseModal}
              data-cy="create-account-modal__cancel-button"
            />
            <Button
              label={t('common:create')}
              disabled={isCreateButtonDisabled}
              onClick={handleCreateAccount}
              data-cy="create-account-modal__create-button"
            />
          </CardAction>
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateAccountModal;
