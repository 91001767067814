import { Button, Card, CardAction, Modal, Option, Select, Switch, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import TextInput from 'src/components/Common/TextInput/TextInput';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import { useCreateAccountingUnit } from 'src/service/accountingunit/AccountingUnit';
import { AccountingUnit, CreateAccountingUnitInput } from 'src/types/accountingunit/AccountingUnit';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { isBlank } from 'src/util/StringUtil';

interface AddAccountingUnitModalProps {
  accountingUnits: AccountingUnit[];
  open: boolean;
  onClose: () => void;
}
const AddAccountingUnitModal: FC<AddAccountingUnitModalProps> = ({ accountingUnits, open, onClose }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const createAccountingUnit = useCreateAccountingUnit();
  const [createaccontingUnitInput, setCreateAccountingUnitInput] = useState<CreateAccountingUnitInput>({
    name: '',
    description: '',
    chartOfAccountsName: '',
    chartOfAccountsDescription: '',
    createDefaultAccounts: false,
    insuranceProviderIds: null,
  });

  const handleClose = () => {
    setCreateAccountingUnitInput({
      name: '',
      description: '',
      chartOfAccountsName: '',
      chartOfAccountsDescription: '',
      createDefaultAccounts: false,
      insuranceProviderIds: null,
    });
    onClose();
  };

  const handleCreateAccountingUnit = () => createAccountingUnit(createaccontingUnitInput, employerId).then(handleClose);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCreateAccountingUnitInput((prev) => ({ ...prev, name: e.target.value }));

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCreateAccountingUnitInput((prev) => ({ ...prev, description: e.target.value }));

  const handleChartOfAccountsNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCreateAccountingUnitInput((prev) => ({ ...prev, chartOfAccountsName: e.target.value }));

  const handleChartOfAccountsDescriptionChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCreateAccountingUnitInput((prev) => ({ ...prev, chartOfAccountsDescription: e.target.value }));

  const isCreateAccountingUnitButtonDisabled =
    isBlank(createaccontingUnitInput.name) ||
    isBlank(createaccontingUnitInput.description) ||
    isBlank(createaccontingUnitInput.chartOfAccountsName) ||
    isBlank(createaccontingUnitInput.chartOfAccountsDescription) ||
    createaccontingUnitInput.name.length > 64 ||
    createaccontingUnitInput.description.length > 255 ||
    createaccontingUnitInput.chartOfAccountsName.length > 64 ||
    createaccontingUnitInput.chartOfAccountsDescription.length > 255;

  return (
    <Modal centered overlayBackground open={open} onClose={handleClose}>
      <Card title={t('economySettings:accounting-unit-tab.modal.title')} defaultClose onClose={handleClose}>
        <Typography bold variant="subtitle">
          {t('economySettings:accounting-unit-tab.modal.label-accounting-unit')}
        </Typography>
        <TextInput
          data-cy="accounting-unit-name"
          variant="default"
          label={t('economySettings:accounting-unit-tab.modal.name-label')}
          placeholder={t('economySettings:accounting-unit-tab.modal.name-placeholder')}
          maxLength={65}
          type="text"
          validationKey="text64"
          onChange={handleNameChange}
          mandatory
          value={createaccontingUnitInput.name}
        />
        <TextInput
          data-cy="accounting-unit-description"
          variant="default"
          label={t('economySettings:accounting-unit-tab.modal.description-label')}
          placeholder={t('economySettings:accounting-unit-tab.modal.description-placeholder')}
          onChange={handleDescriptionChange}
          maxLength={256}
          type="text"
          validationKey="text255"
          mandatory
          value={createaccontingUnitInput.description}
        />
        <Typography bold variant="subtitle">
          {t('economySettings:accounting-unit-tab.modal.label-chart-of-accounts')}
        </Typography>
        <TextInput
          data-cy="chart-of-accounts-name"
          variant="default"
          label={t('economySettings:accounting-unit-tab.modal.name-label')}
          placeholder={t('economySettings:accounting-unit-tab.modal.name-placeholder')}
          onChange={handleChartOfAccountsNameChange}
          maxLength={65}
          type="text"
          validationKey="text64"
          mandatory
          value={createaccontingUnitInput.chartOfAccountsName}
        />
        <TextInput
          data-cy="chart-of-accounts-description"
          variant="default"
          label={t('economySettings:accounting-unit-tab.modal.description-label')}
          placeholder={t('economySettings:accounting-unit-tab.modal.description-placeholder')}
          onChange={handleChartOfAccountsDescriptionChange}
          maxLength={256}
          type="text"
          validationKey="text255"
          mandatory
          value={createaccontingUnitInput.chartOfAccountsDescription}
        />
        {accountingUnits.length < 1 && (
          <>
            <div>
              <Typography variant="caption">
                {t('economySettings:accounting-unit-tab.modal.create-standard-accounts-label')}
              </Typography>
              <Switch
                toggled={createaccontingUnitInput.createDefaultAccounts}
                onChange={() =>
                  setCreateAccountingUnitInput((prev) => ({
                    ...prev,
                    createDefaultAccounts: !prev.createDefaultAccounts,
                  }))
                }
                data-cy="create-standard-accounts__switch"
              />
            </div>
            <QueryWrapper query={GET_INSURANCE_PROVIDERS} type="list" options={{ variables: { employerId } }}>
              {(insuranceProviders: InsuranceProvider[]) => {
                const insuranceProviderOptions = createOptionsFromList(insuranceProviders, 'id', 'legalName');
                const getSelectedInsuranceProviders = () => {
                  return insuranceProviderOptions.filter((option) =>
                    createaccontingUnitInput.insuranceProviderIds?.includes(option.value),
                  );
                };
                const handleSelectInsuranceProviders = (options: Option<string>[]) => {
                  setCreateAccountingUnitInput((prev) => {
                    return { ...prev, insuranceProviderIds: options.map((option) => option.value) };
                  });
                };
                return (
                  <Select
                    label={t('economySettings:accounting-unit-tab.modal.select-insurance-providers-label')}
                    placeholder={t('economySettings:accounting-unit-tab.modal.select-insurance-providers-placeholder')}
                    options={insuranceProviderOptions}
                    selected={getSelectedInsuranceProviders()}
                    onChange={handleSelectInsuranceProviders}
                    disabled={!createaccontingUnitInput.createDefaultAccounts}
                    outlined
                    multiple
                    selectAll
                    small
                  />
                );
              }}
            </QueryWrapper>
          </>
        )}
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleClose}
            data-cy="create-accounting-unit-modal__cancel-button"
          />
          <Button
            label={t('economySettings:accounting-unit-tab.modal.create-button')}
            onClick={handleCreateAccountingUnit}
            disabled={isCreateAccountingUnitButtonDisabled}
            data-cy="create-accounting-unit-modal__add-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};
export default AddAccountingUnitModal;
