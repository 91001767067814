import { Button, Icon, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RuleDrawerNavigation from 'src/components/Account/PolicySettings/AffiliationRuleGroupDetails/RuleDrawerNavigation';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import { AffiliationRuleBlock } from 'src/types/policy/AffiliationRuleBlock';
import { AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import { DRAFT, PolicyState } from 'src/types/policy/PolicyEntity';
import { employerHasIndividualChoice } from 'src/util/IndividualChoiceUtil';
import { getSelectedRuleGroupTypeFromList } from 'src/util/PolicyUtil';

interface RuleBlocksProps {
  employerId: string;
  affiliationRuleBlocks: AffiliationRuleBlock[];
  ruleGroupTypes: AffiliationRuleGroupType[];
  policyState: PolicyState;
  handleDeleteRuleBlock: (ruleBlockId: string) => void;
  onDisplayRuleTypeDrawer: (affiliationRuleGroupType: AffiliationRuleGroupType, ruleBlockId: string) => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}

const RuleBlocks: FC<RuleBlocksProps> = ({
  affiliationRuleBlocks,
  ruleGroupTypes,
  policyState,
  handleDeleteRuleBlock,
  onDisplayRuleTypeDrawer,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();

  const hasITPIndividualChoice = employerHasIndividualChoice('ITP1', employerIndividualChoices);

  if (!hasITPIndividualChoice) {
    ruleGroupTypes = ruleGroupTypes.filter((ruleGroupType) => ruleGroupType !== 'RULE_GROUP_INDIVIDUAL_CHOICE');
  }

  return (
    <>
      {affiliationRuleBlocks.map((ruleBlock, index) => (
        <div key={`RuleBlock-${ruleBlock.id}-${index}`}>
          {index > 0 && (
            <div className="policy-employment-group-drawer__tabs__or-container">
              <Typography className="policy-employment-group-drawer__tabs__or-container__text" variant="subtitle">
                {t('common:or')}
              </Typography>
            </div>
          )}
          <ListGroup variant={'drawer-navigation'} className="policy-employment-group-drawer__tabs__rule-list-group">
            {ruleGroupTypes
              .map((ruleGroupType) =>
                getSelectedRuleGroupTypeFromList(
                  ruleGroupType as AffiliationRuleGroupType,
                  ruleBlock.groupedAffiliationRules,
                ),
              )
              .filter((selectedRuleGroup) => policyState === DRAFT || selectedRuleGroup.affiliationRules.length > 0)
              .map((selectedRuleGroup, index) => {
                return (
                  <RuleDrawerNavigation
                    key={`policy-employment-group-rule-navigation--${selectedRuleGroup.affiliationRuleGroupType}--${index}`}
                    groupedRules={selectedRuleGroup}
                    handleDisplayRuleTypeDrawer={() =>
                      onDisplayRuleTypeDrawer(selectedRuleGroup.affiliationRuleGroupType, ruleBlock.id)
                    }
                  />
                );
              })}
          </ListGroup>
          {index > 0 && policyState === 'DRAFT' && (
            <div className="policy-employment-group-drawer__tabs__or-container">
              <Button
                icon={<Icon type="Trash" />}
                type="secondary"
                className="policy-employment-group-drawer__tabs__or-container__button"
                label={t('policyRules:ruleBlocks.delete')}
                onClick={() => handleDeleteRuleBlock(ruleBlock.id)}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default RuleBlocks;
