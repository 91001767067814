import { gql } from '@apollo/client';

export const GET_EMPLOYER_WAGE_TYPES = gql`
  query GetEmployerWageTypes($employerId: UUID!) {
    employerWageTypes(employerId: $employerId) {
      id
      name
      wageType
      extentCalculation
      negateIncomingAmount
    }
  }
`;
