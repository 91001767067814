export type RemunerationType = 'HOURLY_SALARY' | 'MONTHLY_SALARY' | 'UNKNOWN';

export const RENUMERATION_TYPE_HOURLY_SALARY = 'HOURLY_SALARY';
export const RENUMERATION_TYPE_MONTHLY_SALARY = 'MONTHLY_SALARY';
export const RENUMERATION_TYPE_UNKNOWN = 'UNKNOWN';

export const remunerationTypes: RemunerationType[] = [
  RENUMERATION_TYPE_HOURLY_SALARY,
  RENUMERATION_TYPE_MONTHLY_SALARY,
  RENUMERATION_TYPE_UNKNOWN,
];
