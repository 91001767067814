import { InteractiveCard, Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import When from 'src/components/Common/When';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';
import { EmployeeIndividualChoices } from 'src/types/employees/EmployeeIndividualChoice';
import EmployeeIndividualChoicesDrawer from './Drawer';
import { Employee } from 'src/types/Employee';
import './individual-choices-section.scss';

interface EmployeeIndividualChoicesSectionProps {
  employee: Employee;
}

function EmployeeIndividualChoicesSection({ employee }: EmployeeIndividualChoicesSectionProps) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { t } = useTranslation();

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  return (
    <QueryWrapper
      query={GET_EMPLOYEE_INDIVIDUAL_CHOICES}
      options={{ variables: { employeeId: employee.id, allowDraft: true } }}
    >
      {(employeeIndividualChoices: EmployeeIndividualChoices) => (
        <>
          <InteractiveCard
            data-cy="individual-choices-section"
            label={t('employees:individual-choices.individual-choices')}
            onClick={openDrawer}
            buttonLabel={t('employees:individual-choices.view-more')}
          >
            <div className="individual-choices-section__pills">
              {employeeIndividualChoices.individualChoices.length > 0 ? (
                employeeIndividualChoices.individualChoices.map((choice) => (
                  <Pill key={choice.id} label={t(`individualChoiceTypes:${choice.type}`)} />
                ))
              ) : (
                <Pill type="heads-up" label={t('employees:individual-choices.no-individual-choices')} />
              )}
            </div>
          </InteractiveCard>
          <When condition={drawerOpen}>
            <EmployeeIndividualChoicesDrawer
              open={drawerOpen}
              onClose={closeDrawer}
              employeeIndividualChoices={employeeIndividualChoices}
              employee={employee}
            />
          </When>
        </>
      )}
    </QueryWrapper>
  );
}

export default EmployeeIndividualChoicesSection;
