import { Banner, InteractiveCard } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { Employee } from 'src/types/Employee';
import EmployeeSectionLabel from '../../EmployeeSectionLabel';
import { useTranslation } from 'react-i18next';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import When from 'src/components/Common/When';
import AbsenceOverviewDrawer from '../AbsenceOverviewDrawer';
import './absence-overview-section.scss';

interface AbsenceOverviewSectionProps {
  employee: Employee;
  aggregatedAbsenceEmployeeCards: AggregatedEmployeeCard[];
  employeeCardId: string;
  period: string;
}

const AbsenceOverviewSection: FC<AbsenceOverviewSectionProps> = ({
  employee,
  period,
  aggregatedAbsenceEmployeeCards,
  employeeCardId,
}) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWageMonth, setSelectedWageMonth] = useState<number>();
  const currentEmployeeCard = aggregatedAbsenceEmployeeCards.find((e) => e.period === period);

  const handleDisplayAbsenceWageTypesDrawer = (month: number) => {
    setDrawerOpen(true);
    setSelectedWageMonth(month);
  };

  const handleDisplayAbsenceOverviewDrawer = () => {
    setDrawerOpen(true);
  };

  const handleOnClose = () => {
    setDrawerOpen(false);
    setSelectedWageMonth(null);
  };

  const handleOnBackClick = () => {
    setSelectedWageMonth(null);
  };

  const hasChangesInDraft =
    currentEmployeeCard?.aggregatedWageTypeWages.some((aw) => aw.wages.some((w) => w.isDraftModified)) ?? false;

  const hasAbsenceInPeriod = currentEmployeeCard?.aggregatedWageTypeWages.length > 0;

  return (
    <>
      <InteractiveCard
        label={
          <EmployeeSectionLabel label={t('wages:absence')} period={period} hasChangesInDraft={hasChangesInDraft} />
        }
        onClick={handleDisplayAbsenceOverviewDrawer}
        data-cy="absence-overview-section"
      >
        <div className="absence-overview-section">
          {hasAbsenceInPeriod ? (
            <Banner type="success" message={t('wages:absence-in-period')} />
          ) : (
            <Banner type="announcement" message={t('wages:no-absence-in-period')} />
          )}
        </div>
      </InteractiveCard>
      <When condition={drawerOpen}>
        <AbsenceOverviewDrawer
          period={period}
          open={drawerOpen}
          employee={employee}
          selectedWageMonth={selectedWageMonth}
          onClose={handleOnClose}
          onBackClick={handleOnBackClick}
          onDisplayAbsenceWageTypesOverviewDrawer={handleDisplayAbsenceWageTypesDrawer}
          isDraft={employee.isDraft}
          aggregatedAbsenceEmployeeCards={aggregatedAbsenceEmployeeCards}
          employeeCardId={employeeCardId}
        />
      </When>
    </>
  );
};

export default AbsenceOverviewSection;
