import React from 'react';
import { IconPill, Typography } from 'fgirot-k2-ui-components';
import ReportingEmploymentDetails from '../ReportingEmploymentDetails';
import './reporting-employee-header.scss';
import EmployeeHasDraftPill from 'src/components/Common/EmployeeHasDraftPill/EmployeeHasDraftPill';
import EmploymentCategoryIcon from 'src/components/Common/EmploymentCategoryIcon/EmploymentCategoryIcon';

interface ReportingEmployeeHeaderProps {
  fullName: string;
  personNumber: string;
  employmentCategory?: string;
  employmentDetails: EmploymentDetails[];
  hasDraft: boolean;
}

export interface EmploymentDetails {
  employmentNumber: string;
  employerId: string;
  employmentGroupId?: string;
  policyId?: string;
}

function ReportingEmployeeHeader({
  fullName,
  personNumber,
  employmentCategory,
  employmentDetails,
  hasDraft,
}: ReportingEmployeeHeaderProps) {
  return (
    <div className="reporting-employee-header" data-testid="reporting-employee-header">
      <div className="reporting-employee-header__title">
        <div className="reporting-employee-header__icon">
          <EmploymentCategoryIcon employmentCategory={employmentCategory} />
        </div>
        <Typography component="h3" variant="body1" bold data-testid="reporting-employee-header__full-name">
          {fullName}
        </Typography>
        <EmployeeHasDraftPill hasDraft={hasDraft} />
      </div>
      {employmentDetails.map((employeeDetails) => (
        <ReportingEmploymentDetails
          key={employeeDetails.employmentNumber}
          employerId={employeeDetails.employerId}
          employmentGroupId={employeeDetails.employmentGroupId}
          employmentNumber={employeeDetails.employmentNumber}
          policyId={employeeDetails.policyId}
        />
      ))}
      <IconPill icon="User" label={personNumber} />
    </div>
  );
}

export default ReportingEmployeeHeader;
