import { gql } from '@apollo/client';

export const EMPLOYEE_INDIVIDUAL_SETTINGS_CREATED = gql`
  subscription EmployeeIndividualSettingsCreated($employeeIds: [UUID]!) {
    individualSettingsCreated(employeeIds: $employeeIds) {
      employeeId
      individualSettingsRows {
        settingsType
        settingsValue
        referenceType
        referenceId
      }
    }
  }
`;
