import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AccountingAdjustmentsTab from 'src/components/Account/EconomySettings/AccountingAdjustmentsTab';
import AccountingComponentTab from 'src/components/Account/EconomySettings/AccountingComponentTab/AccountingComponenTab';
import AccountingSettingsTab from 'src/components/Account/EconomySettings/AccountingSettingsTab';
import AccountingTypeTab from 'src/components/Account/EconomySettings/AccountingTypeTab/AccountingTypeTab';
import AccountingUnitsTab from 'src/components/Account/EconomySettings/AccountingUnitsTab';
import PaymentSettingsTab from 'src/components/Account/EconomySettings/PaymentSettingsTab';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import useTabParams from 'src/components/hooks/useTabParams';
import PageBase from 'src/components/PageBase/PageBase';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import { useNavigateWithFallback } from 'src/service/routes/LocationHooks';
import { Employer } from 'src/types/employer/Employer';
import { EconomySettingsTab } from 'src/types/tabs/EconomySettingsTab';

const EconomySettings = () => {
  const { t } = useTranslation();
  const { employerId, customerName } = useParams();
  const handleBackClick = useNavigateWithFallback(-1, '/');
  const [currentTab, setCurrentTab] = useTabParams('tab', EconomySettingsTab.ACCOUNTING_UNITS);

  return (
    <QueryWrapper query={GET_EMPLOYER} options={{ variables: { employerId } }}>
      {(employer: Employer) => {
        const breadCrumbs = [
          { name: customerName, steps: 1 },
          { name: t('navigation:account'), steps: 2 },
          { name: employer.legalName, steps: 3 },
        ];
        return (
          <PageBase title={t('economySettings:title')} handleBackButton={handleBackClick} breadCrumbs={breadCrumbs}>
            <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
              <Tab data-cy="accounting-units-tab" title={t('economySettings:tabs.accounting-units')}>
                <AccountingUnitsTab />
              </Tab>
              <Tab data-cy="accounting-types-tab" title={t('economySettings:tabs.accounting-types')}>
                <AccountingTypeTab />
              </Tab>
              <Tab data-cy="accounting-components-tab" title={t('economySettings:tabs.accounting-components')}>
                <AccountingComponentTab />
              </Tab>
              <Tab data-cy="accounting-adjustment-tab" title={t('economySettings:tabs.accounting-adjustments')}>
                <AccountingAdjustmentsTab />
              </Tab>
              <Tab data-cy="accounting-settings-tab" title={t('economySettings:tabs.accounting-settings')}>
                <AccountingSettingsTab />
              </Tab>
              <Tab data-cy="payment-settings-tab" title={t('economySettings:tabs.payment-settings')}>
                <PaymentSettingsTab employer={employer} />
              </Tab>
            </Tabs>
          </PageBase>
        );
      }}
    </QueryWrapper>
  );
};

export default EconomySettings;
