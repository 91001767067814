import { ListGroup } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYEE_REPORTING_EVENTS } from 'src/graphql/schema/query/reporting/GetEmployeeReportingEvents';
import { Employee } from 'src/types/Employee';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';
import { stringOrBlank } from 'src/util/StringUtil';

interface EmployeeInsuranceEventMessageTabProps {
  employee: Employee;
  insuranceEvent: InsuranceEvent;
}

function EmployeeInsuranceEventMessageTab({ insuranceEvent, employee }: EmployeeInsuranceEventMessageTabProps) {
  const { t } = useTranslation();

  return (
    <div className="employee-insurance-event-message-tab">
      <QueryWrapper
        query={GET_EMPLOYEE_REPORTING_EVENTS}
        options={{ variables: { employeeId: employee.id, wageFilePeriod: insuranceEvent.wageFilePeriod } }}
      >
        {(reportingEvents: ReportingEvent[]) => {
          const matchingReportingEvent = reportingEvents.find(
            (reportingEvent) =>
              reportingEvent.aggregatedInsuranceEvent.insuranceEventId === insuranceEvent.insuranceEventId,
          );
          return (
            <>
              {matchingReportingEvent && (
                <ListGroup variant="inline-edit">
                  <ListProperty
                    label={t('employeeInsuranceEvents:drawer.message-tab.agreement-number-label')}
                    value={stringOrBlank(insuranceEvent.employeeInsurance?.insurance?.agreementNumber)}
                    data-testid="insurance-event-details-drawer__agreement-number"
                  />
                  <ListProperty
                    label={t('employeeInsuranceEvents:drawer.message-tab.available-message-format-label')}
                    value={t(`reporting:available-message-format.${matchingReportingEvent.availableMessageFormat}`)}
                    data-testid="insurance-event-details-drawer__available-message-format"
                  />
                  <ListProperty
                    label={t('employeeInsuranceEvents:drawer.message-tab.message-status-label')}
                    value={
                      matchingReportingEvent.messageReceipt?.messageStatus
                        ? t(`reporting:message-status.${matchingReportingEvent.messageReceipt?.messageStatus}`)
                        : '-'
                    }
                    data-testid="insurance-event-details-drawer__message-status"
                  />
                </ListGroup>
              )}
            </>
          );
        }}
      </QueryWrapper>
    </div>
  );
}

export default EmployeeInsuranceEventMessageTab;
