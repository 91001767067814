export interface EmployerControlPointRule {
  ruleName: string;
  ruleValueUnit: string;
  ruleValues: ControlPointRuleValue[];
}

export interface ControlPointRuleValue {
  value: string;
  valueType: string;
}

export const mandatoryDataBasicFields: string[] = [
  'personNumber',
  'employmentNumber',
  'firstName',
  'lastName',
  'employmentType',
  'employmentStartDate',
  'employmentCategory',
  'remunerationType',
];

export const mandatoryDataEmployerFields: string[] = ['accountingUnitId'];
