import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import PolicyBenefitTab from '../PolicyBenefitTab';
import PolicyEmploymentGroupRulesTab from '../PolicyEmploymentGroupRulesTab';
import DrawerBase from 'src/components/Common/DrawerBase';
import { PolicyEmploymentGroupDrawerTab } from 'src/types/tabs/PolicyEmploymentGroupDrawerTab';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';

interface PolicyEmploymentGroupDrawerProps {
  employerId: string;
  policyEntityState: PolicyState;
  policyEmploymentGroup: PolicyEmploymentGroup;
  onDisplayRuleTypeDrawer: (affiliationRuleGroupType: AffiliationRuleGroupType, ruleBlockId: string) => void;
  onClose: () => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}
const PolicyEmploymentGroupDrawer: FunctionComponent<PolicyEmploymentGroupDrawerProps> = ({
  employerId,
  policyEntityState,
  policyEmploymentGroup,
  onDisplayRuleTypeDrawer,
  onClose,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<PolicyEmploymentGroupDrawerTab>(
    PolicyEmploymentGroupDrawerTab.AFFILIATION,
  );

  return (
    <DrawerBase
      onClose={onClose}
      title={policyEmploymentGroup.employmentGroupName}
      subTitle={t('account:employers-tab.policy-settings.policy-employment-group-drawer.affiliation-rules')}
    >
      <Tabs className="policy-employment-group-drawer__tabs" tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab
          title={t('account:employers-tab.policy-settings.policy-employment-group-drawer.affiliation')}
          key="policy-employment-group-rules-tab"
        >
          <PolicyEmploymentGroupRulesTab
            employerId={employerId}
            policyEntityState={policyEntityState}
            policyEmploymentGroup={policyEmploymentGroup}
            onDisplayRuleTypeDrawer={onDisplayRuleTypeDrawer}
            employerIndividualChoices={employerIndividualChoices}
          />
        </Tab>
        <Tab title={t('account:employers-tab.policy-settings.benefits')} key="policy-benefit-tab">
          <PolicyBenefitTab policyBenefits={policyEmploymentGroup.policyBenefits} />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default PolicyEmploymentGroupDrawer;
