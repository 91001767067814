import { gql } from '@apollo/client';

export const GET_EMPLOYERS_REPORTING_FLOW_SETTINGS = gql`
  query GetEmployersReportingFlowSettings($request: EmployersReportingFlowSettingsRequest!) {
    employersReportingFlowSettings(employersReportingFlowSettingsRequest: $request) {
      id
      employerId
      disableAutomaticReporting
    }
  }
`;
