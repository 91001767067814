import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingItem } from 'src/types/ledgerStorage/AccountingItem';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import { formatSweAmountTextAlwaysWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';

interface AccountingItemTableRowProps {
  accountingItem: AccountingItem;
  accountingComponentTypes: string[];
}
const AccountingItemTableRow: FC<AccountingItemTableRowProps> = ({ accountingItem, accountingComponentTypes }) => {
  const { t } = useTranslation();

  return (
    <TableRow data-testid={`accounting-items-table__${accountingItem.id}`}>
      <Td compressed>{stringOrBlank(accountingItem.employee.firstName)}</Td>
      <Td compressed>{stringOrBlank(accountingItem.employee.lastName)}</Td>
      <Td compressed>{stringOrBlank(accountingItem.employee.personNumber)}</Td>
      <Td compressed>{stringOrBlank(usePeriodText(accountingItem.accountingPeriod))}</Td>
      <Td compressed>{stringOrBlank(usePeriodText(accountingItem.premiumPeriod))}</Td>
      <Td compressed>{stringOrBlank(t(`accountingItems:accounting-statuses.${accountingItem.status}`))}</Td>
      <Td compressed>{stringOrBlank(accountingItem.accountingNumber)}</Td>
      <Td compressed>{stringOrBlank(t(`accountingTypeValue:${accountingItem.accountingTypeValue}`))}</Td>
      <>
        {accountingComponentTypes.map((type) => (
          <Td key={`AccountingComponentTypeTd-${type}`}>
            {accountingItem.accountingComponents
              .filter((component) => component.accountingComponentValue?.accountingComponentType?.name === type)
              .map((component) => component.name)
              .join(', ')}
          </Td>
        ))}
      </>
      <Td alignRight compressed>
        {stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(accountingItem.amount))}
      </Td>
      <Td centered compressed>
        {stringOrBlank(accountingItem.invoiceNumber)}
      </Td>
      <Td compressed>{stringOrBlank(accountingItem.insuranceProvider.legalName)}</Td>
      <Td compressed>
        {!accountingItem.insurance?.benefitType
          ? '-'
          : stringOrBlank(t(`benefitTypes:${accountingItem.insurance?.benefitType}`))}
      </Td>
      <Td compressed>{stringOrBlank(accountingItem.insuranceTypeMapping?.name)}</Td>
    </TableRow>
  );
};

export default AccountingItemTableRow;
