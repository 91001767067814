import { InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styles from './styles.module.scss';
import ApolloServiceWrapper from 'src/components/Common/ApolloServiceWrapper';
import ListProperty from 'src/components/Common/ListProperty';
import {
  ReportingDetailLevel,
  ContractAgreedProduct,
  reportingDetailLevels,
} from 'src/types/contract/ContractAgreedProduct';
import { InsuranceEventType } from 'src/types/reporting/InsuranceEvent';
import { ReportingType } from 'src/types/reporting/ReportingType';
import useAgreedReportingType from './hooks/useAgreedReportingType';

type _SelectableReportingDetailLevel = 'DEFAULT' | ReportingDetailLevel;

interface InlineEditSelectProps {
  label: string;
  placeholder: string;
  options: Option<_SelectableReportingDetailLevel>[];
  selectedValue: _SelectableReportingDetailLevel;
  onChange: (selected: _SelectableReportingDetailLevel) => void;
}

const _InlineEditSelect = ({ label, placeholder, options, onChange, selectedValue }: InlineEditSelectProps) => {
  return (
    <InlineEdit label={label} bold>
      <Select
        small
        placeholder={placeholder}
        options={options}
        selected={options.find((value) => value.value == (selectedValue ?? 'DEFAULT'))}
        onChange={(option: Option<_SelectableReportingDetailLevel>) => onChange(option.value)}
      />
    </InlineEdit>
  );
};

interface AgreedProductGroupingProps {
  employerId: string;
  contractId: string;
  agreedProduct: ContractAgreedProduct;
}
const ReportTypeSelectables: React.FC<AgreedProductGroupingProps> = ({ employerId, contractId, agreedProduct }) => {
  const { t } = useTranslation();
  const [result, service, handleUpdate] = useAgreedReportingType(employerId, agreedProduct.id, contractId);

  const reportingTypeOptions: Option<_SelectableReportingDetailLevel>[] = [
    ...reportingDetailLevels.map((reportingDetailLevel) => ({
      label: t(`reportingDetailLevels:${reportingDetailLevel}`),
      value: reportingDetailLevel,
    })),
    { label: t('agreementRegistrySettings:drawer:tabs:default-reporting-level-label'), value: 'DEFAULT' },
  ];

  return (
    <ApolloServiceWrapper service={service}>
      {Object.keys(result).map((reportingType: ReportingType) => (
        <ListGroup variant="inline-edit" key={reportingType}>
          {/* Reporting Type */}
          <_InlineEditSelect
            placeholder={t('common:select')}
            label={t(`reportingTypes:${reportingType}`)}
            onChange={(v) => handleUpdate(reportingType, null, v !== 'DEFAULT' ? v : null)}
            selectedValue={result[reportingType].reportingType.value}
            options={reportingTypeOptions}
          />
          {/* Insurance Event Types */}
          {Object.keys(result[reportingType].insuranceTypes).map((insuranceEventType: InsuranceEventType) => (
            <_InlineEditSelect
              key={insuranceEventType}
              selectedValue={result[reportingType].insuranceTypes[insuranceEventType].value}
              options={reportingTypeOptions}
              placeholder={t('common:select')}
              label={t(`insuranceEventTypes:${insuranceEventType}`)}
              onChange={(v) => handleUpdate(reportingType, insuranceEventType, v !== 'DEFAULT' ? v : null)}
            />
          ))}
        </ListGroup>
      ))}
    </ApolloServiceWrapper>
  );
};

const AgreedProductGrouping: React.FC<AgreedProductGroupingProps> = (props) => {
  const { agreedProduct } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.agreedProductGroupingContainer}>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('agreementRegistrySettings:drawer:tabs:default-reporting-level')}
          value={
            agreedProduct.defaultReportingDetailLevel
              ? t(`reportingDetailLevels:${agreedProduct.defaultReportingDetailLevel}`)
              : '-'
          }
        />
      </ListGroup>
      <ReportTypeSelectables {...props} />
    </div>
  );
};
export default AgreedProductGrouping;
