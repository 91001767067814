import { Divider, ListGroup } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import ScrollBase from 'src/components/Common/ScrollBase';
import { GET_REPORTING_EVENT_MESSAGE_RECEIPT } from 'src/graphql/schema/query/reporting/GetReportingEventMessageReceipt';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';
import { ReportingEventMessageReceipt } from 'src/types/reporting/ReportingEventMessageReceipt';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';

interface ReportingEventAboutTabProps {
  reportingEvent: ReportingEvent;
}

const ReportingEventMessageTab = ({ reportingEvent }: ReportingEventAboutTabProps) => {
  const { t } = useTranslation();
  const messageStatus = reportingEvent.messageReceipt?.messageStatus || 'QUEUED';

  return (
    <ScrollBase maxHeightPercentage={60} className="reporting-event-message-tab">
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('reporting:reporting-events.reporting-event-type')}
          value={t(`reportingTypes:${reportingEvent.reportingEventType}`)}
          data-testid="reporting-event-message-tab__reporting-event-type"
        />
        <ListProperty
          label={t('reporting:reporting-events.available-message-format')}
          value={t(`reporting:available-message-format.${reportingEvent.availableMessageFormat}`)}
          data-testid="reporting-event-message-tab__available-message-format"
        />
        <ListProperty
          label={t('reporting:reporting-events.recipient')}
          value={t(`benefitTypes:${reportingEvent.insuranceProvider.legalName}`)}
          data-testid="reporting-event-message-tab__recipient"
        />
        {reportingEvent.messageReceipt && (
          <QueryWrapper
            query={GET_REPORTING_EVENT_MESSAGE_RECEIPT}
            options={{ variables: { messageReceiptId: reportingEvent.messageReceipt.id } }}
          >
            {(messageReceipt: ReportingEventMessageReceipt) => {
              return (
                <>
                  <ListProperty
                    label={t('reporting:reporting-events.file')}
                    value={messageReceipt.fileName}
                    data-testid="reporting-event-message-tab__file"
                  />
                  <Divider />
                  <ListProperty
                    label={t('reporting:reporting-events.transfer-channel')}
                    value={messageReceipt.transferChannel ?? '-'}
                    data-testid="reporting-event-message-tab__transfer-channel"
                  />
                  <Divider />
                  <ListProperty
                    label={t('reporting:reporting-events.message-sent-at')}
                    value={messageReceipt.messageSentAt ? formatDateTimeString(messageReceipt.messageSentAt) : '-'}
                    data-testid="reporting-event-message-tab__message-sent-at"
                  />
                </>
              );
            }}
          </QueryWrapper>
        )}
        <ListProperty
          label={t('reporting:reporting-events.message-status')}
          value={t(`reporting:message-status.${messageStatus}`)}
          data-testid="reporting-event-message-tab__message-status"
        />
      </ListGroup>
    </ScrollBase>
  );
};

export default ReportingEventMessageTab;
