import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { Invoice } from 'src/types/invoice/Invoice';
import { PaymentDueDate } from 'src/types/invoice/PaymentDueDate';
import { stringOrBlank } from 'src/util/StringUtil';
import './payment-due-date-details.scss';

interface PaymentDueDateDetailsProps {
  invoice: Invoice;
  paymentDueDate: PaymentDueDate;
  onClose: () => void;
  onBackClick: () => void;
}

const PaymentDueDateDetails: FC<PaymentDueDateDetailsProps> = ({ invoice, paymentDueDate, onClose, onBackClick }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="invoice-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('invoice:invoice-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="invoice-drawer__sub-title">
          <Typography>{invoice.employer?.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={
            <div className="payment-due-date-tab__drawer-navigation-label">
              <Typography bold variant="body2" component="span">
                {t('invoice:invoice-drawer.payment-due-dates-tab.due-date-label')}
              </Typography>
              {paymentDueDate.active ? (
                <Pill type="success" label={t('invoice:invoice-drawer.payment-due-dates-tab.active-due-date')} />
              ) : (
                <Pill type="warning" label={t('invoice:invoice-drawer.payment-due-dates-tab.inactive-due-date')} />
              )}
            </div>
          }
          value={stringOrBlank(paymentDueDate.dueDate).substring(0, 10)}
        />
        <ListProperty
          label={t('invoice:invoice-drawer.payment-due-dates-tab.note-label')}
          value={stringOrBlank(paymentDueDate.note)}
        />
      </ListGroup>
    </Drawer>
  );
};

export default PaymentDueDateDetails;
