import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeInsurance } from 'src/types/insurance/Insurance';
import { unique } from 'src/util/ArrayUtil';
import { createOptionsFromList } from 'src/util/OptionUtil';

function useInsurancesFilterOptions(employeeInsurances: EmployeeInsurance[]) {
  const { t } = useTranslation();

  const benefitTypeFilterOptions = React.useMemo(() => {
    const benefitTypes = employeeInsurances.map((i) => i.insurance.benefitType);
    const uniqueBenefitTypes = [...new Set(benefitTypes)];
    return uniqueBenefitTypes.map((benefitType) => ({
      label: t(`benefitTypes:${benefitType}`),
      value: benefitType,
    }));
  }, [employeeInsurances, t]);

  const insuranceProviderFilterOptions = React.useMemo(() => {
    const insuranceProviders = employeeInsurances.map((i) => i.insurance.insuranceProvider);
    return unique(createOptionsFromList(insuranceProviders, 'id', 'legalName'), 'id');
  }, [employeeInsurances, t]);

  const agreedProductFilterOptions = React.useMemo(() => {
    const agreedProducts = employeeInsurances.map((i) => i.insurance.agreedProduct);
    return unique(createOptionsFromList(agreedProducts, 'id', 'name'));
  }, [employeeInsurances, t]);

  const insuranceNumberOptions = React.useMemo(() => {
    const insuranceNumbers = employeeInsurances.map((i) => i.insurance.insuranceNumber);
    const uniqueInsuranceNumbers = [...new Set(insuranceNumbers)];
    return uniqueInsuranceNumbers
      .filter((insuranceNumber) => insuranceNumber)
      .map((insuranceNumber) => ({
        label: insuranceNumber,
        value: insuranceNumber,
      }));
  }, [employeeInsurances, t]);

  const agreementNumberOptions = React.useMemo(() => {
    const agreementNumbers = employeeInsurances.map((i) => i.insurance.agreementNumber);
    const uniqueAgreementNumbers = [...new Set(agreementNumbers)];
    return uniqueAgreementNumbers.map((agreementNumber) => ({
      label: agreementNumber,
      value: agreementNumber,
    }));
  }, [employeeInsurances, t]);

  return {
    benefitTypeFilterOptions,
    insuranceProviderFilterOptions,
    agreedProductFilterOptions,
    insuranceNumberOptions,
    agreementNumberOptions,
  };
}

export default useInsurancesFilterOptions;
