import { Button, Drawer, Icon, InlineEdit, ListGroup, Pill, Switch, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { useState } from 'react';
import { AgreementCostCentreRequest } from 'src/types/contract/request/AgreementCostCentreRequest';
import { useCreateContractAgreementCostCentre } from 'src/service/contract/CreateContractAgreementCostCentre';
import TextInput from 'src/components/Common/TextInput';
import { stringOrBlank } from 'src/util/StringUtil';

interface CreateNewAgreementCostCentreProps {
  contract: InsuranceProviderContract;
  onClose: () => void;
  onBackClick: () => void;
  navigateToAgreementCostCentreDetails: (agreementCostCentreId: string) => void;
}

const CreateNewAgreementCostCentre = ({
  contract,
  onClose,
  onBackClick,
  navigateToAgreementCostCentreDetails,
}: CreateNewAgreementCostCentreProps) => {
  const { t } = useTranslation();
  const createContractAgreementCostCentre = useCreateContractAgreementCostCentre();

  const [agreementCostCentreRequest, setAgreementCostCentreRequest] = useState<AgreementCostCentreRequest>({
    code: '',
    isDefault: false,
  });

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreementCostCentreRequest({ ...agreementCostCentreRequest, code: event.target.value });
  };

  const handleIsDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreementCostCentreRequest({ ...agreementCostCentreRequest, isDefault: event.target.checked });
  };

  const onCreateNewAgreementCostCentre = () => {
    createContractAgreementCostCentre(contract.employerId, contract.id, agreementCostCentreRequest).then(({ id }) => {
      navigateToAgreementCostCentreDetails(id);
    });
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="agreement-registry-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {contract.name}
        </Typography>
        <div className="agreement-registry-drawer__sub-title">
          <Typography>{stringOrBlank(contract.insuranceProvider?.legalName)}</Typography>
          <Pill type="neutral" label={contract.agreementNumber} />
        </div>
      </div>

      <div className="create-new-agreed-product">
        <Typography variant="body1" bold>
          {t('agreementRegistrySettings:drawer.new-agreement-cost-centre')}
        </Typography>
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('agreementRegistrySettings:drawer.code')} bold mandatory>
            <TextInput
              type="text"
              validationKey="text3"
              maxLength={4}
              data-cy="agreement-cost-centre-code-input"
              variant="inline-edit"
              onChange={handleCodeChange}
              value={agreementCostCentreRequest.code}
            />
          </InlineEdit>
          <InlineEdit label={t('agreementRegistrySettings:drawer.is-default')} bold>
            <Switch onChange={handleIsDefaultChange} toggled={agreementCostCentreRequest.isDefault} />
          </InlineEdit>
        </ListGroup>
        <Button
          label={t('common:add')}
          onClick={onCreateNewAgreementCostCentre}
          disabled={!agreementCostCentreRequest.code.length || agreementCostCentreRequest.code.length > 3}
          data-cy="create-new-agreement-cost-centre-button"
        />
      </div>
    </Drawer>
  );
};

export default CreateNewAgreementCostCentre;
