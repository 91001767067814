import { Button, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import When from 'src/components/Common/When';
import './premium-invoice-rows-filter-details.scss';
import { PremiumInvoiceRowsFilter } from 'src/types/invoice/PremiumInvoiceRowsFilter';

interface PremiumInvoiceRowsFilterDetailsProps {
  filter: PremiumInvoiceRowsFilter;
  applyFilters: (filter: PremiumInvoiceRowsFilter) => void;
}

export const PremiumInvoiceRowsFilterDetails: FC<PremiumInvoiceRowsFilterDetailsProps> = ({ filter, applyFilters }) => {
  const { t } = useTranslation();
  const hasInsuranceProviderFilter = filter?.insuranceProviderIds?.length > 0;
  const hasPeriodStartFilter = filter?.periodStart.year != null;
  const hasPeriodEndFilter = filter?.periodEnd.year != null;

  const hasAnyFilter = hasInsuranceProviderFilter || hasPeriodStartFilter || hasPeriodEndFilter;

  const removeInsuranceProviderFilter = () => {
    applyFilters({
      ...filter,
      insuranceProviderIds: null,
    });
  };

  const removePeriodStartFilter = () => {
    applyFilters({
      ...filter,
      periodStart: { year: null, month: null },
    });
  };

  const removePeriodEndFilter = () => {
    applyFilters({
      ...filter,
      periodEnd: { year: null, month: null },
    });
  };

  const removeAllFilters = () => {
    applyFilters({
      insuranceProviderIds: [],
      periodStart: { year: null, month: null },
      periodEnd: { year: null, month: null },
    });
  };

  return (
    <div className="premium-invoice-rows-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      <When condition={!hasAnyFilter}>
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      </When>
      <When condition={hasInsuranceProviderFilter}>
        <FilterButton
          label={filter?.insuranceProviderIds?.length + ' ' + t('premiumInvoiceRows:filters.insurance-provider-filter')}
          onClick={removeInsuranceProviderFilter}
        />
      </When>
      <When condition={hasPeriodStartFilter}>
        <FilterButton
          label={
            t('premiumInvoiceRows:filters.period-start-filter') +
            ' ' +
            t(`common:months:${filter?.periodStart?.month}`) +
            ' ' +
            filter?.periodStart?.year
          }
          onClick={removePeriodStartFilter}
        />
      </When>
      <When condition={hasPeriodEndFilter}>
        <FilterButton
          label={
            t('premiumInvoiceRows:filters.period-end-filter') +
            ' ' +
            t(`common:months:${filter?.periodEnd?.month}`) +
            ' ' +
            filter?.periodEnd?.year +
            ' '
          }
          onClick={removePeriodEndFilter}
        />
      </When>
      {hasAnyFilter && (
        <Button
          type="warning"
          size="small"
          label={t('premiumInvoiceRows:filters.remove-all')}
          onClick={removeAllFilters}
        />
      )}
    </div>
  );
};

export default PremiumInvoiceRowsFilterDetails;
