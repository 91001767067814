import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_COMMENTS } from 'src/graphql/schema/query/employee/GetEmployeeComments';
import { CREATE_EMPLOYEE_COMMENT } from 'src/graphql/schema/mutation/employee/CreateEmployeeComment';

export const useCreateEmployeeComment = () => {
  const apolloMutation = useApolloMutation();

  const createEmployeeComment = (employeeId: string, comment: string, employerId: string) =>
    apolloMutation(
      CREATE_EMPLOYEE_COMMENT,
      employerId,
      {
        employeeId,
        comment,
      },
      [GET_EMPLOYEE_COMMENTS],
    );

  return createEmployeeComment;
};
