import { ListGroup, InlineEdit, Select, Option } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEmployerKubs } from 'src/service/employer/GetEmployerKubs';
import { useUpdatePolicyBenefitKub } from 'src/service/policy/UpdatePolicyBenefitKub';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyState } from 'src/types/policy/PolicyEntity';

interface KubBlockProps {
  policyEntityState: PolicyState;
  policyId: string;
  policyBenefit: PolicyBenefit;
  employerId: string;
}

export const KubBlock: FC<KubBlockProps> = ({ policyEntityState, policyId, policyBenefit, employerId }) => {
  const { t } = useTranslation();
  const updatePolicyBenefitKub = useUpdatePolicyBenefitKub();

  const employerKubs = useEmployerKubs(employerId);

  const options: Option<string>[] = employerKubs.map((employerKub) => ({
    label: employerKub.kubType,
    value: employerKub.kubType,
  }));

  const handleKubChange = (selected: Option<string>) => {
    updatePolicyBenefitKub(policyId, policyBenefit.benefitPackageId, selected.value, employerId);
  };

  return (
    <ListGroup variant="inline-edit" className="policy-benefit-drawer__tab__table">
      <InlineEdit bold label={t('account:employers-tab.policy-settings.table.drawer.kub-model')}>
        <Select
          placeholder={t('common:select')}
          disabled={policyEntityState !== 'DRAFT'}
          options={options}
          selected={options.find((opt) => opt.value === policyBenefit.kubType)}
          onChange={handleKubChange}
          data-cy={'policy-benefit-calculation__kub_dropdown'}
          small
        />
      </InlineEdit>
    </ListGroup>
  );
};

export default KubBlock;
