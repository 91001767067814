import { ContractAgreedProduct } from 'src/types/contract/ContractAgreedProduct';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';

export const agreedProductRequestIsValid = (request: ContractAgreedProductRequest): boolean => {
  let isValid = true;

  // if there is no name or insuranceProductId request is invalid
  if (!request.name || !request.insuranceProductId) {
    isValid = false;
  }

  // if certifyInsuranceProductCondition is true and certifyInsuranceProductConditionId is not set request is invalid
  if (request.certifyInsuranceProductCondition && !request.certifyInsuranceProductConditionId) {
    isValid = false;
  }

  // if certifyInsuranceProductCondition is false and certifyInsuranceProductConditionId is set request is invalid
  if (!request.certifyInsuranceProductCondition && request.certifyInsuranceProductConditionId) {
    isValid = false;
  }

  if (request.name && request.name.length > 64) {
    isValid = false;
  }

  if (request.insuranceCategory && request.insuranceCategory.length > 64) {
    isValid = false;
  }

  if (request.agreementProductCode && request.agreementProductCode.length > 16) {
    isValid = false;
  }

  if (request.reportingRuleConfigurationId != null && request.defaultReportingDetailLevel == null) {
    isValid = false;
  }

  return isValid;
};

export const hasAgreedProductRequestChangedFromAgreedProduct = (
  request: ContractAgreedProductRequest,
  agreedProduct: ContractAgreedProduct,
): boolean => {
  let hasChanged = false;

  if (
    request.name !== agreedProduct.name ||
    request.insuranceProductId !== agreedProduct.insuranceProduct?.id ||
    request.reportingRuleConfigurationId !== agreedProduct.reportingRuleConfiguration?.id ||
    request.insuranceCategory !== agreedProduct.insuranceProviderProductCategory ||
    request.agreementProductCode !== agreedProduct.agreementProductCode ||
    request.certifyInsuranceProductCondition !== agreedProduct.certifyInsuranceProductCondition ||
    request.certifyInsuranceProductConditionId !== agreedProduct.certifyInsuranceProductConditionId ||
    request.defaultReportingDetailLevel !== agreedProduct.defaultReportingDetailLevel
  ) {
    hasChanged = true;
  }

  return hasChanged;
};
