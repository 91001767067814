import { useApolloMutation } from '../graphql/Mutation';
import { DELETE_DRAFT_EMPLOYEE } from 'src/graphql/schema/mutation/employee/DeleteDraftEmployee';
import { FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/FindEmployeeCardHistoricalAggregatedWages';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';
import { GET_LATEST_EMPLOYEE_CARD } from 'src/graphql/schema/query/employee/GetLatestEmployeeCard';

export const useDeleteDraftEmployee = () => {
  const apolloMutation = useApolloMutation();

  const deleteDraftEmployee = (employeeId: string, employerId: string) =>
    apolloMutation(
      DELETE_DRAFT_EMPLOYEE,
      employerId,
      {
        employeeId,
      },
      [
        GET_EMPLOYEE,
        GET_LATEST_EMPLOYEE_CARD,
        FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES,
        GET_EMPLOYEE_INDIVIDUAL_CHOICES,
      ],
    );

  return deleteDraftEmployee;
};
