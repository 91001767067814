import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TableRow, Tabs, Td } from 'fgirot-k2-ui-components';
import LoadingPage from 'src/components/Common/LoadingPage';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useEmployerControlPointRules } from 'src/service/employer/GetEmployerControlPointRules';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { Employer } from 'src/types/employer/Employer';
import { EmployerControlPointRule } from 'src/types/employer/EmployerControlPointRule';
import EmployerFieldsTabContent from './EmployerFieldsTabContent';
import DrawerModal from 'src/components/Common/DrawerModal';

interface MandatoryDataEmployerTypeRowProps {
  employer: Employer;
}

const MandatoryDataEmployerTypeRow = ({ employer }: MandatoryDataEmployerTypeRowProps) => {
  const [employerFieldsDataModalOpen, setEmployerFieldsDataModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleRowClick = () => setEmployerFieldsDataModalOpen((prev) => !prev);

  const handleModalOnClose = () => setEmployerFieldsDataModalOpen(false);
  const service = useEmployerControlPointRules(employer.id, 'DATA_VALIDATION_CATEGORY');

  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>Error...</div>;
    case LOADED:
      break;
  }
  const employerControlPointRules: EmployerControlPointRule[] = service.payload;

  return (
    <>
      <TableRow key="mandatory-data-employer-type-row" onClick={handleRowClick}>
        <Td>{t('account:employers-tab.reporting-settings.mandatory-data-tab.control-type-employer')}</Td>
        <Td>
          {employerControlPointRules[0]?.ruleValues
            .map((fieldRule) => {
              return `${t(`violationTypes:${fieldRule.value}`)}`;
            })
            .join(', ')}
        </Td>
      </TableRow>
      <DrawerModal overlayBackground overlayOnClose open={employerFieldsDataModalOpen} onClose={handleModalOnClose}>
        <DrawerBase
          onClose={handleModalOnClose}
          title={t('account:employers-tab.reporting-settings.mandatory-data-tab.control-type-employer')}
          subTitle={t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer.type-employer-subtitle')}
        >
          <Tabs>
            <Tab
              title={t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer.type-employer-control-tab')}
            >
              <EmployerFieldsTabContent employerFieldsRuleValues={employerControlPointRules[0]?.ruleValues} />
            </Tab>
            <Tab
              title={t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer.type-employer-history-tab')}
            >
              <div></div>
            </Tab>
          </Tabs>
        </DrawerBase>
      </DrawerModal>
    </>
  );
};
export default MandatoryDataEmployerTypeRow;
