import { Modal, Card, Icon, CardAction, Button, FileCard } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WageFileSession } from 'src/types/WageFileSession';
import ValidationErrorModal from '../ReportingStatus/CompanyUploadStatus/ValidationErrorModal';
import './parse-error-modal.scss';

interface ParseErrorModalProps {
  open: boolean;
  removeFiles: () => void;
  errorSessions: WageFileSession[];
}

const ParseErrorModal = ({ open, removeFiles, errorSessions }: ParseErrorModalProps) => {
  const { t } = useTranslation();
  const handleOnClick = () => {
    removeFiles && removeFiles();
  };
  const [selectedSessionId, setSelectedSessionId] = useState<string>(null);

  const handleFileCardOnClick = (id: string) => {
    setSelectedSessionId(id);
  };
  const handleValidationErrorModalOnClose = () => {
    setSelectedSessionId(null);
  };
  return (
    <>
      <Modal
        className={['parse-error-modal'].join(' ')}
        centered
        overlayBackground
        open={open}
        onClose={handleValidationErrorModalOnClose}
      >
        <Card
          icon={<Icon type="Alert" className="parse-error-modal__icon" />}
          title={t('upload:parse-error-modal.file-cannot-be-received')}
        >
          <>
            {errorSessions.map((session, index) => (
              <FileCard
                onClick={() => handleFileCardOnClick(session.id)}
                key={`FileCardError-${session.id}-${index}`}
                className={index !== 0 ? 'parse-error-modal--margin' : ''}
                type="announcement"
                title={session.filename}
                icon={<Icon type="FileBlank" />}
                error
                fileFormat="pdf"
              />
            ))}
          </>
          <CardAction>
            <Button
              icon={<Icon type="Trash" className="report__error-modal__icon" />}
              label={t('upload:parse-error-modal.remove-and-continue')}
              onClick={handleOnClick}
            />
          </CardAction>
        </Card>
      </Modal>{' '}
      <ValidationErrorModal
        open={selectedSessionId !== null}
        onClose={handleValidationErrorModalOnClose}
        session={errorSessions.find((session) => session.id === selectedSessionId)}
      />
    </>
  );
};

export default ParseErrorModal;
