import { Datepicker } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDaysShort } from 'src/util/date/DaysShort';
import { useMonths } from 'src/util/date/Months';
import { createOptions } from 'src/util/OptionUtil';
import { getYears } from 'src/util/date/Years';
import { datePickerSchema } from 'src/validation/schemas/datePickerSchema';

interface UpdateDatePickerProps {
  fieldValue: string;
  fieldKey: string;
  onBlur: (field: string, value: string) => void;
  label?: string;
  initialMonth?: number;
  initialYear?: number;
  disabled?: boolean;
  placeholder?: string;
}

const UpdateDatePicker = ({
  fieldKey,
  fieldValue,
  onBlur,
  label,
  initialMonth,
  initialYear,
  disabled,
  placeholder = 'XXXX-MM-DD',
  ...props
}: UpdateDatePickerProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(fieldValue || '');
  const [errorMessage, setErrorMessage] = useState<string>();
  const { error } = datePickerSchema.validate({ [fieldKey]: value }, { abortEarly: false });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value.toString());
  };

  const handleValidation = () => {
    error && error.details.length > 0
      ? setErrorMessage(t(`validationErrors:${error.details[0].context.key}.${error.details[0].type}`))
      : onBlur(fieldKey, value);
  };

  const monthOptions = useMonths().map((month, index) => {
    return { label: month, value: index + 1 };
  });

  useEffect(() => {
    error ?? setErrorMessage('');
  }, [error]);

  useEffect(() => {
    setValue(fieldValue || '');
  }, [fieldValue]);

  return (
    <Datepicker
      label={label}
      variant="inline-edit"
      className="update-employee-date-picker"
      placeholder={placeholder}
      onChange={handleChange}
      onClose={handleValidation}
      onBlur={handleValidation}
      value={value}
      setValue={setValue}
      months={monthOptions}
      daysShort={createOptions(getDaysShort())}
      years={createOptions(getYears())}
      initialMonth={initialMonth}
      initialYear={initialYear}
      errorMessage={errorMessage}
      disabled={disabled}
      {...props}
    />
  );
};

export default UpdateDatePicker;
