import { DrawerNavigation, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import './reporting-events-tab.scss';
import { PersonReportingEventsResponse } from 'src/types/PersonReportingEventsResponse';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';
import { stringOrBlank } from 'src/util/StringUtil';

interface ReportingEventsTabProps {
  reportingEventsData: PersonReportingEventsResponse;
  onSelectEvent: (event: ReportingEvent) => void;
}

const ReportingEventsTab = ({ reportingEventsData, onSelectEvent }: ReportingEventsTabProps) => {
  const { t } = useTranslation();

  const handleSelectEvent = (event: ReportingEvent) => {
    onSelectEvent(event);
  };

  return (
    <div className="reporting-events-tab">
      {reportingEventsData.employees.map((e) => (
        <EmployerName employerId={e.employerId} />
      ))}
      <ListGroup variant={'drawer-navigation'} className="reporting-events-tab__list">
        {reportingEventsData.reportingEvents.map((event) => (
          <DrawerNavigation
            key={event.id}
            label={
              <div className="insurance-events-tab__event-label">
                <Typography variant="body2" bold>
                  {t(`reportingTypes:${event.reportingEventType}`)}
                </Typography>
                <Pill type="neutral" size="small" label={`${t(`benefitTypes:abbreviations.${event.benefitType}`)}`} />
                {event.insuranceProvider && (
                  <Pill type="neutral" size="small" label={event.insuranceProvider.legalName} />
                )}
              </div>
            }
            value={stringOrBlank(event.aggregatedInsuranceEvent?.eventFromDate)}
            onClick={() => handleSelectEvent(event)}
          />
        ))}
      </ListGroup>
    </div>
  );
};

const EmployerName = ({ employerId }: { employerId: string }) => {
  const employerLegalName = useEmployerLegalName(employerId);
  return (
    <Typography component="h4" variant="subtitle" bold>
      {employerLegalName}
    </Typography>
  );
};

export default ReportingEventsTab;
