import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import When from 'src/components/Common/When';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { EmployeeIndividualChoice, EmployeeIndividualChoices } from 'src/types/employees/EmployeeIndividualChoice';
import IndividualChoiceInputs from './IndividualChoiceInputs';
import { Banner } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { UpdateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/UpdateEmployeeIndividualChoiceRequest';
import './update-individual-choice-itp1-from-date.scss';
import { useUpdateEmploymentEventCheckpointIndividualChoice } from 'src/service/checkpoint/UpdateEmploymentEventCheckpointIndividualChoice';

interface UpdateIndividualChoiceITP1FromDateProps {
  checkpoint: EmploymentEventCheckpoint;
  employeeId: string;
  employmentStartDate: string;
  employerId: string;
  onBackClick: () => void;
}

const UpdateIndividualChoiceITP1FromDate: React.FC<UpdateIndividualChoiceITP1FromDateProps> = ({
  checkpoint,
  employeeId,
  employmentStartDate,
  employerId,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const updateEmploymentEventCheckpointIndividualChoice = useUpdateEmploymentEventCheckpointIndividualChoice();

  const handleUpdateIndividualChoice = (request: UpdateEmployeeIndividualChoiceRequest) => {
    setLoading(true);
    updateEmploymentEventCheckpointIndividualChoice(request, employerId)
      .then(() => {
        onBackClick();
      })
      .finally(() => setLoading(false));
  };

  return (
    <QueryWrapper
      query={GET_EMPLOYEE_INDIVIDUAL_CHOICES}
      options={{
        variables: {
          employeeId,
          allowDraft: false,
        },
      }}
    >
      {({ individualChoices }: EmployeeIndividualChoices) => {
        const matchingIndividualChoice = getIndividualChoice(individualChoices, checkpoint);

        return (
          <div>
            <When condition={!!matchingIndividualChoice}>
              <IndividualChoiceInputs
                individualChoice={matchingIndividualChoice}
                employeeId={employeeId}
                employmentStartDate={employmentStartDate}
                onUpdate={handleUpdateIndividualChoice}
                loading={loading}
              />
            </When>
            <When condition={!matchingIndividualChoice}>
              <Banner type="warning" message={t('examination:drawer.no-matching-individual-choice')} />
            </When>
          </div>
        );
      }}
    </QueryWrapper>
  );
};

const getIndividualChoice = (individualChoices: EmployeeIndividualChoice[], checkpoint: EmploymentEventCheckpoint) =>
  individualChoices.find((choice) => choice.id === checkpoint.additionalData?.individualChoiceId);

export default UpdateIndividualChoiceITP1FromDate;
