export const reportingTypes = [
  'NEW_REGISTRATION',
  'REGISTRATION_PREMIUM_BASED_AMOUNT',
  'CHANGE_OF_PREMIUM_AMOUNT',
  'END_REGISTRATION_DUE_TO_CLOSURE',
  'REPORTING_SICK',
  'NOTIFICATION_OF_HEALTH',
  'CHANGE_OF_BENEFIT',
] as const;

export type ReportingType = typeof reportingTypes[number];
