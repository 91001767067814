import { ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchInsuranceEventInfos } from 'src/service/insurance/GetInsuranceEventInfos';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEventInfo } from 'src/types/InsuranceEventInfo';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import './supplier-info-table.scss';
import SupplierInfoTableRow from './SupplierInfoTableRow';

interface SupplierInfoTableProps {
  insuranceEventInfos?: InsuranceEventInfo[];
  checkpoints: InsuranceEventCheckpoint[];
  insuranceProviders: InsuranceProvider[];
  handleSelectedProduct: (checkpointId: string) => void;
  employerId: string;
}

const SupplierInfoTable = ({
  checkpoints,
  handleSelectedProduct,
  insuranceProviders,
  employerId,
}: SupplierInfoTableProps) => {
  const { t } = useTranslation();
  const [insuranceEventInfos, setInsuranceEventInfos] = useState<InsuranceEventInfo[]>();

  useEffect(() => {
    fetchInsuranceEventInfos(checkpoints.map((checkpoint) => checkpoint.additionalData.insuranceEventId)).then((res) =>
      setInsuranceEventInfos(res),
    );
  }, []);

  return (
    insuranceProviders &&
    insuranceEventInfos && (
      <div className={['employee-info-table'].join(' ')}>
        <div className="employee-info-table__table">
          <Typography bold variant="subtitle" className="employee-info-table__title">
            {t('certify:drawer.certify-full-employability')}
          </Typography>
          <ListGroup variant={'drawer-navigation'}>
            {checkpoints.map((checkpoint) => {
              const insuranceEvent = insuranceEventInfos.find(
                (insuranceEvent) => insuranceEvent.insuranceEventId === checkpoint.additionalData.insuranceEventId,
              );
              const insuranceProvider = insuranceProviders.find(
                (insuranceProvider) => insuranceProvider.id === checkpoint.additionalData.insuranceProviderId,
              );

              return (
                <SupplierInfoTableRow
                  insuranceProvider={insuranceProvider}
                  key={insuranceEvent.insuranceEventId}
                  insuranceEventInfo={insuranceEvent}
                  checkpoint={checkpoint}
                  handleSelectedProduct={handleSelectedProduct}
                  employerId={employerId}
                />
              );
            })}
          </ListGroup>
        </div>
      </div>
    )
  );
};

export default SupplierInfoTable;
