import React from 'react';
import { Icon, IconPill, Typography } from 'fgirot-k2-ui-components';
import style from './styles.module.scss';

interface CheckpointListHeaderProps {
  fullName: string;
  personNumber: string;
  company: string;
  onClick: () => void;
}

const CheckpointListHeader: React.FC<CheckpointListHeaderProps> = ({ fullName, personNumber, company, onClick }) => {
  return (
    <div className={style.headerContainer} data-testid="controlpoints-list-header" onClick={onClick}>
      <div className={style.title}>
        <div className={style.icon}>
          <Icon type="User" />
        </div>
        <Typography component="h3" variant="body1" bold data-testid="controlpoints-list-header__full-name">
          {fullName}
        </Typography>
      </div>
      <div className={style.pillsOuterContainer}>
        <div className={style.pillsInnrContainer}>
          <IconPill label={company} />
          <IconPill icon="User" label={personNumber} />
        </div>
      </div>
    </div>
  );
};

export default CheckpointListHeader;
