import { gql } from '@apollo/client';

export const UPDATE_POSTING_RULE = gql`
  mutation UpdatePostingRule($request: UpdatePostingRuleInput!) {
    updatePostingRule(request: $request) {
      id
    }
  }
`;

export const UPDATE_POSTING_RULE_COMPONENTS = gql`
  mutation UpdatePostingRuleComponents($request: UpdatePostingRuleComponentsInput!) {
    updatePostingRuleComponents(request: $request) {
      id
    }
  }
`;
