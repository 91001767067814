import { Banner } from 'fgirot-k2-ui-components';
import React from 'react';
import { Employer } from 'src/types/employer/Employer';
import { EmployerReportingFlowSetting } from 'src/types/employer/EmployerReportingFlowSetting';
import './disable-automatic-reporting-warning-banner.scss';
import { useTranslation } from 'react-i18next';

interface DisableAutomaticReportingWarningBannerProps {
  employers: Employer[];
  reportingFlowSettings: EmployerReportingFlowSetting[];
}

function DisableAutomaticReportingWarningBanner({
  employers,
  reportingFlowSettings,
}: DisableAutomaticReportingWarningBannerProps) {
  const { t } = useTranslation();

  const disabledEmployerSettings = reportingFlowSettings.filter((setting) => setting.disableAutomaticReporting);

  if (disabledEmployerSettings.length === 0) {
    return null;
  }

  let bannerMessage = '';

  if (disabledEmployerSettings.length < 4) {
    bannerMessage = `${t('reporting:disable-automatic-reporting.reporting-stopped')} ${disabledEmployerSettings
      .map((setting) => {
        const employer = employers.find((e) => e.id === setting.employerId);
        return employer?.legalName;
      })
      .join(', ')}.`;

    bannerMessage = bannerMessage.replace(/,([^,]*)$/, ' &$1');
  }

  if (disabledEmployerSettings.length >= 4) {
    bannerMessage = `${t('reporting:disable-automatic-reporting.reporting-stopped')} ${disabledEmployerSettings
      .slice(0, 3)
      .map((setting) => {
        const employer = employers.find((e) => e.id === setting.employerId);
        return employer?.legalName;
      })
      .join(', ')}, & ${disabledEmployerSettings.length - 3} ${t('reporting:disable-automatic-reporting.more')}.`;
  }

  return <Banner type="warning" message={bannerMessage} className="disable-automatic-reporting-banner" />;
}

export default DisableAutomaticReportingWarningBanner;
