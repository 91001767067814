import { Button, Card, TableBody, TableHead, TableRow, Td, Th } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { AccrualRule } from 'src/types/accountingunit/AccrualRule';
import AddAccrualDeviationModal from './AddAccrualDeviationModal';

interface AccrualDeviationCardProps {
  accrualRule: AccrualRule;
}
const AccrualDeviationCard: FC<AccrualDeviationCardProps> = ({ accrualRule }) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((prev) => !prev);
  return (
    <Card
      title={t('economySettings:accounting-settings-tab.accrual-rule-cards.accrual-deviation-title')}
      className="accounting-settings-tab__card-content"
    >
      <TableBase compressed>
        <TableHead>
          <TableRow>
            <Th compressed>{t('economySettings:accounting-settings-tab.accrual-rule-cards.insurance-provider')}</Th>
            <Th compressed>{t('economySettings:accounting-settings-tab.accrual-rule-cards.invoice-row-type')}</Th>
            <Th compressed>{t('economySettings:accounting-settings-tab.accrual-rule-cards.benefit-type')}</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {accrualRule.accrualDeviations.map((accrualDeviation) => {
            return (
              <TableRow key={`AccrualDeviationTableRow-${accrualDeviation.id}`}>
                <Td>{accrualDeviation.insuranceTypeMapping?.insuranceProvider.legalName}</Td>
                <Td>{accrualDeviation.insuranceTypeMapping?.name}</Td>
                <Td>{accrualDeviation.insuranceTypeMapping?.benefitType}</Td>
              </TableRow>
            );
          })}
        </TableBody>
      </TableBase>
      <Button
        data-cy="add-accrual-deviation-button"
        label={t('economySettings:accounting-settings-tab.accrual-rule-cards.add-exception')}
        onClick={toggleModalOpen}
      />
      <AddAccrualDeviationModal
        accrualRuleId={accrualRule.id}
        open={modalOpen}
        onClose={toggleModalOpen}
        accrualDeviations={accrualRule.accrualDeviations}
      />
    </Card>
  );
};

export default AccrualDeviationCard;
