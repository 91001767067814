import { AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import { useApolloQuery, useApolloQueryList } from '../graphql/Query';
import { GET_EMPLOYEE_AGGREGATED_WAGES } from 'src/graphql/schema/query/employee/GetEmployeeAggregatedWages';

export const useEmployeeAggregatedWages = (
  employeeId: string,
  fromPeriod: string,
  toPeriod: string,
  wageTypes: string[],
  allowDrafts: boolean,
) => {
  const data = useApolloQueryList<AggregatedWageTypeWage>(GET_EMPLOYEE_AGGREGATED_WAGES, {
    variables: {
      employeeId,
      fromPeriod,
      toPeriod,
      wageTypes,
      allowDrafts,
    },
    fetchPolicy: 'no-cache',
  });

  return data;
};

export const useApolloQueryEmployeeAggregatedWages = (
  employeeId: string,
  fromPeriod: string,
  toPeriod: string,
  wageTypes: string[],
  allowDrafts: boolean,
) => {
  return useApolloQuery<AggregatedWageTypeWage[]>(GET_EMPLOYEE_AGGREGATED_WAGES, {
    variables: {
      employeeId,
      fromPeriod,
      toPeriod,
      wageTypes,
      allowDrafts,
    },
    fetchPolicy: 'no-cache',
  });
};
