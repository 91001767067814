import { Button, DrawerNavigation, InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import './groups-drawer-employer-tab.scss';
import { useTranslation } from 'react-i18next';
import { GroupEmployer } from 'src/types/group/Group';
import { useRemoveEmployerFromGroup } from 'src/service/group/RemoveEmployerFromGroup';

interface GroupsDrawerEmployerTabProps {
  onAddEmployerClick: () => void;
  groupId: string;
  employers: GroupEmployer[];
}
const GroupsDrawerEmployerTab: React.FunctionComponent<GroupsDrawerEmployerTabProps> = ({
  groupId,
  employers,
  onAddEmployerClick,
}) => {
  const { t } = useTranslation();
  const removeEmployerFromGroup = useRemoveEmployerFromGroup();
  const removeEmployer = (employerId) => {
    removeEmployerFromGroup(groupId, employerId);
  };
  return (
    <>
      <ListGroup variant={'inline-edit'} className="groups-drawer-employer-tab">
        <DrawerNavigation
          className="groups-drawer-employer-tab__navigation"
          label={t('permission:groups-drawer.add-employer-label')}
          onClick={onAddEmployerClick}
        />
      </ListGroup>
      <ListGroup variant={'inline-edit'}>
        {employers.map((employer) => (
          <InlineEdit bold key={employer.id} label={employer.name} alignRight>
            <Button label={t('common:delete')} type="secondary" onClick={() => removeEmployer(employer.id)} />
          </InlineEdit>
        ))}
      </ListGroup>
    </>
  );
};

export default GroupsDrawerEmployerTab;
