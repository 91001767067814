import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { Icon, Input } from 'fgirot-k2-ui-components';

interface EventsSearchInputProps {
  initialSearchInput: string;
  onSearch: (searchInput: string) => void;
  disabled?: boolean;
}

const EventsSearchInput = ({ initialSearchInput, disabled, onSearch }: EventsSearchInputProps) => {
  const [searchInput, setSearchInput] = useState(initialSearchInput);
  const { t } = useTranslation();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (initialSearchInput !== event.target.value) {
      onSearch(searchInput);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchInput);
    } else if (e.key === 'Escape') {
      setSearchInput('');
    }
  };

  return (
    <Input.Text
      disabled={disabled}
      placeholder={t('reporting:search-text:search-input-placeholder-text')}
      type="search"
      icon={<Icon type="Search" />}
      value={searchInput}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
      data-cy="reporting-events-search-input"
    />
  );
};

export default EventsSearchInput;
