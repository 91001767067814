import { Icon, Tooltip, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
interface EmploymentPropertyProps {
  label: string;
  value: string;
  hasChanged?: boolean;
}
const nullToDash = (val: string) => {
  if (!val || val === 'null') {
    return '-';
  }
  return val;
};

const EmploymentProperty: FC<EmploymentPropertyProps> = ({ label, value, hasChanged }) => {
  const { t } = useTranslation();

  return (
    <div className="employment-overview-section__property" data-cy="employement-overview-property">
      <Typography variant="caption2" className="employment-overview-section__property-label">
        {label}
      </Typography>
      {hasChanged ? (
        <Tooltip content={t('employees:employee-draft-property-changed')} direction="bottom">
          <div className="employment-overview-section__property-value">
            <Typography variant="body2" bold className="employment-overview-section__property-text">
              {nullToDash(value)}
            </Typography>
            <Icon type="Info" stroke="#a6aebb" className="employment-overview-section__property-icon" />
          </div>
        </Tooltip>
      ) : (
        <div className="employment-overview-section__property-value">
          <Typography variant="body2" bold className="employment-overview-section__property-text">
            {nullToDash(value)}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default EmploymentProperty;
