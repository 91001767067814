import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageBase from 'src/components/PageBase';
import { useTranslation } from 'react-i18next';
import useTabParams from 'src/components/hooks/useTabParams';
import InsuranceEvents from '../InsuranceEvents/InsuranceEvents';
import ReportingEvents from '../ReportingEvents/ReportingEvents';
import { useCustomer } from 'src/service/customer/GetCustomer';

const EventsOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { year } = useParams();
  const [currentTab, setCurrentTab] = useTabParams('tab', 0);
  const customer = useCustomer();

  return (
    <PageBase
      title={t('reporting:events') + ' ' + customer?.name}
      handleBackButton={() => navigate('/reporting/' + year)}
      breadCrumbs={[
        {
          name: customer?.name || '',
          steps: 1,
        },
        {
          name: 'Rapportering',
          steps: 2,
        },
      ]}
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab} fixed>
        <Tab title={t('reporting:tabs.insurance-events')}>
          <InsuranceEvents />
        </Tab>
        <Tab title={t('reporting:tabs.report-events')}>
          <ReportingEvents />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default EventsOverview;
