import { Divider, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import InfoMessage from 'src/components/Common/InfoMessage';
import { useEmployeeAggregatedWages } from 'src/service/employeecard/GetEmployeeAggregatedWages';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { EmployeeCard } from 'src/types/EmployeeCard';
import { getPreviousPeriod, usePeriodText } from 'src/util/date/PeriodUtil';
import { useTranslation } from 'react-i18next';
import FixedSupplementWageRow from './WageRow';
import { SubActionCategory } from 'src/types/SubActionCategory';
import './wage-type-change-info.scss';
import WageRow from './WageRow';

interface WageTypeChangeInfoProps {
  employeeCard: EmployeeCard;
  employerId: string;
  subActionCategory: SubActionCategory;
}

function WageTypeChangeInfo({ employeeCard, employerId, subActionCategory }: WageTypeChangeInfoProps) {
  const { t } = useTranslation();

  const relevantWageTypes = getRelevantWageTypes(subActionCategory);
  const currentPeriod = employeeCard.period;
  const previousPeriod = getPreviousPeriod(currentPeriod);
  const employerWageTypes = useEmployerWageTypes(employerId);

  const currentPeriodWages = useEmployeeAggregatedWages(
    employeeCard.employeeId,
    currentPeriod,
    currentPeriod,
    relevantWageTypes,
    false,
  );

  const previousPeriodWages = useEmployeeAggregatedWages(
    employeeCard.employeeId,
    previousPeriod,
    previousPeriod,
    relevantWageTypes,
    false,
  );

  const [wageTypeTitle, noWagesForCurrentPeriod, noWagesForPreviousPeriod] = getWageTypeTranslations(subActionCategory);

  return (
    <section className="wage-type-change-info">
      <Typography variant="subtitle" bold>
        {t(wageTypeTitle)}
      </Typography>
      <div>
        <div className="wage-type-change-info__period-header">
          <Typography variant="body2">{t('examination:drawer.current-period')}</Typography>
          <PeriodPill period={currentPeriod} />
        </div>
        <ListGroup variant="inline-edit">
          {currentPeriodWages.map((wage) => {
            const wageType = employerWageTypes.find((wt) => wt.id === wage.employerWageTypeId);
            return <FixedSupplementWageRow key={wage.employerWageTypeId} wage={wage} wageType={wageType} />;
          })}
        </ListGroup>
        {currentPeriodWages.length === 0 && <InfoMessage message={t(noWagesForCurrentPeriod)} />}
      </div>
      <Divider />
      <div>
        <div className="wage-type-change-info__period-header">
          <Typography variant="body2">{t('examination:drawer.previous-period')}</Typography>
          <PeriodPill period={previousPeriod} />
        </div>
        <ListGroup variant="inline-edit">
          {previousPeriodWages.map((wage) => {
            const wageType = employerWageTypes.find((wt) => wt.id === wage.employerWageTypeId);
            return <WageRow key={wage.employerWageTypeId} wage={wage} wageType={wageType} />;
          })}
        </ListGroup>
        {previousPeriodWages.length === 0 && <InfoMessage message={t(noWagesForPreviousPeriod)} />}
      </div>
    </section>
  );
}

const PeriodPill = ({ period }: { period: string }) => {
  const periodText = usePeriodText(period);
  return <Pill type="neutral" label={periodText} />;
};

const getRelevantWageTypes = (subActionCategory: SubActionCategory) => {
  switch (subActionCategory) {
    case 'FIXED_SUPPLEMENT_CHANGE':
      return ['FIXED_SUPPLEMENT_VACATION', 'FIXED_SUPPLEMENT_NON_VACATION'];
    case 'GROSS_DEDUCTION_AMOUNT_CHANGE':
      return ['GROSS_DEDUCTION'];
    case 'SALARY_EXCHANGE_AMOUNT_CHANGE':
      return ['SALARY_EXCHANGE'];
    default:
      return [];
  }
};

const getWageTypeTranslations = (subActionCategory: SubActionCategory): [string, string, string] => {
  switch (subActionCategory) {
    case 'FIXED_SUPPLEMENT_CHANGE':
      return [
        'examination:drawer.fixed-supplements',
        'examination:drawer.no-fixed-supplements-current-period',
        'examination:drawer.no-fixed-supplements-previous-period',
      ];
    case 'GROSS_DEDUCTION_AMOUNT_CHANGE':
      return [
        'examination:drawer.gross-deduction',
        'examination:drawer.no-gross-deduction-current-period',
        'examination:drawer.no-gross-deduction-previous-period',
      ];
    case 'SALARY_EXCHANGE_AMOUNT_CHANGE':
      return [
        'examination:drawer.salary-exchange',
        'examination:drawer.no-salary-exchange-current-period',
        'examination:drawer.no-salary-exchange-previous-period',
      ];
    default:
      return ['', '', ''];
  }
};

export default WageTypeChangeInfo;
