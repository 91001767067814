import { gql } from '@apollo/client';

export const GET_PAYMENT_TRANSACTIONS = gql`
  query GetPaymentTransactions($invoiceHeaderId: UUID!) {
    paymentTransactions(invoiceHeaderId: $invoiceHeaderId) {
      id
      createdDate
      amount
      note
    }
  }
`;
