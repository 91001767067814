import { REMOVE_USER_FROM_GROUP } from 'src/graphql/schema/mutation/group/RemoveUserFromGroup';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { GET_USERS } from 'src/graphql/schema/query/user/GetUsers';
import { useApolloMutation } from 'src/service/graphql/Mutation';

export const useRemoveUserFromGroup = () => {
  const apolloMutation = useApolloMutation();

  const removeUserFromGroup = (groupId: string, userId: string) =>
    apolloMutation(REMOVE_USER_FROM_GROUP, '', { groupId, userId }, [GET_GROUPS, GET_USERS]);

  return removeUserFromGroup;
};
