import { Button, Icon, Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { EmployeeCardBenefit } from 'src/types/employees/EmployeeCardBenefit';
import './benefit-details-drawer.scss';
import BenefitDetailsAboutTab from './BenefitDetailsAboutTab';
import BenefitDetailsAffiliationTab from './BenefitDetailsAffiliationTab/BenefitDetailsAffiliationTab';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import { EmployeeBenefitDetailsDrawerTab } from 'src/types/tabs/EmployeeBenefitDetailsDrawerTab';
import { useState } from 'react';

interface BenefitsDetailsDrawerProps {
  onBackClick: () => void;
  onClose: () => void;
  benefit: EmployeeCardBenefit;
  period: string;
}

const BenefitDetailsDrawer = ({ onBackClick, onClose, benefit, period }: BenefitsDetailsDrawerProps) => {
  const { t } = useTranslation();
  const periodText = getFormattedDateStringFromPeriod(period);
  const [currentTab, setCurrentTab] = useState<EmployeeBenefitDetailsDrawerTab>(EmployeeBenefitDetailsDrawerTab.ABOUT);

  return (
    <DrawerBase
      title={
        <>
          <Typography variant="h2" bold>
            {benefit.name}
          </Typography>
          <Pill type="success" label={periodText} />
        </>
      }
      onClose={onClose}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
    >
      <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('common:about')}>
          <div className="benefit-details-drawer">
            <BenefitDetailsAboutTab benefit={benefit} />
          </div>
        </Tab>
        <Tab title={t('benefits:benefits-drawer.affiliation')}>
          <div className="benefit-details-drawer">
            <BenefitDetailsAffiliationTab benefit={benefit} />
          </div>
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default BenefitDetailsDrawer;
