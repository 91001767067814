import React, { useState } from 'react';
import EmployeeInput from '../EmployeeInput';
import { EmployeeInputProps } from '../EmployeeInput/EmployeeInput';
import { DateTime } from 'luxon';
import RetroactiveEmploymentStartDateModal from '../RetroactiveEmploymentStartDateModal';

function EmploymentStartDateInput(employeeInputProps: EmployeeInputProps) {
  const [chosenDate, setChosenDate] = useState<DateTime>(undefined);

  const employmentStartDateChanged = (key: string, value: string) => {
    const oldDate = DateTime.fromFormat(employeeInputProps.activeEmployee.employmentStartDate, 'yyyy-MM-dd');
    const newDate = DateTime.fromFormat(value, 'yyyy-MM-dd');

    if (newDate.diff(oldDate, 'months').months < 0) {
      setChosenDate(newDate);
    } else {
      employeeInputProps.handleChange(key, value);
    }
  };

  const closeModal = () => {
    setChosenDate(undefined);
  };

  return (
    <>
      <EmployeeInput {...employeeInputProps} handleChange={employmentStartDateChanged} type="employmentStartDate" />
      <RetroactiveEmploymentStartDateModal
        open={chosenDate !== undefined}
        employeeId={employeeInputProps.activeEmployee.id}
        employerId={employeeInputProps.activeEmployee.employerId}
        newEmploymentStartDate={chosenDate}
        afterBackfillComplete={() =>
          employeeInputProps.handleChange('employmentStartDate', chosenDate.toFormat('yyyy-MM-dd'))
        }
        onCancel={closeModal}
      />
    </>
  );
}

export default EmploymentStartDateInput;
