import { useApolloQuery } from 'src/service/graphql/Query';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { GET_EMPLOYEE_INSURANCE_EVENTS } from 'src/graphql/schema/query/employee/GetEmployeeInsuranceEvents';
import { useState, useEffect } from 'react';
import useInsuranceEventSubscription from 'src/service/insurance/InsuranceEventSubscription';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { Service } from 'src/types/Service';
import { INIT } from 'src/types/Status';
import { EmployeeInsuranceEventsPage } from 'src/types/employees/EmployeeInsuranceEventsPage';

const useEmployeeInsuranceEvents = (
  employeeId: string,
  fromPeriod: string,
  toPeriod: string,
  checkpoints: InsuranceEventCheckpoint[],
): Service<EmployeeInsuranceEventsPage> => {
  const [data, setData] = useState<Service<EmployeeInsuranceEventsPage>>({ status: INIT });

  const service = useApolloQuery<EmployeeInsuranceEventsPage>(GET_EMPLOYEE_INSURANCE_EVENTS, {
    skip: !employeeId,
    variables: {
      employeeId,
      request: {
        pageNumber: 0,
        pageSize: 100,
        filters: {
          wageFilePeriodFilter: {
            periodStart: fromPeriod,
            periodEnd: toPeriod,
          },
        },
      },
    },
  });

  const insuranceEventIds = checkpoints.map((checkPoint) => checkPoint.additionalData.insuranceEventId);

  const handleUpdate = (insuranceEvent: InsuranceEvent) => {
    setData((prev) => {
      if (prev.status !== 'loaded') return prev;
      return {
        ...prev,
        payload: {
          ...prev.payload,
          employeeInsuranceEvents: prev.payload.employeeInsuranceEvents.map((prevInsuranceEvent) => {
            if (prevInsuranceEvent.insuranceEventId == insuranceEvent.insuranceEventId)
              return { ...prevInsuranceEvent, eventFromDate: insuranceEvent.eventFromDate };
            return prevInsuranceEvent;
          }),
        },
      };
    });
  };
  useInsuranceEventSubscription(insuranceEventIds, handleUpdate);

  useEffect(() => {
    setData(service);
  }, [service]);

  return data;
};

export default useEmployeeInsuranceEvents;
