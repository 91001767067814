import { post } from 'src/http/post';
import KeycloakInstance from 'src/keycloak';

export const updateKeycloakWithSelectedCustomer = async (
  customerId: string,
  successToast?: () => void,
  errorToast?: () => void,
) => {
  const { url, data, config } = prepareRequest(customerId);

  try {
    sessionStorage.setItem('customerId', JSON.stringify(customerId));
    const response = await post(url, data, config);
    const accessToken = response.data['access_token'];
    const refreshToken = response.data['refresh_token'];
    KeycloakInstance.token = accessToken;
    KeycloakInstance.refreshToken = refreshToken;
    successToast && successToast();
    return response;
  } catch (e) {
    errorToast && errorToast();
    return null;
  }
};

const prepareRequest = (customerId: string) => {
  const windowConfig = window['config'];
  const keycloakUrl = windowConfig['KEYCLOAK_URL'];
  const realm = windowConfig['KEYCLOAK_REALM'];
  const clientId = windowConfig['KEYCLOAK_CLIENT'];
  const refreshToken = KeycloakInstance.refreshToken;
  const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
  const url = `${keycloakUrl}/realms/${realm}/protocol/openid-connect/token`;
  const data = {
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken,
    customer_id: customerId,
  };
  return { url, data, config };
};
