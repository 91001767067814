import { Checkbox, DrawerNavigation, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEventInfo } from 'src/types/InsuranceEventInfo';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { isCheckpointStatusMatching } from 'src/util/CheckpointUtil';
import './supplier-info-table-row.scss';

interface SupplierInfoTableRowProps {
  insuranceEventInfo: InsuranceEventInfo;
  checkpoint: InsuranceEventCheckpoint;
  insuranceProvider: InsuranceProvider;
  handleSelectedProduct: (checkpointId: string) => void;
  employerId: string;
}

const SupplierInfoTableRow = ({
  checkpoint,
  insuranceEventInfo,
  insuranceProvider,
  handleSelectedProduct,
  employerId,
}: SupplierInfoTableRowProps) => {
  const { t } = useTranslation();

  const handleOnChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? updateCheckpointStatusMutation([checkpoint.id], 'ACCEPTED', employerId)
      : updateCheckpointStatusMutation([checkpoint.id], 'RAISED', employerId);
  };

  const handleOnClick = () => {
    handleSelectedProduct(checkpoint.id);
  };

  const createLabel = () => {
    return (
      <>
        <Checkbox
          disabled={isCheckpointStatusMatching(checkpoint, 'DECLINED')}
          checked={isCheckpointStatusMatching(checkpoint, 'ACCEPTED')}
          onChange={handleOnChangeChecked}
        />
        <div className="supplier-info-table-row__left">
          <Typography variant="body2" bold>
            {insuranceProvider.legalName}
          </Typography>
          <Pill
            key={`examination-rule-pill-${checkpoint.id}`}
            className={'supplier-info-table-row__pill--margin'}
            type="neutral"
            label={t(`benefitTypes:abbreviations.${insuranceEventInfo.benefitType}`)}
          />
        </div>
      </>
    );
  };

  return <DrawerNavigation label={createLabel()} onClick={handleOnClick} value={insuranceEventInfo.agreementNumber} />;
};

export default SupplierInfoTableRow;
