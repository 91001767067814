import { CREATE_ACCOUNTING_COMPONENT_TYPE } from 'src/graphql/schema/mutation/accountingunit/CreateAccountingComponentType';
import { ACCOUNTING_COMPONENT_TYPES } from 'src/graphql/schema/query/accountingunit/AccountingComponentTypes';
import { CreateAccountingComponentTypeInput } from 'src/types/accountingunit/AccountingComponent';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateAccountingComponentType = () => {
  const apolloMutation = useApolloMutation();

  const createAccountingComponentType = (request: CreateAccountingComponentTypeInput, employerId: string) =>
    apolloMutation(CREATE_ACCOUNTING_COMPONENT_TYPE, employerId, { request }, [ACCOUNTING_COMPONENT_TYPES]);

  return createAccountingComponentType;
};
