import {
  Tab,
  Tabs,
  Typography,
  ListGroup,
  InlineEdit,
  Button,
  Switch,
  Option,
  Select,
  Collapsible,
} from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import TextInput from 'src/components/Common/TextInput';
import { Employer } from 'src/types/employer/Employer';
import {
  PaymentServiceProviderType,
  BankType,
  PaymentAccount,
  paymentServiceProviderType,
  bankType,
  PatchAccountInput,
} from 'src/types/payment/PaymentAccount';
import './payment-settings-drawer.scss';
import { createOptions } from 'src/util/OptionUtil';
import { usePatchAccount } from 'src/service/payment/Account';
import { useParams } from 'react-router-dom';

interface PaymentSettingsDrawerProps {
  open: boolean;
  employer: Employer;
  account: PaymentAccount;
  onClose: () => void;
}

const PaymentSettingsDrawer: FC<PaymentSettingsDrawerProps> = ({ open, employer, account, onClose }) => {
  const { t } = useTranslation();

  const patchAccount = usePatchAccount();
  const { employerId } = useParams();

  const [patchAccountInput, setPatchAccountInput] = useState<PatchAccountInput>({
    id: account.id,
    accountNumber: account.accountNumber,
    paymentServiceProvider: account.paymentServiceProvider,
    accountActive: account.accountActive,
    bank: account.bank,
  });

  const handleAccountNumberChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPatchAccountInput({ ...patchAccountInput, accountNumber: e.target.value });

  const paymentServiceProviderOptions: Option<string>[] = [
    ...createOptions(paymentServiceProviderType, t, 'economySettings:payment-settings-tab.payment-service-provider'),
  ];

  const bankOptions: Option<string>[] = [...createOptions(bankType, t, 'economySettings:payment-settings-tab.bank')];

  const saveAccountButton = () => patchAccount(patchAccountInput, employerId).then(onClose);

  const handlePaymentServiceProviderChange = (option: Option<PaymentServiceProviderType>) => {
    setPatchAccountInput((prev) => ({ ...prev, paymentServiceProvider: option.value }));
  };

  const handleBankChange = (option: Option<BankType>) => {
    setPatchAccountInput((prev) => ({ ...prev, bank: option.value }));
  };

  const handleToggleActiveAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatchAccountInput((prev) => ({ ...prev, accountActive: e.target.checked }));
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        title={t('economySettings:payment-settings-tab.payment-settings-drawer.title')}
        subTitle={employer.legalName}
        onClose={onClose}
      >
        <Tabs>
          <Tab title={t('common:about')}>
            <Typography variant="body2" component="span">
              {t('economySettings:payment-settings-tab.payment-settings-drawer.info-text')}
            </Typography>
            <div className="payment-settings-drawer">
              <ListGroup variant="inline-edit">
                <InlineEdit
                  label={t('economySettings:payment-settings-tab.payment-settings-drawer.active-account-label')}
                  bold
                >
                  <Switch toggled={patchAccountInput.accountActive} onChange={handleToggleActiveAccount} />
                </InlineEdit>
              </ListGroup>

              <Collapsible open={patchAccountInput.accountActive}>
                <ListGroup variant="inline-edit">
                  <InlineEdit
                    label={t('economySettings:payment-settings-tab.payment-settings-drawer.account-number-label')}
                    bold
                  >
                    <TextInput
                      type="text"
                      validationKey="text10"
                      variant="inline-edit"
                      maxLength={11}
                      value={patchAccountInput.accountNumber}
                      onChange={handleAccountNumberChange}
                      data-cy="account-number__input"
                    />
                  </InlineEdit>
                  <InlineEdit
                    label={t(
                      'economySettings:payment-settings-tab.payment-settings-drawer.payment-service-provider-label',
                    )}
                    bold
                  >
                    <Select
                      data-cy="payment-service-provider__select"
                      placeholder={t('common:choose')}
                      options={paymentServiceProviderOptions}
                      selected={paymentServiceProviderOptions.find(
                        (option) => option.value === patchAccountInput.paymentServiceProvider,
                      )}
                      onChange={handlePaymentServiceProviderChange}
                      small
                    />
                  </InlineEdit>
                  <InlineEdit label={t('economySettings:payment-settings-tab.payment-settings-drawer.bank-label')} bold>
                    <Select
                      data-cy="bank__select"
                      placeholder={t('common:choose')}
                      options={bankOptions}
                      selected={bankOptions.find((option) => option.value === patchAccountInput.bank)}
                      onChange={handleBankChange}
                      small
                    />
                  </InlineEdit>
                </ListGroup>
              </Collapsible>
              <Button
                className="payment-settings-drawer__button"
                type="primary"
                label={t('common:save-changes')}
                onClick={saveAccountButton}
                data-cy="payment-settings__about-drawer-tab__save-button"
              />
            </div>
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
};

export default PaymentSettingsDrawer;
