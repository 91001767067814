import { Tabs, Tab } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { Employee } from 'src/types/Employee';
import { EmployeeIndividualChoice, EmployeeIndividualChoices } from 'src/types/employees/EmployeeIndividualChoice';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';
import EmployeeIndividualChoicesAboutTab from './AboutTab';

interface EmployeeIndividualChoicesOverviewDrawerProps {
  employeeIndividualChoices: EmployeeIndividualChoices;
  onSelectIndividualChoice: (individualChoice: EmployeeIndividualChoice) => void;
  onCreateIndividualChoice: () => void;
  employee: Employee;
  onClose: () => void;
}

function EmployeeIndividualChoicesOverviewDrawer({
  employeeIndividualChoices,
  onSelectIndividualChoice,
  onCreateIndividualChoice,
  employee,
  onClose,
}: EmployeeIndividualChoicesOverviewDrawerProps) {
  const { t } = useTranslation();

  const fullName = formatEmployeeFullName(employee);

  return (
    <DrawerBase title={t('employees:individual-choices.individual-choices')} subTitle={fullName} onClose={onClose}>
      <Tabs>
        <Tab title={t('common:about')}>
          <EmployeeIndividualChoicesAboutTab
            employee={employee}
            employeeIndividualChoices={employeeIndividualChoices}
            onSelectIndividualChoice={onSelectIndividualChoice}
            onCreateIndividualChoice={onCreateIndividualChoice}
          />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
}

export default EmployeeIndividualChoicesOverviewDrawer;
