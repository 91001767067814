import { ListItem, TableHead, TableRow, Th, TableBody } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { Employer } from 'src/types/employer/Employer';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import ControlPointListHeader from '../CheckpointListHeader';
import ControlPointTableRow from '../CheckpointTableRow';
import ControlPointListItemCheckbox from './CheckpointListItemCheckbox';
import style from './style.module.scss';

interface CheckpointListItemProps {
  eventReport: InsuranceEventReportEmployeeResponse;
  employers: Employer[];
  setEmployeeId: (employeeId: string) => void;
  insuranceProviders: InsuranceProvider[];
}

const CheckpointListItem: React.FC<CheckpointListItemProps> = ({
  employers,
  eventReport,
  setEmployeeId,
  insuranceProviders,
}) => {
  const { t } = useTranslation();
  const head = [
    { name: t('verify:table.insurance-event') },
    { name: t('verify:table.sub-action-category') },
    { name: t('verify:table.provider') },
  ];
  const handleOnClick = () => setEmployeeId(eventReport.employee.id);
  return (
    <ListItem compressed data-cy={`control-points-search-results-${eventReport.employee.employmentNumber}`}>
      <span className={style.container}>
        <span>
          <ControlPointListHeader
            personNumber={eventReport.employee.personNumber}
            fullName={eventReport.employee.firstName + ' ' + eventReport.employee.lastName}
            company={employers?.find((employer) => employer.id === eventReport.employee?.employerId)?.legalName}
            onClick={handleOnClick}
          />
          <TableBase compressed>
            <TableHead>
              <TableRow className={style.tableHeader}>
                {head.map((title, index) => (
                  <Th compressed key={`verify-${title.name}-${index}`}>
                    {title.name}
                  </Th>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <ControlPointTableRow eventReport={eventReport} providers={insuranceProviders} onClick={handleOnClick} />
            </TableBody>
          </TableBase>
        </span>
        <span>
          <ControlPointListItemCheckbox eventReport={eventReport} />
        </span>
      </span>
    </ListItem>
  );
};

export default CheckpointListItem;
