import React, { useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import PolicyBenefitOverview from './PolicyBenefitOverview';
import AgreedProductDetails from './AgreedProductDetails';
import BenefitAffiliationRuleDetailDrawer from './BenefitAffiliationRuleDetailDrawer';
import './policy-benefit-drawer.scss';
import BenefitConnectionRuleDetailDrawer from './BenefitConnectionRuleDetailDrawer';
import { PolicyBenefitConnectionRuleType } from 'src/types/policy/PolicyBenefitConnectionRule';
import EmploymentGroupConnectionDrawer from './EmploymentGroupConnectionDrawer';
import { BenefitCalculationType } from './PolicyBenefitOverview/PolicyBenefitCalculationDrawerTab/PolicyBenefitCalculationDrawerTab';
import BenefitCalculationPglDetailDrawer from './BenefitCalculationDetailDrawer';
import { PolicyBenefitDrawerTab } from 'src/types/tabs/PolicyBenefitDrawerTab';
import { useEmployerIndividualChoices } from 'src/service/employer/GetEmployerIndividualChoices';

interface PolicyBenefitDrawerProps {
  policyBenefit: PolicyBenefit;
  policyId: string;
  policyEntityState: PolicyState;
  open: boolean;
  onClose: () => void;
  employerId: string;
}

const PolicyBenefitDrawer: React.FunctionComponent<PolicyBenefitDrawerProps> = ({
  policyBenefit,
  policyId,
  policyEntityState,
  open,
  onClose,
  employerId,
}) => {
  const [selectedAgreedProductId, setSelectedAgreedProductId] = useState<string>(undefined);
  const [displayAffiliationRuleDetailDrawer, setDisplayAffiliationRuleDetailDrawer] =
    useState<AffiliationRuleGroupType>(undefined);
  const [selectedRuleBlockId, setSelectedRuleBlockId] = useState<string>();
  const [displayEmploymentGroupConnectionDrawer, setDisplayEmploymentGroupConnectionDrawer] = useState<boolean>(false);
  const [displayCalculationPglDetailDrawer, setDisplayCalculationPglDetailDrawer] =
    useState<BenefitCalculationType>(undefined);
  const [displayConnectionRuleDetailDrawer, setDisplayConnectionRuleDetailDrawer] =
    useState<PolicyBenefitConnectionRuleType>(undefined);
  const [displayAddAgreedProductDrawer, setDisplayAddAgreedProductDrawer] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<PolicyBenefitDrawerTab>(PolicyBenefitDrawerTab.AGREED_PRODUCTS);

  const employerIndividualChoicesService = useEmployerIndividualChoices(employerId);
  const employerIndividualChoices =
    employerIndividualChoicesService.status === 'loaded' && employerIndividualChoicesService.payload;

  const navigateToTab = (policyBenefitDrawerTab: PolicyBenefitDrawerTab) => {
    resetViews();
    setCurrentTab(policyBenefitDrawerTab);
  };

  const inDraft = policyEntityState === 'DRAFT';

  const handleDisplayAgreedProductDetailDrawer = (agreedProductId: string) => {
    setSelectedAgreedProductId(agreedProductId);
  };

  const handleDisplayAffiliationRuleDetailDrawer = (
    affiliationRuleGroupType: AffiliationRuleGroupType,
    ruleBlockId: string,
  ) => {
    setSelectedRuleBlockId(ruleBlockId);
    setDisplayAffiliationRuleDetailDrawer(affiliationRuleGroupType);
  };

  const handleDisplayConnectionRuleDetailDrawer = (connectionRuleType: PolicyBenefitConnectionRuleType) => {
    setDisplayConnectionRuleDetailDrawer(connectionRuleType);
  };

  const handleDisplayEmploymentGroupConnectionDrawer = () => {
    setDisplayEmploymentGroupConnectionDrawer(true);
  };

  const handleDisplayCalculationPglDetailDrawer = (calculationType?: BenefitCalculationType) => {
    setDisplayCalculationPglDetailDrawer(calculationType);
  };

  const handleDisplayAddAgreedProduct = () => {
    setDisplayAddAgreedProductDrawer(true);
  };

  const handleOnClose = () => {
    resetViews();
    onClose();
  };
  const resetViews = () => {
    setDisplayAffiliationRuleDetailDrawer(undefined);
    setSelectedAgreedProductId(undefined);
    setDisplayConnectionRuleDetailDrawer(undefined);
    setSelectedRuleBlockId(undefined);
    setDisplayEmploymentGroupConnectionDrawer(false);
    setDisplayCalculationPglDetailDrawer(undefined);
    setDisplayAddAgreedProductDrawer(false);
  };

  return (
    <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
      {!displayAffiliationRuleDetailDrawer &&
        !selectedAgreedProductId &&
        !displayConnectionRuleDetailDrawer &&
        !displayEmploymentGroupConnectionDrawer &&
        !displayCalculationPglDetailDrawer &&
        !displayAddAgreedProductDrawer && (
          <PolicyBenefitOverview
            employerId={employerId}
            policyEntityState={policyEntityState}
            policyBenefit={policyBenefit}
            onDisplayAgreedProductDetailDrawer={handleDisplayAgreedProductDetailDrawer}
            onDisplayAffiliationRuleDetailDrawer={handleDisplayAffiliationRuleDetailDrawer}
            onDisplayConnectionRuleDetailDrawer={handleDisplayConnectionRuleDetailDrawer}
            onDisplayEmploymentGroupConnectionDrawer={handleDisplayEmploymentGroupConnectionDrawer}
            onDisplayCalculationPglDetailDrawer={handleDisplayCalculationPglDetailDrawer}
            onDisplayAddAgreedProduct={handleDisplayAddAgreedProduct}
            onClose={onClose}
            inDraft={inDraft}
            currentTab={currentTab}
            onTabChange={setCurrentTab}
            employerIndividualChoices={employerIndividualChoices}
          />
        )}
      {(displayAddAgreedProductDrawer || selectedAgreedProductId) && (
        <AgreedProductDetails
          policyBenefit={policyBenefit}
          onClose={handleOnClose}
          navigateToTab={navigateToTab}
          policyState={policyEntityState}
          policyAgreedProduct={policyBenefit.agreedProducts.find((product) => product.id === selectedAgreedProductId)}
          employerId={employerId}
        />
      )}
      {displayAffiliationRuleDetailDrawer && (
        <BenefitAffiliationRuleDetailDrawer
          policyId={policyId}
          policyEntityState={policyEntityState}
          selectedRuleGroupType={displayAffiliationRuleDetailDrawer}
          policyBenefit={policyBenefit}
          onClose={handleOnClose}
          navigateToTab={navigateToTab}
          ruleBlockId={selectedRuleBlockId}
          employerIndividualChoices={employerIndividualChoices}
        />
      )}
      {displayConnectionRuleDetailDrawer && (
        <BenefitConnectionRuleDetailDrawer
          policyId={policyId}
          policyEntityState={policyEntityState}
          selectedConnectionRuleType={displayConnectionRuleDetailDrawer}
          policyBenefit={policyBenefit}
          onClose={handleOnClose}
          navigateToTab={navigateToTab}
          employerId={employerId}
        />
      )}
      {displayEmploymentGroupConnectionDrawer && (
        <EmploymentGroupConnectionDrawer
          navigateToTab={navigateToTab}
          policyEntityState={policyEntityState}
          onClose={handleOnClose}
          policyId={policyId}
          policyBenefit={policyBenefit}
        />
      )}
      {displayCalculationPglDetailDrawer && (
        <BenefitCalculationPglDetailDrawer
          policyId={policyId}
          policyBenefit={policyBenefit}
          policyEntityState={policyEntityState}
          calculationType={displayCalculationPglDetailDrawer}
          onClose={onClose}
          navigateToTab={navigateToTab}
          employerId={employerId}
        />
      )}
    </DrawerModal>
  );
};

export default PolicyBenefitDrawer;
