import { Button, InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { ExtentCalculation, extentCalculations } from 'src/types/employer/request/EmployerWageTypeRequest';
import { UpdateEmployerWageTypeRequest } from 'src/types/employer/request/UpdateEmployerWageTypeRequest';
import { canChangeNegateIncomingAmount, isAbsenceWageType } from 'src/util/WageUtil';
import './wage-type-overview-tab.scss';
import { useUpdateEmployerWageType } from 'src/service/employer/UpdateEmployerWageType';
import When from 'src/components/Common/When';

interface WageTypeOverviewTabProps {
  employerWageType: EmployerWageType;
  employerId: string;
}

function WageTypeOverviewTab({ employerWageType, employerId }: WageTypeOverviewTabProps) {
  const { t } = useTranslation();
  const updateEmployerWageType = useUpdateEmployerWageType();

  const [request, setRequest] = useState<UpdateEmployerWageTypeRequest>({
    name: employerWageType.name,
    extentCalculation: employerWageType.extentCalculation,
    negateIncomingAmount: canChangeNegateIncomingAmount(employerWageType.wageType)
      ? employerWageType.negateIncomingAmount
      : undefined,
  });

  const extentCalculationOptions = extentCalculations.map((ec) => ({
    label: t(`extentCalculation:${ec}`),
    value: ec,
  }));

  const negateIncomingAmountOptions = [
    {
      label: t(`common:yes`),
      value: true,
    },
    {
      label: t(`common:no`),
      value: false,
    },
  ];

  const handleOptionChange = <K extends keyof UpdateEmployerWageTypeRequest>(
    key: K,
    option: Option<UpdateEmployerWageTypeRequest[K]>,
  ) => {
    setRequest({ ...request, [key]: option.value });
  };

  const handleUpdateWageType = () => {
    updateEmployerWageType(employerWageType.id, employerId, request);
  };

  return (
    <div className="wage-type-overview-tab">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('account:employers-tab.wage-type-mapping-settings.wage-type-name')} bold>
          <TextInput
            value={request.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
            validationKey="text64"
          />
        </InlineEdit>
        <InlineEdit label={t('account:employers-tab.wage-type-mapping-settings.global-wage-type')} bold>
          <Select
            small
            selected={{ label: t(`wageTypes:${employerWageType.wageType}`), value: employerWageType.wageType }}
            onChange={null}
            options={[]}
            disabled
          />
        </InlineEdit>
        {isAbsenceWageType(employerWageType.wageType) && (
          <InlineEdit label={t('account:employers-tab.wage-type-mapping-settings.extent-calculation')} bold>
            <Select
              small
              selected={extentCalculationOptions.find((ec) => ec.value === request.extentCalculation)}
              onChange={(option: Option<ExtentCalculation>) => handleOptionChange('extentCalculation', option)}
              options={extentCalculationOptions}
            />
          </InlineEdit>
        )}
        <When condition={canChangeNegateIncomingAmount(employerWageType.wageType)}>
          <InlineEdit label={t('account:employers-tab.wage-type-mapping-settings.negate-incoming-amount')} bold>
            <Select
              small
              selected={negateIncomingAmountOptions.find((ec) => ec.value === request.negateIncomingAmount)}
              onChange={(option: Option<boolean>) => handleOptionChange('negateIncomingAmount', option)}
              options={negateIncomingAmountOptions}
            />
          </InlineEdit>
        </When>
      </ListGroup>
      <Button
        onClick={handleUpdateWageType}
        label={t('common:save-changes')}
        disabled={
          request.extentCalculation === employerWageType.extentCalculation &&
          request.name === employerWageType.name &&
          request.negateIncomingAmount == employerWageType.negateIncomingAmount
        }
      ></Button>
    </div>
  );
}

export default WageTypeOverviewTab;
