import { gql } from '@apollo/client';

export const EMPLOYEE_CARD = gql`
  subscription EmployeeCardUpdated($employeeCardIds: [UUID]!) {
    employeeCardUpdated(employeeCardIds: $employeeCardIds) {
      id
      employeeId
      employmentGroupId
      employmentCategory
      remunerationType
      employmentType
    }
  }
`;
