import React, { ChangeEvent } from 'react';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { AffiliationRule, PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { RuleValue } from 'src/types/policy/RuleValue';
import { Collapsible } from 'fgirot-k2-ui-components';
import { InlineEdit, ListGroup, Switch } from 'fgirot-k2-ui-components';
import UpdateInput from 'src/components/Common/UpdateInput';
import { useTranslation } from 'react-i18next';
import '../../../PolicyEmploymentGroupModal/RuleTypeDrawer/rule-type-drawer.scss';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';

interface EmploymentTypeRuleValueProps {
  policyState: PolicyState;
  affiliationRule: AffiliationRule;
  defaultRuleValue: RuleValue;
  onUpdateOrDeleteAffiliationRule: (request: UpdateOrDeleteAffiliationRuleRequest) => void;
}

const EmploymentTypeRuleValue: React.FunctionComponent<EmploymentTypeRuleValueProps> = ({
  policyState,
  affiliationRule,
  defaultRuleValue,
  onUpdateOrDeleteAffiliationRule,
}) => {
  const { t } = useTranslation();
  const getCurrentRuleValues = () => (affiliationRule ? affiliationRule.ruleValues : []);

  const handleOnChange = (condition: string) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (policyState === 'DRAFT') {
        const ruleValues = event.target.checked
          ? [...getCurrentRuleValues(), defaultRuleValue]
          : getCurrentRuleValues().filter((ruleValue) => ruleValue.condition !== condition);

        onUpdateOrDeleteAffiliationRule({
          ruleId: affiliationRule?.id,
          policyRuleType: PolicyRuleType.EMPLOYMENT_TYPE,
          ruleValues,
        });
      }
    };
  };

  const handleOnBlur = (condition: string, value: string) => {
    if (policyState === 'DRAFT') {
      const ruleValues = getCurrentRuleValues().map((ruleValue) =>
        ruleValue.condition === condition ? { ...ruleValue, value } : ruleValue,
      );
      onUpdateOrDeleteAffiliationRule({
        ruleId: affiliationRule?.id,
        policyRuleType: PolicyRuleType.EMPLOYMENT_TYPE,
        ruleValues,
      });
    }
  };

  const ruleValue = getCurrentRuleValues().find((ruleValue) => ruleValue.condition === defaultRuleValue.condition);

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant={'inline-edit'}>
        <InlineEdit
          label={t(`policyRules:ruleTypeGroup.RULE_GROUP_EMPLOYMENT_TYPE.values.${defaultRuleValue.condition}`)}
          bold
        >
          <Switch
            toggled={getCurrentRuleValues().some((value) => value.condition === defaultRuleValue.condition)}
            onChange={handleOnChange(defaultRuleValue.condition)}
            data-cy={`employment-type-rule-value__switch-${defaultRuleValue.condition}`}
          />
        </InlineEdit>
      </ListGroup>
      <Collapsible
        className="rule-type-drawer__list-group-collapsible"
        open={getCurrentRuleValues().some((value) => value.condition === defaultRuleValue.condition)}
      >
        <ListGroup variant={'inline-edit'}>
          <InlineEdit bold label={t(`policyRules:ruleValues.number-of-days`)}>
            <UpdateInput
              placeholder={t(`policyRules:ruleValues.valueUnit.DAYS`)}
              disabled={policyState !== 'DRAFT'}
              fieldKey={defaultRuleValue.condition}
              fieldValue={ruleValue?.value || defaultRuleValue.value}
              type="number"
              validationKey="days"
              onBlur={handleOnBlur}
              data-cy={`employment-type-rule-value__input-${defaultRuleValue?.condition}`}
            />
          </InlineEdit>
        </ListGroup>
      </Collapsible>
    </div>
  );
};

export default EmploymentTypeRuleValue;
