import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_DOCUMENT_REFERENCES } from 'src/graphql/schema/query/documents/GetDocumentReferences';
import DocumentArchiveTable from './DocumentArchiveTable/DocumentArchiveTable';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { DocumentReference } from 'src/types/documents/DocumentReference';

import FilterComponent from 'src/components/Common/FilterComponent';

const DocumentArchiveTab = () => {
  const { t } = useTranslation();
  const [searchFilter, setSearchFilter] = useState('');
  const handleSearchFilterChange = (e: ChangeEvent<HTMLInputElement>) => setSearchFilter(e.target.value);

  return (
    <>
      <QueryWrapper query={GET_DOCUMENT_REFERENCES} type="list" useErrorToast={false}>
        {(result: DocumentReference[]) => {
          return (
            <div>
              <FilterComponent
                searchPlaceholder={t('documents:document-archive-tab.filter.search-placeholder')}
                onSearchFilterChange={handleSearchFilterChange}
                showSearch={true}
                searchFilter={searchFilter}
              />
              <DocumentArchiveTable documentArchiveTableItems={result} documentFilterText={searchFilter} />
            </div>
          );
        }}
      </QueryWrapper>
    </>
  );
};

export default DocumentArchiveTab;
