import { TableRow, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Employer } from 'src/types/employer/Employer';
import './employers-table-row.scss';

interface EmployersTableRowProps {
  employer: Employer;
  customerName: string;
}

const EmployersTableRow = ({ employer, customerName }: EmployersTableRowProps) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/account/${customerName}/${employer.id}`);
  };

  return (
    <TableRow data-cy={`employer-table-row-${employer.legalName}`} onClick={handleNavigate}>
      <Td>{employer.legalName}</Td>
      <Td>{employer.organisationNumber}</Td>
    </TableRow>
  );
};

export default EmployersTableRow;
