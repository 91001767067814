import React from 'react';
import { Button, Header, IconPill } from 'fgirot-k2-ui-components';
import './page-header.scss';
import { useKeycloak } from 'fgirot-k2-react-keycloak-provider';
import { useTranslation } from 'react-i18next';
import { useCustomer } from 'src/service/customer/GetCustomer';
import SelectCustomerModal from 'src/components/Common/SelectCustomerModal';
import useModal from 'src/util/Hooks/ModalHook';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/user/GetCustomers';
import { GetCustomerPage } from 'src/types/customer/Customer';
import { client } from 'src/graphql';

const PageHeader = () => {
  const { keycloak, initialized } = useKeycloak();
  const { t } = useTranslation();
  const { modalOpen, setModalOpen, closeModal } = useModal();
  const customer = useCustomer();
  const logOut = () => {
    sessionStorage.clear();
    initialized && keycloak.logout();
    client.resetStore();
  };

  return (
    <Header className="page-header">
      <IconPill icon="Building" label={customer?.name} />
      <QueryWrapper query={GET_ALL_CUSTOMERS}>
        {({ customers }: GetCustomerPage) => (
          <>{customers?.length > 1 && <Button onClick={setModalOpen} label={t('selectCustomer:header-button')} />}</>
        )}
      </QueryWrapper>
      <Button onClick={logOut} label="Logga ut" type="secondary" />
      <SelectCustomerModal open={modalOpen} onClose={closeModal} />
    </Header>
  );
};
export default PageHeader;
