import { Typography } from 'fgirot-k2-ui-components';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_EVENTS_COUNT } from 'src/graphql/schema/query/insurance/GetInsuranceEventsCount';
import { InsuranceEventsCount } from 'src/types/reporting/InsuranceEventsCount';
import React from 'react';
import { InsuranceEventsPage } from 'src/types/reporting/InsuranceEventsPage';
import { useTranslation } from 'react-i18next';

interface InsuranceEventsResultSummaryProps {
  reportingPeriod: string;
  insuranceEventsPage: InsuranceEventsPage;
}
const InsuranceEventsResultSummary: React.FC<InsuranceEventsResultSummaryProps> = ({
  reportingPeriod,
  insuranceEventsPage,
}) => {
  const { t } = useTranslation();

  const numberOfInsuranceEvents =
    insuranceEventsPage?.persons?.reduce((acc, curr) => acc + curr.insuranceEvents.length, 0) || 0;

  return (
    <Typography variant="caption">
      <>
        {`${t('reporting:insurance-events.shows')} `}
        <Typography className="insurance-event-table__page" component="span" variant="caption" bold>
          {numberOfInsuranceEvents.toString()}
        </Typography>
        {` ${t('reporting:insurance-events.of')} `}
        <QueryWrapper query={GET_INSURANCE_EVENTS_COUNT} options={{ variables: { wageFilePeriod: reportingPeriod } }}>
          {(inData: InsuranceEventsCount) => (
            <Typography className="insurance-event-table__page" component="span" variant="caption" bold>
              {inData.totalNumberOfInsuranceEvents.toString() + ''}
            </Typography>
          )}
        </QueryWrapper>
        {` ${t('reporting:insurance-events.events-total-in-period')}`}
      </>
    </Typography>
  );
};
export default InsuranceEventsResultSummary;
