import { gql } from '@apollo/client';

export const DELETE_CONTRACT_AGREED_PRODUCT = gql`
  mutation DeleteContractAgreedProduct($insuranceProviderContractId: UUID!, $agreedProductId: UUID!) {
    deleteContractAgreedProduct(
      insuranceProviderContractId: $insuranceProviderContractId
      agreedProductId: $agreedProductId
    ) {
      id
    }
  }
`;
