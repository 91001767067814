import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { DocumentArchiveTableSortField } from './DocumentArchiveTableSortField';

interface DocumentArchiveTableHeadsProps {
  sortDirection: SortDirection;
  sortField: DocumentArchiveTableSortField;
  onSortDirectionChange: () => void;
  onSortFieldChange: (sortField: string) => void;
}

const DocumentArchiveTableHeads: FC<DocumentArchiveTableHeadsProps> = ({
  sortDirection,
  sortField,
  onSortDirectionChange,
  onSortFieldChange,
}) => {
  const { t } = useTranslation();

  const tableHeads: TableHeadProps[] = [
    {
      fieldName: 'fileName',
      title: t('documents:document-archive-tab.table.name'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('fileName');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'contentType',
      title: t('documents:document-archive-tab.table.content-type'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('contentType');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'fromPeriod',
      title: t('documents:document-archive-tab.table.period'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('fromPeriod');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'employerId',
      title: t('documents:document-archive-tab.table.employer'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('employerId');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'createdDate',
      title: t('documents:document-archive-tab.table.created-date'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('createdDate');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'latestDownloadDate',
      title: t('documents:document-archive-tab.table.latest-download-date'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('latestDownloadDate');
        onSortDirectionChange();
      },
    },
    {
      title: t('documents:document-archive-tab.table.download-button'),
      fieldName: 'downloadButton',
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head) => (
        <Th
          includeSortButtons={head.fieldName !== 'downloadButton'}
          sortDirection={sortField === head.fieldName ? sortDirection : undefined}
          handleSortDirectionChange={head.handleSortDirectionChange}
          key={`document-table-${head.title}`}
          data-cy="document-table-head"
        >
          {head.title}
        </Th>
      ))}
    </TableRow>
  );
};

export default DocumentArchiveTableHeads;
