import { Pill, TableRow, Td, Typography } from 'fgirot-k2-ui-components';
import './insurance-event-details-row.scss';
import { useTranslation } from 'react-i18next';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { stringOrBlank } from 'src/util/StringUtil';
import React from 'react';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_POLICY_EMPLOYMENT_GROUP } from 'src/graphql/schema/query/policy/GetPolicyEmploymentGroup';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { getCorrectEventValue } from 'src/util/InsuranceEventUtil';

interface InsuranceEventDetailsRowProps {
  insuranceEvent: InsuranceEvent;
  employmentNumber: string;
  onSelectEvent: (event: InsuranceEvent) => void;
}

const InsuranceEventDetailsRow = ({
  insuranceEvent,
  employmentNumber,
  onSelectEvent,
}: InsuranceEventDetailsRowProps) => {
  const { t } = useTranslation();
  const { evaluationPolicyEntityId, employmentGroupId } = insuranceEvent.employeeCard;
  const hasBeenAssignedEmploymentGroup = evaluationPolicyEntityId && employmentGroupId;

  return (
    <TableRow
      onClick={() => onSelectEvent(insuranceEvent)}
      data-testid={`insurance-event-details__row-${employmentNumber}`}
    >
      <Td compressed data-testid="insurance-event-details__event">
        <div className="insurance-event-details-row__status">
          <Typography variant="caption">{t(`reportingTypes:${insuranceEvent.reportingType}`)}</Typography>
          <Pill size="small" type="heads-up" label={t(`insuranceEventTypes:${insuranceEvent.insuranceEventType}`)} />
        </div>
      </Td>
      <Td compressed data-testid="insurance-event-details__employment-number">
        {employmentNumber}
      </Td>
      <Td compressed>
        {hasBeenAssignedEmploymentGroup ? (
          <QueryWrapper
            query={GET_POLICY_EMPLOYMENT_GROUP}
            options={{
              variables: {
                policyId: evaluationPolicyEntityId,
                employmentGroupId,
              },
            }}
          >
            {(data: PolicyEmploymentGroup) => <>{stringOrBlank(data?.employmentGroupName)}</>}
          </QueryWrapper>
        ) : (
          '-'
        )}
      </Td>
      <Td compressed data-testid="insurance-event-details__event-from-date">
        {insuranceEvent.eventFromDate}
      </Td>
      <Td compressed data-testid="insurance-event-details__benefit-type">
        <Pill type="neutral" size="small" label={t(`benefitTypes:abbreviations.${insuranceEvent.benefitType}`)} />
      </Td>
      <Td compressed data-testid="insurance-event-details__recipient">
        {stringOrBlank(insuranceEvent.insuranceProvider?.legalName)}
      </Td>
      <Td compressed data-testid="insurance-event-details__amount">
        {insuranceEvent.eventValues.length > 0
          ? insuranceEvent.eventValues.map((eventValue) => (
              <div className="insurance-event-details-row__calculations" key={eventValue.eventValueType}>
                <div className="insurance-event-details-row__calculations--details">
                  <Pill type="neutral" size="small" label={eventValue.eventValueType} />
                  <Typography variant="caption">
                    {formatSweAmountTextWithSuffixNoDecimal(getCorrectEventValue(eventValue))}
                  </Typography>
                </div>
              </div>
            ))
          : '-'}
      </Td>
      <Td compressed data-testid="insurance-event-details__status">
        {t(`insuranceEventStatus:${insuranceEvent.status}`)}
      </Td>
    </TableRow>
  );
};

export default InsuranceEventDetailsRow;
