import { EmployerWageTypeRequest } from 'src/types/employer/request/EmployerWageTypeRequest';
import { useApolloMutation } from '../graphql/Mutation';
import { CREATE_EMPLOYER_WAGE_TYPE } from 'src/graphql/schema/mutation/employer/CreateEmployerWageType';
import { GET_EMPLOYER_WAGE_TYPES } from 'src/graphql/schema/query/employer/GetEmployerWageTypes';

export const useCreateEmployerWageType = () => {
  const mutation = useApolloMutation();

  const createEmployerWageType = (employerId: string, request: EmployerWageTypeRequest) =>
    mutation(CREATE_EMPLOYER_WAGE_TYPE, employerId, { request }, [GET_EMPLOYER_WAGE_TYPES]);

  return createEmployerWageType;
};
