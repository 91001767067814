import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import ExaminationTableRow from './ExaminationTableRow';
import ExaminationEmployeeDrawer from './ExaminationEmployeeDrawer';
import { EmploymentEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import TableBase from 'src/components/Common/TableBase';
import { Employer } from 'src/types/employer/Employer';

interface ExaminationTableProps {
  employeeResponses: EmploymentEventReportEmployeeResponse[];
  employers: Employer[];
  wageFilePeriod: string;
}

const ExaminationTable = ({ employeeResponses, employers, wageFilePeriod }: ExaminationTableProps) => {
  const { t } = useTranslation();
  const [selectedReportEmployeeId, setSelectedReportEmployeeId] = useState<string>(undefined);

  const head = [
    { name: t('examination:table.name') },
    { name: t('examination:table.person-number') },
    { name: t('examination:table.employer') },
    { name: t('examination:table.period-difference') },
    { name: t('examination:table.examination-rule') },
    { name: t('examination:table.done') },
    { name: '' },
  ];

  return (
    <>
      <TableBase maxHeightPercentage={45}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th key={`Examination-${title.name}-${index}`}>{title.name}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeResponses.map((reportEmployee, index) => {
            return (
              <ExaminationTableRow
                key={`ExaminationTableRow-${reportEmployee.employee.id}-${index}`}
                employee={reportEmployee.employee}
                checkpoints={reportEmployee.checkpoints}
                employers={employers}
                onClick={() => setSelectedReportEmployeeId(reportEmployee.employee.id)}
              />
            );
          })}
        </TableBody>
      </TableBase>
      {selectedReportEmployeeId !== undefined && (
        <ExaminationEmployeeDrawer
          open={true}
          employeeResponse={employeeResponses.find(
            (employeeResponse) => employeeResponse.employee.id === selectedReportEmployeeId,
          )}
          onClose={() => setSelectedReportEmployeeId(undefined)}
          wageFilePeriod={wageFilePeriod}
        />
      )}
    </>
  );
};

export default ExaminationTable;
