import { Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { Employee } from 'src/types/Employee';
import './employment-details-overview-tab.scss';
import EmploymentDetails from './EmploymentDetails/EmploymentDetails';
import EditableEmploymentDetails from './EditableEmploymentDetails';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYEE_HISTORY } from 'src/graphql/schema/query/employee/GetEmployeeHistory';
import { EmployeeHistory } from 'src/types/employees/EmployeeHistory';

interface EmploymentDetailsOverviewTabProps {
  employeeCard: AggregatedEmployeeCard;
  employee: Employee;
  activeEmployeeCard?: AggregatedEmployeeCard;
  activeEmployee?: Employee;
}

const EmploymentDetailsOverviewTab = ({
  employeeCard,
  employee,
  activeEmployee,
  activeEmployeeCard,
}: EmploymentDetailsOverviewTabProps) => {
  const { t } = useTranslation();
  const isDraft = employee.isDraft;

  return (
    <div className="employment-details-overview-tab">
      <Typography variant="body1" component="h3" bold>
        {t('common:employment')}
      </Typography>
      {isDraft ? (
        <EditableEmploymentDetails
          employeeDraft={employee}
          employeeCardDraft={employeeCard}
          activeEmployee={activeEmployee}
          activeEmployeeCard={activeEmployeeCard}
        />
      ) : (
        <QueryWrapper query={GET_EMPLOYEE_HISTORY} options={{ variables: { employeeId: employee.id } }}>
          {(employeeHistory: EmployeeHistory[]) => (
            <EmploymentDetails employee={employee} employeeCard={employeeCard} employeeHistory={employeeHistory} />
          )}
        </QueryWrapper>
      )}
    </div>
  );
};

export default EmploymentDetailsOverviewTab;
