import React, { FC } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYEE_HISTORY } from 'src/graphql/schema/query/employee/GetEmployeeHistory';
import { Employee } from 'src/types/Employee';
import { EmployeeHistory } from 'src/types/employees/EmployeeHistory';
import EmployeeHistoryTable from './EmployeeHistoryTable/EmployeeHistoryTable';
import './employment-history-overview.scss';

interface EmploymentHistoryOverviewProps {
  employee: Employee;
}

const EmploymentHistoryOverview: FC<EmploymentHistoryOverviewProps> = ({ employee }) => {
  return (
    <div className="employment-history-overview">
      <QueryWrapper query={GET_EMPLOYEE_HISTORY} options={{ variables: { employeeId: employee.id } }}>
        {(employeeHistory: EmployeeHistory[]) => <EmployeeHistoryTable employeeHistory={employeeHistory} />}
      </QueryWrapper>
    </div>
  );
};

export default EmploymentHistoryOverview;
