import { InlineEdit } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import EmployeeRowField from './EmployeeRowField';

interface EmployeeTableRowProps {
  fieldKey: string;
  fieldValue: string;
  accountingUnits?: AccountingUnit[];
  onBlur: (field: string, value: string) => void;
}

const EmployeeTableRow = ({ fieldKey, fieldValue, accountingUnits, onBlur }: EmployeeTableRowProps) => {
  const { t } = useTranslation();

  return (
    <InlineEdit label={`${t(`violationTypes:${fieldKey}`)} *`} bold>
      <EmployeeRowField fieldKey={fieldKey} onBlur={onBlur} accountingUnits={accountingUnits} fieldValue={fieldValue} />
    </InlineEdit>
  );
};

export default EmployeeTableRow;
