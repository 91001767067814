import { InlineEdit, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PRODUCTS } from 'src/graphql/schema/query/insurance/GetInsuranceProducts';
import { InsuranceProduct, InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import { createOptionsFromList } from 'src/util/OptionUtil';

interface InsuranceProductInputProps {
  contract: InsuranceProviderContract;
  agreedProductRequest: ContractAgreedProductRequest;
  onChange: (key: string, value: string) => void;
}

const InsuranceProductInput = ({ contract, agreedProductRequest, onChange }: InsuranceProductInputProps) => {
  const { t } = useTranslation();

  const onInsuranceProductChange = (insuranceProductId: string) => {
    onChange('insuranceProductId', insuranceProductId);
  };

  return (
    <InlineEdit label={t('agreementRegistrySettings:insurance-product')} mandatory bold>
      {contract.insuranceProvider !== null ? (
        <QueryWrapper
          type="list"
          query={GET_INSURANCE_PRODUCTS}
          options={{
            variables: { exployerId: contract.employerId, insuranceProviderId: contract?.insuranceProvider?.id },
            skip: !contract.insuranceProvider?.id,
          }}
        >
          {({ insuranceProducts }: { insuranceProducts: InsuranceProduct[] }) => {
            const insuranceProductOptions = [
              {
                value: null,
                label: t('common:select'),
              },
              ...createOptionsFromList(insuranceProducts, 'id', 'name'),
            ];

            return (
              <Select
                small
                placeholder={t('common:select')}
                options={insuranceProductOptions}
                selected={insuranceProductOptions.find(
                  (option) => option.value === agreedProductRequest.insuranceProductId,
                )}
                onChange={(option) => onInsuranceProductChange(option.value)}
                data-cy="insurance-product-dropdown"
              />
            );
          }}
        </QueryWrapper>
      ) : (
        <Select
          small
          placeholder={t('agreementRegistrySettings:drawer.input-not-available')}
          selected={null}
          options={[]}
          onChange={null}
          disabled
        />
      )}
    </InlineEdit>
  );
};

export default InsuranceProductInput;
