import { Td, Button, Icon } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_MISSING_EMPLOYEE_CHECKPOINT } from 'src/graphql/schema/query/checkpoint/GetMissingEmployeeCheckpoint';
import { Checkpoint } from 'src/types/Checkpoint';
import EmployeeMissingModal from '../../InvoiceDrawer/EmployeeMissingModal';
import { Invoice } from 'src/types/invoice/Invoice';

interface CheckpointPendingTdProps {
  invoice: Invoice;
}
const CheckpointPendingTd: FC<CheckpointPendingTdProps> = ({ invoice }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((prev) => !prev);
  return (
    <Td notClickable>
      <Button onClick={toggleOpen} label={t(`invoice:status.${invoice.invoiceStatus}`)} icon={<Icon type="Alert" />} />
      <QueryWrapper
        query={GET_MISSING_EMPLOYEE_CHECKPOINT}
        options={{ variables: { employerIds: [invoice.employer.id], errandId: invoice.errandId } }}
      >
        {(checkpoints: Checkpoint[]) => (
          <EmployeeMissingModal
            open={open}
            onClose={toggleOpen}
            employerId={invoice.employer.id}
            checkpoints={checkpoints}
          />
        )}
      </QueryWrapper>
    </Td>
  );
};

export default CheckpointPendingTd;
