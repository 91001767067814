import { TableRow, Td } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTemplate } from 'src/types/documents/DocumentTemplate';
import { stringOrBlank } from 'src/util/StringUtil';

interface DocumentTemplateTableRowProps {
  documentTemplate: DocumentTemplate;
  onClick: (id: DocumentTemplate) => void;
}

const DocumentTemplateTableRow: FC<DocumentTemplateTableRowProps> = ({ documentTemplate, onClick }) => {
  const { t } = useTranslation();

  return (
    <TableRow
      onClick={() => onClick(documentTemplate)}
      data-testid={`document-template-table-row__${documentTemplate.id}`}
    >
      <Td>{stringOrBlank(documentTemplate.name)}</Td>
      <Td>{stringOrBlank(t(`documents:format-types.${documentTemplate.formatType}`))}</Td>
      <Td>{stringOrBlank(documentTemplate.description)}</Td>
    </TableRow>
  );
};

export default DocumentTemplateTableRow;
