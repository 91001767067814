import { TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './absence-table-head.scss';

function AbsenceTableHead() {
  const { t } = useTranslation();

  const tableHeads = [
    t('employees:absence-tab.pay-type'),
    t('employees:absence-tab.employee-card-period'),
    t('employees:absence-tab.from-date'),
    t('employees:absence-tab.to-date'),
  ];

  return (
    <TableHead className="absence-table-head">
      <TableRow>
        <>
          {tableHeads.map((head) => (
            <Th key={head}>{head}</Th>
          ))}
          <Th className="absence-table-head__absence-days">
            {t('employees:absence-tab.extent-adjusted-absence-days')}
          </Th>
        </>
      </TableRow>
    </TableHead>
  );
}

export default AbsenceTableHead;
