import { Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import TextInput from 'src/components/Common/TextInput';
import { ACCOUNTING_COMPONENT_VALUES } from 'src/graphql/schema/query/accountingunit/AccountingComponents';
import {
  AccountingComponentFlat,
  AccountingComponentValue,
  CreateAccountingComponentInput,
  ParentType,
} from 'src/types/accountingunit/AccountingComponent';

import { toNumber, isBlank } from 'src/util/StringUtil';
import { generateUUID } from 'src/util/UUIDUtil';
interface AddAccountingComponentModalProps {
  createAccountingComponent: (accountingComponent: AccountingComponentFlat) => void;
  parentId: string;
  parentType: ParentType;
  open: boolean;
  onClose: () => void;
}
const AddAccountingComponentModal: FC<AddAccountingComponentModalProps> = ({
  createAccountingComponent,
  parentId,
  parentType,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [createAccountingComponentInput, setCreateAccountingComponentInput] = useState<CreateAccountingComponentInput>({
    parentId,
    parentType,
    accountingComponentValueId: '',
    share: 0,
  });
  const handleAccountingComponentValueIdChange = (option: Option<AccountingComponentValue>) =>
    setCreateAccountingComponentInput((prev) => ({ ...prev, accountingComponentValueId: option.value.id }));

  const handleShareChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCreateAccountingComponentInput((prev) => ({ ...prev, share: toNumber(e.target.value) }));
  };

  const handleModalClose = () => {
    setCreateAccountingComponentInput({
      parentId,
      parentType,
      accountingComponentValueId: '',
      share: 0,
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <QueryWrapper query={ACCOUNTING_COMPONENT_VALUES} options={{ variables: { employerId } }}>
        {(accountingComponentValues: AccountingComponentValue[]) => {
          const handleAddAccountingComponent = () => {
            const value = accountingComponentValues.find(
              (value) => value.id === createAccountingComponentInput.accountingComponentValueId,
            );
            const newComponent: AccountingComponentFlat = {
              id: generateUUID(),
              name: value?.name,
              accountingComponentValueId: createAccountingComponentInput.accountingComponentValueId,
              type: value?.accountingComponentType.name,
              share: createAccountingComponentInput.share,
              parentId: createAccountingComponentInput.parentId,
              accountingComponents: [],
            };
            createAccountingComponent(newComponent);
            handleModalClose();
          };
          const options = accountingComponentValues.map((component) => ({
            label: `${component.accountingComponentType.name} - ${component.name}`,
            value: component,
          }));
          return (
            <Card title={t('economySettings:add-accounting-component-modal.title')} onClose={handleModalClose}>
              <Select
                label={t('economySettings:add-accounting-component-modal.accounting-component-label')}
                placeholder={t('economySettings:add-accounting-component-modal.accounting-component-placeholder')}
                options={options}
                selected={options.find(
                  (option) => option.value.id === createAccountingComponentInput.accountingComponentValueId,
                )}
                onChange={handleAccountingComponentValueIdChange}
                small
                mandatory
                outlined
              />

              <TextInput
                data-cy="accounting-component-share-input"
                label={t('economySettings:share-label')}
                placeholder={t('economySettings:share-placeholder')}
                variant="default"
                type="number"
                validationKey="percent"
                value={`${createAccountingComponentInput.share || ''}`}
                onChange={handleShareChange}
              />
              <CardAction>
                <Button type="link" onClick={handleModalClose} label={t('common:cancel')} />
                <Button
                  data-cy="add-accounting-component-button"
                  disabled={isBlank(createAccountingComponentInput.accountingComponentValueId)}
                  onClick={handleAddAccountingComponent}
                  label={t('common:add')}
                />
              </CardAction>
            </Card>
          );
        }}
      </QueryWrapper>
    </Modal>
  );
};

export default AddAccountingComponentModal;
