import React, { ChangeEvent } from 'react';
import { RuleGroupProps } from '../../RuleGroupProps';
import { PolicyRuleType, RuleValueUnit } from 'src/types/policy/AffiliationRule';
import { RuleValue, RuleValueType } from 'src/types/policy/RuleValue';
import './individual-choice-retirement-age.rule.scss';
import { InlineEdit, ListGroup, Option, Select, Switch } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import InfoMessage from 'src/components/Common/InfoMessage';

function IndividualChoiceRetirementAgeRule({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
}: RuleGroupProps) {
  const { t } = useTranslation();

  const policyRuleType = PolicyRuleType.AGE_LIMIT;

  const selectedPolicyRule = selectedAffiliationRuleGroup.affiliationRules.find(
    (rule) => rule.policyRuleType === policyRuleType,
  );

  const getCurrentRuleValues = (): RuleValue[] =>
    selectedAffiliationRuleGroup.affiliationRules.flatMap((rule) => rule.ruleValues);

  const defaultValue: RuleValue = {
    valueType: 'LESS_THAN',
    condition: 'INDIVIDUAL_RETIREMENT_AGE',
    value: null,
  };

  const validValueTypes: RuleValueType[] = ['GREATER_OR_EQUALS_THAN', 'LESS_THAN'];

  const valueTypeOptions: Option<RuleValueType>[] = validValueTypes.map((vt) => {
    return {
      label: t(`policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.valueType.${vt}`),
      value: vt,
    };
  });

  const selectedRuleValue = getCurrentRuleValues().find((rule) => rule.condition === 'INDIVIDUAL_RETIREMENT_AGE');

  const getCurrentSelected = (): Option<RuleValueType> => {
    return valueTypeOptions.find((option) => option.value === selectedRuleValue?.valueType);
  };

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (policyState === 'DRAFT') {
      const newRuleValues = event.target.checked ? [{ ...defaultValue }] : [];
      onUpdateOrDeleteAffiliationRule({
        ruleId: selectedPolicyRule?.id,
        ruleValueUnit: RuleValueUnit.AGE,
        policyRuleType,
        ruleValues: [
          ...getCurrentRuleValues().filter((rule) => rule.condition !== 'INDIVIDUAL_RETIREMENT_AGE'),
          ...newRuleValues,
        ],
      });
    }
  };

  const handleSelectValueType = (option: Option<RuleValueType>) => {
    if (policyState === 'DRAFT') {
      const newOption = option.value as RuleValueType;
      const ruleValue: RuleValue = { value: null, valueType: newOption, condition: 'INDIVIDUAL_RETIREMENT_AGE' };
      onUpdateOrDeleteAffiliationRule({
        ruleId: selectedPolicyRule?.id,
        ruleValueUnit: RuleValueUnit.AGE,
        policyRuleType,
        ruleValues: [
          ...getCurrentRuleValues().filter((rule) => rule.condition !== 'INDIVIDUAL_RETIREMENT_AGE'),
          ruleValue,
        ],
      });
    }
  };

  return (
    <div className="individual-choice-retirement-age-rule">
      <ListGroup variant={'inline-edit'}>
        <InlineEdit bold label={t(`policyRules:individual-choices.individual-retirement-age`)}>
          <Switch toggled={!!selectedRuleValue} onChange={(event) => handleToggle(event)} />
        </InlineEdit>
        <When condition={!!selectedRuleValue}>
          <InlineEdit bold label={t(`policyRules:individual-choices.age`)}>
            <Select
              disabled={policyState !== 'DRAFT'}
              options={valueTypeOptions}
              selected={getCurrentSelected()}
              onChange={handleSelectValueType}
              small
            />
          </InlineEdit>
        </When>
      </ListGroup>
      <InfoMessage message={t(`policyRules:individual-choices.individual-retirement-age-info-text`)} />
    </div>
  );
}

export default IndividualChoiceRetirementAgeRule;
