import { gql } from '@apollo/client';

export const ACCOUNTING_TYPE_VALUES = gql`
  query GetAccountingTypeValues {
    accountingTypeValues {
      name
      number
    }
  }
`;
