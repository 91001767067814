import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_INSURANCE_EVENTS_SEARCH_VALUES } from 'src/graphql/schema/query/insurance/InsuranceEventsSelectableSearchValues';
import { InsuranceEventsSelectableSearchValues } from 'src/types/reporting/InsuranceEventsSelectableSearchValues';

export const useInsuranceEventsSelectableSearchValues = (period: string): InsuranceEventsSelectableSearchValues => {
  const [result, setResult] = useState<InsuranceEventsSelectableSearchValues>();
  const { loading, error, data } = useQuery(GET_INSURANCE_EVENTS_SEARCH_VALUES, {
    variables: { wageFilePeriod: period },
  });

  useEffect(() => {
    data && setResult(data.insuranceEventsSelectableSearchValues);
  }, [loading, error, data]);

  return result;
};
