import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InlineEdit, ListGroup, Switch } from 'fgirot-k2-ui-components';
import { RuleValue } from 'src/types/policy/RuleValue';
import '../../PolicyEmploymentGroupModal/RuleTypeDrawer/rule-type-drawer.scss';
import { getDefaultRuleValue } from '../AffiliationRuleTypeConfig';
import { RuleGroupPropsWithType } from '../RuleGroupProps';

const EmploymentDateRule: React.FunctionComponent<RuleGroupPropsWithType> = ({
  policyState,
  selectedAffiliationRuleGroup,
  policyRuleType,
  onUpdateOrDeleteAffiliationRule,
}) => {
  const { t } = useTranslation();
  const defaultRuleValue: RuleValue = getDefaultRuleValue(policyRuleType);
  const getCurrentRuleValues = () => selectedAffiliationRuleGroup.affiliationRules.flatMap((rule) => rule.ruleValues);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (policyState === 'DRAFT') {
      const ruleValues: RuleValue[] = event.target.checked ? [{ ...defaultRuleValue }] : [];

      const selectedPolicyRule = selectedAffiliationRuleGroup.affiliationRules.find((r) => r);
      onUpdateOrDeleteAffiliationRule({ ruleId: selectedPolicyRule?.id, policyRuleType, ruleValues });
    }
  };

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant={'inline-edit'}>
        <InlineEdit
          bold
          key={'EmploymentDateRule'}
          label={t(`policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.name`)}
        >
          <Switch
            toggled={getCurrentRuleValues().length > 0}
            onChange={(event) => handleToggle(event)}
            data-cy={`employment-date-rule__switch-${policyRuleType}`}
          />
        </InlineEdit>
      </ListGroup>
    </div>
  );
};

export default EmploymentDateRule;
