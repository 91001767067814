import { REMOVE_ROLE_FROM_GROUP } from 'src/graphql/schema/mutation/group/RemoveRoleFromGroup';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { GET_USERS } from 'src/graphql/schema/query/user/GetUsers';
import { useApolloMutation } from 'src/service/graphql/Mutation';

export const useRemoveRoleFromGroup = () => {
  const apolloMutation = useApolloMutation();

  const removeRoleFromGroup = (groupId: string, roleId: string) =>
    apolloMutation(REMOVE_ROLE_FROM_GROUP, '', { groupId, roleId }, [GET_GROUPS, GET_USERS]);

  return removeRoleFromGroup;
};
