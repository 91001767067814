import { Option } from 'fgirot-k2-ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReportingEventsSelectableSearchValues } from 'src/service/reporting/GetReportingEventsSelectableSearchValues';
import { Employer } from 'src/types/employer/Employer';
import { useInsuranceEventsSelectableSearchValues } from 'src/service/insurance/GetInsuranceEventsSelectableSearchValues';

export const useEventsFilterOptions = (period: string, employers: Employer[], useInsuranceEvents = false) => {
  const { t } = useTranslation();
  const selectableValues = useInsuranceEvents
    ? useInsuranceEventsSelectableSearchValues(period)
    : useReportingEventsSelectableSearchValues(period);
  const [employerOptions, setEmployerOptions] = useState<Option<string>[]>([]);
  const [insuranceProviderOptions, setInsuranceProviderOptions] = useState<Option<string>[]>([]);
  const [benefitTypeOptions, setBenefitTypeOptions] = useState<Option<string>[]>([]);
  const [insuranceEventTypeOptions, setInsuranceEventTypeOptions] = useState<Option<string>[]>([]);
  const [reportingTypeOptions, setReportingTypeOptions] = useState<Option<string>[]>([]);
  const [insuranceEventStatusOptions, setInsuranceEventStatusOptions] = useState<Option<string>[]>([]);
  const [messageStatusOptions, setMessageStatusOptions] = useState<Option<string>[]>([]);

  useEffect(() => {
    if (selectableValues) {
      setEmployerOptions(
        selectableValues.employerIds.map((id) => {
          const employer = employers.find((e) => e.id === id);
          return { label: employer.legalName, value: employer.id };
        }),
      );

      setInsuranceProviderOptions(
        selectableValues.insuranceProviders.map((ip) => {
          return { label: ip.legalName, value: ip.id };
        }),
      );

      setBenefitTypeOptions(
        selectableValues.benefitTypes.map((benefitType) => {
          return { label: t(`benefitTypes:abbreviations.${benefitType}`), value: benefitType };
        }),
      );

      setInsuranceEventTypeOptions(
        selectableValues.insuranceEventTypes.map((insuranceEventType) => {
          return { label: t(`insuranceEventTypes:${insuranceEventType}`), value: insuranceEventType };
        }),
      );

      setReportingTypeOptions(
        selectableValues.reportingTypes.map((reportingType) => {
          return { label: t(`reportingTypes:${reportingType}`), value: reportingType };
        }),
      );

      setInsuranceEventStatusOptions(
        selectableValues?.insuranceEventStatuses?.map((insuranceEventStatus) => {
          return { label: t(`insuranceEventStatus:${insuranceEventStatus}`), value: insuranceEventStatus };
        }),
      );

      setMessageStatusOptions(
        selectableValues?.messageStatuses?.map((messageStatus) => {
          return { label: t(`reporting:message-status:${messageStatus}`), value: messageStatus };
        }),
      );
    }
  }, [selectableValues]);

  return {
    employerOptions,
    insuranceProviderOptions,
    benefitTypeOptions,
    insuranceEventTypeOptions,
    insuranceEventStatusOptions,
    reportingTypeOptions,
    messageStatusOptions,
  };
};
