import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYEE_INSURANCE_EVENTS } from 'src/graphql/schema/query/employee/GetEmployeeInsuranceEvents';
import { Employee } from 'src/types/Employee';
import { EmployeeInsuranceEventsPage } from 'src/types/employees/EmployeeInsuranceEventsPage';
import EmployeeInsuranceEvents from './EmployeeInsuranceEvents/EmployeeInsuranceEvents';
import {
  EmployeeInsuranceEventsFilters,
  EmployeeInsuranceEventsRequest,
} from 'src/types/employees/request/EmployeeInsuranceEventsRequest';
import './employee-insurance-events-overview.scss';

interface EmployeeInsuranceEventsOverviewProps {
  employee: Employee;
}

function EmployeeInsuranceEventsOverview({ employee }: EmployeeInsuranceEventsOverviewProps) {
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  const [employeeInsuranceEventsRequest, setEmployeeInsuranceEventsRequest] = useState<EmployeeInsuranceEventsRequest>({
    pageNumber: 0,
    pageSize: 25,
    filters: {
      wageFilePeriodFilter: {
        periodStart: null,
        periodEnd: null,
      },
    },
  });

  const handleApplyFilters = (filters: EmployeeInsuranceEventsFilters) => {
    setEmployeeInsuranceEventsRequest({ ...employeeInsuranceEventsRequest, filters });
  };

  return (
    <div className="employee-insurance-events-overview">
      <QueryWrapper
        query={GET_EMPLOYEE_INSURANCE_EVENTS}
        options={{
          variables: {
            employeeId: employee.id,
            request: {
              pageNumber: pageNumber,
              pageSize: pageSize,
              filters: employeeInsuranceEventsRequest?.filters,
            },
          },
        }}
      >
        {({ employeeInsuranceEvents, paging }: EmployeeInsuranceEventsPage) => (
          <EmployeeInsuranceEvents
            insuranceEvents={employeeInsuranceEvents}
            employee={employee}
            paging={paging}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            request={employeeInsuranceEventsRequest}
            onApplyFilters={handleApplyFilters}
            onPageChange={handlePageChange}
          />
        )}
      </QueryWrapper>
    </div>
  );
}

export default EmployeeInsuranceEventsOverview;
