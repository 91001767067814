import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { CREATE_BENEFIT_AGREED_PRODUCT } from 'src/graphql/schema/mutation/policy/CreateBenefitAgreedProduct';
import { BenefitAgreedProductRequest } from 'src/types/policy/request/BenefitAgreedProductRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateBenefitAgreedProduct = () => {
  const apolloMutation = useApolloMutation();

  const createBenefitAgreedProduct = (employerId: string, request: BenefitAgreedProductRequest) =>
    apolloMutation(CREATE_BENEFIT_AGREED_PRODUCT, employerId, { request }, [GET_POLICY]);

  return createBenefitAgreedProduct;
};
