import { Icon } from 'fgirot-k2-ui-components';

interface EmploymentCategoryIconProps {
  employmentCategory: string;
}

const EmploymentCategoryIcon = ({ employmentCategory }: EmploymentCategoryIconProps) => {
  switch (employmentCategory) {
    case 'BLUECOLLAR_CATEGORY':
      return <Icon type="BlueCollar" />;
    case 'WHITECOLLAR_CATEGORY':
      return <Icon type="WhiteCollar" />;
    default:
      return <Icon type="User" />;
  }
};

export default EmploymentCategoryIcon;
