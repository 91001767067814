import { UpdateReportingScheduledJobRequest } from 'src/types/reporting/UpdateReportingScheduledJobRequest';
import { UPDATE_REPORTING_SCHEDULED_JOB } from 'src/graphql/schema/mutation/report/UpdateReportingScheduledJob';
import { GET_REPORTING_SCHEDULED_JOBS } from 'src/graphql/schema/query/reporting/GetReportingScheduledJobs';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdateReportingScheduledJob = () => {
  const apolloMutation = useApolloMutation();

  const updateReportingScheduledJob = (employerId: string, request: UpdateReportingScheduledJobRequest) =>
    apolloMutation(UPDATE_REPORTING_SCHEDULED_JOB, employerId, { request }, [GET_REPORTING_SCHEDULED_JOBS]);

  return updateReportingScheduledJob;
};
