import { useApolloMutation } from '../graphql/Mutation';
import { CREATE_DIRECT_DOCUMENT_EXCEL } from 'src/graphql/schema/mutation/document/CreateDirectDocumentExcel';
import { CreateExcelDirectDocumentInput } from '../../types/documents/CreateExcelDirectDocumentInput';
import { GET_ASSIGNED_DOCUMENTS_TEMPLATES } from 'src/graphql/schema/query/documents/GetAssignedDocumentTemplates';

export const useCreateDirectDocumentExcel = () => {
  const apolloMutation = useApolloMutation();

  const createDirectDocumentExcel = (employerId: string, request: CreateExcelDirectDocumentInput) =>
    apolloMutation(CREATE_DIRECT_DOCUMENT_EXCEL, employerId, { request }, [GET_ASSIGNED_DOCUMENTS_TEMPLATES]);

  return createDirectDocumentExcel;
};
