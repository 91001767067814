import { Icon, Td } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';

interface KubTypeChecksProps {
  employerKubs: EmployerKub[];
  employerPayType: EmployerPayType;
}

const KubTypeChecks: FC<KubTypeChecksProps> = ({ employerKubs, employerPayType }) => {
  return (
    <>
      {employerKubs.map((employerKub) => {
        const isKubTypeMapped = employerPayType.employerKubs.some((kub) => kub.kubType === employerKub.kubType);
        return (
          <Td className="wage-type-table__kub-column" key={employerKub.id}>
            {isKubTypeMapped ? <Icon className="wage-type-table__kub-icon" type="Check" /> : null}
          </Td>
        );
      })}
    </>
  );
};

export default KubTypeChecks;
