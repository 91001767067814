import { gql } from '@apollo/client';

export const POSTING_RULES = gql`
  query GetPostingRules($chartOfAccountsId: UUID!) {
    postingRules(chartOfAccountsId: $chartOfAccountsId) {
      id
      name
      insuranceTypeMapping {
        id
        name
        benefitType
        insuranceProvider {
          id
          legalName
        }
      }
      accountingRow {
        id
        accountNumber
        accountingText
        accountingType {
          id
          accountingTypeValue {
            name
            number
          }
        }
      }
    }
  }
`;
