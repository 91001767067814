import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { CreatePolicyBenefitRequest } from 'src/types/policy/request/CreatePolicyBenefitRequest';
import { CREATE_POLICY_BENEFIT } from 'src/graphql/schema/mutation/policy/CreatePolicyBenefit';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreatePolicyBenefit = () => {
  const apolloMutation = useApolloMutation();

  const createPolicyBenefit = (employerId: string, request: CreatePolicyBenefitRequest): Promise<void> =>
    apolloMutation(CREATE_POLICY_BENEFIT, employerId, { request }, [GET_POLICY]);

  return createPolicyBenefit;
};
