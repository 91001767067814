import { gql } from '@apollo/client';
import { UserFilter } from 'src/types/user/User';

export const GET_USERS = gql`
  query GetUsers($userFilter: String, $pageSize: Int, $pageNumber: Int) {
    users(userFilter: $userFilter, pageSize: $pageSize, pageNumber: $pageNumber) {
      paging {
        totalNumberOfElements
      }
      externalUsers {
        user {
          id
          firstName
          lastName
          email
          phone
          identifier
          identifierType
          orderRef
          userType
          admin
          validTo
          customers {
            id
            name
          }
          accountNonLocked
        }
        externalGroups {
          name
          id
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetUsers {
    users(userFilter: "${UserFilter.ALL}", pageSize: 500, pageNumber: 0) {
      externalUsers {
        user {
          id
          firstName
          lastName
          admin
        }
      }
    }
  }
`;
