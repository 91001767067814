import { Button, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteEmployerKub } from 'src/service/employer/DeleteEmployerKub';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import './kub-drawer.scss';

interface KubTypeDetailsTabProps {
  employerKub: EmployerKub;
  onClose: () => void;
}
export const KubTypeDetailsTab: FunctionComponent<KubTypeDetailsTabProps> = ({ employerKub, onClose }) => {
  const { t } = useTranslation();
  const deleteEmployerKub = useDeleteEmployerKub();

  const handleRemoveKubType = () => {
    deleteEmployerKub(employerKub.id, employerKub.employerId);
    onClose();
  };

  return (
    <div className="kub-drawer__content">
      <Typography className="kub-drawer__description-text" variant="subtitle">
        {t('account:employers-tab.wage-type-mapping-settings.kub-drawer.description')}
      </Typography>
      <ListGroup variant="inline-edit">
        {employerKub.employerPayTypes.map((payType) => (
          <InlineEdit key={payType.id} label={`${payType.number} - ${payType.name}`} bold />
        ))}
      </ListGroup>
      <Button
        data-cy="remove-kub-button"
        icon={<Icon type="Trash" />}
        type="secondary"
        label={t('account:employers-tab.wage-type-mapping-settings.kub-drawer.remove-kub')}
        onClick={handleRemoveKubType}
      />
    </div>
  );
};

export default KubTypeDetailsTab;
