import { GET_PGL_RULES } from 'src/graphql/schema/query/policy/GetPglRules';
import { UPSERT_PGL_RULE_WAGE_TYPE } from 'src/graphql/schema/mutation/policy/UpsertPglRuleWageType';
import { PglWageTypeRequest } from 'src/types/policy/request/PglWageTypeRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpsertPglRuleWageType = () => {
  const apolloMutation = useApolloMutation();

  const upsertPglRuleWageType = (employerId: string, request: PglWageTypeRequest) =>
    apolloMutation(UPSERT_PGL_RULE_WAGE_TYPE, employerId, { request }, [GET_PGL_RULES]);

  return upsertPglRuleWageType;
};
