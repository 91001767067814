import { ListGroup } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PolicyBenefitConnectionRule,
  PolicyBenefitConnectionRuleType,
} from 'src/types/policy/PolicyBenefitConnectionRule';
import ConnectionRuleRow from './ConnectionRuleRow';

interface ConnectionDrawerTabProps {
  connectionRule: PolicyBenefitConnectionRule;
  onDisplayConnectionRuleDetailDrawer: (connectionRuleType: PolicyBenefitConnectionRuleType) => void;
}

const ConnectionDrawerTab: FC<ConnectionDrawerTabProps> = ({ connectionRule, onDisplayConnectionRuleDetailDrawer }) => {
  const { t } = useTranslation();
  return (
    <div className={['policy-benefit-overview__tab'].join(' ')}>
      <ListGroup variant="drawer-navigation" className="policy-benefit-overview__table">
        <ConnectionRuleRow
          title={t('benefitConnection:benefitConnectionRule.optional.name')}
          value={connectionRule.optional}
          onClick={() => onDisplayConnectionRuleDetailDrawer(PolicyBenefitConnectionRuleType.OPTIONAL)}
        />
      </ListGroup>
    </div>
  );
};

export default ConnectionDrawerTab;
