import React, { ChangeEvent, useEffect, useState } from 'react';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import { Employer } from 'src/types/employer/Employer';
import { useInsuranceEventCheckpoints } from 'src/service/checkpoint/GetInsuranceEventCheckpoints';
import LoadingPage from 'src/components/Common/LoadingPage';
import { useTranslation } from 'react-i18next';
import { createOptions } from 'src/util/OptionUtil';
import { unique } from 'src/util/ArrayUtil';
import { Option } from 'fgirot-k2-ui-components';
import { filterBenefitTypes, filterEmployer, filterFullName, filterInsuranceEventTypes } from 'src/util/EmployeeFilter';
import CertifyTable from './CertifyTable';
import { resetCacheList } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import FilterComponent from 'src/components/Common/FilterComponent';
import { useEmployersIdLegalName } from 'src/service/employer/GetEmployer';

interface CertifyProps {
  wageFilePeriod: string;
  customerId: string;
}
const Certify = ({ wageFilePeriod, customerId }: CertifyProps) => {
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState('');
  const [selectedEmployers, setSelectedEmployers] = useState<Option<Employer>[]>([]);
  const [selectedBenefitType, setSelectedBenefitType] = useState<Option<string>[]>([]);
  const [selectedInsuranceEvent, setSelectedInsuranceEvent] = useState<Option<string>[]>([]);

  const employers: Employer[] = useEmployersIdLegalName(customerId);
  const employerIds = employers.map((e) => e.id);
  useEffect(() => {
    resetCacheList(EMPLOYEE_IDS_INSURANCE_READY_TO_FINALIZE);
  }, []);

  const service = useInsuranceEventCheckpoints(employerIds, wageFilePeriod);

  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>{t('common:error')}</div>;
    case LOADED:
      break;
  }

  const reportEmployees = service.payload;

  const checkpoints = reportEmployees.map((reportEmployee) => reportEmployee.checkpoints);

  const handleNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const employerFilterProps = {
    placeholder: t('employeeFilter:all-employers'),
    options: createOptions(employers, null, null, 'legalName'),
    selected: selectedEmployers,
    onChange: (input: Option<Employer>[]): void => {
      setSelectedEmployers([...input]);
    },
  };

  const insuranceEventTypes = unique(
    checkpoints.flatMap((checkpoint) => checkpoint.flatMap((c) => c.insuranceEventType)),
  );

  const insuranceEventFilterProps = {
    placeholder: t('employeeFilter:all-subaction-categories'),
    options: createOptions(insuranceEventTypes, t, 'insuranceEventTypes'),
    selected: selectedInsuranceEvent,
    onChange: (input: Option<string>[]): void => {
      setSelectedInsuranceEvent([...input]);
    },
  };

  const benefitTypes = unique(checkpoints.flatMap((checkpoint) => checkpoint.flatMap((c) => c.benefitType)));

  const benefitsFilterProps = {
    placeholder: t('employeeFilter:all-benefit-categories'),
    options: createOptions(benefitTypes, t, 'benefitTypes'),
    selected: selectedBenefitType,
    onChange: (input: Option<string>[]): void => {
      setSelectedBenefitType([...input]);
    },
  };

  const filteredEmployees = reportEmployees
    .filter((reportEmployee) => filterFullName(reportEmployee.employee, nameFilter))
    .filter((reportEmployee) => filterEmployer(reportEmployee.employee, selectedEmployers))
    .filter((reportEmployee) => filterInsuranceEventTypes(reportEmployee.checkpoints, selectedInsuranceEvent))
    .filter((reportEmployee) => filterBenefitTypes(reportEmployee.checkpoints, selectedBenefitType));
  return (
    <div className={['validating'].join(' ')}>
      <FilterComponent
        showSearch
        searchPlaceholder={t('searchTerms:search-employee')}
        searchFilter={nameFilter}
        onSearchFilterChange={handleNameFilterChange}
        selectProps={[employerFilterProps, insuranceEventFilterProps, benefitsFilterProps]}
      />
      <CertifyTable reportEmployees={filteredEmployees} employers={employers} />
    </div>
  );
};

export default Certify;
