import './show-conditions-modal.scss';
import { Modal, Card, Icon, Typography } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { fetchInsuranceProviders } from 'src/service/insurance/GetInsuranceProviders';
import { CertifyConditionMetadata } from 'src/types/CertifyConditionMetadata';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import Provider from './Provider';
import { useTranslation } from 'react-i18next';
import ScrollBase from 'src/components/Common/ScrollBase';
import { useCache } from 'src/graphql/cache/ApolloCache';
import { CERTIFY_CONDITION_METADATA } from 'src/graphql/cache/CacheKeys';
import { arrayToMap } from 'src/util/ArrayUtil';

interface ShowConditionsModalProps {
  open: boolean;
  handleExit: () => void;
}

const ShowConditionsModal = ({ open, handleExit }: ShowConditionsModalProps) => {
  const { t } = useTranslation();

  const [insuranceProviders, setInsuranceProviders] = useState<InsuranceProvider[]>();

  useEffect(() => {
    fetchInsuranceProviders().then((data) => setInsuranceProviders(data));
  }, []);

  const certifyConditionMetadatas = useCache<CertifyConditionMetadata[]>(
    CERTIFY_CONDITION_METADATA,
    ['benefitType', 'insuranceProviderId', 'insuranceProviderProductConditionId'],
    true,
  );

  const groupedConditionMetadatas: Map<string, Array<CertifyConditionMetadata>> = arrayToMap(
    certifyConditionMetadatas,
    'insuranceProviderId',
  );

  return (
    <Modal centered overlayBackground open={open} onClose={handleExit}>
      <Card
        icon={<Icon className="certify-conditions-modal__card__icon" type="Info" />}
        title={'Villkor'}
        defaultClose={true}
        onClose={handleExit}
      >
        <Typography>{t('certify:conditions-modal.subtitle-click')}</Typography>
        <ScrollBase className="certify-conditions-modal__content" maxHeightPercentage={40}>
          {groupedConditionMetadatas &&
            insuranceProviders &&
            Array.from(groupedConditionMetadatas.keys()).map((key) => (
              <Provider
                key={key}
                certify={false}
                name={insuranceProviders.find((provider) => provider.id === key).legalName}
                values={groupedConditionMetadatas.get(key)}
              />
            ))}
        </ScrollBase>
      </Card>
    </Modal>
  );
};

export default ShowConditionsModal;
