import { gql } from '@apollo/client';

export const FINALIZE_CHECKPOINTS = gql`
  mutation FinalizeCheckpoints($employeeIds: [UUID]!, $period: String, $actionCategory: String!) {
    finalizeCheckpoints(employeeIds: $employeeIds, period: $period, actionCategory: $actionCategory) {
      employeeIds
      period
      actionCategory
    }
  }
`;
