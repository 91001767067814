import { Input } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationDataType } from 'src/types/validation/ValidationDataType';
import { inputSchema } from 'src/validation/schemas/inputSchema';

type InputType = 'text' | 'search' | 'email' | 'number' | 'password' | 'tel' | 'url';

interface UpdateInputTextProps {
  fieldKey: string;
  fieldValue: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  type?: InputType;
  validationKey: ValidationDataType;
  onBlur: (field: string, value: string) => void;
  onChange?: (field: string, value: string) => void;
}

const UpdateInputText: React.FunctionComponent<UpdateInputTextProps> = ({
  fieldKey,
  fieldValue,
  placeholder,
  disabled,
  maxLength,
  type = 'text',
  validationKey,
  onBlur,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(fieldValue);

  const [errorMessage, setErrorMessage] = useState<string>();

  const { error } = inputSchema.validate({ [validationKey]: value }, { abortEarly: false });

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange && onChange(fieldKey, event.target.value);
  };

  const handleOnBlur = () => {
    error && error.details.length > 0
      ? setErrorMessage(t(`validationErrors:${error.details[0].context.key}.${error.details[0].type}`))
      : onBlur(fieldKey, value);
  };

  useEffect(() => {
    error ?? setErrorMessage('');
  }, [error]);

  return (
    <Input.Text
      variant="inline-edit"
      type={type}
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      placeholder={placeholder}
      errorMessage={errorMessage}
      disabled={disabled}
      maxLength={maxLength}
      {...props}
    />
  );
};

export default UpdateInputText;
