import { Employer } from '../employer/Employer';

export interface DocumentTemplate {
  id: string;
  name: string;
  description: string;
  formatType: string;
  inputPeriodParameter: InputPeriodParameter;
  inputParameters: InputParameter[];
  employers: Employer[];
}

export type InputPeriodParameter =
  | 'PERIOD_SINGLE_YEAR_MONTH'
  | 'PERIOD_FROMTO_YEAR_MONTH'
  | 'PERIOD_FROMTO_YEAR_MONTH_DAY';

export enum InputPeriodParameterEnum {
  PERIOD_SINGLE_YEAR_MONTH = 'PERIOD_SINGLE_YEAR_MONTH',
  PERIOD_FROMTO_YEAR_MONTH = 'PERIOD_FROMTO_YEAR_MONTH',
  PERIOD_FROMTO_YEAR_MONTH_DAY = 'PERIOD_FROMTO_YEAR_MONTH_DAY',
}

export type InputParameter = 'INPUT_PERIODTYPE_ACCOUNTING' | 'INPUT_PERIODTYPE_PREMIUM' | 'INPUT_PERIODTYPE_PAYMENT';

export enum InputParameterEnum {
  INPUT_PERIODTYPE_ACCOUNTING = 'INPUT_PERIODTYPE_ACCOUNTING',
  INPUT_PERIODTYPE_PREMIUM = 'INPUT_PERIODTYPE_PREMIUM',
  INPUT_PERIODTYPE_PAYMENT = 'INPUT_PERIODTYPE_PAYMENT',
}
