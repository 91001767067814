import { useMutation } from '@apollo/client';
import { Button, Card, CardAction, ErrorSection, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import { useState } from 'react';
import { client } from 'src/graphql';
import { CONSTRUCT_REPORTING_QUEUE } from 'src/graphql/schema/mutation/report/ConstructReportingQueue';
import { Employer } from 'src/types/employer/Employer';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { GET_REPORTING_EVENTS } from 'src/graphql/schema/query/reporting/GetReportingEvents';

const CalculateReportingQueueButton = ({
  employers,
  wageFilePeriod,
  onSuccess,
}: {
  employers: Employer[];
  wageFilePeriod: string;
  onSuccess: () => void;
}) => {
  const [calculateReportingQueue, { loading, error }] = useMutation(CONSTRUCT_REPORTING_QUEUE);

  const employerOptions = createOptionsFromList(employers, 'id', 'legalName');

  const [open, setOpen] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState<Option<string>>(employerOptions[0]);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const triggerQueueCalculation = () => {
    calculateReportingQueue({
      variables: {
        employerId: selectedEmployer.value,
        wageFilePeriod: wageFilePeriod,
      },
      context: { employerId: selectedEmployer.value },
    }).then(() => {
      client.refetchQueries({
        include: [GET_REPORTING_EVENTS],
      });
    });
    onSuccess();
    closeModal();
  };

  return (
    <>
      <Button label={t('reporting:queue-calculation.calculate-button')} size="small" onClick={openModal} />
      <Modal open={open} centered overlayBackground onClose={closeModal}>
        <Card title={t('reporting:queue-calculation.title')}>
          {error && (
            <ErrorSection>
              <Typography variant="caption">{error.message}</Typography>
            </ErrorSection>
          )}
          <Select
            label={t('reporting:queue-calculation.employer')}
            small
            outlined
            placeholder={t('reporting:queue-calculation.choose-employer')}
            options={employerOptions}
            selected={selectedEmployer}
            onChange={setSelectedEmployer}
          />
          <CardAction>
            <Button label={t('common:cancel')} type="link" onClick={closeModal} />
            <Button
              label={t('reporting:queue-calculation.calculate-button')}
              onClick={triggerQueueCalculation}
              disabled={loading}
            />
          </CardAction>
        </Card>
      </Modal>
    </>
  );
};

export { CalculateReportingQueueButton };
