import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Button, Card, CardAction, Modal } from 'fgirot-k2-ui-components';
import './create-group-modal.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { useCreateGroup } from 'src/service/group/CreateGroup';
import { CreateGroupInput } from 'src/types/group/Group';

interface CreateGroupModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateGroupModal: FunctionComponent<CreateGroupModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const createGroup = useCreateGroup();
  const [groupName, setGroupName] = useState('');
  const isFormValid = groupName.length && groupName.length < 65;

  const handleOnClose = () => {
    setGroupName('');
    onClose();
  };
  const handleGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => setGroupName(event.target.value);

  const handleCreateGroup = () => {
    if (isFormValid) {
      const request: CreateGroupInput = { name: groupName };
      createGroup(request);
      handleOnClose();
    }
  };

  return (
    <Modal overlayBackground centered onClose={handleOnClose} open={open}>
      <Card title={t('permission:create-group-modal.title')}>
        <div className="create-group-modal" data-testid="create-group-modal">
          <TextInput
            label={t('common:name')}
            className="create-group-modal__group-name"
            type="text"
            validationKey="text255"
            variant="default"
            maxLength={256}
            value={groupName}
            onChange={handleGroupNameChange}
            data-testid="group-modal-input"
          />
        </div>
        <CardAction>
          <Button onClick={handleOnClose} type="link" label={t('common:cancel')} />
          <Button
            label={t('common:create')}
            disabled={!isFormValid}
            onClick={handleCreateGroup}
            data-testid={`submit-button-${!isFormValid ? 'disabled' : 'active'}`}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateGroupModal;
