import { gql } from '@apollo/client';

export const CREATE_EMPLOYER_INDIVIDUAL_CHOICE = gql`
  mutation CreateEmployerIndividualChoice($request: EmployerIndividualChoiceRequest!) {
    createEmployerIndividualChoice(request: $request) {
      id
      type
      fromWageFile
      fromInvoice
    }
  }
`;
