import { DocumentNode, QueryHookOptions } from '@apollo/client';
import { useApolloQuery, useApolloQueryList, useApolloQueryPageable } from 'src/service/graphql/Query';
import { Paging } from 'src/types/PageableResponse';
import { INIT, LOADING, ERROR, LOADED } from 'src/types/Status';
import ErrorPage from '../ErrorPage';
import { Loader } from 'fgirot-k2-ui-components';
interface QueryWrapperProps<T> {
  query: DocumentNode;
  children: (result: T | T[], paging?: Paging, fetchMore?: () => void) => JSX.Element;
  type?: 'default' | 'list' | 'pageable';
  options?: QueryHookOptions;
  loadingComponent?: JSX.Element;
  errorComponent?: JSX.Element;
  useErrorToast?: boolean;
}

const QueryWrapper = <T extends object>({
  query,
  type,
  options,
  children,
  loadingComponent,
  errorComponent,
  useErrorToast = true,
}: QueryWrapperProps<T>) => {
  switch (type) {
    case 'list':
      return (
        <QueryWrapperList
          query={query}
          children={children}
          options={options}
          loadingComponent={loadingComponent}
          errorComponent={errorComponent}
          useErrorToast={useErrorToast}
        />
      );
    case 'pageable':
      return (
        <QueryWrapperPageable
          query={query}
          children={children}
          options={options}
          loadingComponent={loadingComponent}
          errorComponent={errorComponent}
        />
      );
    case 'default':
    default:
      return (
        <QueryWrapperDefault
          query={query}
          children={children}
          options={options}
          loadingComponent={loadingComponent}
          errorComponent={errorComponent}
          useErrorToast={useErrorToast}
        />
      );
  }
};

const QueryWrapperDefault = <T extends object>({
  query,
  options,
  children,
  loadingComponent,
  errorComponent,
  useErrorToast,
}: QueryWrapperProps<T>) => {
  const service = useApolloQuery<T>(query, options, useErrorToast);

  switch (service.status) {
    case INIT:
    case LOADING:
      return loadingComponent || <Loader type="blue" size="small" />;
    case ERROR:
      return errorComponent || <ErrorPage errorMessage={service.error.message} />;
    case LOADED:
      return children(service.payload);
  }
};

const QueryWrapperList = <T extends object>({ query, options, children, useErrorToast }: QueryWrapperProps<T>) => {
  // TODO: Handle error state when fetching lists
  const result = useApolloQueryList<T>(query, options, useErrorToast);

  return children(result);
};

const QueryWrapperPageable = <T extends object>({ query, options, children }: QueryWrapperProps<T>) => {
  const { result, paging, fetchMore } = useApolloQueryPageable<T>(query, options);

  return children(result, paging, fetchMore);
};

export default QueryWrapper;
