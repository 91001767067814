import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { RuleValue, RuleValueType } from 'src/types/policy/RuleValue';

export type EmploymentRuleTypeConfig = {
  defaultRuleValue?: RuleValue[];
  validValueTypes?: RuleValueType[];
  validRuleValues?: RuleValue[];
};

const employmentRuleTypeConfig = new Map<PolicyRuleType, EmploymentRuleTypeConfig>([
  [
    PolicyRuleType.EMPLOYMENT_TYPE,
    {
      defaultRuleValue: [
        { condition: 'UNTIL_FURTHER_NOTICE_TYPE', value: '1', valueType: 'GREATER_OR_EQUALS_THAN' },
        { condition: 'TEMPORARY_EMPLOYMENT_TYPE', value: '90', valueType: 'GREATER_OR_EQUALS_THAN' },
      ],
      validValueTypes: ['GREATER_OR_EQUALS_THAN'],
    },
  ],
]);

export const getDefaultEmploymentRuleValues = (policyRuleType: PolicyRuleType): RuleValue[] => {
  return employmentRuleTypeConfig.get(policyRuleType).defaultRuleValue;
};
