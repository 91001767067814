import { DrawerNavigation } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PglWageType } from 'src/types/policy/PglWageType';
import { PglBonusComponent } from 'src/types/policy/PglBonusComponent';
import { isNotBonusComponent } from 'src/util/PolicyUtil';

interface PglCalculationDrawerNavigationProps {
  label: string;
  onClick: () => void;
  type: PglWageType | PglBonusComponent;
  disabled?: boolean;
}

const PglCalculationDrawerNavigation: React.FC<PglCalculationDrawerNavigationProps> = ({
  label,
  onClick,
  type,
  disabled = false,
}) => {
  const { t } = useTranslation();

  let value: string;

  if (isNotBonusComponent(type)) {
    value =
      `${t(`calculationTimeFrames:${type.calculationTimeFrame}`)} * ${type.conversionFactor}` +
      (type.conversionDenominator ? ` / ${type.conversionDenominator}` : '');
  } else {
    value = `${t('account:pgl-rules:drawer:bonus:title')} ${type.numberOfYearsToInclude}${t(
      'account:pgl-rules:drawer:bonus:number-of-years:prefix',
    )}`;
  }

  return (
    <DrawerNavigation
      key={type.id}
      onClick={() => onClick()}
      label={label}
      value={value}
      disabled={disabled}
      className={isNotBonusComponent(type) ? 'pgl-rule-drawer__navigation' : ''}
    />
  );
};

export default PglCalculationDrawerNavigation;
