import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { createOptions } from 'src/util/OptionUtil';
import { SearchPostingRulesFilter } from 'src/types/accountingunit/PostingRulesRequest';
import { insuranceCategories, InsuranceCategory } from 'src/types/insurancetypemapping/InsuranceTypeMapping';
import './search-posting-rules-filter-modal.scss';

interface SearchPostingRulesFilterModalProps {
  open: boolean;
  filters: SearchPostingRulesFilter;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchPostingRulesFilter) => void;
}

const SearchPostingRulesFilterModal: FC<SearchPostingRulesFilterModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<SearchPostingRulesFilter>({ ...filters });

  const insuranceCategoryOptions: Option<InsuranceCategory>[] = createOptions(
    insuranceCategories,
    t,
    'insuranceTypeMapping:insurance-category',
  );

  const handleInsuranceCategoryFilterChange = (options: Option<InsuranceCategory>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceCategoryFilter: options.length > 0 ? options.map((option) => option.value) : [],
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="search-posting-rules-filter-modal"
        title={t('economySettings:posting-rules-tab.search-posting-rules-filter-modal.title')}
        icon={<Icon type="Sliders" className="search-posting-rules-filter-modal__icon" />}
        size="narrow"
      >
        <div className="search-posting-rules-filter-modal__filters">
          <div className="search-posting-rules-filter-modal__select">
            <Select
              label={t('economySettings:posting-rules-tab.search-posting-rules-filter-modal.insurance-category-label')}
              placeholder={t(
                'economySettings:posting-rules-tab.search-posting-rules-filter-modal.insurance-category-placeholder',
              )}
              options={insuranceCategoryOptions}
              selected={insuranceCategoryOptions.filter((option) =>
                newFilters?.insuranceCategoryFilter?.includes(option.value),
              )}
              onChange={handleInsuranceCategoryFilterChange}
              small
              outlined
              selectAll
              multiple
            />
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button
            label={t('economySettings:posting-rules-tab.search-posting-rules-filter-modal.use-button')}
            onClick={onApply}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchPostingRulesFilterModal;
