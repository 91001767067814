import React, { FC, useState } from 'react';
import AgreementRegistryOverview from './AgreementRegistryOverview';
import CreateNewAgreedProduct from './CreateNewAgreedProduct/CreateNewAgreedProduct';
import './agreement-registry-drawer.scss';
import DrawerModal from 'src/components/Common/DrawerModal';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/query/contract/GetInsuranceProviderContract';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import AgreedProductDetails from './AgreedProductDetails/AgreedProductDetails';
import CreateNewAgreementCostCentre from './AgreementRegistryOverview/CreateNewAgreementCostCentre';
import AgreementCostCentreDetails from './AgreementCostCentreDetails/AgreementCostCentreDetails';
import { AgreementRegistryDrawerTab } from 'src/types/tabs/AgreementRegistryDrawerTab';

interface AgreementRegistryDrawerProps {
  open: boolean;
  onClose: () => void;
  contractId: string;
}

const AgreementRegistryDrawer: FC<AgreementRegistryDrawerProps> = ({ open, onClose, contractId }) => {
  const [currentTab, setCurrentTab] = useState<AgreementRegistryDrawerTab>(AgreementRegistryDrawerTab.AGREED_PRODUCTS);
  const [creatingNewAgreedProduct, setCreatingNewAgreedProduct] = useState(false);
  const [creatingNewAgreementCostCentre, setCreatingNewAgreementCostCentre] = useState(false);
  const [selectedCostCentreId, setSelectedCostCentreId] = useState<string>(null);
  const [selectedAgreedProductId, setSelectedAgreedProductId] = useState<string>(null);

  const handleCreateNewAgreedProduct = () => {
    setCreatingNewAgreedProduct(true);
  };

  const handleBackClick = () => {
    setCreatingNewAgreedProduct(false);
    setSelectedAgreedProductId(null);
    setCreatingNewAgreementCostCentre(false);
    setSelectedCostCentreId(null);
  };

  const handleSelectAgreedProduct = (agreedProductId: string) => {
    setSelectedAgreedProductId(agreedProductId);
  };

  const handleCreateNewAgreementCostCentre = () => {
    setCreatingNewAgreementCostCentre(true);
  };

  const handleSelectAgreementCostCentre = (costCentreId: string) => {
    setSelectedCostCentreId(costCentreId);
  };

  const navigateToAgreementCostCentreDetails = (id: string) => {
    setCurrentTab(AgreementRegistryDrawerTab.AGREEMENT_COST_CENTRES);
    handleBackClick();
    setSelectedCostCentreId(id);
  };

  const navigateToAgreementCostCentreOverview = () => {
    setCurrentTab(AgreementRegistryDrawerTab.AGREEMENT_COST_CENTRES);
    handleBackClick();
  };

  const navigateToAgreedProductsOverview = () => {
    setCurrentTab(AgreementRegistryDrawerTab.AGREED_PRODUCTS);
    handleBackClick();
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <QueryWrapper
        query={GET_INSURANCE_PROVIDER_CONTRACT}
        options={{ variables: { insuranceProviderContractId: contractId } }}
      >
        {(contract: InsuranceProviderContract) => (
          <>
            {!creatingNewAgreedProduct &&
              !selectedAgreedProductId &&
              !creatingNewAgreementCostCentre &&
              !selectedCostCentreId && (
                <AgreementRegistryOverview
                  onClose={onClose}
                  contract={contract}
                  onCreateNewAgreedProduct={handleCreateNewAgreedProduct}
                  onSelectAgreedProduct={handleSelectAgreedProduct}
                  onCreateNewAgreementCostCentre={handleCreateNewAgreementCostCentre}
                  onSelectAgreementCostCentre={handleSelectAgreementCostCentre}
                  currentTab={currentTab}
                />
              )}
            {creatingNewAgreedProduct && (
              <CreateNewAgreedProduct
                contract={contract}
                onClose={onClose}
                onBackClick={navigateToAgreedProductsOverview}
              />
            )}
            {creatingNewAgreementCostCentre && (
              <CreateNewAgreementCostCentre
                contract={contract}
                onClose={onClose}
                onBackClick={navigateToAgreementCostCentreOverview}
                navigateToAgreementCostCentreDetails={navigateToAgreementCostCentreDetails}
              />
            )}
            {selectedAgreedProductId && (
              <AgreedProductDetails
                onClose={onClose}
                onBackClick={navigateToAgreedProductsOverview}
                contract={contract}
                agreedProductId={selectedAgreedProductId}
              />
            )}
            {selectedCostCentreId && (
              <AgreementCostCentreDetails
                onClose={onClose}
                onBackClick={navigateToAgreementCostCentreOverview}
                contract={contract}
                agreementCostCentreId={selectedCostCentreId}
              />
            )}
          </>
        )}
      </QueryWrapper>
    </DrawerModal>
  );
};

export default AgreementRegistryDrawer;
