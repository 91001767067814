import { gql } from '@apollo/client';

export const GET_CONTRACT_AGREED_PRODUCT = gql`
  query GetContractAgreedProduct($agreedProductId: UUID!) {
    contractAgreedProduct(agreedProductId: $agreedProductId) {
      id
      name
      insuranceProviderProductCategory
      agreementProductCode
      defaultReportingDetailLevel
      insuranceProduct {
        id
        name
        insuranceProvider {
          id
          legalName
        }
      }
      reportingRuleConfiguration {
        id
        name
      }
      certifyInsuranceProductCondition
      certifyInsuranceProductConditionId
    }
  }
`;
