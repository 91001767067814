import { Icon, Input } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface EmployeeSearchInputProps {
  prefilledText?: string;
  onTriggerSearch: (searchText) => void;
  disabled?: boolean;
  errorMessage?: string;
}

const EmployeeSearchInput = ({ onTriggerSearch, prefilledText, disabled, errorMessage }: EmployeeSearchInputProps) => {
  const { t } = useTranslation();
  const [text, setText] = useState(prefilledText || '');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setText(event.target.value);

  const onEnterKeyPressed = () => {
    onTriggerSearch(text);
  };

  return (
    <Input.Text
      disabled={disabled}
      placeholder={prefilledText || t('employees:search-tab:search-input-placeholder-text')}
      type="search"
      icon={<Icon type="Search" />}
      onChange={onChange}
      errorMessage={errorMessage}
      onKeyDown={(evt) => evt.key === 'Enter' && onEnterKeyPressed()}
      data-cy="employee-search-input"
      maxLength={64}
    />
  );
};

export default EmployeeSearchInput;
