import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { ExternalGroup } from 'src/types/group/Group';
import { User } from 'src/types/user/User';
import { mapNamesToOneString } from 'src/util/NameUtil/NameUtil';

interface UsersTableRowProps {
  user: User;
  groups: ExternalGroup[];
  onClick: (userId: string) => void;
}

const UsersTableRow: FC<UsersTableRowProps> = ({ user, groups, onClick }) => {
  const formatGroupsString = (): string => {
    if (groups.length) {
      const displayedName = groups[0].name;
      const remainingGroups = groups.slice(1);
      if (remainingGroups.length) {
        return `${displayedName} + ${remainingGroups.length}`;
      }
      return displayedName;
    }
    return '-';
  };
  return (
    <TableRow onClick={() => onClick(user.id)} data-testid={`users-table-row-${user.id}`}>
      <Td>{mapNamesToOneString(user.firstName, user.lastName)}</Td>
      <Td>{user.email ?? ''}</Td>
      <Td>{formatGroupsString()}</Td>
    </TableRow>
  );
};

export default UsersTableRow;
