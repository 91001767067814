import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { EmployeeHistory } from 'src/types/employees/EmployeeHistory';
import { stringOrBlank } from 'src/util/StringUtil';

interface EmployeeHistoryTableRowProps {
  employeeHistory: EmployeeHistory;
}

const EmployeeHistoryTableRow: FC<EmployeeHistoryTableRowProps> = ({ employeeHistory }) => {
  return (
    <TableRow data-testid={`employee-history-table-row__${employeeHistory.employmentStartDate}`}>
      <Td>{stringOrBlank(employeeHistory.employmentNumber)}</Td>
      <Td>{stringOrBlank(employeeHistory.earliestEmploymentStartDate)}</Td>
      <Td>{stringOrBlank(employeeHistory.employmentStartDate)}</Td>
      <Td>{stringOrBlank(employeeHistory.employmentEndDate)}</Td>
    </TableRow>
  );
};

export default EmployeeHistoryTableRow;
