import { useState, useEffect } from 'react';
import { getPglRules } from 'src/service/policy/GetPglRules';
import { Option } from 'fgirot-k2-ui-components';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';

export const useSourceReferenceOptions = (
  sourceType: string,
  employerId: string,
  policyId: string,
): Option<string>[] => {
  const [result, setResult] = useState<Option<string>[]>([]);
  const employerWageTypes = useEmployerWageTypes(employerId);

  useEffect(() => {
    if (sourceType === 'WAGE_TYPE') {
      setResult(
        employerWageTypes
          .filter((wt) => wt.wageType !== 'NOT_MAPPED')
          .map((wageType) => {
            return { value: wageType.id, label: wageType.name };
          }),
      );
    } else if (sourceType === 'PGL') {
      getPglRules(policyId).then((res) =>
        setResult(
          res.map((pglRule) => {
            return { value: pglRule.ruleId, label: pglRule.name };
          }),
        ),
      );
    } else {
      setResult([]);
    }
  }, [sourceType]);

  return result;
};
