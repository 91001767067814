import { Icon, Input } from 'fgirot-k2-ui-components';
import React, { ChangeEvent } from 'react';

interface DocumentTemplatesSearchInputProps {
  searchValue: string;
  onSearchFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  searchPlaceholder: string;
}
const DocumentTemplatesSearchInput = ({
  searchValue,
  onSearchFilterChange,
  searchPlaceholder,
}: DocumentTemplatesSearchInputProps) => {
  return (
    <div className="document-template-tab">
      <Input.Text
        value={searchValue}
        type="search"
        onChange={onSearchFilterChange}
        placeholder={searchPlaceholder}
        icon={<Icon type="Search" />}
        className="filter-component__search"
      />
    </div>
  );
};

export default DocumentTemplatesSearchInput;
