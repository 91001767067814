import { gql } from '@apollo/client';

export const GET_QUALIFIED_BENEFITS = gql`
  query GetQualifiedBenefits($employeeId: UUID!, $period: String) {
    qualifiedBenefits(employeeId: $employeeId, period: $period) {
      name
      benefitType
      employeeInsuranceId
      isEntitled
      evaluationPolicyEntityId
      evaluationPolicyPackageId
      evaluationPolicyBenefitId
      optional
      benefitRuleOutcomes {
        policyBenefitRuleId
        policyRuleType
        isSatisfied
        ruleBlockId
        outcomeCause
      }
    }
  }
`;
