import { gql } from '@apollo/client';

export const SESSION_UPDATED = gql`
  subscription SessionUpdated {
    sessionUpdated {
      id
      customerId
      employerId
      period
      uploadSignature
      uploadProtocol
      filename
      status
      createdAt
      validationErrors {
        errorMessageKey
      }
    }
  }
`;
