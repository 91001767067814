import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TableRow, Tabs, Td } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase';
import { mandatoryDataBasicFields } from 'src/types/employer/EmployerControlPointRule';
import BasicFieldsTabContent from './BasicFieldsTabContent';
import DrawerModal from 'src/components/Common/DrawerModal';

const MandatoryDataBasicTypeRow = () => {
  const [basicDataModalOpen, setBasicDataModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleRowClick = () => {
    setBasicDataModalOpen((prev) => !prev);
  };

  const handleModalOnClose = () => setBasicDataModalOpen(false);

  return (
    <>
      <TableRow key="mandatory-data-basic-type-row" onClick={handleRowClick}>
        <Td>{t('account:employers-tab.reporting-settings.mandatory-data-tab.control-type-basic')}</Td>
        <Td>
          {mandatoryDataBasicFields
            .map((fieldName) => {
              return `${t(`violationTypes:${fieldName}`)}`;
            })
            .join(', ')}
        </Td>
      </TableRow>
      <DrawerModal overlayBackground overlayOnClose open={basicDataModalOpen} onClose={handleModalOnClose}>
        <DrawerBase
          onClose={handleModalOnClose}
          title={t('account:employers-tab.reporting-settings.mandatory-data-tab.control-type-basic')}
          subTitle={t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer:type-basic-subtitle')}
        >
          <Tabs>
            <Tab
              title={t('account:employers-tab.reporting-settings.mandatory-data-rule-drawer.type-basic-control-tab')}
            >
              <BasicFieldsTabContent basicFields={mandatoryDataBasicFields} />
            </Tab>
          </Tabs>
        </DrawerBase>
      </DrawerModal>
    </>
  );
};
export default MandatoryDataBasicTypeRow;
