import React, { FC, useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { InvoiceDrawerTab } from 'src/types/tabs/InvoiceDrawerTab';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import PaymentTransactionsDetails from './InvoiceDrawerOverview/PaymentTransactionDetails';
import CreateNewTransaction from './InvoiceDrawerOverview/CreateNewPaymentTransaction';
import InvoiceDrawerOverview from './InvoiceDrawerOverview';
import { Invoice } from 'src/types/invoice/Invoice';
import { GET_PAYMENT_TRANSACTIONS } from 'src/graphql/schema/query/invoice/GetPaymentTransactions';
import { PaymentTransaction } from 'src/types/invoice/PaymentTransaction';
import { GET_PAYMENT_DUE_DATES } from 'src/graphql/schema/query/invoice/GetPaymentDueDates';
import { PaymentDueDate } from 'src/types/invoice/PaymentDueDate';
import PaymentDueDateDetails from './InvoiceDrawerOverview/PaymentDueDateDetails';
import AddNewPaymentDueDate from './InvoiceDrawerOverview/AddNewPaymentDueDate';
import { Payment } from 'src/types/payment/Payment';
import PaymentDetails from './InvoiceDrawerOverview/PaymentDetails';
import UpdateInvoice from './InvoiceDrawerOverview/UpdateInvoice';
import './invoice-drawer.scss';

interface InvoiceDrawerProps {
  open: boolean;
  payments: Payment[];
  onClose: () => void;
  invoice: Invoice;
}

const InvoiceDrawer: FC<InvoiceDrawerProps> = ({ open, payments, onClose, invoice }) => {
  const [currentTab, setCurrentTab] = useState<InvoiceDrawerTab>(InvoiceDrawerTab.ABOUT);
  const [updatingInvoice, setUpdatingInvoice] = useState(false);
  const [addingNewPaymentDueDate, setAddingNewPaymentDueDate] = useState(false);
  const [selectedDueDateId, setSelectedDueDateId] = useState<string>(null);
  const [creatingNewTransaction, setCreatingNewTransaction] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState<string>(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>(null);

  const handleUpdateInvoice = () => {
    setUpdatingInvoice(true);
  };

  const handleAddNewPaymentDueDate = () => {
    setAddingNewPaymentDueDate(true);
    setSelectedDueDateId(undefined);
  };

  const handleSelectPaymentDueDate = (id: string) => {
    setSelectedDueDateId(id);
    setAddingNewPaymentDueDate(false);
  };

  const handleCreateNewTransaction = () => {
    setCreatingNewTransaction(true);
    setSelectedTransactionId(undefined);
  };

  const handleSelectTransaction = (id: string) => {
    setSelectedTransactionId(id);
    setCreatingNewTransaction(false);
  };

  const handleSelectedPayment = (id: string) => {
    setSelectedPaymentId(id);
    setCreatingNewTransaction(false);
  };

  const handleBackToAbout = () => {
    setUpdatingInvoice(false);
  };

  const handleBackToDueDates = () => {
    setAddingNewPaymentDueDate(false);
    setSelectedDueDateId(undefined);
  };

  const handleBackToTransactions = () => {
    setCreatingNewTransaction(false);
    setSelectedTransactionId(undefined);
    setSelectedPaymentId(undefined);
  };

  const navigateToInvoice = () => {
    setCurrentTab(InvoiceDrawerTab.ABOUT);
    handleBackToAbout();
  };

  const navigateToPaymentDueDates = () => {
    setCurrentTab(InvoiceDrawerTab.PAYMENT_DUE_DATES);
    handleBackToDueDates();
  };

  const navigateToPaymentTransactions = () => {
    setCurrentTab(InvoiceDrawerTab.PAYMENT_TRANSACTIONS);
    handleBackToTransactions();
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <QueryWrapper query={GET_PAYMENT_DUE_DATES} options={{ variables: { invoiceHeaderId: invoice?.id } }}>
        {(paymentDueDates: PaymentDueDate[]) => (
          <QueryWrapper query={GET_PAYMENT_TRANSACTIONS} options={{ variables: { invoiceHeaderId: invoice?.id } }}>
            {(paymentTransactions: PaymentTransaction[]) => (
              <>
                {!updatingInvoice &&
                  !addingNewPaymentDueDate &&
                  !selectedDueDateId &&
                  !creatingNewTransaction &&
                  !selectedTransactionId &&
                  !selectedPaymentId && (
                    <InvoiceDrawerOverview
                      invoice={invoice}
                      paymentDueDates={paymentDueDates}
                      paymentTransactions={paymentTransactions}
                      payments={payments}
                      onClose={onClose}
                      onUpdateInvoice={handleUpdateInvoice}
                      onAddNewPaymentDueDate={handleAddNewPaymentDueDate}
                      onSelectPaymentDueDate={handleSelectPaymentDueDate}
                      onCreateNewTransaction={handleCreateNewTransaction}
                      onSelectTransaction={handleSelectTransaction}
                      onSelectedPayment={handleSelectedPayment}
                      currentTab={currentTab}
                    />
                  )}
                {selectedDueDateId && (
                  <PaymentDueDateDetails
                    invoice={invoice}
                    paymentDueDate={paymentDueDates.find((dueDate) => dueDate.id === selectedDueDateId)}
                    onClose={onClose}
                    onBackClick={navigateToPaymentDueDates}
                  />
                )}
                {updatingInvoice && (
                  <UpdateInvoice invoice={invoice} onClose={onClose} onBackClick={navigateToInvoice} />
                )}
                {addingNewPaymentDueDate && (
                  <AddNewPaymentDueDate invoice={invoice} onClose={onClose} onBackClick={navigateToPaymentDueDates} />
                )}
                {selectedTransactionId && (
                  <PaymentTransactionsDetails
                    invoice={invoice}
                    paymentTransaction={paymentTransactions.find(
                      (transaction) => transaction.id === selectedTransactionId,
                    )}
                    onClose={onClose}
                    onBackClick={navigateToPaymentTransactions}
                  />
                )}
                {selectedPaymentId && (
                  <PaymentDetails
                    invoice={invoice}
                    payment={payments.find((payment) => payment.id === selectedPaymentId)}
                    onClose={onClose}
                    onBackClick={navigateToPaymentTransactions}
                  />
                )}
                {creatingNewTransaction && (
                  <CreateNewTransaction
                    onClose={onClose}
                    onBackClick={navigateToPaymentTransactions}
                    invoice={invoice}
                  />
                )}
              </>
            )}
          </QueryWrapper>
        )}
      </QueryWrapper>
    </DrawerModal>
  );
};

export default InvoiceDrawer;
