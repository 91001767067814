import { useUpdatePolicyAffiliationRule } from 'src/service/policy/UpdatePolicyAffiliationRule';
import { useDeletePolicyAffiliationRule } from 'src/service/policy/DeletePolicyAffiliationRule';
import { UpdatePolicyAffiliationRuleRequest } from 'src/types/policy/request/UpdatePolicyAffiliationRuleRequest';
import { DeletePolicyAffiliationRuleRequest } from 'src/types/policy/request/DeletePolicyAffiliationRuleRequest';
import { PolicyRuleType, RuleValueUnit } from 'src/types/policy/AffiliationRule';
import { RuleValue } from 'src/types/policy/RuleValue';

const useUpdateOrDeleteAffiliationRule = () => {
  const deletePolicyAffiliationRule = useDeletePolicyAffiliationRule();
  const updatePolicyAffiliationRule = useUpdatePolicyAffiliationRule();

  const updateOrDeleteAffiliationRule = (
    entityType: string,
    entityId: string,
    employerId: string,
    ruleBlockId: string,
    ruleId: string,
    policyRuleType: PolicyRuleType,
    newStateRuleValues: RuleValue[],
    ruleValueUnit?: RuleValueUnit,
    sourceType?: string,
    sourceReference?: string,
  ): void => {
    if (newStateRuleValues.length === 0) {
      const request: DeletePolicyAffiliationRuleRequest = {
        entityType,
        entityId,
        ruleId,
      };
      deletePolicyAffiliationRule(employerId, request);
    } else {
      const request: UpdatePolicyAffiliationRuleRequest = {
        entityType,
        entityId,
        ruleBlockId,
        rule: {
          id: ruleId,
          policyRuleType: policyRuleType,
          ruleValueUnit,
          sourceType,
          sourceReference,
          ruleValues: newStateRuleValues.map((ruleValue) => {
            return {
              id: ruleValue.id,
              value: ruleValue.value,
              valueType: ruleValue.valueType,
              condition: ruleValue.condition,
            };
          }),
        },
      };
      updatePolicyAffiliationRule(employerId, request);
    }
  };

  return updateOrDeleteAffiliationRule;
};

export default useUpdateOrDeleteAffiliationRule;
