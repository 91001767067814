import { FC, useMemo, useState } from 'react';
import InvoicesTable from '../InvoicesTable';
import SearchInvoicesFiltersModal from '../InvoicesTable/SearchInvoicesFiltersModal';
import { Button, Icon, Pagination, SortDirection } from 'fgirot-k2-ui-components';
import { Invoice } from 'src/types/invoice/Invoice';
import { Paging } from 'src/types/PageableResponse';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';
import InvoicesSearchInput from '../InvoicesSearchInput';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import './invoices.scss';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import When from 'src/components/Common/When';
import { InvoiceTableSortField } from '../InvoicesTable/InvoiceTableSortField';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { useTranslation } from 'react-i18next';
import InvoicesFilterDetails from '../InvoicesFilterDetails';

interface InvoicesProps {
  invoices: Invoice[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  sortProperty?: InvoiceTableSortField;
  sortDirection: SortDirection;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  searchInvoicesFilter: SearchInvoicesFilter;
  searchTextInput: string;
  searchAmountInput: string;
  onTextSearch: (searchInput: string) => void;
  onAmountSearch: (amountSearch: string) => void;
  onApplyFilters: (filters: SearchInvoicesFilter) => void;
  onApplySorting: (property: string, sortDirection: SortDirection) => void;
  onPageChange: (pageSize: number) => void;
}

const Invoices: FC<InvoicesProps> = ({
  invoices,
  paging,
  pageNumber,
  pageSize,
  sortProperty,
  sortDirection,
  searchInvoicesFilter,
  searchTextInput,
  searchAmountInput,
  setPageSize,
  onTextSearch,
  onAmountSearch,
  onApplyFilters,
  onApplySorting,
  onPageChange,
}) => {
  const customer = useCustomer();
  const { t } = useTranslation();
  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );
  const showPagination = totalPages > 0;

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const handleModalAction = () => setFiltersModalOpen(!filtersModalOpen);

  return (
    <div className="invoices">
      <div className="invoices__search-container">
        <div className="invoices__search-container__input">
          <InvoicesSearchInput
            onSearch={onTextSearch}
            searchInput={searchTextInput}
            placeHolder={t('invoice:search-invoices-filters.search-placeholder')}
            type={'text'}
          />
        </div>
        <div className="invoices__search-container__input">
          <InvoicesSearchInput
            onSearch={onAmountSearch}
            searchInput={searchAmountInput}
            placeHolder={t('invoice:search-invoices-filters.search-amount-placeholder')}
            type={'number'}
          />
        </div>
      </div>

      <InvoicesFilterDetails filters={searchInvoicesFilter} applyFilters={onApplyFilters} />

      <div className="invoices__table">
        <InvoicesTable
          invoices={invoices}
          applySorting={onApplySorting}
          sortingProperty={sortProperty}
          sortDirection={sortDirection}
        />
      </div>

      {filtersModalOpen && (
        <SearchInvoicesFiltersModal
          customer={customer}
          open={filtersModalOpen}
          onClose={handleModalAction}
          onApplyFilters={onApplyFilters}
          filters={searchInvoicesFilter}
        />
      )}
      <When condition={showPagination}>
        <div data-testid="invoices-pagination" className="invoices__pagination">
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            pageSizeLabel={t('common:results-per-page')}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={setPageSize}
            pageSizePosition="left"
          />
        </div>
      </When>
      <div className="invoices__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalAction}
          data-cy="filter-button"
        />
      </div>
    </div>
  );
};

export default Invoices;
