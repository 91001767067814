import { gql } from '@apollo/client';

export const GET_INSURANCE_EVENTS_SEARCH_VALUES = gql`
  query ($wageFilePeriod: String!) {
    insuranceEventsSelectableSearchValues(wageFilePeriod: $wageFilePeriod) {
      id
      employerIds
      insuranceProviders {
        id
        legalName
      }
      benefitTypes
      insuranceEventTypes
      reportingTypes
      insuranceEventStatuses
    }
  }
`;
