import { Banner, Button, Icon, ListGroup } from 'fgirot-k2-ui-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { Employee } from 'src/types/Employee';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';
import { CreateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/CreateEmployeeIndividualChoiceRequest';
import When from 'src/components/Common/When';
import {
  getRelevantFieldsForEmployeeIndividualChoice,
  employeeIndividualChoiceRequestIsValid,
} from 'src/util/IndividualChoiceUtil';
import IndividualChoiceField from '../IndividualChoiceField';
import { createOptions } from 'src/util/OptionUtil';
import { IndividualChoiceType } from 'src/types/employer/EmployerIndividualChoice';
import { Option } from 'fgirot-k2-ui-components';
import { EmployeeIndividualChoiceProperty } from 'src/types/employees/EmployeeIndividualChoice';
import './employee-individual-choice-create-drawer.scss';
import { useCreateDraftEmployeeIndividualChoice } from 'src/service/employee/CreateDraftEmployeeIndividualChoice';
import { useEmployerIndividualChoices } from 'src/service/employer/GetEmployerIndividualChoices';

interface EmployeeIndividualChoiceCreateDrawerProps {
  employee: Employee;
  onClose: () => void;
  onBackClick: () => void;
}

const EmployeeIndividualChoiceCreateDrawer: React.FC<EmployeeIndividualChoiceCreateDrawerProps> = ({
  employee,
  onClose,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const [individualChoiceRequest, setIndividualChoiceRequest] = useState<CreateEmployeeIndividualChoiceRequest>({
    employeeId: employee.id,
    type: null,
    fromPeriod: null,
    individualAge: null,
  });

  const createDraftEmployeeIndividualChoice = useCreateDraftEmployeeIndividualChoice();
  const service = useEmployerIndividualChoices(employee.employerId);

  const fullName = formatEmployeeFullName(employee);

  const relevantFields = useMemo(() => {
    return getRelevantFieldsForEmployeeIndividualChoice(individualChoiceRequest.type);
  }, [individualChoiceRequest.type]);

  const handleChange = (key: string, value: unknown) => {
    setIndividualChoiceRequest((prev) => ({ ...prev, [key]: value }));
  };

  const handleCreate = () => {
    let request = individualChoiceRequest;
    if (request.individualAge) {
      request = { ...request, individualAge: +request.individualAge };
    }
    createDraftEmployeeIndividualChoice(request, employee.employerId).then(() => {
      onBackClick();
    });
  };

  const employerIndividualChoices = service.status === 'loaded' ? service.payload : [];

  const individualChoiceTypeOptions: Option<IndividualChoiceType>[] =
    employerIndividualChoices?.length > 0
      ? createOptions(
          employerIndividualChoices.map((choice) => choice.type),
          t,
          'individualChoiceTypes',
        )
      : [];

  const resetRequestWhenTypeChanged = () => {
    Object.keys(individualChoiceRequest).forEach((key) => {
      if (key !== 'employeeId' && key !== 'type') {
        if (!relevantFields.includes(key as EmployeeIndividualChoiceProperty)) {
          setIndividualChoiceRequest((prev) => ({ ...prev, [key]: null }));
        }
      }
    });
  };

  useEffect(() => {
    resetRequestWhenTypeChanged();
  }, [individualChoiceRequest.type]);

  const saveButtonDisabled = useMemo(() => {
    return !employeeIndividualChoiceRequestIsValid(
      relevantFields,
      individualChoiceRequest,
      employee.employmentStartDate,
    );
  }, [relevantFields, individualChoiceRequest]);

  return (
    <DrawerBase
      title={t('employees:individual-choices.add-choice')}
      subTitle={fullName}
      onClose={onClose}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
    >
      <When condition={service.status === 'loaded' && employerIndividualChoices.length === 0}>
        <Banner type="warning" message={t('employees:individual-choices.no-employer-individual-choices')} />
      </When>
      <When condition={employerIndividualChoices.length > 0}>
        <div className="employee-individual-choice-create-drawer">
          <ListGroup variant="inline-edit">
            <IndividualChoiceField
              property="type"
              individualChoice={individualChoiceRequest}
              options={individualChoiceTypeOptions}
              onChange={handleChange}
              value={individualChoiceRequest.type}
            />
            <When condition={!!individualChoiceRequest.type}>
              {relevantFields.map((field) => (
                <IndividualChoiceField
                  key={field}
                  property={field}
                  individualChoice={individualChoiceRequest}
                  value={individualChoiceRequest[field]}
                  onChange={handleChange}
                />
              ))}
            </When>
          </ListGroup>
          <Button
            type="primary"
            label={t('employees:individual-choices.add-choice')}
            disabled={saveButtonDisabled}
            onClick={handleCreate}
          />
        </div>
      </When>
    </DrawerBase>
  );
};

export default EmployeeIndividualChoiceCreateDrawer;
