import { InlineEdit, ListGroup, Option, Select, Switch } from 'fgirot-k2-ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeletePglRuleBonusComponent } from 'src/service/policy/DeletePglRuleBonusComponent';
import { useUpsertPglRuleBonusComponent } from 'src/service/policy/UpsertPglRuleBonusComponent';
import { PglBonusComponent, StartDateBasis } from 'src/types/policy/PglBonusComponent';
import { PglBonusComponentRequest } from 'src/types/policy/request/PglBonusComponentRequest';
import CalculationButtons from '../CalculationButtons';
import { useBonusCalculationOptions } from './useBonusCalculationOptions';
import { Month } from 'src/types/Month';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';

interface BonusCalculationProps {
  employerId: string;
  selectedWageType: Option<EmployerWageType>;
  pglBonusComponent?: PglBonusComponent;
  inDraft: boolean;
  policyId: string;
  pglRuleId: string;
  onComplete: () => void;
}

const BonusCalculation = ({
  employerId,
  selectedWageType,
  inDraft,
  pglBonusComponent,
  policyId,
  pglRuleId,
  onComplete,
}: BonusCalculationProps) => {
  const { t } = useTranslation(['common', 'account']);
  const deletePglRuleBonusComponent = useDeletePglRuleBonusComponent();
  const isCreating = pglBonusComponent?.id === undefined;
  const [loading, setLoading] = useState(false);
  const upsertPglRuleBonusComponent = useUpsertPglRuleBonusComponent();
  const [pglBonusComponentData, setPglBonusComponentData] = useState({
    numberOfYearsToInclude: pglBonusComponent?.numberOfYearsToInclude,
    includePresentYear: pglBonusComponent?.includePresentYear ? true : false,
    recalculationFromMonth: pglBonusComponent?.recalculationFromMonth,
    recalculationToMonth: pglBonusComponent?.recalculationToMonth,
    applyRetroactivelyFromMonth: pglBonusComponent?.applyRetroactivelyFromMonth,
    startDateBasis: pglBonusComponent?.successiveBonusSpecificSettings?.startDateBasis,
    numberOfYearsOfHistory: pglBonusComponent?.successiveBonusSpecificSettings?.numberOfYearsOfHistory || null,
  });
  const {
    numberOfYearsOptions,
    recalculationFromMonthOptions,
    recalculationToMonthOptions,
    applyRetroactivelyFromMonthOptions,
    startDateBasisOptions,
    numberOfYearsOfHistoryOptions,
  } = useBonusCalculationOptions(
    pglBonusComponentData.numberOfYearsOfHistory,
    pglBonusComponentData.recalculationFromMonth,
  );

  const fieldsEmptyWhenStraightBonus = !pglBonusComponentData.numberOfYearsToInclude;
  const fieldsEmptyWhenSuccessiveBonus =
    !pglBonusComponentData.numberOfYearsToInclude || !pglBonusComponentData.startDateBasis;
  const fieldsEmptyOrInvalid =
    selectedWageType.value.wageType === 'SUCCESSIVE_BONUS'
      ? fieldsEmptyWhenSuccessiveBonus
      : fieldsEmptyWhenStraightBonus;

  const handleNumberOfYearsToIncludeChange = (option: Option<number>) => {
    setPglBonusComponentData({ ...pglBonusComponentData, numberOfYearsToInclude: option.value });
  };

  const handleIncludePresentYearChange = () => {
    setPglBonusComponentData({
      ...pglBonusComponentData,
      includePresentYear: !pglBonusComponentData.includePresentYear,
    });
  };

  const handleRecalculationFromMonthChange = (option: Option<Month>) => {
    setPglBonusComponentData((prev) => ({ ...prev, recalculationFromMonth: option.value }));
  };

  const handleRecalculationToMonthChange = (option: Option<Month>) => {
    setPglBonusComponentData((prev) => ({ ...prev, recalculationToMonth: option.value }));
  };

  const handleApplyRetroactivelyFromMonthChange = (option: Option<string>) => {
    setPglBonusComponentData((prev) => ({
      ...prev,
      applyRetroactivelyFromMonth: option.value,
    }));
  };

  const handleStartDateBasisChange = (option: Option<StartDateBasis>) => {
    setPglBonusComponentData((prev) => ({
      ...prev,
      startDateBasis: option.value,
    }));
  };

  const handleNumberOfYearsOfHistoryChange = (option: Option<number>) => {
    setPglBonusComponentData((prev) => ({
      ...prev,
      numberOfYearsOfHistory: option.value,
    }));
  };

  const handleRemove = () => {
    setLoading(true);
    deletePglRuleBonusComponent(policyId, pglRuleId, pglBonusComponent.id, employerId).finally(() => {
      onComplete();
      setLoading(false);
    });
  };

  const handleCreateOrUpdate = () => {
    const request: PglBonusComponentRequest = {
      policyId: policyId,
      pglRuleId: pglRuleId,
      employerWageTypeId: selectedWageType.value.id,
      bonusAverageType: selectedWageType.value.wageType === 'SUCCESSIVE_BONUS' ? 'SUCCESSIVE' : 'STRAIGHT',
      numberOfYearsToInclude: pglBonusComponentData.numberOfYearsToInclude,
      includePresentYear: pglBonusComponentData.includePresentYear,
      id: pglBonusComponent?.id ?? null,
      recalculationFromMonth: pglBonusComponentData.recalculationFromMonth ?? null,
      recalculationToMonth: pglBonusComponentData.recalculationToMonth ?? null,
      applyRetroactivelyFromMonth: pglBonusComponentData.applyRetroactivelyFromMonth ?? null,
    };

    if (selectedWageType.value.wageType === 'SUCCESSIVE_BONUS') {
      request.successiveBonusSpecificSettings = {
        startDateBasis: pglBonusComponentData.startDateBasis ?? null,
        numberOfYearsOfHistory: pglBonusComponentData.numberOfYearsOfHistory ?? null,
      };
    }

    setLoading(true);
    upsertPglRuleBonusComponent(employerId, request)
      .then(() => {
        onComplete();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!pglBonusComponentData.includePresentYear) {
      setPglBonusComponentData({
        ...pglBonusComponentData,
        recalculationFromMonth: null,
        recalculationToMonth: null,
        applyRetroactivelyFromMonth: null,
      });
    }
  }, [pglBonusComponentData.includePresentYear]);

  return (
    <>
      <ListGroup variant="inline-edit">
        <InlineEdit label={`${t('account:pgl-rules:drawer:bonus:number-of-years:title')}*`} bold>
          <Select
            placeholder={t('common:select')}
            options={numberOfYearsOptions}
            selected={numberOfYearsOptions.find(
              (option) => option.value === pglBonusComponentData.numberOfYearsToInclude,
            )}
            onChange={handleNumberOfYearsToIncludeChange}
            disabled={!inDraft || loading}
            small
          />
        </InlineEdit>
        <InlineEdit label={t('account:pgl-rules:drawer:bonus:include-present-year')} bold>
          <Switch toggled={pglBonusComponentData.includePresentYear} onChange={handleIncludePresentYearChange} />
        </InlineEdit>
        <InlineEdit label={t('account:pgl-rules:drawer:bonus:recalculation-from-month')} bold>
          <Select
            placeholder={t('common:select')}
            alphabetical={false}
            options={recalculationFromMonthOptions}
            selected={recalculationFromMonthOptions.find(
              (option) => option.value === pglBonusComponentData.recalculationFromMonth,
            )}
            onChange={handleRecalculationFromMonthChange}
            disabled={!inDraft || loading}
            small
          />
        </InlineEdit>
        <InlineEdit label={t('account:pgl-rules:drawer:bonus:recalculation-to-month')} bold>
          <Select
            placeholder={t('common:select')}
            options={recalculationToMonthOptions}
            selected={recalculationToMonthOptions.find(
              (option) => option.value === pglBonusComponentData.recalculationToMonth,
            )}
            onChange={handleRecalculationToMonthChange}
            disabled={!inDraft || !pglBonusComponentData.includePresentYear || loading}
            small
          />
        </InlineEdit>
        <InlineEdit label={t('account:pgl-rules:drawer:bonus:apply-retroactively-from-month')} bold>
          <Select
            placeholder={t('common:select')}
            options={applyRetroactivelyFromMonthOptions}
            selected={applyRetroactivelyFromMonthOptions.find(
              (option) => option.value === pglBonusComponentData.applyRetroactivelyFromMonth,
            )}
            onChange={handleApplyRetroactivelyFromMonthChange}
            disabled={!inDraft || loading}
            small
          />
        </InlineEdit>
        {selectedWageType.value.wageType === 'SUCCESSIVE_BONUS' && (
          <InlineEdit label={`${t('account:pgl-rules:drawer:bonus:start-date-basis')}*`} bold>
            <Select
              placeholder={t('common:select')}
              options={startDateBasisOptions}
              selected={startDateBasisOptions.find((option) => option.value === pglBonusComponentData.startDateBasis)}
              onChange={handleStartDateBasisChange}
              disabled={!inDraft || loading}
              small
            />
          </InlineEdit>
        )}
        {selectedWageType.value.wageType === 'SUCCESSIVE_BONUS' && (
          <InlineEdit label={`${t('account:pgl-rules:drawer:bonus:number-of-years-of-history:title')}*`} bold>
            <Select
              placeholder={t('common:select')}
              options={numberOfYearsOfHistoryOptions}
              selected={numberOfYearsOfHistoryOptions.find(
                (option) => option.value === pglBonusComponentData.numberOfYearsOfHistory,
              )}
              onChange={handleNumberOfYearsOfHistoryChange}
              disabled={!inDraft || loading}
              small
            />
          </InlineEdit>
        )}
      </ListGroup>
      <CalculationButtons
        inDraft={inDraft}
        isCreating={isCreating}
        handleRemove={handleRemove}
        handleCreateOrUpdate={handleCreateOrUpdate}
        fieldsEmptyOrInvalid={fieldsEmptyOrInvalid}
        loading={loading}
      />
    </>
  );
};

export default BonusCalculation;
