import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import { getEmploymentGroupNamesForBenefit } from 'src/util/PolicyUtil';
import PolicyBenefitRulesDrawerTab from './PolicyBenefitRulesDrawerTab';
import ConnectionDrawerTab from './ConnectionDrawerTab';
import AgreedProductDrawerTab from './AgreedProductDrawerTab';
import './policy-benefit-overview.scss';
import { PolicyBenefitConnectionRuleType } from 'src/types/policy/PolicyBenefitConnectionRule';
import DrawerBase from 'src/components/Common/DrawerBase';
import PolicyBenefitCalculationDrawerTab from './PolicyBenefitCalculationDrawerTab';
import { BenefitCalculationType } from './PolicyBenefitCalculationDrawerTab/PolicyBenefitCalculationDrawerTab';
import { PolicyBenefitDrawerTab } from 'src/types/tabs/PolicyBenefitDrawerTab';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';

interface PolicyBenefitOverviewProps {
  employerId: string;
  policyEntityState: PolicyState;
  policyBenefit: PolicyBenefit;
  inDraft: boolean;
  onDisplayAgreedProductDetailDrawer: (selectedAgreedProductId: string) => void;
  onDisplayAffiliationRuleDetailDrawer: (
    affiliationRuleGroupType: AffiliationRuleGroupType,
    ruleBlockId: string,
  ) => void;
  onDisplayConnectionRuleDetailDrawer: (connectionRuleType: PolicyBenefitConnectionRuleType) => void;
  onDisplayEmploymentGroupConnectionDrawer: (benefitId: string) => void;
  onDisplayCalculationPglDetailDrawer: (type: BenefitCalculationType) => void;
  onDisplayAddAgreedProduct: () => void;
  onClose: () => void;
  currentTab: PolicyBenefitDrawerTab;
  onTabChange: (tabIndex: number) => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}

const PolicyBenefitOverview: FunctionComponent<PolicyBenefitOverviewProps> = ({
  employerId,
  policyEntityState,
  policyBenefit,
  inDraft,
  onDisplayAgreedProductDetailDrawer,
  onDisplayAffiliationRuleDetailDrawer,
  onDisplayConnectionRuleDetailDrawer,
  onDisplayEmploymentGroupConnectionDrawer,
  onDisplayCalculationPglDetailDrawer,
  onDisplayAddAgreedProduct,
  onClose,
  currentTab,
  onTabChange,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerBase
      onClose={onClose}
      title={policyBenefit.name}
      subTitle={
        <div className="sub-title-pill">
          <Pill label={t(`benefitTypes:abbreviations.${policyBenefit.benefitType}`)} />
          <Typography variant="body2">{getEmploymentGroupNamesForBenefit(policyBenefit)}</Typography>
        </div>
      }
    >
      <Tabs tabIndex={currentTab} onChange={onTabChange}>
        <Tab title={t('account:employers-tab.policy-settings.table.drawer.product')}>
          <AgreedProductDrawerTab
            agreedProducts={policyBenefit.agreedProducts || []}
            benefitType={policyBenefit.benefitType}
            onDisplayAgreedProductDetailDrawer={onDisplayAgreedProductDetailDrawer}
            onAddAgreedProduct={onDisplayAddAgreedProduct}
            inDraft={inDraft}
            data-cy="policy-benefit-overview-product-tab"
          />
        </Tab>
        <Tab title={t('account:employers-tab.policy-settings.table.drawer.belonging')}>
          <PolicyBenefitRulesDrawerTab
            employerId={employerId}
            policyEntityState={policyEntityState}
            policyBenefit={policyBenefit}
            onDisplayAffiliationRuleDetailDrawer={onDisplayAffiliationRuleDetailDrawer}
            onDisplayEmploymentGroupConnectionDrawer={onDisplayEmploymentGroupConnectionDrawer}
            employerIndividualChoices={employerIndividualChoices}
          />
        </Tab>
        <Tab title={t('account:employers-tab.policy-settings.table.drawer.connection')}>
          <ConnectionDrawerTab
            connectionRule={policyBenefit.connectionRule}
            onDisplayConnectionRuleDetailDrawer={onDisplayConnectionRuleDetailDrawer}
          />
        </Tab>
        <Tab
          title={t('account:employers-tab.policy-settings.table.drawer.calculation')}
          data-cy="policy-benefit-overview-calculation-button"
        >
          <PolicyBenefitCalculationDrawerTab
            policyBenefit={policyBenefit}
            onDisplayCalculationPglDetailDrawer={onDisplayCalculationPglDetailDrawer}
          />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default PolicyBenefitOverview;
