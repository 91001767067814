import { Button, Card, CardAction, Modal, Select, Switch, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_GLOBAL_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employer/GetGlobalIndividualChoices';
import { GlobalIndividualChoice } from 'src/types/employer/GlobalIndividualChoice';
import { EmployerIndividualChoiceRequest } from 'src/types/employer/request/EmployerIndividualChoiceRequest';
import './add-employer-individual-choice-modal.scss';
import InfoMessage from 'src/components/Common/InfoMessage';
import { useCreateEmployerIndividualChoice } from 'src/service/employer/CreateEmployerIndividualChoice';

interface AddEmployerIndividualChoiceModalProps {
  open: boolean;
  handleClose: () => void;
  employerId: string;
}

function AddEmployerIndividualChoiceModal({ open, handleClose, employerId }: AddEmployerIndividualChoiceModalProps) {
  const [createRequest, setCreateRequest] = React.useState<EmployerIndividualChoiceRequest>({
    employerId,
    type: undefined,
    fromWageFile: false,
    fromInvoice: false,
  });

  const createEmployerIndividualChoice = useCreateEmployerIndividualChoice();

  const { t } = useTranslation();

  const handleAddEmployerIndividualChoice = async () => {
    await createEmployerIndividualChoice(createRequest);
    handleClose();
  };

  const handleChange = (key: string, value: string | boolean) => {
    setCreateRequest((prev) => ({ ...prev, [key]: value }));
  };

  const addButtonDisabled = !createRequest.type;

  return (
    <Modal
      centered
      overlayBackground
      open={open}
      onClose={handleClose}
      className="add-employer-individual-choice-modal"
    >
      <Card
        size="narrow"
        title={t('account:employers-tab.reporting-settings.individual-choices-tab.add-employer-individual-choice')}
        defaultClose
        onClose={handleClose}
      >
        <section className="add-employer-individual-choice-modal__inputs">
          <QueryWrapper type="list" query={GET_GLOBAL_INDIVIDUAL_CHOICES}>
            {(globalIndividualChoices: GlobalIndividualChoice[]) => {
              const globalIndividualChoicesOptions = globalIndividualChoices.map((choice) => ({
                label: t(`individualChoiceTypes:${choice.type}`),
                value: choice.type,
              }));
              return (
                <Select
                  options={globalIndividualChoicesOptions}
                  selected={globalIndividualChoicesOptions.find((option) => option.value === createRequest.type)}
                  placeholder={t('common:select')}
                  label={t('account:employers-tab.reporting-settings.individual-choices-tab.individual-choice-type')}
                  onChange={(opt) => handleChange('type', opt.value)}
                  outlined
                  small
                  allowSelectMaxWidth
                />
              );
            }}
          </QueryWrapper>
          <div className="add-employer-individual-choice-modal__switches">
            <label className="add-employer-individual-choice-modal__switch">
              <Typography variant="caption">
                {t('account:employers-tab.reporting-settings.individual-choices-tab.from-wage-file')}
              </Typography>
              <Switch
                toggled={createRequest.fromWageFile}
                onChange={(event) => handleChange('fromWageFile', event.target.checked)}
              />
            </label>
            <label className="add-employer-individual-choice-modal__switch">
              <Typography variant="caption">
                {t('account:employers-tab.reporting-settings.individual-choices-tab.from-invoice')}
              </Typography>
              <Switch
                toggled={createRequest.fromInvoice}
                onChange={(event) => handleChange('fromInvoice', event.target.checked)}
              />
            </label>
          </div>
        </section>
        <InfoMessage
          message={t(
            'account:employers-tab.reporting-settings.individual-choices-tab.add-employer-individual-choice-info',
          )}
        />
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={handleClose} />
          <Button
            disabled={addButtonDisabled}
            type="primary"
            label={t('common:add')}
            onClick={handleAddEmployerIndividualChoice}
            data-cy="add-employer-individual-choice-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
}

export default AddEmployerIndividualChoiceModal;
