import { useApolloMutation } from '../graphql/Mutation';
import { LOCK_USER } from 'src/graphql/schema/mutation/user/LockUser';
import { UNLOCK_USER } from 'src/graphql/schema/mutation/user/UnlockUser';
import { GET_USER } from 'src/graphql/schema/query/user/GetUser';

export const useLockUser = () => {
  const apolloMutation = useApolloMutation();
  const lockUser = (userId: string) => apolloMutation(LOCK_USER, '', { userId }, [GET_USER]);
  return lockUser;
};

export const useUnlockUser = () => {
  const apolloMutation = useApolloMutation();
  const unlockUser = (userId: string) => apolloMutation(UNLOCK_USER, '', { userId }, [GET_USER]);
  return unlockUser;
};
