import { CREATE_ACCOUNT } from 'src/graphql/schema/mutation/payment/CreateAccount';
import { PATCH_ACCOUNT } from 'src/graphql/schema/mutation/payment/PatchAccount';
import { GET_ACTIVE_ACCOUNT } from 'src/graphql/schema/query/payment/GetActiveAccount';
import { CreateAccountInput, PatchAccountInput } from 'src/types/payment/PaymentAccount';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_ACCOUNTS } from 'src/graphql/schema/query/payment/GetAccounts';

export const useCreateAccount = () => {
  const apolloMutation = useApolloMutation();
  const createAccount = (request: CreateAccountInput, employerId) =>
    apolloMutation(CREATE_ACCOUNT, employerId, { request }, [GET_ACTIVE_ACCOUNT, GET_ACCOUNTS]);
  return createAccount;
};

export const usePatchAccount = () => {
  const apolloMutation = useApolloMutation();
  const patchAccount = (request: PatchAccountInput, employerId) =>
    apolloMutation(PATCH_ACCOUNT, employerId, { request }, [GET_ACTIVE_ACCOUNT, GET_ACCOUNTS]);
  return patchAccount;
};
