import { DocumentNode, useSubscription } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Checkpoint } from 'src/types/Checkpoint';
import { ReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { Service } from 'src/types/Service';
import { LOADED } from 'src/types/Status';
import { unique } from 'src/util/ArrayUtil';
import { fetchEmployee } from '../employee/GetEmployee';
import { fetchEmployeeCard } from '../employeecard/GetEmployeeCardQuery';

export const useCheckpointSubscription = <T extends ReportEmployeeResponse>(
  subscription: DocumentNode,
  employerIds: string[],
  wageFilePeriod: string,
  setResult: Dispatch<SetStateAction<Service<T[]>>>,
) => {
  const { data } = useSubscription(subscription, {
    variables: { employerIds, wageFilePeriod },
    fetchPolicy: 'no-cache',
    skip: !employerIds || employerIds.length === 0,
  });

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const checkpoint = data.checkpoint;
      if (checkpoint.checkpointStatus === 'RAISED') {
        createResponseObject<T>(checkpoint).then((resObject) => {
          setResult((prev) => {
            if (prev.status === LOADED) {
              const inList = prev.payload.some((responses) => responses.employee.id === resObject.employee.id);
              return {
                ...prev,
                payload: inList
                  ? prev.payload.map((response) =>
                      response.employee.id === resObject.employee.id
                        ? {
                            ...resObject,
                            checkpoints: unique([...resObject.checkpoints, ...response.checkpoints], 'id'),
                          }
                        : response,
                    )
                  : [...prev.payload, resObject],
              };
            } else {
              return prev;
            }
          });
        });
      } else {
        setResult((prev) => {
          return prev.status === LOADED
            ? { ...prev, payload: handleCheckpointSubscription(prev.payload, checkpoint) }
            : prev;
        });
      }
    }
  }, [data]);
};

const createResponseObject = async <T extends ReportEmployeeResponse>(checkpoint: Checkpoint): Promise<T> => {
  const employee = await fetchEmployee(checkpoint.employeeId);
  const employeeCard = await fetchEmployeeCard(checkpoint.additionalData.employeeCardId);

  return { employee, employeeCard, checkpoints: [checkpoint] } as T;
};

const handleCheckpointSubscription = <T extends ReportEmployeeResponse>(
  reportEmployees: T[],
  checkpoint: Checkpoint,
): T[] => {
  return reportEmployees.reduce((previousValue: T[], thisResponse: T) => {
    // Find correct employee
    if (thisResponse.employee.id === checkpoint.employeeId) {
      const employee = {
        ...thisResponse,
        checkpoints: updateCheckpointList(checkpoint, thisResponse.checkpoints),
      };
      if (employee.checkpoints.length > 0) {
        // Employee has more than one checkpoint
        previousValue.push(employee);
      }
      return previousValue;
    }

    previousValue.push(thisResponse);
    return previousValue;
  }, []);
};

const updateCheckpointList = (checkpoint: Checkpoint, checkpoints: Checkpoint[]): Checkpoint[] => {
  if (checkpoint.finalized) {
    return checkpoints.filter((thisCheckpoint) => thisCheckpoint.id !== checkpoint.id);
  }
  const checkpointIndex = checkpoints.findIndex((thisCheckpoint) => thisCheckpoint.id === checkpoint.id);
  if (checkpointIndex === -1) {
    return [...checkpoints, checkpoint];
  }
  return checkpoints.map((thisCheckpoint) => {
    return thisCheckpoint.id !== checkpoint.id ? thisCheckpoint : checkpoint;
  });
};
