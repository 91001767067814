import { Button, Copy, Icon } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import { DRAFT, PolicyEntity } from 'src/types/policy/PolicyEntity';
import { PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';
import DeletePolicyDraftModal from '../../DeletePolicyDraftModal';
import CreateDraftPolicyModal from '../../CreateDraftPolicyModal';
import PolicyVersionsDrawer from '../../PolicyVersionsDrawer';
import { useNavigate, useParams } from 'react-router-dom';
import PublishPolicyDraftModal from '../../PublishPolicyDraftModal';
import { useTranslation } from 'react-i18next';

interface PolicyActionsProps {
  selectedPolicyEntity: PolicyEntity;
  policyEntities: PolicyEntity[];
  changes: PolicyVersionChanges;
}

const PolicyActions: React.FunctionComponent<PolicyActionsProps> = ({
  selectedPolicyEntity,
  policyEntities,
  changes,
}: PolicyActionsProps) => {
  const { employerId, customerName } = useParams();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createDraftModalOpen, setCreateDraftModalOpen] = useState(false);
  const [publishDraftModalOpen, setPublishDraftModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);

  const handleOpenPublishDraftModal = () => setPublishDraftModalOpen(true);

  const handleOpenCreateDraftModal = () => setCreateDraftModalOpen(true);

  const handleCloseCreateDraftModal = () => setCreateDraftModalOpen(false);

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleClosePublishDraftModal = () => setPublishDraftModalOpen(false);

  const handleOpenModal = () => setModalOpen(true);

  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <div className="policy-version-header__actions">
        {selectedPolicyEntity && selectedPolicyEntity.state === DRAFT && (
          <>
            <Button
              className="policy-version-header__actions--button"
              label={t('account:employers-tab.policy-settings.policy-version-header.publish')}
              onClick={handleOpenPublishDraftModal}
              data-cy="policy-actions__publish-button"
            />
            <Button
              className="policy-version-header__actions--button"
              size="small"
              type="secondary"
              icon={<Icon type="Trash" />}
              onClick={handleOpenDeleteModal}
              data-cy="policy-actions__delete-button"
            />
          </>
        )}
        {policyEntities.some((policyEntity) => policyEntity.state === DRAFT) &&
          selectedPolicyEntity?.state !== DRAFT && (
            <Button
              type="secondary"
              className="policy-version-header__actions--button"
              icon={<Copy size="small" alert />}
              label={t('account:employers-tab.policy-settings.policy-version-header.ongoing-draft')}
              onClick={() =>
                navigate(
                  `/account/${customerName}/${employerId}/policy-settings/${
                    policyEntities.find((policyEntity) => policyEntity.state === DRAFT)?.id
                  }`,
                )
              }
            />
          )}
        {!policyEntities.some((policyEntity) => policyEntity.state === DRAFT) && (
          <Button
            type="secondary"
            className="policy-version-header__actions--button"
            label={t('account:employers-tab.policy-settings.policy-version-header.create-new-draft')}
            onClick={handleOpenCreateDraftModal}
            data-cy="policy-actions__create-button"
          />
        )}
        <Button
          size="small"
          type="secondary"
          className="policy-version-header__actions--button"
          icon={<Icon type="Before" stroke="#091e42" />}
          label={policyEntities.length.toLocaleString()}
          onClick={handleOpenModal}
        />
      </div>
      <DeletePolicyDraftModal
        changes={changes}
        open={deleteModalOpen}
        handleClose={handleCloseDeleteModal}
        policyEntities={policyEntities}
        policyId={selectedPolicyEntity && selectedPolicyEntity.id}
      />
      <PublishPolicyDraftModal open={publishDraftModalOpen} changes={changes} onClose={handleClosePublishDraftModal} />
      <CreateDraftPolicyModal
        open={createDraftModalOpen}
        handleClose={handleCloseCreateDraftModal}
        employerId={employerId}
      />
      <PolicyVersionsDrawer policyEntities={policyEntities} open={modalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default PolicyActions;
