import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import When from 'src/components/Common/When';
import { SearchEmployeesFilters, SearchEmployeesRequest } from 'src/types/employees/SearchEmployeesRequest';
import './employees-filter-details.scss';

interface EmployeesFilterDetailsProps {
  searchRequest: SearchEmployeesRequest;
  applyFilters: (filters: SearchEmployeesFilters) => void;
}

export const EmployeesFilterDetails: FC<EmployeesFilterDetailsProps> = ({ searchRequest, applyFilters }) => {
  const { t } = useTranslation();
  const hasEmployerIdsFilter = searchRequest.filters?.employerFilter?.employerIds.length > 0;
  const hasEmployementNumberFilter = !!searchRequest.filters?.employmentNumberFilter;

  const removeEmployerIdsFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      employerFilter: {
        employerIds: [],
      },
    });
  };

  const removeEmploymentNumberFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      employmentNumberFilter: null,
    });
  };

  return (
    <div className="employees-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      <When condition={!hasEmployerIdsFilter && !hasEmployementNumberFilter}>
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      </When>
      <When condition={hasEmployerIdsFilter}>
        <FilterButton
          label={
            searchRequest.filters?.employerFilter?.employerIds.length + ' ' + t('employees:search-filters.employer')
          }
          onClick={removeEmployerIdsFilter}
        />
      </When>
      <When condition={hasEmployementNumberFilter}>
        <FilterButton
          label={t(`employees:employment-number-filter.${searchRequest.filters?.employmentNumberFilter}`)}
          onClick={removeEmploymentNumberFilter}
        />
      </When>
    </div>
  );
};

export default EmployeesFilterDetails;
