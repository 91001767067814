import { Icon, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import './error-message.scss';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <div className="error-message">
      <Icon className="error-message__icon" type="Alert" />
      <div className="error-message__content">
        <Typography className="error-message__text" variant="body2">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default ErrorMessage;
