import { ListItem } from 'fgirot-k2-ui-components';
import { Employee } from 'src/types/Employee';
import './employee-search-result.scss';
import SingleEmploymentDetails from './SingleEmploymentDetails/SingleEmploymentDetails';
import MultipleEmploymentDetails from './MultipleEmploymentsDetails/MultipleEmploymentsDetails';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';

interface EmployeeSearchResultProps {
  employeeData: Employee[];
}

const EmployeeSearchResult = ({ employeeData }: EmployeeSearchResultProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fullName = formatEmployeeFullName(employeeData[0]);
  const hasMoreThanOneEmployment = employeeData.length > 1;
  const navigateToEmployee = (uuid: string) => navigate(`/employees/${uuid}`);

  return (
    <ListItem
      data-cy="employee-search-result"
      onClick={hasMoreThanOneEmployment ? undefined : () => navigateToEmployee(employeeData[0].id)}
      className="employee-search-result"
      buttonLabel={t('employees:search-tab.details.navigate-to-detail-page')}
      header={fullName}
    >
      {hasMoreThanOneEmployment ? (
        <MultipleEmploymentDetails employmentData={employeeData} navigateToEmployee={navigateToEmployee} />
      ) : (
        <SingleEmploymentDetails employmentData={employeeData[0]} />
      )}
    </ListItem>
  );
};

export default EmployeeSearchResult;
