import { DEFAULT_PAGE_SIZE } from 'src/tests/constants';
import { SearchEmployeesRequest } from 'src/types/employees/SearchEmployeesRequest';

export const getInitialSearchEmployeeRequest = (): SearchEmployeesRequest => {
  return {
    pageNumber: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    searchInput: '',
    filters: {
      employerFilter: {
        employerIds: [],
      },
      employmentNumberFilter: null,
    },
  };
};

export const isSearchEmployeeRequestChanged = (a: SearchEmployeesRequest, b: SearchEmployeesRequest) =>
  (a === undefined && b !== undefined) ||
  a.searchInput !== b.searchInput ||
  JSON.stringify(a.filters) !== JSON.stringify(b.filters);

export const isSearchEmployeeRequestValid = (request: SearchEmployeesRequest) => {
  if (request.searchInput?.length < 2) {
    return request.filters?.employerFilter?.employerIds.length > 0 && request.searchInput === '*';
  }
  return true;
};

export const searchEmployeeHasActiveFilters = (request: SearchEmployeesRequest) => {
  return request.filters?.employerFilter?.employerIds.length > 0 || request.filters?.employmentNumberFilter?.length > 0;
};
