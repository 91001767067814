import { Typography, Button, Icon } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DRAFT, PolicyState } from 'src/types/policy/PolicyEntity';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import { AffiliationRuleGroupType, employmentGroupRuleGroups } from 'src/types/policy/GroupedAffiliationRules';
import ScrollBase from 'src/components/Common/ScrollBase';
import { useCreateEmploymentGroupRuleBlock } from 'src/service/policy/CreateRuleBlock';
import RuleBlocks from 'src/components/Common/RuleBlocks';
import { useDeleteEmploymentGroupRuleBlock } from 'src/service/policy/DeleteRuleBlock';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';

interface PolicyEmploymentGroupRulesTabProps {
  employerId: string;
  policyEntityState: PolicyState;
  policyEmploymentGroup: PolicyEmploymentGroup;
  onDisplayRuleTypeDrawer: (affiliationRuleGroupType: AffiliationRuleGroupType, ruleBlockId: string) => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}

const PolicyEmploymentGroupRulesTab: React.FunctionComponent<PolicyEmploymentGroupRulesTabProps> = ({
  employerId,
  policyEntityState,
  policyEmploymentGroup,
  onDisplayRuleTypeDrawer,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();
  const createEmploymentGroupRuleBlock = useCreateEmploymentGroupRuleBlock();
  const deleteEmploymentGroupRuleBlock = useDeleteEmploymentGroupRuleBlock();

  const handleNewRuleBlock = () => createEmploymentGroupRuleBlock(employerId, policyEmploymentGroup.id);

  const handleDeleteRuleBlock = (ruleBlockId: string) => deleteEmploymentGroupRuleBlock(ruleBlockId, employerId);

  return (
    <div className="policy-employment-group-drawer__tabs__tab">
      <Typography variant="body2" component="span">
        {t('policySettings:employment-group-drawer.intro-text')}
      </Typography>
      <ScrollBase maxHeightPercentage={60}>
        <RuleBlocks
          employerId={employerId}
          ruleGroupTypes={employmentGroupRuleGroups}
          affiliationRuleBlocks={policyEmploymentGroup.affiliationRuleBlocks}
          policyState={policyEntityState}
          handleDeleteRuleBlock={handleDeleteRuleBlock}
          onDisplayRuleTypeDrawer={onDisplayRuleTypeDrawer}
          employerIndividualChoices={employerIndividualChoices}
        />
      </ScrollBase>
      {policyEntityState === DRAFT && (
        <div className="policy-employment-group-drawer__tabs__or-container">
          <Button
            icon={<Icon className="policy-employment-group-drawer__tabs__or-container__button--icon" type="Plus" />}
            className="policy-employment-group-drawer__tabs__or-container__button"
            label={t('policySettings:employment-group-drawer.add-rule-block')}
            onClick={handleNewRuleBlock}
          />
        </div>
      )}
    </div>
  );
};

export default PolicyEmploymentGroupRulesTab;
