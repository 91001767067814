import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EmploymentControlPointSettingsTable from 'src/components/Account/EmploymentControlPointSettingsTable';
import MandatoryDataSettingsTab from 'src/components/Account/MandatoryDataSettingsTab';
import PageBase from 'src/components/PageBase';
import './reporting-settings.scss';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { Employer } from 'src/types/employer/Employer';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import useTabParams from 'src/components/hooks/useTabParams';
import { ReportingTab } from 'src/types/tabs/ReportingTab';
import { useNavigateWithFallback } from 'src/service/routes/LocationHooks';
import ReportingSettingsTab from 'src/components/Account/ReportingSettings/ReportingSettingsTab';
import EmployerIndividualChoicesTab from 'src/components/Account/ReportingSettings/EmployerIndividualChoicesTab';

const ReportingSettings = () => {
  const { t } = useTranslation();
  const { customerName, employerId } = useParams();
  const [currentTab, setCurrentTab] = useTabParams('tab', ReportingTab.MANDATORY_DATA);

  const handleBackClick = useNavigateWithFallback(-1, '/');

  return (
    <QueryWrapper
      query={GET_EMPLOYER}
      options={{
        variables: { employerId },
        skip: !employerId,
      }}
    >
      {(employer: Employer) => {
        const breadCrumbs = [
          {
            name: customerName,
            steps: 2,
          },
          {
            name: employer.legalName,
            steps: 1,
          },
        ];

        return (
          <PageBase
            title={t('reportingSettings:reporting')}
            handleBackButton={handleBackClick}
            breadCrumbs={breadCrumbs}
          >
            <Tabs className="reporting-settings__tabs" tabIndex={currentTab} onChange={setCurrentTab}>
              <Tab title={t('account:employers-tab.reporting-settings.mandatory-data')}>
                <MandatoryDataSettingsTab employer={employer} />
              </Tab>
              <Tab title={t('account:employers-tab.reporting-settings.control-points')}>
                <EmploymentControlPointSettingsTable employerId={employer.id} />
              </Tab>
              <Tab title={t('account:employers-tab.reporting-settings.individual-choices')}>
                <EmployerIndividualChoicesTab employerId={employer.id} />
              </Tab>
              <Tab title={t('account:employers-tab.reporting-settings.settings')}>
                <ReportingSettingsTab employer={employer} />
              </Tab>
            </Tabs>
          </PageBase>
        );
      }}
    </QueryWrapper>
  );
};

export default ReportingSettings;
