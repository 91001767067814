import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import WageTypeOverviewTab from './WageTypeOverviewTab';

interface WageTypeDrawerProps {
  open: boolean;
  employerWageType: EmployerWageType;
  employerId: string;
  onClose: () => void;
}

function WageTypeDrawer({ employerWageType, open, onClose, employerId }: WageTypeDrawerProps) {
  const { t } = useTranslation();

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        title={t('account:employers-tab.wage-type-mapping-settings.wage-type')}
        subTitle={employerWageType.name}
        onClose={onClose}
      >
        <Tabs>
          <Tab title={t('account:employers-tab.wage-type-mapping-settings.wage-type-drawer:overview')}>
            <WageTypeOverviewTab employerWageType={employerWageType} employerId={employerId} />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
}

export default WageTypeDrawer;
