import { ListGroup, InlineEdit, Typography } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { ChangeEventHandler } from 'react';
import TextInput from 'src/components/Common/TextInput';
import { PolicyState } from 'src/types/policy/PolicyEntity';
interface LimitListProps {
  policyState: PolicyState;
  locked: boolean;
  upperLimit: string;
  handleUpperLimitOnChange: ChangeEventHandler<HTMLInputElement>;
  lowerLimit: string;
  handleLowerLimitOnChange: ChangeEventHandler<HTMLInputElement>;
}
const LimitListGroup = ({
  policyState,
  locked,
  upperLimit,
  handleUpperLimitOnChange,
  lowerLimit,
  handleLowerLimitOnChange,
}: LimitListProps) => {
  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.values.WAGE_UPPER_LIMIT`)}>
          <TextInput
            disabled={locked}
            value={upperLimit}
            validationKey="amount2Dec"
            type="text"
            variant="inline-edit"
            onChange={handleUpperLimitOnChange}
            placeholder={t('common:select-off')}
            data-cy="limit-list-group__upper-limit"
          />
        </InlineEdit>
        <InlineEdit label={t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.values.WAGE_LOWER_LIMIT`)}>
          <TextInput
            disabled={locked}
            onChange={handleLowerLimitOnChange}
            value={lowerLimit}
            validationKey="amount2Dec"
            type="text"
            variant="inline-edit"
            placeholder={t('common:select-off')}
            data-cy="limit-list-group__lower-limit"
          />
        </InlineEdit>
      </ListGroup>
      {policyState === 'DRAFT' && (
        <Typography variant="caption" component="span">
          {t(`policyRules:ruleTypeGroup.RULE_GROUP_WAGE_LIMIT.footer-text`)}
        </Typography>
      )}
    </div>
  );
};

export default LimitListGroup;
