import {
  Typography,
  ListGroup,
  DrawerNavigation,
  Button,
  ProgressSteps,
  ProgressStep,
  Icon,
  Pill,
} from 'fgirot-k2-ui-components';
import React, { useEffect } from 'react';
import { DataValidationCheckpoint } from 'src/types/DataValidationCheckpoint';
import { unique } from 'src/util/ArrayUtil';
import { updateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import { payTypeIsCorrectlyMapped } from 'src/util/WageUtil';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import When from 'src/components/Common/When';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import './pay-type-mapping-overview.scss';
import InfoMessage from 'src/components/Common/InfoMessage';

interface PayTypeMappingsOverviewDrawerProps {
  employerIds: string[];
  selectedEmployerIndex: number;
  setSelectedEmployerIndex: (index: number) => void;
  wageTypeCheckpoints: {
    employerId: string;
    checkpoints: DataValidationCheckpoint[];
  }[];
  handleClose: () => void;
  handleSelectPayType: (payTypeNumber: string, payTypeName: string, existingPayTypeId: string) => void;
  employerPayTypes: EmployerPayType[];
}

const PayTypeMappingsOverviewDrawer = ({
  wageTypeCheckpoints,
  employerIds,
  handleClose,
  handleSelectPayType,
  selectedEmployerIndex,
  setSelectedEmployerIndex,
  employerPayTypes,
}: PayTypeMappingsOverviewDrawerProps) => {
  const customer = useCustomer();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const employerId = employerIds[selectedEmployerIndex];
  const employerLegalName = useEmployerLegalName(employerId);

  const employerCheckpoints = wageTypeCheckpoints
    .filter((w) => w.employerId === employerId)
    .flatMap((ce) => ce.checkpoints);

  const uniquePayTypes = unique(
    employerCheckpoints.flatMap((checkpoint) => checkpoint.additionalData?.wageTypeMappings),
    'code',
  );

  const handleSelectNextEmployer = () => {
    setSelectedEmployerIndex(selectedEmployerIndex + 1);
  };

  const handleSelectPreviousEmployer = () => {
    setSelectedEmployerIndex(selectedEmployerIndex - 1);
  };

  const isAllPayTypesResolved = (checkpoint: DataValidationCheckpoint): boolean => {
    const codesFromCheckpoint = checkpoint?.additionalData?.wageTypeMappings?.map((mapping) => mapping.code);
    const correctlyMappedPayTypes = employerPayTypes
      .filter((payType) => payTypeIsCorrectlyMapped(payType))
      .map((payType) => payType?.number);

    return codesFromCheckpoint.every((codeFromCheckpoint) => correctlyMappedPayTypes.includes(codeFromCheckpoint));
  };

  useEffect(() => {
    const checkpointsToResolve = employerCheckpoints.filter(
      (checkpoint) => checkpoint.checkpointStatus !== 'RESOLVED' && isAllPayTypesResolved(checkpoint),
    );

    checkpointsToResolve.length > 0 &&
      updateCheckpointStatusMutation(
        checkpointsToResolve.map((checkpoint) => checkpoint?.id),
        'RESOLVED',
        employerId,
      );
  }, [employerPayTypes, employerCheckpoints]);

  const navigateToEmployerWageMappings = () => {
    navigate(
      `/account/${encodeURIComponent(customer?.name)}/${employerIds[selectedEmployerIndex]}/wage-type-mapping-settings`,
    );
  };

  const sortedUniquePayTypes = uniquePayTypes.sort((a, b) => {
    const aIsMapped = employerPayTypes.some((pt) => pt?.number === a.code);
    const bIsMapped = employerPayTypes.some((pt) => pt?.number === b.code);

    if (aIsMapped && !bIsMapped) {
      return 1;
    } else if (!aIsMapped && bIsMapped) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <DrawerBase
      leftButton={
        <Button
          type="link"
          label={t('validating:wage-type-mapping-drawer.left-button-label')}
          iconPlacement="left"
          icon={<Icon type="Before" className="pay-type-mapping-drawer__button__icon" />}
          onClick={navigateToEmployerWageMappings}
        />
      }
      onClose={handleClose}
      title={employerLegalName}
      subTitle="Lönearter"
    >
      <When condition={!!employerId}>
        <div className="pay-type-mapping-drawer">
          <div className="pay-type-mapping-drawer__title">
            <Typography variant="subtitle" component="h4" bold className="card__row--icon-title__title">
              {t('validating:wage-type-mapping-drawer.title')}
            </Typography>
            <InfoMessage message={t('validating:wage-type-mapping-drawer.subtitle')} />
          </div>
          <ListGroup variant="drawer-navigation">
            {sortedUniquePayTypes.map((payType) => {
              const existingPayType = employerPayTypes?.find((pt) => pt?.number === payType.code);
              return (
                <DrawerNavigation
                  key={payType.code}
                  label={
                    <div className="pay-type-mapping-drawer__navigation-label">
                      <Typography variant="body2" bold>{`${payType.code} ${payType.description}`}</Typography>
                      <Pill
                        type={existingPayType ? 'success' : 'warning'}
                        size="small"
                        label={
                          existingPayType
                            ? t('validating:wage-type-mapping-drawer.mapped')
                            : t('validating:wage-type-mapping-drawer.not-mapped')
                        }
                      />
                    </div>
                  }
                  onClick={() => handleSelectPayType(payType.code, payType.description, existingPayType?.id)}
                />
              );
            })}
          </ListGroup>
        </div>
      </When>
      <When condition={employerIds.length > 1}>
        <div className="pay-type-mapping-drawer__buttons">
          <Button
            className="pay-type-mapping-drawer__buttons__button"
            size="small"
            type="link"
            label={t('common:back')}
            iconPlacement="left"
            icon={<Icon type="ArrowLeft" />}
            onClick={handleSelectPreviousEmployer}
            disabled={selectedEmployerIndex === 0}
          />
          <ProgressSteps type="page-control" handleStepChange={setSelectedEmployerIndex} active={selectedEmployerIndex}>
            {employerIds.map((employerId, index) => (
              <ProgressStep key={`ProgressStep-${employerId}-${index}`} />
            ))}
          </ProgressSteps>
          <Button
            className="pay-type-mapping-drawer__buttons__button"
            size="small"
            type="link"
            label={`${t('common:next')} ${t('common:employer')}`}
            iconPlacement="right"
            icon={<Icon type="ArrowRight" />}
            onClick={handleSelectNextEmployer}
            disabled={selectedEmployerIndex === employerIds.length - 1}
          />
        </div>
      </When>
    </DrawerBase>
  );
};

export default PayTypeMappingsOverviewDrawer;
