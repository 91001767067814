import React from 'react';
import { TableHead, TableRow } from 'fgirot-k2-ui-components';

const TableHeadEmpty = () => {
  return (
    <TableHead filled>
      <TableRow>
        <></>
      </TableRow>
    </TableHead>
  );
};

export default TableHeadEmpty;
