import { Drawer, Icon, Input, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { useEmployersInPeriod } from 'src/service/employer/GetEmployer';
import { useSession } from 'src/service/WageFileSessionSubscription';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import EmployerList from './EmployerList';
import ParseErrorModal from '../ParseErrorModal';
import './reporting-status.scss';

interface ReportingStatusProps {
  wageFilePeriod: string;
}

const ReportingStatus = ({ wageFilePeriod }: ReportingStatusProps) => {
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState('');

  const customer = useCustomer();

  const [wageFileSessions, removeSessions] = useSession();
  const employers = useEmployersInPeriod(customer?.id, wageFilePeriod, wageFileSessions);

  const employerIds = employers.map((employer) => employer.id);

  const unhandledSessions = wageFileSessions.filter(
    (session) => !employerIds.includes(session.employerId) && session.validationErrors.length > 0,
  );

  const errors =
    employers.find((employee) => employee.wageFileSessions.find((session) => session.validationErrors.length > 0)) !==
      undefined || null;

  const handleNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const noErrorEmployers = employers.filter(
    (e) => e.wageFileSessions.filter((session) => session.validationErrors.length > 0).length === 0,
  );

  return (
    <div className={['reporting-status'].join(' ')}>
      <Drawer type="non-close" className="reporting-status__drawer">
        <div className="reporting-status__header">
          <Typography className="reporting-status__header__title" bold variant="h3" component="h2">
            {t('upload:reporting-status.reporting-status')}
          </Typography>
          <Typography className="reporting-status__header__caption" bold variant="caption">
            <>
              {`${t('common:group')} ${customer?.name}`}| {usePeriodText(wageFilePeriod)}
            </>
          </Typography>
        </div>
        <div className="reporting-status__search">
          <Input.Text
            type="search"
            value={nameFilter}
            onChange={handleNameFilterChange}
            icon={<Icon type="Search" />}
            placeholder={t('searchTerms:search-company')}
          />
        </div>
        <div className="reporting-status__content">
          {errors && (
            <>
              <div className="reporting-status__files__header">
                <Typography variant="subtitle" component="h2" bold>
                  {t('upload:reporting-status.contains-errors')}
                </Typography>
                <Icon type="Info" className="reporting-status__files__header__icon" />
              </div>
              <EmployerList
                error
                employers={employers.filter(
                  (e) => e.wageFileSessions.filter((session) => session.validationErrors.length > 0).length > 0,
                )}
              />
            </>
          )}
          {noErrorEmployers.length > 0 && (
            <>
              <div className={`reporting-status__files__header${errors ? '--errors' : ''}`}>
                <Typography variant="subtitle" component="h2" bold>
                  {t('upload:reporting-status.company')}
                </Typography>
                <Icon className="reporting-status__files__header__icon" type="Info" />
              </div>

              <div className="reporting-status__tabs__container">
                <Tabs className="reporting-status__tabs" variant="button-group">
                  <Tab title={t('upload:reporting-status.all')}>
                    <EmployerList employers={noErrorEmployers} />
                  </Tab>
                  <Tab title={t('upload:reporting-status.uploaded')}>
                    <EmployerList
                      employers={noErrorEmployers.filter((employee) => employee.wageFileSessions.length > 0)}
                    />
                  </Tab>
                </Tabs>
              </div>
            </>
          )}

          <ParseErrorModal
            open={unhandledSessions.length > 0}
            removeFiles={() => removeSessions(unhandledSessions.map((session) => session.id))}
            errorSessions={unhandledSessions}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default ReportingStatus;
