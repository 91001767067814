import { Button } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollBase from 'src/components/Common/ScrollBase';
import { SMALL_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import { useCreatePolicyComment } from 'src/service/policy/CreatePolicyComment';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import PolicyCommentEntry from './PolicyCommentEntry';
import './policy-comments.scss';
import TextArea from 'src/components/Common/TextArea';

interface PolicyCommentsProps {
  selectedPolicyEntity: PolicyEntity;
}

const PolicyComments: React.FunctionComponent<PolicyCommentsProps> = ({ selectedPolicyEntity }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const createPolicyComment = useCreatePolicyComment();

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleOnClick = () => {
    createPolicyComment(selectedPolicyEntity.id, comment, selectedPolicyEntity.employerId).then(() => {
      setComment('');
    });
  };

  return (
    <div className="policy-comments">
      <ScrollBase className="policy-comments__comments" maxHeightPercentage={40}>
        {selectedPolicyEntity.policyComments.map((policyComment, index) => {
          return (
            <PolicyCommentEntry key={`PolicyCommentEntry-${policyComment.id}-${index}`} policyComment={policyComment} />
          );
        })}
      </ScrollBase>
      <div className="policy-comments__add-comments">
        <TextArea
          placeholder={t('account:employers-tab.policy-settings.drawer.comment-placeholder')}
          value={comment}
          onChange={handleOnChange}
          validationKey="text255"
          characterLimit={SMALL_TEXTAREA_MAX_CHARACTERS}
        />
        <Button
          label={t('account:employers-tab.policy-settings.drawer.comment-save')}
          disabled={comment.length < 3}
          onClick={handleOnClick}
        />
      </div>
    </div>
  );
};

export default PolicyComments;
