import { gql } from '@apollo/client';

export const GET_ACCOUNTS = gql`
  query GetAccounts($employerId: UUID!) {
    accounts(employerId: $employerId) {
      id
      accountNumber
      paymentServiceProvider
      accountActive
      bank
    }
  }
`;
