import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import UpdateInput from 'src/components/Common/UpdateInput';
import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import { RuleValue, RuleValueCharCode, RuleValueType } from 'src/types/policy/RuleValue';
import { getValidValueTypes } from '../AffiliationRuleTypeConfig';
import { RuleGroupProps } from '../RuleGroupProps';
import IndividualChoiceRetirementAgeRule from './IndividualChoiceRetirementAgeRule/IndividualChoiceRetirementAgeRule';
import InfoMessage from 'src/components/Common/InfoMessage';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';
import { employerHasIndividualChoice } from 'src/util/IndividualChoiceUtil';
import When from 'src/components/Common/When';

interface AgeLimitRuleProps extends RuleGroupProps {
  employerIndividualChoices: EmployerIndividualChoice[];
}

const AgeLimitRule: React.FunctionComponent<AgeLimitRuleProps> = ({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
  employerIndividualChoices,
}) => {
  const { t } = useTranslation();
  const selectableRuleValueTypes: RuleValueType[] = getValidValueTypes(PolicyRuleType.AGE_LIMIT);

  const getCurrentRuleValues = (): RuleValue[] =>
    selectedAffiliationRuleGroup.affiliationRules.flatMap((rule) => rule.ruleValues);

  const getRuleTypeValue = (ruleValueType: RuleValueType): string => {
    const rowRuleValue = getCurrentRuleValues().find((rv) => rv.valueType === ruleValueType);
    return rowRuleValue && rowRuleValue.value;
  };

  const resolveruleValues = (field: RuleValueType, value: string): RuleValue[] => {
    if (getCurrentRuleValues().some((rule) => rule.valueType === field)) {
      if (value) {
        return getCurrentRuleValues().map((rule) => {
          return rule.valueType === field ? { ...rule, value: value } : rule;
        });
      } else {
        return getCurrentRuleValues().filter((ruleValue) => ruleValue.valueType !== field);
      }
    } else if (value) {
      return [...getCurrentRuleValues(), { value: value, valueType: field }];
    } else {
      return [...getCurrentRuleValues()];
    }
  };

  const handleOnBlur = (field: string, value: string) => {
    if (policyState === 'DRAFT') {
      const ruleValues: RuleValue[] = resolveruleValues(field as RuleValueType, value);
      const selectedPolicyRule = selectedAffiliationRuleGroup.affiliationRules.find((r) => r);
      onUpdateOrDeleteAffiliationRule({
        ruleId: selectedPolicyRule?.id,
        policyRuleType: PolicyRuleType.AGE_LIMIT,
        ruleValues,
      });
    }
  };

  const employerHasIndividualRetirementAge = employerHasIndividualChoice(
    'INDIVIDUAL_RETIREMENT_AGE',
    employerIndividualChoices,
  );

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant={'inline-edit'}>
        {selectableRuleValueTypes
          .filter((rvt) => policyState === 'DRAFT' || getRuleTypeValue(rvt))
          .map((rvt) => {
            return (
              <InlineEdit
                key={`age-limit-${rvt}`}
                label={`${t(
                  `policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.valueType.${rvt}`,
                )} (${String.fromCharCode(RuleValueCharCode[rvt])})`}
                bold
              >
                <UpdateInput
                  key={`AgeLimitRule-${rvt}`}
                  type="number"
                  placeholder={t(`policyRules:ruleValues.valueUnit.AGE`)}
                  onBlur={handleOnBlur}
                  disabled={policyState !== 'DRAFT'}
                  fieldKey={rvt}
                  fieldValue={getRuleTypeValue(rvt)}
                  validationKey="age"
                  data-cy={`age-limit-rule__input-${rvt}`}
                />
              </InlineEdit>
            );
          })}
      </ListGroup>

      <InfoMessage
        message={t(`policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.footer-text`)}
      />

      <When condition={employerHasIndividualRetirementAge}>
        <IndividualChoiceRetirementAgeRule
          policyState={policyState}
          selectedAffiliationRuleGroup={selectedAffiliationRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      </When>
    </div>
  );
};

export default AgeLimitRule;
