import { Button, Icon } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';

interface AgreedProductButtonsProps {
  inDraft: boolean;
  onRemove: () => void;
}

export const AgreedProductButtons = ({ inDraft, onRemove }: AgreedProductButtonsProps) => {
  const { t } = useTranslation();
  return (
    <div className="agreed-products-drawer__button">
      {inDraft && (
        <>
          <Button
            icon={<Icon type="Trash" />}
            type="secondary"
            label={t('policySettings:agreed-products-tab.remove')}
            onClick={onRemove}
            data-cy="agreed-products-drawer-remove-button"
          />
        </>
      )}
    </div>
  );
};

export default AgreedProductButtons;
