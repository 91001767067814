import { TableHead, TableRow, Th, TableBody } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import './pgl-calculation-table.scss';
import PglRulesTableRow from './PglRulesTableRow';
import { PglRule } from 'src/types/policy/PglRule';

interface PglCalculationTableProps {
  pglRules: PglRule[];
  handleOpenPglRuleDrawer: (id: string) => void;
}

const PglCalculationTable = ({ pglRules, handleOpenPglRuleDrawer }: PglCalculationTableProps) => {
  const { t } = useTranslation(['account']);

  const head = [
    { name: t('account:pgl-rules:table.head.name') },
    { name: t('account:pgl-rules:table.head.content') },
    { name: t('account:pgl-rules:table.head.used-by') },
  ];

  return (
    <div className={['pgl-calculation-table'].join(' ')}>
      <TableBase maxHeightPercentage={50}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th key={`PglCalculationTable-${title.name}-${index}`}>{title.name}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {pglRules.map((rule) => {
            return <PglRulesTableRow pglRule={rule} key={rule.id} handleOpenPglRuleModal={handleOpenPglRuleDrawer} />;
          })}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default PglCalculationTable;
