import { Button, Card, CardAction, Divider, ErrorSection, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WageFileSession } from 'src/types/WageFileSession';
import './validation-error-modal.scss';

interface ValidationErrorModalProps {
  open: boolean;
  onClose?: () => void;
  session?: WageFileSession;
}

const ValidationErrorModal = ({ open, onClose, session }: ValidationErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <div className={['validation-error-modal'].join(' ')}>
      <Modal overlayBackground centered open={open} onClose={onClose}>
        <Card
          defaultClose
          icon={<Icon type="Alert" className="validation-error-modal__icon" />}
          title={t('upload:reporting-status.company-upload-status.validation-error.file-process-error')}
          subtitle={`Filen ${session?.filename} kan inte behandlas. Se orsaker och vad du kan göra nedan.`}
          onClose={onClose}
          error
        >
          <ErrorSection className="validation-error-modal__error-section">
            {session &&
              session.validationErrors.map((error, index) => (
                <Typography key={`${session.id}-${error.errorMessageKey}-${index}`}>
                  {t(`errors:${error.errorMessageKey}`)}
                </Typography>
              ))}
          </ErrorSection>
          <CardAction>
            <Button type="link" label={t('common:cancel')} onClick={onClose} />
            <Button
              label={t('upload:reporting-status.company-upload-status.validation-error.remove-file')}
              icon={<Icon type="Trash" />}
            />
          </CardAction>
          <Divider className="validation-error-modal__divider" />
          <Typography className="validation-error-modal__divider__title" variant="h4" component="h4" bold>
            {t('upload:reporting-status.company-upload-status.validation-error.what-to-do')}
          </Typography>
          <Button
            className="validation-error-modal__faq"
            label={t('upload:reporting-status.company-upload-status.validation-error.faq')}
            type="link"
          ></Button>
        </Card>
      </Modal>
    </div>
  );
};

export default ValidationErrorModal;
