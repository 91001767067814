import { Td, Pill } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { examinationPillOutcome } from 'src/util/CheckpointUtil';
import './td-insurance-event-type.scss';

interface TdCertifyInsuranceEventTypeProps {
  checkpoints: InsuranceEventCheckpoint[];
}

const TdCertifyInsuranceEventType = ({ checkpoints }: TdCertifyInsuranceEventTypeProps) => {
  const { t } = useTranslation();

  return (
    <Td>
      {checkpoints && checkpoints.length && (
        <Pill
          key={`certify-insurance-event-type-pill-${checkpoints[0].id}`}
          type={examinationPillOutcome(checkpoints)}
          label={`${t(`insuranceEventTypes:${checkpoints[0].insuranceEventType}`)}${
            checkpoints.length > 1 ? ` + ${checkpoints.length - 1}` : ''
          }`}
        />
      )}
    </Td>
  );
};

export default TdCertifyInsuranceEventType;
