import React, { useContext, useState } from 'react';
import './select-customer.scss';
import QueryWrapper from '../Common/QueryWrapper';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/user/GetCustomers';
import { Customer, GetCustomerPage } from 'src/types/customer/Customer';
import { updateKeycloakWithSelectedCustomer } from 'src/service/updateKeycloakWithSelectedCustomer';
import { Button, FgLogo, Option, Select, ToastContext } from 'fgirot-k2-ui-components';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { useTranslation } from 'react-i18next';
import TextInput from '../Common/TextInput';
import { useKeycloak } from 'fgirot-k2-react-keycloak-provider';
import { client } from 'src/graphql';

const SelectCustomer = () => {
  const { keycloak, initialized } = useKeycloak();
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const [searchInput, setSearchInput] = useState('');
  const { displayToast } = useContext(ToastContext);

  const handleOnSetCustomer = async (customerId: string) => {
    const resp = await updateKeycloakWithSelectedCustomer(customerId, null, () =>
      displayToast('error', t('common:error'), t('userFeedback:SelectCustomer.error')),
    );
    if (resp) {
      window.location.reload();
    }
  };

  const customerOptionList = (customers: Customer[]) => {
    return createOptionsFromList(customers, 'id', 'name');
  };

  const handleSelectCustomerChange = (option: Option<string>) => {
    setSelectedCustomerId(option.value);
  };

  const { t } = useTranslation();

  const filterBySearchInput = (customer: Option<string>) => {
    return customer.label.toLowerCase().includes(searchInput.toLowerCase());
  };

  const logOut = () => {
    sessionStorage.clear();
    initialized && keycloak.logout();
    client.resetStore();
  };

  return (
    <div className="select-customer">
      <div className="select-customer__header">
        <Button onClick={logOut} label="Logga ut" type="secondary" />
      </div>
      <div className="select-customer__content">
        <QueryWrapper query={GET_ALL_CUSTOMERS}>
          {({ customers }: GetCustomerPage) => {
            const customerOptions = customerOptionList(customers);
            const filteredCustomerOptions = customerOptions.filter(filterBySearchInput);
            return (
              <div className="select-customer__wrapper">
                <FgLogo />
                {customerOptions.length > 10 && (
                  <TextInput
                    className="select-customer__filter-customers"
                    type="text"
                    validationKey="text64"
                    variant="default"
                    maxLength={65}
                    value={searchInput}
                    placeholder={t('selectCustomer:filter-customers')}
                    onChange={(e) => setSearchInput(e.target.value)}
                    data-testid="select-customer-modal-input"
                  />
                )}
                <Select
                  allowSelectMaxWidth
                  placeholder={t('common:select')}
                  label={t('selectCustomer:customer')}
                  selected={customerOptions.find((option) => option.value === selectedCustomerId)}
                  options={filteredCustomerOptions}
                  onChange={handleSelectCustomerChange}
                />
                <Button
                  label={t('selectCustomer:select-customer')}
                  onClick={() => handleOnSetCustomer(selectedCustomerId)}
                  disabled={!selectedCustomerId}
                />
              </div>
            );
          }}
        </QueryWrapper>
      </div>
    </div>
  );
};

export default SelectCustomer;
