import { Button, Drawer, Icon, InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/Common/TextArea';
import { Invoice } from 'src/types/invoice/Invoice';
import { isBlank } from 'src/util/StringUtil';
import { LARGE_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import { useCreatePaymentDueDate } from 'src/service/invoice/PaymentDueDate';
import { CreatePaymentDueDateInput } from 'src/types/invoice/CreatePaymentDueDateInput';
import DatePickerInput from 'src/components/Common/DatePickerInput';
import { datePickerSchema } from 'src/validation/schemas/datePickerSchema';
import './add-new-payment-due-date.scss';

interface AddNewPaymentDueDateProps {
  invoice: Invoice;
  onClose: () => void;
  onBackClick: () => void;
}

const AddNewPaymentDueDate: FC<AddNewPaymentDueDateProps> = ({ invoice, onClose, onBackClick }) => {
  const { t } = useTranslation();
  const createPaymentDueDate = useCreatePaymentDueDate();

  const [paymentDueDateInput, setPaymentDueDateInput] = useState<CreatePaymentDueDateInput>({
    dueDate: '',
    note: '',
  });

  const isCreateButtonDisabled =
    isBlank(paymentDueDateInput.dueDate || paymentDueDateInput.note) ||
    paymentDueDateInput.note.length < 3 ||
    datePickerSchema.validate({ ['paymentDueDate']: paymentDueDateInput?.dueDate }).error?.details?.length > 0;

  const handleDueDateInput = (value: string) => {
    setPaymentDueDateInput({ ...paymentDueDateInput, dueDate: value });
  };

  const handleNoteInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPaymentDueDateInput({ ...paymentDueDateInput, note: e.target.value });
  };

  const handleAddNewPaymentDueDate = () => {
    createPaymentDueDate(invoice.id, paymentDueDateInput, invoice.employer.id).then(() => onBackClick());
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="invoice-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('invoice:invoice-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="invoice-drawer__sub-title">
          <Typography>{invoice.employer?.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>

      <div className="add-new-payment-due-date">
        <Typography variant="body1" bold>
          {t('invoice:invoice-drawer.payment-due-dates-tab.add-new-due-date-title')}
        </Typography>
      </div>

      <div className="add-new-payment-due-date__list-group">
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('invoice:invoice-drawer.payment-due-dates-tab.due-date-label')} bold mandatory>
            <DatePickerInput
              fieldValue={paymentDueDateInput?.dueDate ?? ''}
              fieldKey="paymentDueDate"
              onBlur={(_, value) => {
                handleDueDateInput(value);
              }}
            />
          </InlineEdit>
        </ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('invoice:invoice-drawer.payment-due-dates-tab.note-label')} bold mandatory>
            <div className="add-new-payment-due-date__note-input">
              <TextArea
                small
                value={paymentDueDateInput?.note}
                onChange={handleNoteInput}
                validationKey="text1000"
                placeholder={t('invoice:invoice-drawer.payment-due-dates-tab.note-placeholder')}
                characterLimit={LARGE_TEXTAREA_MAX_CHARACTERS}
              />
            </div>
          </InlineEdit>
        </ListGroup>
      </div>
      <div className="payment-due-date-details__buttons">
        <Button
          className="payment-due-date-details__button"
          label={t('common:add')}
          onClick={handleAddNewPaymentDueDate}
          disabled={isCreateButtonDisabled}
          data-cy="add-new-payment-due-date__add-button"
        />
      </div>
    </Drawer>
  );
};

export default AddNewPaymentDueDate;
