import { gql } from '@apollo/client';

export const GET_EMPLOYEE_HISTORY = gql`
  query GetEmployeeHistory($employeeId: UUID!) {
    employeeHistory(employeeId: $employeeId) {
      employmentNumber
      earliestEmploymentStartDate
      employmentStartDate
      employmentEndDate
      previousEmploymentEndDate
      customerEmploymentStartDate
    }
  }
`;
