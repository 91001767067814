import { useApolloMutation } from '../graphql/Mutation';
import { GET_GROUPS } from 'src/graphql/schema/query/group/GetGroups';
import { ADD_EMPLOYER_TO_GROUP } from 'src/graphql/schema/mutation/group/AddEmployerToGroup';

export const useAddEmployerToGroup = () => {
  const apolloMutation = useApolloMutation();

  const addEmployerToGroup = (groupId: string, employerId: string) =>
    apolloMutation(ADD_EMPLOYER_TO_GROUP, '', { groupId, employerId }, [GET_GROUPS]);

  return addEmployerToGroup;
};
