import { gql } from '@apollo/client';
import {
  CreateBalancingOfBooksSettingsInput,
  UpdateBalancingOfBooksSettingsInput,
} from 'src/types/accountingunit/BalancingOfBooksSettings';
import { BALANCING_OF_BOOKS_SETTINGS } from 'src/graphql/schema/query/accountingunit/BalancingOfBooksSettings';
import { useApolloMutation } from '../graphql/Mutation';

export const CREATE_BALANCING_OF_BOOKS_SETTINGS = gql`
  mutation CreateBalancingOfbooksSettings($request: CreateBalancingOfBooksSettingsInput!) {
    createBalancingOfBooksSettings(request: $request) {
      id
    }
  }
`;

export const UPDATE_BALANCING_OF_BOOKS_SETTINGS = gql`
  mutation UpdateBalancingOfbooksSettings($request: UpdateBalancingOfBooksSettingsInput!) {
    updateBalancingOfBooksSettings(request: $request) {
      id
    }
  }
`;

export const useCreateBalancingOfBooksSettings = () => {
  const apolloMutation = useApolloMutation();

  const createBalancingOfBooksSettings = (request: CreateBalancingOfBooksSettingsInput, employerId: string) =>
    apolloMutation(CREATE_BALANCING_OF_BOOKS_SETTINGS, employerId, { request }, [BALANCING_OF_BOOKS_SETTINGS]);

  return createBalancingOfBooksSettings;
};

export const useUpdateBalancingOfBooksSettings = () => {
  const apolloMutation = useApolloMutation(false);

  const updateBalancingOfBooksSettings = (request: UpdateBalancingOfBooksSettingsInput, employerId: string) =>
    apolloMutation(UPDATE_BALANCING_OF_BOOKS_SETTINGS, employerId, { request }, [BALANCING_OF_BOOKS_SETTINGS]);

  return updateBalancingOfBooksSettings;
};
