import { TableHeadProps } from 'src/types/TableHeadProps';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import { DocumentTemplateTableSortField } from './DocumentTemplateTableSortField';

interface DocumentTemplateTableHeadsProps {
  sortDirection: SortDirection;
  sortField: DocumentTemplateTableSortField;
  onSortDirectionChange: () => void;
  onSortFieldChange: (sortField: string) => void;
}

const DocumentTemplateTableHeads: FC<DocumentTemplateTableHeadsProps> = ({
  sortDirection,
  onSortDirectionChange,
  onSortFieldChange,
  sortField,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      fieldName: 'documentTemplateName',
      title: t('documents:document-templates-tab.table.head.name'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('name');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'documentTemplateReportType',
      title: t('documents:document-templates-tab.table.head.report-type'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('reportType');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'documentTemplateDescription',
      title: t('documents:document-templates-tab.table.head.description'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('description');
        onSortDirectionChange();
      },
    },
  ];

  return (
    <TableRow>
      {tableHeads.map((head) => (
        <Th
          key={`document-template-${head.title}`}
          includeSortButtons
          sortDirection={sortField === head.fieldName ? sortDirection : undefined}
          handleSortDirectionChange={head.handleSortDirectionChange}
        >
          {head.title}
        </Th>
      ))}
    </TableRow>
  );
};

export default DocumentTemplateTableHeads;
