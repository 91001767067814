import { Banner } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyState, DRAFT, PENDING, INACTIVE } from 'src/types/policy/PolicyEntity';
interface PolicyStateBannerProps {
  policyState: PolicyState;
}
const PolicyStateBanner: FC<PolicyStateBannerProps> = ({ policyState }) => {
  const { t } = useTranslation();
  switch (policyState) {
    case DRAFT:
      return <Banner message={t('account:employers-tab.policy-settings.policy-version-header.banner.draft')} />;
    case PENDING:
      return <Banner message={t('account:employers-tab.policy-settings.policy-version-header.banner.pending')} />;
    case INACTIVE:
      return <Banner message={t('account:employers-tab.policy-settings.policy-version-header.banner.inactive')} />;
    default:
      return;
  }
};
export default PolicyStateBanner;
