import { gql } from '@apollo/client';

export const GET_INSURANCE_EVENT_CHECKPOINTS = gql`
  query GetInsuranceEventCheckpoints($employerIds: [UUID]!, $wageFilePeriod: String!) {
    reportEmployees(employerIds: $employerIds, wageFilePeriod: $wageFilePeriod, actionCategory: "INSURANCE_EVENT") {
      employee {
        id
        firstName
        lastName
        personNumber
        employerId
        employmentStartDate
        employmentEndDate
        hasDraft
      }
      employeeCard {
        id
        employeeId
        employmentGroupId
        workingPercentage
        sickDays
      }
      checkpoints {
        id
        errandId
        subActionCategory
        checkpointStatus
        benefitType
        insuranceEventType
        employerId
        additionalData {
          employeeCardId
          employeeCardPeriod
          insuranceProviderId
          insuranceEventId
          insuranceProviderProductConditionId
          eventFlowType
          insuranceProviderId
          insuranceProviderProductConditionId
        }
      }
    }
  }
`;
