import { Button, Card, CardAction, Modal } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import { useState } from 'react';
import TextInput from 'src/components/Common/TextInput';
import { useCreatePglRule } from 'src/service/policy/CreatePglRule';

interface CreateCalculationModalProps {
  employerId: string;
  policyId: string;
  open: boolean;
  onClose: () => void;
}

const CreateCalculationModal = ({ open, onClose, policyId, employerId }: CreateCalculationModalProps) => {
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [calculationName, setCalculationName] = useState('');
  const createPglRule = useCreatePglRule();

  const handleCalculationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalculationName(event.target.value);
    setCreateButtonDisabled(event.target.value.length < 3 || event.target.value.length > 64);
  };

  const handleOnClose = () => {
    setCreateButtonDisabled(true);
    setCalculationName('');
    onClose();
  };

  const handleCreateCalculation = () => {
    setCreateButtonDisabled(true);
    createPglRule(employerId, policyId, calculationName).finally(() => {
      handleOnClose();
    });
  };

  return (
    <Modal data-cy="create-calculation-modal" centered overlayBackground open={open} onClose={handleOnClose}>
      <Card title={t('policySettings:calculations-tab.create-calculation-modal.title')} size="narrow">
        <TextInput
          label={t('policySettings:calculations-tab.create-calculation-modal.name')}
          type="text"
          validationKey="text64"
          variant="default"
          onChange={handleCalculationNameChange}
          value={calculationName}
          maxLength={65}
        />
        <CardAction>
          <Button
            label={t('common:cancel')}
            type="link"
            onClick={handleOnClose}
            data-cy="create-calculation-modal__cancel-button"
          />
          <Button
            label={t('common:create')}
            disabled={createButtonDisabled}
            onClick={handleCreateCalculation}
            data-cy="create-calculation-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateCalculationModal;
