import { Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { stringOrBlank } from 'src/util/StringUtil';
import { useTranslation } from 'react-i18next';
import DraftChangedTooltip from 'src/components/Common/DraftChangedTooltip';

interface WageTypeLabelProps {
  employerWageTypes: EmployerWageType[];
  employerWageTypeId: string;
  hasWagesChangedInDraft: boolean;
}

const EmployerWageTypeLabel: FC<WageTypeLabelProps> = ({
  employerWageTypes,
  employerWageTypeId,
  hasWagesChangedInDraft,
}) => {
  const { t } = useTranslation();
  const employerWageTypeName = stringOrBlank(
    employerWageTypes.find((employerWageType) => employerWageType.id == employerWageTypeId)?.name,
  );

  return (
    <div className="employer-wage-type-label">
      <DraftChangedTooltip
        hasChangesInDraft={hasWagesChangedInDraft}
        label={t('employees:employee-draft-pay-type-changed')}
      >
        <Typography variant="body2" bold>
          {employerWageTypeName}
        </Typography>
      </DraftChangedTooltip>
    </div>
  );
};

export default EmployerWageTypeLabel;
