import { gql } from '@apollo/client';

export const GET_AGREED_REPORTING_TYPE_CONFIGS = gql`
  query GetAgreedReportingTypeConfigs($insuranceProviderContractId: UUID!, $agreedProductId: UUID!) {
    agreedReportingTypeConfigs(
      insuranceProviderContractId: $insuranceProviderContractId
      agreedProductId: $agreedProductId
    ) {
      id
      reportingDetailLevel
      reportingType
      insuranceEventType
    }
  }
`;
