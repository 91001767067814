import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sv from './translations/sv';
import en from './translations/en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      sv,
      en,
    },
    lang: 'sv',
    fallbackLng: 'sv',
    interpolation: {
      escapeValue: false,
    },
  })
  .finally(() => document.documentElement.setAttribute('lang', 'sv'));

i18n.on('languageChanged', (lang) => {
  document.documentElement.setAttribute('lang', lang);
});
