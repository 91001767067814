import { Service } from '../../types/Service';
import { ERROR, INIT, LOADED, LOADING } from '../../types/Status';
import { useEffect, useState } from 'react';
import { useEmployeeIndividualSettingsCreatedSubscription } from '../employee/IndividualSettingsCreatedSubscription';
import { useQuery } from '@apollo/client';
import { EmployeeIndividualSettings } from 'src/types/employees/EmployeeIndividualSettings';
import { GET_EMPLOYEE_INDIVIDUAL_SETTINGS } from 'src/graphql/schema/query/employee/GetEmployeeIndividualSettings';
import { EMPLOYEE_INDIVIDUAL_SETTINGS_CREATED } from 'src/graphql/schema/mutation/employee/EmployeeIndividualSettingsCreatedSubscription';

const useEmployeeIndividualSettings = (employeeId: string): Service<EmployeeIndividualSettings> => {
  const [result, setResult] = useState<Service<EmployeeIndividualSettings>>({ status: INIT });

  const { loading, error, data } = useQuery(GET_EMPLOYEE_INDIVIDUAL_SETTINGS, {
    variables: {
      employeeId,
    },
    skip: !employeeId,
    fetchPolicy: 'no-cache',
  });

  useEmployeeIndividualSettingsCreatedSubscription(EMPLOYEE_INDIVIDUAL_SETTINGS_CREATED, employeeId, setResult);

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    data &&
      result.status !== LOADED &&
      setResult({
        status: LOADED,
        payload: data.individualSettings,
      });
  }, [loading, error, data]);

  return result;
};

export default useEmployeeIndividualSettings;
