import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';

import DocumentArchiveTableHeads from './DocumentArchiveTableHeads';
import { DocumentArchiveTableSortField } from './DocumentArchiveTableSortField';
import DocumentArchiveTableRow from './DocumentArchiveTableRow';
import { DocumentReference } from 'src/types/documents/DocumentReference';
import { sortAlphabeticalAsc, sortAlphabeticalDesc } from 'src/util/SortUtil';

interface DocumentArchiveTableProps {
  documentArchiveTableItems: DocumentReference[];
  documentFilterText: string;
}

const DocumentArchiveTable: FC<DocumentArchiveTableProps> = ({ documentArchiveTableItems, documentFilterText }) => {
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));
  const [sortDirection, setSortDirection] = useState<SortDirection>('DOWN');
  const [sortField, setSortField] = useState<DocumentArchiveTableSortField>('createdDate');

  const handleSortFieldChange = (sortField: DocumentArchiveTableSortField) => setSortField(sortField);

  const sortFunction = (a: DocumentReference, b: DocumentReference) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;

    switch (sortField) {
      case 'fileName':
        return sortAlphabetical(a.fileName, b.fileName);
      case 'contentType':
        return sortAlphabetical(a.contentType, b.contentType);
      case 'fromPeriod':
        return sortAlphabetical(a.fromPeriod, b.fromPeriod);
      case 'toPeriod':
        return sortAlphabetical(a.toPeriod, b.toPeriod);
      case 'employerId':
        return sortAlphabetical(a.employerId, b.employerId);
      case 'createdDate':
        return sortAlphabetical(a.createdDate, b.createdDate);
      default:
        return undefined;
    }
  };

  const filterDocuments = (document: DocumentReference) => {
    const searchedFilterPeriod = document.fromPeriod
      .toLocaleUpperCase()
      .includes(documentFilterText.toLocaleUpperCase());
    const searchedFilterName = document.fileName.toLocaleUpperCase().includes(documentFilterText.toLocaleUpperCase());
    return searchedFilterPeriod || searchedFilterName;
  };

  const documents = documentArchiveTableItems.filter(filterDocuments).sort(sortFunction);

  return (
    <>
      <TableBase maxHeightPercentage={55}>
        <TableHead filled>
          <DocumentArchiveTableHeads
            sortDirection={sortDirection}
            sortField={sortField}
            onSortDirectionChange={handleSortDirectionChange}
            onSortFieldChange={handleSortFieldChange}
          />
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <DocumentArchiveTableRow key={document.id} documentReference={document} />
          ))}
        </TableBody>
      </TableBase>
    </>
  );
};

export default DocumentArchiveTable;
