import { AffiliationRule } from './AffiliationRule';

export enum AffiliationRuleGroupType {
  RULE_GROUP_EMPLOYMENT_TYPE = 'RULE_GROUP_EMPLOYMENT_TYPE',
  RULE_GROUP_EMPLOYMENT_REMUNERATION_TYPE = 'RULE_GROUP_EMPLOYMENT_REMUNERATION_TYPE',
  RULE_GROUP_EMPLOYMENT_CATEGORY = 'RULE_GROUP_EMPLOYMENT_CATEGORY',
  RULE_GROUP_AGE_LIMIT = 'RULE_GROUP_AGE_LIMIT',
  RULE_GROUP_YEAR_OF_BIRTH_LIMIT = 'RULE_GROUP_YEAR_OF_BIRTH_LIMIT',
  RULE_GROUP_EMPLOYMENT_START_DATE = 'RULE_GROUP_EMPLOYMENT_START_DATE',
  RULE_GROUP_EMPLOYMENT_END_DATE = 'RULE_GROUP_EMPLOYMENT_END_DATE',
  RULE_GROUP_WAGE_LIMIT = 'RULE_GROUP_WAGE_LIMIT',
  RULE_GROUP_LEAVE_LIMIT = 'RULE_GROUP_LEAVE_LIMIT',
  RULE_GROUP_SICK_LEAVE_LIMIT = 'RULE_GROUP_SICK_LEAVE_LIMIT',
  RULE_GROUP_INDIVIDUAL_CHOICE = 'RULE_GROUP_INDIVIDUAL_CHOICE',
  RULE_GROUP_WORKING_HOURS = 'RULE_GROUP_WORKING_HOURS',
}

export const employmentGroupRuleGroups = [
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_TYPE,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_REMUNERATION_TYPE,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_CATEGORY,
  AffiliationRuleGroupType.RULE_GROUP_AGE_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_YEAR_OF_BIRTH_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_START_DATE,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_END_DATE,
  AffiliationRuleGroupType.RULE_GROUP_LEAVE_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_SICK_LEAVE_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_INDIVIDUAL_CHOICE,
];

export const benefitRuleGroups = [
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_TYPE,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_REMUNERATION_TYPE,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_CATEGORY,
  AffiliationRuleGroupType.RULE_GROUP_AGE_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_YEAR_OF_BIRTH_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_START_DATE,
  AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_END_DATE,
  AffiliationRuleGroupType.RULE_GROUP_WAGE_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_LEAVE_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_SICK_LEAVE_LIMIT,
  AffiliationRuleGroupType.RULE_GROUP_INDIVIDUAL_CHOICE,
  AffiliationRuleGroupType.RULE_GROUP_WORKING_HOURS,
];

export interface GroupedAffiliationRules {
  affiliationRuleGroupType: AffiliationRuleGroupType;
  affiliationRules: AffiliationRule[];
}
