import { CREATE_CONTRACT_AGREEMENT_COST_CENTRE } from 'src/graphql/schema/mutation/contract/CreateContractAgreementCostCentre';
import { GET_INSURANCE_PROVIDER_CONTRACT } from 'src/graphql/schema/query/contract/GetInsuranceProviderContract';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { AgreementCostCentreRequest } from 'src/types/contract/request/AgreementCostCentreRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useCreateContractAgreementCostCentre = () => {
  const apolloMutation = useApolloMutation();

  const createContractAgreementCostCentre = (
    employerId: string,
    insuranceProviderContractId: string,
    request: AgreementCostCentreRequest,
  ) =>
    apolloMutation(
      CREATE_CONTRACT_AGREEMENT_COST_CENTRE,
      employerId,
      {
        insuranceProviderContractId,
        request,
      },
      [GET_INSURANCE_PROVIDER_CONTRACTS, GET_INSURANCE_PROVIDER_CONTRACT],
    );

  return createContractAgreementCostCentre;
};
