import { InlineEdit, ListGroup, Switch } from 'fgirot-k2-ui-components';
import './groups-drawer-roles-tab.scss';
import { GroupRole } from 'src/types/group/Group';
import { EXTERNAL_ADMIN_ROLE_NAME, Role } from 'src/types/Role';
import { useAddRoleToGroup } from 'src/service/group/AddRoleToGroup';
import { useRemoveRoleFromGroup } from 'src/service/group/RemoveRoleFromGroup';
import { useTranslation } from 'react-i18next';

interface GroupsDrawerRolesTabProps {
  allRoles: Role[];
  groupRoles: GroupRole[];
  groupId: string;
  adminGroup: boolean;
}

const GroupsDrawerRolesTab: React.FunctionComponent<GroupsDrawerRolesTabProps> = ({
  allRoles,
  groupRoles,
  groupId,
  adminGroup,
}) => {
  const { t } = useTranslation();
  const addRoleToGroup = useAddRoleToGroup();
  const removeRoleFromGroup = useRemoveRoleFromGroup();
  const isToggled = (roleId: string) => {
    return !!groupRoles.find((role) => role.id === roleId);
  };

  const onToggleChange = (roleId: string) => {
    if (isToggled(roleId)) {
      removeRoleFromGroup(groupId, roleId);
    } else {
      addRoleToGroup(groupId, roleId);
    }
  };

  const getTranslation = (roleName: string) => {
    return t(`rolesTranslations:${roleName}`);
  };

  const filterAdmin = (role: Role): boolean => {
    return adminGroup ? role.name === EXTERNAL_ADMIN_ROLE_NAME : role.name !== EXTERNAL_ADMIN_ROLE_NAME;
  };

  const shouldBeDisabled = (role: Role) => {
    return role.name === EXTERNAL_ADMIN_ROLE_NAME;
  };

  return (
    <ListGroup variant="inline-edit" className="groups-drawer-roles-tab">
      {allRoles
        .filter((role) => filterAdmin(role))
        .map((role) => (
          <InlineEdit
            key={role.id}
            bold
            label={getTranslation(role.name)}
            data-testid={`role-switch-${isToggled(role.id) ? 'active' : 'inactive'}`}
          >
            <Switch
              toggled={isToggled(role.id)}
              onChange={() => onToggleChange(role.id)}
              disabled={shouldBeDisabled(role)}
            />
          </InlineEdit>
        ))}
    </ListGroup>
  );
};

export default GroupsDrawerRolesTab;
