import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { EmployeeHistory } from 'src/types/employees/EmployeeHistory';
import EmployeeHistoryTableRow from './EmployeeHistoryTableRow';

interface EmployeeHistoryTableProps {
  employeeHistory: EmployeeHistory[];
}
const EmployeeHistoryTable: FC<EmployeeHistoryTableProps> = ({ employeeHistory }) => {
  const { t } = useTranslation();

  const tableHeads = [
    { title: t('employees:employee-history-tab.employment-number') },
    { title: t('employees:employee-history-tab.earliest-employment-start-date') },
    { title: t('employees:employee-history-tab.employment-start-date') },
    { title: t('employees:employee-history-tab.employment-end-date') },
  ];
  return (
    <TableBase>
      <TableHead filled>
        <TableRow>
          {tableHeads.map((head) => (
            <Th key={`document-template-${head.title}`}>{head.title}</Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {employeeHistory.map((history) => (
          <EmployeeHistoryTableRow key={history.employmentStartDate} employeeHistory={history} />
        ))}
      </TableBody>
    </TableBase>
  );
};

export default EmployeeHistoryTable;
