import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE_INDIVIDUAL_CHOICES } from 'src/graphql/schema/query/employee/GetEmployeeIndividualChoices';
import { CREATE_DRAFT_EMPLOYEE_INDIVIDUAL_CHOICE } from 'src/graphql/schema/mutation/employee/CreateDraftEmployeeIndividualChoice';
import { CreateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/CreateEmployeeIndividualChoiceRequest';

export const useCreateDraftEmployeeIndividualChoice = () => {
  const apolloMutation = useApolloMutation();

  const createDraftEmployeeIndividualChoice = (request: CreateEmployeeIndividualChoiceRequest, employerId: string) =>
    apolloMutation(
      CREATE_DRAFT_EMPLOYEE_INDIVIDUAL_CHOICE,
      employerId,
      {
        request,
      },
      [GET_EMPLOYEE_INDIVIDUAL_CHOICES],
    );

  return createDraftEmployeeIndividualChoice;
};
