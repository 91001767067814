import { gql } from '@apollo/client';

export const ADD_USER_TO_GROUP = gql`
  mutation AddUserToGroup($groupId: UUID!, $userId: UUID!, $request: AddUserToGroupInput!) {
    addUserToGroup(groupId: $groupId, userId: $userId, request: $request) {
      groupId
      userId
    }
  }
`;
