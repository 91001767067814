import { SortDirection } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsurancesSortField } from './InsurancesSortField';

interface InsurancesTableHeadProps {
  sortDirection?: SortDirection;
  onSortFieldChange?: (sortField: InsurancesSortField) => void;
  onSortDirectionChange?: () => void;
}

export function getTableHeads({ sortDirection, onSortFieldChange, onSortDirectionChange }: InsurancesTableHeadProps) {
  const { t } = useTranslation();

  return [
    {
      fieldName: 'insuranceProvider',
      title: t('insurances:insurance-provider'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceProvider');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'benefitType',
      title: t('insurances:benefit-type'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('benefitType');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'insuranceTypeMapping',
      title: t('insurances:insurance-type-mapping'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceTypeMapping');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'agreedProduct',
      title: t('insurances:agreed-product'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('agreedProduct');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'certifyInsuranceProductCondition',
      title: t('insurances:full-employability-decision.required'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('certifyInsuranceProductCondition');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'insuranceNumber',
      title: t('insurances:insurance-number'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('insuranceNumber');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'agreementNumber',
      title: t('insurances:agreement-number'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('agreementNumber');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'statusCategory',
      title: t('insurances:status-category'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('statusCategory');
        onSortDirectionChange();
      },
    },
    {
      fieldName: 'latestStatusChange',
      title: t('insurances:latest-status-change'),
      sortDirection,
      handleSortDirectionChange: () => {
        onSortFieldChange('latestStatusChange');
        onSortDirectionChange();
      },
    },
  ];
}
