import { Button } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { Employer } from 'src/types/employer/Employer';
import AutomaticReportingDrawer from './DisableAutomaticReportingDrawer';
import { EmployersReportingFlowSettingsRequest } from 'src/types/employer/request/EmployersReportingFlowSettingsRequest';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYERS_REPORTING_FLOW_SETTINGS } from 'src/graphql/schema/query/employer/GetEmployersReportingFlowSettings';
import { EmployerReportingFlowSetting } from 'src/types/employer/EmployerReportingFlowSetting';
import DisableAutomaticReportingWarningBanner from './DisableAutomaticReportingWarningBanner';
import './disable-automatic-reporting.scss';
import { useTranslation } from 'react-i18next';

interface DisableAutomaticReportingProps {
  employers: Employer[];
}

function DisableAutomaticReporting({ employers }: DisableAutomaticReportingProps) {
  const [automaticReportingSettingsDrawerOpen, setAutomaticReportingSettingsDrawerOpen] = useState(false);

  const { t } = useTranslation();

  const employerIds = employers.map((e) => e.id);
  const request: EmployersReportingFlowSettingsRequest = {
    employerIds,
  };

  const openAutomaticReportingSettingsDrawer = () => {
    setAutomaticReportingSettingsDrawerOpen(true);
  };

  const closeAutomaticReportingSettingsDrawer = () => {
    setAutomaticReportingSettingsDrawerOpen(false);
  };

  return (
    <>
      <QueryWrapper
        query={GET_EMPLOYERS_REPORTING_FLOW_SETTINGS}
        options={{ variables: { request }, skip: !employerIds?.length }}
      >
        {(reportingFlowSettings: EmployerReportingFlowSetting[]) => (
          <>
            <div className="disable-automatic-reporting">
              <div>
                <Button
                  type="warning"
                  label={t('reporting:disable-automatic-reporting.disable-reporting')}
                  size="small"
                  onClick={openAutomaticReportingSettingsDrawer}
                />
              </div>
              <DisableAutomaticReportingWarningBanner
                employers={employers}
                reportingFlowSettings={reportingFlowSettings}
              />
            </div>
            {automaticReportingSettingsDrawerOpen && (
              <AutomaticReportingDrawer
                onClose={closeAutomaticReportingSettingsDrawer}
                open={automaticReportingSettingsDrawerOpen}
                employers={employers}
                reportingFlowSettings={reportingFlowSettings}
              />
            )}
          </>
        )}
      </QueryWrapper>
    </>
  );
}

export default DisableAutomaticReporting;
