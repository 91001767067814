import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pill, Td, Tooltip } from 'fgirot-k2-ui-components';
import './td-sub-action-category.scss';
import { examinationPillOutcome } from 'src/util/CheckpointUtil';
import { Checkpoint } from 'src/types/Checkpoint';

interface TdSubActionCategoryProps {
  checkpoints: Checkpoint[];
}

const TdSubActionCategory = ({ checkpoints }: TdSubActionCategoryProps) => {
  const { t } = useTranslation();

  return (
    <Td>
      <Tooltip content={checkpoints.map((f) => t(`subActionCategories:${f.subActionCategory}`)).join(', ')}>
        <Pill
          key={`sub-action-category-pill-${checkpoints[0].id}`}
          type={examinationPillOutcome(checkpoints)}
          label={`${t(`subActionCategories:${checkpoints[0].subActionCategory}`)} ${
            checkpoints.length > 1 ? ` + ${checkpoints.length - 1}` : ''
          }`}
        />
      </Tooltip>
    </Td>
  );
};

export default TdSubActionCategory;
