import { UpdatePGLRuleRequest } from '../../types/policy/request/UpdatePGLRuleRequest';
import { UPDATE_PGL_RULE } from '../../graphql/schema/mutation/policy/UpdatePolicyPGLRule';
import { GET_PGL_RULES } from '../../graphql/schema/query/policy/GetPglRules';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdatePGLRule = () => {
  const apolloMutation = useApolloMutation();

  const updatePGLRule = (employerId: string, policyId: string, pglRuleId: string, request: UpdatePGLRuleRequest) =>
    apolloMutation(UPDATE_PGL_RULE, employerId, { policyId, pglRuleId, request }, [GET_PGL_RULES]);

  return updatePGLRule;
};
