import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import './kub-drawer.scss';
import KubDetailsTab from './KubDetailsTab';
import { KubDrawerTab } from 'src/types/tabs/KubDrawerTab';

interface KubDrawerProps {
  open: boolean;
  onClose: () => void;
  employerKub: EmployerKub;
}
export const KubDrawer: FunctionComponent<KubDrawerProps> = ({ open, onClose, employerKub }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<KubDrawerTab>(KubDrawerTab.WAGE_TYPES);

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase title={employerKub.kubType} onClose={onClose}>
        <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
          <Tab title={t('account:employers-tab.wage-type-mapping-settings.kub-drawer.wage-types')}>
            <KubDetailsTab employerKub={employerKub} onClose={onClose} />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
};

export default KubDrawer;
