import { gql } from '@apollo/client';

export const UPSERT_PGL_RULE_BONUS_COMPONENT = gql`
  mutation UpsertPglRuleBonusComponent($request: PglBonusComponentRequest!) {
    upsertPglRuleBonusComponent(pglBonusComponentRequest: $request) {
      id
      bonusAverageType
      numberOfYearsToInclude
      includePresentYear
      recalculationFromMonth
      recalculationToMonth
      applyRetroactivelyFromMonth
      successiveBonusSpecificSettings {
        numberOfYearsOfHistory
        startDateBasis
      }
    }
  }
`;
