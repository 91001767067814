import { Option } from 'fgirot-k2-ui-components';
import { useEffect, useState } from 'react';
import { useContractedInsuranceProviders } from 'src/service/contract/GetContractedInsuranceProviders';
import { Employer } from 'src/types/employer/Employer';

export const useContractedInsuranceProviderOptions = (chosenEmployerId: string, employers: Employer[]) => {
  const contractedInsuranceProviders = useContractedInsuranceProviders();
  const [employerOptions, setEmployerOptions] = useState<Option<string>[]>([]);
  const [insuranceProviderOptions, setInsuranceProviderOptions] = useState<Option<string>[]>([]);

  useEffect(() => {
    if (contractedInsuranceProviders) {
      setEmployerOptions(
        contractedInsuranceProviders.map((cip) => {
          const employer = employers.find((e) => e.id === cip.employerId);
          return { label: employer.legalName, value: employer.id };
        }),
      );

      if (chosenEmployerId) {
        setInsuranceProviderOptions(
          contractedInsuranceProviders
            .find((cip) => cip.employerId === chosenEmployerId)
            ?.insuranceProviders?.map((provider) => {
              return {
                label: provider.legalName,
                value: provider.id,
              };
            }),
        );
      }
    }
  }, [contractedInsuranceProviders, chosenEmployerId]);

  return {
    employerOptions,
    insuranceProviderOptions,
  };
};
