import { gql } from '@apollo/client';

export const GET_EMPLOYEE_AGGREGATED_WAGES = gql`
  query GetEmployeeAggregatedWages(
    $employeeId: UUID!
    $fromPeriod: String!
    $toPeriod: String!
    $wageTypes: [String]!
    $allowDrafts: Boolean
  ) {
    employeeAggregatedWages(
      employeeId: $employeeId
      fromPeriod: $fromPeriod
      toPeriod: $toPeriod
      wageTypes: $wageTypes
      allowDrafts: $allowDrafts
    ) {
      extentAdjustedAbsenceDays
      amount
      employerWageTypeId
      wages {
        id
        wageType
        employerWageTypeId
        payTypeName
        payTypeNumber
        amount
        currency
        fromDate
        toDate
        quantityValue
        quantityUnit
        inUse
        payTypeName
        payTypeNumber
        extent
        extentAdjustedAbsenceDays
        isDraftModified
        employeeCardPeriod
      }
    }
  }
`;
