import { Button, Icon, ListGroup, InlineEdit, Typography, DrawerNavigation, Pill } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { AggregatedPayTypeWage, AggregatedWageTypeWage } from 'src/types/employees/AggregatedEmployeeCard';
import {
  formatSweAmountTextWithSuffixNoDecimal,
  formatSweAmountTextWithSuffixWithTwoDecimals,
} from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import './wage-type-details-drawer.scss';
import React from 'react';
import DraftChangedTooltip from 'src/components/Common/DraftChangedTooltip';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import { formatPayType, wageIsOnlyMappedToKUB } from 'src/util/WageUtil';

interface WageTypeDetailsDrawerProps {
  selectedWageType: AggregatedWageTypeWage;
  selectedPayType: AggregatedPayTypeWage;
  selectedPeriod: string;
  employerId: string;
  onClose: () => void;
  onBackClick: () => void;
  onSelectWage: (wageId: string) => void;
  isDraft: boolean;
}

const WageTypeDetailsDrawer = ({
  selectedWageType,
  selectedPayType,
  onClose,
  onBackClick,
  employerId,
  onSelectWage,
  selectedPeriod,
  isDraft,
}: WageTypeDetailsDrawerProps) => {
  const { t } = useTranslation();
  const employerWageTypes = useEmployerWageTypes(employerId);
  const formattedPeriod = getFormattedDateStringFromPeriod(selectedPeriod);
  const employerWageType = selectedWageType
    ? employerWageTypes.find((w) => w.id === selectedWageType.employerWageTypeId)
    : null;

  const aggregatedFormattedAmount = formatSweAmountTextWithSuffixNoDecimal(
    selectedWageType ? selectedWageType.amount : selectedPayType.payedAmount,
  );

  const drawerTitle = employerWageType
    ? stringOrBlank(employerWageType?.name)
    : `${selectedPayType?.payTypeNumber} - ${selectedPayType?.payTypeName}`;

  const wages = selectedWageType ? selectedWageType.wages : selectedPayType.wages || [];

  return (
    <DrawerBase
      title={drawerTitle}
      subTitle={<Pill type="success" label={formattedPeriod} />}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="wage-type-details-drawer">
        {isDraft && <EmployeeInDraftBanner />}
        <Typography variant="subtitle" bold>
          {t('wages:employer-wage-types')}
        </Typography>
        <ListGroup variant="drawer-navigation">
          {wages.map((wage) => {
            const isOnlyMappedToKub = wageIsOnlyMappedToKUB(wage);
            const displayedAmount = formatSweAmountTextWithSuffixWithTwoDecimals(
              isOnlyMappedToKub ? wage.payedAmount : wage.amount,
            );

            return (
              <DrawerNavigation
                key={wage.id}
                onClick={() => onSelectWage(wage.id)}
                label={
                  <div>
                    <DraftChangedTooltip
                      label={t('employees:employee-draft-wage-type-changed')}
                      hasChangesInDraft={wage.isDraftModified}
                    >
                      <Typography bold variant={'body2'}>
                        {formatPayType(wage.payTypeNumber, wage.payTypeName, t)}
                      </Typography>
                    </DraftChangedTooltip>
                  </div>
                }
                value={displayedAmount}
              />
            );
          })}
        </ListGroup>
        <ListGroup variant={'inline-edit'}>
          <InlineEdit bold label={t('wages:sum')} className="wage-type-details-drawer__sum">
            <Typography variant="body2">{aggregatedFormattedAmount}</Typography>
          </InlineEdit>
        </ListGroup>
      </div>
    </DrawerBase>
  );
};

export default WageTypeDetailsDrawer;
