import { TableRow, Td, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './absence-sum-row.scss';

interface AbsenceSumRowProps {
  sum: number;
}

function AbsenceSumRow({ sum }: AbsenceSumRowProps) {
  const { t } = useTranslation();

  return (
    <TableRow className="absence-sum-row">
      <Td>
        <Typography variant="body2" bold className="absence-sum-row__text">
          {t('employees:absence-tab.sum-extent-adjusted-absence-days')}
        </Typography>
      </Td>
      <Td />
      <Td />
      <Td />
      <Td>
        <Typography variant="body2" bold className="absence-sum-row__sum">{`${sum}`}</Typography>
      </Td>
    </TableRow>
  );
}

export default AbsenceSumRow;
