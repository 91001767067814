import { InlineEdit, Option, Select } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UpdateInput from 'src/components/Common/UpdateInput';
import {
  EmployeeIndividualChoice,
  EmployeeIndividualChoiceProperty,
} from 'src/types/employees/EmployeeIndividualChoice';
import { CreateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/CreateEmployeeIndividualChoiceRequest';
import { IndividualChoiceType } from 'src/types/employer/EmployerIndividualChoice';

interface IndividualChoicePropertyProps {
  property: EmployeeIndividualChoiceProperty;
  individualChoice: EmployeeIndividualChoice | CreateEmployeeIndividualChoiceRequest;
  value: string;
  onChange: (key: EmployeeIndividualChoiceProperty, value: string) => void;
  options?: Option<IndividualChoiceType>[];
}

function IndividualChoiceField({ property, value, onChange, options }: IndividualChoicePropertyProps) {
  const { t } = useTranslation();

  switch (property) {
    case 'type':
      return (
        <InlineEdit label={t(`employees:individual-choices.${property}`)} bold>
          <Select
            selected={options.find((option) => option.value === value)}
            onChange={(option: Option<IndividualChoiceType>) => onChange(property, option.value)}
            options={options}
            placeholder={t('common:select')}
            small
          />
        </InlineEdit>
      );
    case 'individualAge':
      return (
        <InlineEdit label={t(`employees:individual-choices.${property}`)} bold>
          <UpdateInput
            fieldKey={property}
            fieldValue={value || ''}
            onBlur={onChange}
            onChange={onChange}
            validationKey="individualRetirementAge"
            placeholder="Ange ålder"
          />
        </InlineEdit>
      );
    case 'fromPeriod':
      return (
        <InlineEdit label={t(`employees:individual-choices.${property}`)} bold>
          <UpdateInput
            fieldKey={property}
            fieldValue={value || ''}
            onBlur={onChange}
            onChange={onChange}
            validationKey="period"
            type="number"
            placeholder="YYYYMM"
          />
        </InlineEdit>
      );
    default:
      return null;
  }
}

export default IndividualChoiceField;
