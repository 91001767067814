import { TableRow, Td } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { EmployerKub } from 'src/types/employer/EmployerKub';

interface EmployerKubTableRowProps {
  employerKub: EmployerKub;
  handleOpenEmployerKubrawer: (employerKub: EmployerKub) => void;
}

const EmployerKubTableRow = ({ employerKub, handleOpenEmployerKubrawer }: EmployerKubTableRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow onClick={() => handleOpenEmployerKubrawer(employerKub)}>
      <Td>{employerKub.kubType}</Td>
      <Td>{`${employerKub?.employerPayTypes.length} ${t(
        'account:employers-tab.wage-type-mapping-settings.kub-table.wage-types',
      )}`}</Td>
    </TableRow>
  );
};

export default EmployerKubTableRow;
