import { CompanyCard, Loader, Pill } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ScrollBase from 'src/components/Common/ScrollBase';
import { ReportingStatusEmployer } from 'src/types/employer/Employer';
import CompanyUploadStatus from '../CompanyUploadStatus';

interface EmployerListProps {
  employers: ReportingStatusEmployer[];
  error?: boolean;
}

const EmployerList = ({ employers, error }: EmployerListProps) => {
  const { t } = useTranslation();
  const { period } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmployerId, setSelectedEmployerId] = useState<string>();

  const handleOpenModal = (employerId: string) => {
    setSelectedEmployerId(employerId);
    setModalOpen(true);
  };

  const handleOnClose = () => {
    setModalOpen(false);
  };

  return (
    <ScrollBase maxHeightPercentage={50}>
      <>
        {employers &&
          employers.map((employer, index) => {
            const uploadStarted = employer.wageFileSessions.length > 0;
            const label =
              uploadStarted && employer.wageFileSessions.length > 1
                ? t('upload:reporting-status.employer-list.files')
                : t('upload:reporting-status.employer-list.file');

            const uploadDone =
              uploadStarted && employer.wageFileSessions.every((session) => session.status === 'DONE' || 'ERROR');

            return (
              <CompanyCard
                className={index !== 0 ? 'reporting-status__employer-list__company-card--margin' : ''}
                error={error}
                done={uploadStarted}
                onClick={() => handleOpenModal(employer.id)}
                key={`FileCard-${employer.id}-${index}`}
                title={employer.legalName}
                subtitle={
                  uploadStarted
                    ? t('upload:reporting-status.employer-list.started')
                    : t('upload:reporting-status.employer-list.waiting')
                }
              >
                {uploadStarted && !uploadDone && <Loader size="small" type="blue" />}

                {uploadDone && <Pill label={`${employer.wageFileSessions.length} ${label}`} />}
              </CompanyCard>
            );
          })}

        {selectedEmployerId && (
          <CompanyUploadStatus
            open={modalOpen}
            onClose={handleOnClose}
            wageFilePeriod={period}
            employer={employers.find((employer) => employer.id === selectedEmployerId)}
          />
        )}
      </>
    </ScrollBase>
  );
};

export default EmployerList;
