import { InlineEdit, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { REPORTING_RULE_CONFIGURATIONS } from 'src/graphql/schema/query/insurance/ReportingRuleConfigurations';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { ReportingRuleConfigurationPage } from 'src/types/contract/ReportingRuleConfiguration';
import { ContractAgreedProductRequest } from 'src/types/contract/request/ContractAgreedProductRequest';
import { createOptionsFromList } from 'src/util/OptionUtil';

interface ReportingRuleConfigurationInputProps {
  contract: InsuranceProviderContract;
  agreedProductRequest: ContractAgreedProductRequest;
  onChange: (key: string, value: string) => void;
}

export const ReportingRuleConfigurationInput = ({
  contract,
  agreedProductRequest,
  onChange,
}: ReportingRuleConfigurationInputProps) => {
  const { t } = useTranslation();

  const onReportingRuleConfigurationChange = (reportingRuleConfigurationId: string) => {
    onChange('reportingRuleConfigurationId', reportingRuleConfigurationId);
    if (reportingRuleConfigurationId == null) onChange('defaultReportingDetailLevel', null);
  };
  return (
    <InlineEdit label={t('agreementRegistrySettings:reporting-rule-configuration')} bold>
      {contract.insuranceProvider !== null ? (
        <QueryWrapper
          query={REPORTING_RULE_CONFIGURATIONS}
          options={{
            variables: {
              request: {
                pageNumber: 0,
                pageSize: 100,
                filters: { insuranceProviderIdsFilter: [contract.insuranceProvider?.id] },
              },
            },
          }}
        >
          {({ reportingRuleConfigurations }: ReportingRuleConfigurationPage) => {
            const reportingRuleConfigurationsOptions = [
              { value: null, label: t('common:select') },
              ...createOptionsFromList(reportingRuleConfigurations, 'id', 'name'),
            ];
            return (
              <Select
                small
                placeholder={t('common:select')}
                options={reportingRuleConfigurationsOptions}
                selected={reportingRuleConfigurationsOptions.find(
                  (option) => option.value === agreedProductRequest.reportingRuleConfigurationId,
                )}
                onChange={(option) => onReportingRuleConfigurationChange(option.value)}
                data-cy="reporting-rule-configuration-dropdown"
              />
            );
          }}
        </QueryWrapper>
      ) : (
        <Select
          small
          placeholder={t('agreementRegistrySettings:drawer.input-not-available')}
          selected={null}
          options={[]}
          onChange={null}
          disabled
        />
      )}
    </InlineEdit>
  );
};

export default ReportingRuleConfigurationInput;
