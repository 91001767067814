import { ListItem, TableBody, TableHead } from 'fgirot-k2-ui-components';
import './reporting-events-search-result.scss';
import { useTranslation } from 'react-i18next';
import { PersonReportingEventsResponse } from 'src/types/PersonReportingEventsResponse';
import React, { useState } from 'react';
import ReportingEventTableHeads from './ReportingEventTableHeads';
import ReportingEventDetailsRow from './ReportingEventDetailsRow';
import TableBase from 'src/components/Common/TableBase';
import { ReportingEvent } from 'src/types/reporting/ReportingEvent';
import ReportingEmployeeHeader from '../../ReportingEmployeeHeader';
import ReportingEventsDrawer from '../ReportingEventsDrawer';
import { formatEmployeeFullName } from 'src/util/EmployeeUtil';

interface ReportingEventsSearchResultProps {
  person: PersonReportingEventsResponse;
}

const ReportingEventsSearchResult = ({ person }: ReportingEventsSearchResultProps) => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedReportingEvent, setSelectedReportingEvent] = useState<ReportingEvent>(null);
  const fullName = formatEmployeeFullName(person?.employees[0]);
  const employmentDetails = person.employees.map((employee) => {
    return {
      employmentNumber: employee.employmentNumber,
      employerId: employee.employerId,
    };
  });

  const handleOpenDrawer = () => {
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setSelectedReportingEvent(null);
  };

  const handleBackClick = () => {
    setSelectedReportingEvent(null);
  };

  const handleSelectEvent = (event: ReportingEvent) => {
    setShowDrawer(true);
    setSelectedReportingEvent(event);
  };

  const anyEmployeeHasDraft = person.employees.some((employee) => employee.hasDraft);

  return (
    <div className="reporting-events-search-result" data-testid="reporting-events-search-result">
      <ListItem
        compressed
        onClick={handleOpenDrawer}
        buttonLabel={t('reporting:reporting-events.show-details')}
        header={
          <ReportingEmployeeHeader
            fullName={fullName}
            personNumber={person.personNumber}
            employmentDetails={employmentDetails}
            hasDraft={anyEmployeeHasDraft}
          />
        }
      >
        <TableBase compressed>
          <TableHead>
            <ReportingEventTableHeads />
          </TableHead>
          <TableBody>
            {person.reportingEvents.map((reportEvent) => {
              const employee = person.employees.find((employee) => employee.id === reportEvent.employeeId);
              return (
                <ReportingEventDetailsRow
                  key={reportEvent.id}
                  reportingEvent={reportEvent}
                  employee={employee}
                  onSelectEvent={handleSelectEvent}
                />
              );
            })}
          </TableBody>
        </TableBase>
      </ListItem>
      {showDrawer && (
        <ReportingEventsDrawer
          reportingEventsData={person}
          onClose={handleCloseDrawer}
          open={showDrawer}
          onSelectEvent={handleSelectEvent}
          onBackClick={handleBackClick}
          selectedReportingEvent={selectedReportingEvent}
        />
      )}
    </div>
  );
};

export default ReportingEventsSearchResult;
