import { GET_AGREED_REPORTING_TYPE_CONFIGS } from '../../graphql/schema/query/contract/GetAgreedReportingtypeConfigs';
import { PATCH_AGREED_REPORTING_TYPE_CONFIG } from '../../graphql/schema/mutation/contract/PatchAgreedReportingTypeConfig';
import { PatchReportingTypeConfigRequest } from '../../types/contract/request/PatchReportingTypeConfigRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const usePatchAgreedReportingTypeConfig = () => {
  const apolloMutation = useApolloMutation();

  const patchAgreedReportingTypeConfig = (
    employerId: string,
    insuranceProviderContractId: string,
    agreedProductId: string,
    agreedReportingTypeConfigId: string,
    request: PatchReportingTypeConfigRequest,
  ) =>
    apolloMutation(
      PATCH_AGREED_REPORTING_TYPE_CONFIG,
      employerId,
      {
        insuranceProviderContractId,
        agreedProductId,
        agreedReportingTypeConfigId,
        request,
      },
      [GET_AGREED_REPORTING_TYPE_CONFIGS],
    );

  return patchAgreedReportingTypeConfig;
};
