import { ListGroup, Option } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { Month } from 'src/types/Month';
import PeriodFromToInput from '../PeriodFromToInput';

interface FromToYearMonthProps {
  selectedFromYear: Option<number>;
  setSelectedFromYear: React.Dispatch<React.SetStateAction<Option<number>>>;
  selectedFromMonth: Option<Month>;
  setSelectedFromMonth: React.Dispatch<React.SetStateAction<Option<Month>>>;
  selectedToYear: Option<number>;
  setSelectedToYear: React.Dispatch<React.SetStateAction<Option<number>>>;
  selectedToMonth: Option<Month>;
  setSelectedToMonth: React.Dispatch<React.SetStateAction<Option<Month>>>;
  setSelectedFromPeriod: React.Dispatch<React.SetStateAction<string>>;
  setSelectedToPeriod: React.Dispatch<React.SetStateAction<string>>;
}
const FromToYearMonth: FC<FromToYearMonthProps> = ({
  selectedFromYear,
  setSelectedFromYear,
  selectedFromMonth,
  setSelectedFromMonth,
  selectedToYear,
  setSelectedToYear,
  selectedToMonth,
  setSelectedToMonth,
  setSelectedFromPeriod,
  setSelectedToPeriod,
}) => {
  return (
    <ListGroup variant="inline-edit">
      <PeriodFromToInput
        selectedFromYear={selectedFromYear}
        setSelectedFromYear={setSelectedFromYear}
        selectedFromMonth={selectedFromMonth}
        setSelectedFromMonth={setSelectedFromMonth}
        selectedToYear={selectedToYear}
        setSelectedToYear={setSelectedToYear}
        selectedToMonth={selectedToMonth}
        setSelectedToMonth={setSelectedToMonth}
        setSelectedFromPeriod={setSelectedFromPeriod}
        setSelectedToPeriod={setSelectedToPeriod}
      />
    </ListGroup>
  );
};

export default FromToYearMonth;
