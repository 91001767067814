import { gql } from '@apollo/client';

export const ADD_ROLE_TO_GROUP = gql`
  mutation AddRoleToGroup($groupId: UUID!, $roleId: UUID!) {
    addRoleToGroup(groupId: $groupId, roleId: $roleId) {
      groupId
      roleId
    }
  }
`;
