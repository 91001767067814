import { gql } from '@apollo/client';
import {
  CreateBalancingOfBooksAdjustmentInput,
  UpdateBalancingOfBooksAdjustmentInput,
} from 'src/types/accountingunit/BalancingOfBooksAdjustment';
import { BALANCING_OF_BOOKS_SETTINGS } from 'src/graphql/schema/query/accountingunit/BalancingOfBooksSettings';
import { useApolloMutation } from '../graphql/Mutation';

const CREATE_BALANCING_OF_BOOKS_ADJUSTMENT = gql`
  mutation CreateBalancingOfBooksAdjustment($request: CreateBalancingOfBooksAdjustmentInput!) {
    createBalancingOfBooksAdjustment(request: $request) {
      id
    }
  }
`;

const UPDATE_BALANCING_OF_BOOKS_ADJUSTMENT = gql`
  mutation UpdateBalancingOfBooksAdjustment($request: UpdateBalancingOfBooksAdjustmentInput!) {
    updateBalancingOfBooksAdjustment(request: $request) {
      id
    }
  }
`;

const DELETE_BALANCING_OF_BOOKS_ADJUSTMENT = gql`
  mutation DeleteBalancingOfBooksAdjustment($balancingOfBooksAdjustmentId: UUID!) {
    deleteBalancingOfBooksAdjustment(balancingOfBooksAdjustmentId: $balancingOfBooksAdjustmentId) {
      id
    }
  }
`;

export const useCreateBalancingOfBooksAdjustment = () => {
  const apolloMutation = useApolloMutation();

  const createBalancingOfBooksAdjustment = (request: CreateBalancingOfBooksAdjustmentInput, employerId: string) =>
    apolloMutation(CREATE_BALANCING_OF_BOOKS_ADJUSTMENT, employerId, { request }, [BALANCING_OF_BOOKS_SETTINGS]);

  return createBalancingOfBooksAdjustment;
};

export const useUpdateBalancingOfBooksAdjustment = () => {
  const apolloMutation = useApolloMutation(false);

  const updateBalancingOfBooksAdjustment = (request: UpdateBalancingOfBooksAdjustmentInput, employerId: string) =>
    apolloMutation(UPDATE_BALANCING_OF_BOOKS_ADJUSTMENT, employerId, { request }, [BALANCING_OF_BOOKS_SETTINGS]);

  return updateBalancingOfBooksAdjustment;
};

export const useDeleteBalancingOfBooksAdjustment = () => {
  const apolloMutation = useApolloMutation();

  const deleteBalancingOfBooksAdjustment = (balancingOfBooksAdjustmentId: string, employerId: string) =>
    apolloMutation(DELETE_BALANCING_OF_BOOKS_ADJUSTMENT, employerId, { balancingOfBooksAdjustmentId }, [
      BALANCING_OF_BOOKS_SETTINGS,
    ]);

  return deleteBalancingOfBooksAdjustment;
};
