import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import InvoiceRowsTableHead from './InvoiceRowsTableHead';
import { InvoiceRowSortField } from './InvoiceRowSortField';
import { sortAlphabeticalAsc, sortAlphabeticalDesc, sortNumericalAsc, sortNumericalDesc } from 'src/util/SortUtil';
import InvoiceRowTableRow from './InvoiceRowTableRow/InvoiceRowTableRow';
import { InvoiceRow } from 'src/types/invoice/InvoiceRow';

interface InvoiceRowsProps {
  invoiceRows: InvoiceRow[];
}

const InvoiceRowsTable: FC<InvoiceRowsProps> = ({ invoiceRows }) => {
  const [sortField, setSortField] = useState<InvoiceRowSortField>();
  const [sortDirection, setSortDirection] = useState<SortDirection>('DOWN');

  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));
  const handleSortFieldChange = (sortField: InvoiceRowSortField) => setSortField(sortField);

  const sortFunction = (a: InvoiceRow, b: InvoiceRow) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalAsc : sortNumericalDesc;
    switch (sortField) {
      case 'employee.personNumber':
      case 'employee.firstName':
      case 'employee.lastName':
      case 'employee.employmentNumber': {
        const field = sortField.split('.')[1];
        return sortAlphabetical(a?.employee[field], b.employee[field]);
      }
      case 'invoicePeriod.periodStart':
      case 'invoicePeriod.periodEnd': {
        const field = sortField.split('.')[1];
        return sortAlphabetical(a.invoicePeriod[field], b.invoicePeriod[field]);
      }
      case 'benefitType':
      case 'insuranceNumber':
      case 'contractBenefitGroup':
      case 'contractBenefitGroupName':
      case 'note':
        return sortAlphabetical(a[sortField], b[sortField]);
      case 'amount':
        return sortNumerical(a[sortField], b[sortField]);
      default:
        return undefined;
    }
  };

  const sortedInvoiceRows = [...invoiceRows].sort(sortFunction);

  return (
    <>
      <TableBase compressed>
        <TableHead>
          <InvoiceRowsTableHead
            sortDirection={sortDirection}
            sortField={sortField}
            onSortFieldChange={handleSortFieldChange}
            onSortDirectionChange={handleSortDirectionChange}
          />
        </TableHead>
        <TableBody>
          {sortedInvoiceRows.map((invoiceRow, index) => (
            <InvoiceRowTableRow key={`invoice-row-table-row-${invoiceRow.id}-${index}`} row={invoiceRow} />
          ))}
        </TableBody>
      </TableBase>
    </>
  );
};

export default InvoiceRowsTable;
