import { Option, Select, Typography } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Month } from 'src/types/Month';
import { useSearchParams } from 'src/util/Hooks/SearchParamsHook';
import { convertMonthEnumToNumber, convertMonthNumberToEnum, useMonthOptions } from 'src/util/date/Months';
import { useYearOptions } from 'src/util/date/Years';
import './period-filter.scss';
import { useLocation } from 'react-router-dom';

interface PeriodFilterProps {
  placement?: 'left' | 'right';
  useInitialFilter?: boolean;
  periodStart?: string;
  periodEnd?: string;
}

const PeriodFilter = ({ placement = 'left', useInitialFilter, periodStart, periodEnd }: PeriodFilterProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const historyExists = location.key !== 'default';
  const { searchParams, setSearchParams, deleteSearchParams, setMultipleSearchParams } = useSearchParams();
  const [selectedStartMonth, setSelectedStartMonth] = useState<Option<Month>>();
  const [selectedEndMonth, setSelectedEndMonth] = useState<Option<Month>>();
  const [selectedStartYear, setSelectedStartYear] = useState<Option<number>>();
  const [selectedEndYear, setSelectedEndYear] = useState<Option<number>>();

  const monthOptions = useMonthOptions();
  const yearsOptions = useYearOptions();

  const searchParamPeriodStartDate = searchParams?.get('periodStart');
  const searchParamPeriodEndDate = searchParams?.get('periodEnd');

  useEffect(() => {
    if (useInitialFilter && periodStart && periodEnd) {
      setMultipleSearchParams({ periodStart, periodEnd });
      setSelectedPeriodStart(periodStart);
      setSelectedPeriodEnd(periodEnd);
    } else if (!useInitialFilter && historyExists) {
      deleteSearchParams(['periodStart', 'periodEnd']);
    }
  }, []);

  const setSelectedPeriodStart = (period: string) => {
    const month = convertMonthNumberToEnum(parseInt(period.substring(4, 6)));
    const year = parseInt(period.substring(0, 4));
    setSelectedStartMonth(monthOptions.find((option) => option.value === month));
    setSelectedStartYear(yearsOptions.find((option) => option.value === year));
  };

  const setSelectedPeriodEnd = (period: string) => {
    const month = convertMonthNumberToEnum(parseInt(period.substring(4, 6)));
    const year = parseInt(period.substring(0, 4));
    setSelectedEndMonth(monthOptions.find((option) => option.value === month));
    setSelectedEndYear(yearsOptions.find((option) => option.value === year));
  };

  useEffect(() => {
    if (!historyExists && !searchParamPeriodStartDate && !searchParamPeriodEndDate) {
      setSelectedStartMonth(null);
      setSelectedStartYear(null);
      setSelectedEndMonth(null);
      setSelectedEndYear(null);
    }
    if (
      !historyExists &&
      !useInitialFilter &&
      searchParamPeriodStartDate &&
      !selectedStartMonth &&
      !selectedStartYear
    ) {
      setSelectedPeriodStart(searchParamPeriodStartDate);
    }

    if (!historyExists && !useInitialFilter && searchParamPeriodEndDate && !selectedEndMonth && !selectedEndYear) {
      setSelectedPeriodEnd(searchParamPeriodEndDate);
    }
  }, [searchParams]);

  const handlePeriodStartChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedStartMonth(monthOption);
    setSelectedStartYear(yearOption);

    if (yearOption && monthOption) {
      const periodStart = new Date(yearOption.value, convertMonthEnumToNumber(monthOption.value));
      setSearchParams('periodStart', periodStart.toJSON().replace(/\D/g, '').slice(0, 6));
    }
  };

  const handlePeriodEndChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedEndMonth(monthOption);
    setSelectedEndYear(yearOption);

    if (yearOption && monthOption) {
      const periodEnd = new Date(yearOption.value, convertMonthEnumToNumber(monthOption.value));
      setSearchParams('periodEnd', periodEnd.toJSON().replace(/\D/g, '').slice(0, 6));
    }
  };

  return (
    <div className={`date-filter date-filter--${placement}`}>
      <div className="date-filter__period">
        <Typography bold component="label" variant="caption" className="date-filter__label">
          {t('common:period-filter.choose-period-start')}
        </Typography>
        <div className="date-filter__period__selects">
          <Select
            placeholder={
              !selectedStartMonth ? t('common:month').toLowerCase() : t(`common:months.${selectedStartMonth.value}`)
            }
            options={monthOptions}
            alphabetical={false}
            onChange={(option: Option<Month>) => handlePeriodStartChange(option, selectedStartYear)}
            selected={selectedStartMonth}
            data-testid="start-month-select"
          />
          <Select
            placeholder={!selectedStartYear ? t('common:year').toLowerCase() : selectedStartYear.value.toString()}
            options={yearsOptions}
            onChange={(option: Option<number>) => handlePeriodStartChange(selectedStartMonth, option)}
            selected={selectedStartYear}
            data-testid="start-year-select"
          />
        </div>
      </div>
      <div className="date-filter__period">
        <Typography bold component="label" variant="caption" className="date-filter__label">
          {t('common:period-filter.choose-period-end')}
        </Typography>
        <div className="date-filter__period__selects">
          <Select
            placeholder={
              !selectedEndMonth ? t('common:month').toLowerCase() : t(`common:months.${selectedEndMonth.value}`)
            }
            options={monthOptions}
            alphabetical={false}
            onChange={(option: Option<Month>) => handlePeriodEndChange(option, selectedEndYear)}
            selected={selectedEndMonth}
            data-testid="end-month-select"
          />
          <Select
            placeholder={!selectedEndYear ? t('common:year').toLowerCase() : selectedEndYear.value.toString()}
            options={yearsOptions}
            onChange={(option: Option<number>) => handlePeriodEndChange(selectedEndMonth, option)}
            selected={selectedEndYear}
            data-testid="end-year-select"
          />
        </div>
      </div>
    </div>
  );
};

export default PeriodFilter;
