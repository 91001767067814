export interface PolicyVersionChanges {
  changedEmploymentGroups: ChangedEmploymentGroup[];
  changedBenefits: ChangedBenefit[];
  changedPglRules: ChangedPglRule[];
}

export interface ChangedEmploymentGroup {
  versionChangeType: VersionChangeType;
  policyEmploymentGroup: PolicyEmploymentGroup;
}

export interface ChangedBenefit {
  id: string;
  name: string;
  benefitId: string;
  benefitType: string;
  versionChangeType: VersionChangeType;
  policyEmploymentGroup: PolicyEmploymentGroup;
}

export interface ChangedPglRule {
  id: string;
  name: string;
  versionChangeType: VersionChangeType;
}

export interface PolicyEmploymentGroup {
  id: string;
  employmentGroupId: string;
}

export type VersionChangeType = 'NEW' | 'UPDATED' | 'DELETED';

export const NEW: VersionChangeType = 'NEW';
export const UPDATED: VersionChangeType = 'UPDATED';
export const DELETED: VersionChangeType = 'DELETED';

export type ChangedObjectType = 'GROUPS' | 'BENEFITS' | 'CALCULATIONS';
export const GROUPS: ChangedObjectType = 'GROUPS';
export const BENEFITS: ChangedObjectType = 'BENEFITS';
export const CALCULATIONS: ChangedObjectType = 'CALCULATIONS';
