import { EmployerWageFileSetting } from 'src/types/employer/EmployerWageFileSetting';
import { EmployerWageFileSettingRequest } from 'src/types/employer/request/EmployerWageFileSettingRequest';

export const wageFileSettingRequestIsValid = (request: EmployerWageFileSettingRequest): boolean => {
  let isValid = true;

  if (!request.salarySystem || !request.wageFileFormat || !request.expectedNoOfWageFiles) {
    isValid = false;
  }

  // expectedNoOfWageFiles must be an integer
  if (request.expectedNoOfWageFiles && !request.expectedNoOfWageFiles.toString().match(/^\d+$/)) {
    isValid = false;
  }

  return isValid;
};

export const hasWageFileSettingRequestChangedFromWageFileSetting = (
  request: EmployerWageFileSettingRequest,
  employerWageFileSetting: EmployerWageFileSetting,
): boolean => {
  let hasChanged = false;

  if (
    employerWageFileSetting !== null &&
    (request.salarySystem !== employerWageFileSetting.salarySystem ||
      request.wageFileFormat !== employerWageFileSetting.wageFileFormat ||
      request.expectedNoOfWageFiles !== employerWageFileSetting.expectedNoOfWageFiles)
  ) {
    hasChanged = true;
  }

  if (employerWageFileSetting === null) {
    hasChanged = true;
  }

  return hasChanged;
};
