import { InlineEdit, ListGroup, Select, Option } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { useUpdatePolicyBenefitConnectionRule } from 'src/service/policy/UpdatePolicyBenefitConnectionRule';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { PolicyBenefitConnectionRuleValue } from 'src/types/policy/PolicyBenefitConnectionRule';
import { PolicyState } from 'src/types/policy/PolicyEntity';

interface ConnectionRuleDetailsProps {
  policyId: string;
  policyState: PolicyState;
  policyBenefit: PolicyBenefit;
  employerId: string;
}

const ConnectionRuleDetails: React.FunctionComponent<ConnectionRuleDetailsProps> = ({
  policyId,
  policyState,
  policyBenefit,
  employerId,
}) => {
  const { t } = useTranslation();
  const updatePolicyBenefitConnectionRule = useUpdatePolicyBenefitConnectionRule();

  const options: Option<PolicyBenefitConnectionRuleValue>[] = [
    {
      label: t('benefitConnection:benefitConnectionRule:optional:labels.optional'),
      value: PolicyBenefitConnectionRuleValue.OPTIONAL,
    },
    {
      label: t('benefitConnection:benefitConnectionRule:optional:labels.mandatory'),
      value: PolicyBenefitConnectionRuleValue.MANDATORY,
    },
  ];

  const getSelected = (): Option<PolicyBenefitConnectionRuleValue> => {
    if (policyBenefit.connectionRule.optional == null) {
      return undefined;
    }
    const selected = policyBenefit.connectionRule.optional
      ? PolicyBenefitConnectionRuleValue.OPTIONAL
      : PolicyBenefitConnectionRuleValue.MANDATORY;
    return options.find((option) => option.value === selected);
  };

  const handleSelect = (option: Option<PolicyBenefitConnectionRuleValue>) => {
    const connectionRuleValue = option.value as PolicyBenefitConnectionRuleValue;
    updatePolicyBenefitConnectionRule(
      {
        policyId,
        benefitId: policyBenefit.id,
        optional: connectionRuleValue === PolicyBenefitConnectionRuleValue.OPTIONAL,
      },
      employerId,
    );
  };

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant="inline-edit">
        <InlineEdit bold label={t('benefitConnection:benefitConnectionRule:optional.name')}>
          <Select
            disabled={policyState !== 'DRAFT'}
            options={options}
            selected={getSelected()}
            onChange={handleSelect}
            placeholder="-"
            data-cy={'policy-benefit-connection-rule__optional__dropdown'}
            small
          />
        </InlineEdit>
      </ListGroup>
    </div>
  );
};

export default ConnectionRuleDetails;
