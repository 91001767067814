import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { DocumentTemplate } from 'src/types/documents/DocumentTemplate';
import { sortAlphabeticalAsc, sortAlphabeticalDesc } from 'src/util/SortUtil';

import DocumentTemplateTableHeads from './DocumentTemplateTableHeads';
import DocumentTemplateTableRow from './DocumentTemplateTableRow';
import { DocumentTemplateTableSortField } from './DocumentTemplateTableSortField';

interface DocumentTemplatesTableProps {
  documentFilterText: string;
  documentTemplates: DocumentTemplate[];
  onTemplateRowClick: (id: string) => void;
}
const DocumentTemplatesTable: FC<DocumentTemplatesTableProps> = ({
  documentFilterText,
  documentTemplates,
  onTemplateRowClick,
}) => {
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');
  const [sortField, setSortField] = useState<DocumentTemplateTableSortField>('documentTemplateName');

  const sortFunction = (a: DocumentTemplate, b: DocumentTemplate) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;

    switch (sortField) {
      case 'documentTemplateName':
        return sortAlphabetical(a.name, b.name);
      case 'reportType':
        return sortAlphabetical(a.formatType, b.formatType);
      case 'description':
        return sortAlphabetical(a.description, b.description);
      default:
        return undefined;
    }
  };

  const handleSortFieldChange = (sortField: DocumentTemplateTableSortField) => setSortField(sortField);
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));
  const filterDocumentsFromSearch = (documentTemplate: DocumentTemplate) => {
    return documentTemplate.name.toLocaleUpperCase().includes(documentFilterText.toLocaleUpperCase());
  };

  const filteredDocuments = documentTemplates.filter(filterDocumentsFromSearch).sort(sortFunction);

  return (
    <div className="document-template-tab__table">
      <TableBase maxHeightPercentage={55}>
        <TableHead filled>
          <DocumentTemplateTableHeads
            sortDirection={sortDirection}
            onSortDirectionChange={handleSortDirectionChange}
            onSortFieldChange={handleSortFieldChange}
            sortField={sortField}
          />
        </TableHead>
        <TableBody>
          {filteredDocuments.map((document) => (
            <DocumentTemplateTableRow
              key={document.id}
              documentTemplate={document}
              onClick={() => onTemplateRowClick(document.id)}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default DocumentTemplatesTable;
