import { UPDATE_POLICY_BENEFIT_CALCULATION_RULE } from 'src/graphql/schema/mutation/policy/UpdatePolicyBenefitCalculationRule';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import { UpdatePolicyBenefitCalculationRuleRequest } from 'src/types/policy/request/UpdatePolicyBenefitCalculationRuleRequest';
import { useApolloMutation } from '../graphql/Mutation';

export const useUpdatePolicyBenefitCalculationRule = () => {
  const apolloMutation = useApolloMutation();

  const updatePolicyBenefitCalculationRule = (request: UpdatePolicyBenefitCalculationRuleRequest, employerId: string) =>
    apolloMutation(UPDATE_POLICY_BENEFIT_CALCULATION_RULE, employerId, { request }, [GET_POLICY]);

  return updatePolicyBenefitCalculationRule;
};
