import { IconPill } from 'fgirot-k2-ui-components';
import { Employee } from 'src/types/Employee';
import './single-employment.details.scss';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import { useTranslation } from 'react-i18next';

interface SingleEmploymentDetailsProps {
  employmentData: Employee;
}

const SingleEmploymentDetails = ({ employmentData }: SingleEmploymentDetailsProps) => {
  const { t } = useTranslation();
  const personNumber = employmentData.personNumber;
  const employmentNumber = employmentData.employmentNumber;
  const employerLegalName = useEmployerLegalName(employmentData.employerId);

  return (
    <div className="single-employment-details">
      <IconPill icon="User" label={personNumber} />
      <IconPill icon="Building" label={employerLegalName} />
      {employmentNumber != null ? (
        <IconPill icon="Hash" label={employmentNumber} />
      ) : (
        <IconPill icon="Hash" label={t('employees:search-tab:no-employment-number')} />
      )}
    </div>
  );
};

export default SingleEmploymentDetails;
